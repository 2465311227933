import {
  GET_OFFICE_LISTING,
  GET_OFFICE_LISTING_SUCCESS,
  GET_OFFICE_LISTING_FAILED,
  FETCH_OFFICE_BY_SECTION,
  FETCH_OFFICE_BY_SECTION_SUCCESS,
  FETCH_OFFICE_BY_SECTION_FAIL,
  ADD_OFFICE,
  ADD_OFFICE_SUCCESS,
  ADD_OFFICE_FAIL,
  EDIT_OFFICE,
  EDIT_OFFICE_SUCCESS,
  EDIT_OFFICE_FAIL,
  UPDATE_OFFICE,
  UPDATE_OFFICE_SUCCESS,
  UPDATE_OFFICE_FAIL,
  OFFICE_ACTIVE_INACTIVE,
  OFFICE_ACTIVE_INACTIVE_SUCCESS,
  OFFICE_ACTIVE_INACTIVE_FAIL,
  OFFICE_POSITION_SUCCESS,
  OFFICE_POSITION,
  OFFICE_POSITION_FAIL,
} from "./actionTypes"

export const officeListing = () => {
  return {
    type: GET_OFFICE_LISTING,
  }
}

export const officeListingSuccess = office => {
  return {
    type: GET_OFFICE_LISTING_SUCCESS,
    payload: office,
  }
}

export const officeListingFail = error => {
  return {
    type: GET_OFFICE_LISTING_FAILED,
    payload: error,
  }
}

export const fetchOfficeListingBySection = data => {
  return {
    type: FETCH_OFFICE_BY_SECTION,
    payload: { data },
  }
}

export const fetchOfficeListingBySectionSuccess = office => {
  return {
    type: FETCH_OFFICE_BY_SECTION_SUCCESS,
    payload: office,
  }
}

export const fetchOfficeListingBySectionFail = error => {
  return {
    type: FETCH_OFFICE_BY_SECTION_FAIL,
    payload: error,
  }
}

export const addOffice = (data, history) => {
  return {
    type: ADD_OFFICE,
    payload: { data, history },
  }
}

export const addOfficeSuccess = office => {
  return {
    type: ADD_OFFICE_SUCCESS,
    payload: office,
  }
}

export const addOfficeFail = error => {
  return {
    type: ADD_OFFICE_FAIL,
    payload: error,
  }
}

export const editOffice = id => {
  return {
    type: EDIT_OFFICE,
    payload: { id: id },
  }
}
export const editOfficeSuccess = office => {
  return {
    type: EDIT_OFFICE_SUCCESS,
    payload: office,
  }
}
export const editOfficeFail = error => {
  return {
    type: EDIT_OFFICE_FAIL,
    payload: error,
  }
}

export const updateOffice = (updateOffice, history) => {
  return {
    type: UPDATE_OFFICE,
    payload: { updateOffice, history },
  }
}

export const updateOfficeSuccess = updateOffice => {
  return {
    type: UPDATE_OFFICE_SUCCESS,
    payload: updateOffice,
  }
}

export const updateOfficeFail = error => {
  return {
    type: UPDATE_OFFICE_FAIL,
    payload: error,
  }
}

export const officeChange = id => ({
  type: OFFICE_ACTIVE_INACTIVE,
  payload: { id: id },
})

export const officeChangeSuccess = office => ({
  type: OFFICE_ACTIVE_INACTIVE_SUCCESS,
  payload: office,
})

export const officeChangeFail = error => ({
  type: OFFICE_ACTIVE_INACTIVE_FAIL,
  payload: error,
})

export const updateOfficePosition = position => ({
  type: OFFICE_POSITION,
  payload: { position },
})

export const updateOfficePositionSuccess = packing => ({
  type: OFFICE_POSITION_SUCCESS,
  payload: packing,
})

export const updateOfficePositionFail = error => ({
  type: OFFICE_POSITION_FAIL,
  payload: error,
})
