export const CO_GET_SUPPLIERNAME_LISTING = "CO_GET_SUPPLIERNAME_LISTING"
export const CO_GET_SUPPLIERNAME_LISTING_SUCCESS = "CO_GET_SUPPLIERNAME_LISTING_SUCCESS"
export const CO_GET_SUPPLIERNAME_LISTING_FAILED = "CO_GET_SUPPLIERNAME_LISTING_FAILED"

export const CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE =
  "CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE"
export const CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS =
  "CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS"
export const CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED =
  "CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED"
