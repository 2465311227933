import {
  GET_MATERIALSTANDARD_LISTING,
  GET_MATERIALSTANDARD_LISTING_SUCCESS,
  GET_MATERIALSTANDARD_LISTING_FAILED,
  ADD_MATERIAL,
  ADD_MATERIAL_SUCCESS,
  ADD_MATERIAL_FAIL,
  CHANGE_MATERIAL_STATUS,
  CHANGE_MATERIAL_STATUS_SUCCESS,
  CHANGE_MATERIAL_STATUS_FAIL,
  EDIT_MATERIAL,
  EDIT_MATERIAL_SUCCESS,
  EDIT_MATERIAL_FAIL,
  UPDATE_MATERIAL,
  UPDATE_MATERIAL_SUCCESS,
  UPDATE_MATERIAL_FAIL,
  FETCH_MATERIAL_BY_SECTION,
  FETCH_MATERIAL_BY_SECTION_SUCCESS,
  FETCH_MATERIAL_BY_SECTION_FAIL,
  MATERIAL_STANDARD_POSITION_SUCCESS,
  MATERIAL_STANDARD_POSITION,
  MATERIAL_STANDARD_POSITION_FAIL,
} from "./actionTypes"

const initialState = {
  loading: false,
  msg: null,
  error: null,
  positionUpdate: null,
  materialStandard: [],
  materialStandardEdit: null,
  materialStandardUpdate: null,
}

const materialStandard = (state = initialState, action) => {
  switch (action.type) {
    case GET_MATERIALSTANDARD_LISTING:
      state = {
        ...state,
        loading: true,
        msg: null,
        error: null,
        positionUpdate: null,
        materialStandard: action.payload,
      }
      break
    case GET_MATERIALSTANDARD_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: null,
        positionUpdate: null,
        materialStandard: action.payload,
      }
      break
    case GET_MATERIALSTANDARD_LISTING_FAILED:
      state = {
        ...state,
        msg: null,
        positionUpdate: null,
        loading: false,
      }
      break
    case ADD_MATERIAL:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        materialStandard: action.payload,
      }
      break
    case ADD_MATERIAL_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        materialStandard: action.payload,
      }
      break
    case ADD_MATERIAL_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload ? action.payload : null,
      }
      break
    case CHANGE_MATERIAL_STATUS:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break
    case CHANGE_MATERIAL_STATUS_SUCCESS:
      const index = state.materialStandard.findIndex(
        material_standards =>
          material_standards.material_standard_id === action.payload.id
      )
      let newMaterialStandardAfterStatusChange = []
      newMaterialStandardAfterStatusChange = [...state.materialStandard] //making a new array
      newMaterialStandardAfterStatusChange[index].status =
        !newMaterialStandardAfterStatusChange[index].status

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        materialStandard: newMaterialStandardAfterStatusChange,
      }
      break

    case CHANGE_MATERIAL_STATUS_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case EDIT_MATERIAL:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        materialStandardEdit: action.payload,
      }
      break
    case EDIT_MATERIAL_SUCCESS:
      if (state === null || state.materialStandard.length === 0) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          materialStandard: [...state.materialStandard, action.payload],
        }
      } else {
        state = {
          ...state,
          loading: false,
          materialStandard: state.materialStandard.map(i =>
            i.id === action.payload.id ? action.payload : i
          ),
        }
      }
      break
    case EDIT_MATERIAL_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case UPDATE_MATERIAL:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        materialStandard: action.payload,
      }
      break
    case UPDATE_MATERIAL_SUCCESS:
      state = { ...state, error: null, msg: null, loading: false }
      break
    case UPDATE_MATERIAL_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case FETCH_MATERIAL_BY_SECTION:
      state = {
        ...state,
        loading: true,
        msg: null,
        error: null,
        positionUpdate: null,
        materialStandardList: action.payload,
      }
      break
    case FETCH_MATERIAL_BY_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: null,
        positionUpdate: null,
        materialStandard: action.payload,
      }
      break
    case FETCH_MATERIAL_BY_SECTION_FAIL:
      state = {
        ...state,
        msg: null,
        positionUpdate: null,
        loading: false,
      }
      break

    case MATERIAL_STANDARD_POSITION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
      }
      break

    case MATERIAL_STANDARD_POSITION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: action.payload.msg != "" ? action.payload.msg : "",
        positionUpdate: true,
      }
      break
    case MATERIAL_STANDARD_POSITION_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.msg != "" ? action.payload.msg : "",
        msg: null,
        positionUpdate: null,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default materialStandard
