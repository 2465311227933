import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "reactstrap"
import CardUser from "./card-user"
import { useSelector, useDispatch } from "react-redux"
import { validateToken, getTotalCountOnDashboard } from "../../store/actions"

const Dashboard = () => {
  //meta title
  document.title = "Dashboard"
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(validateToken())
    dispatch(getTotalCountOnDashboard())
  }, [dispatch])
  const [dashboardCount, setDashboardCount] = useState(null)
  const { dashboardCounts } = useSelector(state => state.Dashboard)

  useEffect(() => {
    if (JSON.stringify(dashboardCounts) !== "{}") {
      setDashboardCount(dashboardCounts)
    }
  }, [dashboardCounts])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <h4>Dashboard</h4>
          {/* Card User */}
          {dashboardCount ? (
            <CardUser dashboardCounts={dashboardCount} />
          ) : null}
          <Row>
            <Col xl="12">
              <Row></Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
