import {
  GET_INCOTERMCITY_LISTING,
  GET_INCOTERMCITY_LISTING_SUCCESS,
  GET_INCOTERMCITY_LISTING_FAILED,
  ADD_INCOTERM,
  ADD_INCOTERM_SUCCESS,
  ADD_INCOTERM_FAIL,
  CHANGE_INCOTERM_STATUS,
  CHANGE_INCOTERM_STATUS_SUCCESS,
  CHANGE_INCOTERM_STATUS_FAIL,
  EDIT_INCOTERM,
  EDIT_INCOTERM_SUCCESS,
  EDIT_INCOTERM_FAIL,
  UPDATE_INCOTERM,
  UPDATE_INCOTERM_SUCCESS,
  UPDATE_INCOTERM_FAIL,
  FETCH_INCOTERM_BY_SECTION,
  FETCH_INCOTERM_BY_SECTION_SUCCESS,
  FETCH_INCOTERM_BY_SECTION_FAIL,
  INCOTERM_CITY_POSITION,
  INCOTERM_CITY_POSITION_SUCCESS,
  INCOTERM_CITY_POSITION_FAIL,
} from "./actionTypes"

export const incotermCityListing = () => {
  return {
    type: GET_INCOTERMCITY_LISTING,
  }
}

export const incotermCityListingSuccess = incotermCity => {
  return {
    type: GET_INCOTERMCITY_LISTING_SUCCESS,
    payload: incotermCity,
  }
}

export const incotermCityListingFail = error => {
  return {
    type: GET_INCOTERMCITY_LISTING_FAILED,
    payload: error,
  }
}

export const addIncoterm = (incotermCity, history) => {
  return {
    type: ADD_INCOTERM,
    payload: { incotermCity, history },
  }
}

export const addIncotermSuccess = incotermCity => {
  return {
    type: ADD_INCOTERM_SUCCESS,
    payload: incotermCity,
  }
}

export const addIncotermFail = error => {
  return {
    type: ADD_INCOTERM_FAIL,
    payload: error,
  }
}

export const changeIncotermStatus = id => ({
  type: CHANGE_INCOTERM_STATUS,
  payload: { id: id },
})

export const changeIncotermStatusSuccess = incotermCity => ({
  type: CHANGE_INCOTERM_STATUS_SUCCESS,
  payload: incotermCity,
})

export const changeIncotermStatusFail = error => ({
  type: CHANGE_INCOTERM_STATUS_FAIL,
  payload: error,
})

export const editIncoterm = id => {
  return {
    type: EDIT_INCOTERM,
    payload: { id },
  }
}
export const editIncotermSuccess = incotermCity => {
  return {
    type: EDIT_INCOTERM_SUCCESS,
    payload: incotermCity,
  }
}
export const editIncotermFail = error => {
  return {
    type: EDIT_INCOTERM_FAIL,
    payload: error,
  }
}

export const updateIncoterm = (incotermCityUpdate, history) => {
  return {
    type: UPDATE_INCOTERM,
    payload: { incotermCityUpdate, history },
  }
}

export const updateIncotermSuccess = incotermCity => {
  return {
    type: UPDATE_INCOTERM_SUCCESS,
    payload: incotermCity,
  }
}

export const updateIncotermFail = error => {
  return {
    type: UPDATE_INCOTERM_FAIL,
    payload: error,
  }
}

export const fetchIncotermListingBySection = data => {
  return {
    type: FETCH_INCOTERM_BY_SECTION,
    payload: { data },
  }
}

export const fetchIncotermListingBySectionSuccess = incotermCity => {
  return {
    type: FETCH_INCOTERM_BY_SECTION_SUCCESS,
    payload: incotermCity,
  }
}

export const fetchIncotermListingBySectionFail = error => {
  return {
    type: FETCH_INCOTERM_BY_SECTION_FAIL,
    payload: error,
  }
}

export const incotermCityPosition = position => ({
  type: INCOTERM_CITY_POSITION,
  payload: { position },
})

export const incotermCityPositionSuccess = incoterm => ({
  type: INCOTERM_CITY_POSITION_SUCCESS,
  payload: incoterm,
})

export const incotermCityPositionFail = error => ({
  type: INCOTERM_CITY_POSITION_FAIL,
  payload: error,
})
