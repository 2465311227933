import {
  QA_COLLECTION_ADD,
  QA_COLLECTION_SUCCESS,
  QA_COLLECTION_FAIL,
  QA_GET_COLLECTION_LISTING,
  QA_GET_COLLECTION_LISTING_SUCCESS,
  QA_GET_COLLECTION_LISTING_FAILED,
  QA_COLLECTION_EDIT,
  QA_COLLECTION_EDIT_SUCCESS,
  QA_COLLECTION_EDIT_FAIL,
  QA_COLLECTION_UPDATE,
  QA_COLLECTION_UPDATE_SUCCESS,
  QA_COLLECTION_UPDATE_FAIL,
  QA_COLLECTION_DELETE,
  QA_COLLECTION_DELETE_SUCCESS,
  QA_COLLECTION_DELETE_FAIL,
  QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
  QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS,
  QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL,
  QA_COLLECTION_COPY,
  QA_COLLECTION_COPY_SUCCESS,
  QA_COLLECTION_COPY_FAIL,
  QA_EXPORTDOWNLOAD_PROGRESS,
  QA_EXPORTDOWNLOAD_PROGRESS_SUCCESS,
  QA_EXPORTDOWNLOAD_PROGRESS_FAIL,
  QA_ADD_MORE_TO_COLLECTION,
  QA_ADD_MORE_TO_COLLECTION_SUCCESS,
  QA_ADD_MORE_TO_COLLECTION_FAIL,
  QA_ADD_MORE_TO_COLLECTION_UPDATE,
  QA_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS,
  QA_ADD_MORE_TO_COLLECTION_UPDATE_FAIL,
  QA_GET_PRODUCTS_FOR_ADD_COLLECTION,
  QA_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS,
  QA_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL,
  QA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
  QA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS,
  QA_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL,
  QA_REFRESH_COLLECTION_COUNT,
  QA_REFRESH_COLLECTION_COUNT_SUCCESS,
  QA_REFRESH_COLLECTION_COUNT_FAIL,
  QA_GET_LAYOUT,
  QA_GET_LAYOUT_SUCCESS,
  QA_GET_LAYOUT_FAIL
} from "./actionTypes"

export const qaCollectionSuccess = collection => {
  return {
    type: QA_COLLECTION_SUCCESS,
    payload: collection,
  }
}

export const qaCollectionFail = error => {
  return {
    type: QA_COLLECTION_FAIL,
    payload: error,
  }
}

export const qaAddNewCollection = (collection, history) => {
  return {
    type: QA_COLLECTION_ADD,
    payload: { collection, history },
  }
}

export const qaCollectionsListing = history => {
  return {
    type: QA_GET_COLLECTION_LISTING,
  }
}

export const qaCollectionsListingSuccess = collection => {
  return {
    type: QA_GET_COLLECTION_LISTING_SUCCESS,
    payload: collection,
  }
}

export const qaCollectionsListingFail = error => {
  return {
    type: QA_GET_COLLECTION_LISTING_FAILED,
    payload: error,
  }
}

export const qaCollectionEdit = id => {
  return {
    type: QA_COLLECTION_EDIT,
    payload: { id: id },
  }
}

export const qaEditCollectionSuccess = collection => {
  return {
    type: QA_COLLECTION_EDIT_SUCCESS,
    payload: collection,
  }
}

export const qaEditCollectionFail = error => {
  return {
    type: QA_COLLECTION_EDIT_FAIL,
    payload: error,
  }
}

export const qaUpdateCollection = (collection, history) => {
  return {
    type: QA_COLLECTION_UPDATE,
    payload: { collection, history },
  }
}

export const qaUpdateCollectionSuccess = collection => {
  return {
    type: QA_COLLECTION_UPDATE_SUCCESS,
    payload: collection,
  }
}

export const qaUpdateCollectionFail = error => {
  return {
    type: QA_COLLECTION_UPDATE_FAIL,
    payload: error,
  }
}

export const qaDeleteCollection = collectionId => ({
  type: QA_COLLECTION_DELETE,
  payload: { id: collectionId },
})

export const qaDeleteCollectionSuccess = collection => ({
  type: QA_COLLECTION_DELETE_SUCCESS,
  payload: collection,
})

export const qaDeleteCollectionFail = error => ({
  type: QA_COLLECTION_DELETE_FAIL,
  payload: error,
})

export const qaDownloadExportCollectionCatalog = (collection, history) => {
  return {
    type: QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
    payload: { collection, history },
  }
}

export const qaExportCollectionCatalogSuccess = collection => {
  return {
    type: QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS,
    payload: collection,
  }
}

export const qaExportCollectionCatalogFail = error => {
  return {
    type: QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL,
    payload: error,
  }
}

export const qaCopyCollection = (collection, history) => {
  return {
    type: QA_COLLECTION_COPY,
    payload: { collection, history },
  }
}

export const qaCopyCollectionSuccess = collection => {
  return {
    type: QA_COLLECTION_COPY_SUCCESS,
    payload: collection,
  }
}

export const qaCopyCollectionFail = error => {
  return {
    type: QA_COLLECTION_COPY_FAIL,
    payload: error,
  }
}

export const qaExportDownloadProgress = (qaExportDownloadProgress, history) => {
  return {
    type: QA_EXPORTDOWNLOAD_PROGRESS,
    payload: { qaExportDownloadProgress, history },
  }
}

export const qaExportDownloadProgressSuccess = qaExportDownloadProgress => {
  return {
    type: QA_EXPORTDOWNLOAD_PROGRESS_SUCCESS,
    payload: qaExportDownloadProgress,
  }
}

export const qaExportDownloadProgressFail = error => {
  return {
    type: QA_EXPORTDOWNLOAD_PROGRESS_FAIL,
    payload: error,
  }
}

export const qaGetAddMoreToCollection = (collection_id) => {
  return {
    type: QA_ADD_MORE_TO_COLLECTION,
    payload: { collection_id:collection_id},
  }
}
export const qaGetAddMoreToCollectionSuccess = collectionAddMoreDetails => {
  return {
    type: QA_ADD_MORE_TO_COLLECTION_SUCCESS,
    payload: collectionAddMoreDetails,
  }
}
export const qaGetAddMoreToCollectionFail = error => {
  return {
    type: QA_ADD_MORE_TO_COLLECTION_FAIL,
    payload: error,
  }
}

export const qaGetAddMoreToCollectionUpdate = (addMoreUpdateDetails, history) => {
  return {
    type: QA_ADD_MORE_TO_COLLECTION_UPDATE,
    payload: {addMoreUpdateDetails, history},
  }
}
export const qaGetAddMoreToCollectionUpdateSuccess = collectionAddMoreDetailsUpdate => {
  return {
    type: QA_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS,
    payload: collectionAddMoreDetailsUpdate,
  }
}
export const qaGetAddMoreToCollectionUpdateFail = error => {
  return {
    type: QA_ADD_MORE_TO_COLLECTION_UPDATE_FAIL,
    payload: error,
  }
}

export const qaProductForAddCollection = productsForAddCollection => {
  return {
    type: QA_GET_PRODUCTS_FOR_ADD_COLLECTION,
    payload: {productsForAddCollection},
  }
}

export const qaProductForAddCollectionSuccess = productsForAddCollection => {
  return {
    type: QA_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS,
    payload: productsForAddCollection,
  }
}

export const qaProductForAddCollectionFail = error => {
  return {
    type: QA_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL,
    payload: error,
  }
}

export const qaProductOfferForAddCollection = productsOfferForAddCollection => {
  return {
    type: QA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
    payload: {productsOfferForAddCollection},
  }
}

export const qaProductOfferForAddCollectionSuccess = productsOfferForAddCollection => {
  return {
    type: QA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS,
    payload: productsOfferForAddCollection,
  }
}

export const qaProductOfferForAddCollectionFail = error => {
  return {
    type: QA_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL,
    payload: error,
  }
}

export const qaRefreshCollectionCount = id => {
  return {
    type: QA_REFRESH_COLLECTION_COUNT,
    payload: { collection_id: id },
  }
}

export const qaRefreshCollectionCountSuccess = collection => {
  return {
    type: QA_REFRESH_COLLECTION_COUNT_SUCCESS,
    payload: collection,
  }
}

export const qaRefreshCollectionCountFail = error => {
  return {
    type: QA_REFRESH_COLLECTION_COUNT_FAIL,
    payload: error,
  }
}

export const qaGetLayOut = layout => {
  return {
    type: QA_GET_LAYOUT,
    payload: layout,
  }
}

export const qaGetLayOutSuccess = (layout) => {
  return {
    type: QA_GET_LAYOUT_SUCCESS,
    payload: { layout },
  }
}

export const qaGetLayOutFail = error => {
  return {
    type: QA_GET_LAYOUT_FAIL,
    payload: error,
  }
}

