import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_DELIVEREDON_LISTING,
  ADD_DELIVERED,
  CHANGE_DELIVERED_STATUS,
  EDIT_DELIVERED,
  UPDATE_DELIVERED,
  FETCH_DELIVERED_BY_SECTION,
  UPDATE_DELIVERED_POSITION,
} from "./actionTypes"
import {
  deliveredOnListingSuccess,
  deliveredOnListingFail,
  addDeliveredSuccess,
  addDeliveredFail,
  changeDeliveredStatusSuccess,
  changeDeliverdStatusFail,
  editDeliveredSuccess,
  editDeliveredFail,
  updateDeliveredSuccess,
  updateDeliveredFail,
  fetchDeliveredListingBySectionSuccess,
  fetchDeliveredListingBySectionFail,
  updateDeliversPositionSuccess,
  updateDeliversPositionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  deliveredOnListingApi,
  addDeliveredApi,
  changeDeliveredStatusApi,
  editDeliveredApi,
  updateDeliveredApi,
  fetchDropdownsBySectionApi,
  fetchDropDownPositionApi,
} from "../../../helpers/fakebackend_helper"

function* getDeliveredOnListing() {
  try {
    const response = yield call(deliveredOnListingApi)
    if (!response.results.delivers) {
      yield put(deliveredOnListingFail(error))
    }
    yield put(deliveredOnListingSuccess(response.results.delivers))
  } catch (error) {
    yield put(deliveredOnListingFail(error))
  }
}

function* addDeliveredOn({ payload: { deliveredOn, history } }) {
  try {
    const response = yield call(addDeliveredApi, deliveredOn)
    if (response && response.status) {
      yield put(addDeliveredSuccess(response))
      history.push("/dropdown/delivered_on/list")
    } else {
      throw response.msg ? response.msg : "Failed to add place"
    }
  } catch (error) {
    yield put(addDeliveredFail(error))
  }
}

function* onDeliveredStatusChange({ payload: { id } }) {
  try {
    const response = yield call(changeDeliveredStatusApi, {
      delivery_id: id,
    })
    if (response.status) {
      response.id = id
      yield put(changeDeliveredStatusSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to change status"
    }
  } catch (error) {
    yield put(changeDeliverdStatusFail(error))
  }
}

function* getDeliveredDetailsById({ payload: { id } }) {
  try {
    const response = yield call(editDeliveredApi, id)
    if (response && response.status) {
      yield put(editDeliveredSuccess(response.results.deliery_dtls))
    } else {
      throw response.msg ? response.msg : "Failed to edit"
    }
  } catch (error) {
    yield put(editDeliveredFail(error))
  }
}

function* onUpdateDelivered({ payload: { deliveredOnUpdate, history } }) {
  try {
    const response = yield call(updateDeliveredApi, deliveredOnUpdate)
    if (response && response.status) {
      yield put(updateDeliveredSuccess(response))
      history.push("/dropdown/delivered_on/list")
    } else {
      throw response.msg ? response.msg : "Failed to update"
    }
  } catch (error) {
    yield put(updateDeliveredFail(error))
  }
}

function* fetchDeliveredListingBySection({ payload: { data } }) {
  try {
    const response = yield call(fetchDropdownsBySectionApi, data)
    if (!response?.results?.delivers) {
      throw response.msg ? response.msg : "Failed to fetch details"
    }
    yield put(fetchDeliveredListingBySectionSuccess(response.results.delivers))
  } catch (error) {
    yield put(fetchDeliveredListingBySectionFail(error))
  }
}

function* updateDeliveredPosition({ payload: { data } }) {
  try {
    const response = yield call(fetchDropDownPositionApi, data)
    if (response && response.status) {
      yield put(updateDeliversPositionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update position"
    }
  } catch (error) {
    let msg = error ? { msg: error } : { msg: "Failed to update position" }
    yield put(updateDeliversPositionFail(msg))
  }
}

function* deliveredOnSaga() {
  yield takeEvery(GET_DELIVEREDON_LISTING, getDeliveredOnListing)
  yield takeEvery(ADD_DELIVERED, addDeliveredOn)
  yield takeEvery(CHANGE_DELIVERED_STATUS, onDeliveredStatusChange)
  yield takeEvery(EDIT_DELIVERED, getDeliveredDetailsById)
  yield takeEvery(UPDATE_DELIVERED, onUpdateDelivered)
  yield takeEvery(FETCH_DELIVERED_BY_SECTION, fetchDeliveredListingBySection)
  yield takeEvery(UPDATE_DELIVERED_POSITION, updateDeliveredPosition)
}
export default deliveredOnSaga
