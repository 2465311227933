import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Prompt } from "react-router"

//redux
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import {
  editProfileDetails,
  resetProfileFlag,
  onUpdateProfile,
  onChangePassword,
} from "../../store/actions"

import user1 from "../../assets/images/default_user.png"

const UserProfile = () => {
  //meta title
  document.title = "Profile"

  const dispatch = useDispatch()
  const [name, setname] = useState("")
  const [details, setDetails] = useState({})

  useEffect(() => {
    dispatch(editProfileDetails())
  }, [])

  const { editProfile } = useSelector(state => state.Profile)
  const update = useSelector(state => state.Profile)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: details && details.name ? details.name : "",
      short_name: details && details.short_name ? details.short_name : "",
      avatar: details && details.avatar ? details.avatar : "",
      user_role: details && details.user_role ? details.user_role : "",
      user_email: details && details.user_email ? details.user_email : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter your user name"),
      short_name: Yup.string().required("Please enter your short name"),
      user_role: Yup.string().required("Please enter role"),
      user_email: Yup.string().required("Please enter your email"),
    }),
    onSubmit: values => {
      let formData = new FormData(document.getElementById("updateUserProfile"))
      dispatch(onUpdateProfile(formData))
    },
  })

  const validationChangePassword = useFormik({
    enableReinitialize: true,
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("Please enter current password"),
      new_password: Yup.string().required("Please enter new password"),
      confirm_password: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
    }),
    onSubmit: values => {
      dispatch(onChangePassword(values))
    },
  })

  useEffect(() => {
    if (Object.keys(editProfile).length !== 0) {
      setDetails(editProfile)
      if (editProfile.avatar) {
        let user = JSON.parse(sessionStorage.getItem("authUser"))
        if (user) {
          let newData = user
          newData.name = editProfile?.name || ""
          newData.avatar = editProfile.avatar ? editProfile.avatar : null
          sessionStorage.setItem("authUser", JSON.stringify(newData))
        }
      }
    }
  }, [editProfile, details])

  const { error, msg, loading } = useSelector(state => ({
    msg: state.Profile.msg ? state.Profile.msg : "",
    error: state.Profile.error,
    loading: state.Profile.loading,
  }))

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      document.getElementById("update-password-form").reset()
      toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  return (
    <React.Fragment>
      <div className="page-content">
        <Prompt
          when={validation.dirty}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              <Card>
                {error || msg ? <ToastContainer /> : ""}
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={details && details.avatar ? details.avatar : user1}
                        alt="user image"
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{details && details.name ? details.name : ""}</h5>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change Profile</h4>

          <Card>
            <CardBody>
              <Row>
                <Col md={6}>
                  <Form
                    id="updateUserProfile"
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="form-group">
                      <Label htmlFor="form-label-userName">User Name</Label>
                      <Input
                        id="form-label-userName"
                        name="name"
                        className="form-control"
                        type="text"
                        readOnly={true}
                        autoComplete="off"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                    </div>
                    <br></br>

                    <div className="form-group">
                      <Label htmlFor="form-label-shortName">Short Name</Label>
                      <Input
                        id="form-label-shortName"
                        name="short_name"
                        className="form-control"
                        autoComplete="off"
                        type="text"
                        readOnly={true}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.short_name || ""}
                        invalid={
                          validation.touched.short_name &&
                          validation.errors.short_name
                            ? true
                            : false
                        }
                      />
                    </div>
                    <br></br>

                    <div className="form-group">
                      <Label htmlFor="form-label-userName">User Role</Label>
                      <Input
                        id="form-label-userRole"
                        name="user_role"
                        className="form-control"
                        type="text"
                        readOnly={true}
                        autoComplete="off"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.user_role || ""}
                        invalid={
                          validation.touched.user_role &&
                          validation.errors.user_role
                            ? true
                            : false
                        }
                      />
                    </div>
                    <br></br>

                    <div className="form-group">
                      <Label htmlFor="form-label-userName">User Email</Label>
                      <Input
                        id="form-label-userEmail"
                        name="user_email"
                        className="form-control"
                        type="text"
                        readOnly={true}
                        autoComplete="off"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.user_email || ""}
                        invalid={
                          validation.touched.user_email &&
                          validation.errors.user_email
                            ? true
                            : false
                        }
                      />
                    </div>
                    <br></br>

                    <div className="form-group">
                      <Label htmlFor="form-label-userName">Avatar</Label>
                      <Input
                        type="file"
                        name="avatar"
                        className="form-control"
                        placeholder="Enter avatar"
                        autoComplete="off"
                        onChange={validation.handleChange}
                      />
                    </div>
                    <div className="text-left mt-4">
                      {update && update.loading ? (
                        <Button type="submit" color="primary">
                          Updating User ...
                        </Button>
                      ) : (
                        <Button type="submit" className="btn btn-primary w-md">
                          Update User
                        </Button>
                      )}
                    </div>
                  </Form>
                </Col>
                <Col md={6}>
                  <Form
                    id="update-password-form"
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validationChangePassword.handleSubmit()
                      return false
                    }}
                  >
                    <div className="form-group">
                      <Label htmlFor="current-password">
                        Current Password
                        <span className="text-danger"> *</span>
                      </Label>
                      <Input
                        id="old_password"
                        name="old_password"
                        className={
                          validationChangePassword.touched.old_password &&
                          validationChangePassword.errors.old_password
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Enter your current password"
                        type="password"
                        onChange={validationChangePassword.handleChange}
                        onBlur={validationChangePassword.handleBlur}
                        value={
                          validationChangePassword.values.old_password || ""
                        }
                        invalid={
                          validationChangePassword.touched.old_password &&
                          validationChangePassword.errors.old_password
                            ? true
                            : false
                        }
                      />
                      {validationChangePassword.touched.old_password &&
                      validationChangePassword.errors.old_password ? (
                        <FormFeedback type="invalid">
                          {validationChangePassword.errors.old_password}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <br />

                    <div className="form-group">
                      <Label htmlFor="new-password">
                        New Password
                        <span className="text-danger"> *</span>
                      </Label>
                      <Input
                        id="new-password"
                        name="new_password"
                        className={
                          validationChangePassword.touched.new_password &&
                          validationChangePassword.errors.new_password
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Enter your new password"
                        type="password"
                        onChange={validationChangePassword.handleChange}
                        onBlur={validationChangePassword.handleBlur}
                        value={
                          validationChangePassword.values.new_password || ""
                        }
                        invalid={
                          validationChangePassword.touched.new_password &&
                          validationChangePassword.errors.new_password
                            ? true
                            : false
                        }
                      />
                      {validationChangePassword.touched.new_password &&
                      validationChangePassword.errors.new_password ? (
                        <FormFeedback type="invalid">
                          {validationChangePassword.errors.new_password}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <br></br>

                    <div className="form-group">
                      <Label htmlFor="confirm-password">
                        Confirm Password
                        <span className="text-danger"> *</span>
                      </Label>
                      <Input
                        id="confirm-password"
                        name="confirm_password"
                        className={
                          validationChangePassword.touched.confirm_password &&
                          validationChangePassword.errors.confirm_password
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Enter confirm password"
                        type="password"
                        onChange={validationChangePassword.handleChange}
                        onBlur={validationChangePassword.handleBlur}
                        value={
                          validationChangePassword.values.confirm_password || ""
                        }
                        invalid={
                          validationChangePassword.touched.confirm_password &&
                          validationChangePassword.errors.confirm_password
                            ? true
                            : false
                        }
                      />
                      {validationChangePassword.touched.confirm_password &&
                      validationChangePassword.errors.confirm_password ? (
                        <FormFeedback type="invalid">
                          {validationChangePassword.errors.confirm_password}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="text-left mt-4">
                      {update && update.loading ? (
                        <Button type="submit" color="primary">
                          Updating Password ...
                        </Button>
                      ) : (
                        <Button type="submit" className="btn btn-primary w-md">
                          Update Password
                        </Button>
                      )}
                    </div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
