import React from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Form,
  Input,
  FormFeedback,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
  bdAddNewOffer,
  bdDeliveredOnListing,
  bdEnvironmentalAndHealthCertificationListing,
  bdHeaveyColliListing,
  bdIncotermCityListing,
  bdMaterialStandardListing,
  bdPackagingListing,
  bdOfferStatusListing,
  bdPackingStandardListing,
  bdSupplierNameListing,
} from "../../../store/actions"

const BusinessDeveloperAddOfferNew = props => {
  document.title = "Create"
  const { id, prod_no } = useParams()
  const dispatch = useDispatch()

  const defaultMargin = 0.8 // 20% margin =>  (100-20)/100

  const [accordianOpen, setAccordianOpen] = useState("")
  const toggle = id => {
    if (accordianOpen === id) {
      setAccordianOpen("")
    } else {
      setAccordianOpen(id)
    }
  }

  useEffect(() => {
    dispatch(bdSupplierNameListing())
    dispatch(bdOfferStatusListing())
    dispatch(bdIncotermCityListing())
    dispatch(bdMaterialStandardListing())
    dispatch(bdPackingStandardListing())
    dispatch(bdEnvironmentalAndHealthCertificationListing())
    dispatch(bdPackagingListing())
    dispatch(bdDeliveredOnListing())
    dispatch(bdHeaveyColliListing())
  }, [])

  const [errorsArr, SetErrorsArr] = useState([]) // validation section

  const [details, setDetails] = useState({}) // this is for calculation

  const [bdSupplierName, setBdSupplierName] = useState([])

  const [bdOfferStatus, setBdOfferStatus] = useState([])

  const [bdIncotermCity, setBdIncotermCity] = useState([])

  const [bdMaterialStandard, setBdMaterialStandard] = useState([])

  const [bdPackingStandard, setBdPackingStandard] = useState([])

  const [
    bdEnvironmentalAndHealthCertification,
    setBdEnvironmentalAndHealthCertification,
  ] = useState([])

  const [bdPackaging, setBdPackaging] = useState([])

  const [bdDeliveredOn, setBdDeliveredOn] = useState([])

  const [bdHeaveyColli, setBdHeaveyColli] = useState([])

  // data from redux (reducer)
  const products = useSelector(state => state.bdProduct)
  const bdSupplierNameData = useSelector(
    state => state.bdSupplierName.bdSupplierName
  ) ///1st supplierName is from reducer
  const bdOfferStatusData = useSelector(
    state => state.bdOfferStatus.bdOfferStatus
  )
  const bdIncotermCityData = useSelector(
    state => state.bdIncotermCity.bdIncotermCity
  )
  const bdMaterialStandardData = useSelector(
    state => state.bdMaterialStandard.bdMaterialStandard
  )
  const bdPackingStandardData = useSelector(
    state => state.bdPackingStandard.bdPackingStandard
  )
  const bdEnvironmentalAndHealthCertificationData = useSelector(
    state =>
      state.bdEnvironmentalAndHealthCertification
        .bdEnvironmentalAndHealthCertification
  )

  const bdPackagingData = useSelector(state => state.bdPackaging.bdPackaging)

  const bdDeliveredOnData = useSelector(
    state => state.bdDeliveredOn.bdDeliveredOn
  )
  const bdHeaveyColliData = useSelector(
    state => state.bdHeaveyColli.bdHeaveyColli
  )

  useEffect(() => {
    let prods = []
    if (products && products.product && products.product.length > 0) {
      prods = products.product.filter(product => product.prod_id == id)
    }
    if (prods.length > 0) {
      setDetails(prods[0])
    } else {
      props.history.push("/products/list")
    }

    setBdSupplierName(bdSupplierNameData)
    setBdOfferStatus(bdOfferStatusData)
    setBdIncotermCity(bdIncotermCityData)
    setBdMaterialStandard(bdMaterialStandardData)
    setBdPackingStandard(bdPackingStandardData)
    setBdEnvironmentalAndHealthCertification(
      bdEnvironmentalAndHealthCertificationData
    )
    setBdPackaging(bdPackagingData)
    setBdDeliveredOn(bdDeliveredOnData)
    setBdHeaveyColli(bdHeaveyColliData)
  }, [
    bdSupplierNameData,
    bdOfferStatusData,
    bdIncotermCityData,
    bdMaterialStandardData,
    bdPackingStandardData,
    bdEnvironmentalAndHealthCertificationData,
    bdPackagingData,
    bdDeliveredOnData,
    bdHeaveyColliData,
  ])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      prod_id: id,
      offer_status_id: "1",
      vendor_article_no: "",
      supplier_id: "",
      production_time: "",
      incoterm_city_id: "",
      material_standard_id: "",
      packing_standard_id: "",
      env_health_id: "",
      non_compliance: "",
      sbn_comments: "",
      quality_control: "",
      color_code_gloss: "",
      purchase_price_fsc: "",
      sales_price_fsc: "",
      sales_margin_fsc: "",
      purchasing_price_comments: "",
      full_truck: "",
      container: "",
      moq: "",
      net_weight: "",
      gross_weight: "",
      packaging_id: "",
      delivered_on: "",
      heavy_colli_id: "",
      units_per_box: "",
      bom_per_article: "",
      master_cardboard_box: "",
      packing_comments: "",
    },
    validationSchema: Yup.object({
      offer_status_id: Yup.string().required(
        "Offer Status- This field is required"
      ),

      supplier_id: Yup.string().required(
        "Supplier Name- This field is required"
      ),

      incoterm_city_id: Yup.string().required(
        "Incoterm City- This field is required"
      ),

      production_time: Yup.string()
        .required(
          "Production Time- This field is required and this is must be a number."
        )
        .matches(
          /^[0-9]+$/,
          "Positive numbers are allowed without dots, negetive or decimal"
        ),

      material_standard_id: Yup.string().required(
        "Material Standard- This field is required"
      ),
      packing_standard_id: Yup.string().required(
        "Packing Standard- This field is required"
      ),
      env_health_id: Yup.string().required(
        "Environmental and Health Certification- This field is required"
      ),

      purchase_price_fsc: Yup.number().required("Purchase FSC is required"),
     
      sales_price_fsc: Yup.number(),
      sales_margin_fsc: Yup.number(),

      full_truck: Yup.number().required(
        "Full Truck/40HC- This field is required"
      ),
      container: Yup.number().required(
        "20ST Container- This field is required"
      ),
      moq: Yup.number().required("MOQ- This field is required"),
      net_weight: Yup.number().required("Net Weight- This field is required"),
      gross_weight: Yup.number().required(
        "Gross Weight- This field is required"
      ),
      packaging_id: Yup.string().required("Packaging- This field is required"),
      delivered_on: Yup.string().required(
        "Delivered On- This field is required"
      ),
      heavy_colli_id: Yup.string().required(
        "Heavy Colli- This field is required"
      ),
      units_per_box: Yup.number().required(
        "Number of Units Per Box- This field is required"
      ),
      bom_per_article: Yup.number().required(
        "BOM Per Article- This field is required"
      ),
    }),
    onSubmit: values => {
      dispatch(bdAddNewOffer(values, props.history))
    },
  })

  let validationSections = [
    {
      section: "Offer Details",
      errorPresent: false,
      children: [
        { name: "offer_status_id", errorMessage: null },
        { name: "supplier_id", errorMessage: null },
        { name: "incoterm_city_id", errorMessage: null },
        { name: "production_time", errorMessage: null },
        { name: "material_standard_id", errorMessage: null },
        { name: "packing_standard_id", errorMessage: null },
        { name: "env_health_id", errorMessage: null },
      ],
    },
    {
      section: "Offer Price",
      errorPresent: false,
      children: [
        { name: "purchase_price_fsc", errorMessage: null },
        { name: "sales_price_fsc", errorMessage: null },
      ],
    },
    {
      section: "Offer Packing",
      errorPresent: false,
      children: [
        { name: "full_truck", errorMessage: null },
        { name: "container", errorMessage: null },
        { name: "moq", errorMessage: null },
        { name: "net_weight", errorMessage: null },
        { name: "gross_weight", errorMessage: null },
        { name: "packaging_id", errorMessage: null },
        { name: "delivered_on", errorMessage: null },
        { name: "heavy_colli_id", errorMessage: null },
        { name: "units_per_box", errorMessage: null },
        { name: "bom_per_article", errorMessage: null },
      ],
    },
  ]

  useEffect(() => {
    if (
      Object.keys(validation.touched).length &&
      Object.keys(validation.errors).length
    ) {
      let currentValidation = [...validationSections]
      for (const error in validation.errors) {
        currentValidation.forEach(section => {
          // Find the matching child by name
          const child = section.children.find(c => c.name === error)
          // If a matching child is found, update its errorMessage
          if (child && validation?.errors[error] !== "") {
            child.errorMessage = validation.errors[error]
            section.errorPresent = true
          } else if (child && validation?.errors[error] === "") {
            child.errorMessage = null
          }
        })
        SetErrorsArr(currentValidation)
      }
    } else {
      SetErrorsArr([])
    }
  }, [validation.errors, validation.touched])

  function updateDecimalPortion(data = 0.0) {
    data = parseFloat(data)
    data = data.toFixed(2)

    let updated_number = 0.0
    let left_portion = parseFloat((data + "").split(".")[0])
    let decimal_portion = parseFloat((data + "").split(".")[1])
    if (left_portion >= 0 && left_portion <= 30) {
      // 0-30
      if (decimal_portion > 0 && decimal_portion <= 25) {
        updated_number = left_portion.toString() + ".25"
      } else if (decimal_portion > 25 && decimal_portion <= 50) {
        // 26-49 - 50
        updated_number = left_portion.toString() + ".50"
      } else if (decimal_portion > 50 && decimal_portion <= 75) {
        // 51-74 - 75
        updated_number = left_portion.toString() + ".75"
      } else if (decimal_portion > 75 && decimal_portion <= 99) {
        // 76-99 - 100
        updated_number = (left_portion + 1).toFixed(2)
      } else {
        updated_number = left_portion.toFixed(2)
      }
    } else if (left_portion > 30 && left_portion <= 50) {
      // 31-50
      if (decimal_portion > 0 && decimal_portion <= 50) {
        updated_number = left_portion.toString() + ".50"
      } else {
        updated_number = (left_portion + 1).toFixed(2)
      }
    } else {
      // left portion greater than 50
      if (decimal_portion > 0) {
        updated_number = (left_portion + 1).toFixed(2)
      } else {
        updated_number = left_portion.toFixed(2)
      }
    }
    updated_number = parseFloat(updated_number).toFixed(2)
    return updated_number
  }

  function yearly_purchase_fsc(e) {
    let purchase_price_fsc = isNaN(e.target.value) ? 0 : e.target.value
    let optionValue = purchase_price_fsc * details.yearly_forecast
    let sales_price_fsc1 = purchase_price_fsc / defaultMargin
    let yearly_sales_fsc = sales_price_fsc1 * details.yearly_forecast

    yearly_sales_fsc = yearly_sales_fsc.toFixed(2)
    let sales_price_fsc = sales_price_fsc1.toFixed(2)

    sales_price_fsc = updateDecimalPortion(sales_price_fsc)
    let sales_margin_fsc =
      ((sales_price_fsc - purchase_price_fsc) / sales_price_fsc) * 100
    validation.setFieldValue(
      "yearly_purchase_fsc",
      parseFloat(optionValue).toFixed(2)
    )
    validation.setFieldValue(
      "sales_price_fsc",
      parseFloat(sales_price_fsc).toFixed(2)
    )
    validation.setFieldValue(
      "sales_margin_fsc",
      parseFloat(sales_margin_fsc).toFixed(1)
    )
    validation.setFieldValue(
      "yearly_sales_fsc",
      parseFloat(yearly_sales_fsc).toFixed(2)
    )
  }

  function yearly_sales_fsc(e) {
    let number = isNaN(e.target.value) ? 0 : e.target.value
    let optionValue = number * details.yearly_forecast
    validation.setFieldValue("yearly_sales_fsc", optionValue.toFixed(2))
    sales_margin_fsc()
  }

  function sales_margin_fsc() {
    let purchase_price_fsc = document.getElementsByClassName(
      "purchase_price_fsc"
    )[0].value
      ? parseFloat(
          document.getElementsByClassName("purchase_price_fsc")[0].value
        )
      : 0
    let sales_price_fsc = document.getElementsByClassName("sales_price_fsc")[0]
      .value
      ? parseFloat(document.getElementsByClassName("sales_price_fsc")[0].value)
      : 0
    if (sales_price_fsc > 0) {
      let sales_margin_fsc =
        ((sales_price_fsc - purchase_price_fsc) / sales_price_fsc) * 100
      validation.setFieldValue("sales_margin_fsc", sales_margin_fsc.toFixed(1))
    } else {
      validation.setFieldValue("sales_margin_fsc", 0.0)
    }
  }

  function yearly_purchase_non_fsc(e) {
    let purchase_price_non_fsc = isNaN(e.target.value) ? 0 : e.target.value

    let optionValue = purchase_price_non_fsc * details.yearly_forecast
    validation.setFieldValue("yearly_purchase_non_fsc", optionValue)

    let sales_price_non_fsc = purchase_price_non_fsc / defaultMargin
    sales_price_non_fsc = updateDecimalPortion(sales_price_non_fsc)

    let sales_margin_non_fsc =
      ((sales_price_non_fsc - purchase_price_non_fsc) / sales_price_non_fsc) *
      100

    let yearly_sales_non_fsc = sales_margin_non_fsc * details.yearly_forecast
    yearly_sales_non_fsc = yearly_sales_non_fsc.toFixed(2)

    validation.setFieldValue(
      "yearly_purchase_non_fsc",
      parseFloat(optionValue).toFixed(2)
    )
    validation.setFieldValue(
      "sales_price_non_fsc",
      parseFloat(sales_price_non_fsc).toFixed(2)
    )
    validation.setFieldValue(
      "sales_margin_non_fsc",
      parseFloat(sales_margin_non_fsc).toFixed(1)
    )
    validation.setFieldValue(
      "yearly_sales_non_fsc",
      parseFloat(yearly_sales_non_fsc).toFixed(2)
    )
    // sales_margin_non_fsc()
  }

  function yearly_sales_non_fsc(e) {
    let number = isNaN(e.target.value) ? 0 : e.target.value
    let optionValue = number * details.yearly_forecast
    validation.setFieldValue("yearly_sales_non_fsc", optionValue)
    sales_margin_non_fsc()
  }

  function sales_margin_non_fsc() {
    let purchase_price_non_fsc = document.getElementsByClassName(
      "purchase_price_non_fsc"
    )[0].value
      ? parseFloat(
          document.getElementsByClassName("purchase_price_non_fsc")[0].value
        )
      : 0
    let sales_price_non_fsc = document.getElementsByClassName(
      "sales_price_non_fsc"
    )[0].value
      ? parseFloat(
          document.getElementsByClassName("sales_price_non_fsc")[0].value
        )
      : 0
    if (sales_price_non_fsc > 0) {
      let sales_margin_non_fsc =
        ((sales_price_non_fsc - purchase_price_non_fsc) / sales_price_non_fsc) *
        100
      validation.setFieldValue(
        "sales_margin_non_fsc",
        sales_margin_non_fsc.toFixed(1)
      )
    } else {
      validation.setFieldValue("sales_margin_non_fsc", 0.0)
    }
  }

  const bdOffer = useSelector(state => state.bdOffer) // from reducer.
  const { error, msg } = useSelector(state => ({
    error: state.bdOffer.error,
    msg: state.bdOffer.msg ? state.bdOffer.msg : "",
  }))

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  return (
    <React.Fragment>
      <div style={{ paddingTop: "70px" }} className="page-content">
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="CREATE OFFERS" />
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  {msg || error ? (
                    <ToastContainer className="Toastify__close-button" />
                  ) : (
                    ""
                  )}
                  {prod_no ? (
                    <h4 className="card-title mb-4">
                      Product Number :- {prod_no}
                    </h4>
                  ) : (
                    ""
                  )}
                  <Form
                    id="addOfferForm"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Accordion open={accordianOpen} toggle={toggle}>
                      <AccordionItem>
                        <AccordionHeader targetId="1">
                          <b>Offer Details</b>
                        </AccordionHeader>
                        <AccordionBody accordionId="1">
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-offer-status">
                                  Offer Status{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="offer_status_id"
                                  id="add-offer-offer-status"
                                  className={
                                    validation.touched.offer_status_id &&
                                    validation.errors.offer_status_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.offer_status_id || ""
                                  }
                                >
                                  <option value="">Choose...</option>
                                  {bdOfferStatus &&
                                    bdOfferStatus.map((bdOfferStatus, ofs) => (
                                      <option
                                        key={"ofs" + ofs}
                                        value={bdOfferStatus.id}
                                      >
                                        {bdOfferStatus.offer_status}
                                      </option>
                                    ))}
                                </select>
                                {validation.touched.offer_status_id &&
                                validation.errors.offer_status_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.offer_status_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-Vendor-article-number">
                                  Vendor Article Number
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="vendor_article_no"
                                  type="text"
                                  className="form-control"
                                  id="add-offer-Vendor-article-number"
                                  // placeholder="Enter your vender article number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.vendor_article_no || ""
                                  }
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-supplier-id">
                                  Supplier Name{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="supplier_id"
                                  id="add-offer-supplier-id"
                                  className={
                                    validation.touched.supplier_id &&
                                    validation.errors.supplier_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.supplier_id || ""}
                                >
                                  <option value="">Choose...</option>
                                  {bdSupplierName &&
                                    bdSupplierName.map(
                                      (bdSupplierName, snk) => (
                                        <option
                                          key={"snk" + snk}
                                          value={bdSupplierName.id}
                                        >
                                          {bdSupplierName.name}
                                        </option>
                                      )
                                    )}
                                </select>
                                {validation.touched.supplier_id &&
                                validation.errors.supplier_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.supplier_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-production-input1">
                                  Production Time{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="production_time"
                                  type="number"
                                  className={
                                    validation.touched.production_time &&
                                    validation.errors.production_time
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-production-input1"
                                  // placeholder="Enter production time in number of days"
                                  onChange={e => {
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.production_time || ""
                                  }
                                />
                                {validation.touched.production_time &&
                                validation.errors.production_time ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.production_time}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-incoterm-city">
                                  Incoterm City (port){" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className={
                                    validation.touched.incoterm_city_id &&
                                    validation.errors.incoterm_city_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="incoterm_city_id"
                                  id="add-offer-incoterm-city"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.incoterm_city_id || ""
                                  }
                                >
                                  <option value="">Choose...</option>
                                  {bdIncotermCity &&
                                    bdIncotermCity.map(
                                      (bdIncotermCity, icc) => (
                                        <option
                                          key={"icc" + icc}
                                          value={bdIncotermCity.id}
                                        >
                                          {bdIncotermCity.city}
                                        </option>
                                      )
                                    )}
                                </select>
                                {validation.touched.incoterm_city_id &&
                                validation.errors.incoterm_city_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.incoterm_city_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-material-standard">
                                  Material Standard{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className={
                                    validation.touched.material_standard_id &&
                                    validation.errors.material_standard_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="material_standard_id"
                                  id="add-offer-material-standard"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.material_standard_id || ""
                                  }
                                >
                                  <option value="">Choose...</option>
                                  {bdMaterialStandard &&
                                    bdMaterialStandard.map(
                                      (bdMaterialStandard, ms) => (
                                        <option
                                          key={"ms" + ms}
                                          value={bdMaterialStandard.id}
                                        >
                                          {bdMaterialStandard.material_standard}
                                        </option>
                                      )
                                    )}
                                </select>
                                {validation.touched.material_standard_id &&
                                validation.errors.material_standard_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.material_standard_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-packing-standard">
                                  Packing Standard{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className={
                                    validation.touched.packing_standard_id &&
                                    validation.errors.packing_standard_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="packing_standard_id"
                                  id="add-offer-packing-standard"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.packing_standard_id || ""
                                  }
                                >
                                  <option value="">Choose...</option>
                                  {bdPackingStandard &&
                                    bdPackingStandard.map(
                                      (bdPackingStandard, ps) => (
                                        <option
                                          key={"ps" + ps}
                                          value={bdPackingStandard.id}
                                        >
                                          {bdPackingStandard.packing_standard}
                                        </option>
                                      )
                                    )}
                                </select>
                                {validation.touched.packing_standard_id &&
                                validation.errors.packing_standard_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.packing_standard_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-env&hlth-certification">
                                  Environmental and Health Certification{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className={
                                    validation.touched.env_health_id &&
                                    validation.errors.env_health_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="env_health_id"
                                  id="add-offer-env&hlth-certification"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.env_health_id || ""}
                                >
                                  <option value="">Choose...</option>
                                  {bdEnvironmentalAndHealthCertification &&
                                    bdEnvironmentalAndHealthCertification.map(
                                      (
                                        bdEnvironmentalAndHealthCertification,
                                        enhc
                                      ) => (
                                        <option
                                          key={"enhc" + enhc}
                                          value={
                                            bdEnvironmentalAndHealthCertification.id
                                          }
                                        >
                                          {
                                            bdEnvironmentalAndHealthCertification.certs
                                          }
                                        </option>
                                      )
                                    )}
                                </select>
                                {validation.touched.env_health_id &&
                                validation.errors.env_health_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.env_health_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="12">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-compliance-input1">
                                  NON-compliance
                                </Label>
                                <textarea
                                  name="non_compliance"
                                  id="basicpill-compliance-input1"
                                  className="form-control"
                                  rows="3"
                                  // placeholder="Free Text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.non_compliance || ""}
                                  onKeyDown={e => {
                                    e.key === "Enter" && e.preventDefault()
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="12">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-comments-input1">
                                  SBN comments
                                </Label>
                                <textarea
                                  name="sbn_comments"
                                  id="basicpill-comments-input1"
                                  className="form-control"
                                  rows="3"
                                  // placeholder="Free Text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.sbn_comments || ""}
                                  onKeyDown={e => {
                                    e.key === "Enter" && e.preventDefault()
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="12">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-quality-input1">
                                Product and Material Details
                                </Label>
                                <textarea
                                  name="quality_control"
                                  id="basicpill-quality-input1"
                                  className="form-control"
                                  rows="3"
                                  // placeholder="Free Text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.quality_control || ""
                                  }
                                  onKeyDown={e => {
                                    e.key === "Enter" && e.preventDefault()
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="12">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-color-input1">
                                  Color code and Gloss
                                </Label>
                                <textarea
                                  name="color_code_gloss"
                                  id="basicpill-color-input1"
                                  className="form-control"
                                  rows="3"
                                  // placeholder="Free Text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.color_code_gloss || ""
                                  }
                                  onKeyDown={e => {
                                    e.key === "Enter" && e.preventDefault()
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader targetId="2">
                          <b>Offer Price</b>
                        </AccordionHeader>
                        <AccordionBody accordionId="2">
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <Label htmlFor="basicpill-price-input5">
                                  Purchase Price (FSC)
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="purchase_price_fsc"
                                  type="text"
                                  autoComplete="off"
                                  className={
                                    validation.touched.purchase_price_fsc &&
                                    validation.errors.purchase_price_fsc
                                      ? "purchase_price_fsc is-invalid"
                                      : "purchase_price_fsc"
                                  }
                                  onChange={e => (
                                    validation.handleChange(e),
                                    yearly_purchase_fsc(e)
                                  )}
                                  id="basicpill-price-input5"
                                  // placeholder="Enter purchase price (FSC)."
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.purchase_price_fsc || ""
                                  }
                                />
                                {validation.touched.purchase_price_fsc &&
                                validation.errors.purchase_price_fsc ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.purchase_price_fsc}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            {/* <Col>
                              <div className="mb-3">
                                <Label htmlFor="basicpill-Yearly-input6">
                                  Yearly Purchase (FSC)
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="yearly_purchase_fsc"
                                  type="text"
                                  autoComplete="off"
                                  readOnly={true}
                                  disabled
                                  className={
                                    validation.touched.yearly_purchase_fsc &&
                                    validation.errors.yearly_purchase_fsc
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-year-input6"
                                  // placeholder="Enter yearly price (FSC)"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.yearly_purchase_fsc || ""
                                  }
                                />
                                {validation.touched.yearly_purchase_fsc &&
                                validation.errors.yearly_purchase_fsc ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.yearly_purchase_fsc}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col> */}
                          </Row>
                          {/* <Row>
                            <Col>
                              <div className="mb-3">
                                <Label
                                  htmlFor="basicpill-purchase-price-input10"
                                  className="mb-3"
                                >
                                  Purchase Price (NON FSC)
                                </Label>
                                <Input
                                  name="purchase_price_non_fsc"
                                  type="text"
                                  autoComplete="off"
                                  className="form-control"
                                  id="basicpill-purchase-price-input10"
                                  // placeholder="Enter purchase price (NON FSC)"
                                  onChange={e => {
                                    validation.handleChange(e)
                                    yearly_purchase_non_fsc(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.purchase_price_non_fsc ||
                                    ""
                                  }
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="mb-3">
                                <Label
                                  htmlFor="basicpill-yearly-price-non-input10"
                                  className="mb-3"
                                >
                                  Yearly Purchase (NON FSC)
                                </Label>
                                <Input
                                  name="yearly_purchase_non_fsc"
                                  type="text"
                                  autoComplete="off"
                                  readOnly={true}
                                  disabled
                                  className="form-control"
                                  id="basicpill-yearly-purchase-non-input10"
                                  // placeholder="Enter yearly price (NON FSC)"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.yearly_purchase_non_fsc ||
                                    ""
                                  }
                                />
                              </div>
                            </Col>
                          </Row> */}
                          <Row>
                            <Col lg="12">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-com-input1">
                                  Purchasing Price Comments
                                </Label>
                                <textarea
                                  name="purchasing_price_comments"
                                  id="basicpill-com-input1"
                                  className="form-control"
                                  rows="3"
                                  // placeholder="Enter Text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values
                                      .purchasing_price_comments || ""
                                  }
                                  onKeyDown={e => {
                                    e.key === "Enter" && e.preventDefault()
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader targetId="3">
                          <b>Offer Packing</b>
                        </AccordionHeader>
                        <AccordionBody accordionId="3">
                          <Row>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-truck-input11">
                                  Full Truck/40HC
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="full_truck"
                                  type="text"
                                  className={
                                    validation.touched.full_truck &&
                                    validation.errors.full_truck
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-truck-input11"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.full_truck || ""}
                                />
                                {validation.touched.full_truck &&
                                validation.errors.full_truck ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.full_truck}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-20st-input11">
                                  20ST Container
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="container"
                                  type="text"
                                  className={
                                    validation.touched.container &&
                                    validation.errors.container
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-20st-input11"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.container || ""}
                                />
                                {validation.touched.container &&
                                validation.errors.container ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.container}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-moq-input11">
                                  MOQ
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="moq"
                                  type="text"
                                  className={
                                    validation.touched.moq &&
                                    validation.errors.moq
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-moq-input11"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.moq || ""}
                                />
                                {validation.touched.moq &&
                                validation.errors.moq ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.moq}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-net-input11">
                                  Net Weight
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="net_weight"
                                  type="text"
                                  className={
                                    validation.touched.net_weight &&
                                    validation.errors.net_weight
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-net-input11"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.net_weight || ""}
                                />
                                {validation.touched.net_weight &&
                                validation.errors.net_weight ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.net_weight}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-gross-input12">
                                  Gross Weight
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="gross_weight"
                                  type="text"
                                  className={
                                    validation.touched.gross_weight &&
                                    validation.errors.gross_weight
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-gross-input12"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.gross_weight || ""}
                                />
                                {validation.touched.gross_weight &&
                                validation.errors.gross_weight ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.gross_weight}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-packaging">
                                  Packaging{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className={
                                    validation.touched.packaging_id &&
                                    validation.errors.packaging_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="packaging_id"
                                  id="add-offer-packaging"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.packaging_id || ""}
                                >
                                  <option value="">Choose...</option>
                                  {bdPackaging &&
                                    bdPackaging.map((bdPackaging, pck) => (
                                      <option
                                        key={"pck" + pck}
                                        value={bdPackaging.id}
                                      >
                                        {bdPackaging.packaging}
                                      </option>
                                    ))}
                                </select>
                                {validation.touched.packaging_id &&
                                validation.errors.packaging_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.packaging_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-delivered-on">
                                  Delivered On{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className={
                                    validation.touched.delivered_on &&
                                    validation.errors.delivered_on
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="delivered_on"
                                  id="add-offer-delivered-on"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.delivered_on || ""}
                                >
                                  <option value="">Choose...</option>
                                  {bdDeliveredOn &&
                                    bdDeliveredOn.map((bdDeliveredOn, dn) => (
                                      <option
                                        key={"dn" + dn}
                                        value={bdDeliveredOn.id}
                                      >
                                        {bdDeliveredOn.delivery_name}
                                      </option>
                                    ))}
                                </select>
                                {validation.touched.delivered_on &&
                                validation.errors.delivered_on ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.delivered_on}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-heavey-colli">
                                  Heavy Colli{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className={
                                    validation.touched.heavy_colli_id &&
                                    validation.errors.heavy_colli_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="heavy_colli_id"
                                  id="add-offer-heavey-colli"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.heavy_colli_id || ""}
                                >
                                  <option value="">Choose...</option>
                                  {bdHeaveyColli &&
                                    bdHeaveyColli.map((bdHeaveyColli, hc) => (
                                      <option
                                        key={"hc" + hc}
                                        value={bdHeaveyColli.id}
                                      >
                                        {bdHeaveyColli.heavy_collis}
                                      </option>
                                    ))}
                                </select>
                                {validation.touched.heavy_colli_id &&
                                validation.errors.heavy_colli_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.heavy_colli_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-npb-input12">
                                  Number of Units per Box
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="units_per_box"
                                  type="text"
                                  className={
                                    validation.touched.units_per_box &&
                                    validation.errors.units_per_box
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpil-npb-input12"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.units_per_box || ""}
                                />
                                {validation.touched.units_per_box &&
                                validation.errors.units_per_box ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.units_per_box}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-bom-input12">
                                  BOM per article
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="bom_per_article"
                                  type="text"
                                  className={
                                    validation.touched.bom_per_article &&
                                    validation.errors.bom_per_article
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-bom-input12"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.bom_per_article || ""
                                  }
                                />
                                {validation.touched.bom_per_article &&
                                validation.errors.bom_per_article ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.bom_per_article}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-mcb-input12">
                                  Master Cardboard Box
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="master_cardboard_box"
                                  type="text"
                                  className="form-control"
                                  id="basicpill-mcb-input12"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.master_cardboard_box || ""
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-pc-input1">
                                  Packing comments
                                </Label>
                                <textarea
                                  name="packing_comments"
                                  id="basicpill-pc-input1"
                                  className="form-control"
                                  rows="3"
                                  // placeholder="Free Text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.packing_comments || ""
                                  }
                                  onKeyDown={e => {
                                    e.key === "Enter" && e.preventDefault()
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </AccordionBody>
                      </AccordionItem>
                    </Accordion>
                    <br />
                    <Row>
                      <Col>
                        <Button
                          type="submit"
                          className="btn btn-primary w-md"
                          disabled={bdOffer && bdOffer.loading ? true : false}
                        >
                          {bdOffer && bdOffer.loading
                            ? "Adding Offer"
                            : "Add Offer"}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <section className="error-message">
                    {errorsArr && errorsArr.length
                      ? errorsArr.map((er, ei) => {
                          if (er.errorPresent) {
                            return (
                              <ul key={`ei_` + ei}>
                                <li className="text-danger">
                                  <b>{er.section}</b>
                                </li>
                                <ul>
                                  {er.children.map((ce, ci) => {
                                    if (ce.errorMessage) {
                                      return (
                                        <li
                                          key={`ce_` + ci}
                                          className="text-danger"
                                        >
                                          {ce.errorMessage}
                                        </li>
                                      )
                                    }
                                  })}
                                </ul>
                              </ul>
                            )
                          }
                        })
                      : null}
                  </section>

                  {/* below code will be useful when we do not need the validation section.*/}
                  {/* {Object.keys(validation.errors).some(
                    key => validation.touched[key] && !validation.values[key]
                  ) && (
                    <div className="error-message">
                      <ul>
                        {Object.keys(validation.errors).map((key, index) => {
                          if (
                            validation.touched[key] &&
                            !validation.values[key]
                          ) {
                            return (
                              <li key={index}>{validation.errors[key]}.</li>
                            )
                          }
                          return null
                        })}
                      </ul>
                    </div>
                  )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BusinessDeveloperAddOfferNew
