import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import React, { useState, useRef, useEffect, useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import ProductImageRenderer from "../../Products/Co-ordinator/co-ProductImageRenderer"
import BtnCellRenderer from "./co-BtnCellRenderer.jsx"
import ActiveInactive from "./co-ActiveInactive.jsx"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  Row,
  Col,
  Container,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import {
  coOffersListing,
  coDeleteOffer,
  coChangeOfferStatus,
} from "../../../store/actions"

function CoOfferList() {
  let key = `${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`
  LicenseManager.setLicenseKey(key)
  document.title = "Offer List"

  const gridRef = useRef()

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      filter: true,
      minWidth: 200,
      resizable: true,
    }),
    []
  )

  const rowHeight = 150
  const dispatch = useDispatch()

  const [offersData, setOffersData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [currentOffer, setCurrentOffer] = useState(undefined)

  let currentUser = sessionStorage.getItem("authUser")
  if (currentUser) {
    currentUser = JSON.parse(sessionStorage.getItem("authUser"))
  }
  const columnDefination = [
    {
      field: "action",
      cellRenderer: "BtnCellRenderer",
      maxWidth: 100,
      cellRendererSelector: params => {
        const buttons = {
          component: BtnCellRenderer,
          params: {
            deleteOfferConfirmation: updateModalState,
            userType: currentUser?.user_role_short,
          },
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Status",
      field: "status",
      maxWidth: 100,
      cellRenderer: "ActiveInactive",
      cellRendererSelector: params => {
        const buttons = {
          component: ActiveInactive,
          params: { callChangeOfferStatus: callChangeOfferStatus },
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product No",
      field: "prod_no",
    },
    {
      headerName: "Material Desc",
      field: "material_desc",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Product Category",
      field: "prod_category",
    },
    {
      headerName: "Product Folder",
      field: "prod_folder",
      maxWidth: 150,
      cellRenderer: params => {
        if (params.data.prod_folder) {
          return (
            <a
              className="text-center cursor-pointer font_size_medium"
              target="_blank"
              rel="noreferrer"
              href={params.data.prod_folder}
            >
              Click here
            </a>
          )
        } else {
          return undefined
        }
      },
    },
    {
      headerName: "Product Image 1",
      field: "img1",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 2",
      field: "img2",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 3",
      field: "img3",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 4",
      field: "img4",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 5",
      field: "img5",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Supplier Name",
      field: "supplier_name",
    },
    {
      headerName: "Production Time",
      field: "production_time",
    },
    {
      headerName: "Incoterm City(port)",
      field: "incoterm_city",
    },
    {
      headerName: "Offer Status",
      field: "offer_status",
    },
    {
      headerName: "Created By",
      field: "created_by",
    },
    {
      headerName: "Created",
      field: "created_date",
    },
    {
      headerName: "Product size",
      field: "product_size",
    },
    {
      headerName: "Vendor Article No",
      field: "vendor_article_no",
    },
    {
      headerName: "Material Standard",
      field: "material_standard",
    },
    {
      headerName: "Packing Standard",
      field: "packing_standard",
    },
    {
      headerName: "Environmental & health certification",
      field: "env_health",
    },
    {
      headerName: "Non-Compliance",
      field: "non_compliance",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "SBN Comments",
      field: "sbn_comments",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Color code & gloss",
      field: "color_code_gloss",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Product and Material Details",
      field: "quality_control",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Purchase Price(FSC)",
      field: "purchase_price_fsc",
    },
    {
      headerName: "Purchasing price comments",
      field: "purchasing_price_comments",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Number of units per box",
      field: "no_units_per_box",
    },
    {
      headerName: "Packaging",
      field: "packaging",
    },
    {
      headerName: "Net Weight",
      field: "net_weight",
    },
    {
      headerName: "Gross Weight",
      field: "gross_weight",
    },
    {
      headerName: "Heavy Colli",
      field: "heavy_colli",
    },
    {
      headerName: "BOM per article",
      field: "bom_per_article",
    },
    {
      headerName: "Master cardboard box",
      field: "master_cardboard_box",
    },
    {
      headerName: "Delivered On",
      field: "delivered_on",
    },
    {
      headerName: "Full truck/40hc",
      field: "full_truck",
    },
    {
      headerName: "20st Container",
      field: "container",
    },
    {
      headerName: "MOQ",
      field: "moq",
    },
    {
      headerName: "Packing Comments",
      field: "packing_comments",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Comments",
      field: "comments",
    },
    {
      headerName: "Article Description",
      field: "article_desc",
    },
    {
      headerName: "Special Supplier Email",
      field: "special_supplier_email",
    },
    {
      headerName: "Return Aggrement",
      field: "return_agreement",
    },
    {
      headerName: "JYSK Supplier No.",
      field: "jysk_supplier_no",
      cellRenderer: function (params) {
        return "28608"
      },
    },
    {
      headerName: "Vendor Article Name",
      field: "vendor_article_name",
    },
    {
      headerName: "Sample QA",
      field: "sample_qa",
    },
    {
      headerName: "Sample Request",
      field: "sample_request",
    },
    {
      headerName: "Sample Comments",
      field: "sample_comments",
    },
    {
      headerName: "Sample Status",
      field: "sample_status",
    },
    {
      headerName: "Sample QA Checking Comments",
      field: "sample_qa_checking_comments",
    },
    {
      headerName: "Sample Sales Checking Comments",
      field: "sample_sales_checking_comments",
    },
    {
      headerName: "Sample Main Materials",
      field: "sample_main_materials",
    },
    {
      headerName: "Sample Hardware",
      field: "sample_hardware",
    },
    {
      headerName: "Sample Surface Treatment",
      field: "sample_surface_treatment",
    },
    {
      headerName: "Sample Air Freight Cost",
      field: "sample_freight_cost",
    },
  ]
  const [columnDefs, setColumnDefs] = useState(columnDefination)

  function updateModalState(id) {
    setModalOpen(!modalOpen)
    setCurrentOffer(id)
  }

  function deleteOfferHandler(id) {
    if (id) {
      dispatch(coDeleteOffer(id))
      updateModalState()
    }
  }

  function callChangeOfferStatus(id) {
    dispatch(coChangeOfferStatus(id))
  }

  useEffect(() => {
    dispatch(coOffersListing())
  }, [coOffersListing])

  let OfferList = useSelector(state => state.coOffer.offer)

  useEffect(() => {
    if (OfferList && OfferList.length > 0) {
      setOffersData(OfferList)
      if (columnDefination && offersData && offersData.length > 0) {
        gridRef.current.api.setColumnDefs(columnDefination)
      }
    }
  }, [OfferList])

  const showAgGridLoading = useCallback(() => {
    if (gridRef.current.api) {
      gridRef.current.api.showLoadingOverlay()
    }
  }, [])

  const hideAgGridLoading = useCallback(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.hideOverlay()
    }
  }, [])

  const { error, loading, msg } = useSelector(state => ({
    error: state.coOffer.error,
    loading: state.coOffer.loading,
    msg: state.offer.msg,
  }))

  if (loading) {
    showAgGridLoading()
  } else {
    hideAgGridLoading()
  }

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container fluid>
          <Row>
            <Col md={12} lg={12} xl={12}>
              {error ? <ToastContainer /> : ""}
              {msg ? <ToastContainer /> : ""}
            </Col>
            <Col md={12} lg={12} xl={12}>
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: "80vh" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={offersData}
                  rowHeight={rowHeight}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  animateRows={true}
                  pagination={true}
                  paginationPageSize={20}
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while we are fetching data</span>'
                  }
                />
              </div>
            </Col>
          </Row>

          <Modal
            isOpen={modalOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="deleteOfferConfirm"
            tabIndex="-1"
            backdrop={false}
            toggle={() => updateModalState()}
          >
            <ModalHeader>Remove Offer</ModalHeader>
            <ModalBody>Do you really want to delete offer?</ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => deleteOfferHandler(currentOffer)}
              >
                Yes
              </Button>{" "}
              <Button onClick={() => updateModalState()}>No</Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CoOfferList
