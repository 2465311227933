import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Redux States
import {
  GET_SUPPLIERNAME_LISTING,
  FETCH_SUPPLIER_BY_SECTION,
  ADD_SUPPLIER,
  EDIT_SUPPLIER,
  UPDATE_SUPPLIER,
  SUPPLIER_ACTIVE_INACTIVE,
  UPDATE_SUPPLIER_POSITION,
  GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
} from "./actionTypes"

import {
  supplierNameListingSuccess,
  supplierNameListingFail,
  fetchSupllierListingBySectionSuccess,
  fetchSupllierListingBySectionFail,
  addSupplierSuccess,
  addSupplierFail,
  editSupplierSuccess,
  editSupplierFail,
  updateSupplierSuccess,
  updateSupplierFail,
  changeSupplierStatusFail,
  changeSupplierStatusSuccess,
  updateSupplierPositionFail,
  updateSupplierPositionSuccess,
  supplierNameListingForEditOfferSearchPageSuccess,
  supplierNameListingForEditOfferSearchPageFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  supplierNameListingApi,
  addSupplierApi,
  updateSupplierApi,
  getSupplierDetailsById,
  fetchDropdownsBySectionApi,
  supplierChangeStatusApi,
  fetchDropDownPositionApi,
  supplierNameListingForEditOfferSearchPageApi,
} from "../../../helpers/fakebackend_helper"

function* getSupplierNameListing() {
  try {
    const response = yield call(supplierNameListingApi)
    if (!response.results.supplier_names) {
      yield put(supplierNameListingFail(error))
    }
    yield put(supplierNameListingSuccess(response.results.supplier_names))
  } catch (error) {
    yield put(supplierNameListingFail(error))
  }
}

function* fetchSupplierListingBySection({ payload: { data } }) {
  try {
    const response = yield call(fetchDropdownsBySectionApi, data)
    if (!response?.results?.supplier_names) {
      throw response.msg ? response.msg : "Failed to fetch details"
    }
    yield put(
      fetchSupllierListingBySectionSuccess(response.results.supplier_names)
    )
  } catch (error) {
    yield put(fetchSupllierListingBySectionFail(error))
  }
}

function* addSupplier({ payload: { data, history } }) {
  try {
    const response = yield call(addSupplierApi, data)
    if (response && response.status) {
      yield put(addSupplierSuccess(response))
      history.push("/dropdown/supplier_name/list")
    } else {
      throw response.msg ? response.msg : "Failed to add"
    }
  } catch (error) {
    yield put(addSupplierFail(error))
  }
}

function* editSupplier({ payload: { id } }) {
  try {
    const response = yield call(getSupplierDetailsById, id)
    if (response && response.status) {
      response.id = id
      yield put(editSupplierSuccess(response.results.supplier_name))
    } else {
      throw response.msg ? response.msg : "Failed to edit"
    }
  } catch (error) {
    yield put(editSupplierFail(error))
  }
}

function* updateSupplier({ payload: { updateSupplier, history } }) {
  try {
    const response = yield call(updateSupplierApi, updateSupplier)
    if (response && response.status) {
      yield put(updateSupplierSuccess(response))
      history.push("/dropdown/supplier_name/list")
    } else {
      throw response.msg ? response.msg : "Failed to update"
    }
  } catch (error) {
    yield put(updateSupplierFail(error))
  }
}

function* changeSupplierStatus({ payload: { id } }) {
  try {
    const response = yield call(supplierChangeStatusApi, { supplier_id: id })
    if (response && response.status) {
      response.id = id
      yield put(changeSupplierStatusSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update status"
    }
  } catch (error) {
    yield put(changeSupplierStatusFail(error))
  }
}

function* updateSupplierPosition({ payload: { data } }) {
  try {
    const response = yield call(fetchDropDownPositionApi, data)
    if (response && response.status) {
      yield put(updateSupplierPositionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update position"
    }
  } catch (error) {
    let msg = error ? { msg: error } : { msg: "Failed to update position" }
    yield put(updateSupplierPositionFail(msg))
  }
}

function* getSupplierNameListingForEditOfferSearchPage({ payload: { data } }) {
  try {
    const response = yield call(
      supplierNameListingForEditOfferSearchPageApi,
      data
    )
    if (
      response &&
      response.status &&
      response?.results?.supplier_names &&
      response?.results?.supplier_names.length
    ) {
      yield put(
        supplierNameListingForEditOfferSearchPageSuccess(
          response.results.supplier_names
        )
      )
    } else {
      throw response.msg ? response.msg : "Failed to fetch supplier names"
    }
  } catch (error) {
    yield put(supplierNameListingForEditOfferSearchPageFail(error))
  }
}

function* supplierNameSaga() {
  yield takeEvery(GET_SUPPLIERNAME_LISTING, getSupplierNameListing)
  yield takeEvery(FETCH_SUPPLIER_BY_SECTION, fetchSupplierListingBySection)
  yield takeEvery(ADD_SUPPLIER, addSupplier)
  yield takeEvery(EDIT_SUPPLIER, editSupplier)
  yield takeEvery(UPDATE_SUPPLIER, updateSupplier)
  yield takeEvery(SUPPLIER_ACTIVE_INACTIVE, changeSupplierStatus)
  yield takeEvery(UPDATE_SUPPLIER_POSITION, updateSupplierPosition)
  yield takeEvery(
    GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
    getSupplierNameListingForEditOfferSearchPage
  )
}

export default supplierNameSaga
