import React from "react"
import "./sa-products.css"
function ProductImageRenderer(props) {
  let imageSource = props.value !== undefined ? props.value : null
  return (
    <>
      {imageSource ? (
        <div className="d-flex align-items-center image_handler">
          <a href={imageSource} target="_blank" rel="noreferrer">
            <img
              className="justify-content-center w-100 h-100 rounded cursor-pointer"
              src={imageSource}
            />
          </a>
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default ProductImageRenderer
