export const QA_COLLECTION_ADD = "QA_COLLECTION_ADD"
export const QA_COLLECTION_SUCCESS = "QA_COLLECTION_SUCCESS"
export const QA_COLLECTION_FAIL = "QA_COLLECTION_FAIL"

export const QA_GET_COLLECTION_LISTING = "QA_GET_COLLECTION_LISTING"
export const QA_GET_COLLECTION_LISTING_SUCCESS =
  "QA_GET_COLLECTION_LISTING_SUCCESS"
export const QA_GET_COLLECTION_LISTING_FAILED =
  "QA_GET_COLLECTION_LISTING_FAILED"

export const QA_COLLECTION_EDIT = "QA_COLLECTION_EDIT"
export const QA_COLLECTION_EDIT_SUCCESS = "QA_COLLECTION_EDIT_SUCCESS"
export const QA_COLLECTION_EDIT_FAIL = "QA_COLLECTION_EDIT_FAIL"

export const QA_COLLECTION_UPDATE = "QA_COLLECTION_UPDATE"
export const QA_COLLECTION_UPDATE_SUCCESS = "QA_COLLECTION_UPDATE_SUCCESS"
export const QA_COLLECTION_UPDATE_FAIL = "QA_COLLECTION_UPDATE_FAIL"

export const QA_COLLECTION_DELETE = "QA_COLLECTION_DELETE"
export const QA_COLLECTION_DELETE_SUCCESS = "QA_COLLECTION_DELETE_SUCCESS"
export const QA_COLLECTION_DELETE_FAIL = "QA_COLLECTION_DELETE_FAIL"

export const QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD =
  "QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD"
export const QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS =
  "QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS"
export const QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL =
  "QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL"

export const QA_COLLECTION_COPY = "QA_COLLECTION_COPY"
export const QA_COLLECTION_COPY_SUCCESS = "QA_COLLECTION_COPY_SUCCESS"
export const QA_COLLECTION_COPY_FAIL = "QA_COLLECTION_COPY_FAIL"

export const QA_EXPORTDOWNLOAD_PROGRESS = "QA_EXPORTDOWNLOAD_PROGRESS"
export const QA_EXPORTDOWNLOAD_PROGRESS_SUCCESS =
  "QA_EXPORTDOWNLOAD_PROGRESS_SUCCESS"
export const QA_EXPORTDOWNLOAD_PROGRESS_FAIL = "QA_EXPORTDOWNLOAD_PROGRESS_FAIL"

export const QA_ADD_MORE_TO_COLLECTION = "QA_ADD_MORE_TO_COLLECTION"
export const QA_ADD_MORE_TO_COLLECTION_SUCCESS =
  "QA_ADD_MORE_TO_COLLECTION_SUCCESS"
export const QA_ADD_MORE_TO_COLLECTION_FAIL = "QA_ADD_MORE_TO_COLLECTION_FAIL"

export const QA_ADD_MORE_TO_COLLECTION_UPDATE =
  "QA_ADD_MORE_TO_COLLECTION_UPDATE"
export const QA_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS =
  "QA_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS"
export const QA_ADD_MORE_TO_COLLECTION_UPDATE_FAIL =
  "QA_ADD_MORE_TO_COLLECTION_UPDATE_FAIL"

export const QA_GET_PRODUCTS_FOR_ADD_COLLECTION =
  "QA_GET_PRODUCTS_FOR_ADD_COLLECTION"
export const QA_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS =
  "QA_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS"
export const QA_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL =
  "QA_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL"

export const QA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION =
  "QA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION"
export const QA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS =
  "QA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS"
export const QA_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL =
  "QA_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL"

export const QA_REFRESH_COLLECTION_COUNT = "QA_REFRESH_COLLECTION_COUNT"
export const QA_REFRESH_COLLECTION_COUNT_SUCCESS =
  "QA_REFRESH_COLLECTION_COUNT_SUCCESS"
export const QA_REFRESH_COLLECTION_COUNT_FAIL =
  "QA_REFRESH_COLLECTION_COUNT_FAIL"

export const QA_GET_LAYOUT = "QA_GET_LAYOUT"
export const QA_GET_LAYOUT_SUCCESS = "QA_GET_LAYOUT_SUCCESS"
export const QA_GET_LAYOUT_FAIL = "QA_GET_LAYOUT_FAIL"
