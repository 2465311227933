import {
  CO_GET_USERLISTACCORDINGROLE_LISTING,
  CO_GET_USERLISTACCORDINGROLE_LISTING_SUCCESS,
  CO_GET_USERLISTACCORDINGROLE_LISTING_FAILED,
} from "./actionTypes"

export const coUserListAccordingRoleListing = (userType, history) => {
  return {
    type: CO_GET_USERLISTACCORDINGROLE_LISTING,
    payload: { userType, history },
  }
}

export const coUserListAccordingRoleSuccess = userListAccordingRole => {
  return {
    type: CO_GET_USERLISTACCORDINGROLE_LISTING_SUCCESS,
    payload: userListAccordingRole,
  }
}

export const coUserListAccordingRoleFail = error => {
  return {
    type: CO_GET_USERLISTACCORDINGROLE_LISTING_FAILED,
    payload: error,
  }
}
