import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { CO_GET_PACKAGING_LISTING } from "./actionTypes"
import {
  coPackagingListingSuccess,
  coPackagingListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { coPackagingListingApi } from "../../../helpers/fakebackend_helper"

function* coGetPackagingListing() {
  try {
    const response = yield call(coPackagingListingApi)
    if (!response.results.packaging) {
      yield put(coPackagingListingFail(error))
    }
    yield put(coPackagingListingSuccess(response.results.packaging))
  } catch (error) {
    yield put(coPackagingListingFail(error))
  }
}

function* coPackagingSaga() {
  yield takeEvery(CO_GET_PACKAGING_LISTING, coGetPackagingListing)
}
export default coPackagingSaga
