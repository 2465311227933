export const USER_SUCCESS = "USER_SUCCESS"
export const USER_FAIL = "USER_FAIL"
export const USER_ADD = "USER_ADD"

export const GET_USERS_LISTING = "GET_USERS_LISTING"
export const GET_USERS_LISTING_SUCCESS = "GET_USERS_LISTING_SUCCESS"
export const GET_USERS_LISTING_FAILED = "GET_USERS_LISTING_FAILED"

export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS"
export const CHANGE_USER_STATUS_SUCCESS = "CHANGE_USER_STATUS_SUCCESS"
export const CHANGE_USER_STATUS_FAIL = "CHANGE_USER_STATUS_FAIL"

export const USER_EDIT = "USER_EDIT"
export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS"
export const USER_EDIT_FAIL = "USER_EDIT_FAIL"

export const USER_UPDATE = "USER_UPDATE"
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL"

export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD"
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS"
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL"
