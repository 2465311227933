import {
  BD_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING,
  BD_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_SUCCESS,
  BD_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_FAILED,
} from "./actionTypes"

export const bdEnvironmentalAndHealthCertificationListing = () => {
  return {
    type: BD_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING,
    payload: "",
  }
}

export const bdEnvironmentalAndHealthCertificationListingSuccess =
  bdEnvironmentalAndHealthCertification => {
    return {
      type: BD_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_SUCCESS,
      payload: bdEnvironmentalAndHealthCertification,
    }
  }

export const bdEnvironmentalAndHealthCertificationListingFail = error => {
  return {
    type: BD_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_FAILED,
    payload: error,
  }
}
