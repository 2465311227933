import {
  BD_COLLECTION_ADD,
  BD_COLLECTION_SUCCESS,
  BD_COLLECTION_FAIL,
  BD_GET_COLLECTION_LISTING,
  BD_GET_COLLECTION_LISTING_SUCCESS,
  BD_GET_COLLECTION_LISTING_FAILED,
  BD_COLLECTION_EDIT,
  BD_COLLECTION_EDIT_SUCCESS,
  BD_COLLECTION_EDIT_FAIL,
  BD_COLLECTION_UPDATE,
  BD_COLLECTION_UPDATE_SUCCESS,
  BD_COLLECTION_UPDATE_FAIL,
  BD_COLLECTION_DELETE,
  BD_COLLECTION_DELETE_SUCCESS,
  BD_COLLECTION_DELETE_FAIL,
  BD_COLLECTION_COPY,
  BD_COLLECTION_COPY_SUCCESS,
  BD_COLLECTION_COPY_FAIL,
  BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
  BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS,
  BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL,
  BD_EXPORTDOWNLOAD_PROGRESS,
  BD_EXPORTDOWNLOAD_PROGRESS_SUCCESS,
  BD_EXPORTDOWNLOAD_PROGRESS_FAIL,
  BD_ADD_MORE_TO_COLLECTION,
  BD_ADD_MORE_TO_COLLECTION_SUCCESS,
  BD_ADD_MORE_TO_COLLECTION_FAIL,
  BD_ADD_MORE_TO_COLLECTION_UPDATE,
  BD_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS,
  BD_ADD_MORE_TO_COLLECTION_UPDATE_FAIL,
  BD_GET_PRODUCTS_FOR_ADD_COLLECTION,
  BD_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS,
  BD_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL,
  BD_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
  BD_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS,
  BD_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL,
  BD_REFRESH_COLLECTION_COUNT,
  BD_REFRESH_COLLECTION_COUNT_SUCCESS,
  BD_REFRESH_COLLECTION_COUNT_FAIL,
  BD_GET_LAYOUT,
  BD_GET_LAYOUT_SUCCESS,
  BD_GET_LAYOUT_FAIL,
} from "./actionTypes"

export const bdAddNewCollection = (collection, history) => {
  return {
    type: BD_COLLECTION_ADD,
    payload: { collection, history },
  }
}

export const bdCollectionSuccess = collection => {
  return {
    type: BD_COLLECTION_SUCCESS,
    payload: collection,
  }
}

export const bdCollectionFail = error => {
  return {
    type: BD_COLLECTION_FAIL,
    payload: error,
  }
}

export const bdCollectionsListing = history => {
  return {
    type: BD_GET_COLLECTION_LISTING,
  }
}

export const bdCollectionsListingSuccess = collection => {
  return {
    type: BD_GET_COLLECTION_LISTING_SUCCESS,
    payload: collection,
  }
}

export const bdCollectionsListingFail = error => {
  return {
    type: BD_GET_COLLECTION_LISTING_FAILED,
    payload: error,
  }
}

export const bdCollectionEdit = id => {
  return {
    type: BD_COLLECTION_EDIT,
    payload: { id: id },
  }
}

export const bdEditCollectionSuccess = collection => {
  return {
    type: BD_COLLECTION_EDIT_SUCCESS,
    payload: collection,
  }
}

export const bdEditCollectionFail = error => {
  return {
    type: BD_COLLECTION_EDIT_FAIL,
    payload: error,
  }
}

export const bdUpdateCollection = (collection, history) => {
  return {
    type: BD_COLLECTION_UPDATE,
    payload: { collection, history },
  }
}

export const bdUpdateCollectionSuccess = collection => {
  return {
    type: BD_COLLECTION_UPDATE_SUCCESS,
    payload: collection,
  }
}

export const bdUpdateCollectionFail = error => {
  return {
    type: BD_COLLECTION_UPDATE_FAIL,
    payload: error,
  }
}

export const bdDeleteCollection = collectionId => ({
  type: BD_COLLECTION_DELETE,
  payload: { id: collectionId },
})

export const bdDeleteCollectionSuccess = collection => ({
  type: BD_COLLECTION_DELETE_SUCCESS,
  payload: collection,
})

export const bdDeleteCollectionFail = error => ({
  type: BD_COLLECTION_DELETE_FAIL,
  payload: error,
})

export const bdCopyCollection = (collection, history) => {
  return {
    type: BD_COLLECTION_COPY,
    payload: { collection, history },
  }
}

export const bdCopyCollectionSuccess = collection => {
  return {
    type: BD_COLLECTION_COPY_SUCCESS,
    payload: collection,
  }
}

export const bdCopyCollectionFail = error => {
  return {
    type: BD_COLLECTION_COPY_FAIL,
    payload: error,
  }
}

export const bdDownloadExportCollectionCatalog = (collection, history) => {
  return {
    type: BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
    payload: { collection, history },
  }
}

export const bdExportCollectionCatalogSuccess = collection => {
  return {
    type: BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS,
    payload: collection,
  }
}

export const bdExportCollectionCatalogFail = error => {
  return {
    type: BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL,
    payload: error,
  }
}

export const bdExportDownloadProgress = (exportDownloadProgress, history) => {
  return {
    type: BD_EXPORTDOWNLOAD_PROGRESS,
    payload: { exportDownloadProgress, history },
  }
}

export const bdExportDownloadProgressSuccess = exportDownloadProgress => {
  return {
    type: BD_EXPORTDOWNLOAD_PROGRESS_SUCCESS,
    payload: exportDownloadProgress,
  }
}

export const bdExportDownloadProgressFail = error => {
  return {
    type: BD_EXPORTDOWNLOAD_PROGRESS_FAIL,
    payload: error,
  }
}

export const bdGetAddMoreToCollection = collection_id => {
  return {
    type: BD_ADD_MORE_TO_COLLECTION,
    payload: { collection_id: collection_id },
  }
}

export const bdGetAddMoreToCollectionSuccess = collectionAddMore => {
  return {
    type: BD_ADD_MORE_TO_COLLECTION_SUCCESS,
    payload: collectionAddMore,
  }
}

export const bdGetAddMoreToCollectionFail = error => {
  return {
    type: BD_ADD_MORE_TO_COLLECTION_FAIL,
    payload: error,
  }
}

export const bdGetAddMoreToCollectionUpdate = (
  addMoreUpdateDetails,
  history
) => {
  return {
    type: BD_ADD_MORE_TO_COLLECTION_UPDATE,
    payload: { addMoreUpdateDetails, history },
  }
}

export const bdGetAddMoreToCollectionUpdateSuccess = addMoreUpdateDetails => {
  return {
    type: BD_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS,
    payload: addMoreUpdateDetails,
  }
}

export const bdGetAddMoreToCollectionUpdateFail = error => {
  return {
    type: BD_ADD_MORE_TO_COLLECTION_UPDATE_FAIL,
    payload: error,
  }
}

export const bdProductForAddCollection = productsForAddCollection => {
  return {
    type: BD_GET_PRODUCTS_FOR_ADD_COLLECTION,
    payload: productsForAddCollection,
  }
}

export const bdProductForAddCollectionSuccess = productsForAddCollection => {
  return {
    type: BD_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS,
    payload: productsForAddCollection,
  }
}

export const bdProductForAddCollectionFail = error => {
  return {
    type: BD_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL,
    payload: error,
  }
}

export const bdProductOfferForAddCollection = productsOfferForAddCollection => {
  return {
    type: BD_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
    payload: productsOfferForAddCollection,
  }
}

export const bdProductOfferForAddCollectionSuccess =
  productsOfferForAddCollection => {
    return {
      type: BD_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS,
      payload: productsOfferForAddCollection,
    }
  }

export const bdProductOfferForAddCollectionFail = error => {
  return {
    type: BD_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL,
    payload: error,
  }
}

export const bdRefreshCollectionCount = id => {
  return {
    type: BD_REFRESH_COLLECTION_COUNT,
    payload: { collection_id: id },
  }
}

export const bdRefreshCollectionCountSuccess = collection => {
  return {
    type: BD_REFRESH_COLLECTION_COUNT_SUCCESS,
    payload: collection,
  }
}

export const bdRefreshCollectionCountFail = error => {
  return {
    type: BD_REFRESH_COLLECTION_COUNT_FAIL,
    payload: error,
  }
}

export const bdGetLayOut = layout => {
  return {
    type: BD_GET_LAYOUT,
    payload: layout,
  }
}

export const bdGetLayOutSuccess = (layout) => {
  return {
    type: BD_GET_LAYOUT_SUCCESS,
    payload: { layout },
  }
}

export const bdGetLayOutFail = error => {
  return {
    type: BD_GET_LAYOUT_FAIL,
    payload: error,
  }
}
