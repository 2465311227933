import React from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Form,
  Input,
  FormFeedback,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  FormGroup,
  Table,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useEffect, useState } from "react"
import { Prompt } from "react-router-dom"
import Select from "react-select"
import noImageFound from "../../../assets/images/no_image_available.webp"

import {
  bdSupplierNameListing,
  bdDeliveredOnListing,
  bdEnvironmentalAndHealthCertificationListing,
  bdHeaveyColliListing,
  bdIncotermCityListing,
  bdMaterialStandardListing,
  bdPackagingListing,
  bdOfferStatusListing,
  bdPackingStandardListing,
  bdSupplierNameListingForEditOfferSearch,
  bdCreatedByListing,
  bdProductCategoryListing,
  bdOfferEdit,
  bdUpdateOffer,
  bdSearchItemInNewOfferPage,
  bdClearOfferResult
} from "../../../store/actions"

const BusinessDeveloperGetOfferDetailForUpdate = props => {
  document.title = "Edit Offer"
  const dispatch = useDispatch()

  const [accordianOpen, setAccordianOpen] = useState("")

  const [isBlocking, SetIsBlocking] = useState(false) // for prompt
  const [errorsArr, SetErrorsArr] = useState([]) // validation section
  const [details, setDetails] = useState({}) // this is for all details including calculation
  const [bdSupplierName, setBdSupplierName] = useState([])
  const [bdOfferStatus, setBdOfferStatus] = useState([])
  const [bdIncotermCity, setBdIncotermCity] = useState([])
  const [bdMaterialStandard, setBdMaterialStandard] = useState([])
  const [bdPackingStandard, setBdPackingStandard] = useState([])
  const [
    bdEnvironmentalAndHealthCertification,
    setBdEnvironmentalAndHealthCertification,
  ] = useState([])
  const [bdPackaging, setBdPackaging] = useState([])
  const [bdDeliveredOn, setBdDeliveredOn] = useState([])
  const [bdHeaveyColli, setBdHeaveyColli] = useState([])

  const [
    bdProductCategoriesForLeftSideDropdown,
    setBdProductCategoriesForLeftSideDropdown,
  ] = useState([])
  const [bdSupplierForLeftSideDropdown, setBdSupplierForLeftSideDropdown] =
    useState([])
  const [bdCreatedByForLeftSideDropdown, setBdCreatedByForLeftSideDropdown] =
    useState([])

  const [editOfferId, setEditOfferId] = useState("")
  const [searchResults, setSearchResults] = useState([])
  
  useEffect(() => {
    dispatch(bdOfferStatusListing())
    dispatch(bdSupplierNameListingForEditOfferSearch())
    dispatch(bdSupplierNameListing())
    dispatch(bdIncotermCityListing())
    dispatch(bdMaterialStandardListing())
    dispatch(bdPackingStandardListing())
    dispatch(bdEnvironmentalAndHealthCertificationListing())
    dispatch(bdPackagingListing())
    dispatch(bdDeliveredOnListing())
    dispatch(bdHeaveyColliListing())
    dispatch(bdProductCategoryListing())
    dispatch(bdCreatedByListing())
  }, [])

  const toggle = id => {
    if (accordianOpen === id) {
      setAccordianOpen("")
    } else {
      setAccordianOpen(id)
    }
  }

  function getOfferById(e) {
    if (e?.target?.checked) {
      if (
        Object.keys(validation?.touched).length &&
        validation.dirty &&
        !validation.isSubmitting
      ) {
        if (confirm("You have unsaved changes. Do you really want to leave?")) {
          setEditOfferId(e.target.value)
          dispatch(bdOfferEdit(e.target.value))
        } else {
          e.target.checked = false
        }
      } else {
        setEditOfferId(e.target.value)
        dispatch(bdOfferEdit(e.target.value))
      }
    } else {
      setEditOfferId("")
      setDetails({})
    }
  }

  const bdOffersData = useSelector(state => state.bdOffer.offer)
  const bdSupplierNameData = useSelector(
    state => state.bdSupplierName.bdSupplierName
  ) ///1st supplierName is from reducer
  const bdSupplierNameDataForSearch = useSelector(state => state.bdSupplierName.bdSupplierListingForSearch)
  const bdOfferStatusData = useSelector(
    state => state.bdOfferStatus.bdOfferStatus
  )
  const bdIncotermCityData = useSelector(
    state => state.bdIncotermCity.bdIncotermCity
  )
  const bdMaterialStandardData = useSelector(
    state => state.bdMaterialStandard.bdMaterialStandard
  )
  const bdPackingStandardData = useSelector(
    state => state.bdPackingStandard.bdPackingStandard
  )
  const bdEnvironmentalAndHealthCertificationData = useSelector(
    state =>
      state.bdEnvironmentalAndHealthCertification
        .bdEnvironmentalAndHealthCertification
  )
  const bdPackagingData = useSelector(state => state.bdPackaging.bdPackaging)

  const bdDeliveredOnData = useSelector(
    state => state.bdDeliveredOn.bdDeliveredOn
  )
  const bdHeaveyColliData = useSelector(
    state => state.bdHeaveyColli.bdHeaveyColli
  )

  const bdProductCategoryData = useSelector(
    state => state.bdProductCategory.bdProductCategory
  )
  const bdCeatedByData = useSelector(state => state.bdCreatedBy.bdCreatedBy)

  const bdOffersSearchData = useSelector(state => state.bdOffer.search)

  useEffect(() => {
    // this is for react select dropdown
    let prodCatOptionsForLeftSideDropdown =
      bdProductCategoryData &&
      bdProductCategoryData.map(bdProductCategoryData => ({
        value: bdProductCategoryData.id,
        label: bdProductCategoryData.prod_cat_name,
      }))
    setBdProductCategoriesForLeftSideDropdown(prodCatOptionsForLeftSideDropdown)

    let createdByOptionsForLeftSideDropdown =
      bdCeatedByData &&
      bdCeatedByData.map(bdCeatedByData => ({
        value: bdCeatedByData.id,
        label: bdCeatedByData.name,
      }))
    setBdCreatedByForLeftSideDropdown(createdByOptionsForLeftSideDropdown)

    let supplierOptionsForLeftSideDropdown = []
    if (bdSupplierNameDataForSearch && bdSupplierNameDataForSearch.length) {
      supplierOptionsForLeftSideDropdown =
      bdSupplierNameDataForSearch &&
      bdSupplierNameDataForSearch.length &&
      bdSupplierNameDataForSearch.map(bdSupplierNameDataForSearch => ({
          value: bdSupplierNameDataForSearch.id,
          label: bdSupplierNameDataForSearch.name,
        }))
    } else {
      supplierOptionsForLeftSideDropdown =
      bdSupplierNameData &&
      bdSupplierNameData.length &&
      bdSupplierNameData.map(d => ({
          value: d.id,
          label: d.name,
        }))
    }
    setBdSupplierForLeftSideDropdown(supplierOptionsForLeftSideDropdown)

    setBdSupplierName(bdSupplierNameData)
    setBdOfferStatus(bdOfferStatusData)
    setBdIncotermCity(bdIncotermCityData)
    setBdMaterialStandard(bdMaterialStandardData)
    setBdPackingStandard(bdPackingStandardData)
    setBdEnvironmentalAndHealthCertification(
      bdEnvironmentalAndHealthCertificationData
    )
    setBdPackaging(bdPackagingData)
    setBdDeliveredOn(bdDeliveredOnData)
    setBdHeaveyColli(bdHeaveyColliData)
  }, [
    bdSupplierNameDataForSearch,
    bdSupplierNameData,
    bdOfferStatusData,
    bdIncotermCityData,
    bdMaterialStandardData,
    bdPackingStandardData,
    bdEnvironmentalAndHealthCertificationData,
    bdPackagingData,
    bdDeliveredOnData,
    bdHeaveyColliData,
    bdProductCategoryData,
    bdCeatedByData,
  ])

  useEffect(() => {
    let offers = []
    if (bdOffersData && editOfferId) {
      offers = bdOffersData.filter(offer => offer.offer_id == editOfferId)

      if (offers.length > 0) {
        setDetails(offers[0])
        if (editOfferId) {
          if (accordianOpen == "") {
            setAccordianOpen("1")
          }
        }
      }
    }

    if (bdOffersSearchData && bdOffersSearchData.length) {
      setSearchResults(prev => (prev = bdOffersSearchData))
    } else {
      setSearchResults([])
    }
  }, [editOfferId, bdOffersSearchData, bdOffersData])
  useEffect(() => {
    dispatch(bdClearOfferResult())
  }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      offer_id: details.offer_id ? details.offer_id : "",
      offer_status_id:
        details && details.offer_status_id ? details.offer_status_id : "",
      vendor_article_no:
        details && details.vendor_article_no ? details.vendor_article_no : "",
      supplier_id: details && details.supplier_id ? details.supplier_id : "",
      production_time:
        details && details.production_time ? details.production_time : "",
      incoterm_city_id:
        details && details.incoterm_city_id ? details.incoterm_city_id : "",
      material_standard_id:
        details && details.material_standard_id
          ? details.material_standard_id
          : "",
      packing_standard_id:
        details && details.packing_standard_id
          ? details.packing_standard_id
          : "",
      env_health_id:
        details && details.env_health_id ? details.env_health_id : "",
      non_compliance:
        details && details.non_compliance ? details.non_compliance : "",
      sbn_comments: details && details.sbn_comments ? details.sbn_comments : "",
      quality_control:
        details && details.quality_control ? details.quality_control : "",
      color_code_gloss:
        details && details.color_code_gloss ? details.color_code_gloss : "",
      purchase_price_fsc:
        details && details.purchase_price_fsc ? details.purchase_price_fsc : "",
     
      purchasing_price_comments:
        details && details.purchasing_price_comments
          ? details.purchasing_price_comments
          : "",
      sales_price_fsc:
        details && details.sales_price_fsc ? details.sales_price_fsc : 0,
      sales_margin_fsc:
        details && details.sales_margin_fsc ? details.sales_margin_fsc : 0,
      full_truck: details && details.full_truck ? details.full_truck : "",
      container: details && details.container ? details.container : "",
      moq: details && details.moq ? details.moq : "",
      net_weight: details && details.net_weight ? details.net_weight : "",
      gross_weight: details && details.gross_weight ? details.gross_weight : "",
      packaging_id: details && details.packaging_id ? details.packaging_id : "",
      delivered_on: details && details.delivered_on ? details.delivered_on : "",
      heavy_colli_id:
        details && details.heavy_colli_id ? details.heavy_colli_id : "",
      units_per_box:
        details && details.units_per_box ? details.units_per_box : "",
      bom_per_article:
        details && details.bom_per_article ? details.bom_per_article : "",
      master_cardboard_box:
        details && details.master_cardboard_box
          ? details.master_cardboard_box
          : "",
      packing_comments:
        details && details.packing_comments ? details.packing_comments : "",
    },
    validationSchema: Yup.object({
      offer_status_id: Yup.string().required(
        "Offer Status- This field is required"
      ),

      supplier_id: Yup.string().required(
        "Supplier Name- This field is required"
      ),

      incoterm_city_id: Yup.string().required(
        "Incoterm City- This field is required"
      ),

      production_time: Yup.string()
        .required(
          "Production Time- This field is required and this is must be a number."
        )
        .matches(
          /^[0-9]+$/,
          "Positive numbers are allowed without dots, negetive or decimal"
        ),

      material_standard_id: Yup.string().required(
        "Material Standard- This field is required"
      ),
      packing_standard_id: Yup.string().required(
        "Packing Standard- This field is required"
      ),
      env_health_id: Yup.string().required(
        "Environmental and Health Certification- This field is required"
      ),

      purchase_price_fsc: Yup.number().required("Purchase FSC is required"),
     
      sales_price_fsc: Yup.number(),
      sales_margin_fsc: Yup.number(),

      full_truck: Yup.number().required(
        "Full Truck/40HC- This field is required"
      ),
      container: Yup.number().required(
        "20ST Container- This field is required"
      ),
      moq: Yup.number().required("MOQ- This field is required"),
      net_weight: Yup.number().required("Net Weight- This field is required"),
      gross_weight: Yup.number().required(
        "Gross Weight- This field is required"
      ),
      packaging_id: Yup.string().required("Packaging- This field is required"),
      delivered_on: Yup.string().required(
        "Delivered On- This field is required"
      ),
      heavy_colli_id: Yup.string().required(
        "Heavy Colli- This field is required"
      ),
      units_per_box: Yup.number().required(
        "Number of Units Per Box- This field is required"
      ),
      bom_per_article: Yup.number().required(
        "BOM Per Article- This field is required"
      ),
    }),
    onSubmit: values => {
      dispatch(bdUpdateOffer(values, props.history))
    },
  })

  const validationForLeftForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      created_by: [],
      product_number: "",
      supplier_name: [],
      prod_cat: [],
      text: "",
      type: "edit_offer",
    },
    validationSchema: Yup.object({
      created_by: Yup.array(),
      product_number: Yup.string(),
      supplier_name: Yup.array(),
      prod_cat: Yup.array(),
      text: Yup.string(),
    }),
    onSubmit: values => {
      if (Object.keys(validation?.touched).length) {
        if (confirm("You have unsaved changes. Do you really want to leave?")) {
          delete values.search
          if (
            values.created_by.length ||
            values.product_number ||
            values.supplier_name.length ||
            values.prod_cat.length ||
            values.text
          ) {
            setSearchResults([])
            setEditOfferId("")
            setDetails({})
            dispatch(bdSearchItemInNewOfferPage(values))
            // setAccordianOpen("")
          } else {
            toast.dismiss()
            toast.error("Please add data for search", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            })
          }
        }
      } else {
        delete values.search
        if (
          values.created_by.length ||
          values.product_number ||
          values.supplier_name.length ||
          values.prod_cat.length ||
          values.text
        ) {
          setSearchResults([])
          setEditOfferId("")
          setDetails({})
          dispatch(bdSearchItemInNewOfferPage(values))
          // setAccordianOpen("")
        } else {
          toast.dismiss()
          toast.error("Please add data for search", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          })
        }
      }
    },
  })

  let validationSections = [
    {
      section: "Offer Details",
      errorPresent: false,
      children: [
        { name: "offer_status_id", errorMessage: null },
        { name: "supplier_id", errorMessage: null },
        { name: "incoterm_city_id", errorMessage: null },
        { name: "production_time", errorMessage: null },
        { name: "material_standard_id", errorMessage: null },
        { name: "packing_standard_id", errorMessage: null },
        { name: "env_health_id", errorMessage: null },
      ],
    },
    {
      section: "Offer Price",
      errorPresent: false,
      children: [{ name: "purchase_price_fsc", errorMessage: null }],
    },
    {
      section: "Offer Packing",
      errorPresent: false,
      children: [
        { name: "full_truck", errorMessage: null },
        { name: "container", errorMessage: null },
        { name: "moq", errorMessage: null },
        { name: "net_weight", errorMessage: null },
        { name: "gross_weight", errorMessage: null },
        { name: "packaging_id", errorMessage: null },
        { name: "delivered_on", errorMessage: null },
        { name: "heavy_colli_id", errorMessage: null },
        { name: "units_per_box", errorMessage: null },
        { name: "bom_per_article", errorMessage: null },
      ],
    },
  ]

  useEffect(() => {
    if (
      Object.keys(validation.touched).length &&
      Object.keys(validation.errors).length
    ) {
      let currentValidation = [...validationSections]
      for (const error in validation.errors) {
        currentValidation.forEach(section => {
          // Find the matching child by name
          const child = section.children.find(c => c.name === error)
          // If a matching child is found, update its errorMessage
          if (child && validation?.errors[error] !== "") {
            child.errorMessage = validation.errors[error]
            section.errorPresent = true
          } else if (child && validation?.errors[error] === "") {
            child.errorMessage = null
          }
        })
        SetErrorsArr(currentValidation)
      }
    } else {
      SetErrorsArr([])
    }
  }, [validation.errors, validation.touched])

  // prompt
  useEffect(() => {
    SetIsBlocking(
      validation?.dirty && validation.submitCount === 0 ? true : false
    )
  }, [isBlocking, validation?.dirty, validation.submitCount])

  function on_purchase_price_change(e, type) {
    let current_value = isNaN(e.target.value) ? 0 : parseFloat(e.target.value)
    let yearly_forecast = isNaN(details.yearly_forecast)
      ? 0
      : details.yearly_forecast * current_value
    if (type === "fsc") {
      validation.setFieldValue("yearly_purchase_fsc", yearly_forecast)
      let sales_price = details.sales_price_fsc
        ? parseFloat(details.sales_price_fsc)
        : 0
      validation.setFieldValue("sales_price_fsc", sales_price)
      let sales_margin = sales_price > 0 ? 1 - current_value / sales_price : 0
      sales_margin = isNaN(sales_margin) ? 0 : sales_margin.toFixed(1)
      validation.setFieldValue("sales_margin_fsc", sales_margin)
      let yearly_sales_fsc = yearly_forecast * current_value
      validation.setFieldValue("yearly_sales_fsc", yearly_sales_fsc.toFixed(2))
    } else {
      validation.setFieldValue("yearly_purchase_non_fsc", yearly_forecast)

      let sales_price = details.sales_price_non_fsc
        ? parseFloat(details.sales_price_non_fsc)
        : 0
      validation.setFieldValue("sales_price_non_fsc", sales_price)
      let sales_margin = sales_price > 0 ? 1 - current_value / sales_price : 0
      sales_margin = isNaN(sales_margin) ? 0 : sales_margin.toFixed(1)
      validation.setFieldValue("sales_margin_non_fsc", sales_margin)
      let yearly_sales_non_fsc = yearly_forecast * current_value
      validation.setFieldValue(
        "yearly_sales_non_fsc",
        yearly_sales_non_fsc.toFixed(2)
      )
    }
  }

  const getSuppliersFromCreatedBy = created_by_ids => {
    dispatch(bdSupplierNameListingForEditOfferSearch({ id: created_by_ids })) // left side supplier dropdown in search section
  }
  useEffect(() => {
    if (
      validationForLeftForm.dirty &&
      validationForLeftForm.values.created_by == ""
    ) {
      let supplierOptionsForLeftSideDropdown =
      bdSupplierNameData &&
      bdSupplierNameData.length &&
      bdSupplierNameData.map(d => ({
          value: d.id,
          label: d.name,
        }))
      setBdSupplierForLeftSideDropdown(supplierOptionsForLeftSideDropdown)
    }
  }, [validationForLeftForm.values])

  const bdOffer = useSelector(state => state.bdOffer.offer)
  const { error, msg } = useSelector(state => ({
    error: state.bdOffer.error,
    msg: state.bdOffer.msg ? state.bdOffer.msg : "",
  }))

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  return (
    <React.Fragment>
      <div
        style={{ paddingTop: "55px" }}
        className={
          searchResults && searchResults.length
            ? "page-content"
            : "page-content edit-offer-page-content"
        }
      >
        <Prompt
          when={isBlocking}
          message="You have unsaved changes. Do you really want to leave?"
        />

        <Container fluid={true} className="p-10">
          <Breadcrumbs breadcrumbItem="EDIT OFFERS" />
          <Row>
            <Col className="md-3 form-control-sm">
              <Card>
                {msg || error ? (
                  <ToastContainer className="Toastify__close-button" />
                ) : (
                  ""
                )}
                <CardBody className="px-2 py-2">
                  <Form
                    id="newEditOfferLeftSide"
                    onSubmit={e => {
                      e.preventDefault()
                      validationForLeftForm.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="12">
                        <Select
                          id="created-by"
                          name="created_by[]"
                          placeholder="Created By"
                          onChange={createdByUsers => {
                            let created_by_ids =
                              createdByUsers.length &&
                              createdByUsers.map(i => i.value)
                            if (created_by_ids && created_by_ids.length) {
                              validationForLeftForm.setFieldValue(
                                "created_by",
                                created_by_ids
                              )
                              getSuppliersFromCreatedBy(created_by_ids)
                            } else {
                              validationForLeftForm.setFieldValue(
                                "created_by",
                                ""
                              )
                            }
                          }}
                          isMulti
                          options={bdCreatedByForLeftSideDropdown}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </Col>

                      <Col md="12">
                        <Select
                          placeholder="Supplier Name"
                          id="supplier"
                          name="supplier_name[]"
                          className="basic-multi-select mt-2"
                          onChange={supplier => {
                            let suplliers =
                              supplier.length && supplier.map(i => i.value)
                            if (suplliers) {
                              validationForLeftForm.setFieldValue(
                                "supplier_name",
                                suplliers
                              )
                            } else {
                              validationForLeftForm.setFieldValue(
                                "supplier_name",
                                ""
                              )
                            }
                          }}
                          isMulti
                          options={bdSupplierForLeftSideDropdown}
                          classNamePrefix="select"
                        />
                      </Col>

                      <Col md="12">
                        <Select
                          placeholder="Product Category"
                          id="prod-cat"
                          name="prod_cat[]"
                          className="basic-multi-select mt-2"
                          options={bdProductCategoriesForLeftSideDropdown}
                          onChange={category => {
                            let cat =
                              category.length && category.map(i => i.value)
                            if (cat) {
                              validationForLeftForm.setFieldValue(
                                "prod_cat",
                                cat
                              )
                            } else {
                              validationForLeftForm.setFieldValue(
                                "prod_cat",
                                ""
                              )
                            }
                          }}
                          isMulti
                          classNamePrefix="select"
                        />
                      </Col>

                      <Col md="12">
                        <Input
                          placeholder="Product Number"
                          id="product-no"
                          name="product_number"
                          className="mt-2 font-size-12"
                          autoComplete="off"
                          onChange={product_number => {
                            validationForLeftForm.setFieldValue(
                              "product_number",
                              product_number?.target?.value
                            )
                          }}
                        />
                      </Col>

                      <Col md="12">
                        <div className="input-group my-2">
                          <Input
                            type="text"
                            className="form-control font-size-12"
                            placeholder="Text Search"
                            aria-label="Text Search"
                            name="text"
                            autoComplete="off"
                            aria-describedby="basic-addon2"
                            onChange={text => {
                              validationForLeftForm.setFieldValue(
                                "text",
                                text?.target?.value
                              )
                            }}
                          />
                          <div className="input-group-append" />
                          <button
                            className="btn btn-outline-primary submit_new"
                            type="submit"
                          >
                            Search
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              {/* search result start here */}
              <section
                className="search-results overflow-auto mt-2"
                style={{ height: "285px" }}
              >
                {searchResults && searchResults.length
                  ? searchResults.map((searchRes, rkey) => {
                      return (
                        <Card
                          key={`searchResults_` + rkey}
                          className="my-2 border border-primary"
                          color="primary"
                          outline
                        >
                          <CardBody className="search_result_container">
                            <Row>
                              <Col className="col-md-4">
                                {searchRes && searchRes.img1 ? (
                                  <a
                                    className="text-center cursor-pointer font_size_medium"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={searchRes.img1}
                                  >
                                    <img
                                      src={searchRes.img1}
                                      className="object-fit-contain rounded w-100 h-100"
                                      alt="image"
                                    />
                                  </a>
                                ) : (
                                  <img
                                    src={noImageFound}
                                    className="object-fit-contain rounded w-100 h-100"
                                    alt="image"
                                  />
                                )}
                              </Col>
                              <Col className="p-0 col-md-8 search_result_area bg-white">
                                <Table responsive>
                                  <tbody>
                                    <tr>
                                      <td className="p-0">
                                        <FormGroup check className="m-0">
                                          <Input
                                            id={`offer_id_radio_` + rkey}
                                            className="cursor-pointer ml-1"
                                            name="radio"
                                            type="radio"
                                            value={searchRes?.offer_id}
                                            onChange={getOfferById}
                                          />
                                          <Label
                                            check
                                            for={`offer_id_radio_` + rkey}
                                          >
                                            <small>
                                              <b>
                                                {searchRes && searchRes.prod_no
                                                  ? searchRes.prod_no
                                                  : ""}
                                              </b>
                                            </small>
                                          </Label>
                                        </FormGroup>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="p-0">
                                        <small>
                                          <b>
                                            {searchRes && searchRes.created_date
                                              ? searchRes.created_date
                                              : ""}
                                          </b>
                                        </small>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="p-0">
                                        <small>
                                          <b>
                                            {searchRes &&
                                            searchRes.supplier_name
                                              ? searchRes.supplier_name
                                              : ""}
                                          </b>
                                        </small>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="p-0">
                                        <small>
                                          <b>
                                            {searchRes &&
                                            searchRes.prod_category
                                              ? searchRes.prod_category
                                              : ""}
                                          </b>
                                        </small>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      )
                    })
                  : ""}
              </section>

              {/* search result ends here */}
            </Col>
            <Col md="9">
              <Card>
                <CardBody>
                  <Form
                    id="newEditOfferFormRightSide"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Accordion open={accordianOpen} toggle={toggle}>
                      <AccordionItem>
                        <AccordionHeader targetId="1">
                          <b>Offer Details</b>
                        </AccordionHeader>
                        <AccordionBody accordionId="1">
                          <Row>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-offer-status">
                                  Offer Status{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="offer_status_id"
                                  id="add-offer-offer-status"
                                  className={
                                    validation.touched.offer_status_id &&
                                    validation.errors.offer_status_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.offer_status_id || ""
                                  }
                                >
                                  <option value="">Choose...</option>
                                  {bdOfferStatus &&
                                    bdOfferStatus.map((bdOfferStatus, ofs) => (
                                      <option
                                        key={"ofs" + ofs}
                                        value={bdOfferStatus.id}
                                      >
                                        {bdOfferStatus.offer_status}
                                      </option>
                                    ))}
                                </select>
                                {validation.touched.offer_status_id &&
                                validation.errors.offer_status_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.offer_status_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-Vendor-article-number">
                                  Vendor Article Number
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="vendor_article_no"
                                  type="text"
                                  className="form-control input-sm"
                                  id="add-offer-Vendor-article-number"
                                  // placeholder="Enter your vender article number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.vendor_article_no || ""
                                  }
                                />
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-supplier-id">
                                  Supplier Name{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="supplier_id"
                                  id="add-offer-supplier-id"
                                  className={
                                    validation.touched.supplier_id &&
                                    validation.errors.supplier_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.supplier_id || ""}
                                >
                                  <option value="">Choose...</option>
                                  {bdSupplierName &&
                                    bdSupplierName.map(
                                      (bdSupplierName, snk) => (
                                        <option
                                          key={"snk" + snk}
                                          value={bdSupplierName.id}
                                        >
                                          {bdSupplierName.name}
                                        </option>
                                      )
                                    )}
                                </select>
                                {validation.touched.supplier_id &&
                                validation.errors.supplier_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.supplier_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-production-input1">
                                  Production Time{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="production_time"
                                  type="number"
                                  className={
                                    validation.touched.production_time &&
                                    validation.errors.production_time
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-production-input1"
                                  // placeholder="Enter production time in number of days"
                                  onChange={e => {
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.production_time || ""
                                  }
                                />
                                {validation.touched.production_time &&
                                validation.errors.production_time ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.production_time}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-incoterm-city">
                                  Incoterm City (port){" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className={
                                    validation.touched.incoterm_city_id &&
                                    validation.errors.incoterm_city_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="incoterm_city_id"
                                  id="add-offer-incoterm-city"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.incoterm_city_id || ""
                                  }
                                >
                                  <option value="">Choose...</option>
                                  {bdIncotermCity &&
                                    bdIncotermCity.map(
                                      (bdIncotermCity, icc) => (
                                        <option
                                          key={"icc" + icc}
                                          value={bdIncotermCity.id}
                                        >
                                          {bdIncotermCity.city}
                                        </option>
                                      )
                                    )}
                                </select>
                                {validation.touched.incoterm_city_id &&
                                validation.errors.incoterm_city_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.incoterm_city_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-material-standard">
                                  Material Standard{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className={
                                    validation.touched.material_standard_id &&
                                    validation.errors.material_standard_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="material_standard_id"
                                  id="add-offer-material-standard"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.material_standard_id || ""
                                  }
                                >
                                  <option value="">Choose...</option>
                                  {bdMaterialStandard &&
                                    bdMaterialStandard.map(
                                      (bdMaterialStandard, ms) => (
                                        <option
                                          key={"ms" + ms}
                                          value={bdMaterialStandard.id}
                                        >
                                          {bdMaterialStandard.material_standard}
                                        </option>
                                      )
                                    )}
                                </select>
                                {validation.touched.material_standard_id &&
                                validation.errors.material_standard_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.material_standard_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-packing-standard">
                                  Packing Standard{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className={
                                    validation.touched.packing_standard_id &&
                                    validation.errors.packing_standard_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="packing_standard_id"
                                  id="add-offer-packing-standard"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.packing_standard_id || ""
                                  }
                                >
                                  <option value="">Choose...</option>
                                  {bdPackingStandard &&
                                    bdPackingStandard.map(
                                      (bdPackingStandard, ps) => (
                                        <option
                                          key={"ps" + ps}
                                          value={bdPackingStandard.id}
                                        >
                                          {bdPackingStandard.packing_standard}
                                        </option>
                                      )
                                    )}
                                </select>
                                {validation.touched.packing_standard_id &&
                                validation.errors.packing_standard_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.packing_standard_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-env&hlth-certification">
                                  Environmental and Health Certification{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className={
                                    validation.touched.env_health_id &&
                                    validation.errors.env_health_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="env_health_id"
                                  id="add-offer-env&hlth-certification"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.env_health_id || ""}
                                >
                                  <option value="">Choose...</option>
                                  {bdEnvironmentalAndHealthCertification &&
                                    bdEnvironmentalAndHealthCertification.map(
                                      (
                                        bdEnvironmentalAndHealthCertification,
                                        enhc
                                      ) => (
                                        <option
                                          key={"enhc" + enhc}
                                          value={
                                            bdEnvironmentalAndHealthCertification.id
                                          }
                                        >
                                          {
                                            bdEnvironmentalAndHealthCertification.certs
                                          }
                                        </option>
                                      )
                                    )}
                                </select>
                                {validation.touched.env_health_id &&
                                validation.errors.env_health_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.env_health_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-compliance-input1">
                                  NON-compliance
                                </Label>
                                <textarea
                                  name="non_compliance"
                                  id="basicpill-compliance-input1"
                                  className="form-control"
                                  rows="2"
                                  // placeholder="Free Text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.non_compliance || ""}
                                  onKeyDown={e => {
                                    e.key === "Enter" && e.preventDefault()
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-comments-input1">
                                  SBN comments
                                </Label>
                                <textarea
                                  name="sbn_comments"
                                  id="basicpill-comments-input1"
                                  className="form-control"
                                  rows="2"
                                  // placeholder="Free Text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.sbn_comments || ""}
                                  onKeyDown={e => {
                                    e.key === "Enter" && e.preventDefault()
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-quality-input1">
                                Product and Material Details
                                </Label>
                                <textarea
                                  name="quality_control"
                                  id="basicpill-quality-input1"
                                  className="form-control"
                                  rows="2"
                                  // placeholder="Free Text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.quality_control || ""
                                  }
                                  onKeyDown={e => {
                                    e.key === "Enter" && e.preventDefault()
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-color-input1">
                                  Color code and Gloss
                                </Label>
                                <textarea
                                  name="color_code_gloss"
                                  id="basicpill-color-input1"
                                  className="form-control"
                                  rows="2"
                                  placeholder="Free Text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.color_code_gloss || ""
                                  }
                                  onKeyDown={e => {
                                    e.key === "Enter" && e.preventDefault()
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader targetId="2">
                          <b>Offer Price</b>
                        </AccordionHeader>
                        <AccordionBody accordionId="2">
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <Label htmlFor="basicpill-price-input5">
                                  Purchase Price (FSC)
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="purchase_price_fsc"
                                  type="text"
                                  autoComplete="off"
                                  className={
                                    validation.touched.purchase_price_fsc &&
                                    validation.errors.purchase_price_fsc
                                      ? "purchase_price_fsc is-invalid"
                                      : "purchase_price_fsc"
                                  }
                                  onChange={e => (
                                    validation.handleChange(e),
                                    on_purchase_price_change(e, "fsc")
                                  )}
                                  id="basicpill-price-input5"
                                  // placeholder="Enter purchase price (FSC)."
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.purchase_price_fsc || ""
                                  }
                                />
                                {validation.touched.purchase_price_fsc &&
                                validation.errors.purchase_price_fsc ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.purchase_price_fsc}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            {/* <Col>
                              <div className="mb-3">
                                <Label htmlFor="basicpill-Yearly-input6">
                                  Yearly Purchase (FSC)
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="yearly_purchase_fsc"
                                  type="text"
                                  autoComplete="off"
                                  readOnly={true}
                                  disabled
                                  className={
                                    validation.touched.yearly_purchase_fsc &&
                                    validation.errors.yearly_purchase_fsc
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-year-input6"
                                  // placeholder="Enter yearly price (FSC)"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.yearly_purchase_fsc || ""
                                  }
                                />
                                {validation.touched.yearly_purchase_fsc &&
                                validation.errors.yearly_purchase_fsc ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.yearly_purchase_fsc}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col> */}
                          </Row>
                          {/* <Row>
                            <Col>
                              <div>
                                <Label htmlFor="basicpill-purchase-price-input10">
                                  Purchase Price (NON FSC)
                                </Label>
                                <Input
                                  name="purchase_price_non_fsc"
                                  type="text"
                                  autoComplete="off"
                                  className="form-control purchase_price_non_fsc"
                                  id="basicpill-purchase-price-input10"
                                  // placeholder="Enter purchase price (NON FSC)"
                                  onChange={e => {
                                    validation.handleChange(e)
                                    on_purchase_price_change(e, "non_fsc")
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.purchase_price_non_fsc ||
                                    ""
                                  }
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="mb-3">
                                <Label
                                  htmlFor="basicpill-yearly-price-non-input10"
                                  className="mb-2"
                                >
                                  Yearly Purchase (NON FSC)
                                </Label>
                                <Input
                                  name="yearly_purchase_non_fsc"
                                  type="text"
                                  autoComplete="off"
                                  readOnly={true}
                                  disabled
                                  className="form-control yearly_purchase_non_fsc"
                                  id="basicpill-yearly-purchase-non-input10"
                                  // placeholder="Enter yearly price (NON FSC)"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.yearly_purchase_non_fsc ||
                                    ""
                                  }
                                />
                              </div>
                            </Col>
                          </Row> */}
                          <Row>
                            <Col lg="12">
                              <div className="mb-2 my-1">
                                <Label htmlFor="basicpill-com-input1">
                                  Purchasing Price Comments
                                </Label>
                                <textarea
                                  name="purchasing_price_comments"
                                  id="basicpill-com-input1"
                                  className="form-control"
                                  rows="2"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values
                                      .purchasing_price_comments || ""
                                  }
                                  onKeyDown={e => {
                                    e.key === "Enter" && e.preventDefault()
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader targetId="3">
                          <b>Offer Packing</b>
                        </AccordionHeader>
                        <AccordionBody accordionId="3">
                          <Row>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-truck-input11">
                                  Full Truck/40HC
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="full_truck"
                                  type="text"
                                  className={
                                    validation.touched.full_truck &&
                                    validation.errors.full_truck
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-truck-input11"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.full_truck || ""}
                                />
                                {validation.touched.full_truck &&
                                validation.errors.full_truck ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.full_truck}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-20st-input11">
                                  20ST Container
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="container"
                                  type="text"
                                  className={
                                    validation.touched.container &&
                                    validation.errors.container
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-20st-input11"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.container || ""}
                                />
                                {validation.touched.container &&
                                validation.errors.container ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.container}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-moq-input11">
                                  MOQ
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="moq"
                                  type="text"
                                  className={
                                    validation.touched.moq &&
                                    validation.errors.moq
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-moq-input11"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.moq || ""}
                                />
                                {validation.touched.moq &&
                                validation.errors.moq ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.moq}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-net-input11">
                                  Net Weight
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="net_weight"
                                  type="text"
                                  className={
                                    validation.touched.net_weight &&
                                    validation.errors.net_weight
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-net-input11"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.net_weight || ""}
                                />
                                {validation.touched.net_weight &&
                                validation.errors.net_weight ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.net_weight}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-gross-input12">
                                  Gross Weight
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="gross_weight"
                                  type="text"
                                  className={
                                    validation.touched.gross_weight &&
                                    validation.errors.gross_weight
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-gross-input12"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.gross_weight || ""}
                                />
                                {validation.touched.gross_weight &&
                                validation.errors.gross_weight ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.gross_weight}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-packaging">
                                  Packaging{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className={
                                    validation.touched.packaging_id &&
                                    validation.errors.packaging_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="packaging_id"
                                  id="add-offer-packaging"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.packaging_id || ""}
                                >
                                  <option value="">Choose...</option>
                                  {bdPackaging &&
                                    bdPackaging.map((bdPackaging, pck) => (
                                      <option
                                        key={"pck" + pck}
                                        value={bdPackaging.id}
                                      >
                                        {bdPackaging.packaging}
                                      </option>
                                    ))}
                                </select>
                                {validation.touched.packaging_id &&
                                validation.errors.packaging_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.packaging_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-delivered-on">
                                  Delivered On{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className={
                                    validation.touched.delivered_on &&
                                    validation.errors.delivered_on
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="delivered_on"
                                  id="add-offer-delivered-on"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.delivered_on || ""}
                                >
                                  <option value="">Choose...</option>
                                  {bdDeliveredOn &&
                                    bdDeliveredOn.map((bdDeliveredOn, dn) => (
                                      <option
                                        key={"dn" + dn}
                                        value={bdDeliveredOn.id}
                                      >
                                        {bdDeliveredOn.delivery_name}
                                      </option>
                                    ))}
                                </select>
                                {validation.touched.delivered_on &&
                                validation.errors.delivered_on ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.delivered_on}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="add-offer-heavey-colli">
                                  Heavy Colli{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className={
                                    validation.touched.heavy_colli_id &&
                                    validation.errors.heavy_colli_id
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  name="heavy_colli_id"
                                  id="add-offer-heavey-colli"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.heavy_colli_id || ""}
                                >
                                  <option value="">Choose...</option>
                                  {bdHeaveyColli &&
                                    bdHeaveyColli.map((bdHeaveyColli, hc) => (
                                      <option
                                        key={"hc" + hc}
                                        value={bdHeaveyColli.id}
                                      >
                                        {bdHeaveyColli.heavy_collis}
                                      </option>
                                    ))}
                                </select>
                                {validation.touched.heavy_colli_id &&
                                validation.errors.heavy_colli_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.heavy_colli_id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-npb-input12">
                                  Number of Units per Box
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="units_per_box"
                                  type="text"
                                  className={
                                    validation.touched.units_per_box &&
                                    validation.errors.units_per_box
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpil-npb-input12"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.units_per_box || ""}
                                />
                                {validation.touched.units_per_box &&
                                validation.errors.units_per_box ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.units_per_box}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-bom-input12">
                                  BOM per article
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="bom_per_article"
                                  type="text"
                                  className={
                                    validation.touched.bom_per_article &&
                                    validation.errors.bom_per_article
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="basicpill-bom-input12"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.bom_per_article || ""
                                  }
                                />
                                {validation.touched.bom_per_article &&
                                validation.errors.bom_per_article ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.bom_per_article}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-mcb-input12">
                                  Master Cardboard Box
                                </Label>
                                <Input
                                  autoComplete="off"
                                  name="master_cardboard_box"
                                  type="text"
                                  className="form-control"
                                  id="basicpill-mcb-input12"
                                  // placeholder="Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.master_cardboard_box || ""
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <div className="mb-3">
                                <Label htmlFor="basicpill-pc-input1">
                                  Packing comments
                                </Label>
                                <textarea
                                  name="packing_comments"
                                  id="basicpill-pc-input1"
                                  className="form-control"
                                  rows="2"
                                  // placeholder="Free Text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.packing_comments || ""
                                  }
                                  onKeyDown={e => {
                                    e.key === "Enter" && e.preventDefault()
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </AccordionBody>
                      </AccordionItem>
                    </Accordion>
                    <br />
                    <Row>
                      <Col>
                        <Button
                          type="submit"
                          className="btn btn-primary w-md"
                          disabled={bdOffer && bdOffer.loading ? true : false}
                        >
                          {bdOffer && bdOffer.loading
                            ? "Updating Offer"
                            : "Update Offer"}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <section className="error-message">
                    {errorsArr && errorsArr.length
                      ? errorsArr.map((er, ei) => {
                          if (er.errorPresent) {
                            return (
                              <ul key={`ei_` + ei}>
                                <li className="text-danger">
                                  <b>{er.section}</b>
                                </li>
                                <ul>
                                  {er.children.map((ce, ci) => {
                                    if (ce.errorMessage) {
                                      return (
                                        <li
                                          key={`ce_` + ci}
                                          className="text-danger"
                                        >
                                          {ce.errorMessage}
                                        </li>
                                      )
                                    }
                                  })}
                                </ul>
                              </ul>
                            )
                          }
                        })
                      : null}
                  </section>

                  {/* below code will be useful when we do not need the validation section.*/}
                  {/* {Object.keys(validation.errors).some(
                    key => validation.touched[key] && !validation.values[key]
                  ) && (
                    <div className="error-message">
                      <ul>
                        {Object.keys(validation.errors).map((key, index) => {
                          if (
                            validation.touched[key] &&
                            !validation.values[key]
                          ) {
                            return (
                              <li key={index}>{validation.errors[key]}.</li>
                            )
                          }
                          return null
                        })}
                      </ul>
                    </div>
                  )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BusinessDeveloperGetOfferDetailForUpdate
