import React, { useState, useEffect } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import * as Yup from "yup"
import { useFormik } from "formik"
import classnames from "classnames"
import { Link } from "react-router-dom"
import { useParams, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  supplierNameListing,
  offerStatusListing,
  incotermCityListing,
  materialStandardListing,
  packingStandardListing,
  environmentalAndHealthCertificationListing,
  packagingListing,
  deliveredOnListing,
  heaveyColliListing,
  userListAccordingRoleListing,
  addNewOffer,
} from "../../../store/actions"

const AddOffer = props => {
  document.title = "Add Offer"
  const { id, prod_no } = useParams()

  const dispatch = useDispatch()
  const defaultMargin = 0.8 // 20% margin =>  (100-20)/100

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  useEffect(() => {
    dispatch(supplierNameListing())
    dispatch(offerStatusListing())
    dispatch(incotermCityListing())
    dispatch(materialStandardListing())
    dispatch(packingStandardListing())
    dispatch(environmentalAndHealthCertificationListing())
    dispatch(packagingListing())
    dispatch(deliveredOnListing())
    dispatch(heaveyColliListing())
    dispatch(userListAccordingRoleListing({ user_role: "qa" }, props.history))
  }, [])

  const [details, setDetails] = useState({}) // this is for calculation
  const [errorsArr, SetErrorsArr] = useState([]) // validation section

  const [supplierName, setSupplierName] = useState([])
  const [supplierNameDefault, setsupplierNameDefault] = useState([])

  const [offerStatus, setOfferStatus] = useState([])
  const [offerStatusDefault, setOfferStatusDefault] = useState([])

  const [incotermCity, setIncotermCity] = useState([])
  const [incotermCityDefault, setincotermCityDefault] = useState([])

  const [materialStandard, setMaterialStandard] = useState([])
  const [materialStandardDefault, setmaterialStandardDefault] = useState([])

  const [packingStandard, setPackingStandard] = useState([])
  const [packingStandardDataDefault, setpackingStandardDataDefault] = useState(
    []
  )

  const [
    environmentalAndHealthCertification,
    setEnvironmentalAndHealthCertification,
  ] = useState([])
  const [
    environmentalAndHealthCertificationDefault,
    setenvironmentalAndHealthCertificationDefault,
  ] = useState([])

  const [packaging, setPackaging] = useState([])
  const [packagingDefault, setPackagingDefault] = useState([])

  const [deliveredOn, setDeliveredOn] = useState([])
  const [deliveredOnDataDefault, setdeliveredOnDataDefault] = useState([])

  const [heaveyColli, setHeaveyColli] = useState([])
  const [heaveyColliDefault, setheaveyColliDefault] = useState([])

  const [userListAccordingRole, setUserListAccordingRole] = useState([])
  const [userListAccordingRoleDefault, setuserListAccordingRoleDefault] =
    useState([])

  const products = useSelector(state => state.Product)
  const supplierNameData = useSelector(state => state.supplierName.supplierName) ///1st supplierName is from reducer
  const offerStatusData = useSelector(state => state.offerStatus.offerStatus)
  const incotermCityData = useSelector(state => state.incotermCity.incotermCity)

  const materialStandardData = useSelector(
    state => state.materialStandard.materialStandard
  )
  const packingStandardData = useSelector(
    state => state.packingStandard.packingStandard
  )

  const environmentalAndHealthCertificationData = useSelector(
    state =>
      state.environmentalAndHealthCertification
        .environmentalAndHealthCertification
  )
  const packagingData = useSelector(state => state.packaging.packaging)
  const deliveredOnData = useSelector(state => state.deliveredOn.deliveredOn)
  const heaveyColliData = useSelector(state => state.heaveyColli.heaveyColli)
  const userListAccordingRoleData = useSelector(
    state => state.userListAccordingRole.userListAccordingRole
  )

  useEffect(() => {
    let prods = []
    if (products && products.product && products.product.length > 0) {
      prods = products.product.filter(product => product.prod_id == id)
    }
    if (prods.length > 0) {
      setDetails(prods[0])
    } else {
      props.history.push("/products/list")
    }
    setSupplierName(supplierNameData)
    if (supplierNameData && supplierNameData.length > 0) {
      setsupplierNameDefault(
        supplierNameData.filter((supplier, pc) => {
          return supplier.is_default == 1
        })
      )
    }

    setOfferStatus(offerStatusData)
    if (offerStatusData && offerStatusData.length > 0) {
      setOfferStatusDefault(
        offerStatusData.filter((offer, pc) => {
          return offer.is_default == 1
        })
      )
    }

    setIncotermCity(incotermCityData)
    if (incotermCityData && incotermCityData.length > 0) {
      setincotermCityDefault(
        incotermCityData.filter((incoterm, pc) => {
          return incoterm.is_default == 1
        })
      )
    }
    setMaterialStandard(materialStandardData)
    if (materialStandardData && materialStandardData.length > 0) {
      setmaterialStandardDefault(
        materialStandardData.filter((ms, pc) => {
          return ms.is_default == 1
        })
      )
    }
    setPackingStandard(packingStandardData)
    if (packingStandardData && packingStandardData.length > 0) {
      setpackingStandardDataDefault(
        packingStandardData.filter((pck, pc) => {
          return pck.is_default == 1
        })
      )
    }
    setEnvironmentalAndHealthCertification(
      environmentalAndHealthCertificationData
    )
    if (
      environmentalAndHealthCertificationData &&
      environmentalAndHealthCertificationData.length > 0
    ) {
      setenvironmentalAndHealthCertificationDefault(
        environmentalAndHealthCertificationData.filter((env, pc) => {
          return env.is_default == 1
        })
      )
    }

    setPackaging(packagingData)
    if (packagingData && packagingData.length > 0) {
      setPackagingDefault(
        packagingData.filter((pack, pc) => {
          return pack.is_default == 1
        })
      )
    }

    setDeliveredOn(deliveredOnData)
    if (deliveredOnData && deliveredOnData.length > 0) {
      setdeliveredOnDataDefault(
        deliveredOnData.filter((dev, pc) => {
          return dev.is_default == 1
        })
      )
    }
    setHeaveyColli(heaveyColliData)
    if (heaveyColliData && heaveyColliData.length > 0) {
      setheaveyColliDefault(
        heaveyColliData.filter((hevy, pc) => {
          return hevy.is_default == 1
        })
      )
    }
    setUserListAccordingRole(userListAccordingRoleData)
    if (userListAccordingRoleData && userListAccordingRoleData.length > 0) {
      setuserListAccordingRoleDefault(
        userListAccordingRoleDefault.filter((ul, pc) => {
          return ul.is_default == 1
        })
      )
    }
  }, [
    supplierNameData,
    offerStatusData,
    incotermCityData,
    materialStandardData,
    packingStandardData,
    environmentalAndHealthCertificationData,
    packagingData,
    deliveredOnData,
    heaveyColliData,
    userListAccordingRoleData,
  ])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      prod_id: id,
      offer_status_id:
        offerStatusDefault && offerStatusDefault.length > 0
          ? offerStatusDefault[0]["id"]
          : "",
      vendor_article_no: "",
      supplier_id:
        supplierNameDefault && supplierNameDefault.length
          ? supplierNameDefault[0]["id"]
          : "",
      production_time: "",
      incoterm_city_id:
        incotermCityDefault && incotermCityDefault.length > 0
          ? incotermCityDefault[0]["id"]
          : "",
      material_standard_id:
        materialStandardDefault && materialStandardDefault.length > 0
          ? materialStandardDefault[0]["id"]
          : "",
      packing_standard_id:
        packingStandardDataDefault && packingStandardDataDefault.length > 0
          ? packingStandardDataDefault[0]["id"]
          : "",
      env_health_id:
        environmentalAndHealthCertificationDefault &&
        environmentalAndHealthCertificationDefault.length > 0
          ? environmentalAndHealthCertificationDefault[0]["id"]
          : "",
      non_compliance: "",
      sbn_comments: "",
      quality_control: "",
      color_code_gloss: "",
      purchase_price_fsc: "",
      yearly_purchase_fsc: "",
      purchase_price_non_fsc: "",
      yearly_purchase_non_fsc: "",
      purchasing_price_comments: "",
      sales_price_fsc: "",
      sales_margin_fsc: "",
      yearly_sales_fsc: "",
      sales_price_non_fsc: "",
      sales_margin_non_fsc: "",
      yearly_sales_non_fsc: "",
      full_truck: "",
      container: "",
      moq: "",
      net_weight: "",
      gross_weight: "",
      packaging_id:
        packagingDefault && packagingDefault.length > 0
          ? packagingDefault[0]["id"]
          : "",
      delivered_on:
        deliveredOnDataDefault && deliveredOnDataDefault.length > 0
          ? deliveredOnDataDefault[0]["id"]
          : "",
      heavy_colli_id:
        heaveyColliDefault && heaveyColliDefault.length > 0
          ? heaveyColliDefault[0]["id"]
          : "",
      units_per_box: "",
      bom_per_article: "",
      master_cardboard_box: "",
      packing_comments: "",
      qa_user_id:
        userListAccordingRoleDefault && userListAccordingRoleDefault.length > 0
          ? userListAccordingRoleDefault[0]["id"]
          : "",
      sample_request: "",
      sample_comments: "",
      sample_status: "",
      qa_sample_checking_comments: "",
      sales_sample_checking_comments: "",
      sample_freight_cost: "",
      sample_main_material: "",
      sample_hardware: "",
      sample_surface_treatment: "",
    },
    validationSchema: Yup.object({
      offer_status_id: Yup.string().required("Offer status is required"),

      supplier_id: Yup.string().required("Supplier is required"),

      incoterm_city_id: Yup.string().required("Incoterm is required"),

      production_time: Yup.string()
        .required("Production time is required. this is must be a number.")
        .matches(
          /^[0-9]+$/,
          "Positive numbers are allowed without dots, negetive or decimal"
        ),

      material_standard_id: Yup.string().required(
        "Material standard is required"
      ),
      packing_standard_id: Yup.string().required(
        "Packing standard is required"
      ),
      env_health_id: Yup.string().required("Env health is required"),

      purchase_price_fsc: Yup.number().required("Purchase FSC is required"),
      yearly_purchase_fsc: Yup.number(),
      sales_price_fsc: Yup.number().required("Sales price FSC is required"),
      sales_margin_fsc: Yup.number(),
      yearly_sales_fsc: Yup.number(),

      purchase_price_non_fsc: Yup.number(),
      yearly_purchase_non_fsc: Yup.number(),
      sales_price_non_fsc: Yup.number(),
      sales_margin_non_fsc: Yup.number(),
      yearly_sales_non_fsc: Yup.number(),

      full_truck: Yup.number().required("Full truck is required"),
      container: Yup.number().required("20ST Container is required"),
      moq: Yup.number().required("Moq is required"),
      net_weight: Yup.number().required("Net weight is required"),
      gross_weight: Yup.number().required("Gross weight is required"),
      packaging_id: Yup.string().required("Packaging is required"),
      delivered_on: Yup.string().required("Delivered on is required"),
      heavy_colli_id: Yup.string().required("Heavy colli is required"),
      units_per_box: Yup.number().required("Units per box is required"),
      bom_per_article: Yup.number().required("BOM per article is required"),
      // user_type_id: Yup.string().required("Sample QA is required"),
    }),
    onSubmit: values => {
      dispatch(addNewOffer(values, props.history))
    },
  })

  let validationSections = [
    {
      section: "Offer Details",
      errorPresent: false,
      children: [
        { name: "offer_status_id", errorMessage: null },
        { name: "supplier_id", errorMessage: null },
        { name: "incoterm_city_id", errorMessage: null },
        { name: "production_time", errorMessage: null },
        { name: "material_standard_id", errorMessage: null },
        { name: "packing_standard_id", errorMessage: null },
        { name: "env_health_id", errorMessage: null },
      ],
    },
    {
      section: "Offer Price",
      errorPresent: false,
      children: [
        { name: "purchase_price_fsc", errorMessage: null },
        { name: "sales_price_fsc", errorMessage: null },
      ],
    },
    {
      section: "Offer Packing",
      errorPresent: false,
      children: [
        { name: "full_truck", errorMessage: null },
        { name: "container", errorMessage: null },
        { name: "moq", errorMessage: null },
        { name: "net_weight", errorMessage: null },
        { name: "gross_weight", errorMessage: null },
        { name: "packaging_id", errorMessage: null },
        { name: "delivered_on", errorMessage: null },
        { name: "heavy_colli_id", errorMessage: null },
        { name: "units_per_box", errorMessage: null },
        { name: "bom_per_article", errorMessage: null },
      ],
    },
  ]

  useEffect(() => {
    if (
      Object.keys(validation.touched).length &&
      Object.keys(validation.errors).length
    ) {
      let currentValidation = [...validationSections]
      for (const error in validation.errors) {
        currentValidation.forEach(section => {
          // Find the matching child by name
          const child = section.children.find(c => c.name === error)
          // If a matching child is found, update its errorMessage
          if (child && validation?.errors[error] !== "") {
            child.errorMessage = validation.errors[error]
            section.errorPresent = true
          } else if (child && validation?.errors[error] === "") {
            child.errorMessage = null
          }
        })
        SetErrorsArr(currentValidation)
      }
    } else {
      SetErrorsArr([])
    }
  }, [validation.errors, validation.touched])

  function updateDecimalPortion(data = 0.0) {
    data = parseFloat(data)
    data = data.toFixed(2)

    let updated_number = 0.0
    let left_portion = parseFloat((data + "").split(".")[0])
    let decimal_portion = parseFloat((data + "").split(".")[1])
    if (left_portion >= 0 && left_portion <= 30) {
      if (decimal_portion > 0 && decimal_portion < 25) {
        updated_number = left_portion.toString() + ".25"
      } else if (decimal_portion > 25 && decimal_portion <= 49) {
        updated_number = left_portion.toString() + ".50"
      } else if (decimal_portion > 50 && decimal_portion <= 74) {
        updated_number = left_portion.toString() + ".75"
      } else if (decimal_portion > 75 && decimal_portion <= 99) {
        updated_number = (left_portion + 1).toFixed(2)
      } else {
        updated_number = left_portion.toFixed(2)
      }
    } else if (left_portion >= 30 && left_portion <= 50) {
      if (decimal_portion > 0 && decimal_portion < 50) {
        updated_number = left_portion.toString() + ".50"
      } else {
        updated_number = (left_portion + 1).toFixed(2)
      }
    } else {
      if (decimal_portion > 0) {
        updated_number = (left_portion + 1).toFixed(2)
      } else {
        updated_number = left_portion.toFixed(2)
      }
    }
    updated_number = parseFloat(updated_number).toFixed(2)
    return updated_number
  }

  function yearly_purchase_fsc(e) {
    let purchase_price_fsc = isNaN(e.target.value) ? 0 : e.target.value
    let optionValue = purchase_price_fsc * details.yearly_forecast
    let sales_price_fsc1 = purchase_price_fsc / defaultMargin
    let yearly_sales_fsc = sales_price_fsc1 * details.yearly_forecast

    yearly_sales_fsc = yearly_sales_fsc.toFixed(2)
    let sales_price_fsc = sales_price_fsc1.toFixed(2)
    sales_price_fsc = updateDecimalPortion(sales_price_fsc)
    let sales_margin_fsc =
      ((sales_price_fsc - purchase_price_fsc) / sales_price_fsc) * 100
    validation.setFieldValue(
      "yearly_purchase_fsc",
      parseFloat(optionValue).toFixed(2)
    )
    validation.setFieldValue(
      "sales_price_fsc",
      parseFloat(sales_price_fsc).toFixed(2)
    )
    validation.setFieldValue(
      "sales_margin_fsc",
      parseFloat(sales_margin_fsc).toFixed(1)
    )
    validation.setFieldValue(
      "yearly_sales_fsc",
      parseFloat(yearly_sales_fsc).toFixed(2)
    )
  }

  function yearly_sales_fsc(e) {
    let number = isNaN(e.target.value) ? 0 : e.target.value
    let optionValue = number * details.yearly_forecast
    validation.setFieldValue("yearly_sales_fsc", optionValue.toFixed(2))
    sales_margin_fsc()
  }

  function sales_margin_fsc() {
    let purchase_price_fsc = document.getElementsByClassName(
      "purchase_price_fsc"
    )[0].value
      ? parseFloat(
          document.getElementsByClassName("purchase_price_fsc")[0].value
        )
      : 0
    let sales_price_fsc = document.getElementsByClassName("sales_price_fsc")[0]
      .value
      ? parseFloat(document.getElementsByClassName("sales_price_fsc")[0].value)
      : 0
    if (sales_price_fsc > 0) {
      let sales_margin_fsc =
        ((sales_price_fsc - purchase_price_fsc) / sales_price_fsc) * 100
      validation.setFieldValue("sales_margin_fsc", sales_margin_fsc.toFixed(1))
    } else {
      validation.setFieldValue("sales_margin_fsc", 0.0)
    }
  }

  function yearly_purchase_non_fsc(e) {
    let purchase_price_non_fsc = isNaN(e.target.value) ? 0 : e.target.value

    let optionValue = purchase_price_non_fsc * details.yearly_forecast
    validation.setFieldValue("yearly_purchase_non_fsc", optionValue)

    let sales_price_non_fsc = purchase_price_non_fsc / defaultMargin
    sales_price_non_fsc = updateDecimalPortion(sales_price_non_fsc)

    let sales_margin_non_fsc =
      ((sales_price_non_fsc - purchase_price_non_fsc) / sales_price_non_fsc) *
      100

    let yearly_sales_non_fsc = sales_margin_non_fsc * details.yearly_forecast
    yearly_sales_non_fsc = yearly_sales_non_fsc.toFixed(2)

    validation.setFieldValue(
      "yearly_purchase_non_fsc",
      parseFloat(optionValue).toFixed(2)
    )
    validation.setFieldValue(
      "sales_price_non_fsc",
      parseFloat(sales_price_non_fsc).toFixed(2)
    )
    validation.setFieldValue(
      "sales_margin_non_fsc",
      parseFloat(sales_margin_non_fsc).toFixed(1)
    )
    validation.setFieldValue(
      "yearly_sales_non_fsc",
      parseFloat(yearly_sales_non_fsc).toFixed(2)
    )
    // sales_margin_non_fsc()
  }

  function yearly_sales_non_fsc(e) {
    let number = isNaN(e.target.value) ? 0 : e.target.value
    let optionValue = number * details.yearly_forecast
    validation.setFieldValue("yearly_sales_non_fsc", optionValue)
    sales_margin_non_fsc()
  }

  function sales_margin_non_fsc() {
    let purchase_price_non_fsc = document.getElementsByClassName(
      "purchase_price_non_fsc"
    )[0].value
      ? parseFloat(
          document.getElementsByClassName("purchase_price_non_fsc")[0].value
        )
      : 0
    let sales_price_non_fsc = document.getElementsByClassName(
      "sales_price_non_fsc"
    )[0].value
      ? parseFloat(
          document.getElementsByClassName("sales_price_non_fsc")[0].value
        )
      : 0
    if (sales_price_non_fsc > 0) {
      let sales_margin_non_fsc =
        ((sales_price_non_fsc - purchase_price_non_fsc) / sales_price_non_fsc) *
        100
      validation.setFieldValue(
        "sales_margin_non_fsc",
        sales_margin_non_fsc.toFixed(1)
      )
    } else {
      validation.setFieldValue("sales_margin_non_fsc", 0.0)
    }
  }

  const offer = useSelector(state => state.offer)
  const { error, msg } = useSelector(state => ({
    error: state.offer.error,
    msg: state.offer.msg ? state.offer.msg : "",
  }))

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Add Offer" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {prod_no ? (
                    <h4 className="card-title mb-4">
                      Product Number :- {prod_no}
                    </h4>
                  ) : (
                    ""
                  )}
                  {msg || error ? (
                    <ToastContainer className="Toastify__close-button" />
                  ) : (
                    ""
                  )}

                  <Form
                    id="addOfferForm"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === 1,
                              })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">01</span> Offer Details
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 2,
                              })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number ms-2">02</span> Offer
                              Price
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 3,
                              })}
                              onClick={() => {
                                setactiveTab(3)
                              }}
                              disabled={!(passedSteps || []).includes(3)}
                            >
                              <span className="number">03</span> Offer Packing
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 4 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 4,
                              })}
                              onClick={() => {
                                setactiveTab(4)
                              }}
                              disabled={!(passedSteps || []).includes(4)}
                            >
                              <span className="number">04</span> Sample Details
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix mt-4 addformsection">
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId={1}>
                            {/* <Form> */}
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-offer-status">
                                    Offer Status{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.offer_status_id &&
                                      validation.errors.offer_status_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="offer_status_id"
                                    id="add-offer-offer-status"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.offer_status_id || ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {offerStatus &&
                                      offerStatus.map((offerStatus, ofs) => (
                                        <option
                                          key={"ofs" + ofs}
                                          value={offerStatus.id}
                                        >
                                          {offerStatus.offer_status}
                                        </option>
                                      ))}
                                  </select>
                                  {validation.touched.offer_status_id &&
                                  validation.errors.offer_status_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.offer_status_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-Vendor-article-number">
                                    Vendor Article Number
                                  </Label>
                                  <Input
                                    autoComplete="off"
                                    name="vendor_article_no"
                                    type="text"
                                    className="form-control"
                                    id="add-offer-Vendor-article-number"
                                    placeholder="Enter vender article number"
                                    onChange={e => {
                                      validation.handleChange(e)
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.vendor_article_no || ""
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-supplier-id">
                                    Supplier Name{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.supplier_id &&
                                      validation.errors.supplier_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="supplier_id"
                                    id="add-offer-supplier-id"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.supplier_id || ""}
                                  >
                                    <option value="">Choose...</option>
                                    {supplierName &&
                                      supplierName.map((supplierName, snk) => (
                                        <option
                                          key={"snk" + snk}
                                          value={supplierName.id}
                                        >
                                          {supplierName.name}
                                        </option>
                                      ))}
                                  </select>
                                  {validation.touched.supplier_id &&
                                  validation.errors.supplier_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.supplier_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-production-input1">
                                    Production Time{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    autoComplete="off"
                                    name="production_time"
                                    type="number"
                                    className={
                                      validation.touched.production_time &&
                                      validation.errors.production_time
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="basicpill-production-input1"
                                    placeholder="Enter production time in number of days"
                                    onChange={e => {
                                      validation.handleChange(e)
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.production_time || ""
                                    }
                                  />
                                  {validation.touched.production_time &&
                                  validation.errors.production_time ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.production_time}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-incoterm-city">
                                    Incoterm City (port){" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.incoterm_city_id &&
                                      validation.errors.incoterm_city_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="incoterm_city_id"
                                    id="add-offer-incoterm-city"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.incoterm_city_id || ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {incotermCity &&
                                      incotermCity.map((incotermCity, icc) => (
                                        <option
                                          key={"icc" + icc}
                                          value={incotermCity.id}
                                        >
                                          {incotermCity.city}
                                        </option>
                                      ))}
                                  </select>
                                  {validation.touched.incoterm_city_id &&
                                  validation.errors.incoterm_city_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.incoterm_city_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-material-standard">
                                    Material Standard{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.material_standard_id &&
                                      validation.errors.material_standard_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="material_standard_id"
                                    id="add-offer-material-standard"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.material_standard_id ||
                                      ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {materialStandard &&
                                      materialStandard.map(
                                        (materialStandard, ms) => (
                                          <option
                                            key={"ms" + ms}
                                            value={materialStandard.id}
                                          >
                                            {materialStandard.material_standard}
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {validation.touched.material_standard_id &&
                                  validation.errors.material_standard_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.material_standard_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-packing-standard">
                                    Packing Standard{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.packing_standard_id &&
                                      validation.errors.packing_standard_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="packing_standard_id"
                                    id="add-offer-packing-standard"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.packing_standard_id ||
                                      ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {packingStandard &&
                                      packingStandard.map(
                                        (packingStandard, ps) => (
                                          <option
                                            key={"ps" + ps}
                                            value={packingStandard.id}
                                          >
                                            {packingStandard.packing_standard}
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {validation.touched.packing_standard_id &&
                                  validation.errors.packing_standard_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.packing_standard_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-env&hlth-certification">
                                    Environmental and Health Certification{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.env_health_id &&
                                      validation.errors.env_health_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="env_health_id"
                                    id="add-offer-env&hlth-certification"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.env_health_id || ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {environmentalAndHealthCertification &&
                                      environmentalAndHealthCertification.map(
                                        (
                                          environmentalAndHealthCertification,
                                          enhc
                                        ) => (
                                          <option
                                            key={"enhc" + enhc}
                                            value={
                                              environmentalAndHealthCertification.id
                                            }
                                          >
                                            {
                                              environmentalAndHealthCertification.certs
                                            }
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {validation.touched.env_health_id &&
                                  validation.errors.env_health_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.env_health_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-compliance-input1">
                                    NON-compliance
                                  </Label>
                                  <textarea
                                    name="non_compliance"
                                    id="basicpill-compliance-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.non_compliance || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-comments-input1">
                                    SBN comments
                                  </Label>
                                  <textarea
                                    name="sbn_comments"
                                    id="basicpill-comments-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.sbn_comments || ""}
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-quality-input1">
                                  Product and Material Details
                                  </Label>
                                  <textarea
                                    name="quality_control"
                                    id="basicpill-quality-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.quality_control || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-color-input1">
                                    Color code and Gloss
                                  </Label>
                                  <textarea
                                    name="color_code_gloss"
                                    id="basicpill-color-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.color_code_gloss || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {/* </Form> */}
                          </TabPane>
                          <TabPane tabId={2}>
                            <div>
                              {/* <Form> */}
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-price-input5">
                                      Purchase Price (FSC)
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="purchase_price_fsc"
                                      type="text"
                                      autoComplete="off"
                                      className={
                                        validation.touched.purchase_price_fsc &&
                                        validation.errors.purchase_price_fsc
                                          ? "purchase_price_fsc is-invalid"
                                          : "purchase_price_fsc"
                                      }
                                      id="basicpill-price-input5"
                                      placeholder="Enter purchase price (FSC)."
                                      onChange={e => (
                                        validation.handleChange(e),
                                        yearly_purchase_fsc(e)
                                      )}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.purchase_price_fsc ||
                                        ""
                                      }
                                    />
                                    {validation.touched.purchase_price_fsc &&
                                    validation.errors.purchase_price_fsc ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.purchase_price_fsc}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                {/* <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-Yearly-input6">
                                      Yearly Purchase (FSC)
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="yearly_purchase_fsc"
                                      type="text"
                                      autoComplete="off"
                                      readOnly={true}
                                      disabled
                                      className={
                                        validation.touched
                                          .yearly_purchase_fsc &&
                                        validation.errors.yearly_purchase_fsc
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-year-input6"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.yearly_purchase_fsc ||
                                        ""
                                      }
                                    />
                                    {validation.touched.yearly_purchase_fsc &&
                                    validation.errors.yearly_purchase_fsc ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.yearly_purchase_fsc}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col> */}
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sales-price-input7">
                                      Sales Price (FSC)
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="sales_price_fsc"
                                      type="text"
                                      autoComplete="off"
                                      className={
                                        validation.touched.sales_price_fsc &&
                                        validation.errors.sales_price_fsc
                                          ? "sales_price_fsc is-invalid"
                                          : "sales_price_fsc"
                                      }
                                      id="basicpill-sales-price-input7"
                                      placeholder="Enter sales price (FSC)"
                                      onChange={e => {
                                        validation.handleChange(e)
                                        yearly_sales_fsc(e)
                                      }}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sales_price_fsc || ""
                                      }
                                    />
                                    {validation.touched.sales_price_fsc &&
                                    validation.errors.sales_price_fsc ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.sales_price_fsc}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sales-input8">
                                      Sales Margin (FSC) in %
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="sales_margin_fsc"
                                      type="text"
                                      autoComplete="off"
                                      readOnly={true}
                                      disabled
                                      className={
                                        validation.touched.sales_margin_fsc &&
                                        validation.errors.sales_margin_fsc
                                          ? "sales_margin_fsc is-invalid"
                                          : "sales_margin_fsc"
                                      }
                                      id="basicpill-sales-input8"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sales_margin_fsc || ""
                                      }
                                    />
                                    {validation.touched.sales_margin_fsc &&
                                    validation.errors.sales_margin_fsc ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.sales_margin_fsc}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                {/* <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-yearly-sales-input9">
                                      Yearly Sales(FSC)
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="yearly_sales_fsc"
                                      type="text"
                                      autoComplete="off"
                                      readOnly={true}
                                      disabled
                                      className={
                                        validation.touched.yearly_sales_fsc &&
                                        validation.errors.yearly_sales_fsc
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-yearly-sales-input9"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.yearly_sales_fsc || ""
                                      }
                                    />
                                    {validation.touched.yearly_sales_fsc &&
                                    validation.errors.yearly_sales_fsc ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.yearly_sales_fsc}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col> */}
                              </Row>
{/* 
                              <Row className="mt-2">
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-purchase-price-input10">
                                      Purchase Price (NON FSC)
                                    </Label>
                                    <Input
                                      name="purchase_price_non_fsc"
                                      type="text"
                                      autoComplete="off"
                                      className="form-control"
                                      id="basicpill-purchase-price-input10"
                                      placeholder="Enter purchase price (NON FSC)"
                                      onChange={e => {
                                        validation.handleChange(e)
                                        yearly_purchase_non_fsc(e)
                                      }}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .purchase_price_non_fsc || ""
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-yearly-price-non-input10">
                                      Yearly Purchase (NON FSC)
                                    </Label>
                                    <Input
                                      name="yearly_purchase_non_fsc"
                                      type="text"
                                      autoComplete="off"
                                      readOnly={true}
                                      disabled
                                      className="form-control"
                                      id="basicpill-yearly-price-non-input10"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .yearly_purchase_non_fsc || ""
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sales-non-input10">
                                      Sales Price (NON FSC)
                                    </Label>
                                    <Input
                                      name="sales_price_non_fsc"
                                      type="text"
                                      autoComplete="off"
                                      className="form-control"
                                      id="basicpill-sales-non-input10"
                                      placeholder="Enter sales price (NON FSC)"
                                      onChange={e => {
                                        validation.handleChange(e)
                                        yearly_sales_non_fsc(e)
                                      }}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sales_price_non_fsc ||
                                        ""
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-margin-input10">
                                      Sales Margin (NON FSC) in %
                                    </Label>
                                    <Input
                                      name="sales_margin_non_fsc"
                                      type="text"
                                      autoComplete="off"
                                      readOnly={true}
                                      disabled
                                      className="form-control"
                                      id="basicpill-margin-input10"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .sales_margin_non_fsc || ""
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-yearly-sales-input10">
                                      Yearly Sales (NON FSC)
                                    </Label>
                                    <Input
                                      name="yearly_sales_non_fsc"
                                      type="text"
                                      autoComplete="off"
                                      className="form-control"
                                      id="basicpill-yearly-sales-input10"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      readOnly={true}
                                      disabled
                                      value={
                                        validation.values
                                          .yearly_sales_non_fsc || ""
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row> */}

                              <Row>
                                <Col className="mt-2" lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-com-input1">
                                      Purchasing Price Comments
                                    </Label>
                                    <textarea
                                      name="purchasing_price_comments"
                                      id="basicpill-com-input1"
                                      className="form-control"
                                      rows="3"
                                      placeholder="Enter Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .purchasing_price_comments || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              {/* <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <FormGroup className="mb-3">
                                      <div className="form-check">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="invalidCheck"
                                          name="export_jysk_list"
                                          value={exportJyskList}
                                          onClick={() =>
                                            setExportJyskList(!exportJyskList)
                                          }
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="invalidCheck"
                                        >
                                          {" "}
                                          Export to JYSK list
                                        </Label>
                                      </div>
                                    </FormGroup>
                                  </div>
                                </Col>
                              </Row> */}
                            </div>
                          </TabPane>
                          <TabPane tabId={3}>
                            <div>
                              <Row>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-truck-input11">
                                      Full Truck/40HC
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      autoComplete="off"
                                      name="full_truck"
                                      type="text"
                                      className={
                                        validation.touched.full_truck &&
                                        validation.errors.full_truck
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-truck-input11"
                                      placeholder="Enter Full Truck/40HC"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.full_truck || ""}
                                    />
                                    {validation.touched.full_truck &&
                                    validation.errors.full_truck ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.full_truck}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-20st-input11">
                                      20ST Container
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      autoComplete="off"
                                      name="container"
                                      type="text"
                                      className={
                                        validation.touched.container &&
                                        validation.errors.container
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-20st-input11"
                                      placeholder="Enter 20ST Container"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.container || ""}
                                    />
                                    {validation.touched.container &&
                                    validation.errors.container ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.container}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-moq-input11">
                                      MOQ
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      autoComplete="off"
                                      name="moq"
                                      type="text"
                                      className={
                                        validation.touched.moq &&
                                        validation.errors.moq
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-moq-input11"
                                      placeholder="Enter MOQ"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.moq || ""}
                                    />
                                    {validation.touched.moq &&
                                    validation.errors.moq ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.moq}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-net-input11">
                                      Net Weight
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      autoComplete="off"
                                      name="net_weight"
                                      type="text"
                                      className={
                                        validation.touched.net_weight &&
                                        validation.errors.net_weight
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-net-input11"
                                      placeholder="Enter net weight"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.net_weight || ""}
                                    />
                                    {validation.touched.net_weight &&
                                    validation.errors.net_weight ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.net_weight}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-gross-input12">
                                      Gross Weight
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      autoComplete="off"
                                      name="gross_weight"
                                      type="text"
                                      className={
                                        validation.touched.gross_weight &&
                                        validation.errors.gross_weight
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-gross-input12"
                                      placeholder="Enter gross weight"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.gross_weight || ""
                                      }
                                    />
                                    {validation.touched.gross_weight &&
                                    validation.errors.gross_weight ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.gross_weight}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="add-offer-packaging">
                                      Packaging{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <select
                                      className={
                                        validation.touched.packaging_id &&
                                        validation.errors.packaging_id
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      name="packaging_id"
                                      id="add-offer-packaging"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.packaging_id || ""
                                      }
                                    >
                                      <option value="">Choose...</option>
                                      {packaging &&
                                        packaging.map((packaging, pck) => (
                                          <option
                                            key={"pck" + pck}
                                            value={packaging.id}
                                          >
                                            {packaging.packaging}
                                          </option>
                                        ))}
                                    </select>
                                    {validation.touched.packaging_id &&
                                    validation.errors.packaging_id ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.packaging_id}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label htmlFor="add-offer-delivered-on">
                                      Delivered On{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <select
                                      className={
                                        validation.touched.delivered_on &&
                                        validation.errors.delivered_on
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      name="delivered_on"
                                      id="add-offer-delivered-on"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.delivered_on || ""
                                      }
                                    >
                                      <option value="">Choose...</option>
                                      {deliveredOn &&
                                        deliveredOn.map((deliveredOn, dn) => (
                                          <option
                                            key={"dn" + dn}
                                            value={deliveredOn.id}
                                          >
                                            {deliveredOn.delivery_name}
                                          </option>
                                        ))}
                                    </select>
                                    {validation.touched.delivered_on &&
                                    validation.errors.delivered_on ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.delivered_on}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label htmlFor="add-offer-heavey-colli">
                                      Heavy Colli{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <select
                                      className={
                                        validation.touched.heavy_colli_id &&
                                        validation.errors.heavy_colli_id
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      name="heavy_colli_id"
                                      id="add-offer-heavey-colli"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.heavy_colli_id || ""
                                      }
                                    >
                                      <option value="">Choose...</option>
                                      {heaveyColli &&
                                        heaveyColli.map((heaveyColli, hc) => (
                                          <option
                                            key={"hc" + hc}
                                            value={heaveyColli.id}
                                          >
                                            {heaveyColli.heavy_collis}
                                          </option>
                                        ))}
                                    </select>
                                    {validation.touched.heavy_colli_id &&
                                    validation.errors.heavy_colli_id ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.heavy_colli_id}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-npb-input12">
                                      Number of Units per Box
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      autoComplete="off"
                                      name="units_per_box"
                                      type="text"
                                      className={
                                        validation.touched.units_per_box &&
                                        validation.errors.units_per_box
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpil-npb-input12"
                                      placeholder="Enter number of unit per box"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.units_per_box || ""
                                      }
                                    />
                                    {validation.touched.units_per_box &&
                                    validation.errors.units_per_box ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.units_per_box}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-bom-input12">
                                      BOM per article
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      autoComplete="off"
                                      name="bom_per_article"
                                      type="text"
                                      className={
                                        validation.touched.bom_per_article &&
                                        validation.errors.bom_per_article
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-bom-input12"
                                      placeholder="Enter BOM per article"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.bom_per_article || ""
                                      }
                                    />
                                    {validation.touched.bom_per_article &&
                                    validation.errors.bom_per_article ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.bom_per_article}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-mcb-input12">
                                      Master Cardboard Box
                                    </Label>
                                    <Input
                                      autoComplete="off"
                                      name="master_cardboard_box"
                                      type="text"
                                      className="form-control"
                                      id="basicpill-mcb-input12"
                                      placeholder="Enter master cardboard box"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .master_cardboard_box || ""
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-pc-input1">
                                      Packing comments
                                    </Label>
                                    <textarea
                                      name="packing_comments"
                                      id="basicpill-pc-input1"
                                      className="form-control"
                                      rows="3"
                                      placeholder="Enter Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.packing_comments || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </TabPane>
                          <TabPane tabId={4}>
                            <div>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label htmlFor="add-offer-qa-user-id">
                                      QA Users
                                    </Label>
                                    <select
                                      className="form-control"
                                      name="qa_user_id"
                                      id="add-offer-qa-user-id"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.qa_user_id || ""}
                                    >
                                      <option value="">Choose...</option>
                                      {userListAccordingRole.length > 0 &&
                                        userListAccordingRole.map((u, ut) => (
                                          <option
                                            key={"ut" + ut}
                                            value={u.user_id}
                                          >
                                            {u.user_name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sr-input1">
                                      Sample Request
                                    </Label>
                                    <textarea
                                      name="sample_request"
                                      id="basicpill-sr-input1"
                                      className="form-control"
                                      rows="3"
                                      placeholder="Enter Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_request || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sm-input1">
                                      Sample comments
                                    </Label>
                                    <textarea
                                      name="sample_comments"
                                      id="basicpill-sm-input1"
                                      className="form-control"
                                      rows="3"
                                      placeholder="Enter sample comments"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_comments || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-ss-input1">
                                      Sample status
                                    </Label>
                                    <textarea
                                      name="sample_status"
                                      id="basicpill-ss-input1"
                                      className="form-control"
                                      rows="3"
                                      placeholder="Enter Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_status || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-qa-input1">
                                      QA Sample Checking comments
                                    </Label>
                                    <textarea
                                      name="qa_sample_checking_comments"
                                      id="basicpill-qa-input1"
                                      className="form-control"
                                      rows="3"
                                      placeholder="Enter Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .qa_sample_checking_comments || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sc-input1">
                                      Sales sample checking comments
                                    </Label>
                                    <textarea
                                      name="sales_sample_checking_comments"
                                      id="basicpill-sc-input1"
                                      className="form-control"
                                      rows="3"
                                      placeholder="Enter Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .sales_sample_checking_comments || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sf-input1">
                                      Sample freight cost
                                    </Label>
                                    <textarea
                                      name="sample_freight_cost"
                                      id="basicpill-sf-input1"
                                      className="form-control"
                                      rows="3"
                                      placeholder="Enter Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_freight_cost ||
                                        ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-smm-input1">
                                      Sample main material
                                    </Label>
                                    <textarea
                                      name="sample_main_material"
                                      id="basicpill-smm-input1"
                                      className="form-control"
                                      rows="3"
                                      placeholder="Enter Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .sample_main_material || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sh-input1">
                                      Sample hardware
                                    </Label>
                                    <textarea
                                      name="sample_hardware"
                                      id="basicpill-sh-input1"
                                      className="form-control"
                                      rows="3"
                                      placeholder="Enter Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_hardware || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sst-input1">
                                      Sample surface treatment
                                    </Label>
                                    <textarea
                                      name="sample_surface_treatment"
                                      id="basicpill-sst-input1"
                                      className="form-control"
                                      rows="3"
                                      placeholder="Enter Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .sample_surface_treatment || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab - 1)
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                          <li className={activeTab !== 4 ? "next" : "next"}>
                            {activeTab !== 4 ? (
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                              >
                                Next
                              </Link>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                                disabled={offer && offer.loading ? true : false}
                              >
                                {offer && offer.loading
                                  ? "Adding Offer"
                                  : "Add Offer"}
                              </button>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Form>
                  <section className="error-message">
                    {errorsArr && errorsArr.length
                      ? errorsArr.map((er, ei) => {
                          if (er.errorPresent) {
                            return (
                              <ul key={`ei_` + ei}>
                                <li className="text-danger">
                                  <b>{er.section}</b>
                                </li>
                                <ul>
                                  {er.children.map((ce, ci) => {
                                    if (ce.errorMessage) {
                                      return (
                                        <li
                                          key={`ce_` + ci}
                                          className="text-danger"
                                        >
                                          {ce.errorMessage}
                                        </li>
                                      )
                                    }
                                  })}
                                </ul>
                              </ul>
                            )
                          }
                        })
                      : null}
                  </section>

                  {/* below code will be useful when we do not need the validation section.*/}
                  {/* {Object.keys(validation.errors).some(
                    key => validation.touched[key] && !validation.values[key]
                  ) && (
                    <div className="text-danger">
                      <ul>
                        {Object.keys(validation.errors).map((key, index) => {
                          if (
                            validation.touched[key] &&
                            !validation.values[key]
                          ) {
                            return <li key={index}>{validation.errors[key]}.</li>
                          }
                          return null
                        })}
                      </ul>
                    </div>
                  )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddOffer
