import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { CO_GET_USERLISTACCORDINGROLE_LISTING } from "./actionTypes"

import {
  coUserListAccordingRoleSuccess,
  coUserListAccordingRoleFail,
} from "./actions"

//Include Both Helper File with needed methods
import { coUserListAccordingRoleApi } from "../../../helpers/fakebackend_helper"

function* coGetUserListAccordingRoleListing({ payload: { userType, history } }) {
  try {
    const response = yield call(coUserListAccordingRoleApi, userType)
    if (!response.results.user_lists) {
      yield put(coUserListAccordingRoleFail(error))
    }
    yield put(coUserListAccordingRoleSuccess(response.results.user_lists))
  } catch (error) {
    yield put(coUserListAccordingRoleFail(error))
  }
}

function* coUserListAccordingRoleSaga() {
  yield takeEvery(
    CO_GET_USERLISTACCORDINGROLE_LISTING,
    coGetUserListAccordingRoleListing
  )
}
export default coUserListAccordingRoleSaga
