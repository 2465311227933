import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_OFFICE_LISTING,
  FETCH_OFFICE_BY_SECTION,
  ADD_OFFICE,
  EDIT_OFFICE,
  UPDATE_OFFICE,
  OFFICE_ACTIVE_INACTIVE,
  OFFICE_POSITION,
} from "./actionTypes"
import {
  officeListingSuccess,
  officeListingFail,
  fetchOfficeListingBySectionSuccess,
  fetchOfficeListingBySectionFail,
  addOfficeSuccess,
  addOfficeFail,
  editOfficeSuccess,
  editOfficeFail,
  updateOfficeSuccess,
  updateOfficeFail,
  officeChangeSuccess,
  officeChangeFail,
  updateOfficePositionSuccess,
  updateOfficePositionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  officeListingApi,
  fetchDropdownsBySectionApi,
  addOfficeApi,
  getOfficeDetailsById,
  updateOfficeApi,
  OfficeChangeStatusApi,
  fetchDropDownPositionApi,
} from "../../../helpers/fakebackend_helper"

function* getOfficeListing() {
  try {
    const response = yield call(officeListingApi)
    if (!response.results.offices) {
      yield put(officeListingFail(error))
    }
    yield put(officeListingSuccess(response.results.offices))
  } catch (error) {
    yield put(officeListingFail(error))
  }
}

function* fetchOfficeListingBySection({ payload: { data } }) {
  try {
    const response = yield call(fetchDropdownsBySectionApi, data)
    if (!response?.results?.offices) {
      throw response.msg ? response.msg : "Failed to fetch details"
    }
    yield put(fetchOfficeListingBySectionSuccess(response.results.offices))
  } catch (error) {
    yield put(fetchOfficeListingBySectionFail(error))
  }
}

function* addOffice({ payload: { data, history } }) {
  try {
    const response = yield call(addOfficeApi, data)
    if (response && response.status) {
      yield put(addOfficeSuccess(response))
      history.push("/dropdown/office/list")
    } else {
      throw response.msg ? response.msg : "Failed to add"
    }
  } catch (error) {
    yield put(addOfficeFail(error))
  }
}

function* editOffice({ payload: { id } }) {
  try {
    const response = yield call(getOfficeDetailsById, id)
    if (response && response.status) {
      response.id = id
      yield put(editOfficeSuccess(response.results.office))
    } else {
      throw response.msg ? response.msg : "Failed to edit"
    }
  } catch (error) {
    yield put(editOfficeFail(error))
  }
}

function* updateOffice({ payload: { updateOffice, history } }) {
  try {
    const response = yield call(updateOfficeApi, updateOffice)
    if (response && response.status) {
      yield put(updateOfficeSuccess(response))
      history.push("/dropdown/office/list")
    } else {
      throw response.msg ? response.msg : "Failed to update"
    }
  } catch (error) {
    yield put(updateOfficeFail(error))
  }
}

function* officeChange({ payload: { id } }) {
  try {
    const response = yield call(OfficeChangeStatusApi, {
      office_id: id,
    })
    if (response && response.status) {
      response.id = id
      yield put(officeChangeSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update status"
    }
  } catch (error) {
    yield put(officeChangeFail(error))
  }
}

function* updateOfficePosition({ payload: { position } }) {
  try {
    const response = yield call(fetchDropDownPositionApi, position)
    if (response && response.status) {
      yield put(updateOfficePositionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update position"
    }
  } catch (error) {
    let msg = error ? { msg: error } : { msg: "Failed to update position" }
    yield put(updateOfficePositionFail(msg))
  }
}

function* officeSaga() {
  yield takeEvery(GET_OFFICE_LISTING, getOfficeListing)
  yield takeEvery(FETCH_OFFICE_BY_SECTION, fetchOfficeListingBySection)
  yield takeEvery(ADD_OFFICE, addOffice)
  yield takeEvery(EDIT_OFFICE, editOffice)
  yield takeEvery(UPDATE_OFFICE, updateOffice)
  yield takeEvery(OFFICE_ACTIVE_INACTIVE, officeChange)
  yield takeEvery(OFFICE_POSITION, updateOfficePosition)
}
export default officeSaga
