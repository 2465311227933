import {
  GET_PRODUCTCATEGORY_LISTING,
  GET_PRODUCTCATEGORY_LISTING_SUCCESS,
  GET_PRODUCTCATEGORY_LISTING_FAILED,
  ADD_PRODUCT_CATEGORY,
  ADD_PRODUCT_CATEGORY_SUCCESS,
  ADD_PRODUCT_CATEGORY_FAIL,
  CHANGE_PRODUCT_CATEGORY_STATUS,
  CHANGE_PRODUCT_CATEGORY_STATUS_SUCCESS,
  CHANGE_PRODUCT_CATEGORY_STATUS_FAIL,
  EDIT_PRODUCT_CATEGORY,
  EDIT_PRODUCT_CATEGORY_SUCCESS,
  EDIT_PRODUCT_CATEGORY_FAIL,
  UPDATE_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY_SUCCESS,
  UPDATE_PRODUCT_CATEGORY_FAIL,
  FETCH_PRODUCT_CATEGORY_BY_SECTION,
  FETCH_PRODUCT_CATEGORY_BY_SECTION_SUCCESS,
  FETCH_PRODUCT_CATEGORY_BY_SECTION_FAIL,
  UPDATE_PRODUCT_CATEGORY_POSITION,
  UPDATE_PRODUCT_CATEGORY_POSITION_SUCCESS,
  UPDATE_PRODUCT_CATEGORY_POSITION_FAIL,
} from "./actionTypes"

export const productCategoryListing = () => {
  return {
    type: GET_PRODUCTCATEGORY_LISTING,
  }
}

export const productCategoryListingSuccess = productCategory => {
  return {
    type: GET_PRODUCTCATEGORY_LISTING_SUCCESS,
    payload: productCategory,
  }
}

export const productCategoryListingFail = error => {
  return {
    type: GET_PRODUCTCATEGORY_LISTING_FAILED,
    payload: error,
  }
}

export const addProductCategory = (productCategory, history) => {
  return {
    type: ADD_PRODUCT_CATEGORY,
    payload: { productCategory, history },
  }
}

export const addProductCategorySuccess = productCategory => {
  return {
    type: ADD_PRODUCT_CATEGORY_SUCCESS,
    payload: productCategory,
  }
}

export const addProductCategoryFail = error => {
  return {
    type: ADD_PRODUCT_CATEGORY_FAIL,
    payload: error,
  }
}

export const changeProductCategoryStatus = id => ({
  type: CHANGE_PRODUCT_CATEGORY_STATUS,
  payload: { id: id },
})

export const changeProductCategoryStatusSuccess = productCategory => ({
  type: CHANGE_PRODUCT_CATEGORY_STATUS_SUCCESS,
  payload: productCategory,
})

export const changeProductCategoryStatusFail = error => ({
  type: CHANGE_PRODUCT_CATEGORY_STATUS_FAIL,
  payload: error,
})

export const editProductCategory = id => {
  return {
    type: EDIT_PRODUCT_CATEGORY,
    payload: { id },
  }
}
export const editProductCategorySuccess = productCategory => {
  return {
    type: EDIT_PRODUCT_CATEGORY_SUCCESS,
    payload: productCategory,
  }
}
export const editProductCategoryFail = error => {
  return {
    type: EDIT_PRODUCT_CATEGORY_FAIL,
    payload: error,
  }
}

export const updateProductCategory = (productCategory, history) => {
  return {
    type: UPDATE_PRODUCT_CATEGORY,
    payload: { productCategory, history },
  }
}

export const updateProductCategorySuccess = productCategory => {
  return {
    type: UPDATE_PRODUCT_CATEGORY_SUCCESS,
    payload: productCategory,
  }
}

export const updateProductCategoryFail = error => {
  return {
    type: UPDATE_PRODUCT_CATEGORY_FAIL,
    payload: error,
  }
}

export const fetchProductCategoryListingBySection = data => {
  return {
    type: FETCH_PRODUCT_CATEGORY_BY_SECTION,
    payload: { data },
  }
}

export const fetchProductCategoryListingBySectionSuccess = productCategory => {
  return {
    type: FETCH_PRODUCT_CATEGORY_BY_SECTION_SUCCESS,
    payload: productCategory,
  }
}

export const fetchProductCategoryListingBySectionFail = error => {
  return {
    type: FETCH_PRODUCT_CATEGORY_BY_SECTION_FAIL,
    payload: error,
  }
}

export const updateProductCategoryPosition = data => ({
  type: UPDATE_PRODUCT_CATEGORY_POSITION,
  payload: { data },
})

export const updateProductCategoryPositionSuccess = productCategory => ({
  type: UPDATE_PRODUCT_CATEGORY_POSITION_SUCCESS,
  payload: productCategory,
})

export const updateProductCategoryPositionFail = error => ({
  type: UPDATE_PRODUCT_CATEGORY_POSITION_FAIL,
  payload: error,
})
