export const BD_OFFER_ADD = "BD_OFFER_ADD"
export const BD_OFFER_SUCCESS = "BD_OFFER_SUCCESS"
export const BD_OFFER_FAIL = "BD_OFFER_FAIL"

export const BD_OFFER_EDIT = "BD_OFFER_EDIT"
export const BD_OFFER_EDIT_SUCCESS = "BD_OFFER_EDIT_SUCCESS"
export const BD_OFFER_EDIT_FAIL = "BD_OFFER_EDIT_FAIL"

export const BD_GET_OFFERS_LISTING = "BD_GET_OFFERS_LISTING"
export const BD_GET_OFFERS_LISTING_SUCCESS = "BD_GET_OFFERS_LISTING_SUCCESS"
export const BD_GET_OFFERS_LISTING_FAILED = "BD_GET_OFFERS_LISTING_FAILED"

export const BD_OFFER_UPDATE = "BD_OFFER_UPDATE"
export const BD_OFFER_UPDATE_SUCCESS = "BD_OFFER_UPDATE_SUCCESS"
export const BD_OFFER_UPDATE_FAIL = "BD_OFFER_UPDATE_FAIL"

export const BD_CHANGE_OFFER_STATUS = "BD_CHANGE_OFFER_STATUS"
export const BD_CHANGE_OFFER_STATUS_SUCCESS = "BD_CHANGE_OFFER_STATUS_SUCCESS"
export const BD_CHANGE_OFFER_STATUS_FAIL = "BD_CHANGE_OFFER_STATUS_FAIL"

export const BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE = "BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE"
export const BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS = "BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS"
export const BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL = "BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL"

export const BD_RESET_OFFER = "BD_RESET_OFFER"
export const BD_RESET_OFFER_SUCCESS = "BD_RESET_OFFER_SUCCESS"
export const BD_RESET_OFFER_FAIL = "BD_RESET_OFFER_FAIL"