export const GET_PACKAGING_LISTING = "GET_PACKAGING_LISTING"
export const GET_PACKAGING_LISTING_SUCCESS = "GET_PACKAGING_LISTING_SUCCESS"
export const GET_PACKAGING_LISTING_FAILED = "GET_PACKAGING_LISTING_FAILED"

export const FETCH_PACKAGING_BY_SECTION = "FETCH_PACKAGING_BY_SECTION"
export const FETCH_PACKAGING_BY_SECTION_SUCCESS =
  "FETCH_PACKAGING_BY_SECTION_SUCCESS"
export const FETCH_PACKAGING_BY_SECTION_FAIL = "FETCH_PACKAGING_BY_SECTION_FAIL"

export const ADD_PACKAGING = "ADD_PACKAGING"
export const ADD_PACKAGING_SUCCESS = "ADD_PACKAGING"
export const ADD_PACKAGING_FAIL = "ADD_PACKAGING_FAIL"

export const EDIT_PACKAGING = "EDIT_PACKAGING"
export const EDIT_PACKAGING_SUCCESS = "EDIT_PACKAGING_SUCCESS"
export const EDIT_PACKAGING_FAIL = "EDIT_PACKAGING_FAIL"

export const UPDATE_PACKAGING = "UPDATE_PACKAGING"
export const UPDATE_PACKAGING_SUCCESS = "UPDATE_PACKAGING_SUCCESS"
export const UPDATE_PACKAGING_FAIL = "UPDATE_PACKAGING_FAIL"

export const PACKAGING_ACTIVE_INACTIVE = "PACKAGING_ACTIVE_INACTIVE"
export const PACKAGING_ACTIVE_INACTIVE_SUCCESS =
  "PACKAGING_ACTIVE_INACTIVE_SUCCESS"
export const PACKAGING_ACTIVE_INACTIVE_FAIL = "PACKAGING_ACTIVE_INACTIVE_FAIL"

export const PACKING_POSITION = "PACKING_POSITION"
export const PACKING_POSITION_SUCCESS = "PACKING_POSITION_SUCCESS"
export const PACKING_POSITION_FAIL = "PACKING_POSITION_FAIL"
