import {
  PRODUCT_SUCCESS_CO,
  PRODUCT_FAIL_CO,
  PRODUCT_EDIT_CO,
  PRODUCT_EDIT_FAIL_CO,
  PRODUCT_EDIT_SUCCESS_CO,
  PRODUCT_ADD_CO,
  GET_PRODUCTS_LISTING_CO,
  GET_PRODUCTS_LISTING_SUCCESS_CO,
  GET_PRODUCTS_LISTING_FAILED_CO,
  PRODUCT_UPDATE_CO,
  PRODUCT_UPDATE_SUCCESS_CO,
  PRODUCT_UPDATE_FAIL_CO,
  PRODUCT_IMAGE_DELETE_CO,
  PRODUCT_IMAGE_DELETE_SUCCESS_CO,
  PRODUCT_IMAGE_DELETE_FAIL_CO,
  PRODUCT_DELETE_CO,
  PRODUCT_DELETE_SUCCESS_CO,
  PRODUCT_DELETE_FAIL_CO,
  CHANGE_PRODUCT_STATUS_CO,
  CHANGE_PRODUCT_STATUS_SUCCESS_CO,
  CHANGE_PRODUCT_STATUS_FAIL_CO,
} from "./actionTypes"

export const coAddNewProduct = (product, history) => {
  return {
    type: PRODUCT_ADD_CO,
    payload: { product, history },
  }
}

export const coProductSuccess = products => {
  return {
    type: PRODUCT_SUCCESS_CO,
    payload: products,
  }
}

export const coProductFail = error => {
  return {
    type: PRODUCT_FAIL_CO,
    payload: error,
  }
}

export const coEditProduct = productId => {
  return {
    type: PRODUCT_EDIT_CO,
    payload: { productId },
  }
}
export const coEditProductSuccess = product => {
  return {
    type: PRODUCT_EDIT_SUCCESS_CO,
    payload: product,
  }
}
export const coEditProductFail = error => {
  return {
    type: PRODUCT_EDIT_FAIL_CO,
    payload: error,
  }
}

export const coUpdateProduct = (product, history) => {
  return {
    type: PRODUCT_UPDATE_CO,
    payload: { product, history },
  }
}

export const coUpdateProductSuccess = product => {
  return {
    type: PRODUCT_UPDATE_SUCCESS_CO,
    payload: product,
  }
}

export const coUpdateProductFail = error => {
  return {
    type: PRODUCT_UPDATE_FAIL_CO,
    payload: error,
  }
}

export const coProductsListing = () => {
  return {
    type: GET_PRODUCTS_LISTING_CO,
  }
}
export const coProductsListingSuccess = product => {
  return {
    type: GET_PRODUCTS_LISTING_SUCCESS_CO,
    payload: product,
  }
}
export const coProductsListingFail = error => {
  return {
    type: GET_PRODUCTS_LISTING_FAILED_CO,
    payload: error,
  }
}

export const coDeleteProductImage = (productImgId, history) => {
  return {
    type: PRODUCT_IMAGE_DELETE_CO,
    payload: { productImgId, history },
  }
}
export const coDeleteProductImageSuccess = productImg => {
  return {
    type: PRODUCT_IMAGE_DELETE_SUCCESS_CO,
    payload: productImg,
  }
}
export const coDeleteProductImageFail = error => {
  return {
    type: PRODUCT_IMAGE_DELETE_FAIL_CO,
    payload: error,
  }
}
export const coDeleteProduct = prod_id => ({
  type: PRODUCT_DELETE_CO,
  payload: { id: prod_id },
})

export const coDeleteProductSuccess = product => ({
  type: PRODUCT_DELETE_SUCCESS_CO,
  payload: product,
})

export const coDeleteProductFail = error => ({
  type: PRODUCT_DELETE_FAIL_CO,
  payload: error,
})

export const coChangeProductStatus = productId => ({
  type: CHANGE_PRODUCT_STATUS_CO,
  payload: { id: productId },
})

export const coChangeProductStatusSuccess = product => ({
  type: CHANGE_PRODUCT_STATUS_SUCCESS_CO,
  payload: product,
})

export const coChangeProductStatusFail = error => ({
  type: CHANGE_PRODUCT_STATUS_FAIL_CO,
  payload: error,
})
