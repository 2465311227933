import {
  BD_GET_PRODUCTCATEGORY_LISTING,
  BD_GET_PRODUCTCATEGORY_LISTING_SUCCESS,
  BD_GET_PRODUCTCATEGORY_LISTING_FAILED,
} from "./actionTypes"

export const bdProductCategoryListing = () => {
  return {
    type: BD_GET_PRODUCTCATEGORY_LISTING,
  }
}

export const bdProductCategoryListingSuccess = bdProductCategory => {
  return {
    type: BD_GET_PRODUCTCATEGORY_LISTING_SUCCESS,
    payload: bdProductCategory,
  }
}

export const bdProductCategoryListingFail = error => {
  return {
    type: BD_GET_PRODUCTCATEGORY_LISTING_FAILED,
    payload: error,
  }
}