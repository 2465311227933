import {
  CO_GET_CREATEDBY_LISTING,
  CO_GET_CREATEDBY_LISTING_SUCCESS,
  CO_GET_CREATEDBY_LISTING_FAILED,
  } from "./actionTypes"

  export const coCreatedByListing = () => {
    return {
      type: CO_GET_CREATEDBY_LISTING,
    }
  }
  
  export const coCreatedByListingSuccess = createdBy => {
    return {
      type: CO_GET_CREATEDBY_LISTING_SUCCESS,
      payload: createdBy,
    }
  }
  
  export const coCreatedByListingFail = error => {
    return {
      type: CO_GET_CREATEDBY_LISTING_FAILED,
      payload: error,
    }
  }