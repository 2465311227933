export const GET_INCOTERMCITY_LISTING = "GET_INCOTERMCITY_LISTING"
export const GET_INCOTERMCITY_LISTING_SUCCESS =
  "GET_INCOTERMCITY_LISTING_SUCCESS"
export const GET_INCOTERMCITY_LISTING_FAILED = "GET_INCOTERMCITY_LISTING_FAILED"

export const ADD_INCOTERM = "ADD_INCOTERM"
export const ADD_INCOTERM_SUCCESS = "ADD_INCOTERM_SUCCESS"
export const ADD_INCOTERM_FAIL = "ADD_INCOTERM_FAIL"

export const CHANGE_INCOTERM_STATUS = "CHANGE_INCOTERM_STATUS"
export const CHANGE_INCOTERM_STATUS_SUCCESS = "CHANGE_INCOTERM_STATUS_SUCCESS"
export const CHANGE_INCOTERM_STATUS_FAIL = "CHANGE_INCOTERM_STATUS_FAIL"

export const EDIT_INCOTERM = "EDIT_INCOTERM"
export const EDIT_INCOTERM_SUCCESS = "EDIT_INCOTERM_SUCCESS"
export const EDIT_INCOTERM_FAIL = "EDIT_INCOTERM_FAIL"

export const UPDATE_INCOTERM = "UPDATE_INCOTERM"
export const UPDATE_INCOTERM_SUCCESS = "UPDATE_INCOTERM_SUCCESS"
export const UPDATE_INCOTERM_FAIL = "UPDATE_INCOTERM_FAIL"

export const FETCH_INCOTERM_BY_SECTION = "FETCH_INCOTERM_BY_SECTION"
export const FETCH_INCOTERM_BY_SECTION_SUCCESS =
  "FETCH_INCOTERM_BY_SECTION_SUCCESS"
export const FETCH_INCOTERM_BY_SECTION_FAIL = "FETCH_INCOTERM_BY_SECTION_FAIL"

export const INCOTERM_CITY_POSITION = "INCOTERM_CITY_POSITION"
export const INCOTERM_CITY_POSITION_SUCCESS = "INCOTERM_CITY_POSITION_SUCCESS"
export const INCOTERM_CITY_POSITION_FAIL = "INCOTERM_CITY_POSITION_FAIL"
