import {
  CO_GET_PRODUCTCATEGORY_LISTING,
  CO_GET_PRODUCTCATEGORY_LISTING_SUCCESS,
  CO_GET_PRODUCTCATEGORY_LISTING_FAILED,
  } from "./actionTypes"
  
  export const coProductCategoryListing = () => {
    return {
      type: CO_GET_PRODUCTCATEGORY_LISTING,
    }
  }
  
  export const coProductCategoryListingSuccess = productCategory => {
    return {
      type: CO_GET_PRODUCTCATEGORY_LISTING_SUCCESS,
      payload: productCategory,
    }
  }
  
  export const coProductCategoryListingFail = error => {
    return {
      type: CO_GET_PRODUCTCATEGORY_LISTING_FAILED,
      payload: error,
    }
  }
  
