export const GET_ROLES_LISTING = "GET_ROLES_LISTING"
export const GET_ROLES_LISTING_SUCCESS = "GET_ROLES_LISTING_SUCCESS"
export const GET_ROLES_LISTING_FAILED = "GET_ROLES_LISTING_FAILED"

export const FETCH_ROLES_BY_SECTION = "FETCH_ROLES_BY_SECTION"
export const FETCH_ROLES_BY_SECTION_SUCCESS = "FETCH_ROLES_BY_SECTION_SUCCESS"
export const FETCH_ROLES_BY_SECTION_FAIL = "FETCH_ROLES_BY_SECTION_FAIL"

export const EDIT_ROLE = "EDIT_ROLE"
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS"
export const EDIT_ROLE_FAIL = "EDIT_ROLE_FAIL"

export const ADD_ROLE = "ADD_ROLE"
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS"
export const ADD_ROLE_FAIL = "ADD_ROLE_FAIL"

export const UPDATE_ROLE = "UPDATE_ROLE"
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS"
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL"

export const USER_ROLE_ACTIVE_INACTIVE = "USER_ROLE_ACTIVE_INACTIVE"
export const USER_ROLE_ACTIVE_INACTIVE_SUCCESS =
  "USER_ROLE_ACTIVE_INACTIVE_SUCCESS"
export const USER_ROLE_ACTIVE_INACTIVE_FAIL = "USER_ROLE_ACTIVE_INACTIVE_FAIL"

export const UPDATE_USER_ROLE_POSITION = "UPDATE_USER_ROLE_POSITION"
export const UPDATE_USER_ROLE_POSITION_SUCCESS =
  "UPDATE_USER_ROLE_POSITION_SUCCESS"
export const UPDATE_USER_ROLE_POSITION_FAIL = "UPDATE_USER_ROLE_POSITION_FAIL"
