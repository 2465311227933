import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING,
  ADD_CERTIFICATION,
  CHANGE_CERTIFICATION_STATUS,
  EDIT_CERTIFICATION,
  UPDATE_CERTIFICATION,
  FETCH_CERTIFICATION_BY_SECTION,
  UPDATE_CERTIFICATION_POSITION,
} from "./actionTypes"
import {
  environmentalAndHealthCertificationListingSuccess,
  environmentalAndHealthCertificationListingFail,
  addCertificateSuccess,
  addCertificateFail,
  changeCertificateStatusSuccess,
  changeCertificateStatusFail,
  editCertificateSuccess,
  editCertificateFail,
  updateCertificateSuccess,
  updateCertificateFail,
  fetchCertificateListingBySectionSuccess,
  fetchCertificateListingBySectionFail,
  updateCertificatePositionSuccess,
  updateCertificatePositionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  environmentalAndHealthCertificationListingApi,
  addCertificatesApi,
  changeCertificatesStatusApi,
  editCertificatesApi,
  updateCertificatesApi,
  fetchDropdownsBySectionApi,
  fetchDropDownPositionApi,
} from "../../../helpers/fakebackend_helper"

function* getEnvironmentalAndHealthCertificationListing() {
  try {
    const response = yield call(environmentalAndHealthCertificationListingApi)
    if (!response.results.certificates) {
      yield put(environmentalAndHealthCertificationListingFail(error))
    }
    yield put(
      environmentalAndHealthCertificationListingSuccess(
        response.results.certificates
      )
    )
  } catch (error) {
    yield put(environmentalAndHealthCertificationListingFail(error))
  }
}

function* addCertificates({
  payload: { environmentalAndHealthCertification, history },
}) {
  try {
    const response = yield call(
      addCertificatesApi,
      environmentalAndHealthCertification
    )
    if (response && response.status) {
      yield put(addCertificateSuccess(response))
      history.push("/dropdown/env_health_certificate/list")
    } else {
      throw response.msg ? response.msg : "Failed to add"
    }
  } catch (error) {
    yield put(addCertificateFail(error))
  }
}

function* onCertificatesStatusChange({ payload: { id } }) {
  try {
    const response = yield call(changeCertificatesStatusApi, {
      certification_id: id,
    })
    if (response.status) {
      response.id = id
      yield put(changeCertificateStatusSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to change status"
    }
  } catch (error) {
    yield put(changeCertificateStatusFail(error))
  }
}

function* getCertificateDetailsById({ payload: { id } }) {
  try {
    const response = yield call(editCertificatesApi, id)
    if (response && response.status) {
      yield put(editCertificateSuccess(response.results.cert))
    } else {
      throw response.msg ? response.msg : "Failed to edit"
    }
  } catch (error) {
    yield put(editCertificateFail(error))
  }
}

function* onUpdateCertificates({
  payload: { environmentalAndHealthCertification, history },
}) {
  try {
    const response = yield call(
      updateCertificatesApi,
      environmentalAndHealthCertification
    )
    if (response && response.status) {
      yield put(updateCertificateSuccess(response))
      history.push("/dropdown/env_health_certificate/list")
    } else {
      throw response.msg ? response.msg : "Failed to update"
    }
  } catch (error) {
    yield put(updateCertificateFail(error))
  }
}

function* fetchCertificateListingBySection({ payload: { data } }) {
  try {
    const response = yield call(fetchDropdownsBySectionApi, data)
    if (!response?.results?.certificates) {
      throw response.msg ? response.msg : "Failed to fetch details"
    }
    yield put(
      fetchCertificateListingBySectionSuccess(response.results.certificates)
    )
  } catch (error) {
    yield put(fetchCertificateListingBySectionFail(error))
  }
}

function* updateCertificatePosition({ payload: { data } }) {
  try {
    const response = yield call(fetchDropDownPositionApi, data)
    if (response && response.status) {
      yield put(updateCertificatePositionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update position"
    }
  } catch (error) {
    let msg = error ? { msg: error } : { msg: "Failed to update position" }
    yield put(updateCertificatePositionFail(msg))
  }
}

function* environmentalAndHealthCertificationSaga() {
  yield takeEvery(
    GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING,
    getEnvironmentalAndHealthCertificationListing
  )
  yield takeEvery(ADD_CERTIFICATION, addCertificates)
  yield takeEvery(CHANGE_CERTIFICATION_STATUS, onCertificatesStatusChange)
  yield takeEvery(EDIT_CERTIFICATION, getCertificateDetailsById)
  yield takeEvery(UPDATE_CERTIFICATION, onUpdateCertificates)
  yield takeEvery(
    FETCH_CERTIFICATION_BY_SECTION,
    fetchCertificateListingBySection
  )
  yield takeEvery(UPDATE_CERTIFICATION_POSITION, updateCertificatePosition)
}
export default environmentalAndHealthCertificationSaga
