import {
  GET_DELIVEREDON_LISTING,
  GET_DELIVEREDON_LISTING_SUCCESS,
  GET_DELIVEREDON_LISTING_FAILED,
  ADD_DELIVERED,
  ADD_DELIVERED_SUCCESS,
  ADD_DELIVERED_FAIL,
  CHANGE_DELIVERED_STATUS,
  CHANGE_DELIVERED_STATUS_SUCCESS,
  CHANGE_DELIVERED_STATUS_FAIL,
  EDIT_DELIVERED,
  EDIT_DELIVERED_SUCCESS,
  EDIT_DELIVERED_FAIL,
  UPDATE_DELIVERED,
  UPDATE_DELIVERED_SUCCESS,
  UPDATE_DELIVERED_FAIL,
  FETCH_DELIVERED_BY_SECTION,
  FETCH_DELIVERED_BY_SECTION_SUCCESS,
  FETCH_DELIVERED_BY_SECTION_FAIL,
  UPDATE_DELIVERED_POSITION,
  UPDATE_DELIVERED_POSITION_SUCCESS,
  UPDATE_DELIVERED_POSITION_FAIL,
} from "./actionTypes"

const initialState = {
  error: null,
  msg: null,
  loading: false,
  deliveredOn: null,
  deliveredOnEdit: null,
  deliveredOnUpdate: null,
  positionUpdate: null,
}

const deliveredOn = (state = initialState, action) => {
  switch (action.type) {
    case GET_DELIVEREDON_LISTING:
      state = {
        ...state,
        loading: true,
        positionUpdate: null,
        error: null,
        msg: null,
        deliveredOn: action.payload,
      }
      break
    case GET_DELIVEREDON_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        deliveredOn: action.payload,
        positionUpdate: null,
        error: null,
        msg: null,
      }
      break
    case GET_DELIVEREDON_LISTING_FAILED:
      state = { ...state, loading: false, positionUpdate: null }
      break
    case ADD_DELIVERED:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        deliveredOn: action.payload,
      }
      break
    case ADD_DELIVERED_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        deliveredOn: action.payload,
      }
      break
    case ADD_DELIVERED_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload ? action.payload : null,
      }
      break
    case CHANGE_DELIVERED_STATUS:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break
    case CHANGE_DELIVERED_STATUS_SUCCESS:
      const index = state.deliveredOn.findIndex(
        delivers => delivers.id === action.payload.id
      )
      let newDeliversAfterStatusChange = []
      newDeliversAfterStatusChange = [...state.deliveredOn] //making a new array
      newDeliversAfterStatusChange[index].status =
        !newDeliversAfterStatusChange[index].status

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        deliveredOn: newDeliversAfterStatusChange,
      }
      break

    case CHANGE_DELIVERED_STATUS_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case EDIT_DELIVERED:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        deliveredOnEdit: action.payload,
      }
      break
    case EDIT_DELIVERED_SUCCESS:
      if (state === null || state.deliveredOn.length === 0) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          deliveredOn: [...state.deliveredOn, action.payload],
        }
      } else {
        state = {
          ...state,
          loading: false,
          deliveredOn: state.deliveredOn.map(i =>
            i.id === action.payload.id ? action.payload : i
          ),
        }
      }
      break
    case EDIT_DELIVERED_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case UPDATE_DELIVERED:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        deliveredOnUpdate: action.payload,
      }
      break
    case UPDATE_DELIVERED_SUCCESS:
      state = { ...state, error: null, msg: null, loading: false }
      break
    case UPDATE_DELIVERED_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case FETCH_DELIVERED_BY_SECTION:
      state = {
        ...state,
        loading: true,
        positionUpdate: null,
        error: null,
        msg: null,
        deliveredOnList: action.payload,
      }
      break
    case FETCH_DELIVERED_BY_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        deliveredOn: action.payload,
        positionUpdate: null,
        error: null,
        msg: null,
      }
      break
    case FETCH_DELIVERED_BY_SECTION_FAIL:
      state = { ...state, loading: false, positionUpdate: null }
      break
      case UPDATE_DELIVERED_POSITION:
        state = {
          ...state,
          loading: true,
          error: null,
          msg: null,
          positionUpdate: null,
        }
        break
  
      case UPDATE_DELIVERED_POSITION_SUCCESS:
        state = {
          ...state,
          loading: false,
          error: null,
          msg: action.payload.msg != "" ? action.payload.msg : "",
          positionUpdate: true,
        }
        break
      case UPDATE_DELIVERED_POSITION_FAIL:
        state = {
          ...state,
          loading: false,
          error: action.payload.msg != "" ? action.payload.msg : "",
          msg: null,
          positionUpdate: null,
        }
        break
    default:
      state = { ...state }
      break
  }
  return state
}

export default deliveredOn
