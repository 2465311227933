import {
    CO_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING,
    CO_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_SUCCESS,
    CO_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_FAILED,
  } from "./actionTypes"
  
  export const coEnvironmentalAndHealthCertificationListing = () => {
    return {
      type: CO_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING,
      payload: "",
    }
  }
  
  export const coEnvironmentalAndHealthCertificationListingSuccess = environmentalAndHealthCertification => {
    return {
      type: CO_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_SUCCESS,
      payload: environmentalAndHealthCertification,
    }
  }
  
  export const coEnvironmentalAndHealthCertificationListingFail = error => {
    return {
      type: CO_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_FAILED,
      payload: error,
    }
  }
  
