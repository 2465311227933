import {
  CO_GET_CONSTRUCTON_LISTING,
  CO_GET_CONSTRUCTON_LISTING_SUCCESS,
  CO_GET_CONSTRUCTON_LISTING_FAILED,
  CO_ADD_CONSTRUCTON,
  CO_ADD_CONSTRUCTON_SUCCESS,
  CO_ADD_CONSTRUCTON_FAIL,
  CO_EDIT_CONSTRUCTON,
  CO_EDIT_CONSTRUCTON_SUCCESS,
  CO_EDIT_CONSTRUCTON_FAIL,
  CO_UPDATE_CONSTRUCTON,
  CO_UPDATE_CONSTRUCTON_SUCCESS,
  CO_UPDATE_CONSTRUCTON_FAIL,
} from "./actionTypes"

const initialState = {
  error: null,
  msg: null,
  loading: false,
  construction: null,
}

const coConstruction = (state = initialState, action) => {
  switch (action.type) {
    case CO_GET_CONSTRUCTON_LISTING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
        construction: action.payload,
      }
      break
    case CO_GET_CONSTRUCTON_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        positionUpdate: null,
        construction: action.payload,
      }
      break
    case CO_GET_CONSTRUCTON_LISTING_FAILED:
      state = { ...state, positionUpdate: null, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default coConstruction
