import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  BD_GET_CREATEDBY_LISTING,
} from "./actionTypes"
import {
  bdCreatedByListingSuccess,
  bdCreatedByListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  bdCreatedByListingApi,
} from "../../../helpers/fakebackend_helper"

function* BdGetCreatedByListing() {
  try {
    const response = yield call(bdCreatedByListingApi)
    if (!response.results) {
      yield put(bdCreatedByListingFail(error))
    }
    yield put(bdCreatedByListingSuccess(response.results))
  } catch (error) {
    yield put(bdCreatedByListingFail(error))
  }
}

function* bdCreatedBySaga() {
  yield takeEvery(BD_GET_CREATEDBY_LISTING, BdGetCreatedByListing)
}
export default bdCreatedBySaga
