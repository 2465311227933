import {
  SA_COLLECTION_ADD,
  SA_COLLECTION_SUCCESS,
  SA_COLLECTION_FAIL,
  SA_GET_COLLECTION_LISTING,
  SA_GET_COLLECTION_LISTING_SUCCESS,
  SA_GET_COLLECTION_LISTING_FAILED,
  SA_COLLECTION_EDIT,
  SA_COLLECTION_EDIT_SUCCESS,
  SA_COLLECTION_EDIT_FAIL,
  SA_COLLECTION_UPDATE,
  SA_COLLECTION_UPDATE_SUCCESS,
  SA_COLLECTION_UPDATE_FAIL,
  SA_COLLECTION_DELETE,
  SA_COLLECTION_DELETE_SUCCESS,
  SA_COLLECTION_DELETE_FAIL,
  SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
  SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS,
  SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL,
  SA_COLLECTION_COPY,
  SA_COLLECTION_COPY_SUCCESS,
  SA_COLLECTION_COPY_FAIL,
  SA_EXPORTDOWNLOAD_PROGRESS,
  SA_EXPORTDOWNLOAD_PROGRESS_SUCCESS,
  SA_EXPORTDOWNLOAD_PROGRESS_FAIL,
  SA_ADD_MORE_TO_COLLECTION,
  SA_ADD_MORE_TO_COLLECTION_SUCCESS,
  SA_ADD_MORE_TO_COLLECTION_FAIL,
  SA_ADD_MORE_TO_COLLECTION_UPDATE,
  SA_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS,
  SA_ADD_MORE_TO_COLLECTION_UPDATE_FAIL,
  SA_GET_PRODUCTS_FOR_ADD_COLLECTION,
  SA_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS,
  SA_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL,
  SA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
  SA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS,
  SA_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL,
  SA_REFRESH_COLLECTION_COUNT,
  SA_REFRESH_COLLECTION_COUNT_SUCCESS,
  SA_REFRESH_COLLECTION_COUNT_FAIL,
  SA_GET_LAYOUT,
  SA_GET_LAYOUT_SUCCESS,
  SA_GET_LAYOUT_FAIL,
} from "./actionTypes"

export const saCollectionSuccess = collection => {
  return {
    type: SA_COLLECTION_SUCCESS,
    payload: collection,
  }
}

export const saCollectionFail = error => {
  return {
    type: SA_COLLECTION_FAIL,
    payload: error,
  }
}

export const saAddNewCollection = (collection, history) => {
  return {
    type: SA_COLLECTION_ADD,
    payload: { collection, history },
  }
}

export const saCollectionsListing = history => {
  return {
    type: SA_GET_COLLECTION_LISTING,
  }
}

export const saCollectionsListingSuccess = collection => {
  return {
    type: SA_GET_COLLECTION_LISTING_SUCCESS,
    payload: collection,
  }
}

export const saCollectionsListingFail = error => {
  return {
    type: SA_GET_COLLECTION_LISTING_FAILED,
    payload: error,
  }
}

export const saCollectionEdit = id => {
  return {
    type: SA_COLLECTION_EDIT,
    payload: { id: id },
  }
}

export const saEditCollectionSuccess = collection => {
  return {
    type: SA_COLLECTION_EDIT_SUCCESS,
    payload: collection,
  }
}

export const saEditCollectionFail = error => {
  return {
    type: SA_COLLECTION_EDIT_FAIL,
    payload: error,
  }
}

export const saUpdateCollection = (collection, history) => {
  return {
    type: SA_COLLECTION_UPDATE,
    payload: { collection, history },
  }
}

export const saUpdateCollectionSuccess = collection => {
  return {
    type: SA_COLLECTION_UPDATE_SUCCESS,
    payload: collection,
  }
}

export const saUpdateCollectionFail = error => {
  return {
    type: SA_COLLECTION_UPDATE_FAIL,
    payload: error,
  }
}

export const saDeleteCollection = collectionId => ({
  type: SA_COLLECTION_DELETE,
  payload: { id: collectionId },
})

export const saDeleteCollectionSuccess = collection => ({
  type: SA_COLLECTION_DELETE_SUCCESS,
  payload: collection,
})

export const saDeleteCollectionFail = error => ({
  type: SA_COLLECTION_DELETE_FAIL,
  payload: error,
})

export const saDownloadExportCollectionCatalog = (collection, history) => {
  return {
    type: SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
    payload: { collection, history },
  }
}

export const saExportCollectionCatalogSuccess = collection => {
  return {
    type: SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS,
    payload: collection,
  }
}

export const saExportCollectionCatalogFail = error => {
  return {
    type: SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL,
    payload: error,
  }
}

export const saCopyCollection = (collection, history) => {
  return {
    type: SA_COLLECTION_COPY,
    payload: { collection, history },
  }
}

export const saCopyCollectionSuccess = collection => {
  return {
    type: SA_COLLECTION_COPY_SUCCESS,
    payload: collection,
  }
}

export const saCopyCollectionFail = error => {
  return {
    type: SA_COLLECTION_COPY_FAIL,
    payload: error,
  }
}

export const saExportDownloadProgress = (saExportDownloadProgress, history) => {
  return {
    type: SA_EXPORTDOWNLOAD_PROGRESS,
    payload: { saExportDownloadProgress, history },
  }
}

export const saExportDownloadProgressSuccess = saExportDownloadProgress => {
  return {
    type: SA_EXPORTDOWNLOAD_PROGRESS_SUCCESS,
    payload: saExportDownloadProgress,
  }
}

export const saExportDownloadProgressFail = error => {
  return {
    type: SA_EXPORTDOWNLOAD_PROGRESS_FAIL,
    payload: error,
  }
}

export const saGetAddMoreToCollection = (collection_id) => {
  return {
    type: SA_ADD_MORE_TO_COLLECTION,
    payload: { collection_id:collection_id},
  }
}
export const saGetAddMoreToCollectionSuccess = collectionAddMoreDetails => {
  return {
    type: SA_ADD_MORE_TO_COLLECTION_SUCCESS,
    payload: collectionAddMoreDetails,
  }
}
export const saGetAddMoreToCollectionFail = error => {
  return {
    type: SA_ADD_MORE_TO_COLLECTION_FAIL,
    payload: error,
  }
}

export const saGetAddMoreToCollectionUpdate = (addMoreUpdateDetails, history) => {
  return {
    type: SA_ADD_MORE_TO_COLLECTION_UPDATE,
    payload: {addMoreUpdateDetails, history},
  }
}
export const saGetAddMoreToCollectionUpdateSuccess = collectionAddMoreDetailsUpdate => {
  return {
    type: SA_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS,
    payload: collectionAddMoreDetailsUpdate,
  }
}
export const saGetAddMoreToCollectionUpdateFail = error => {
  return {
    type: SA_ADD_MORE_TO_COLLECTION_UPDATE_FAIL,
    payload: error,
  }
}

export const saProductForAddCollection = productsForAddCollection => {
  return {
    type: SA_GET_PRODUCTS_FOR_ADD_COLLECTION,
    payload: {productsForAddCollection},
  }
}

export const saProductForAddCollectionSuccess = productsForAddCollection => {
  return {
    type: SA_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS,
    payload: productsForAddCollection,
  }
}

export const saProductForAddCollectionFail = error => {
  return {
    type: SA_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL,
    payload: error,
  }
}

export const saProductOfferForAddCollection = productsOfferForAddCollection => {
  return {
    type: SA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
    payload: {productsOfferForAddCollection},
  }
}

export const saProductOfferForAddCollectionSuccess = productsOfferForAddCollection => {
  return {
    type: SA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS,
    payload: productsOfferForAddCollection,
  }
}

export const saProductOfferForAddCollectionFail = error => {
  return {
    type: SA_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL,
    payload: error,
  }
}

export const saRefreshCollectionCount = id => {
  return {
    type: SA_REFRESH_COLLECTION_COUNT,
    payload: { collection_id: id },
  }
}

export const saRefreshCollectionCountSuccess = collection => {
  return {
    type: SA_REFRESH_COLLECTION_COUNT_SUCCESS,
    payload: collection,
  }
}

export const saRefreshCollectionCountFail = error => {
  return {
    type: SA_REFRESH_COLLECTION_COUNT_FAIL,
    payload: error,
  }
}

export const saGetLayOut = layout => {
  return {
    type: SA_GET_LAYOUT,
    payload: layout,
  }
}

export const saGetLayOutSuccess = (layout) => {
  return {
    type: SA_GET_LAYOUT_SUCCESS,
    payload: { layout },
  }
}

export const saGetLayOutFail = error => {
  return {
    type: SA_GET_LAYOUT_FAIL,
    payload: error,
  }
}
