export const PRODUCT_ADD_CO = "PRODUCT_ADD_CO"
export const PRODUCT_SUCCESS_CO = "PRODUCT_SUCCESS_CO"
export const PRODUCT_FAIL_CO = "PRODUCT_FAIL_CO"

export const PRODUCT_EDIT_CO = "PRODUCT_EDIT_CO"
export const PRODUCT_EDIT_SUCCESS_CO = "PRODUCT_EDIT_SUCCESS_CO"
export const PRODUCT_EDIT_FAIL_CO = "PRODUCT_EDIT_FAIL_CO"

export const GET_PRODUCTS_LISTING_CO = "GET_PRODUCTS_LISTING_CO"
export const GET_PRODUCTS_LISTING_SUCCESS_CO = "GET_PRODUCTS_LISTING_SUCCESS_CO"
export const GET_PRODUCTS_LISTING_FAILED_CO = "GET_PRODUCTS_LISTING_FAILED_CO"

export const PRODUCT_UPDATE_CO = "PRODUCT_UPDATE_CO"
export const PRODUCT_UPDATE_SUCCESS_CO = "PRODUCT_UPDATE_SUCCESS_CO"
export const PRODUCT_UPDATE_FAIL_CO = "PRODUCT_UPDATE_FAIL_CO"

export const PRODUCT_DELETE_CO = "PRODUCT_DELETE_CO"
export const PRODUCT_DELETE_SUCCESS_CO = "PRODUCT_DELETE_SUCCESS_CO"
export const PRODUCT_DELETE_FAIL_CO = "PRODUCT_DELETE_FAIL_CO"

export const PRODUCT_IMAGE_DELETE_CO = "PRODUCT_IMAGE_DELETE_CO"
export const PRODUCT_IMAGE_DELETE_SUCCESS_CO = "PRODUCT_IMAGE_DELETE_SUCCESS_CO"
export const PRODUCT_IMAGE_DELETE_FAIL_CO = "PRODUCT_IMAGE_DELETE_FAIL_CO"

export const CHANGE_PRODUCT_STATUS_CO = "CHANGE_PRODUCT_STATUS_CO"
export const CHANGE_PRODUCT_STATUS_SUCCESS_CO =
  "CHANGE_PRODUCT_STATUS_SUCCESS_CO"
export const CHANGE_PRODUCT_STATUS_FAIL_CO = "CHANGE_PRODUCT_STATUS_FAIL_CO"
