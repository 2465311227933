import React from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// actions
import {
  // productCategoryListing,
  // qualityRatingListing,
  constructionListing,
  saEditProduct,
} from "../../../store/actions" // here i call the admin/store/product/actionjs because SM has no right to edit. just fetching this functions for showing the data in page,
import { useEffect, useState } from "react"

const SaEditProduct = props => {
  const dispatch = useDispatch()
  document.title = "Edit Product"
  const { id } = useParams()

  const [details, setDetails] = useState({})

  const [productCategories, setProductCategories] = useState([])
  // const [qualityRatings, setQualityRatings] = useState([])
  const [constructions, setConstructions] = useState([])

  useEffect(() => {
    dispatch(productCategoryListing())
    // dispatch(qualityRatingListing())
    dispatch(constructionListing())
    dispatch(saEditProduct(id))
  }, [dispatch])

  const products = useSelector(state => state.saProduct)

  const productCategoryData = useSelector(
    state => state.ProductCategory.productCategory
  )
  // const qualityRatingData = useSelector(
  //   state => state.QualityRating.qualityRating
  // )
  const constructionData = useSelector(state => state.construction.construction)

  useEffect(() => {
    let prods = []
    if (products && products.product && products.product.length > 0) {
      prods = products.product.filter(product => product.prod_id == id)
    }
    setProductCategories(productCategoryData)
    // setQualityRatings(qualityRatingData)
    setConstructions(constructionData)
    if (prods.length > 0) {
      setDetails(prods[0])
    } else {
      props.history.push("/products/list")
    }
  }, [productCategoryData, constructionData, products])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      prod_id: details && details.prod_id ? details.prod_id : "",

      prod_name: details && details.prod_name ? details.prod_name : "",

      prod_cat:
        details && details.product_category_id
          ? details.product_category_id
          : "",

      construction:
        details && details.construction_id ? details.construction_id : "",

      product_folder:
        details && details.product_folder ? details.product_folder : "",

      description:
        details && details.material_desc ? details.material_desc : "",
      length: details && details.length ? details.length : "",
      depth: details && details.depth ? details.depth : "",
      height: details && details.height ? details.height : "",
      img1: "",
      img2: "",
      img3: "",
      img4: "",
      img5: "",
    },
    validationSchema: Yup.object({
      prod_cat: Yup.string().required("Please enter category"),
      construction: Yup.string().required("Please enter construction type"),
      length: Yup.number()
        .required("Please enter length")
        .min(1, "This value should be between 1 and 5000")
        .max(5000, "This value should be between 1 and 5000"),

      depth: Yup.number()
        .required("Please enter depth")
        .min(1, "This value should be between 1 and 5000")
        .max(5000, "This value should be between 1 and 5000"),

      height: Yup.number()
        .required("Please enter height")
        .min(1, "This value should be between 1 and 5000")
        .max(5000, "This value should be between 1 and 5000"),
    }),
    onSubmit: () => {},
  })

  const { error } = useSelector(state => ({
    error: state.saProduct.error,
  }))

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Edit Product" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    <Row>
                      <Col md={6}>
                        <b className="align-right">
                          Product Number :-{" "}
                          {details && details.prod_no ? details.prod_no : ""}
                        </b>
                      </Col>
                    </Row>
                  </CardTitle>
                  {error ? <ToastContainer /> : ""}
                  <Form
                    id="editproductForm"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <div className="mb-3 mt-1">
                          <Label htmlFor="product-name">Product Name</Label>
                          <Input
                            name="prod_id"
                            type="hidden"
                            value={validation.values.prod_id || ""}
                          />
                          <Input
                            readOnly={true}
                            autoComplete="off"
                            disabled
                            className="form-control"
                            name="prod_name"
                            type="text"
                            id="product-name"
                            value={validation.values.prod_name || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="product-category">
                            Product Category
                          </Label>
                          <select
                            readOnly={true}
                            name="prod_cat"
                            id="formrow-product-category"
                            autoComplete="off"
                            disabled
                            className="form-control"
                            value={validation.values.prod_cat || ""}
                          >
                            <option value="">Choose...</option>
                            {productCategories &&
                              productCategories.map((productCategory, pc) => (
                                <option
                                  key={"pc" + pc}
                                  value={productCategory.id}
                                >
                                  {productCategory.prod_cat_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Col>
                      {/* <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-quality-rating">
                            Quality Rating
                          </Label>
                          <select
                            readOnly={true}
                            name="quality_rating_id"
                            id="formrow-quality-rating"
                            autoComplete="off"
                            disabled
                            className="form-control"
                            value={validation.values.quality_rating_id || ""}
                          >
                            <option value="">Choose...</option>
                            {qualityRatings &&
                              qualityRatings.map((qualityRating, qr) => (
                                <option
                                  key={"qr" + qr}
                                  value={qualityRating.id}
                                >
                                  {qualityRating.quality_rating}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Col> */}
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-productFolder">
                            Product Folder
                          </Label>
                          <Input
                            readOnly={true}
                            name="product_folder"
                            type="text"
                            autoComplete="off"
                            disabled
                            className="form-control"
                            id="formrow-product-folder"
                            value={validation.values.product_folder || ""}
                          />
                        </div>
                      </Col>
                      {/* <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-yearlyForecast">
                            Yearly Forecast
                          </Label>
                          <Input
                            readOnly={true}
                            name="yearly_forecast"
                            type="text"
                            autoComplete="off"
                            disabled
                            className="form-control"
                            id="formrow-yearly-forecast"
                            value={validation.values.yearly_forecast || ""}
                          />
                        </div>
                      </Col> */}
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-construction">
                            Construction
                          </Label>
                          <select
                            readOnly={true}
                            name="construction"
                            id="formrow-construction"
                            autoComplete="off"
                            disabled
                            className="form-control"
                            value={validation.values.construction || ""}
                          >
                            <option value="">Choose...</option>
                            {constructions &&
                              constructions.map((construction, cns) => (
                                <option
                                  key={"cns" + cns}
                                  value={construction.construction_id}
                                >
                                  {construction.construction}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-material-description">
                            Material Description
                          </Label>
                          <textarea
                            readOnly={true}
                            name="description"
                            type="text"
                            autoComplete="off"
                            disabled
                            className="form-control"
                            id="formrow-material-description"
                            value={validation.values.description || ""}
                            onKeyDown={e => {
                              e.key === "Enter" && e.preventDefault()
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="length">Length (mm)</Label>
                          <Input
                            readOnly={true}
                            name="length"
                            type="text"
                            autoComplete="off"
                            disabled
                            className="form-control"
                            id="formrow-length"
                            value={validation.values.length || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-depth">Depth (mm)</Label>
                          <Input
                            readOnly={true}
                            name="depth"
                            type="text"
                            autoComplete="off"
                            disabled
                            className="form-control"
                            id="formrow-depth"
                            value={validation.values.depth || ""}
                          />
                        </div>
                      </Col>

                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-heigth">Heigth (mm)</Label>
                          <Input
                            readOnly={true}
                            name="height"
                            type="text"
                            autoComplete="off"
                            disabled
                            className="form-control"
                            id="formrow-heigth"
                            value={validation.values.height || ""}
                          />
                        </div>
                      </Col>
                    </Row>

                    {/* Images */}
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-1">
                            Product Image 1
                          </Label>
                          <Input
                            readOnly={true}
                            name="img1"
                            type="file"
                            autoComplete="off"
                            disabled
                            className="form-control"
                            id="formrow-image-1"
                            value={validation.values.img1 || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3 text-center">
                          {details && details.img1 ? (
                            <a
                              href={details.img1}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={details.img1}
                                className="img-fluid img-thumbnail rounded"
                                width="80"
                                height="100"
                                alt="img1"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-2">
                            Product Image 2
                          </Label>
                          <Input
                            readOnly={true}
                            name="img2"
                            type="file"
                            autoComplete="off"
                            disabled
                            className="form-control"
                            id="formrow-image-2"
                            value={validation.values.img2 || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3 text-center">
                          {details && details.img2 ? (
                            <a
                              href={details.img2}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={details.img2}
                                className="img-fluid img-thumbnail rounded"
                                width="80"
                                height="100"
                                alt="img2"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-3">
                            Product Image 3
                          </Label>
                          <Input
                            readOnly={true}
                            name="img3"
                            type="file"
                            autoComplete="off"
                            disabled
                            className="form-control"
                            id="formrow-image-3"
                            value={validation.values.img3 || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3 text-center">
                          {details && details.img3 ? (
                            <a
                              href={details.img3}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={details.img3}
                                className="img-fluid img-thumbnail rounded"
                                width="80"
                                height="100"
                                alt="img3"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-4">
                            Product Image 4
                          </Label>
                          <Input
                            readOnly={true}
                            name="img4"
                            type="file"
                            autoComplete="off"
                            disabled
                            className="form-control"
                            id="formrow-image-4"
                            value={validation.values.img4 || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3 text-center">
                          {details && details.img4 ? (
                            <a
                              href={details.img4}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={details.img4}
                                className="img-fluid img-thumbnail rounded"
                                width="80"
                                height="100"
                                alt="img4"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-5">
                            Product Image 5
                          </Label>
                          <Input
                            readOnly={true}
                            name="img5"
                            type="file"
                            autoComplete="off"
                            disabled
                            className="form-control"
                            id="formrow-image-5"
                            value={validation.values.img5 || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3 text-center">
                          {details && details.img5 ? (
                            <a
                              href={details.img5}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={details.img5}
                                className="img-fluid img-thumbnail rounded"
                                width="80"
                                height="100"
                                alt="img5"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Row>

                    <div>
                      {products && products.loading ? (
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          disabled
                        >
                          Updating ...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          disabled
                          className="btn btn-primary w-md"
                        >
                          Update Product
                        </button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SaEditProduct
