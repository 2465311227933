import {
  SA_GET_PRODUCTS_LISTING,
  SA_GET_PRODUCTS_LISTING_SUCCESS,
  SA_GET_PRODUCTS_LISTING_FAILED,
  SA_PRODUCT_EDIT,
  SA_PRODUCT_EDIT_SUCCESS,
  SA_PRODUCT_EDIT_FAIL,
} from "./actionTypes"

const initialState = {
  error: null,
  msg: null,
  loading: false,
  product: [],
  productEdit: null,
}

const saProduct = (state = initialState, action) => {
  switch (action.type) {
    case SA_GET_PRODUCTS_LISTING:
      state = {
        ...state,
        loading: true,
        error: null,
        product: action.payload,
        productEdit: null,
        msg: null,
      }
      break
    case SA_GET_PRODUCTS_LISTING_SUCCESS:
      state = { ...state, loading: false, error: null, product: action.payload, msg: null, }
      break
    case SA_GET_PRODUCTS_LISTING_FAILED:
      state = {
        ...state,
        loading: false,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
            msg: null,
      }
      break
      case SA_PRODUCT_EDIT:
        state = {
          ...state,
          loading: true,
          error: null,
          productEdit: action.payload,
        }
        break
      case SA_PRODUCT_EDIT_SUCCESS:
        if (state === null || state.product.length === 0) {
          state = {
            ...state,
            loading: false,
            error: null,
            product: [...state.product, action.payload],
          }
        } else {
          state = {
            ...state,
            loading: false,
            product: state.product.map(i =>
              i.prod_id === action.payload.prod_id ? action.payload : i
            ),
          }
        }
        break
      case SA_PRODUCT_EDIT_FAIL:
        state = {
          ...state,
          loading: false,
          error:
            action.payload !== "light"
              ? action.payload
              : "" !== "light"
              ? action.payload
              : "",
        }
        break
    default:
      state = { ...state, loading: false, error: null, msg: null, }
      break
  }
  return state
}

export default saProduct
