export const GET_MATERIALSTANDARD_LISTING = "GET_MATERIALSTANDARD_LISTING"
export const GET_MATERIALSTANDARD_LISTING_SUCCESS =
  "GET_MATERIALSTANDARD_LISTING_SUCCESS"
export const GET_MATERIALSTANDARD_LISTING_FAILED =
  "GET_MATERIALSTANDARD_LISTING_FAILED"

export const ADD_MATERIAL = "ADD_MATERIAL"
export const ADD_MATERIAL_SUCCESS = "ADD_MATERIAL_SUCCESS"
export const ADD_MATERIAL_FAIL = "ADD_MATERIAL_FAIL"

export const CHANGE_MATERIAL_STATUS = "CHANGE_MATERIAL_STATUS"
export const CHANGE_MATERIAL_STATUS_SUCCESS = "CHANGE_MATERIAL_STATUS_SUCCESS"
export const CHANGE_MATERIAL_STATUS_FAIL = "CHANGE_MATERIAL_STATUS_FAIL"

export const EDIT_MATERIAL = "EDIT_MATERIAL"
export const EDIT_MATERIAL_SUCCESS = "EDIT_MATERIAL_SUCCESS"
export const EDIT_MATERIAL_FAIL = "EDIT_MATERIAL_FAIL"

export const UPDATE_MATERIAL = "UPDATE_MATERIAL"
export const UPDATE_MATERIAL_SUCCESS = "UPDATE_MATERIAL_SUCCESS"
export const UPDATE_MATERIAL_FAIL = "UPDATE_MATERIAL_FAIL"

export const FETCH_MATERIAL_BY_SECTION = "FETCH_MATERIAL_BY_SECTION"
export const FETCH_MATERIAL_BY_SECTION_SUCCESS =
  "FETCH_MATERIAL_BY_SECTION_SUCCESS"
export const FETCH_MATERIAL_BY_SECTION_FAIL = "FETCH_MATERIAL_BY_SECTION_FAIL"

export const MATERIAL_STANDARD_POSITION = "MATERIAL_STANDARD_POSITION"
export const MATERIAL_STANDARD_POSITION_SUCCESS =
  "MATERIAL_STANDARD_POSITION_SUCCESS"
export const MATERIAL_STANDARD_POSITION_FAIL = "MATERIAL_STANDARD_POSITION_FAIL"
