export const GET_QUALITYRATING_LISTING = "GET_QUALITYRATING_LISTING"
export const GET_QUALITYRATING_LISTING_SUCCESS =
  "GET_QUALITYRATING_LISTING_SUCCESS"
export const GET_QUALITYRATING_LISTING_FAILED =
  "GET_QUALITYRATING_LISTING_FAILED"

export const ADD_QUALITY_RATING = "ADD_QUALITY_RATING"
export const ADD_QUALITY_RATING_SUCCESS = "ADD_QUALITY_RATING_SUCCESS"
export const ADD_QUALITY_RATING_FAIL = "ADD_QUALITY_RATING_FAIL"

export const CHANGE_QUALITY_RATING_STATUS = "CHANGE_QUALITY_RATING_STATUS"
export const CHANGE_QUALITY_RATING_STATUS_SUCCESS =
  "CHANGE_QUALITY_RATING_STATUS_SUCCESS"
export const CHANGE_QUALITY_RATING_STATUS_FAIL =
  "CHANGE_QUALITY_RATING_STATUS_FAIL"

export const EDIT_QUALITY_RATING = "EDIT_QUALITY_RATING"
export const EDIT_QUALITY_RATING_SUCCESS = "EDIT_QUALITY_RATING_SUCCESS"
export const EDIT_QUALITY_RATING_FAIL = "EDIT_QUALITY_RATING_FAIL"

export const UPDATE_QUALITY_RATING = "UPDATE_QUALITY_RATING"
export const UPDATE_QUALITY_RATING_SUCCESS = "UPDATE_QUALITY_RATING_SUCCESS"
export const UPDATE_QUALITY_RATING_FAIL = "UPDATE_QUALITY_RATING_FAIL"

export const FETCH_QUALITY_RATING_BY_SECTION = "FETCH_QUALITY_RATING_BY_SECTION"
export const FETCH_QUALITY_RATING_BY_SECTION_SUCCESS =
  "FETCH_QUALITY_RATING_BY_SECTION_SUCCESS"
export const FETCH_QUALITY_RATING_BY_SECTION_FAIL =
  "FETCH_QUALITY_RATING_BY_SECTION_FAIL"

  export const UPDATE_QUALITY_RATING_POSITION = "UPDATE_QUALITY_RATING_POSITION"
export const UPDATE_QUALITY_RATING_POSITION_SUCCESS =
  "UPDATE_QUALITY_RATING_POSITION_SUCCESS"
export const UPDATE_QUALITY_RATING_POSITION_FAIL = "UPDATE_QUALITY_RATING_POSITION_FAIL"