import {
  GET_USERLISTACCORDINGROLE_LISTING,
  GET_USERLISTACCORDINGROLE_LISTING_SUCCESS,
  GET_USERLISTACCORDINGROLE_LISTING_FAILED,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  userListAccordingRole: null,
}

const userListAccordingRole = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERLISTACCORDINGROLE_LISTING:
      state = {
        ...state,
        loading: true,
        userListAccordingRole: action.payload,
      }
      break
    case GET_USERLISTACCORDINGROLE_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        userListAccordingRole: action.payload,
      }
      break
    case GET_USERLISTACCORDINGROLE_LISTING_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default userListAccordingRole
