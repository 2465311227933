import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { BD_GET_INCOTERMCITY_LISTING } from "./actionTypes"
import {
  bdIncotermCityListingSuccess,
  bdIncotermCityListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { bdIncotermCityListingApi } from "../../../helpers/fakebackend_helper"

function* getBdIncotermCityListing() {
  try {
    const response = yield call(bdIncotermCityListingApi)
    if (!response.results.incoterm_city) {
      yield put(bdIncotermCityListingFail(error))
    }
    yield put(bdIncotermCityListingSuccess(response.results.incoterm_city))
  } catch (error) {
    yield put(bdIncotermCityListingFail(error))
  }
}

function* bdIncotermCitySaga() {
  yield takeEvery(BD_GET_INCOTERMCITY_LISTING, getBdIncotermCityListing)
}
export default bdIncotermCitySaga
