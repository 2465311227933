import React, { useState, useEffect, useCallback } from "react"

import {
  Card,
  CardBody,
  Col,
  CardTitle,
  Container,
  Form,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import classnames from "classnames"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  coOfferEdit,
  coUpdateOffer,
  coDeliveredOnListing,
  coEnvironmentalAndHealthCertificationListing,
  coHeaveyColliListing,
  coIncotermCityListing,
  coMaterialStandardListing,
  coOfferStatusListing,
  coPackagingListing,
  coPackingStandardListing,
  coSupplierNameListing,
  coUserListAccordingRoleListing,
} from "../../../store/actions"

const CoEditOffer = props => {
  document.title = "Edit Offer"
  const { id } = useParams()
  const dispatch = useDispatch()
  const defaultMargin = 0.8 // 20% margin =>  (100-20)/100

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])
  const [productNumberNameCategory, setProductNumberNameCategory] =
    useState(null)

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  useEffect(() => {
    dispatch(coSupplierNameListing())
    dispatch(coOfferStatusListing())
    dispatch(coIncotermCityListing())
    dispatch(coMaterialStandardListing())
    dispatch(coPackingStandardListing())
    dispatch(coEnvironmentalAndHealthCertificationListing())
    dispatch(coPackagingListing())
    dispatch(coDeliveredOnListing())
    dispatch(coHeaveyColliListing())
    dispatch(coUserListAccordingRoleListing({ user_role: "qa" }, props.history))
    dispatch(coOfferEdit(id))
  }, [])

  const [details, setDetails] = useState({})
  const [coSupplierName, setCoSupplierName] = useState([])
  const [coOfferStatus, setCoOfferStatus] = useState([])
  const [coIncotermCity, setCoIncotermCity] = useState([])
  const [coMaterialStandard, setCoMaterialStandard] = useState([])
  const [coPackingStandard, setCoPackingStandard] = useState([])
  const [
    coEnvironmentalAndHealthCertification,
    setCoEnvironmentalAndHealthCertification,
  ] = useState([])
  const [coPackaging, setCoPackaging] = useState([])
  const [coDeliveredOn, setCoDeliveredOn] = useState([])
  const [coHeaveyColli, setCoHeaveyColli] = useState([])
  const [coUserListAccordingRole, setCoUserListAccordingRole] = useState([])

  const offersData = useSelector(state => state.coOffer.offer)

  const coSupplierNameData = useSelector(
    state => state.coSupplierName.supplierName
  ) ///1st supplierName is from reducer
  const coOfferStatusData = useSelector(
    state => state.coOfferStatus.offerStatus
  )
  const coIncotermCityData = useSelector(
    state => state.coIncotermCity.incotermCity
  )
  const coMaterialStandardData = useSelector(
    state => state.coMaterialStandard.materialStandard
  )
  const coPackingStandardData = useSelector(
    state => state.coPackingStandard.packingStandard
  )
  const coEnvironmentalAndHealthCertificationData = useSelector(
    state =>
      state.coEnvironmentalAndHealthCertification
        .environmentalAndHealthCertification
  )
  const coPackagingData = useSelector(state => state.coPackaging.packaging)
  const coDeliveredOnData = useSelector(
    state => state.coDeliveredOn.deliveredOn
  )
  const coHeaveyColliData = useSelector(
    state => state.coHeaveyColli.heaveyColli
  )
  const coUserListAccordingRoleData = useSelector(
    state => state.coUserListAccordingRole.userListAccordingRole
  )

  useEffect(() => {
    let offers = []
    if (offersData) {
      offers = offersData.filter(offer => offer.offer_id == id)
    }
    setCoSupplierName(coSupplierNameData)
    setCoOfferStatus(coOfferStatusData)
    setCoIncotermCity(coIncotermCityData)
    setCoMaterialStandard(coMaterialStandardData)
    setCoPackingStandard(coPackingStandardData)
    setCoEnvironmentalAndHealthCertification(
      coEnvironmentalAndHealthCertificationData
    )
    setCoPackaging(coPackagingData)
    setCoDeliveredOn(coDeliveredOnData)
    setCoHeaveyColli(coHeaveyColliData)
    setCoUserListAccordingRole(coUserListAccordingRoleData)
    if (offers.length > 0) {
      setDetails(offers[0])
    } else {
      props.history.push("/offers/list")
    }
  }, [
    coSupplierNameData,
    coOfferStatusData,
    coIncotermCityData,
    coMaterialStandardData,
    coPackingStandardData,
    coEnvironmentalAndHealthCertificationData,
    coPackagingData,
    coDeliveredOnData,
    coHeaveyColliData,
    coUserListAccordingRoleData,
  ])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      offer_id: id,
      offer_status_id:
        details && details.offer_status_id ? details.offer_status_id : "",
      vendor_article_no:
        details && details.vendor_article_no ? details.vendor_article_no : "",
      supplier_id: details && details.supplier_id ? details.supplier_id : "",
      production_time:
        details && details.production_time ? details.production_time : "",
      incoterm_city_id:
        details && details.incoterm_city_id ? details.incoterm_city_id : "",
      material_standard_id:
        details && details.material_standard_id
          ? details.material_standard_id
          : "",
      packing_standard_id:
        details && details.packing_standard_id
          ? details.packing_standard_id
          : "",
      env_health_id:
        details && details.env_health_id ? details.env_health_id : "",
      non_compliance:
        details && details.non_compliance ? details.non_compliance : "",
      sbn_comments: details && details.sbn_comments ? details.sbn_comments : "",
      quality_control:
        details && details.quality_control ? details.quality_control : "",
      color_code_gloss:
        details && details.color_code_gloss ? details.color_code_gloss : "",
      purchase_price_fsc:
        details && details.purchase_price_fsc ? details.purchase_price_fsc : 0,
      yearly_purchase_fsc:
        details && details.yearly_purchase_fsc
          ? details.yearly_purchase_fsc
          : 0,
      purchase_price_non_fsc:
        details && details.purchase_price_non_fsc
          ? details.purchase_price_non_fsc
          : 0,
      yearly_purchase_non_fsc:
        details && details.yearly_purchase_non_fsc
          ? details.yearly_purchase_non_fsc
          : 0,
      purchasing_price_comments:
        details && details.purchasing_price_comments
          ? details.purchasing_price_comments
          : "",
      sales_price_fsc:
        details && details.sales_price_fsc ? details.sales_price_fsc : 0,
      sales_margin_fsc:
        details && details.sales_margin_fsc ? details.sales_margin_fsc : 0,
      yearly_sales_fsc:
        details && details.yearly_sales_fsc ? details.yearly_sales_fsc : 0,
      sales_price_non_fsc:
        details && details.sales_price_non_fsc
          ? details.sales_price_non_fsc
          : 0,
      sales_margin_non_fsc:
        details && details.sales_margin_non_fsc
          ? details.sales_margin_non_fsc
          : 0,
      yearly_sales_non_fsc:
        details && details.yearly_sales_non_fsc
          ? details.yearly_sales_non_fsc
          : 0,

      full_truck: details && details.full_truck ? details.full_truck : "",
      container: details && details.container ? details.container : "",
      moq: details && details.moq ? details.moq : "",
      net_weight: details && details.net_weight ? details.net_weight : "",
      gross_weight: details && details.gross_weight ? details.gross_weight : "",
      packaging_id: details && details.packaging_id ? details.packaging_id : "",
      delivered_on: details && details.delivered_on ? details.delivered_on : "",
      heavy_colli_id:
        details && details.heavy_colli_id ? details.heavy_colli_id : "",
      units_per_box:
        details && details.units_per_box ? details.units_per_box : "",
      bom_per_article:
        details && details.bom_per_article ? details.bom_per_article : "",
      master_cardboard_box:
        details && details.master_cardboard_box
          ? details.master_cardboard_box
          : "",
      packing_comments:
        details && details.packing_comments ? details.packing_comments : "",
      qa_user_id: details && details.user_type_id ? details.user_type_id : "",
      sample_request:
        details && details.sample_request ? details.sample_request : "",
      sample_comments:
        details && details.sample_comments ? details.sample_comments : "",
      sample_status:
        details && details.sample_status ? details.sample_status : "",
      qa_sample_checking_comments:
        details && details.qa_sample_checking_comments
          ? details.qa_sample_checking_comments
          : "",
      sales_sample_checking_comments:
        details && details.sales_sample_checking_comments
          ? details.sales_sample_checking_comments
          : "",
      sample_freight_cost:
        details && details.sample_freight_cost
          ? details.sample_freight_cost
          : "",
      sample_main_material:
        details && details.sample_main_material
          ? details.sample_main_material
          : "",
      sample_hardware:
        details && details.sample_hardware ? details.sample_hardware : "",
      sample_surface_treatment:
        details && details.sample_surface_treatment
          ? details.sample_surface_treatment
          : "",
    },
    validationSchema: Yup.object({
      offer_status_id: Yup.string().required("Offer status is required"),
      supplier_id: Yup.string().required("Supplier is required"),
      incoterm_city_id: Yup.string().required("Incoterm is required"),

      production_time: Yup.string()
        .required("Production time is required. this is must be a number.")
        .matches(
          /^[0-9]+$/,
          "Positive numbers are allowed without dots, negetive or decimal"
        ),

      material_standard_id: Yup.string().required(
        "Material standard is required"
      ),
      packing_standard_id: Yup.string().required(
        "Packing standard is required"
      ),
      env_health_id: Yup.string().required("Env health is required"),
      
      purchase_price_fsc: Yup.number().required("Purchase FSC is required"),
      yearly_purchase_fsc: Yup.number(),
      sales_price_fsc: Yup.number(),
      sales_margin_fsc: Yup.number(),
      yearly_sales_fsc: Yup.number(),

      purchase_price_non_fsc: Yup.number(),
      yearly_purchase_non_fsc: Yup.number(),
      sales_price_non_fsc: Yup.number(),
      sales_margin_non_fsc: Yup.number(),
      yearly_sales_non_fsc: Yup.number(),

      sales_price_fsc: Yup.number().required("Sales price FSC is required"),
      sales_margin_fsc: Yup.number().required("Sales margin FSC is required"),
      yearly_sales_fsc: Yup.number().required("Yearly sales FSC is required"),
      full_truck: Yup.number().required("Full truck is required"),
      container: Yup.number().required("20ST Container is required"),
      moq: Yup.number().required("Moq is required"),
      net_weight: Yup.number().required("Net weight is required"),
      gross_weight: Yup.number().required("Gross weight is required"),
      packaging_id: Yup.string().required("Packaging is required"),
      delivered_on: Yup.string().required("Delivered on is required"),
      heavy_colli_id: Yup.string().required("Heavy colli is required"),
      units_per_box: Yup.number().required("Units per box is required"),
      bom_per_article: Yup.number().required("BOM per article is required"),
      // user_type_id: Yup.string().required("Sample QA is required"),
    }),

    onSubmit: values => {
      dispatch(coUpdateOffer(values, props.history))
    },
  })

  function on_purchase_price_change(e, type) {
    let current_value = isNaN(e.target.value) ? 0 : parseFloat(e.target.value)
    let yearly_forecast = isNaN(details.yearly_forecast)
      ? 0
      : details.yearly_forecast * current_value
    if (type === "fsc") {
      validation.setFieldValue("yearly_purchase_fsc", yearly_forecast)
      let sales_price = details.sales_price_fsc
        ? parseFloat(details.sales_price_fsc)
        : 0
      validation.setFieldValue("sales_price_fsc", sales_price)
      let sales_margin = sales_price > 0 ? 1 - current_value / sales_price : 0
      sales_margin = isNaN(sales_margin) ? 0 : sales_margin * 100
      sales_margin = sales_margin.toFixed(1)
      validation.setFieldValue("sales_margin_fsc", sales_margin)
      validation.setFieldValue(
        "yearly_sales_fsc",
        yearly_forecast * current_value
      )
    } else {
      validation.setFieldValue("yearly_purchase_non_fsc", yearly_forecast)

      let sales_price = details.sales_price_non_fsc
        ? parseFloat(details.sales_price_non_fsc)
        : 0
      validation.setFieldValue("sales_price_non_fsc", sales_price)
      let sales_margin = sales_price > 0 ? 1 - current_value / sales_price : 0
      sales_margin = isNaN(sales_margin) ? 0 : sales_margin * 100
      sales_margin = sales_margin.toFixed(1)
      validation.setFieldValue("sales_margin_non_fsc", sales_margin)
      validation.setFieldValue(
        "yearly_sales_non_fsc",
        yearly_forecast * current_value
      )
    }
  }

  const offer = useSelector(state => state.coOffer.offer)
  const { error, msg } = useSelector(state => ({
    error: state.coOffer.error,
    msg: state.coOffer.msg ? state.coOffer.msg : "",
  }))

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            breadcrumbItem="Edit Offer"
          // extra={productNumberNameCategory}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    <Row>
                      <Col md={4}>
                        <b className="align-left">
                          Product Number :-{" "}
                          {details && details.product_number
                            ? details.product_number
                            : ""}
                        </b>
                      </Col>
                      <Col md={4}>
                        <b className="align-middle">
                          Product Name :-{" "}
                          {details && details.product_name
                            ? details.product_name
                            : ""}
                        </b>
                      </Col>
                      <Col md={4}>
                        <b className="align-right">
                          Product Category :-{" "}
                          {details && details.product_category_name
                            ? details.product_category_name
                            : ""}
                        </b>
                      </Col>
                    </Row>
                  </CardTitle>
                  {msg || error ? (
                    <ToastContainer className="Toastify__close-button" />
                  ) : (
                    ""
                  )}
                  {Object.keys(validation.errors).some(
                    key => validation.touched[key] && !validation.values[key]
                  ) && (
                      <div className="text-danger">
                        <ul>
                          {Object.keys(validation.errors).map((key, index) => {
                            if (
                              validation.touched[key] &&
                              !validation.values[key]
                            ) {
                              return (
                                <li key={index}>{validation.errors[key]}.</li>
                              )
                            }
                            return null
                          })}
                        </ul>
                      </div>
                    )}
                  <Form
                    id="editOfferForm"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === 1,
                              })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">01</span> Offer Details
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 2,
                              })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number ms-2">02</span> Offer
                              Price
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 3,
                              })}
                              onClick={() => {
                                setactiveTab(3)
                              }}
                              disabled={!(passedSteps || []).includes(3)}
                            >
                              <span className="number">03</span> Offer Packing
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 4 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 4,
                              })}
                              onClick={() => {
                                setactiveTab(4)
                              }}
                              disabled={!(passedSteps || []).includes(4)}
                            >
                              <span className="number">04</span> Sample Details
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix mt-4 addformsection">
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId={1}>
                            {/* <Form> */}
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-offer-status">
                                    Offer Status{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    name="offer_status_id"
                                    id="add-offer-offer-status"
                                    className={
                                      validation.touched.offer_status_id &&
                                        validation.errors.offer_status_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.offer_status_id || ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {coOfferStatus &&
                                      coOfferStatus.map((offerStatus, ofs) => (
                                        <option
                                          key={"ofs" + ofs}
                                          value={offerStatus.id}
                                        >
                                          {offerStatus.offer_status}
                                        </option>
                                      ))}
                                  </select>
                                  {validation.touched.offer_status_id &&
                                    validation.errors.offer_status_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.offer_status_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-Vendor-article-number">
                                    Vendor Article Number
                                  </Label>
                                  <Input
                                    name="vendor_article_no"
                                    type="text"
                                    className="form-control"
                                    id="add-offer-Vendor-article-number"
                                    // placeholder="Enter your vender article number"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.vendor_article_no || ""
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-supplier-id">
                                    Supplier Name{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    name="supplier_id"
                                    id="add-offer-supplier-id"
                                    className={
                                      validation.touched.supplier_id &&
                                        validation.errors.supplier_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.supplier_id || ""}
                                  >
                                    <option value="">Choose...</option>
                                    {coSupplierName &&
                                      coSupplierName.map(
                                        (supplierName, snk) => (
                                          <option
                                            key={"snk" + snk}
                                            value={supplierName.id}
                                          >
                                            {supplierName.name}
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {validation.touched.supplier_id &&
                                    validation.errors.supplier_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.supplier_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-production-input1">
                                    Production Time{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="production_time"
                                    type="number"
                                    className={
                                      validation.touched.production_time &&
                                        validation.errors.production_time
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="basicpill-production-input1"
                                    // placeholder="Enter production time in number of days"
                                    onChange={e => {
                                      validation.handleChange(e)
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.production_time || ""
                                    }
                                  />
                                  {validation.touched.production_time &&
                                    validation.errors.production_time ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.production_time}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-incoterm-city">
                                    Incoterm City (port){" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.incoterm_city_id &&
                                        validation.errors.incoterm_city_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="incoterm_city_id"
                                    id="add-offer-incoterm-city"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.incoterm_city_id || ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {coIncotermCity &&
                                      coIncotermCity.map(
                                        (incotermCity, icc) => (
                                          <option
                                            key={"icc" + icc}
                                            value={incotermCity.id}
                                          >
                                            {incotermCity.city}
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {validation.touched.incoterm_city_id &&
                                    validation.errors.incoterm_city_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.incoterm_city_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-material-standard">
                                    Material Standard{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.material_standard_id &&
                                        validation.errors.material_standard_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="material_standard_id"
                                    id="add-offer-material-standard"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.material_standard_id ||
                                      ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {coMaterialStandard &&
                                      coMaterialStandard.map(
                                        (materialStandard, ms) => (
                                          <option
                                            key={"ms" + ms}
                                            value={materialStandard.id}
                                          >
                                            {materialStandard.material_standard}
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {validation.touched.material_standard_id &&
                                    validation.errors.material_standard_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.material_standard_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-packing-standard">
                                    Packing Standard{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.packing_standard_id &&
                                        validation.errors.packing_standard_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="packing_standard_id"
                                    id="add-offer-packing-standard"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.packing_standard_id ||
                                      ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {coPackingStandard &&
                                      coPackingStandard.map(
                                        (packingStandard, ps) => (
                                          <option
                                            key={"ps" + ps}
                                            value={packingStandard.id}
                                          >
                                            {packingStandard.packing_standard}
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {validation.touched.packing_standard_id &&
                                    validation.errors.packing_standard_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.packing_standard_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-env&hlth-certification">
                                    Environmental and Health Certification{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.env_health_id &&
                                        validation.errors.env_health_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="env_health_id"
                                    id="add-offer-env&hlth-certification"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.env_health_id || ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {coEnvironmentalAndHealthCertification &&
                                      coEnvironmentalAndHealthCertification.map(
                                        (
                                          environmentalAndHealthCertification,
                                          enhc
                                        ) => (
                                          <option
                                            key={"enhc" + enhc}
                                            value={
                                              environmentalAndHealthCertification.id
                                            }
                                          >
                                            {
                                              environmentalAndHealthCertification.certs
                                            }
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {validation.touched.env_health_id &&
                                    validation.errors.env_health_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.env_health_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-compliance-input1">
                                    NON-compliance
                                  </Label>
                                  <textarea
                                    name="non_compliance"
                                    id="basicpill-compliance-input1"
                                    className="form-control"
                                    rows="3"
                                    // placeholder="Free Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.non_compliance || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-comments-input1">
                                    SBN comments
                                  </Label>
                                  <textarea
                                    name="sbn_comments"
                                    id="basicpill-comments-input1"
                                    className="form-control"
                                    rows="3"
                                    // placeholder="Free Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.sbn_comments || ""}
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-quality-input1">
                                    Quality Control
                                  </Label>
                                  <textarea
                                    name="quality_control"
                                    id="basicpill-quality-input1"
                                    className="form-control"
                                    rows="3"
                                    // placeholder="Free Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.quality_control || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-color-input1">
                                    Color code and Gloss
                                  </Label>
                                  <textarea
                                    name="color_code_gloss"
                                    id="basicpill-color-input1"
                                    className="form-control"
                                    rows="3"
                                    // placeholder="Free Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.color_code_gloss || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {/* </Form> */}
                          </TabPane>
                          <TabPane tabId={2}>
                            <div>
                              {/* <Form> */}
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-price-input5">
                                      Purchase Price (FSC)
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="purchase_price_fsc"
                                      type="text"
                                      autoComplete="off"
                                      className={
                                        validation.touched.purchase_price_fsc &&
                                          validation.errors.purchase_price_fsc
                                          ? "purchase_price_fsc is-invalid"
                                          : "purchase_price_fsc"
                                      }
                                      onChange={e => (
                                        validation.handleChange(e),
                                        on_purchase_price_change(e, "fsc")
                                      )}
                                      id="basicpill-price-input5"
                                      // placeholder="Enter purchase price (FSC)."
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.purchase_price_fsc ||
                                        ""
                                      }
                                    />
                                    {validation.touched.purchase_price_fsc &&
                                      validation.errors.purchase_price_fsc ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.purchase_price_fsc}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-Yearly-input6">
                                      Yearly Purchase (FSC)
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="yearly_purchase_fsc"
                                      type="text"
                                      autoComplete="off"
                                      readOnly={true}
                                      disabled
                                      className={
                                        validation.touched
                                          .yearly_purchase_fsc &&
                                          validation.errors.yearly_purchase_fsc
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-year-input6"
                                      // placeholder="Enter yearly price (FSC)"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.yearly_purchase_fsc ||
                                        ""
                                      }
                                    />
                                    {validation.touched.yearly_purchase_fsc &&
                                      validation.errors.yearly_purchase_fsc ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.yearly_purchase_fsc}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-purchase-price-input10">
                                      Purchase Price (NON FSC)
                                    </Label>
                                    <Input
                                      name="purchase_price_non_fsc"
                                      type="text"
                                      autoComplete="off"
                                      className="form-control"
                                      id="basicpill-purchase-price-input10"
                                      // placeholder="Enter purchase price (NON FSC)"
                                      onChange={e => {
                                        validation.handleChange(e)
                                        on_purchase_price_change(e, "non_fsc")
                                      }}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .purchase_price_non_fsc || ""
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-yearly-price-non-input10">
                                      Yearly Purchase (NON FSC)
                                    </Label>
                                    <Input
                                      name="yearly_purchase_non_fsc"
                                      type="text"
                                      autoComplete="off"
                                      readOnly={true}
                                      disabled
                                      className="form-control"
                                      id="basicpill-yearly-price-non-input10"
                                      // placeholder="Enter yearly price (NON FSC)"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .yearly_purchase_non_fsc || ""
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-com-input1">
                                      Purchasing Price Comments
                                    </Label>
                                    <textarea
                                      name="purchasing_price_comments"
                                      id="basicpill-com-input1"
                                      className="form-control"
                                      rows="3"
                                      // placeholder="Enter Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .purchasing_price_comments || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {/* <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <FormGroup className="mb-3">
                                      <div className="form-check">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="invalidCheck"
                                          name="export_jysk_list"
                                          value={exportJyskList}
                                          onClick={() =>
                                            setExportJyskList(!exportJyskList)
                                          }
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="invalidCheck"
                                        >
                                          {" "}
                                          Export to JYSK list
                                        </Label>
                                      </div>
                                    </FormGroup>
                                  </div>
                                </Col>
                              </Row> */}
                            </div>
                          </TabPane>
                          <TabPane tabId={3}>
                            <div>
                              <Row>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-truck-input11">
                                      Full Truck/40HC
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="full_truck"
                                      type="text"
                                      className={
                                        validation.touched.full_truck &&
                                          validation.errors.full_truck
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-truck-input11"
                                      // placeholder="Number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.full_truck || ""}
                                    />
                                    {validation.touched.full_truck &&
                                      validation.errors.full_truck ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.full_truck}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-20st-input11">
                                      20ST Container
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="container"
                                      type="text"
                                      className={
                                        validation.touched.container &&
                                          validation.errors.container
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-20st-input11"
                                      // placeholder="Number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.container || ""}
                                    />
                                    {validation.touched.container &&
                                      validation.errors.container ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.container}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-moq-input11">
                                      MOQ
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="moq"
                                      type="text"
                                      className={
                                        validation.touched.moq &&
                                          validation.errors.moq
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-moq-input11"
                                      // placeholder="Number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.moq || ""}
                                    />
                                    {validation.touched.moq &&
                                      validation.errors.moq ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.moq}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-net-input11">
                                      Net Weight
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="net_weight"
                                      type="text"
                                      className={
                                        validation.touched.net_weight &&
                                          validation.errors.net_weight
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-net-input11"
                                      // placeholder="Number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.net_weight || ""}
                                    />
                                    {validation.touched.net_weight &&
                                      validation.errors.net_weight ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.net_weight}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-gross-input12">
                                      Gross Weight
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="gross_weight"
                                      type="text"
                                      className={
                                        validation.touched.gross_weight &&
                                          validation.errors.gross_weight
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-gross-input12"
                                      // placeholder="Number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.gross_weight || ""
                                      }
                                    />
                                    {validation.touched.gross_weight &&
                                      validation.errors.gross_weight ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.gross_weight}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="add-offer-packaging">
                                      Packaging{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <select
                                      className={
                                        validation.touched.packaging_id &&
                                          validation.errors.packaging_id
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      name="packaging_id"
                                      id="add-offer-packaging"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.packaging_id || ""
                                      }
                                    >
                                      <option value="">Choose...</option>
                                      {coPackaging &&
                                        coPackaging.map((packaging, pck) => (
                                          <option
                                            key={"pck" + pck}
                                            value={packaging.id}
                                          >
                                            {packaging.packaging}
                                          </option>
                                        ))}
                                    </select>
                                    {validation.touched.packaging_id &&
                                      validation.errors.packaging_id ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.packaging_id}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label htmlFor="add-offer-delivered-on">
                                      Delivered On{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <select
                                      className={
                                        validation.touched.delivered_on &&
                                          validation.errors.delivered_on
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      name="delivered_on"
                                      id="add-offer-delivered-on"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.delivered_on || ""
                                      }
                                    >
                                      <option value="">Choose...</option>
                                      {coDeliveredOn &&
                                        coDeliveredOn.map((deliveredOn, dn) => (
                                          <option
                                            key={"dn" + dn}
                                            value={deliveredOn.id}
                                          >
                                            {deliveredOn.delivery_name}
                                          </option>
                                        ))}
                                    </select>
                                    {validation.touched.delivered_on &&
                                      validation.errors.delivered_on ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.delivered_on}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label htmlFor="add-offer-heavey-colli">
                                      Heavey Colli{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <select
                                      className={
                                        validation.touched.heavy_colli_id &&
                                          validation.errors.heavy_colli_id
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      name="heavy_colli_id"
                                      id="add-offer-heavey-colli"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.heavy_colli_id || ""
                                      }
                                    >
                                      <option value="">Choose...</option>
                                      {coHeaveyColli &&
                                        coHeaveyColli.map((heaveyColli, hc) => (
                                          <option
                                            key={"hc" + hc}
                                            value={heaveyColli.id}
                                          >
                                            {heaveyColli.heavy_collis}
                                          </option>
                                        ))}
                                    </select>
                                    {validation.touched.heavy_colli_id &&
                                      validation.errors.heavy_colli_id ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.heavy_colli_id}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-npb-input12">
                                      Number of Units per Box
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="units_per_box"
                                      type="text"
                                      className={
                                        validation.touched.units_per_box &&
                                          validation.errors.units_per_box
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpil-npb-input12"
                                      // placeholder="Number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.units_per_box || ""
                                      }
                                    />
                                    {validation.touched.units_per_box &&
                                      validation.errors.units_per_box ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.units_per_box}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-bom-input12">
                                      BOM per article
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="bom_per_article"
                                      type="text"
                                      className={
                                        validation.touched.bom_per_article &&
                                          validation.errors.bom_per_article
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="basicpill-bom-input12"
                                      // placeholder="Number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.bom_per_article || ""
                                      }
                                    />
                                    {validation.touched.bom_per_article &&
                                      validation.errors.bom_per_article ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.bom_per_article}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-mcb-input12">
                                      Master Cardboard Box
                                    </Label>
                                    <Input
                                      name="master_cardboard_box"
                                      type="text"
                                      className="form-control"
                                      id="basicpill-mcb-input12"
                                      // placeholder="Number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .master_cardboard_box || ""
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-pc-input1">
                                      Packing comments
                                    </Label>
                                    <textarea
                                      name="packing_comments"
                                      id="basicpill-pc-input1"
                                      className="form-control"
                                      rows="3"
                                      // placeholder="Free Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.packing_comments || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {/* </Form> */}
                            </div>
                          </TabPane>
                          <TabPane tabId={4}>
                            <div>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label htmlFor="add-offer-user-type">
                                      QA Users
                                    </Label>
                                    <select
                                      className="form-control"
                                      name="qa_user_id"
                                      id="add-offer-user-type"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.qa_user_id || ""}
                                    >
                                      <option value="">Choose...</option>
                                      {coUserListAccordingRole.length > 0 &&
                                        coUserListAccordingRole.map((u, ut) => (
                                          <option
                                            key={"ut" + ut}
                                            value={u.user_id}
                                          >
                                            {u.user_name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sr-input1">
                                      Sample Request
                                    </Label>
                                    <textarea
                                      name="sample_request"
                                      id="basicpill-sr-input1"
                                      className="form-control"
                                      rows="3"
                                      // placeholder="Free Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_request || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sm-input1">
                                      Sample comments
                                    </Label>
                                    <textarea
                                      name="sample_comments"
                                      id="basicpill-sm-input1"
                                      className="form-control"
                                      rows="3"
                                      // placeholder="Free Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_comments || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-ss-input1">
                                      Sample status
                                    </Label>
                                    <textarea
                                      name="sample_status"
                                      id="basicpill-ss-input1"
                                      className="form-control"
                                      rows="3"
                                      // placeholder="Free Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_status || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-qa-input1">
                                      QA Sample Checkin comments
                                    </Label>
                                    <textarea
                                      name="qa_sample_checking_comments"
                                      id="basicpill-qa-input1"
                                      className="form-control"
                                      rows="3"
                                      // placeholder="Free Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .qa_sample_checking_comments || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sc-input1">
                                      Sales sample checkin comments
                                    </Label>
                                    <textarea
                                      name="sales_sample_checking_comments"
                                      id="basicpill-sc-input1"
                                      className="form-control"
                                      rows="3"
                                      // placeholder="Free Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .sales_sample_checking_comments || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sf-input1">
                                      Sample freight cost
                                    </Label>
                                    <textarea
                                      name="sample_freight_cost"
                                      id="basicpill-sf-input1"
                                      className="form-control"
                                      rows="3"
                                      // placeholder="Free Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_freight_cost ||
                                        ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-smm-input1">
                                      Sample main material
                                    </Label>
                                    <textarea
                                      name="sample_main_material"
                                      id="basicpill-smm-input1"
                                      className="form-control"
                                      rows="3"
                                      // placeholder="Free Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .sample_main_material || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sh-input1">
                                      Sample hardware
                                    </Label>
                                    <textarea
                                      name="sample_hardware"
                                      id="basicpill-sh-input1"
                                      className="form-control"
                                      rows="3"
                                      // placeholder="Free Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_hardware || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sst-input1">
                                      Sample surface treatment
                                    </Label>
                                    <textarea
                                      name="sample_surface_treatment"
                                      id="basicpill-sst-input1"
                                      className="form-control"
                                      rows="3"
                                      // placeholder="Free Text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .sample_surface_treatment || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab - 1)
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                          <li className={activeTab !== 4 ? "next" : "next"}>
                            {activeTab !== 4 ? (
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                              >
                                Next
                              </Link>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                                disabled={offer && offer.loading ? true : false}
                              >
                                {offer && offer.loading
                                  ? "Updating Offer"
                                  : "Update Offer"}
                              </button>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CoEditOffer
