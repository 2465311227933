import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { QA_GET_PRODUCTS_LISTING, QA_PRODUCT_EDIT, } from "./actionTypes"

import {
  qaProductsListingSuccess,
  qaProductsListingFail,
  qaEditProductSuccess,
  qaEditProductFail,
} from "./actions"

import { qaProductListingApi, qaEditProductApi, } from "../../../helpers/fakebackend_helper"

function* qaGetProductListing() {
  try {
    const response = yield call(qaProductListingApi)
    if (!response.results.product_dtls) {
      yield put(qaProductsListingFail(error))
    }
    yield put(qaProductsListingSuccess(response.results.product_dtls))
  } catch (error) {
    yield put(qaProductsListingFail(error))
  }
}

function* qaGetProductDetailsById({ payload: { productId } }) {
  try {
    const response = yield call(qaEditProductApi, productId)
    if (response && response.status) {
      yield put(qaEditProductSuccess(response.results.prod_dtls))
    } else {
      throw response.msg ? response.msg : "Failed to edit product"
    }
  } catch (error) {
    yield put(qaEditProductFail(error))
  }
}

function* qaProductSaga() {
  yield takeEvery(QA_GET_PRODUCTS_LISTING, qaGetProductListing)
  yield takeEvery(QA_PRODUCT_EDIT, qaGetProductDetailsById)
}

export default qaProductSaga
