export const GET_OFFERSTATUS_LISTING = "GET_OFFERSTATUS_LISTING"
export const GET_OFFERSTATUS_LISTING_SUCCESS = "GET_OFFERSTATUS_LISTING_SUCCESS"
export const GET_OFFERSTATUS_LISTING_FAILED = "GET_OFFERSTATUS_LISTING_FAILED"

export const FETCH_OFFER_STATUS_BY_SECTION = "FETCH_OFFER_STATUS_BY_SECTION"
export const FETCH_OFFER_STATUS_BY_SECTION_SUCCESS =
  "FETCH_OFFER_STATUS_BY_SECTION_SUCCESS"
export const FETCH_OFFER_STATUS_BY_SECTION_FAIL =
  "FETCH_OFFER_STATUS_BY_SECTION_FAIL"

export const ADD_OFFER_STATUS = "ADD_OFFER_STATUS"
export const ADD_OFFER_STATUS_SUCCESS = "ADD_OFFER_STATUS"
export const ADD_OFFER_STATUS_FAIL = "ADD_OFFER_STATUS_FAIL"

export const EDIT_OFFER_STATUS = "EDIT_OFFER_STATUS"
export const EDIT_OFFER_STATUS_SUCCESS = "EDIT_OFFER_STATUS_SUCCESS"
export const EDIT_OFFER_STATUS_FAIL = "EDIT_OFFER_STATUS_FAIL"

export const UPDATE_OFFER_STATUS = "UPDATE_OFFER_STATUS"
export const UPDATE_OFFER_STATUS_SUCCESS = "UPDATE_OFFER_STATUS_SUCCESS"
export const UPDATE_OFFER_STATUS_FAIL = "UPDATE_OFFER_STATUS_FAIL"

export const OFFER_STATUS_ACTIVE_INACTIVE = "OFFER_STATUS_ACTIVE_INACTIVE"
export const OFFER_STATUS_ACTIVE_INACTIVE_SUCCESS =
  "OFFER_STATUS_ACTIVE_INACTIVE_SUCCESS"
export const OFFER_STATUS_ACTIVE_INACTIVE_FAIL =
  "OFFER_STATUS_ACTIVE_INACTIVE_FAIL"

export const OFFER_STATUS_POSITION = "OFFER_STATUS_POSITION"
export const OFFER_STATUS_POSITION_SUCCESS = "OFFER_STATUS_POSITION_SUCCESS"
export const OFFER_STATUS_POSITION_FAIL = "OFFER_STATUS_POSITION_FAIL"
