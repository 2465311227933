import {
  QA_GET_OFFERS_LISTING,
  QA_GET_OFFERS_LISTING_SUCCESS,
  QA_GET_OFFERS_LISTING_FAILED,
  QA_OFFER_EDIT,
  QA_OFFER_EDIT_SUCCESS,
  QA_OFFER_EDIT_FAIL,
  QA_OFFER_UPDATE,
  QA_OFFER_UPDATE_SUCCESS,
  QA_OFFER_UPDATE_FAIL,
  QA_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
  QA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS,
  QA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL,
  QA_RESET_OFFER,
  QA_RESET_OFFER_SUCCESS,
  QA_RESET_OFFER_FAIL
} from "./actionTypes"

export const qaOffersListing = history => {
  return {
    type: QA_GET_OFFERS_LISTING,
  }
}

export const qaOffersListingSuccess = offer => {
  return {
    type: QA_GET_OFFERS_LISTING_SUCCESS,
    payload: offer,
  }
}

export const qaOffersListingFail = error => {
  return {
    type: QA_GET_OFFERS_LISTING_FAILED,
    payload: error,
  }
}

export const qaOfferEdit = offer_id => {
  return {
    type: QA_OFFER_EDIT,
    payload: { id: offer_id },
  }
}

export const qaEditOfferSuccess = offer => {
  return {
    type: QA_OFFER_EDIT_SUCCESS,
    payload: offer,
  }
}

export const qaEditOfferFail = error => {
  return {
    type: QA_OFFER_EDIT_FAIL,
    payload: error,
  }
}

export const qaUpdateOffer = (offer, history) => {
  return {
    type: QA_OFFER_UPDATE,
    payload: { offer, history },
  }
}

export const qaUpdateOfferSuccess = offer => {
  return {
    type: QA_OFFER_UPDATE_SUCCESS,
    payload: offer,
  }
}

export const qaUpdateOfferFail = error => {
  return {
    type: QA_OFFER_UPDATE_FAIL,
    payload: error,
  }
}

export const qaSearchItemInNewOfferPage = search => {
  return {
    type: QA_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
    payload: {search : search},
  }
}

export const qaSearchItemInNewOfferPageSuccess = search => {
  return {
    type: QA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS,
    payload: search,
  }
}

export const qaSearchItemInNewOfferPageFail = error => {
  return {
    type: QA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL,
    payload: error,
  }
}

export const qaClearOfferResult = () => {
  return {
    type: QA_RESET_OFFER,
  }
}

export const qaClearOfferResultSuccess = () => {
  return {
    type: QA_RESET_OFFER_SUCCESS,
  }
}

export const qaClearOfferResultError = () => {
  return {
    type: QA_RESET_OFFER_FAIL,
  }
}
