export const GET_SUPPLIERNAME_LISTING = "GET_SUPPLIERNAME_LISTING"
export const GET_SUPPLIERNAME_LISTING_SUCCESS =
  "GET_SUPPLIERNAME_LISTING_SUCCESS"
export const GET_SUPPLIERNAME_LISTING_FAILED = "GET_SUPPLIERNAME_LISTING_FAILED"

export const FETCH_SUPPLIER_BY_SECTION = "FETCH_SUPPLIER_BY_SECTION"
export const FETCH_SUPPLIER_BY_SECTION_SUCCESS =
  "FETCH_SUPPLIER_BY_SECTION_SUCCESS"
export const FETCH_SUPPLIER_BY_SECTION_FAIL = "FETCH_SUPPLIER_BY_SECTION_FAIL"

export const ADD_SUPPLIER = "ADD_SUPPLIER"
export const ADD_SUPPLIER_SUCCESS = "ADD_SUPPLIER_SUCCESS"
export const ADD_SUPPLIER_FAIL = "ADD_SUPPLIER_FAIL"

export const EDIT_SUPPLIER = "EDIT_SUPPLIER"
export const EDIT_SUPPLIER_SUCCESS = "EDIT_SUPPLIER_SUCCESS"
export const EDIT_SUPPLIER_FAIL = "EDIT_SUPPLIER_FAIL"

export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER"
export const UPDATE_SUPPLIER_SUCCESS = "UPDATE_SUPPLIER_SUCCESS"
export const UPDATE_SUPPLIER_FAIL = "UPDATE_SUPPLIER_FAIL"

export const SUPPLIER_ACTIVE_INACTIVE = "SUPPLIER_ACTIVE_INACTIVE"
export const SUPPLIER_ACTIVE_INACTIVE_SUCCESS =
  "SUPPLIER_ACTIVE_INACTIVE_SUCCESS"
export const SUPPLIER_ACTIVE_INACTIVE_FAIL = "SUPPLIER_ACTIVE_INACTIVE_FAIL"

export const UPDATE_SUPPLIER_POSITION = "UPDATE_SUPPLIER_POSITION"
export const UPDATE_SUPPLIER_POSITION_SUCCESS =
  "UPDATE_SUPPLIER_POSITION_SUCCESS"
export const UPDATE_SUPPLIER_POSITION_FAIL = "UPDATE_SUPPLIER_POSITION_FAIL"

export const GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE =
  "GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE"
export const GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS =
  "GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS"
export const GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED =
  "GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED"
