import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { SA_GET_PRODUCTS_LISTING, SA_PRODUCT_EDIT, } from "./actionTypes"

import {
  saProductsListingSuccess,
  saProductsListingFail,
  saEditProductSuccess,
  saEditProductFail,
} from "./actions"

import { saProductListingApi, saEditProductApi, } from "../../../helpers/fakebackend_helper"

function* getSaProductListing() {
  try {
    const response = yield call(saProductListingApi)
    if (!response.results.product_dtls) {
      yield put(saProductsListingFail(error))
    }
    yield put(saProductsListingSuccess(response.results.product_dtls))
  } catch (error) {
    yield put(saProductsListingFail(error))
  }
}

function* saGetProductDetailsById({ payload: { productId } }) {
  try {
    const response = yield call(saEditProductApi, productId)
    if (response && response.status) {
      yield put(saEditProductSuccess(response.results.prod_dtls))
    } else {
      throw response.msg ? response.msg : "Failed to edit product"
    }
  } catch (error) {
    yield put(saEditProductFail(error))
  }
}

function* saProductSaga() {
  yield takeEvery(SA_GET_PRODUCTS_LISTING, getSaProductListing)
  yield takeEvery(SA_PRODUCT_EDIT, saGetProductDetailsById)
}

export default saProductSaga
