import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  RESET_PROFILE_FLAG,
  EDIT_PROFILE,
  PROFILE_EDIT_SUCCESS,
  PROFILE_EDIT_FAIL,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from "./actionTypes"

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const editProfileDetails = () => {
  return {
    type: EDIT_PROFILE,
  }
}
export const editProfileSuccess = editProfile => {
  return {
    type: PROFILE_EDIT_SUCCESS,
    payload: editProfile,
  }
}
export const editProfileFail = error => {
  return {
    type: PROFILE_EDIT_FAIL,
    payload: error,
  }
}

export const onUpdateProfile = data => {
  return {
    type: UPDATE_PROFILE,
    payload: { data: data },
  }
}

export const onUpdateProfileSuccess = updateProfile => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: updateProfile,
  }
}

export const onUpdateProfileFail = error => {
  return {
    type: UPDATE_PROFILE_FAIL,
    payload: error,
  }
}

export const onChangePassword = data => {
  return {
    type: CHANGE_PASSWORD,
    payload: { data: data },
  }
}

export const onChangePasswordSuccess = changePassword => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: changePassword,
  }
}

export const onChangePasswordFail = error => {
  return {
    type: CHANGE_PASSWORD_FAIL,
    payload: error,
  }
}
