import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { CO_GET_INCOTERMCITY_LISTING } from "./actionTypes"
import {
  coIncotermCityListingSuccess,
  coIncotermCityListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { coIncotermCityListingApi } from "../../../helpers/fakebackend_helper"

function* coGetIncotermCityListing() {
  try {
    const response = yield call(coIncotermCityListingApi)
    if (!response.results.incoterm_city) {
      yield put(coIncotermCityListingFail(error))
    }
    yield put(coIncotermCityListingSuccess(response.results.incoterm_city))
  } catch (error) {
    yield put(coIncotermCityListingFail(error))
  }
}

function* coIncotermCitySaga() {
  yield takeEvery(CO_GET_INCOTERMCITY_LISTING, coGetIncotermCityListing)
}
export default coIncotermCitySaga
