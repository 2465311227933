import React, { useState, useRef, useEffect } from "react"
import {
  Container,
  Row,
  Form,
  Input,
  Label,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Card,
  CardBody,
  FormGroup,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  qaCollectionsListing,
  qaDownloadExportCollectionCatalog,
  qaGetLayOut,
} from "../../../store/actions"

function QaExportDownload(props) {
  document.title = "Export Download"
  const dispatch = useDispatch()

  const [currentData, setCurrentData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [layoutData, setLayoutData] = useState([])

  const CollectionList = useSelector(state => state.qaCollection.collection)
  const exportData = useSelector(state => state.qaCollection.exportCollection)
  const layoutListing = useSelector(state => state.qaCollection.export_layout)

  const qaInitialDetails = {
    export_type: null,
    price_to_export: null,
    collection_id: null,
    layout_details: false,
    layout_id: null,
  }
  const [qaDetails, setQaDetails] = useState(qaInitialDetails)

  function downloadData(e) {
    setModalOpen(true)
    dispatch(qaDownloadExportCollectionCatalog(qaDetails, props.history))
    e.preventDefault()
  }

  function handleExportSelection(e) {
    const { name, value } = e.target
    let newState = {}
    newState = qaDetails
    if (name === "export_selection") {
      let price_to_export = document.getElementsByClassName("price_to_export")
      for (let i = 0; i < price_to_export.length; i++) {
        price_to_export[i].checked = false
      }

      newState.export_type = value
      newState.price_to_export = null
      newState.collection_id = null
      newState.layout_details = false
      newState.layout_id = null
      setLayoutData([])
      if (value == "2") {
        newState.type = "product"
        dispatch(qaCollectionsListing())
        dispatch(qaGetLayOut())
      } else {
        newState.type = null
      }
    } else if (name === "collection") {
      newState.collection_id = value
      newState.layout_details = true
      setLayoutData([])
      dispatch(qaCollectionsListing())
      dispatch(qaGetLayOut())
    }

    if (name == "layout_id") {
      newState.layout_id = value
    }

    setQaDetails(prevState => ({
      ...prevState,
      ...newState,
    }))
  }

  useEffect(() => {
    if (CollectionList && CollectionList.length > 0) {
      setCurrentData(CollectionList)
    }
    if (
      layoutListing &&
      layoutListing.layout &&
      layoutListing.layout.length > 0
    ) {
      setLayoutData(layoutListing.layout)
    }
  }, [qaDetails, CollectionList, layoutListing])

  const { error, loading } = useSelector(state => ({
    error: state.qaCollection.error,
    loading: state.qaCollection.loading,
  }))

  function moveToDownload() {
    setModalOpen(!modalOpen)
    props.history.push("/export/download")
  }

  function updateModalState() {
    let currentStateModal = !modalOpen
    setModalOpen(currentStateModal)
  }

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Export" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {error ? <ToastContainer /> : ""}

                  <Form id="exportForm" method="post">
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <FormGroup check inline>
                            <Input
                              type="radio"
                              className="form-check-input"
                              name="export_selection"
                              value="2"
                              onChange={e => handleExportSelection(e)}
                              id="export_selection_qa_product_without_prices"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="export_selection_qa_product_without_prices"
                            >
                              Export Product catalogue <b>without prices</b>{" "}
                              based on products ?
                            </Label>
                          </FormGroup>
                        </div>
                      </Col>

                      {qaDetails &&
                      qaDetails.price_to_export === null &&
                      qaDetails.type == "product" ? (
                        <Col md={12}>
                          <div
                            className="collection"
                            id="offer_product_collection"
                          >
                            <hr />
                            <strong>
                              <i>Select product collection</i>
                            </strong>
                            <br />
                            <br />
                            <select
                              name="collection"
                              className="form-control"
                              onChange={handleExportSelection}
                            >
                              <option value="">Choose...</option>
                              {currentData &&
                                currentData.map((cr, pc) =>
                                  cr.collection_type == "product" ? (
                                    <option key={"op" + pc} value={cr.id}>
                                      {cr.collection_name +
                                        " (" +
                                        cr.item_count +
                                        ")"}
                                    </option>
                                  ) : (
                                    ""
                                  )
                                )}
                            </select>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {qaDetails.layout_details && qaDetails.collection_id ? (
                        (qaDetails.export_type == "2") &&
                        layoutData.length ? (
                          <Col md={12}>
                            <div className="layout_template">
                              <hr />
                              <strong>
                                <i>Select export format</i>
                              </strong>
                              <br />
                              <br />
                              <div className="row">
                                {layoutData.map((layoutSec, il) => {
                                  return (
                                    <>
                                      <div className="col-md-12 p-2">
                                        <FormGroup
                                          check
                                          inline
                                          key={`layout_section_` + il}
                                        >
                                          <Input
                                            type="radio"
                                            name="layout_id"
                                            value={layoutSec.id}
                                            className="form-check-input"
                                            onChange={handleExportSelection}
                                            id={
                                              `layout_radio_btn_` + layoutSec.id
                                            }
                                          />
                                          <Label
                                            className="form-check-label"
                                            htmlFor={
                                              `layout_radio_btn_` + layoutSec.id
                                            }
                                          >
                                            {layoutSec.layout}
                                          </Label>
                                        </FormGroup>
                                        <img
                                          src={layoutSec.image}
                                          width="150"
                                          height="150"
                                          className="img-thumbnail"
                                          alt={layoutSec.layout}
                                        />
                                      </div>
                                      <br />
                                    </>
                                  )
                                })}
                                <br />
                              </div>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </Row>
                    <Row>
                      <Col md={6} className="mt-2">
                        {qaDetails && qaDetails.collection_id ? (
                          <button
                            className="btn btn-primary text-end mt-2"
                            onClick={e => downloadData(e)}
                          >
                            {loading ? "Loading ..." : "Export now"}
                          </button>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col md={6} className="text-end mt-2"></Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={modalOpen}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="deleteProductConfirm"
          tabIndex="-1"
          backdrop={false}
          toggle={() => updateModalState()}
        >
          <ModalHeader>Download Export</ModalHeader>
          <ModalBody>
            You export is being processed. Depending on how many products,
            exporting can take several minutes. Please check the status in the
            download section.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={moveToDownload}>
              Ok
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default QaExportDownload
