import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
    GET_CREATEDBY_LISTING,
} from "./actionTypes"
import {
    createdByListingSuccess,
    createdByListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  createdByListingApi,
} from "../../../helpers/fakebackend_helper"

function* getCreatedByListing() {
  try {
    const response = yield call(createdByListingApi)
    if (!response.results) {
      yield put(createdByListingFail(error))
    }
    yield put(createdByListingSuccess(response.results))
  } catch (error) {
    yield put(createdByListingFail(error))
  }
}

function* createdBySaga() {
  yield takeEvery(GET_CREATEDBY_LISTING, getCreatedByListing)
}
export default createdBySaga
