import {
  OFFER_SUCCESS,
  OFFER_FAIL,
  OFFER_ADD,
  GET_OFFERS_LISTING,
  GET_OFFERS_LISTING_SUCCESS,
  GET_OFFERS_LISTING_FAILED,
  OFFER_DELETE,
  OFFER_DELETE_SUCCESS,
  OFFER_DELETE_FAIL,
  OFFER_EDIT,
  OFFER_EDIT_SUCCESS,
  OFFER_EDIT_FAIL,
  OFFER_UPDATE,
  OFFER_UPDATE_SUCCESS,
  OFFER_UPDATE_FAIL,
  CHANGE_OFFER_STATUS,
  CHANGE_OFFER_STATUS_SUCCESS,
  CHANGE_OFFER_STATUS_FAIL,
  SEARCH_ITEM_IN_NEW_OFFER_PAGE,
  SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS,
  SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL,
  RESET_OFFER_SUCCESS,
  RESET_OFFER,
  RESET_OFFER_FAIL,
} from "./actionTypes"

export const offerSuccess = offer => {
  return {
    type: OFFER_SUCCESS,
    payload: offer,
  }
}

export const offerFail = error => {
  return {
    type: OFFER_FAIL,
    payload: error,
  }
}

export const addNewOffer = (offer, history) => {
  return {
    type: OFFER_ADD,
    payload: { offer, history },
  }
}

export const offersListing = history => {
  return {
    type: GET_OFFERS_LISTING,
  }
}

export const offersListingSuccess = offer => {
  return {
    type: GET_OFFERS_LISTING_SUCCESS,
    payload: offer,
  }
}

export const offersListingFail = error => {
  return {
    type: GET_OFFERS_LISTING_FAILED,
    payload: error,
  }
}

export const deleteOffer = offerId => ({
  type: OFFER_DELETE,
  payload: { id: offerId },
})

export const deleteOfferSuccess = offer => ({
  type: OFFER_DELETE_SUCCESS,
  payload: offer,
})

export const deleteOfferFail = error => ({
  type: OFFER_DELETE_FAIL,
  payload: error,
})

export const offerEdit = offer_id => {
  return {
    type: OFFER_EDIT,
    payload: { id: offer_id },
  }
}

export const editOfferSuccess = offer => {
  return {
    type: OFFER_EDIT_SUCCESS,
    payload: offer,
  }
}

export const editOfferFail = error => {
  return {
    type: OFFER_EDIT_FAIL,
    payload: error,
  }
}

export const updateOffer = (offer, history) => {
  return {
    type: OFFER_UPDATE,
    payload: { offer, history },
  }
}

export const updateOfferSuccess = offer => {
  return {
    type: OFFER_UPDATE_SUCCESS,
    payload: offer,
  }
}

export const updateOfferFail = error => {
  return {
    type: OFFER_UPDATE_FAIL,
    payload: error,
  }
}

export const changeOfferStatus = offerId => ({
  type: CHANGE_OFFER_STATUS,
  payload: { id: offerId },
})

export const changeOfferStatusSuccess = offer => ({
  type: CHANGE_OFFER_STATUS_SUCCESS,
  payload: offer,
})

export const changeOfferStatusFail = error => ({
  type: CHANGE_OFFER_STATUS_FAIL,
  payload: error,
})

export const searchItemInNewOfferPage = search => {
  return {
    type: SEARCH_ITEM_IN_NEW_OFFER_PAGE,
    payload: { search: search },
  }
}

export const searchItemInNewOfferPageSuccess = search => {
  return {
    type: SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS,
    payload: search,
  }
}

export const searchItemInNewOfferPageFail = error => {
  return {
    type: SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL,
    payload: error,
  }
}

export const clearOfferResult = () => {
  return {
    type: RESET_OFFER,
  }
}

export const clearOfferResultSuccess = () => {
  return {
    type: RESET_OFFER_SUCCESS,
  }
}

export const clearOfferResultError = () => {
  return {
    type: RESET_OFFER_FAIL,
  }
}
