import { all, fork } from "redux-saga/effects"

import AuthSaga from "./auth/login/saga"
import ForgetPasswordSaga from "./auth/forgetpwd/saga"

//admin all saga
import UserSaga from "./admin/users/saga"
import OfficeSaga from "./admin/office/saga"
import RoleSaga from "./admin/roles/saga"
import LogSaga from "./admin/logs/saga"
import productCategorySaga from "./admin/productCategory/saga"
import productNumberSaga from "./admin/productNumber/saga"
import createdBySaga from "./admin/createdBy/saga"
import ProductSaga from "./admin/products/saga"
import qualityRatingSaga from "./admin/qualityRating/saga"
import supplierNameSaga from "./admin/suppliers/saga"
import offerStatusSaga from "./admin/offerStatus/saga"
import packagingSaga from "./admin/packaging/saga"
import packingStandardSaga from "./admin/packingStandard/saga"
import userListAccordingRoleSaga from "./admin/userListAccordingRole/saga"
import deliveredOnSaga from "./admin/deliveredOn/saga"
import environmentalAndHealthCertificationSaga from "./admin/environmentalAndHealthCertification/saga"
import heaveyColliSaga from "./admin/heaveyColli/saga"
import incotermCitySaga from "./admin/incotermCity/saga"
import materialStandardSaga from "./admin/materialStandard/saga"
import offerSaga from "./admin/offers/saga"
import collectionSaga from "./admin/export/saga"
import constructionSaga from "./admin/constructions/saga"

//bd product
import bdProductSaga from "./business-developer/bd-product/saga"

// bd offer
import bdOfferSaga from "./business-developer/bd-offer/saga"

import bdDeliveredOnSaga from "./business-developer/bd-deliveredOn/saga"
import bdEnvironmentalAndHealthCertificationSaga from "./business-developer/bd-environmentalAndHealthCertification/saga"
import bdHeaveyColliSaga from "./business-developer/bd-heaveyColli/saga"
import bdIncotermCitySaga from "./business-developer/bd-incotermCity/saga"
import bdMaterialStandardSaga from "./business-developer/bd-materialStandard/saga"
import bdPackagingSaga from "./business-developer/bd-packaging/saga"
import bdOfferStatusSaga from "./business-developer/bd-offerStatus/saga"
import bdPackingStandardSaga from "./business-developer/bd-packingStandard/saga"
import bdSupplierNameSaga from "./business-developer/bd-suppliers/saga"
import bdUserListAccordingRoleSaga from "./business-developer/bd-userListAccordingRole/saga"
import bdCreatedBySaga from "./business-developer/bd-createdBy/saga"
import bdProductCategorySaga from "./business-developer/bd-productCategory/saga"
import bdProductNumberSaga from "./business-developer/bd-productNumber/saga"

// dashboard
import dashboardSaga from "./dashboard/saga"

// profile
import profileSaga from "./auth/profile/saga"

//bd export and collection
import bdCollectionSaga from "./business-developer/bd-export/saga"

// co product
import coProductSaga from "./co-ordinator/co-products/saga"
import coProductCategorySaga from "./co-ordinator/co-productCategory/saga"
import coQualityRatingSaga from "./co-ordinator/co-qualityRating/saga"
import coConstructionSaga from "./co-ordinator/co-constructions/saga"

//co offer
import coOfferSaga from "./co-ordinator/co-offer/saga"
import coDeliveredOnSaga from "./co-ordinator/co-deliveredOn/saga"
import coEnvironmentalAndHealthCertificationSaga from "./co-ordinator/co-environmentalAndHealthCertification/saga"
import coHeaveyColliSaga from "./co-ordinator/co-heaveyColli/saga"
import coIncotermCitySaga from "./co-ordinator/co-incotermCity/saga"
import coMaterialStandardSaga from "./co-ordinator/co-materialStandard/saga"
import coOfferStatusSaga from "./co-ordinator/co-offerStatus/saga"
import coPackagingSaga from "./co-ordinator/co-packaging/saga"
import coPackingStandardSaga from "./co-ordinator/co-packingStandard/saga"
import coSupplierNameSaga from "./co-ordinator/co-suppliers/saga"
import coUserListAccordingRoleSaga from "./co-ordinator/co-userListAccordingRole/saga"
import coCreatedBySaga from "./co-ordinator/co-createdBy/saga"
import coProductNumberSaga from "./co-ordinator/co-productNumber/saga"

//co export
import coCollectionSaga from "./co-ordinator/co-export/saga"

//sa product
import saProductSaga from "./sales-manager/sa-product/saga"

//sa offer
import saOfferSaga from "./sales-manager/sa-offer/saga"

//sa export collection
import saCollectionSaga from "./sales-manager/sa-export/saga"

//qa product
import qaProductSaga from "./quality-assurance/qa-product/saga"

//qa offer
import qaofferSaga from "./quality-assurance/qa-offer/saga"
import qaUserListAccordingRoleSaga from "./quality-assurance/qa-userListAccordingRole/saga"

//qa export collection
import qaCollectionSaga from "./quality-assurance/qa-export/saga"

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(ForgetPasswordSaga),
    fork(UserSaga),
    fork(OfficeSaga),
    fork(RoleSaga),
    fork(LogSaga),
    fork(productCategorySaga),
    fork(productNumberSaga),
    fork(createdBySaga),
    fork(ProductSaga),
    fork(qualityRatingSaga),
    fork(supplierNameSaga),
    fork(offerStatusSaga),
    fork(packagingSaga),
    fork(packingStandardSaga),
    fork(userListAccordingRoleSaga),
    fork(deliveredOnSaga),
    fork(environmentalAndHealthCertificationSaga),
    fork(heaveyColliSaga),
    fork(incotermCitySaga),
    fork(materialStandardSaga),
    fork(offerSaga),
    fork(collectionSaga),
    fork(bdProductSaga),
    fork(bdOfferSaga),
    fork(dashboardSaga),
    fork(profileSaga),
    fork(bdCreatedBySaga),
    fork(bdProductNumberSaga),
    fork(bdDeliveredOnSaga),
    fork(bdEnvironmentalAndHealthCertificationSaga),
    fork(bdHeaveyColliSaga),
    fork(bdIncotermCitySaga),
    fork(bdMaterialStandardSaga),
    fork(bdPackagingSaga),
    fork(bdOfferStatusSaga),
    fork(bdProductCategorySaga),
    fork(bdPackingStandardSaga),
    fork(bdSupplierNameSaga),
    fork(bdUserListAccordingRoleSaga),
    fork(bdCollectionSaga),
    fork(coProductSaga),
    fork(coOfferSaga),
    fork(coDeliveredOnSaga),
    fork(coCreatedBySaga),
    fork(coEnvironmentalAndHealthCertificationSaga),
    fork(coHeaveyColliSaga),
    fork(coIncotermCitySaga),
    fork(coMaterialStandardSaga),
    fork(coOfferStatusSaga),
    fork(coProductNumberSaga),
    fork(coPackagingSaga),
    fork(coPackingStandardSaga),
    fork(coProductCategorySaga),
    fork(coQualityRatingSaga),
    fork(coSupplierNameSaga),
    fork(coUserListAccordingRoleSaga),
    fork(coCollectionSaga),
    fork(qaProductSaga),
    fork(qaofferSaga),
    fork(qaUserListAccordingRoleSaga),
    fork(qaCollectionSaga),
    fork(saProductSaga),
    fork(saOfferSaga),
    fork(saCollectionSaga),
    fork(constructionSaga),
    fork(coConstructionSaga),
  ])
}
