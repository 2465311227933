import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import React, { useState, useRef, useEffect, useMemo, useCallback } from "react"
import { Row, Col, Container, Button, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  fetchDeliveredListingBySection,
  changeDeliveredStatus,
  updateDeliversPosition,
} from "store/actions"
import ActiveInactive from "./ActiveInactive"
import BtnCellRenderer from "./BtnCellRenderer"

const ListDeliveredOn = props => {
  const dispatch = useDispatch()

  let key = `${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`
  LicenseManager.setLicenseKey(key)
  document.title = "Delivery On"

  const gridRef = useRef()

  const [rowData, setRowData] = useState([])
  const [rowPositions, setRowPositions] = useState([])

  const columnDefination = [
    {
      field: "action",
      cellRenderer: "BtnCellRenderer",
      maxWidth: 200,
      cellRendererSelector: params => {
        const buttons = {
          component: BtnCellRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Name",
      rowDrag: true,
      field: "delivery_name",
    },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: "ActiveInactive",
      cellRendererSelector: params => {
        const buttons = {
          component: ActiveInactive,
          params: {
            callChangedeliveryStatus: callChangedeliveryStatus,
          },
        }
        if (params) return buttons
        else return undefined
      },
    },
  ]

  const [columnDefs, setColumnDefs] = useState(columnDefination)
  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      filter: true,
      minWidth: 200,
      resizable: true,
    }),
    []
  )

  const onGridReady = useCallback(params => {
    // remove previous records
    setRowData([])
    // access the Grid API
    gridRef?.api?.deselectAll()

    gridRef?.current?.api?.setFilterModel(null)
    // access the Grid Column API
    gridRef?.columnApi?.resetColumnState()

    // refresh the grid
    gridRef?.api?.redrawRows()
  }, [])

  const { error, loading, msg, deliveres, positionUpdate } = useSelector(
    state => ({
      error: state.deliveredOn.error,
      loading: state.deliveredOn.loading,
      msg: state.deliveredOn.msg,
      deliveres: state.deliveredOn.deliveredOn,
      positionUpdate: state.deliveredOn.positionUpdate,
    })
  )

  useEffect(() => {
    let deliversData = { section: "delivery_name" }
    dispatch(fetchDeliveredListingBySection(deliversData))
  }, [])

  useEffect(() => {
    if (deliveres && deliveres.length > 0) {
      setRowData(deliveres)
      if (columnDefination && deliveres && deliveres.length > 0) {
        gridRef?.current?.api?.setColumnDefs(columnDefination)
      }
    }
  }, [deliveres])

  const onRowDragEnd = useCallback(e => {
    let allNodes = e.api.getModel().rowsToDisplay
    let updatedNodes = []
    if (Array.isArray(allNodes) && allNodes.length) {
      allNodes.forEach(function (arrayItem) {
        updatedNodes.push({
          id: arrayItem.data.id,
          position: arrayItem.rowIndex,
        })
      })
      setRowPositions(updatedNodes)
    }
  }, [])

  function callChangedeliveryStatus(id) {
    dispatch(changeDeliveredStatus(id))
  }

  function updatePositions() {
    if (rowPositions.length) {
      // api call
      dispatch(
        updateDeliversPosition({
          section: "deliver",
          nodes: rowPositions,
        })
      )
    }
  }

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (positionUpdate && msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
      setRowPositions([])
    }
  }, [msg, positionUpdate, error])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container fluid={true}>
          {msg || error ? <ToastContainer /> : ""}
          <Row>
            <Col>
              <h5>Delivered On</h5>
            </Col>
            <Col>
              <div className="d-flex justify-content-end">
                <Link
                  className="btn btn-primary mb-2"
                  to="/dropdown/delivered_on/add"
                >
                  Add
                </Link>
                {rowPositions.length ? (
                  <button
                    className="btn btn-secondary mb-2 mx-1"
                    onClick={updatePositions}
                  >
                    Update Positions
                  </button>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: "80vh" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  animateRows={true}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  rowDragManaged={true}
                  onRowDragEnd={onRowDragEnd}
                  enableCellChangeFlash={true}
                  suppressChangeDetection={true}
                  onGridReady={onGridReady}
                  onSortChanged={onRowDragEnd}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ListDeliveredOn
