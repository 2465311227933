import React, { useState, useRef, useMemo, useEffect, useCallback } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import ProductImageRenderer from "../../Products/sales-manager/sa-ProductImageRenderer"

import { useSelector, useDispatch } from "react-redux"
import { Prompt } from "react-router"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { saAddNewCollection } from "../../../store/actions"
import { saProductForAddCollection } from "../../../store/actions"
import { saProductOfferForAddCollection } from "../../../store/actions"
import { Link } from "react-router-dom"

const SaAddCollection = props => {
  document.title = "Add Collection"
  let key = `${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`
  LicenseManager.setLicenseKey(key)

  const gridRef = useRef()
  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      filter: true,
      minWidth: 200,
      resizable: true,
    }),
    []
  )

  const dispatch = useDispatch()

  const rowHeight = 150

  const [currentData, setCurrentData] = useState([])
  const collectionsData = useSelector(state => state.saCollection)

  const [checked, setChecked] = useState(false)

  const columnDefination = [
    {
      headerName: "Product No",
      field: "prod_no",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    {
      headerName: "Product Category",
      field: "prod_category",
    },
  ]

  const [columnDefs] = useState(columnDefination)

  const productColumnDefination = [
    {
      headerName: "Product No",
      field: "prod_no",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    // {
    //   headerName: "Product link",
    //   field: "prod_no",
    //   cellRenderer: params => {
    //     return (
    //       <Link to={`/products/edit/` + params.data.prod_id}>
    //         {params.data.prod_no}
    //       </Link>
    //     )
    //   },
    // },
    {
      headerName: "Product Name",
      field: "prod_name",
    },
    {
      headerName: "Product Category",
      field: "prod_category",
    },
    {
      headerName: "Material Desc",
      field: "material_desc",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    { headerName: "Length", field: "length", filter: "agSetColumnFilter" },
    { headerName: "Depth", field: "depth", filter: "agSetColumnFilter" },
    { headerName: "height", field: "height", filter: "agSetColumnFilter" },
    {
      headerName: "Created By",
      field: "created_by",
    },
    {
      headerName: "Created Date",
      field: "created_date",
    },
    {
      headerName: "Product Folder",
      field: "product_folder",
      maxWidth: 150,
      cellRenderer: params => {
        if (params.data.product_folder) {
          return (
            <a
              className="text-center cursor-pointer font_size_medium"
              target="_blank"
              rel="noreferrer"
              href={params.data.product_folder}
            >
              Click here
            </a>
          )
        } else {
          return undefined
        }
      },
    },
    {
      headerName: "Product Image 1",
      field: "img1",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 2",
      field: "img2",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 3",
      field: "img3",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 4",
      field: "img4",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 5",
      field: "img5",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
  ]
  const [productColumnDefs] = useState(productColumnDefination)

  const offerColumnDefination = [
    {
      headerName: "Product No",
      field: "product_no",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    {
      headerName: "Material Desc",
      field: "material_desc",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Product Category",
      field: "product_category",
    },
    {
      headerName: "Product Size",
      field: "length",

      cellRendererSelector: params => {
        let length = params.data.length.toString()
        let depth = params.data.depth.toString()
        let height = params.data.height.toString()
        let result =
          params.data.length +
          "x" +
          params.data.depth +
          "x" +
          params.data.height
        return result
      },
    },
    {
      headerName: "Product Folder",
      field: "prod_folder",
      maxWidth: 150,
      cellRenderer: params => {
        if (params.data.product_folder) {
          return (
            <a
              className="text-center cursor-pointer font_size_medium"
              target="_blank"
              rel="noreferrer"
              href={params.data.product_folder}
            >
              Click here
            </a>
          )
        } else {
          return undefined
        }
      },
    },
    {
      headerName: "Product Image 1",
      field: "img1",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 2",
      field: "img2",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 3",
      field: "img3",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 4",
      field: "img4",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 5",
      field: "img5",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Supplier Name",
      field: "supplier_name",
    },
    {
      headerName: "Production Time",
      field: "production_time",
    },
    {
      headerName: "Incoterm City(port)",
      field: "incoterm_city",
    },
    {
      headerName: "Offer Status",
      field: "offer_status",
    },
    {
      headerName: "Created By",
      field: "created_by",
    },
    {
      headerName: "Created",
      field: "created_date",
    },
    {
      headerName: "Product size",
      field: "product_size",
    },
    {
      headerName: "Vendor Article No",
      field: "vendor_article_no",
    },
    {
      headerName: "Material Standard",
      field: "material_standard",
    },
    {
      headerName: "Packing Standard",
      field: "packing_standard",
    },
    {
      headerName: "Environmental & health certification",
      field: "env_health",
    },
    {
      headerName: "Non-Compliance",
      field: "non_compliance",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "SBN Comments",
      field: "sbn_comments",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Color code & gloss",
      field: "color_code_gloss",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Product and Material Details",
      field: "quality_control",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Purchase Price(FSC)",
      field: "purchase_price_fsc",
    },
    {
      headerName: "Sales Price(FSC)",
      field: "sales_price_fsc",
    },
    {
      headerName: "Sales Margin(FSC)",
      field: "sales_margin_fsc",
    },
    {
      headerName: "Purchasing price comments",
      field: "purchasing_price_comments",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Number of units per box",
      field: "no_units_per_box",
    },
    {
      headerName: "Packaging",
      field: "packaging",
    },
    {
      headerName: "Net Weight",
      field: "net_weight",
    },
    {
      headerName: "Gross Weight",
      field: "gross_weight",
    },
    {
      headerName: "Heavy Colli",
      field: "heavy_colli",
    },
    {
      headerName: "BOM per article",
      field: "bom_per_article",
    },
    {
      headerName: "Master cardboard box",
      field: "master_cardboard_box",
    },
    {
      headerName: "Delivered On",
      field: "delivered_on",
    },
    {
      headerName: "Full truck/40hc",
      field: "full_truck",
    },
    {
      headerName: "20st Container",
      field: "container",
    },
    {
      headerName: "MOQ",
      field: "moq",
    },
    {
      headerName: "Packing Comments",
      field: "packing_comments",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Comments",
      field: "comments",
    },
    {
      headerName: "Article Description",
      field: "article_desc",
    },
    {
      headerName: "Special Supplier Email",
      field: "special_supplier_email",
    },
    {
      headerName: "Return Aggrement",
      field: "return_agreement",
    },
    {
      headerName: "Currency",
      field: "currency",
    },
    {
      headerName: "Incoterm",
      field: "incoterm",
    },
    {
      headerName: "JYSK Supplier No.",
      field: "jysk_supplier_no",
      cellRenderer: function (params) {
        return "28608"
      },
    },
    {
      headerName: "Vendor Article Name",
      field: "vendor_article_name",
    },
    {
      headerName: "Sample QA",
      field: "sample_qa",
    },
    {
      headerName: "Sample Request",
      field: "sample_request",
    },
    {
      headerName: "Sample Comments",
      field: "sample_comments",
    },
    {
      headerName: "Sample Status",
      field: "sample_status",
    },
    {
      headerName: "Sample QA Checking Comments",
      field: "sample_qa_checking_comments",
    },
    {
      headerName: "Sample Sales Checking Comments",
      field: "sample_sales_checking_comments",
    },
    {
      headerName: "Sample Main Materials",
      field: "sample_main_materials",
    },
    {
      headerName: "Sample Hardware",
      field: "sample_hardware",
    },
    {
      headerName: "Sample Surface Treatment",
      field: "sample_surface_treatment",
    },
    {
      headerName: "Sample Air Freight Cost",
      field: "sample_freight_cost",
    },
  ]
  const [offerColumnDefs] = useState(offerColumnDefination)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      collection_name: "",
      collection_type: "",
    },
    validationSchema: Yup.object({
      collection_name: Yup.string().required("Please enter collection name"),
      collection_type: Yup.string().required("Please enter collection type"),
    }),
    onSubmit: values => {
      if (values.collection_type === "offer") {
        dispatch(saProductOfferForAddCollection({ active: 1 }))
      } else {
        dispatch(saProductForAddCollection({ active: 1 }))
      }
    },
  })

  const onGridReady = useCallback(params => {
    // remove previous records
    setCurrentData([])
    // access the Grid API
    gridRef?.api?.deselectAll()

    gridRef?.current?.api?.setFilterModel(null)
    // access the Grid Column API
    gridRef?.columnApi?.resetColumnState()

    // refresh the grid
    gridRef?.api?.redrawRows()
  }, [])

  let productForAddCollectionList = useSelector(
    state => state.saCollection.productsForAddCollection
  )

  let productOfferForAddCollectionList = useSelector(
    state => state.saCollection.productsOfferForAddCollection
  )

  useEffect(() => {
    if (
      gridRef.current &&
      productForAddCollectionList &&
      productForAddCollectionList.length > 0
    ) {
      gridRef.current?.api?.setColumnDefs(productColumnDefination)
      setCurrentData(productForAddCollectionList)
    }
  }, [productForAddCollectionList, gridRef.current])

  useEffect(() => {
    if (
      gridRef.current &&
      productOfferForAddCollectionList &&
      productOfferForAddCollectionList.length > 0
    ) {
      setCurrentData(productOfferForAddCollectionList)
      gridRef.current?.api?.setColumnDefs(offerColumnDefination)
    }
  }, [productOfferForAddCollectionList, gridRef.current])

  function saveCollection() {
    let meta_data = []
    if (validation.values.collection_type == "product") {
      meta_data = gridRef.current.api.getSelectedRows().map(function (value) {
        return value.prod_id
      })
    } else {
      meta_data = gridRef.current.api.getSelectedRows().map(function (value) {
        return value.offer_id
      })
    }
    if (meta_data.length > 0) {
      setChecked(false)
      validation.setSubmitting(true)
      meta_data = "[" + meta_data.join(",") + "]"
      validation.values.meta_data = meta_data
      dispatch(saAddNewCollection(validation.values, props.history))
    } else {
      validation.setSubmitting(false)
    }
  }

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows()
    if (selectedRows.length > 0) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [])

  useEffect(() => {
    if (validation.values) {
      const isEmpty = Object.values(validation.values).every(
        x => x === null || x === ""
      )
      if (isEmpty) {
        setChecked(false)
      } else {
        setChecked(true)
      }
    }
    let meta_data = []
    if (validation.values.collection_type == "product" && gridRef.current) {
      meta_data = gridRef.current.api.getSelectedRows().map(function (value) {
        return value.prod_id
      })
    } else if (
      validation.values.collection_type == "offer" &&
      gridRef.current
    ) {
      meta_data = gridRef.current.api.getSelectedRows().map(function (value) {
        return value.offer_id
      })
    }

    if (meta_data.length > 0) {
      setChecked(true)
    }
  }, [validation.values, gridRef])

  const { error, loading, msg } = useSelector(state => ({
    error: state.saCollection.error,
    loading: state.saCollection.loading,
    // msg: state.saCollection.msg,
  }))

  const showAgGridLoading = useCallback(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.showLoadingOverlay()
    }
  }, [])

  const hideAgGridLoading = useCallback(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.hideOverlay()
    }
  }, [])
  if (loading) {
    showAgGridLoading()
  } else {
    hideAgGridLoading()
  }

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error])

  return (
    <React.Fragment>
      <div className="page-content">
        <Prompt
          when={checked}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Add Collection" />
          <Row>
            <Col lg={12}>
              <Card className="p-2">
                {error ? <ToastContainer /> : ""}
                <Form
                  method="post"
                  id="collectionForm"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row>
                    <Col md={5}>
                      <div className="mb-1">
                        <Label htmlFor="collectionName">
                          Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="collection_name"
                          className={
                            validation.touched.collection_name &&
                            validation.errors.collection_name
                              ? "is-invalid"
                              : ""
                          }
                          id="collectionName"
                          placeholder="Enter your collection name"
                          autoComplete="off"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.collection_name || ""}
                        />
                        {validation.touched.collection_name &&
                        validation.errors.collection_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.collection_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className="mb-3">
                        <Label htmlFor="collectionType">
                          Type <span className="text-danger">*</span>
                        </Label>
                        <select
                          name="collection_type"
                          id="collectionType"
                          className={
                            validation.touched.collection_type &&
                            validation.errors.collection_type
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.collection_type || ""}
                        >
                          <option value="">Choose...</option>
                          <option value="product">Product</option>
                          <option value="offer">Offer</option>
                        </select>
                        {validation.touched.collection_type &&
                        validation.errors.collection_type ? (
                          <FormFeedback type="invalid">
                            {validation.errors.collection_type}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={2} className="mt-2">
                      <div className="mb-2">
                        <button
                          type="submit"
                          className="btn btn-primary w-md mt-3"
                        >
                          Search
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
          <Row>
            <Row>
              <Col md={6}></Col>
              <Col md={6} className="text-end py-2">
                {currentData && currentData.length > 0 ? (
                  collectionsData.loading ? (
                    <Button color="primary" size="sm" disabled>
                      Saving...
                    </Button>
                  ) : (
                    <Button color="primary" size="sm" onClick={saveCollection}>
                      Save
                    </Button>
                  )
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Col md={12} lg={12} xl={12}>
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: "80vh" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={currentData}
                  rowHeight={rowHeight}
                  columnDefs={columnDefs}
                  productColumnDefs={productColumnDefs}
                  offerColumnDefs={offerColumnDefs}
                  defaultColDef={defaultColDef}
                  rowSelection={"multiple"}
                  suppressRowClickSelection={true}
                  onGridReady={onGridReady}
                  onSelectionChanged={onSelectionChanged}
                />
              </div>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default SaAddCollection
