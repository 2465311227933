import {
  BD_GET_MATERIALSTANDARD_LISTING,
  BD_GET_MATERIALSTANDARD_LISTING_SUCCESS,
  BD_GET_MATERIALSTANDARD_LISTING_FAILED,
  } from "./actionTypes"
  
  export const bdMaterialStandardListing = () => {
    return {
      type: BD_GET_MATERIALSTANDARD_LISTING,
    }
  }
  
  export const bdMaterialStandardListingSuccess = bdMaterialStandard => {
    return {
      type: BD_GET_MATERIALSTANDARD_LISTING_SUCCESS,
      payload: bdMaterialStandard,
    }
  }
  
  export const bdMaterialStandardListingFail = error => {
    return {
      type: BD_GET_MATERIALSTANDARD_LISTING_FAILED,
      payload: error,
    }
  }
  
