import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  CO_GET_CONSTRUCTON_LISTING,
} from "./actionTypes"
import {
  coConstructionListingSuccess,
  coConstructionListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  coConstructionListingApi,
} from "../../../helpers/fakebackend_helper"

function* coGetConstructionListing() {
  try {
    const response = yield call(coConstructionListingApi)
    if (!response.results.construction) {
      yield put(coConstructionListingFail(error))
    }
    yield put(coConstructionListingSuccess(response.results.construction))
  } catch (error) {
    yield put(coConstructionListingFail(error))
  }
}

function* coConstructionSaga() {
  yield takeEvery(CO_GET_CONSTRUCTON_LISTING, coGetConstructionListing)
}
export default coConstructionSaga
