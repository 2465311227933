import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { useHistory } from "react-router-dom"

import {
  BD_OFFER_ADD,
  BD_GET_OFFERS_LISTING,
  BD_OFFER_EDIT,
  BD_OFFER_UPDATE,
  BD_CHANGE_OFFER_STATUS,
  BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
  BD_RESET_OFFER,
} from "./actionTypes"

import {
  bdOfferSuccess,
  bdOfferFail,
  bdOffersListingSuccess,
  bdOffersListingFail,
  bdEditOfferSuccess,
  bdEditOfferFail,
  bdUpdateOfferSuccess,
  bdUpdateOfferFail,
  bdChangeOfferStatusFail,
  bdChangeOfferStatusSuccess,
  bdSearchItemInNewOfferPageSuccess,
  bdSearchItemInNewOfferPageFail,
  bdClearOfferResultSuccess,
  bdClearOfferResultError
} from "./actions"

import {
  bdAddOfferApi,
  bdOffersListingApi,
  bdEditOfferApi,
  bdUpdateOfferApi,
  bdChangeOfferStatusApi,
  bdSearchItemInNewOfferPageApi
} from "../../../helpers/fakebackend_helper"

function* bdAddNewOffer({ payload: { offer, history } }) {
  try {
    const response = yield call(bdAddOfferApi, offer)

    if (response && response.status) {
      yield put(bdOfferSuccess(response))
      setTimeout(() => {
        history.push("/offers/list")
      }, 5000)
    } else {
      throw response.msg ? response.msg : "Failed to add offer"
    }
  } catch (error) {
    yield put(bdOfferFail(error))
  }
}

function* getBdOfferListing() {
  try {
    const response = yield call(bdOffersListingApi)
    if (
      response.results === undefined ||
      response.results.prod_offers.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(bdOffersListingSuccess(response.results.prod_offers))
  } catch (error) {
    yield put(bdOffersListingFail(error))
  }
}

function* getBdOfferDetailsById({ payload: { id } }) {
  try {
    const response = yield call(bdEditOfferApi, id)
    if (response && response.status) {
      response.id = id
      yield put(bdEditOfferSuccess(response.results.offer_details))
    } else {
      throw response.msg ? response.msg : "Failed to fetch offer details"
    }
  } catch (error) {
    yield put(bdEditOfferFail(error))
  }
}

function* onBdUpdateOffer({ payload: { offer, history } }) {
  try {
    const response = yield call(bdUpdateOfferApi, offer)
    if (response && response.status) {
      yield put(bdUpdateOfferSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update offer"
    }
  } catch (error) {
    yield put(bdUpdateOfferFail(error))
  }
}

function* onOfferStatusChange({ payload: { id } }) {
  try {
    const response = yield call(bdChangeOfferStatusApi, { offer_id: id })
    if (response.status) {
      response.id = id
      yield put(bdChangeOfferStatusSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to delete offer"
    }
  } catch (error) {
    yield put(bdChangeOfferStatusFail(error))
  }
}

function* bdSearchAtNewOfferPage({ payload: { search } }) {
  try {
    const response = yield call(bdSearchItemInNewOfferPageApi, search)

    if (response && response.status && response?.results?.prod_offers) {
      yield put(bdSearchItemInNewOfferPageSuccess(response.results.prod_offers))
    } else {
      throw response.msg ? response.msg : "No Record found"
    }
  } catch (error) {
    yield put(bdSearchItemInNewOfferPageFail(error))
  }
}

function* bdClearOfferResults() {
  try {
    yield put(bdClearOfferResultSuccess())
  } catch (error) {
    yield put(bdClearOfferResultError(error))
  }
}

function* bdOfferSaga() {
  yield takeEvery(BD_OFFER_ADD, bdAddNewOffer)
  yield takeEvery(BD_GET_OFFERS_LISTING, getBdOfferListing)
  yield takeEvery(BD_OFFER_EDIT, getBdOfferDetailsById)
  yield takeEvery(BD_OFFER_UPDATE, onBdUpdateOffer)
  yield takeEvery(BD_CHANGE_OFFER_STATUS, onOfferStatusChange)
  yield takeEvery(BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE, bdSearchAtNewOfferPage)
  yield takeEvery(BD_RESET_OFFER, bdClearOfferResults)
}
export default bdOfferSaga
