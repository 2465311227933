import React, { useState, useRef, useMemo, useEffect, useCallback } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"

import { Prompt } from "react-router"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import ProductImageRenderer from "../../Products/business-developer/bd-ProductImageRenderer"
import {
  bdCollectionEdit,
  bdGetAddMoreToCollectionUpdate,
  bdGetAddMoreToCollection,
} from "../../../store/actions"
import { bdProductForAddCollection } from "../../../store/actions"
import { bdProductOfferForAddCollection } from "../../../store/actions"

const BdAddMoreDataToCollection = props => {
  document.title = "Add more to collection"
  const { id } = useParams()
  let key = `${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`
  LicenseManager.setLicenseKey(key)

  const gridRef = useRef()
  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      filter: true,
      minWidth: 200,
      resizable: true,
    }),
    []
  )
  const rowHeight = 150

  const dispatch = useDispatch()
  const [checked, setChecked] = useState(false)
  const collectionsData = useSelector(state => state.bdCollection.collection)
  const addMoreToCollection = useSelector(
    state => state.bdCollection.collectionAddMore
  )
  function saveCollection() {
    if (!validation.isValid) {
      return false
    }
    let meta_data = []
    if (validation.values.collection_type == "product") {
      meta_data = gridRef.current.api.getSelectedRows().map(function (value) {
        return value.prod_id
      })
    } else {
      meta_data = gridRef.current.api.getSelectedRows().map(function (value) {
        return value.offer_id
      })
    }
    if (meta_data.length > 0) {
      setChecked(false)
      validation.setSubmitting(true)
      meta_data = "[" + meta_data.join(",") + "]"
      validation.values.meta_data = meta_data
      validation.values.collection_id = id
      dispatch(bdGetAddMoreToCollectionUpdate(validation.values, props.history))
    } else {
      error = { error: "No option is selected" }
    }
  }

  const [currentCollection, setCurrentCollection] = useState([])
  const [currentData, setCurrentData] = useState([])
  let current_collection = []
  useEffect(() => {
    dispatch(bdCollectionEdit(id))
    dispatch(bdGetAddMoreToCollection(id))

    if (collectionsData && collectionsData.length > 0) {
      current_collection = collectionsData.filter(
        collection => collection.id == id
      )
    }
    if (current_collection.length > 0) {
      setCurrentCollection(current_collection[0])
    } else {
      props.history.push("/export/collection")
    }
  }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: id,
      collection_name:
        currentCollection && currentCollection.collection_name
          ? currentCollection.collection_name
          : "",
      collection_type:
        currentCollection && currentCollection.collection_type
          ? currentCollection.collection_type
          : "",
    },
    validationSchema: Yup.object({
      collection_name: Yup.string().required("Please enter collection name"),
      collection_type: Yup.string().required("Please enter collection type"),
    }),
    onSubmit: values => {
      if (values.collection_type === "offer") {
        dispatch(bdProductOfferForAddCollection({ active: 1 }))
      } else {
        dispatch(bdProductForAddCollection({ active: 1 }))
      }
    },
  })

  const columnDefination = [
    {
      headerName: "Product No",
      field: "prod_no",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    {
      headerName: "Product Category",
      field: "prod_category",
    },
  ]
  const [columnDefs, setColumnDefs] = useState(columnDefination)
  const productColumnDefination = [
    {
      headerName: "Product No",
      field: "prod_no",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    {
      headerName: "Product Name",
      field: "prod_name",
    },
    {
      headerName: "Product Category",
      field: "prod_category",
    },
    {
      headerName: "Material Desc",
      field: "material_desc",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Construction",
      field: "construction",
    },
    { headerName: "Length", field: "length", filter: "agSetColumnFilter" },
    { headerName: "Depth", field: "depth", filter: "agSetColumnFilter" },
    { headerName: "height", field: "height", filter: "agSetColumnFilter" },
    {
      headerName: "Created By",
      field: "created_by",
    },
    {
      headerName: "Created Date",
      field: "created_date",
    },
    {
      headerName: "Product Folder",
      field: "product_folder",
      maxWidth: 150,
      cellRenderer: params => {
        if (params.data.product_folder) {
          return (
            <a
              className="text-center cursor-pointer font_size_medium"
              target="_blank"
              rel="noreferrer"
              href={params.data.product_folder}
            >
              Click here
            </a>
          )
        } else {
          return undefined
        }
      },
    },
    {
      headerName: "Product Image 1",
      field: "img1",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 2",
      field: "img2",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 3",
      field: "img3",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 4",
      field: "img4",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 5",
      field: "img5",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
  ]
  const [productColumnDefs] = useState(productColumnDefination)

  const offerColumnDefination = [
    {
      headerName: "Product No",
      field: "prod_no",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    {
      headerName: "Material Desc",
      field: "material_desc",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Product Category",
      field: "prod_category",
    },
    {
      headerName: "Product Folder",
      field: "prod_folder",
      maxWidth: 150,
      cellRenderer: params => {
        if (params.data.prod_folder) {
          return (
            <a
              className="text-center cursor-pointer font_size_medium"
              target="_blank"
              rel="noreferrer"
              href={params.data.prod_folder}
            >
              Click here
            </a>
          )
        } else {
          return undefined
        }
      },
    },
    {
      headerName: "Product Image 1",
      field: "img1",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
          // params: { values: "1" },
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 2",
      field: "img2",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
          // params: { values: "2" },
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 3",
      field: "img3",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
          // params: { values: "3" },
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 4",
      field: "img4",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
          // params: { values: "4" },
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 5",
      field: "img5",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
          params: { values: "5" },
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Supplier Name",
      field: "supplier_name",
    },
    {
      headerName: "Production Time",
      field: "production_time",
    },
    {
      headerName: "Incoterm City(port)",
      field: "incoterm_city",
    },
    {
      headerName: "Offer Status",
      field: "offer_status",
    },
    {
      headerName: "Created By",
      field: "created_by",
    },
    {
      headerName: "Created",
      field: "created_date",
    },
    {
      headerName: "Product size",
      field: "product_size",
    },
    {
      headerName: "Vendor Article No",
      field: "vendor_article_no",
    },
    {
      headerName: "Material Standard",
      field: "material_standard",
    },
    {
      headerName: "Packing Standard",
      field: "packing_standard",
    },
    {
      headerName: "Environmental & health certification",
      field: "env_health",
    },
    {
      headerName: "Non-Compliance",
      field: "non_compliance",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "SBN Comments",
      field: "sbn_comments",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Color code & gloss",
      field: "color_code_gloss",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Product and Material Details",
      field: "quality_control",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Purchase Price(FSC)",
      field: "purchase_price_fsc",
    },
    {
      headerName: "Purchasing price comments",
      field: "purchasing_price_comments",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Number of units per box",
      field: "no_units_per_box",
    },
    {
      headerName: "Packaging",
      field: "packaging",
    },
    {
      headerName: "Net Weight",
      field: "net_weight",
    },
    {
      headerName: "Gross Weight",
      field: "gross_weight",
    },
    {
      headerName: "Heavy Colli",
      field: "heavy_colli",
    },
    {
      headerName: "BOM per article",
      field: "bom_per_article",
    },
    {
      headerName: "Master cardboard box",
      field: "master_cardboard_box",
    },
    {
      headerName: "Delivered On",
      field: "delivered_on",
    },
    {
      headerName: "Full truck/40hc",
      field: "full_truck",
    },
    {
      headerName: "20st Container",
      field: "container",
    },
    {
      headerName: "MOQ",
      field: "moq",
    },
    {
      headerName: "Packing Comments",
      field: "packing_comments",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Comments",
      field: "comments",
    },
    {
      headerName: "Article Description",
      field: "article_desc",
    },
    {
      headerName: "Special Supplier Email",
      field: "special_supplier_email",
    },
    {
      headerName: "Return Aggrement",
      field: "return_agreement",
    },
    {
      headerName: "Currency",
      field: "currency",
    },
    {
      headerName: "Incoterm",
      field: "incoterm",
    },
    {
      headerName: "JYSK Supplier No.",
      field: "jysk_supplier_no",
      cellRenderer: function (params) {
        return "28608"
      },
    },
    {
      headerName: "Vendor Article Name",
      field: "vendor_article_name",
    },
    {
      headerName: "Sample QA",
      field: "sample_qa",
    },
    {
      headerName: "Sample Request",
      field: "sample_request",
    },
    {
      headerName: "Sample Comments",
      field: "sample_comments",
    },
    {
      headerName: "Sample Status",
      field: "sample_status",
    },
    {
      headerName: "Sample QA Checking Comments",
      field: "sample_qa_checking_comments",
    },
    {
      headerName: "Sample Sales Checking Comments",
      field: "sample_sales_checking_comments",
    },
    {
      headerName: "Sample Main Materials",
      field: "sample_main_materials",
    },
    {
      headerName: "Sample Hardware",
      field: "sample_hardware",
    },
    {
      headerName: "Sample Surface Treatment",
      field: "sample_surface_treatment",
    },
    {
      headerName: "Sample Air Freight Cost",
      field: "sample_freight_cost",
    },
  ]
  const [offerColumnDefs] = useState(offerColumnDefination)

  useEffect(() => {
    if (
      currentCollection !== undefined &&
      currentCollection.collection_type == "product"
    ) {
      setColumnDefs(productColumnDefination)
      if (columnDefination && currentData && currentData.length > 0) {
        gridRef.current.api.setColumnDefs(productColumnDefination)
      }
    } else if (
      currentCollection !== undefined &&
      currentCollection.collection_type == "offer"
    ) {
      setColumnDefs(offerColumnDefination)
      if (columnDefination && currentData && currentData.length > 0) {
        gridRef.current.api.setColumnDefs(offerColumnDefination)
      }
    }

    if (
      addMoreToCollection &&
      Array.isArray(addMoreToCollection) &&
      addMoreToCollection.length > 0
    ) {
      setCurrentData(addMoreToCollection)
    }
  }, [addMoreToCollection, currentCollection])

  const onFirstDataRendered = useCallback(params => {
    gridRef.current.api.forEachNode(node => {
      if (current_collection[0]["meta_data"]) {
        let currentNode = current_collection[0]
        for (let index = 0; index < currentNode["meta_data"].length; index++) {
          if (currentNode.collection_type == "offer") {
            node.setSelected(
              node.data.offer_id &&
                currentNode["meta_data"][index] == node.data.offer_id
            )
          } else {
            node.setSelected(
              node.data.prod_id &&
                currentNode["meta_data"][index] == node.data.prod_id
            )
          }
        }
      }
    })
  }, [])

  useEffect(() => {
    const isEmpty = validation.dirty
    if (isEmpty && validation.values) {
      const isEmpty = Object.values(validation.values).every(
        x => x === null || x === ""
      )
      if (isEmpty) {
        setChecked(false)
      } else {
        setChecked(true)
      }
    }
  }, [validation.values])

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows()
    if (selectedRows.length > 0) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [])

  const { error, loading, msg } = useSelector(state => ({
    error: state.bdCollection.error,
    loading: state.bdCollection.loading,
    msg: state.bdCollection.msg,
  }))

  const showAgGridLoading = useCallback(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.showLoadingOverlay()
    }
  }, [])

  const hideAgGridLoading = useCallback(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.hideOverlay()
    }
  }, [])
  if (loading) {
    showAgGridLoading()
  } else {
    hideAgGridLoading()
  }

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  return (
    <React.Fragment>
      <div className="page-content">
        <Prompt
          when={checked}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Add more to collection" />
          <Row className="gy-0">
            <Form
              id="addMoreDataToCollection"
              onSubmit={e => {
                e.preventDefault()
                if (validation.isValid) {
                  saveCollection()
                }
                return false
              }}
            >
              <Col lg={12}>
                <Card className="p-1">
                  {error ? <ToastContainer /> : ""}
                  {msg ? <ToastContainer /> : ""}
                  <Row>
                    <Col md={6}>
                      <div className="mb-1">
                        <Label htmlFor="collectionName">
                          Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="collection_name"
                          className={
                            validation.touched.collection_name &&
                            validation.errors.collection_name
                              ? "is-invalid"
                              : ""
                          }
                          id="collectionName"
                          readOnly
                          placeholder="Enter your collection name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.collection_name || ""}
                        />
                        {validation.touched.collection_name &&
                        validation.errors.collection_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.collection_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-1">
                        <Label htmlFor="collectionType">
                          Type <span className="text-danger">*</span>
                        </Label>
                        <Input
                          readOnly
                          type="text"
                          name="collection_type"
                          className={
                            validation.touched.collection_type &&
                            validation.errors.collection_type
                              ? "is-invalid"
                              : ""
                          }
                          id="collectionType"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.collection_type || ""}
                        />
                        {validation.touched.collection_type &&
                        validation.errors.collection_type ? (
                          <FormFeedback type="invalid">
                            {validation.errors.collection_type}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Form>
          </Row>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Row className="py-1">
                <Col md={6}></Col>
                <Col md={6} className="text-end">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn btn-primary w-md"
                    size="sm"
                    onClick={saveCollection}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: "80vh" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={currentData}
                  rowHeight={rowHeight}
                  animateRows={true}
                  enableRangeSelection={true}
                  enableCharts={true}
                  columnDefs={columnDefs}
                  productColumnDefs={productColumnDefs}
                  offerColumnDefs={offerColumnDefs}
                  defaultColDef={defaultColDef}
                  rowSelection={"multiple"}
                  onFirstDataRendered={onFirstDataRendered}
                  onSelectionChanged={onSelectionChanged}
                />
              </div>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
      {/* {Prompt} */}
    </React.Fragment>
  )
}

export default BdAddMoreDataToCollection
