import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { BD_GET_PACKAGING_LISTING } from "./actionTypes"
import {
  bdPackagingListingSuccess,
  bdPackagingListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { bdPackagingListingApi } from "../../../helpers/fakebackend_helper"

function* getBdPackagingListing() {
  try {
    const response = yield call(bdPackagingListingApi)
    if (!response.results.packaging) {
      yield put(bdPackagingListingFail(error))
    }
    yield put(bdPackagingListingSuccess(response.results.packaging))
  } catch (error) {
    yield put(bdPackagingListingFail(error))
  }
}

function* bdPackagingSaga() {
  yield takeEvery(BD_GET_PACKAGING_LISTING, getBdPackagingListing)
}
export default bdPackagingSaga
