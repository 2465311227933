import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./offers.css"

function BtnCellRenderer(props) {
  let userType = props.userType
  let allowedUserTypes = ["AM", "SA", "CO", "BD", "QA"]
  return (
    <>
      <div className="row">
        {/* {props &&
        props?.data &&
        (props?.data?.is_owner || allowedUserTypes.includes(userType)) ? (
          <div className="col">
            <Link
              to={"/offers/edit"}
              className="text-decoration-none"
            >
              <i
                className="bx bx-edit cursor-pointer font_size_large"
                title="Edit Offer"
              ></i>
            </Link>
          </div>
        ) : null} */}
        <div className="col">
          <i
            className="bx bx-folder-minus cursor-pointer font_size_large text-danger"
            onClick={() => props.deleteOfferConfirmation(props.data.offer_id)}
            title="Delete offer"
          ></i>
        </div>
      </div>
    </>
  )
}

export default BtnCellRenderer
