import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { CO_GET_QUALITYRATING_LISTING } from "./actionTypes"
import {
  coQualityRatingListingSuccess,
  coQualityRatingListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { coQualityRatingListingApi } from "../../../helpers/fakebackend_helper"

function* coGetQualityRatingListing() {
  try {
    const response = yield call(coQualityRatingListingApi)
    if (!response.results.ratings) {
      yield put(coQualityRatingListingFail(error))
    }
    yield put(coQualityRatingListingSuccess(response.results.ratings))
  } catch (error) {
    yield put(coQualityRatingListingFail(error))
  }
}

function* coQualityRatingSaga() {
  yield takeEvery(CO_GET_QUALITYRATING_LISTING, coGetQualityRatingListing)
}
export default coQualityRatingSaga
