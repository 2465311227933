import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { useHistory } from "react-router-dom"

import {
  BD_COLLECTION_ADD,
  BD_GET_COLLECTION_LISTING,
  BD_COLLECTION_EDIT,
  BD_COLLECTION_UPDATE,
  BD_COLLECTION_DELETE,
  BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
  BD_COLLECTION_COPY,
  BD_EXPORTDOWNLOAD_PROGRESS,
  BD_ADD_MORE_TO_COLLECTION,
  BD_ADD_MORE_TO_COLLECTION_UPDATE,
  BD_GET_PRODUCTS_FOR_ADD_COLLECTION,
  BD_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
  BD_REFRESH_COLLECTION_COUNT,
  BD_GET_LAYOUT,
} from "./actionTypes"

import {
  bdCollectionSuccess,
  bdCollectionFail,
  bdCollectionsListingSuccess,
  bdCollectionsListingFail,
  bdEditCollectionSuccess,
  bdEditCollectionFail,
  bdUpdateCollectionSuccess,
  bdUpdateCollectionFail,
  bdDeleteCollectionSuccess,
  bdDeleteCollectionFail,
  bdExportCollectionCatalogSuccess,
  bdExportCollectionCatalogFail,
  bdCopyCollectionSuccess,
  bdCopyCollectionFail,
  bdExportDownloadProgressSuccess,
  bdExportDownloadProgressFail,
  bdGetAddMoreToCollectionSuccess,
  bdGetAddMoreToCollectionFail,
  bdGetAddMoreToCollectionUpdateSuccess,
  bdGetAddMoreToCollectionUpdateFail,
  bdProductForAddCollectionSuccess,
  bdProductForAddCollectionFail,
  bdProductOfferForAddCollectionSuccess,
  bdProductOfferForAddCollectionFail,
  bdRefreshCollectionCountSuccess,
  bdRefreshCollectionCountFail,
  bdGetLayOutSuccess,
  bdGetLayOutFail,
} from "./actions"

import {
  addCollectionApi,
  bdCollectionsListingApi,
  bdEditCollectionApi,
  bdUpdateCollectionApi,
  bdDeleteCollectionApi,
  bdExportCollectionCatalogApi,
  bdCopyCollectionApi,
  bdExportDownloadProgressApi,
  getBdAddMoreToCollectionApi,
  bdAddMoreDataToCollectionUpdateApi,
  bdGetProductForAddCollectionApi,
  bdGetProductsOfferForAddCollectionApi,
  bdRefreshCollectionCountApi,
  bdGetLayoutApi,
} from "../../../helpers/fakebackend_helper"

function* bdAddNewCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(addCollectionApi, collection)

    if (response && response.status) {
      yield put(bdCollectionSuccess(response))
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to add collection"
    }
  } catch (error) {
    yield put(bdCollectionFail(error))
  }
}

function* getBdCollectionListing() {
  try {
    const response = yield call(bdCollectionsListingApi)
    if (
      response.results === undefined ||
      response.results.collections.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(bdCollectionsListingSuccess(response.results.collections))
  } catch (error) {
    yield put(bdCollectionsListingFail(error))
  }
}

function* getBdCollectionDetailsById({ payload: { id } }) {
  try {
    const response = yield call(bdEditCollectionApi, id)
    if (response && response.status) {
      response.id = id
      yield put(bdEditCollectionSuccess(response.results.collection))
    } else {
      throw response.msg ? response.msg : "Failed to fetch collection details"
    }
  } catch (error) {
    yield put(bdEditCollectionFail(error))
  }
}

function* bdOnUpdateCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(bdUpdateCollectionApi, collection)
    if (response && response.status) {
      yield put(bdUpdateCollectionSuccess(response))
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to update collection"
    }
  } catch (error) {
    yield put(bdUpdateCollectionFail(error))
  }
}

function* bdOnDeleteCollection({ payload: { id } }) {
  try {
    const response = yield call(bdDeleteCollectionApi, { collection_id: id })
    if (response && response.status) {
      response.id = id
      yield put(bdDeleteCollectionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to delete collection"
    }
  } catch (error) {
    yield put(bdDeleteCollectionFail(error))
  }
}

function* bdOnExportCollectionCatalog({ payload: { collection, history } }) {
  try {
    const response = yield call(bdExportCollectionCatalogApi, collection)
    if (response && response.status) {
      yield put(bdExportCollectionCatalogSuccess(response))
    } else {
      throw response.msg ? response.msg : "Download in progress..."
    }
  } catch (error) {
    yield put(bdExportCollectionCatalogFail(error))
  }
}

function* bdOnCopyCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(bdCopyCollectionApi, collection)

    if (response && response.status) {
      yield put(
        bdCopyCollectionSuccess(response.record.original?.results?.collection)
      )
    } else {
      throw response.msg ? response.msg : "Failed to copy collection"
    }
  } catch (error) {
    yield put(bdCopyCollectionFail(error))
  }
}

function* onBdExportDownloadProgress({
  payload: { exportDownloadProgress, history },
}) {
  try {
    const response = yield call(
      bdExportDownloadProgressApi,
      exportDownloadProgress
    )
    if (response && response.status) {
      yield put(bdExportDownloadProgressSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to download file"
    }
  } catch (error) {
    yield put(bdExportDownloadProgressFail(error))
  }
}

function* bdOnFetchDataToAddMoreToCollection({ payload: { collection_id } }) {
  try {
    const response = yield call(getBdAddMoreToCollectionApi, {
      collection_id: collection_id,
    })
    if (response && response.status && response?.results?.product_dtls) {
      yield put(
        bdGetAddMoreToCollectionSuccess(response?.results?.product_dtls)
      )
      // history.push("/export/collection")
    } else if (response && response.status && response?.results?.prod_offers) {
      yield put(bdGetAddMoreToCollectionSuccess(response?.results?.prod_offers))
    } else {
      throw response.msg ? response.msg : "Failed to fetch data"
    }
  } catch (error) {
    yield put(bdGetAddMoreToCollectionFail(error))
  }
}

function* bdOnUpdateDataToAddMoreToCollection({
  payload: { addMoreUpdateDetails, history },
}) {
  try {
    const response = yield call(
      bdAddMoreDataToCollectionUpdateApi,
      addMoreUpdateDetails
    )
    if (response && response.status) {
      yield put(bdGetAddMoreToCollectionUpdateSuccess(response))
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to update data"
    }
  } catch (error) {
    yield put(bdGetAddMoreToCollectionUpdateFail(error))
  }
}

function* getBdProductsForAddCollection({ payload: productsForAddCollection }) {
  try {
    const response = yield call(
      bdGetProductForAddCollectionApi,
      productsForAddCollection
    )
    if (
      response.results === undefined ||
      response.results.product_dtls.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(bdProductForAddCollectionSuccess(response.results.product_dtls))
  } catch (error) {
    yield put(bdProductForAddCollectionFail(error))
  }
}

function* getBdProductsOfferForAddCollection({
  payload: productsOfferForAddCollection,
}) {
  try {
    const response = yield call(
      bdGetProductsOfferForAddCollectionApi,
      productsOfferForAddCollection
    )
    if (
      response.results === undefined ||
      response.results.prod_offers.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(
      bdProductOfferForAddCollectionSuccess(response.results.prod_offers)
    )
  } catch (error) {
    yield put(bdProductOfferForAddCollectionFail(error))
  }
}

function* bdRefreshCollectionCount({ payload: { collection_id } }) {
  try {
    const response = yield call(bdRefreshCollectionCountApi, {
      collection_id: collection_id,
    })
    if (response.status && response.result) {
      yield put(bdRefreshCollectionCountSuccess(response.result))
    } else {
      throw response.msg ? response.msg : "No data found"
    }
  } catch (error) {
    yield put(bdRefreshCollectionCountFail(error))
  }
}

function* bdGetLayout() {
  try {
    const response = yield call(bdGetLayoutApi)
    if (response === undefined || response.results.length == 0) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(bdGetLayOutSuccess(response.results))
  } catch (error) {
    yield put(bdGetLayOutFail(error))
  }
}

function* bdCollectionSaga() {
  yield takeEvery(BD_COLLECTION_ADD, bdAddNewCollection)
  yield takeEvery(BD_GET_COLLECTION_LISTING, getBdCollectionListing)
  yield takeEvery(BD_COLLECTION_EDIT, getBdCollectionDetailsById)
  yield takeEvery(BD_COLLECTION_UPDATE, bdOnUpdateCollection)
  yield takeEvery(BD_COLLECTION_DELETE, bdOnDeleteCollection)
  yield takeEvery(
    BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
    bdOnExportCollectionCatalog
  )
  yield takeEvery(BD_COLLECTION_COPY, bdOnCopyCollection)
  yield takeEvery(BD_EXPORTDOWNLOAD_PROGRESS, onBdExportDownloadProgress)
  yield takeEvery(BD_ADD_MORE_TO_COLLECTION, bdOnFetchDataToAddMoreToCollection)
  yield takeEvery(
    BD_ADD_MORE_TO_COLLECTION_UPDATE,
    bdOnUpdateDataToAddMoreToCollection
  )
  yield takeEvery(
    BD_GET_PRODUCTS_FOR_ADD_COLLECTION,
    getBdProductsForAddCollection
  )
  yield takeEvery(
    BD_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
    getBdProductsOfferForAddCollection
  )
  yield takeEvery(BD_REFRESH_COLLECTION_COUNT, bdRefreshCollectionCount)
  yield takeEvery(BD_GET_LAYOUT, bdGetLayout)
}

export default bdCollectionSaga
