import React from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
  ButtonGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  editProductCategory,
  updateProductCategory,
} from "../../../store/actions"

import { useEffect, useState } from "react"

const EditProductCategory = props => {
  const dispatch = useDispatch()
  document.title = "Edit Product Category"
  const { id } = useParams()

  const [details, setDetails] = useState({})

  useEffect(() => {
    dispatch(editProductCategory(id))
  }, [])

  const { error, loading, msg, productCategories } = useSelector(state => ({
    error: state.ProductCategory.error,
    loading: state.ProductCategory.loading,
    msg: state.ProductCategory.msg,
    productCategories: state.ProductCategory.productCategory,
  }))

  useEffect(() => {
    let prodCategory = []
    if (productCategories && productCategories.length > 0) {
      prodCategory = productCategories.filter(
        productCategory => productCategory.id == id
      )
    }
    if (prodCategory.length > 0) {
      setDetails(prodCategory[0])
    } else {
      props.history.push("/dropdown/product_category/list")
    }
  }, [productCategories])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: details && details.id ? details.id : "",

      prod_cat_name:
        details && details.prod_cat_name ? details.prod_cat_name : "",

      prod_cat_no: details && details.prod_cat_no ? details.prod_cat_no : "",

      is_default: details && details.is_default ? 1 : 0,
    },
    validationSchema: Yup.object({
      prod_cat_name: Yup.string().required("Please enter category"),
    }),
    onSubmit: values => {
      dispatch(updateProductCategory(values, props.history))
    },
  })

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Edit Product Category" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {error ? <ToastContainer /> : ""}
                  <Form
                    id="editproductCategoryForm"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3 mt-1">
                          <Label htmlFor="product-category-name">
                            Product Category Name
                          </Label>
                          <Input
                            name="prod_cat_name"
                            type="text"
                            id="product-category-name"
                            // placeholder="Enter product category name"
                            autoComplete="off"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.prod_cat_name || ""}
                          />
                          {validation.touched.prod_cat_name &&
                          validation.errors.prod_cat_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.prod_cat_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 mt-1">
                          <Label htmlFor="categoryno">
                            Product Category Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            name="prod_cat_no"
                            className={
                              validation.touched.prod_cat_no &&
                              validation.errors.prod_cat_no
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            id="categoryno"
                            // placeholder="Enter Category"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.prod_cat_no || ""}
                          />
                          {validation.touched.prod_cat_no &&
                          validation.errors.prod_cat_no ? (
                            <FormFeedback type="invalid">
                              {validation.errors.prod_cat_no}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <div>
                      <h6>Default : </h6>
                      <ButtonGroup>
                        <Button
                          name="is_default"
                          color="success"
                          outline
                          value={1}
                          active={validation.values.is_default == 1}
                          onClick={validation.handleChange}
                        >
                          Yes
                        </Button>

                        <Button
                          name="is_default"
                          color="secondary"
                          outline
                          value={0}
                          active={validation.values.is_default == 0}
                          onClick={validation.handleChange}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </div>
                    <br />
                    <div>
                      {productCategories && productCategories.loading ? (
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          disabled
                        >
                          Updating ...
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary w-md">
                          Update
                        </button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default EditProductCategory
