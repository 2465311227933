import { call, put, takeEvery } from "redux-saga/effects"

import { GET_COUNTS_ON_DASHBOARD } from "./actionTypes"

import {
  getTotalCountOnDashboardSuccess,
  getTotalCountOnDashboardFail,
} from "./actions"

import { countsOnDashboardApi } from "../../helpers/fakebackend_helper"

function* getTotalCountOnDashboard() {
  try {
    const response = yield call(countsOnDashboardApi)
    if (!response.results?.details_count) {
      yield put(getTotalCountOnDashboardFail(error))
    }
    yield put(getTotalCountOnDashboardSuccess(response.results.details_count))
  } catch (error) {
    yield put(getTotalCountOnDashboardFail(error))
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_COUNTS_ON_DASHBOARD, getTotalCountOnDashboard)
}

export default dashboardSaga
