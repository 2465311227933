import {
  CO_COLLECTION_ADD,
  CO_COLLECTION_SUCCESS,
  CO_COLLECTION_FAIL,
  CO_GET_COLLECTION_LISTING,
  CO_GET_COLLECTION_LISTING_SUCCESS,
  CO_GET_COLLECTION_LISTING_FAILED,
  CO_COLLECTION_EDIT,
  CO_COLLECTION_EDIT_SUCCESS,
  CO_COLLECTION_EDIT_FAIL,
  CO_COLLECTION_UPDATE,
  CO_COLLECTION_UPDATE_SUCCESS,
  CO_COLLECTION_UPDATE_FAIL,
  CO_COLLECTION_DELETE,
  CO_COLLECTION_DELETE_SUCCESS,
  CO_COLLECTION_DELETE_FAIL,
  CO_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
  CO_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS,
  CO_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL,
  CO_COLLECTION_COPY,
  CO_COLLECTION_COPY_SUCCESS,
  CO_COLLECTION_COPY_FAIL,
  CO_EXPORTDOWNLOAD_PROGRESS,
  CO_EXPORTDOWNLOAD_PROGRESS_SUCCESS,
  CO_EXPORTDOWNLOAD_PROGRESS_FAIL,
  CO_ADD_MORE_TO_COLLECTION,
  CO_ADD_MORE_TO_COLLECTION_SUCCESS,
  CO_ADD_MORE_TO_COLLECTION_FAIL,
  CO_ADD_MORE_TO_COLLECTION_UPDATE,
  CO_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS,
  CO_ADD_MORE_TO_COLLECTION_UPDATE_FAIL,
  CO_GET_PRODUCTS_FOR_ADD_COLLECTION,
  CO_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS,
  CO_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL,
  CO_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
  CO_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS,
  CO_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL,
  CO_REFRESH_COLLECTION_COUNT,
  CO_REFRESH_COLLECTION_COUNT_SUCCESS,
  CO_REFRESH_COLLECTION_COUNT_FAIL,
  CO_GET_LAYOUT,
  CO_GET_LAYOUT_SUCCESS,
  CO_GET_LAYOUT_FAIL,
} from "./actionTypes"

export const coAddNewCollection = (collection, history) => {
  return {
    type: CO_COLLECTION_ADD,
    payload: { collection, history },
  }
}

export const coCollectionSuccess = collection => {
  return {
    type: CO_COLLECTION_SUCCESS,
    payload: collection,
  }
}

export const coCollectionFail = error => {
  return {
    type: CO_COLLECTION_FAIL,
    payload: error,
  }
}

export const coCollectionsListing = history => {
  return {
    type: CO_GET_COLLECTION_LISTING,
  }
}

export const coCollectionsListingSuccess = collection => {
  return {
    type: CO_GET_COLLECTION_LISTING_SUCCESS,
    payload: collection,
  }
}

export const coCollectionsListingFail = error => {
  return {
    type: CO_GET_COLLECTION_LISTING_FAILED,
    payload: error,
  }
}

export const coCollectionEdit = id => {
  return {
    type: CO_COLLECTION_EDIT,
    payload: { id: id },
  }
}

export const coEditCollectionSuccess = collection => {
  return {
    type: CO_COLLECTION_EDIT_SUCCESS,
    payload: collection,
  }
}

export const coEditCollectionFail = error => {
  return {
    type: CO_COLLECTION_EDIT_FAIL,
    payload: error,
  }
}

export const coUpdateCollection = (collection, history) => {
  return {
    type: CO_COLLECTION_UPDATE,
    payload: { collection, history },
  }
}

export const coUpdateCollectionSuccess = collection => {
  return {
    type: CO_COLLECTION_UPDATE_SUCCESS,
    payload: collection,
  }
}

export const coUpdateCollectionFail = error => {
  return {
    type: CO_COLLECTION_UPDATE_FAIL,
    payload: error,
  }
}

export const coDeleteCollection = collectionId => ({
  type: CO_COLLECTION_DELETE,
  payload: { id: collectionId },
})

export const coDeleteCollectionSuccess = collection => ({
  type: CO_COLLECTION_DELETE_SUCCESS,
  payload: collection,
})

export const coDeleteCollectionFail = error => ({
  type: CO_COLLECTION_DELETE_FAIL,
  payload: error,
})

export const coExportCollectionCatalogDownload = (collection, history) => {
  return {
    type: CO_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
    payload: { collection, history },
  }
}

export const coExportCollectionCatalogDownloadSuccess = collection => {
  return {
    type: CO_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS,
    payload: collection,
  }
}

export const coExportCollectionCatalogDownloadFail = error => {
  return {
    type: CO_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL,
    payload: error,
  }
}

export const coCopyCollection = (collection, history) => {
  return {
    type: CO_COLLECTION_COPY,
    payload: { collection, history },
  }
}

export const coCopyCollectionSuccess = collection => {
  return {
    type: CO_COLLECTION_COPY_SUCCESS,
    payload: collection,
  }
}

export const coCopyCollectionFail = error => {
  return {
    type: CO_COLLECTION_COPY_FAIL,
    payload: error,
  }
}

export const coExportDownloadProgress = (coExportDownloadProgress, history) => {
  return {
    type: CO_EXPORTDOWNLOAD_PROGRESS,
    payload: { coExportDownloadProgress, history },
  }
}

export const coExportDownloadProgressSuccess = coExportDownloadProgress => {
  return {
    type: CO_EXPORTDOWNLOAD_PROGRESS_SUCCESS,
    payload: coExportDownloadProgress,
  }
}

export const coExportDownloadProgressFail = error => {
  return {
    type: CO_EXPORTDOWNLOAD_PROGRESS_FAIL,
    payload: error,
  }
}

export const coGetAddMoreToCollection = (collection_id) => {
  return {
    type: CO_ADD_MORE_TO_COLLECTION,
    payload: { collection_id:collection_id},
  }
}
export const coGetAddMoreToCollectionSuccess = collectionAddMoreDetails => {
  return {
    type: CO_ADD_MORE_TO_COLLECTION_SUCCESS,
    payload: collectionAddMoreDetails,
  }
}
export const coGetAddMoreToCollectionFail = error => {
  return {
    type: CO_ADD_MORE_TO_COLLECTION_FAIL,
    payload: error,
  }
}

export const coGetAddMoreToCollectionUpdate = (addMoreUpdateDetails, history) => {
  return {
    type: CO_ADD_MORE_TO_COLLECTION_UPDATE,
    payload: {addMoreUpdateDetails, history},
  }
}
export const coGetAddMoreToCollectionUpdateSuccess = collectionAddMoreDetailsUpdate => {
  return {
    type: CO_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS,
    payload: collectionAddMoreDetailsUpdate,
  }
}
export const coGetAddMoreToCollectionUpdateFail = error => {
  return {
    type: CO_ADD_MORE_TO_COLLECTION_UPDATE_FAIL,
    payload: error,
  }
}

export const coProductForAddCollection = productsForAddCollection => {
  return {
    type: CO_GET_PRODUCTS_FOR_ADD_COLLECTION,
    payload: {productsForAddCollection},
  }
}

export const coProductForAddCollectionSuccess = productsForAddCollection => {
  return {
    type: CO_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS,
    payload: productsForAddCollection,
  }
}

export const coProductForAddCollectionFail = error => {
  return {
    type: CO_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL,
    payload: error,
  }
}

export const coProductOfferForAddCollection = productsOfferForAddCollection => {
  return {
    type: CO_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
    payload: {productsOfferForAddCollection},
  }
}

export const coProductOfferForAddCollectionSuccess = productsOfferForAddCollection => {
  return {
    type: CO_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS,
    payload: productsOfferForAddCollection,
  }
}

export const coProductOfferForAddCollectionFail = error => {
  return {
    type: CO_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL,
    payload: error,
  }
}

export const coRefreshCollectionCount = id => {
  return {
    type: CO_REFRESH_COLLECTION_COUNT,
    payload: { collection_id: id },
  }
}

export const coRefreshCollectionCountSuccess = collection => {
  return {
    type: CO_REFRESH_COLLECTION_COUNT_SUCCESS,
    payload: collection,
  }
}

export const coRefreshCollectionCountFail = error => {
  return {
    type: CO_REFRESH_COLLECTION_COUNT_FAIL,
    payload: error,
  }
}

export const coGetLayOut = layout => {
  return {
    type: CO_GET_LAYOUT,
    payload: layout,
  }
}

export const coGetLayOutSuccess = (layout) => {
  return {
    type: CO_GET_LAYOUT_SUCCESS,
    payload: { layout },
  }
}

export const coGetLayOutFail = error => {
  return {
    type: CO_GET_LAYOUT_FAIL,
    payload: error,
  }
}
