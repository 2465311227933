import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { CO_GET_OFFERSTATUS_LISTING } from "./actionTypes"
import {
  coOfferStatusListingSuccess,
  coOfferStatusListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { coOfferStatusListingApi } from "../../../helpers/fakebackend_helper"

function* coGetOfferStatusListing() {
  try {
    const response = yield call(coOfferStatusListingApi)
    if (!response.results.offer_status) {
      yield put(coOfferStatusListingFail(error))
    }
    yield put(coOfferStatusListingSuccess(response.results.offer_status))
  } catch (error) {
    yield put(coOfferStatusListingFail(error))
  }
}

function* coOfferStatusSaga() {
  yield takeEvery(CO_GET_OFFERSTATUS_LISTING, coGetOfferStatusListing)
}
export default coOfferStatusSaga
