import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { useHistory } from "react-router-dom"

import {
  COLLECTION_ADD,
  GET_COLLECTION_LISTING,
  COLLECTION_EDIT,
  COLLECTION_UPDATE,
  COLLECTION_DELETE,
  EXPORTCOLLECTIONCATALOG_DOWNLOAD,
  COLLECTION_COPY,
  ADMINEXPORTDOWNLOAD_PROGRESS,
  ADMIN_ADD_MORE_TO_COLLECTION,
  ADMIN_ADD_MORE_TO_COLLECTION_UPDATE,
  GET_PRODUCTS_FOR_ADD_COLLECTION,
  GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
  REFRESH_COLLECTION_COUNT,
  GET_LAYOUT,
} from "./actionTypes"

import {
  collectionSuccess,
  collectionFail,
  collectionsListingSuccess,
  collectionsListingFail,
  editCollectionSuccess,
  editCollectionFail,
  updateCollectionSuccess,
  updateCollectionFail,
  deleteCollectionSuccess,
  deleteCollectionFail,
  exportCollectionCatalogSuccess,
  exportCollectionCatalogFail,
  coppyCollectionSuccess,
  copyCollectionFail,
  adminExportDownloadProgressSuccess,
  adminExportDownloadProgressFail,
  adminGetAddMoreToCollectionSuccess,
  adminGetAddMoreToCollectionFail,
  adminGetAddMoreToCollectionUpdateFail,
  adminGetAddMoreToCollectionUpdateSuccess,
  productForAddCollectionSuccess,
  productForAddCollectionFail,
  productOfferForAddCollectionSuccess,
  productOfferForAddCollectionFail,
  refreshCollectionCountSuccess,
  refreshCollectionCountFail,
  getLayOutSuccess,
  getLayOutFail,
} from "./actions"

import {
  addCollection,
  collectionsListingApi,
  editCollectionApi,
  updateCollectionApi,
  deleteCollectionApi,
  exportCollectionCatalogApi,
  copyCollection,
  adminExportDownloadProgressApi,
  getAddMoreToCollection,
  addMoreDataToCollectionUpdate,
  getProductForAddCollectionApi,
  getProductsOfferForAddCollectionApi,
  refreshCollectionCountApi,
  getLayoutApi,
} from "../../../helpers/fakebackend_helper"

function* addNewCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(addCollection, collection)

    if (response && response.status) {
      yield put(collectionSuccess(response))
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to add collection"
    }
  } catch (error) {
    yield put(collectionFail(error))
  }
}

function* getCollectionListing() {
  try {
    const response = yield call(collectionsListingApi)
    if (
      response.results === undefined ||
      response.results.collections.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(collectionsListingSuccess(response.results.collections))
  } catch (error) {
    yield put(collectionsListingFail(error))
  }
}

function* getCollectionDetailsById({ payload: { id } }) {
  try {
    const response = yield call(editCollectionApi, id)
    if (response && response.status) {
      response.id = id
      yield put(editCollectionSuccess(response.results.collection))
    } else {
      throw response.msg ? response.msg : "Failed to fetch collection details"
    }
  } catch (error) {
    yield put(editCollectionFail(error))
  }
}

function* onUpdateCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(updateCollectionApi, collection)
    if (response && response.status) {
      yield put(updateCollectionSuccess(response))
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to update collection"
    }
  } catch (error) {
    yield put(updateCollectionFail(error))
  }
}

function* onDeleteCollection({ payload: { id } }) {
  try {
    const response = yield call(deleteCollectionApi, { collection_id: id })
    if (response && response.status) {
      response.id = id
      yield put(deleteCollectionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to delete collection"
    }
  } catch (error) {
    yield put(deleteCollectionFail(error))
  }
}

function* onExportCollectionCatalog({ payload: { collection, history } }) {
  try {
    const response = yield call(exportCollectionCatalogApi, collection)
    if (response && response.status) {
      yield put(exportCollectionCatalogSuccess(response))
    } else {
      throw response.msg ? response.msg : "Download in progress..."
    }
  } catch (error) {
    yield put(exportCollectionCatalogFail(error))
  }
}

function* onCopyCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(copyCollection, collection)

    if (response && response.status) {
      yield put(
        coppyCollectionSuccess(response.record.original?.results?.collection)
      )
    } else {
      throw response.msg ? response.msg : "Failed to copy collection"
    }
  } catch (error) {
    yield put(copyCollectionFail(error))
  }
}

function* onAdminExportDownloadProgress({
  payload: { adminExportDownloadProgress, history },
}) {
  try {
    const response = yield call(
      adminExportDownloadProgressApi,
      adminExportDownloadProgress
    )
    if (response && response.status) {
      yield put(adminExportDownloadProgressSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to download file"
    }
  } catch (error) {
    yield put(adminExportDownloadProgressFail(error))
  }
}

function* onFetchDataToAddMoreToCollection({ payload: { collection_id } }) {
  try {
    const response = yield call(getAddMoreToCollection, {
      collection_id: collection_id,
    })
    if (response && response.status && response?.results?.product_dtls) {
      yield put(
        adminGetAddMoreToCollectionSuccess(response?.results?.product_dtls)
      )
    } else if (response && response.status && response?.results?.prod_offers) {
      yield put(
        adminGetAddMoreToCollectionSuccess(response?.results?.prod_offers)
      )
    } else {
      throw response.msg ? response.msg : "Failed to fetch data"
    }
  } catch (error) {
    yield put(adminGetAddMoreToCollectionFail(error))
  }
}

function* onUpdateDataToAddMoreToCollection({
  payload: { addMoreUpdateDetails, history },
}) {
  try {
    const response = yield call(
      addMoreDataToCollectionUpdate,
      addMoreUpdateDetails
    )
    if (response && response.status) {
      yield put(adminGetAddMoreToCollectionUpdateSuccess(response))
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to update data"
    }
  } catch (error) {
    yield put(adminGetAddMoreToCollectionUpdateFail(error))
  }
}

function* getProductsForAddCollection({
  payload: { productsForAddCollection },
}) {
  try {
    const response = yield call(
      getProductForAddCollectionApi,
      productsForAddCollection
    )
    if (
      response.results === undefined ||
      response.results.product_dtls.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(productForAddCollectionSuccess(response.results.product_dtls))
  } catch (error) {
    yield put(productForAddCollectionFail(error))
  }
}

function* getProductsOfferForAddCollection({
  payload: { productsOfferForAddCollection },
}) {
  try {
    const response = yield call(
      getProductsOfferForAddCollectionApi,
      productsOfferForAddCollection
    )
    if (
      response.results === undefined ||
      response.results.prod_offers.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(productOfferForAddCollectionSuccess(response.results.prod_offers))
  } catch (error) {
    yield put(productOfferForAddCollectionFail(error))
  }
}

function* refreshCollectionCount({ payload: { collection_id } }) {
  try {
    const response = yield call(refreshCollectionCountApi, {
      collection_id: collection_id,
    })
    if (response.status && response.result) {
      yield put(refreshCollectionCountSuccess(response.result))
    } else {
      throw response.msg ? response.msg : "No data found"
    }
  } catch (error) {
    yield put(refreshCollectionCountFail(error))
  }
}

function* getLayout() {
  try {
    const response = yield call(getLayoutApi)
    if (response === undefined || response.results.length == 0) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(getLayOutSuccess(response.results))
  } catch (error) {
    yield put(getLayOutFail(error))
  }
}

function* collectionSaga() {
  yield takeEvery(COLLECTION_ADD, addNewCollection)
  yield takeEvery(GET_COLLECTION_LISTING, getCollectionListing)
  yield takeEvery(COLLECTION_EDIT, getCollectionDetailsById)
  yield takeEvery(COLLECTION_UPDATE, onUpdateCollection)
  yield takeEvery(COLLECTION_DELETE, onDeleteCollection)
  yield takeEvery(EXPORTCOLLECTIONCATALOG_DOWNLOAD, onExportCollectionCatalog)
  yield takeEvery(COLLECTION_COPY, onCopyCollection)
  yield takeEvery(ADMINEXPORTDOWNLOAD_PROGRESS, onAdminExportDownloadProgress)
  yield takeEvery(
    ADMIN_ADD_MORE_TO_COLLECTION,
    onFetchDataToAddMoreToCollection
  )
  yield takeEvery(
    ADMIN_ADD_MORE_TO_COLLECTION_UPDATE,
    onUpdateDataToAddMoreToCollection
  )
  yield takeEvery(GET_PRODUCTS_FOR_ADD_COLLECTION, getProductsForAddCollection)
  yield takeEvery(
    GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
    getProductsOfferForAddCollection
  )
  yield takeEvery(REFRESH_COLLECTION_COUNT, refreshCollectionCount)
  yield takeEvery(GET_LAYOUT, getLayout)
}

export default collectionSaga
