export const BD_COLLECTION_ADD = "BD_COLLECTION_ADD"
export const BD_COLLECTION_SUCCESS = "BD_COLLECTION_SUCCESS"
export const BD_COLLECTION_FAIL = "BD_COLLECTION_FAIL"

export const BD_GET_COLLECTION_LISTING = "BD_GET_COLLECTION_LISTING"
export const BD_GET_COLLECTION_LISTING_SUCCESS = "BD_GET_COLLECTION_LISTING_SUCCESS"
export const BD_GET_COLLECTION_LISTING_FAILED = "BD_GET_COLLECTION_LISTING_FAILED"

export const BD_COLLECTION_EDIT = "BD_COLLECTION_EDIT"
export const BD_COLLECTION_EDIT_SUCCESS = "BD_COLLECTION_EDIT_SUCCESS"
export const BD_COLLECTION_EDIT_FAIL = "BD_COLLECTION_EDIT_FAIL"

export const BD_COLLECTION_UPDATE = "BD_COLLECTION_UPDATE"
export const BD_COLLECTION_UPDATE_SUCCESS = "BD_COLLECTION_UPDATE_SUCCESS"
export const BD_COLLECTION_UPDATE_FAIL = "BD_COLLECTION_UPDATE_FAIL"

export const BD_COLLECTION_DELETE = "BD_COLLECTION_DELETE"
export const BD_COLLECTION_DELETE_SUCCESS = "BD_COLLECTION_DELETE_SUCCESS"
export const BD_COLLECTION_DELETE_FAIL = "BD_COLLECTION_DELETE_FAIL"

export const BD_COLLECTION_COPY = "BD_COLLECTION_COPY"
export const BD_COLLECTION_COPY_SUCCESS = "BD_COLLECTION_COPY_SUCCESS"
export const BD_COLLECTION_COPY_FAIL = "BD_COLLECTION_COPY_FAIL"

export const BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD = "BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD"
export const BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS = "BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS"
export const BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL = "BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL"

export const BD_EXPORTDOWNLOAD_PROGRESS = "BD_EXPORTDOWNLOAD_PROGRESS"
export const BD_EXPORTDOWNLOAD_PROGRESS_SUCCESS = "BD_EXPORTDOWNLOAD_PROGRESS_SUCCESS"
export const BD_EXPORTDOWNLOAD_PROGRESS_FAIL = "BD_EXPORTDOWNLOAD_PROGRESS_FAIL"

export const BD_ADD_MORE_TO_COLLECTION = "BD_ADD_MORE_TO_COLLECTION"
export const BD_ADD_MORE_TO_COLLECTION_SUCCESS = "BD_ADD_MORE_TO_COLLECTION_SUCCESS"
export const BD_ADD_MORE_TO_COLLECTION_FAIL = "BD_ADD_MORE_TO_COLLECTION_FAIL"

export const BD_ADD_MORE_TO_COLLECTION_UPDATE = "BD_ADD_MORE_TO_COLLECTION_UPDATE"
export const BD_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS = "BD_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS"
export const BD_ADD_MORE_TO_COLLECTION_UPDATE_FAIL = "BD_ADD_MORE_TO_COLLECTION_UPDATE_FAIL"

export const BD_GET_PRODUCTS_FOR_ADD_COLLECTION = "BD_GET_PRODUCTS_FOR_ADD_COLLECTION"
export const BD_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS = "BD_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS"
export const BD_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL = "BD_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL"

export const BD_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION = "BD_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION"
export const BD_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS = "BD_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS"
export const BD_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL = "BD_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL"

export const BD_REFRESH_COLLECTION_COUNT = "BD_REFRESH_COLLECTION_COUNT"
export const BD_REFRESH_COLLECTION_COUNT_SUCCESS =
  "BD_REFRESH_COLLECTION_COUNT_SUCCESS"
export const BD_REFRESH_COLLECTION_COUNT_FAIL = "BD_REFRESH_COLLECTION_COUNT_FAIL"

export const BD_GET_LAYOUT = "BD_GET_LAYOUT"
export const BD_GET_LAYOUT_SUCCESS = "BD_GET_LAYOUT_SUCCESS"
export const BD_GET_LAYOUT_FAIL = "BD_GET_LAYOUT_FAIL"