export const PRODUCT_SUCCESS = "PRODUCT_SUCCESS"
export const PRODUCT_FAIL = "PRODUCT_ERROR"

export const PRODUCT_EDIT = "PRODUCT_EDIT"
export const PRODUCT_EDIT_SUCCESS = "PRODUCT_EDIT_SUCCESS"
export const PRODUCT_EDIT_FAIL = "PRODUCT_EDIT_FAIL"

export const PRODUCT_ADD = "PRODUCT_ADD"

export const GET_PRODUCTS_LISTING = "GET_PRODUCTS_LISTING"
export const GET_PRODUCTS_LISTING_SUCCESS = "GET_PRODUCTS_LISTING_SUCCESS"
export const GET_PRODUCTS_LISTING_FAILED = "GET_PRODUCTS_LISTING_FAILED"

export const PRODUCT_UPDATE = "PRODUCT_UPDATE"
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS"
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL"

export const PRODUCT_DELETE = "PRODUCT_DELETE"
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS"
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL"

export const PRODUCT_IMAGE_DELETE = "PRODUCT_IMAGE_DELETE"
export const PRODUCT_IMAGE_DELETE_SUCCESS = "PRODUCT_IMAGE_DELETE_SUCCESS"
export const PRODUCT_IMAGE_DELETE_FAIL = "PRODUCT_IMAGE_DELETE_FAIL"

export const CHANGE_PRODUCT_STATUS = "CHANGE_PRODUCT_STATUS"
export const CHANGE_PRODUCT_STATUS_SUCCESS = "CHANGE_PRODUCT_STATUS_SUCCESS"
export const CHANGE_PRODUCT_STATUS_FAIL = "CHANGE_PRODUCT_STATUS_FAIL"
