import {
  BD_GET_PACKAGING_LISTING,
  BD_GET_PACKAGING_LISTING_SUCCESS,
  BD_GET_PACKAGING_LISTING_FAILED,
} from "./actionTypes"

export const bdPackagingListing = () => {
  return {
    type: BD_GET_PACKAGING_LISTING,
  }
}

export const bdPackagingListingSuccess = bdPackaging => {
  return {
    type: BD_GET_PACKAGING_LISTING_SUCCESS,
    payload: bdPackaging,
  }
}

export const bdPackagingListingFail = error => {
  return {
    type: BD_GET_PACKAGING_LISTING_FAILED,
    payload: error,
  }
}
