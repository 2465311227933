import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { BD_GET_OFFERSTATUS_LISTING } from "./actionTypes"
import {
  bdOfferStatusListingSuccess,
  bdOfferStatusListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { bdOfferStatusListingApi } from "../../../helpers/fakebackend_helper"

function* getBdOfferStatusListing() {
  try {
    const response = yield call(bdOfferStatusListingApi)
    if (!response.results.offer_status) {
      yield put(bdOfferStatusListingFail(error))
    }
    yield put(bdOfferStatusListingSuccess(response.results.offer_status))
  } catch (error) {
    yield put(bdOfferStatusListingFail(error))
  }
}

function* bdOfferStatusSaga() {
  yield takeEvery(BD_GET_OFFERSTATUS_LISTING, getBdOfferStatusListing)
}
export default bdOfferStatusSaga
