import {
  GET_PACKINGSTANDARD_LISTING,
  GET_PACKINGSTANDARD_LISTING_SUCCESS,
  GET_PACKINGSTANDARD_LISTING_FAILED,
  FETCH_PACKING_STANDARD_BY_SECTION,
  FETCH_PACKING_STANDARD_BY_SECTION_SUCCESS,
  FETCH_PACKING_STANDARD_BY_SECTION_FAIL,
  ADD_PACKING_STANDARD,
  ADD_PACKING_STANDARD_SUCCESS,
  ADD_PACKING_STANDARD_FAIL,
  EDIT_PACKING_STANDARD,
  EDIT_PACKING_STANDARD_SUCCESS,
  EDIT_PACKING_STANDARD_FAIL,
  UPDATE_PACKING_STANDARD,
  UPDATE_PACKING_STANDARD_SUCCESS,
  UPDATE_PACKING_STANDARD_FAIL,
  PACKING_STANDARD_ACTIVE_INACTIVE,
  PACKING_STANDARD_ACTIVE_INACTIVE_SUCCESS,
  PACKING_STANDARD_ACTIVE_INACTIVE_FAIL,
  PACKING_STANDARD_POSITION,
  PACKING_STANDARD_POSITION_SUCCESS,
  PACKING_STANDARD_POSITION_FAIL,
} from "./actionTypes"

export const packingStandardListing = () => {
  return {
    type: GET_PACKINGSTANDARD_LISTING,
  }
}

export const packingStandardListingSuccess = packingStandard => {
  return {
    type: GET_PACKINGSTANDARD_LISTING_SUCCESS,
    payload: packingStandard,
  }
}

export const packingStandardListingFail = error => {
  return {
    type: GET_PACKINGSTANDARD_LISTING_FAILED,
    payload: error,
  }
}

export const fetchPackingStandardListingBySection = data => {
  return {
    type: FETCH_PACKING_STANDARD_BY_SECTION,
    payload: { data },
  }
}

export const fetchPackingStandardListingBySectionSuccess = packingStandard => {
  return {
    type: FETCH_PACKING_STANDARD_BY_SECTION_SUCCESS,
    payload: packingStandard,
  }
}

export const fetchPackingStandardListingBySectionFail = error => {
  return {
    type: FETCH_PACKING_STANDARD_BY_SECTION_FAIL,
    payload: error,
  }
}

export const addPackingStandard = (data, history) => {
  return {
    type: ADD_PACKING_STANDARD,
    payload: { data, history },
  }
}

export const addPackingStandardSuccess = packingStandard => {
  return {
    type: ADD_PACKING_STANDARD_SUCCESS,
    payload: packingStandard,
  }
}

export const addPackingStandardFail = error => {
  return {
    type: ADD_PACKING_STANDARD_FAIL,
    payload: error,
  }
}

export const editPackingStandard = id => {
  return {
    type: EDIT_PACKING_STANDARD,
    payload: { id: id },
  }
}
export const editPackingStandardSuccess = packingStandard => {
  return {
    type: EDIT_PACKING_STANDARD_SUCCESS,
    payload: packingStandard,
  }
}
export const editPackingStandardFail = error => {
  return {
    type: EDIT_PACKING_STANDARD_FAIL,
    payload: error,
  }
}

export const updatePackingStandard = (updatePackingStandard, history) => {
  return {
    type: UPDATE_PACKING_STANDARD,
    payload: { updatePackingStandard, history },
  }
}

export const updatePackingStandardSuccess = updatePackingStandard => {
  return {
    type: UPDATE_PACKING_STANDARD_SUCCESS,
    payload: updatePackingStandard,
  }
}

export const updatePackingStandardFail = error => {
  return {
    type: UPDATE_PACKING_STANDARD_FAIL,
    payload: error,
  }
}

export const packingStandardChange = id => ({
  type: PACKING_STANDARD_ACTIVE_INACTIVE,
  payload: { id: id },
})

export const packingStandardChangeSuccess = packingStandard => ({
  type: PACKING_STANDARD_ACTIVE_INACTIVE_SUCCESS,
  payload: packingStandard,
})

export const packingStandardChangeFail = error => ({
  type: PACKING_STANDARD_ACTIVE_INACTIVE_FAIL,
  payload: error,
})

export const packingStandardPosition = position => ({
  type: PACKING_STANDARD_POSITION,
  payload: { position },
})

export const packingStandardPositionSuccess = position => ({
  type: PACKING_STANDARD_POSITION_SUCCESS,
  payload: position,
})

export const packingStandardPositionFail = error => ({
  type: PACKING_STANDARD_POSITION_FAIL,
  payload: error,
})
