import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import React, { useState, useRef, useEffect, useMemo, useCallback } from "react"
import BtnCellRenderer from "./co-BtnCellRenderer.jsx"
import ProductImageRenderer from "./co-ProductImageRenderer.jsx"
import ActiveInactive from "./co-ActiveInactive.jsx"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Row,
  Col,
  Container,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  coProductsListing,
  coDeleteProduct,
  coChangeProductStatus,
} from "../../../store/actions"

const CoProductList = props => {
  let key = `${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`
  LicenseManager.setLicenseKey(key)

  const gridRef = useRef()

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      filter: true,
      minWidth: 200,
      resizable: true,
    }),
    []
  )
  const rowHeight = 150

  document.title = "Products List"

  const dispatch = useDispatch()

  const [productsData, setProductsData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [currentProduct, setCurrentProduct] = useState(undefined)
  const columnDefination = [
    {
      field: "action",
      cellRenderer: "BtnCellRenderer",
      maxWidth: 200,
      cellRendererSelector: params => {
        const buttons = {
          component: BtnCellRenderer,
          params: {
            deleteProductConfirmation: updateModalState,
          },
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Status",
      field: "product_status",
      maxWidth: 100,
      cellRenderer: "ActiveInactive",
      cellRendererSelector: params => {
        const buttons = {
          component: ActiveInactive,
          params: {
            callChangeProductStatus: callChangeProductStatus,
          },
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Name",
      field: "prod_name",
    },
    {
      headerName: "Product No",
      field: "prod_no",
    },
    {
      headerName: "Product Category",
      field: "prod_category",
    },
    {
      headerName: "Material Desc",
      field: "material_desc",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    {
      headerName: "Construction",
      field: "construction",
      filter: "agSetColumnFilter",
    },
    { headerName: "Length", field: "length", filter: "agSetColumnFilter" },
    { headerName: "Depth", field: "depth", filter: "agSetColumnFilter" },
    { headerName: "height", field: "height", filter: "agSetColumnFilter" },
    {
      headerName: "Created By",
      field: "created_by",
    },
    {
      headerName: "Created Date",
      field: "created_date",
    },
    {
      headerName: "Product Folder",
      field: "product_folder",
      maxWidth: 150,
      cellRenderer: params => {
        if (params.data.product_folder) {
          return (
            <a
              className="text-center cursor-pointer font_size_medium"
              target="_blank"
              rel="noreferrer"
              href={params.data.product_folder}
            >
              Click here
            </a>
          )
        } else {
          return undefined
        }
      },
    },
    {
      headerName: "Product Image 1",
      field: "img1",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 2",
      field: "img2",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 3",
      field: "img3",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 4",
      field: "img4",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 5",
      field: "img5",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
  ]
  const [columnDefs, setColumnDefs] = useState(columnDefination)

  function updateModalState(id) {
    setModalOpen(!modalOpen)
    setCurrentProduct(id)
  }

  function deleteProductHandler(id) {
    if (id) {
      dispatch(coDeleteProduct(id))
      updateModalState()
    }
  }

  function callChangeProductStatus(id) {
    dispatch(coChangeProductStatus(id))
  }

  useEffect(() => {
    dispatch(coProductsListing())
  }, [])

  const showAgGridLoading = useCallback(() => {
    if (gridRef.current.api) {
      gridRef.current.api.showLoadingOverlay()
    }
  }, [])

  const hideAgGridLoading = useCallback(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.hideOverlay()
    }
  }, [])

  let productList = useSelector(state => state.coProduct.product)
  useEffect(() => {
    if (productList && productList.length > 0) {
      setProductsData(productList)
      if (columnDefination && productsData && productsData.length > 0) {
        gridRef.current.api.setColumnDefs(columnDefination)
      }
    }
  }, [productList])

  const { error, loading, msg } = useSelector(state => ({
    error: state.coProduct.error,
    loading: state.coProduct.loading,
    msg: state.coProduct.msg,
  }))

  if (loading) {
    showAgGridLoading()
  } else {
    hideAgGridLoading()
  }
  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container fluid={true}>
          <Row>
            <Col md={12} lg={12} xl={12}>
              {error ? <ToastContainer /> : ""}
              {msg ? <ToastContainer /> : ""}
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: "80vh" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={productsData}
                  rowHeight={rowHeight}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  animateRows={true}
                  pagination={true}
                  paginationPageSize={20}
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while we are fetching data</span>'
                  }
                />
              </div>
            </Col>
          </Row>

          <Modal
            isOpen={modalOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="deleteProductConfirm"
            tabIndex="-1"
            backdrop={false}
            toggle={() => updateModalState()}
          >
            <ModalHeader>Remove Product</ModalHeader>
            <ModalBody>Do you really want to delete product?</ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => deleteProductHandler(currentProduct)}
              >
                Yes
              </Button>{" "}
              <Button onClick={() => updateModalState()}>No</Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CoProductList
