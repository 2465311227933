import {
  GET_PRODUCTCATEGORY_LISTING,
  GET_PRODUCTCATEGORY_LISTING_SUCCESS,
  GET_PRODUCTCATEGORY_LISTING_FAILED,
  ADD_PRODUCT_CATEGORY,
  ADD_PRODUCT_CATEGORY_SUCCESS,
  ADD_PRODUCT_CATEGORY_FAIL,
  CHANGE_PRODUCT_CATEGORY_STATUS,
  CHANGE_PRODUCT_CATEGORY_STATUS_SUCCESS,
  CHANGE_PRODUCT_CATEGORY_STATUS_FAIL,
  EDIT_PRODUCT_CATEGORY,
  EDIT_PRODUCT_CATEGORY_SUCCESS,
  EDIT_PRODUCT_CATEGORY_FAIL,
  UPDATE_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY_SUCCESS,
  UPDATE_PRODUCT_CATEGORY_FAIL,
  FETCH_PRODUCT_CATEGORY_BY_SECTION,
  FETCH_PRODUCT_CATEGORY_BY_SECTION_SUCCESS,
  FETCH_PRODUCT_CATEGORY_BY_SECTION_FAIL,
  UPDATE_PRODUCT_CATEGORY_POSITION,
  UPDATE_PRODUCT_CATEGORY_POSITION_SUCCESS,
  UPDATE_PRODUCT_CATEGORY_POSITION_FAIL,
} from "./actionTypes"

const initialState = {
  error: null,
  msg: null,
  loading: false,
  productCategory: null,
  productCategoryEdit: null,
  productCategoryUpdate: null,
  positionUpdate: null,
}

const productCategory = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTCATEGORY_LISTING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
        productCategory: action.payload,
      }
      break
    case GET_PRODUCTCATEGORY_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        positionUpdate: null,
        productCategory: action.payload,
      }
      break
    case GET_PRODUCTCATEGORY_LISTING_FAILED:
      state = { ...state, loading: false }
      break
    case ADD_PRODUCT_CATEGORY:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        productCategory: action.payload,
      }
      break
    case ADD_PRODUCT_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        productCategory: action.payload,
      }
      break
    case ADD_PRODUCT_CATEGORY_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload ? action.payload : null,
      }
      break
    case CHANGE_PRODUCT_CATEGORY_STATUS:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break
    case CHANGE_PRODUCT_CATEGORY_STATUS_SUCCESS:
      const index = state.productCategory.findIndex(
        prod_cat => prod_cat.id === action.payload.id
      )
      let newProductCategoryAfterStatusChange = []
      newProductCategoryAfterStatusChange = [...state.productCategory] //making a new array
      newProductCategoryAfterStatusChange[index].status =
        newProductCategoryAfterStatusChange[index].status == 1 ? 0 : 1

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        productCategory: newProductCategoryAfterStatusChange,
      }
      break

    case CHANGE_PRODUCT_CATEGORY_STATUS_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case EDIT_PRODUCT_CATEGORY:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        productCategoryEdit: action.payload,
      }
      break
    case EDIT_PRODUCT_CATEGORY_SUCCESS:
      if (state === null || state.productCategory.length === 0) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          productCategory: [...state.productCategory, action.payload],
        }
      } else {
        state = {
          ...state,
          loading: false,
          productCategory: state.productCategory.map(i =>
            i.id === action.payload.id ? action.payload : i
          ),
        }
      }
      break
    case EDIT_PRODUCT_CATEGORY_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case UPDATE_PRODUCT_CATEGORY:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        productCategoryUpdate: action.payload,
      }
      break
    case UPDATE_PRODUCT_CATEGORY_SUCCESS:
      state = { ...state, error: null, msg: null, loading: false }
      break
    case UPDATE_PRODUCT_CATEGORY_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case FETCH_PRODUCT_CATEGORY_BY_SECTION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
        productCategoryList: action.payload,
      }
      break
    case FETCH_PRODUCT_CATEGORY_BY_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        positionUpdate: null,
        productCategory: action.payload,
      }
      break
    case FETCH_PRODUCT_CATEGORY_BY_SECTION_FAIL:
      state = { ...state, loading: false }
      break
      default:
        state = { ...state }
        break
        case UPDATE_PRODUCT_CATEGORY_POSITION:
          state = {
            ...state,
            loading: true,
            error: null,
            msg: null,
            positionUpdate: null,
          }
          break
    
        case UPDATE_PRODUCT_CATEGORY_POSITION_SUCCESS:
          state = {
            ...state,
            loading: false,
            error: null,
            msg: action.payload.msg != "" ? action.payload.msg : "",
            positionUpdate: true,
          }
          break
        case UPDATE_PRODUCT_CATEGORY_POSITION_FAIL:
          state = {
            ...state,
            loading: false,
            error: action.payload.msg != "" ? action.payload.msg : "",
            msg: null,
            positionUpdate: null,
          }
          break
  }
  return state
}

export default productCategory
