import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { useHistory } from "react-router-dom"

import {
  QA_COLLECTION_ADD,
  QA_GET_COLLECTION_LISTING,
  QA_COLLECTION_EDIT,
  QA_COLLECTION_UPDATE,
  QA_COLLECTION_DELETE,
  QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
  QA_COLLECTION_COPY,
  QA_EXPORTDOWNLOAD_PROGRESS,
  QA_ADD_MORE_TO_COLLECTION,
  QA_ADD_MORE_TO_COLLECTION_UPDATE,
  QA_GET_PRODUCTS_FOR_ADD_COLLECTION,
  QA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
  QA_REFRESH_COLLECTION_COUNT,
  QA_GET_LAYOUT
} from "./actionTypes"

import {
  qaCollectionSuccess,
  qaCollectionFail,
  qaCollectionsListingSuccess,
  qaCollectionsListingFail,
  qaEditCollectionSuccess,
  qaEditCollectionFail,
  qaUpdateCollectionSuccess,
  qaUpdateCollectionFail,
  qaDeleteCollectionSuccess,
  qaDeleteCollectionFail,
  qaExportCollectionCatalogSuccess,
  qaExportCollectionCatalogFail,
  qaCopyCollectionSuccess,
  qaCopyCollectionFail,
  qaExportDownloadProgressSuccess,
  qaExportDownloadProgressFail,
  qaGetAddMoreToCollectionSuccess,
  qaGetAddMoreToCollectionFail,
  qaGetAddMoreToCollectionUpdateFail,
  qaGetAddMoreToCollectionUpdateSuccess,
  qaProductForAddCollectionSuccess,
  qaProductForAddCollectionFail,
  qaProductOfferForAddCollectionSuccess,
  qaProductOfferForAddCollectionFail,
  qaRefreshCollectionCountSuccess,
  qaRefreshCollectionCountFail,
  qaGetLayOutSuccess,
  qaGetLayOutFail
} from "./actions"

import {
  qaAddCollectionApi,
  qaCollectionsListingApi,
  qaEditCollectionApi,
  qaUpdateCollectionApi,
  qaDeleteCollectionApi,
  qaExportCollectionCatalogApi,
  qaCopyCollectionApi,
  qaExportDownloadProgressApi,
  qaGetAddMoreToCollectionApi,
  qaAddMoreDataToCollectionUpdateApi,
  qaGetProductForAddCollectionApi,
  qaGetProductsOfferForAddCollectionApi,
  qaRefreshCollectionCountApi,
  qaGetLayoutApi,
} from "../../../helpers/fakebackend_helper"

function* qaAddNewCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(qaAddCollectionApi, collection)

    if (response && response.status) {
      yield put(qaCollectionSuccess(response))
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to add collection"
    }
  } catch (error) {
    yield put(qaCollectionFail(error))
  }
}

function* qaGetCollectionListing() {
  try {
    const response = yield call(qaCollectionsListingApi)
    if (
      response.results === undefined ||
      response.results.collections.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(qaCollectionsListingSuccess(response.results.collections))
  } catch (error) {
    yield put(qaCollectionsListingFail(error))
  }
}

function* qaGetCollectionDetailsById({ payload: { id } }) {
  try {
    const response = yield call(qaEditCollectionApi, id)
    if (response && response.status) {
      response.id = id
      yield put(qaEditCollectionSuccess(response.results.collection))
    } else {
      throw response.msg ? response.msg : "Failed to fetch collection details"
    }
  } catch (error) {
    yield put(qaEditCollectionFail(error))
  }
}

function* qaUpdateCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(qaUpdateCollectionApi, collection)
    if (response && response.status) {
      yield put(qaUpdateCollectionSuccess(response))
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to update collection"
    }
  } catch (error) {
    yield put(qaUpdateCollectionFail(error))
  }
}

function* qaOnDeleteCollection({ payload: { id } }) {
  try {
    const response = yield call(qaDeleteCollectionApi, { collection_id: id })
    if (response && response.status) {
      response.id = id
      yield put(qaDeleteCollectionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to delete collection"
    }
  } catch (error) {
    yield put(qaDeleteCollectionFail(error))
  }
}

function* qaOnExportCollectionCatalog({ payload: { collection, history } }) {
  try {
    const response = yield call(qaExportCollectionCatalogApi, collection)
    if (response && response.status) {
      yield put(qaExportCollectionCatalogSuccess(response))
    } else {
      throw response.msg ? response.msg : "Download in progress..."
    }
  } catch (error) {
    yield put(qaExportCollectionCatalogFail(error))
  }
}

function* qaOnCopyCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(qaCopyCollectionApi, collection)

    if (response && response.status) {
      yield put(
        qaCopyCollectionSuccess(response.record.original?.results?.collection)
      )
    } else {
      throw response.msg ? response.msg : "Failed to copy collection"
    }
  } catch (error) {
    yield put(qaCopyCollectionFail(error))
  }
}

function* onQaExportDownloadProgress({ payload: { adminExportDownloadProgress, history } }) {
  try {
    const response = yield call(qaExportDownloadProgressApi, adminExportDownloadProgress)
    if (response && response.status) {
      yield put(qaExportDownloadProgressSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to download file"
    }
  } catch (error) {
    yield put(qaExportDownloadProgressFail(error))
  }
}

function* qaOnFetchDataToAddMoreToCollection({ payload: { collection_id } }) {
  try {
    const response = yield call(qaGetAddMoreToCollectionApi, { 'collection_id': collection_id })
    if (response && response.status && response?.results?.product_dtls) {
      yield put(
        qaGetAddMoreToCollectionSuccess(response?.results?.product_dtls)
      )
    } else if (response && response.status && response?.results?.prod_offers) {
      yield put(
        qaGetAddMoreToCollectionSuccess(response?.results?.prod_offers)
      )
    } else {
      throw response.msg ? response.msg : "Failed to fetch data"
    }
  } catch (error) {
    yield put(qaGetAddMoreToCollectionFail(error))
  }
}

function* qaOnUpdateDataToAddMoreToCollection({ payload: { addMoreUpdateDetails, history } }) {
  try {
    const response = yield call(qaAddMoreDataToCollectionUpdateApi, addMoreUpdateDetails)
    if (response && response.status) {
      yield put(
        qaGetAddMoreToCollectionUpdateSuccess(response)
      )
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to update data"
    }
  } catch (error) {
    yield put(qaGetAddMoreToCollectionUpdateFail(error))
  }
}

function* qaGetProductsForAddCollection({ payload: { productsForAddCollection } }) {
  try {
    const response = yield call(qaGetProductForAddCollectionApi, productsForAddCollection)
    if (
      response.results === undefined ||
      response.results.product_dtls.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(qaProductForAddCollectionSuccess(response.results.product_dtls))
  } catch (error) {
    yield put(qaProductForAddCollectionFail(error))
  }
}

function* qaGetProductsOfferForAddCollection({ payload: { productsOfferForAddCollection } }) {
  try {
    const response = yield call(qaGetProductsOfferForAddCollectionApi, productsOfferForAddCollection)
    if (
      response.results === undefined ||
      response.results.prod_offers.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(qaProductOfferForAddCollectionSuccess(response.results.prod_offers))
  } catch (error) {
    yield put(qaProductOfferForAddCollectionFail(error))
  }
}

function* qaRefreshCollectionCount({ payload: { collection_id } }) {
  try {
    const response = yield call(qaRefreshCollectionCountApi, {
      collection_id: collection_id,
    })
    if (response.status && response.result) {
      yield put(qaRefreshCollectionCountSuccess(response.result))
    } else {
      throw response.msg ? response.msg : "No data found"
    }
  } catch (error) {
    yield put(qaRefreshCollectionCountFail(error))
  }
}

function* qaGetLayout() {
  try {
    const response = yield call(qaGetLayoutApi)
    if (response === undefined || response.results.length == 0) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(qaGetLayOutSuccess(response.results))
  } catch (error) {
    yield put(qaGetLayOutFail(error))
  }
}

function* qaCollectionSaga() {
  yield takeEvery(QA_COLLECTION_ADD, qaAddNewCollection)
  yield takeEvery(QA_GET_COLLECTION_LISTING, qaGetCollectionListing)
  yield takeEvery(QA_COLLECTION_EDIT, qaGetCollectionDetailsById)
  yield takeEvery(QA_COLLECTION_UPDATE, qaUpdateCollection)
  yield takeEvery(QA_COLLECTION_DELETE, qaOnDeleteCollection)
  yield takeEvery(QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD, qaOnExportCollectionCatalog)
  yield takeEvery(QA_COLLECTION_COPY, qaOnCopyCollection)
  yield takeEvery(QA_EXPORTDOWNLOAD_PROGRESS, onQaExportDownloadProgress)
  yield takeEvery(QA_ADD_MORE_TO_COLLECTION, qaOnFetchDataToAddMoreToCollection)
  yield takeEvery(QA_ADD_MORE_TO_COLLECTION_UPDATE, qaOnUpdateDataToAddMoreToCollection)
  yield takeEvery(QA_GET_PRODUCTS_FOR_ADD_COLLECTION, qaGetProductsForAddCollection)
  yield takeEvery(QA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION, qaGetProductsOfferForAddCollection)
  yield takeEvery(QA_REFRESH_COLLECTION_COUNT, qaRefreshCollectionCount)
  yield takeEvery(QA_GET_LAYOUT, qaGetLayout)
}

export default qaCollectionSaga
