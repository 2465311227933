import React from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useEffect, useState } from "react"

import { roleListing, userEdit, updateUser } from "../../../store/actions"
import { update } from "lodash"

const EditUserRole = props => {
  const dispatch = useDispatch()
  document.title = "Edit User"
  const { id } = useParams()

  const [details, setDetails] = useState({})

  useEffect(() => {
    dispatch(roleListing())
    dispatch(userEdit(id))
  }, [dispatch])

  const [roles, setRoles] = useState([])
  const rolesData = useSelector(state => state.Role.role)

  useEffect(() => {
    setRoles(rolesData)
  }, [rolesData])
  const users = useSelector(state => state.User)

  useEffect(() => {
    let user = []
    if (users && users.user && users.user.length > 0) {
      user = users.user.filter(user => user.user_id == id)
    }
    if (user.length > 0) {
      setDetails(user[0])
    } else {
      // props.history.push("/users/list")
    }
  }, [users])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_id: details && details.user_id ? details.user_id : "",
      user_type_id: details && details.user_role_id ? details.user_role_id : "",
      user_name: details && details.user_name ? details.user_name : "", // left and right both for edit
      user_name_short:
        details && details.user_name_short ? details.user_name_short : "",
    },
    validationSchema: Yup.object({
      user_type_id: Yup.string().required("Please enter your role"),
      user_name: Yup.string().required("Please enter your user name"),
      user_name_short: Yup.string().required(
        "Please enter your user short name"
      ),
    }),
    onSubmit: values => {
      let updateParams = {
        user_id: id,
        user_type_id: values.user_type_id,
        name: values.user_name, // left name is from update params, and right user_name is from edit params.
        short_name: values.user_name_short,
      }
      dispatch(updateUser(updateParams, props.history))
    },
  })

  const { error, msg } = useSelector(state => ({
    error: state.User.error,
    msg: state.User.msg ? state.User.msg : "",
  }))

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Edit User" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {error || msg ? <ToastContainer className="Toastify__close-button" /> : ""}
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="username">
                            User Name
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            name="user_name"
                            className={
                              validation.touched.user_name &&
                              validation.errors.user_name
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            id="username"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user_name || ""}
                          />
                          {validation.touched.user_name &&
                          validation.errors.user_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.user_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="shortname">
                            User name short
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="user_name_short"
                            type="text"
                            className={
                              validation.touched.user_name_short &&
                              validation.errors.user_name_short
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            id="shortname"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user_name_short || ""}
                          />
                          {validation.touched.user_name_short &&
                          validation.errors.user_name_short ? (
                            <FormFeedback type="invalid">
                              {validation.errors.user_name_short}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="role">
                            User role <span className="text-danger">*</span>
                          </Label>
                          <select
                            id="role"
                            className={
                              validation.touched.user_type_id &&
                              validation.errors.user_type_id
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            name="user_type_id"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user_type_id || ""}
                          >
                            <option value="">Choose...</option>
                            {roles &&
                              roles.map((role, rl) => (
                                <option key={"rl" + rl} value={role.id}>
                                  {role.user_role}
                                </option>
                              ))}
                          </select>
                          {validation.touched.user_type_id &&
                          validation.errors.user_type_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.user_type_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <div>
                      {users && users.loading ? (
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          disabled
                        >
                          Updating ...
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary w-md">
                          Update
                        </button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default EditUserRole
