import {
  GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING,
  GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_SUCCESS,
  GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_FAILED,
  ADD_CERTIFICATION,
  ADD_CERTIFICATION_SUCCESS,
  ADD_CERTIFICATION_FAIL,
  CHANGE_CERTIFICATION_STATUS,
  CHANGE_CERTIFICATION_STATUS_SUCCESS,
  CHANGE_CERTIFICATION_STATUS_FAIL,
  EDIT_CERTIFICATION,
  EDIT_CERTIFICATION_SUCCESS,
  EDIT_CERTIFICATION_FAIL,
  UPDATE_CERTIFICATION,
  UPDATE_CERTIFICATION_SUCCESS,
  UPDATE_CERTIFICATION_FAIL,
  FETCH_CERTIFICATION_BY_SECTION,
  FETCH_CERTIFICATION_BY_SECTION_SUCCESS,
  FETCH_CERTIFICATION_BY_SECTION_FAIL,
  UPDATE_CERTIFICATION_POSITION,
  UPDATE_CERTIFICATION_POSITION_SUCCESS,
  UPDATE_CERTIFICATION_POSITION_FAIL,
} from "./actionTypes"

export const environmentalAndHealthCertificationListing = () => {
  return {
    type: GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING,
  }
}

export const environmentalAndHealthCertificationListingSuccess =
  environmentalAndHealthCertification => {
    return {
      type: GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_SUCCESS,
      payload: environmentalAndHealthCertification,
    }
  }

export const environmentalAndHealthCertificationListingFail = error => {
  return {
    type: GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_FAILED,
    payload: error,
  }
}

export const addCertificate = (
  environmentalAndHealthCertification,
  history
) => {
  return {
    type: ADD_CERTIFICATION,
    payload: { environmentalAndHealthCertification, history },
  }
}

export const addCertificateSuccess = environmentalAndHealthCertification => {
  return {
    type: ADD_CERTIFICATION_SUCCESS,
    payload: environmentalAndHealthCertification,
  }
}

export const addCertificateFail = error => {
  return {
    type: ADD_CERTIFICATION_FAIL,
    payload: error,
  }
}

export const changeCertificateStatus = id => ({
  type: CHANGE_CERTIFICATION_STATUS,
  payload: { id: id },
})

export const changeCertificateStatusSuccess =
  environmentalAndHealthCertification => ({
    type: CHANGE_CERTIFICATION_STATUS_SUCCESS,
    payload: environmentalAndHealthCertification,
  })

export const changeCertificateStatusFail = error => ({
  type: CHANGE_CERTIFICATION_STATUS_FAIL,
  payload: error,
})

export const editCertificate = id => {
  return {
    type: EDIT_CERTIFICATION,
    payload: { id },
  }
}
export const editCertificateSuccess = environmentalAndHealthCertification => {
  return {
    type: EDIT_CERTIFICATION_SUCCESS,
    payload: environmentalAndHealthCertification,
  }
}
export const editCertificateFail = error => {
  return {
    type: EDIT_CERTIFICATION_FAIL,
    payload: error,
  }
}

export const updateCertificate = (
  environmentalAndHealthCertification,
  history
) => {
  return {
    type: UPDATE_CERTIFICATION,
    payload: { environmentalAndHealthCertification, history },
  }
}

export const updateCertificateSuccess = certificateUpdate => {
  return {
    type: UPDATE_CERTIFICATION_SUCCESS,
    payload: certificateUpdate,
  }
}

export const updateCertificateFail = error => {
  return {
    type: UPDATE_CERTIFICATION_FAIL,
    payload: error,
  }
}

export const fetchCertificateListingBySection = data => {
  return {
    type: FETCH_CERTIFICATION_BY_SECTION,
    payload: { data },
  }
}

export const fetchCertificateListingBySectionSuccess =
  environmentalAndHealthCertification => {
    return {
      type: FETCH_CERTIFICATION_BY_SECTION_SUCCESS,
      payload: environmentalAndHealthCertification,
    }
  }

export const fetchCertificateListingBySectionFail = error => {
  return {
    type: FETCH_CERTIFICATION_BY_SECTION_FAIL,
    payload: error,
  }
}

export const updateCertificatePosition = data => ({
  type: UPDATE_CERTIFICATION_POSITION,
  payload: { data },
})

export const updateCertificatePositionSuccess =
  environmentalAndHealthCertification => ({
    type: UPDATE_CERTIFICATION_POSITION_SUCCESS,
    payload: environmentalAndHealthCertification,
  })

export const updateCertificatePositionFail = error => ({
  type: UPDATE_CERTIFICATION_POSITION_FAIL,
  payload: error,
})
