import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { CO_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING } from "./actionTypes"
import {
  coEnvironmentalAndHealthCertificationListingSuccess,
  coEnvironmentalAndHealthCertificationListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { coEnvironmentalAndHealthCertificationListingApi } from "../../../helpers/fakebackend_helper"

function* coGetEnvironmentalAndHealthCertificationListing() {
  try {
    const response = yield call(coEnvironmentalAndHealthCertificationListingApi)
    if (!response.results.certificates) {
      yield put(coEnvironmentalAndHealthCertificationListingFail(error))
    }
    yield put(
      coEnvironmentalAndHealthCertificationListingSuccess(
        response.results.certificates
      )
    )
  } catch (error) {
    yield put(coEnvironmentalAndHealthCertificationListingFail(error))
  }
}

function* coEnvironmentalAndHealthCertificationSaga() {
  yield takeEvery(
    CO_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING,
    coGetEnvironmentalAndHealthCertificationListing
  )
}
export default coEnvironmentalAndHealthCertificationSaga
