import { call, put, takeEvery } from "redux-saga/effects"

import { UPDATE_PROFILE, EDIT_PROFILE, CHANGE_PASSWORD } from "./actionTypes"

import {
  editProfileSuccess,
  editProfileFail,
  onUpdateProfileSuccess,
  onUpdateProfileFail,
  onChangePasswordSuccess,
  onChangePasswordFail,
} from "./actions"

import {
  editProfileApi,
  onUpdateProfileApi,
  onChangePasswordApi,
} from "../../../helpers/fakebackend_helper"

function* getProfileDetails() {
  try {
    const response = yield call(editProfileApi)
    if (response && response.status) {
      yield put(editProfileSuccess(response.results.user_details))
    } else {
      throw response.msg ? response.msg : "Failed to edit profile"
    }
  } catch (error) {
    yield put(editProfileFail(error))
  }
}

function* onUpdateProfile({ payload: { data } }) {
  try {
    const response = yield call(onUpdateProfileApi, data)
    if (response && response.status) {
      yield put(onUpdateProfileSuccess(response))
      setTimeout(() => {
        history.push("/offers/list")
      }, 5000)
    } else {
      throw response.msg ? response.msg : "Failed to update profile"
    }
  } catch (error) {
    yield put(onUpdateProfileFail(error))
  }
}

function* onChangePassword({ payload: { data } }) {
  try {
    const response = yield call(onChangePasswordApi, data)
    if (response && response.status) {
      yield put(onChangePasswordSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to change password"
    }
  } catch (error) {
    yield put(onChangePasswordFail(error))
  }
}

function* profileSaga() {
  yield takeEvery(UPDATE_PROFILE, onUpdateProfile)
  yield takeEvery(CHANGE_PASSWORD, onChangePassword)
  yield takeEvery(EDIT_PROFILE, getProfileDetails)
}

export default profileSaga
