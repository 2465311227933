import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { CO_GET_HEAVEYCOLLI_LISTING } from "./actionTypes"
import {
  coHeaveyColliListingSuccess,
  coHeaveyColliListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { coHeaveyColliListingApi } from "../../../helpers/fakebackend_helper"

function* coGetHeaveyColliListing() {
  try {
    const response = yield call(coHeaveyColliListingApi)
    if (!response.results.heavy_colli) {
      yield put(coHeaveyColliListingFail(error))
    }
    yield put(coHeaveyColliListingSuccess(response.results.heavy_colli))
  } catch (error) {
    yield put(coHeaveyColliListingFail(error))
  }
}

function* coHeaveyColliSaga() {
  yield takeEvery(CO_GET_HEAVEYCOLLI_LISTING, coGetHeaveyColliListing)
}
export default coHeaveyColliSaga
