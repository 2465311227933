import {
  GET_SUPPLIERNAME_LISTING,
  GET_SUPPLIERNAME_LISTING_SUCCESS,
  GET_SUPPLIERNAME_LISTING_FAILED,
  FETCH_SUPPLIER_BY_SECTION,
  FETCH_SUPPLIER_BY_SECTION_SUCCESS,
  FETCH_SUPPLIER_BY_SECTION_FAIL,
  EDIT_SUPPLIER,
  EDIT_SUPPLIER_SUCCESS,
  EDIT_SUPPLIER_FAIL,
  ADD_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_FAIL,
  ADD_SUPPLIER,
  UPDATE_SUPPLIER,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_FAIL,
  SUPPLIER_ACTIVE_INACTIVE,
  SUPPLIER_ACTIVE_INACTIVE_FAIL,
  SUPPLIER_ACTIVE_INACTIVE_SUCCESS,
  UPDATE_SUPPLIER_POSITION,
  UPDATE_SUPPLIER_POSITION_SUCCESS,
  UPDATE_SUPPLIER_POSITION_FAIL,
  GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
  GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS,
  GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED,
} from "./actionTypes"

const initialState = {
  error: null,
  msg: null,
  loading: false,
  supplierName: [],
  supplierListingForSearch: [],
  positionUpdate: null,
}

const supplierName = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPLIERNAME_LISTING:
      state = {
        ...state,
        loading: true,
        msg: null,
        error: null,
        positionUpdate: null,
        supplierName: action.payload,
      }
      break
    case GET_SUPPLIERNAME_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: null,
        positionUpdate: null,
        supplierName: action.payload,
      }
      break
    case GET_SUPPLIERNAME_LISTING_FAILED:
      state = { ...state, loading: false }
      break
    case FETCH_SUPPLIER_BY_SECTION:
      state = {
        ...state,
        loading: true,
        msg: null,
        error: null,
        positionUpdate: null,
        supplierNameList: action.payload,
      }
      break
    case FETCH_SUPPLIER_BY_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: null,
        positionUpdate: null,
        supplierName: action.payload,
      }
      break
    case FETCH_SUPPLIER_BY_SECTION_FAIL:
      state = {
        ...state,
        msg: null,
        positionUpdate: null,
        loading: false,
      }
      break
    case ADD_SUPPLIER:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        supplierName: action.payload,
        supplierEdit: null,
      }
      break
    case ADD_SUPPLIER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        supplierName: action.payload,
      }
      break
    case ADD_SUPPLIER_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload ? action.payload : null,
      }
      break
    case EDIT_SUPPLIER:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        supplierEdit: action.payload,
      }
      break
    case EDIT_SUPPLIER_SUCCESS:
      if (state === null || state.supplierName.length === 0) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          supplierName: [...state.supplierName, action.payload],
        }
      } else {
        state = {
          ...state,
          loading: false,
          supplierName: state.supplierName.map(i =>
            i.id === action.payload.id ? action.payload : i
          ),
        }
      }
      break
    case EDIT_SUPPLIER_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case UPDATE_SUPPLIER:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        supplierName: action.payload,
      }
      break
    case UPDATE_SUPPLIER_SUCCESS:
      state = { ...state, error: null, msg: null, loading: false }
      break
    case UPDATE_SUPPLIER_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    case SUPPLIER_ACTIVE_INACTIVE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break

    case SUPPLIER_ACTIVE_INACTIVE_SUCCESS:
      const index = state.supplierName.findIndex(
        supplierName => supplierName.supplier_id === action.payload.id
      )
      let supplierNameStatus = []
      supplierNameStatus = [...state.supplierName] //making a new array
      supplierNameStatus[index].status =
        supplierNameStatus[index].status == 1 ? 0 : 1

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        supplierName: supplierNameStatus,
      }
      break

    case SUPPLIER_ACTIVE_INACTIVE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    case UPDATE_SUPPLIER_POSITION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
      }
      break

    case UPDATE_SUPPLIER_POSITION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: action.payload.msg != "" ? action.payload.msg : "",
        positionUpdate: true,
      }
      break
    case UPDATE_SUPPLIER_POSITION_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.msg != "" ? action.payload.msg : "",
        msg: null,
        positionUpdate: null,
      }
      break
    case GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE:
      state = {
        ...state,
        loading: true,
        msg: null,
        error: null,
        positionUpdate: null,
        supplierListingForSearch: action.payload,
      }
      break
    case GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: null,
        positionUpdate: null,
        supplierListingForSearch: action.payload,
      }
      break
    case GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED:
      state = { ...state, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default supplierName
