export const COLLECTION_ADD = "COLLECTION_ADD"
export const COLLECTION_SUCCESS = "COLLECTION_SUCCESS"
export const COLLECTION_FAIL = "COLLECTION_FAIL"

export const GET_COLLECTION_LISTING = "GET_COLLECTION_LISTING"
export const GET_COLLECTION_LISTING_SUCCESS = "GET_COLLECTION_LISTING_SUCCESS"
export const GET_COLLECTION_LISTING_FAILED = "GET_COLLECTION_LISTING_FAILED"

export const COLLECTION_EDIT = "COLLECTION_EDIT"
export const COLLECTION_EDIT_SUCCESS = "COLLECTION_EDIT_SUCCESS"
export const COLLECTION_EDIT_FAIL = "COLLECTION_EDIT_FAIL"

export const COLLECTION_UPDATE = "COLLECTION_UPDATE"
export const COLLECTION_UPDATE_SUCCESS = "COLLECTION_UPDATE_SUCCESS"
export const COLLECTION_UPDATE_FAIL = "COLLECTION_UPDATE_FAIL"

export const COLLECTION_DELETE = "COLLECTION_DELETE"
export const COLLECTION_DELETE_SUCCESS = "COLLECTION_DELETE_SUCCESS"
export const COLLECTION_DELETE_FAIL = "COLLECTION_DELETE_FAIL"

export const EXPORTCOLLECTIONCATALOG_DOWNLOAD =
  "EXPORTCOLLECTIONCATALOG_DOWNLOAD"
export const EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS =
  "EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS"
export const EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL =
  "EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL"

export const COLLECTION_COPY = "COLLECTION_COPY"
export const COLLECTION_COPY_SUCCESS = "COLLECTION_COPY_SUCCESS"
export const COLLECTION_COPY_FAIL = "COLLECTION_COPY_FAIL"

export const ADMINEXPORTDOWNLOAD_PROGRESS = "ADMINEXPORTDOWNLOAD_PROGRESS"
export const ADMINEXPORTDOWNLOAD_PROGRESS_SUCCESS =
  "ADMINEXPORTDOWNLOAD_PROGRESS_SUCCESS"
export const ADMINEXPORTDOWNLOAD_PROGRESS_FAIL =
  "ADMINEXPORTDOWNLOAD_PROGRESS_FAIL"

export const ADMIN_ADD_MORE_TO_COLLECTION = "ADMIN_ADD_MORE_TO_COLLECTION"
export const ADMIN_ADD_MORE_TO_COLLECTION_SUCCESS =
  "ADMIN_ADD_MORE_TO_COLLECTION_SUCCESS"
export const ADMIN_ADD_MORE_TO_COLLECTION_FAIL =
  "ADMIN_ADD_MORE_TO_COLLECTION_FAIL"

export const ADMIN_ADD_MORE_TO_COLLECTION_UPDATE =
  "ADMIN_ADD_MORE_TO_COLLECTION_UPDATE"
export const ADMIN_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS =
  "ADMIN_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS"
export const ADMIN_ADD_MORE_TO_COLLECTION_UPDATE_FAIL =
  "ADMIN_ADD_MORE_TO_COLLECTION_UPDATE_FAIL"

export const GET_PRODUCTS_FOR_ADD_COLLECTION = "GET_PRODUCTS_FOR_ADD_COLLECTION"
export const GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS =
  "GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS"
export const GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL =
  "GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL"

export const GET_PRODUCT_OFFER_FOR_ADD_COLLECTION =
  "GET_PRODUCT_OFFER_FOR_ADD_COLLECTION"
export const GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS =
  "GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS"
export const GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL =
  "GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL"

export const REFRESH_COLLECTION_COUNT = "REFRESH_COLLECTION_COUNT"
export const REFRESH_COLLECTION_COUNT_SUCCESS =
  "REFRESH_COLLECTION_COUNT_SUCCESS"
export const REFRESH_COLLECTION_COUNT_FAIL = "REFRESH_COLLECTION_COUNT_FAIL"

export const GET_LAYOUT = "GET_LAYOUT"
export const GET_LAYOUT_SUCCESS = "GET_LAYOUT_SUCCESS"
export const GET_LAYOUT_FAIL = "GET_LAYOUT_FAIL"
