import {
  BD_GET_SUPPLIERNAME_LISTING,
  BD_GET_SUPPLIERNAME_LISTING_SUCCESS,
  BD_GET_SUPPLIERNAME_LISTING_FAILED,
  BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
  BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS,
  BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED,
} from "./actionTypes"

export const bdSupplierNameListing = () => {
  return {
    type: BD_GET_SUPPLIERNAME_LISTING,
  }
}

export const bdSupplierNameListingSuccess = bdSupplierName => {
  return {
    type: BD_GET_SUPPLIERNAME_LISTING_SUCCESS,
    payload: bdSupplierName,
  }
}

export const bdSupplierNameListingFail = error => {
  return {
    type: BD_GET_SUPPLIERNAME_LISTING_FAILED,
    payload: error,
  }
}

export const bdSupplierNameListingForEditOfferSearch = data => {
  return {
    type: BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
    payload: { data: data },
  }
}

export const bdSupplierNameListingForEditOfferSearchPageSuccess =
  bdSupplierListingForSearch => {
    return {
      type: BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS,
      payload: bdSupplierListingForSearch,
    }
  }

export const bdSupplierNameListingForEditOfferSearchPageFail = error => {
  return {
    type: BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED,
    payload: error,
  }
}
