import React, { useState, useRef, useEffect, useMemo, useCallback } from "react"
import BtnCellRenderer from "./BtnCellRenderer.jsx"
import RefreshCellRenderer from "./RefreshCellRenderer.jsx"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"

import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Row,
  Col,
  Container,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
} from "reactstrap"

import {
  collectionsListing,
  deleteCollection,
  coppyCollection,
  updateCollection,
  refreshCollectionCount,
} from "../../../store/actions"

const Collections = props => {
  document.title = "Collection List"

  let key = `${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`
  LicenseManager.setLicenseKey(key)

  const dispatch = useDispatch()

  const [modalOpen, setModalOpen] = useState(false)
  const [copyCollectionModal, setCopyCollectionModal] = useState(false)
  const [collectionNameModal, setCollectionNameModal] = useState(false)
  const [currentCollectionKey, setCurrentCollectionKey] = useState(undefined)

  const defaultCopyCollectionState = {
    id: "",
    collection_name: "",
    collection_type: "",
    meta_data: "",
  }
  const [copyCollectionData, setCopyCollectionData] = useState(
    defaultCopyCollectionState
  )

  function deleteConfirmation(id) {
    setModalOpen(!modalOpen)
    setCurrentCollectionKey(id)
  }

  function updateModalState() {
    let currentStateModal = !modalOpen
    setModalOpen(currentStateModal)
  }

  function collectionCopyState() {
    setCopyCollectionModal(!copyCollectionModal)
  }

  function collectionNameModalState() {
    setCollectionNameModal(!collectionNameModal)
  }

  function updateCopyCollectionModalState(
    collection_type,
    collection_name,
    meta_data
  ) {
    let newData = {
      collection_type: collection_type,
      collection_name: collection_name + "_copy",
      meta_data: "[" + meta_data + "]",
    }

    setCopyCollectionData(prev => {
      return { ...prev, ...newData }
    })
    collectionCopyState()
  }

  function deleteCollectionHandler(id) {
    if (id) {
      dispatch(deleteCollection(id))
      updateModalState()
    }
  }

  function addCopyCollection() {
    let formElements = document.getElementById("addCopyCollection").elements
    if (formElements.length > 0) {
      let addData = {}
      for (let i = 0; i < formElements.length; i++) {
        addData[formElements[i].name] = formElements[i].value
      }
      dispatch(coppyCollection(addData, props.history))
      collectionCopyState()
      setCopyCollectionData(defaultCopyCollectionState)
    }
  }

  function refreshCollectionItemCount(collectionId) {
    if (collectionId) {
      dispatch(refreshCollectionCount(collectionId))
    }
  }

  function updateCollectionNameDetails() {
    let formElements = document.getElementById(
      "updateCollectionNameDetails"
    ).elements
    if (formElements.length > 0) {
      let addData = {}
      for (let i = 0; i < formElements.length; i++) {
        addData[formElements[i].name] = formElements[i].value
      }
      const updateCollectionName = new Promise((resolve, reject) => {
        resolve(dispatch(updateCollection(addData, props.history)))
      })
      updateCollectionName.then(() => {
        dispatch(collectionsListing())
      })
      collectionNameModalState()
      setCopyCollectionData(defaultCopyCollectionState)
    }
  }

  function onUpdateCollectionCopyData(e) {
    const { name, value } = e.target
    const newdata = {
      collection_type: copyCollectionData.collection_type,
      collection_name: value,
      meta_data: copyCollectionData.meta_data,
    }
    setCopyCollectionData(newdata)
  }

  function onUpdateCollectionNameData(e) {
    const { name, value } = e.target
    const newdata = {
      id: copyCollectionData.id,
      collection_type: copyCollectionData.collection_type,
      collection_name: value,
      meta_data: copyCollectionData.meta_data,
    }
    setCopyCollectionData(newdata)
  }

  function updateCollectionNameModalState(
    id,
    collection_type,
    collection_name,
    meta_data
  ) {
    let newData = {
      id: id,
      collection_type: collection_type,
      collection_name: collection_name,
      meta_data: "[" + meta_data + "]",
    }

    setCopyCollectionData(prev => {
      return { ...prev, ...newData }
    })
    collectionNameModalState()
  }

  const gridRef = useRef()
  const rowHeight = 120

  useEffect(() => {
    dispatch(collectionsListing())
  }, [collectionsListing])

  const showAgGridLoading = useCallback(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.showLoadingOverlay()
    }
  }, [])

  const hideAgGridLoading = useCallback(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.hideOverlay()
    }
  }, [])

  const columnDefination = [
    {
      field: "action",
      maxWidth: 300,
      cellRenderer: "BtnCellRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: BtnCellRenderer,
          params: {
            deleteConfirmation: deleteConfirmation,
            updateCopyCollectionModalState: updateCopyCollectionModalState,
            updateCollectionNameModalState: updateCollectionNameModalState,
          },
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Collection Name",
      field: "collection_name",
    },
    {
      headerName: "Collection Type",
      field: "collection_type",
    },
    {
      headerName: "Items in collection",
      cellRenderer: "RefreshCellRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: RefreshCellRenderer,
          params: {
            refreshCollectionItemCount: refreshCollectionItemCount,
          },
        }
        if (params) return buttons
        else return undefined
      },
    },
  ]
  const [columnDefs, setColumnDefs] = useState(columnDefination)
  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      filter: true,
      minWidth: 200,
      resizable: true,
    }),
    []
  )

  const [collectionData, setCollectionData] = useState([])
  let CollectionList = useSelector(state => state.collection.collection)

  useEffect(() => {
    setCollectionData(CollectionList)
    if (columnDefination && collectionData && collectionData.length > 0) {
      gridRef.current.api.setColumnDefs(columnDefination)
    }
  }, [CollectionList])

  const error = useSelector(state => state.collection.error)
  const loading = useSelector(state => state.collection.loading)
  const msg = useSelector(state => state.collection.msg)

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  if (loading) {
    showAgGridLoading()
  } else {
    hideAgGridLoading()
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        {error ? <ToastContainer /> : ""}
        {msg ? <ToastContainer /> : ""}
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container fluid={true}>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: "80vh" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={collectionData}
                  rowHeight={rowHeight}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while we are fetching data</span>'
                  }
                />
              </div>
            </Col>
          </Row>

          <Modal
            isOpen={modalOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="deleteProductConfirm"
            tabIndex="-1"
            backdrop={false}
            toggle={() => updateModalState()}
          >
            <ModalHeader>Remove Collection</ModalHeader>
            <ModalBody>
              Once you delete a collection, all downloaded files related to that
              collection will be deleted from the download list. Do you really
              want to delete collection?
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => deleteCollectionHandler(currentCollectionKey)}
              >
                Yes
              </Button>{" "}
              <Button onClick={() => updateModalState()}>No</Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={copyCollectionModal}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="deleteProductConfirm"
            tabIndex="-1"
            backdrop={false}
            toggle={() => collectionCopyState()}
          >
            <ModalHeader>Copy Collection</ModalHeader>
            <ModalBody>
              <Row>
                <form method="post" id="addCopyCollection">
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="collectionName">
                        Collection name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="hidden"
                        name="meta_data"
                        value={
                          copyCollectionData && copyCollectionData.meta_data
                            ? copyCollectionData.meta_data
                            : ""
                        }
                      />
                      <Input
                        type="hidden"
                        name="collection_type"
                        value={
                          copyCollectionData &&
                          copyCollectionData.collection_type
                            ? copyCollectionData.collection_type
                            : ""
                        }
                      />
                      <Input
                        type="text"
                        name="collection_name"
                        id="collectionName"
                        placeholder="Enter your collection name"
                        autoComplete="off"
                        value={
                          copyCollectionData &&
                          copyCollectionData.collection_name
                            ? copyCollectionData.collection_name
                            : ""
                        }
                        onChange={onUpdateCollectionCopyData}
                      />
                    </div>
                  </Col>
                </form>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => addCopyCollection()}>
                Yes
              </Button>{" "}
              <Button onClick={() => collectionCopyState()}>No</Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={collectionNameModal}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="deleteProductConfirm"
            tabIndex="-1"
            backdrop={false}
            toggle={() => collectionNameModalState()}
          >
            <ModalHeader>Edit Collection Name</ModalHeader>
            <ModalBody>
              <Row>
                <form method="post" id="updateCollectionNameDetails">
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="collectionName">
                        Collection name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="hidden"
                        name="meta_data"
                        value={
                          copyCollectionData && copyCollectionData.meta_data
                            ? copyCollectionData.meta_data
                            : ""
                        }
                      />
                      <Input
                        type="hidden"
                        name="collection_type"
                        value={
                          copyCollectionData &&
                          copyCollectionData.collection_type
                            ? copyCollectionData.collection_type
                            : ""
                        }
                      />
                      <Input
                        type="hidden"
                        name="collection_id"
                        value={
                          copyCollectionData && copyCollectionData.id
                            ? copyCollectionData.id
                            : ""
                        }
                      />
                      <Input
                        type="text"
                        name="collection_name"
                        id="collectionName"
                        placeholder="Edit collection name"
                        autoComplete="off"
                        value={
                          copyCollectionData &&
                          copyCollectionData.collection_name
                            ? copyCollectionData.collection_name
                            : ""
                        }
                        onChange={onUpdateCollectionNameData}
                      />
                    </div>
                  </Col>
                </form>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => updateCollectionNameDetails()}
              >
                Yes
              </Button>{" "}
              <Button onClick={() => collectionNameModalState()}>No</Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Collections
