import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_HEAVEYCOLLI_LISTING,
  ADD_HEAVEY_COLLI,
  CHANGE_HEAVEY_COLLI_STATUS,
  EDIT_HEAVEY_COLLI,
  UPDATE_HEAVEY_COLLI,
  FETCH_HEAVEY_COLLI_BY_SECTION,
  UPDATE_HEAVEY_COLLI_POSITION,
} from "./actionTypes"
import {
  heaveyColliListingSuccess,
  heaveyColliListingFail,
  addHeaveyColliSuccess,
  addHeaveyColliFail,
  changeHeaveyColliStatusSuccess,
  changeHeaveyColliStatusFail,
  editHeaveyColliSuccess,
  editHeaveyColliFail,
  updateHeaveyColliSuccess,
  updateHeaveyColliFail,
  fetchHeaveyColliListingBySectionSuccess,
  fetchHeaveyListingBySectionFail,
  updateHeaveyPositionSuccess,
  updateHeaveyPositionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  heaveyColliListingApi,
  addHeaveyColliApi,
  changeHeaveyColliStatusApi,
  editHeaveyColliApi,
  updateHeaveyColliApi,
  fetchDropdownsBySectionApi,
  fetchDropDownPositionApi,
} from "../../../helpers/fakebackend_helper"

function* getHeaveyColliListing() {
  try {
    const response = yield call(heaveyColliListingApi)
    if (!response.results.heavy_colli) {
      yield put(heaveyColliListingFail(error))
    }
    yield put(heaveyColliListingSuccess(response.results.heavy_colli))
  } catch (error) {
    yield put(heaveyColliListingFail(error))
  }
}

function* addHeavey({ payload: { heaveyColli, history } }) {
  try {
    const response = yield call(addHeaveyColliApi, heaveyColli)
    if (response && response.status) {
      yield put(addHeaveyColliSuccess(response))
      history.push("/dropdown/heavey_colli/list")
    } else {
      throw response.msg ? response.msg : "Failed to add"
    }
  } catch (error) {
    yield put(addHeaveyColliFail(error))
  }
}

function* onHeaveyStatusChange({ payload: { id } }) {
  try {
    const response = yield call(changeHeaveyColliStatusApi, {
      colli_id: id,
    })
    if (response.status) {
      response.id = id
      yield put(changeHeaveyColliStatusSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to change status"
    }
  } catch (error) {
    yield put(changeHeaveyColliStatusFail(error))
  }
}

function* getHeaveyDetailsById({ payload: { id } }) {
  try {
    const response = yield call(editHeaveyColliApi, id)
    if (response && response.status) {
      yield put(editHeaveyColliSuccess(response.results.colli))
    } else {
      throw response.msg ? response.msg : "Failed to edit"
    }
  } catch (error) {
    yield put(editHeaveyColliFail(error))
  }
}

function* onUpdateHeavey({ payload: { heaveyColliUpdate, history } }) {
  try {
    const response = yield call(updateHeaveyColliApi, heaveyColliUpdate)
    if (response && response.status) {
      yield put(updateHeaveyColliSuccess(response))
      history.push("/dropdown/heavey_colli/list")
    } else {
      throw response.msg ? response.msg : "Failed to update"
    }
  } catch (error) {
    yield put(updateHeaveyColliFail(error))
  }
}

function* fetchHeaveyListingBySection({ payload: { data } }) {
  try {
    const response = yield call(fetchDropdownsBySectionApi, data)
    if (!response?.results?.heavy_colli) {
      throw response.msg ? response.msg : "Failed to fetch details"
    }
    yield put(
      fetchHeaveyColliListingBySectionSuccess(response.results.heavy_colli)
    )
  } catch (error) {
    yield put(fetchHeaveyListingBySectionFail(error))
  }
}

function* updateHeaveyPosition({ payload: { data } }) {
  try {
    const response = yield call(fetchDropDownPositionApi, data)
    if (response && response.status) {
      yield put(updateHeaveyPositionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update position"
    }
  } catch (error) {
    let msg = error ? { msg: error } : { msg: "Failed to update position" }
    yield put(updateHeaveyPositionFail(msg))
  }
}

function* heaveyColliSaga() {
  yield takeEvery(GET_HEAVEYCOLLI_LISTING, getHeaveyColliListing)
  yield takeEvery(ADD_HEAVEY_COLLI, addHeavey)
  yield takeEvery(CHANGE_HEAVEY_COLLI_STATUS, onHeaveyStatusChange)
  yield takeEvery(EDIT_HEAVEY_COLLI, getHeaveyDetailsById)
  yield takeEvery(UPDATE_HEAVEY_COLLI, onUpdateHeavey)
  yield takeEvery(FETCH_HEAVEY_COLLI_BY_SECTION, fetchHeaveyListingBySection)
  yield takeEvery(UPDATE_HEAVEY_COLLI_POSITION, updateHeaveyPosition)
}
export default heaveyColliSaga
