import { SUPPLIER_ACTIVE_INACTIVE } from "../suppliers/actionTypes"
import {
  GET_ROLES_LISTING,
  GET_ROLES_LISTING_SUCCESS,
  GET_ROLES_LISTING_FAILED,
  FETCH_ROLES_BY_SECTION,
  FETCH_ROLES_BY_SECTION_SUCCESS,
  FETCH_ROLES_BY_SECTION_FAIL,
  ADD_ROLE,
  ADD_ROLE_FAIL,
  ADD_ROLE_SUCCESS,
  EDIT_ROLE,
  EDIT_ROLE_FAIL,
  EDIT_ROLE_SUCCESS,
  UPDATE_ROLE,
  UPDATE_ROLE_FAIL,
  UPDATE_ROLE_SUCCESS,
  USER_ROLE_ACTIVE_INACTIVE,
  USER_ROLE_ACTIVE_INACTIVE_FAIL,
  USER_ROLE_ACTIVE_INACTIVE_SUCCESS,
  UPDATE_USER_ROLE_POSITION,
  UPDATE_USER_ROLE_POSITION_FAIL,
  UPDATE_USER_ROLE_POSITION_SUCCESS,
} from "./actionTypes"

export const roleListing = () => {
  return {
    type: GET_ROLES_LISTING,
  }
}

export const roleListingSuccess = role => {
  return {
    type: GET_ROLES_LISTING_SUCCESS,
    payload: role,
  }
}

export const roleListingFail = error => {
  return {
    type: GET_ROLES_LISTING_FAILED,
    payload: error,
  }
}

export const fetchRoleListingBySection = data => {
  return {
    type: FETCH_ROLES_BY_SECTION,
    payload: { data },
  }
}

export const fetchRoleListingBySectionSuccess = role => {
  return {
    type: FETCH_ROLES_BY_SECTION_SUCCESS,
    payload: role,
  }
}

export const fetchRoleListingBySectionFail = error => {
  return {
    type: FETCH_ROLES_BY_SECTION_FAIL,
    payload: error,
  }
}

export const editRole = id => {
  return {
    type: EDIT_ROLE,
    payload: { id: id },
  }
}
export const editRoleSuccess = supplier => {
  return {
    type: EDIT_ROLE_SUCCESS,
    payload: supplier,
  }
}
export const editRoleFail = error => {
  return {
    type: EDIT_ROLE_FAIL,
    payload: error,
  }
}

export const updateRole = (updateRole, history) => {
  return {
    type: UPDATE_ROLE,
    payload: { updateRole, history },
  }
}

export const updateRoleSuccess = updateRole => {
  return {
    type: UPDATE_ROLE_SUCCESS,
    payload: updateRole,
  }
}

export const updateRoleFail = error => {
  return {
    type: UPDATE_ROLE_FAIL,
    payload: error,
  }
}

export const changeUserRoleStatus = id => ({
  type: USER_ROLE_ACTIVE_INACTIVE,
  payload: { id: id },
})

export const changeUserRoleStatusSuccess = user => ({
  type: USER_ROLE_ACTIVE_INACTIVE_SUCCESS,
  payload: user,
})

export const changeUserRoleStatusFail = error => ({
  type: USER_ROLE_ACTIVE_INACTIVE_FAIL,
  payload: error,
})

export const updateUserRolePosition = position => ({
  type: UPDATE_USER_ROLE_POSITION,
  payload: { position },
})

export const updateUserRolePositionSuccess = UserRole => ({
  type: UPDATE_USER_ROLE_POSITION_SUCCESS,
  payload: UserRole,
})

export const updateUserRolePositionFail = error => ({
  type: UPDATE_USER_ROLE_POSITION_FAIL,
  payload: error,
})

export const addRole = (data, history) => {
  return {
    type: ADD_ROLE,
    payload: { data, history },
  }
}

export const addRoleSuccess = role => {
  return {
    type: ADD_ROLE_SUCCESS,
    payload: role,
  }
}

export const addRoleFail = error => {
  return {
    type: ADD_ROLE_FAIL,
    payload: error,
  }
}
