import {
  GET_PACKAGING_LISTING,
  GET_PACKAGING_LISTING_SUCCESS,
  GET_PACKAGING_LISTING_FAILED,
  FETCH_PACKAGING_BY_SECTION,
  FETCH_PACKAGING_BY_SECTION_SUCCESS,
  FETCH_PACKAGING_BY_SECTION_FAIL,
  ADD_PACKAGING,
  ADD_PACKAGING_SUCCESS,
  ADD_PACKAGING_FAIL,
  EDIT_PACKAGING,
  EDIT_PACKAGING_SUCCESS,
  EDIT_PACKAGING_FAIL,
  UPDATE_PACKAGING,
  UPDATE_PACKAGING_SUCCESS,
  UPDATE_PACKAGING_FAIL,
  PACKAGING_ACTIVE_INACTIVE,
  PACKAGING_ACTIVE_INACTIVE_SUCCESS,
  PACKAGING_ACTIVE_INACTIVE_FAIL,
  PACKING_POSITION,
  PACKING_POSITION_SUCCESS,
  PACKING_POSITION_FAIL,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  packaging: null,
  msg: null,
  positionUpdate: null,
}

const packaging = (state = initialState, action) => {
  switch (action.type) {
    case GET_PACKAGING_LISTING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
        packaging: action.payload,
      }
      break
    case GET_PACKAGING_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        packaging: action.payload,
      }
      break
    case GET_PACKAGING_LISTING_FAILED:
      state = {
        ...state,
        loading: false,
        msg: null,
        positionUpdate: null,
      }
      break
    case FETCH_PACKAGING_BY_SECTION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
        packagingList: action.payload,
      }
      break
    case FETCH_PACKAGING_BY_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        positionUpdate: null,
        packaging: action.payload,
      }
      break
    case FETCH_PACKAGING_BY_SECTION_FAIL:
      state = { ...state, loading: false }
      break
    case ADD_PACKAGING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        packaging: action.payload,
        packagingEdit: null,
      }
      break
    case ADD_PACKAGING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        packaging: action.payload,
      }
      break
    case ADD_PACKAGING_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload ? action.payload : null,
      }
      break
    case EDIT_PACKAGING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        packagingEdit: action.payload,
      }
      break
    case EDIT_PACKAGING_SUCCESS:
      if (state === null || state.packaging.length === 0) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          packaging: [...state.packaging, action.payload],
        }
      } else {
        state = {
          ...state,
          loading: false,
          packaging: state.packaging.map(i =>
            i.id === action.payload.id ? action.payload : i
          ),
        }
      }
      break
    case EDIT_PACKAGING_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case UPDATE_PACKAGING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        packaging: action.payload,
      }
      break
    case UPDATE_PACKAGING_SUCCESS:
      state = { ...state, error: null, msg: null, loading: false }
      break
    case UPDATE_PACKAGING_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    case PACKAGING_ACTIVE_INACTIVE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break

    case PACKAGING_ACTIVE_INACTIVE_SUCCESS:
      const index = state.packaging.findIndex(
        packaging => packaging.packaging_id === action.payload.id
      )
      let packagingStatus = []
      packagingStatus = [...state.packaging] //making a new array
      packagingStatus[index].status = packagingStatus[index].status == 1 ? 0 : 1

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        packaging: packagingStatus,
      }
      break

    case PACKAGING_ACTIVE_INACTIVE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    case PACKING_POSITION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
      }
      break

    case PACKING_POSITION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: action.payload.msg != "" ? action.payload.msg : "",
        positionUpdate: true,
      }
      break
    case PACKING_POSITION_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.msg != "" ? action.payload.msg : "",
        msg: null,
        positionUpdate: null,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default packaging
