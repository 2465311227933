import {
  PRODUCT_SUCCESS,
  PRODUCT_FAIL,
  PRODUCT_EDIT,
  PRODUCT_EDIT_FAIL,
  PRODUCT_EDIT_SUCCESS,
  PRODUCT_ADD,
  GET_PRODUCTS_LISTING,
  GET_PRODUCTS_LISTING_SUCCESS,
  GET_PRODUCTS_LISTING_FAILED,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_IMAGE_DELETE,
  PRODUCT_IMAGE_DELETE_SUCCESS,
  PRODUCT_IMAGE_DELETE_FAIL,
  PRODUCT_DELETE,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  CHANGE_PRODUCT_STATUS,
  CHANGE_PRODUCT_STATUS_SUCCESS,
  CHANGE_PRODUCT_STATUS_FAIL,
} from "./actionTypes"

export const addNewProduct = (product, history) => {
  return {
    type: PRODUCT_ADD,
    payload: { product, history },
  }
}

export const productSuccess = products => {
  return {
    type: PRODUCT_SUCCESS,
    payload: products,
  }
}

export const productFail = error => {
  return {
    type: PRODUCT_FAIL,
    payload: error,
  }
}

export const editProduct = productId => {
  return {
    type: PRODUCT_EDIT,
    payload: { productId },
  }
}
export const editProductSuccess = product => {
  return {
    type: PRODUCT_EDIT_SUCCESS,
    payload: product,
  }
}
export const editProductFail = error => {
  return {
    type: PRODUCT_EDIT_FAIL,
    payload: error,
  }
}

export const updateProduct = (product, history) => {
  return {
    type: PRODUCT_UPDATE,
    payload: { product, history },
  }
}

export const updateProductSuccess = product => {
  return {
    type: PRODUCT_UPDATE_SUCCESS,
    payload: product,
  }
}

export const updateProductFail = error => {
  return {
    type: PRODUCT_UPDATE_FAIL,
    payload: error,
  }
}

export const productsListing = () => {
  return {
    type: GET_PRODUCTS_LISTING,
  }
}
export const productsListingSuccess = product => {
  return {
    type: GET_PRODUCTS_LISTING_SUCCESS,
    payload: product,
  }
}
export const productsListingFail = error => {
  return {
    type: GET_PRODUCTS_LISTING_FAILED,
    payload: error,
  }
}

export const deleteProductImage = (productImgId, history) => {
  return {
    type: PRODUCT_IMAGE_DELETE,
    payload: { productImgId, history },
  }
}
export const deleteProductImageSuccess = productImg => {
  return {
    type: PRODUCT_IMAGE_DELETE_SUCCESS,
    payload: productImg,
  }
}
export const deleteProductImageFail = error => {
  return {
    type: PRODUCT_IMAGE_DELETE_FAIL,
    payload: error,
  }
}
export const deleteProduct = productId => ({
  type: PRODUCT_DELETE,
  payload: { id: productId },
})

export const deleteProductSuccess = product => ({
  type: PRODUCT_DELETE_SUCCESS,
  payload: product,
})

export const deleteProductFail = error => ({
  type: PRODUCT_DELETE_FAIL,
  payload: error,
})

export const changeProductStatus = productId => ({
  type: CHANGE_PRODUCT_STATUS,
  payload: { id: productId },
})

export const changeProductStatusSuccess = product => ({
  type: CHANGE_PRODUCT_STATUS_SUCCESS,
  payload: product,
})

export const changeProductStatusFail = error => ({
  type: CHANGE_PRODUCT_STATUS_FAIL,
  payload: error,
})
