export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const VALIDATE_TOKEN = "VALIDATE_TOKEN"
export const VALIDATE_TOKEN_ERROR = "VALIDATE_TOKEN_ERROR"
export const VALIDATE_TOKEN_SUCCESS = "VALIDATE_TOKEN_SUCCESS"
