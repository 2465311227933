import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { useHistory } from "react-router-dom"
// Login Redux States
import {
  USER_ADD,
  GET_USERS_LISTING,
  CHANGE_USER_STATUS,
  USER_EDIT,
  USER_UPDATE,
  USER_RESET_PASSWORD,
} from "./actionTypes"
import {
  userSuccess,
  userFail,
  usersListingSuccess,
  usersListingFail,
  changeUserStatusSuccess,
  changeUserStatusFail,
  editUserSuccess,
  editUserFail,
  updateUserSuccess,
  updateUserFail,
  resetUserPasswordFail,
  resetUserPasswordSuccess,
} from "./actions"

import {
  addUser,
  usersListingApi,
  changeUserStatusApi,
  editUserApi,
  updateUserApi,
  adminResetUserPassword,
  // addUserRoleApi
} from "../../../helpers/fakebackend_helper"

function* addNewUser({ payload: { user, history } }) {
  try {
    const response = yield call(addUser, user)
    if (response.status) {
      yield put(userSuccess(response))
      setTimeout(() => {
        history.push("/users/list")
      }, 5000)
    } else {
      throw response.msg
        ? response.msg
        : "Something went wrong. Please try again later"
    }
  } catch (error) {
    yield put(userFail(error))
  }
}

function* getUserListing() {
  try {
    const response = yield call(usersListingApi)
    if (!response.results.user_lists) {
      yield put(usersListingFail(error))
    }
    yield put(usersListingSuccess(response.results.user_lists))
  } catch (error) {
    yield put(usersListingFail(error))
  }
}

function* onUserStatusChange({ payload: { id } }) {
  try {
    const response = yield call(changeUserStatusApi, { user_id: id })
    if (response.status) {
      response.id = id
      yield put(changeUserStatusSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update status"
    }
  } catch (error) {
    yield put(changeUserStatusFail(error))
  }
}

function* getUserDetailsById({ payload: { userId } }) {
  try {
    const response = yield call(editUserApi, userId)
    if (response && response.status) {
      yield put(editUserSuccess(response.results.user_details))
    } else {
      throw response.msg ? response.msg : "Failed to edit user"
    }
  } catch (error) {
    yield put(editUserFail(error))
  }
}

function* onUpdateUser({ payload: { user, history } }) {
  try {
    const response = yield call(updateUserApi, user)
    if (response && response.status) {
      yield put(updateUserSuccess(response))
      setTimeout(() => {
        history.push("/users/list")
      }, 5000)
    } else {
      throw response.msg ? response.msg : "Failed to update user"
    }
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUserResetPassword({ payload: { id } }) {
  try {
    const response = yield call(adminResetUserPassword, { user_id: id })
    if (response.status) {
      yield put(resetUserPasswordSuccess(response))
    } else {
      throw response.msg
        ? response.msg
        : "Something went wrong. Please try again later"
    }
  } catch (error) {
    yield put(resetUserPasswordFail(error))
  }
}

function* userSaga() {
  yield takeEvery(USER_ADD, addNewUser)
  yield takeEvery(GET_USERS_LISTING, getUserListing)
  yield takeEvery(CHANGE_USER_STATUS, onUserStatusChange)
  yield takeEvery(USER_EDIT, getUserDetailsById)
  yield takeEvery(USER_UPDATE, onUpdateUser)
  yield takeEvery(USER_RESET_PASSWORD, onUserResetPassword)
}
export default userSaga
