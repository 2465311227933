import {
    CO_GET_OFFERSTATUS_LISTING,
    CO_GET_OFFERSTATUS_LISTING_SUCCESS,
    CO_GET_OFFERSTATUS_LISTING_FAILED,
  } from "./actionTypes"
  
  const initialState = {
    error: "",
    loading: false,
    offerStatus: null,
  }
  
  const coOfferStatus = (state = initialState, action) => {
    switch (action.type) {
      case CO_GET_OFFERSTATUS_LISTING:
        state = {
          ...state,
          loading: true,
          offerStatus: action.payload,
        }
        break
      case CO_GET_OFFERSTATUS_LISTING_SUCCESS:
        state = { ...state, loading: false, offerStatus: action.payload }
        break
      case CO_GET_OFFERSTATUS_LISTING_FAILED:
        state = { ...state, loading: false }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default coOfferStatus
  