import React from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
  ButtonGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { editSupplier, updateSupplier } from "../../../store/actions"

import { useEffect, useState } from "react"

const EditSupplier = props => {
  const dispatch = useDispatch()
  document.title = "Edit Supplier"

  const { id } = useParams()
  const [details, setDetails] = useState({})

  useEffect(() => {
    dispatch(editSupplier(id))
  }, [])

  const { error, loading, msg, suppliers } = useSelector(state => ({
    msg: state.supplierName.msg,
    suppliers: state.supplierName,
    error: state.supplierName.error,
    loading: state.supplierName.loading,
  }))

  useEffect(() => {
    let supplierName = []
    if (
      suppliers &&
      suppliers.supplierName &&
      suppliers.supplierName.length > 0
    ) {
      supplierName = suppliers.supplierName.filter(s => s.supplier_id == id)
    }
    if (supplierName.length > 0) {
      setDetails(supplierName[0])
    } else {
      // props.history.push("/dropdown/supplier_name/list")
    }
  }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      supplier_id: details && details.supplier_id ? details.supplier_id : "",

      supplier_name:
        details && details.supplier_name ? details.supplier_name : "",

      is_default: details && details.is_default ? 1 : 0,
    },
    validationSchema: Yup.object({
      supplier_name: Yup.string().required("Please enter supplierName"),
    }),
    onSubmit: values => {
      values.supplier_id = id // colli_id is from api thats why this needs to be same with "id"
      dispatch(updateSupplier(values, props.history))
    },
  })

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Edit Supplier Name" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {error ? <ToastContainer /> : ""}
                  <Form
                    id="editsupplierNameForm"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3 mt-1">
                          <Label htmlFor="supplierName">Supplier Name</Label>
                          <Input
                            name="supplier_name"
                            type="text"
                            id="supplierName"
                            // placeholder="Enter supplier name"
                            autoComplete="off"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.supplier_name || ""}
                          />
                          {validation.touched.supplier_name &&
                          validation.errors.supplier_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.supplier_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <div>
                      <h6>Default : </h6>
                      <ButtonGroup>
                        <Button
                          name="is_default"
                          color="success"
                          outline
                          value={1}
                          active={validation.values.is_default == 1}
                          onClick={validation.handleChange}
                        >
                          Yes
                        </Button>

                        <Button
                          name="is_default"
                          color="secondary"
                          outline
                          value={0}
                          active={validation.values.is_default == 0}
                          onClick={validation.handleChange}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </div>
                    <br />
                    <div>
                      {suppliers && suppliers.loading ? (
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          disabled
                        >
                          Updating ...
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary w-md">
                          Update
                        </button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default EditSupplier
