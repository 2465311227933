import {
  GET_OFFERSTATUS_LISTING,
  GET_OFFERSTATUS_LISTING_SUCCESS,
  GET_OFFERSTATUS_LISTING_FAILED,
  FETCH_OFFER_STATUS_BY_SECTION,
  FETCH_OFFER_STATUS_BY_SECTION_SUCCESS,
  FETCH_OFFER_STATUS_BY_SECTION_FAIL,
  ADD_OFFER_STATUS,
  ADD_OFFER_STATUS_SUCCESS,
  ADD_OFFER_STATUS_FAIL,
  EDIT_OFFER_STATUS,
  EDIT_OFFER_STATUS_SUCCESS,
  EDIT_OFFER_STATUS_FAIL,
  UPDATE_OFFER_STATUS,
  UPDATE_OFFER_STATUS_SUCCESS,
  UPDATE_OFFER_STATUS_FAIL,
  OFFER_STATUS_ACTIVE_INACTIVE,
  OFFER_STATUS_ACTIVE_INACTIVE_SUCCESS,
  OFFER_STATUS_ACTIVE_INACTIVE_FAIL,
  OFFER_STATUS_POSITION,
  OFFER_STATUS_POSITION_SUCCESS,
  OFFER_STATUS_POSITION_FAIL,
} from "./actionTypes"

export const offerStatusListing = () => {
  return {
    type: GET_OFFERSTATUS_LISTING,
  }
}

export const offerStatusListingSuccess = offerStatus => {
  return {
    type: GET_OFFERSTATUS_LISTING_SUCCESS,
    payload: offerStatus,
  }
}

export const offerStatusListingFail = error => {
  return {
    type: GET_OFFERSTATUS_LISTING_FAILED,
    payload: error,
  }
}

export const fetchOfferStatusListingBySection = data => {
  return {
    type: FETCH_OFFER_STATUS_BY_SECTION,
    payload: { data },
  }
}

export const fetchOfferStatusListingBySectionSuccess = offerStatus => {
  return {
    type: FETCH_OFFER_STATUS_BY_SECTION_SUCCESS,
    payload: offerStatus,
  }
}

export const fetchOfferStatusListingBySectionFail = error => {
  return {
    type: FETCH_OFFER_STATUS_BY_SECTION_FAIL,
    payload: error,
  }
}

export const addOfferStatus = (data, history) => {
  return {
    type: ADD_OFFER_STATUS,
    payload: { data, history },
  }
}

export const addOfferStatusSuccess = offerStatus => {
  return {
    type: ADD_OFFER_STATUS_SUCCESS,
    payload: offerStatus,
  }
}

export const addOfferStatusFail = error => {
  return {
    type: ADD_OFFER_STATUS_FAIL,
    payload: error,
  }
}

export const editOfferStatus = id => {
  return {
    type: EDIT_OFFER_STATUS,
    payload: { id: id },
  }
}
export const editOfferStatusSuccess = offerStatus => {
  return {
    type: EDIT_OFFER_STATUS_SUCCESS,
    payload: offerStatus,
  }
}
export const editOfferStatusFail = error => {
  return {
    type: EDIT_OFFER_STATUS_FAIL,
    payload: error,
  }
}

export const updateOfferStatus = (updateOfferStatus, history) => {
  return {
    type: UPDATE_OFFER_STATUS,
    payload: { updateOfferStatus, history },
  }
}

export const updateOfferStatusSuccess = updateOfferStatus => {
  return {
    type: UPDATE_OFFER_STATUS_SUCCESS,
    payload: updateOfferStatus,
  }
}

export const updateOfferStatusFail = error => {
  return {
    type: UPDATE_OFFER_STATUS_FAIL,
    payload: error,
  }
}

export const offerStatusChange = id => ({
  type: OFFER_STATUS_ACTIVE_INACTIVE,
  payload: { id: id },
})

export const offerStatusChangeSuccess = offerStatus => ({
  type: OFFER_STATUS_ACTIVE_INACTIVE_SUCCESS,
  payload: offerStatus,
})

export const offerStatusChangeFail = error => ({
  type: OFFER_STATUS_ACTIVE_INACTIVE_FAIL,
  payload: error,
})

export const offerStatusPosition = position => ({
  type: OFFER_STATUS_POSITION,
  payload: { position },
})

export const offerStatusPositionSuccess = packing => ({
  type: OFFER_STATUS_POSITION_SUCCESS,
  payload: packing,
})

export const offerStatusPositionFail = error => ({
  type: OFFER_STATUS_POSITION_FAIL,
  payload: error,
})
