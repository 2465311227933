import React from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
  ButtonGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { editMaterial, updateMaterial } from "../../../store/actions"

import { useEffect, useState } from "react"

const EditMaterial = props => {
  const dispatch = useDispatch()
  document.title = "Edit Material"
  const { id } = useParams()

  const [details, setDetails] = useState({})

  useEffect(() => {
    dispatch(editMaterial(id))
  }, [dispatch])

  const materials = useSelector(state => state.materialStandard)

  useEffect(() => {
    let materialStands = []
    if (
      materials &&
      materials.materialStandard &&
      materials.materialStandard.length > 0
    ) {
      materialStands = materials.materialStandard.filter(
        materialStandard => materialStandard.material_standard_id == id
      )
    }
    if (materialStands.length > 0) {
      setDetails(materialStands[0])
    } else {
      props.history.push("/dropdown/material_standard/list")
    }
  }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      material_standard_id:
        details && details.material_standard_id
          ? details.material_standard_id
          : "",

      material_standard:
        details && details.material_standard ? details.material_standard : "",

      is_default: details && details.is_default ? 1 : 0,
    },
    validationSchema: Yup.object({
      material_standard: Yup.string().required("Please enter material standard"),
    }),
    onSubmit: values => {
      values.material_standard_id = id // colli_id is from api thats why this needs to be same with "id"
      dispatch(updateMaterial(values, props.history))
    },
  })

  const { error } = useSelector(state => ({
    error: state.materialStandard.error,
  }))

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Edit Material Standard" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {error ? <ToastContainer /> : ""}
                  <Form
                    id="editHeaveyForm"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3 mt-1">
                          <Label htmlFor="ms">Material Standard</Label>
                          <Input
                            name="material_standard"
                            type="text"
                            id="ms"
                            // placeholder="Enter material standard"
                            autoComplete="off"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.material_standard || ""}
                          />
                          {validation.touched.material_standard &&
                          validation.errors.material_standard ? (
                            <FormFeedback type="invalid">
                              {validation.errors.material_standard}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <div>
                      <h6>Default : </h6>
                      <ButtonGroup>
                        <Button
                          name="is_default"
                          color="success"
                          outline
                          value={1}
                          active={validation.values.is_default == 1}
                          onClick={validation.handleChange}
                        >
                          Yes
                        </Button>

                        <Button
                          name="is_default"
                          color="secondary"
                          outline
                          value={0}
                          active={validation.values.is_default == 0}
                          onClick={validation.handleChange}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </div>
                    <br />
                    <div>
                      {materials && materials.loading ? (
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          disabled
                        >
                          Updating ...
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary w-md">
                          Update
                        </button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default EditMaterial
