import {
    QA_GET_PRODUCTS_LISTING,
    QA_GET_PRODUCTS_LISTING_SUCCESS,
    QA_GET_PRODUCTS_LISTING_FAILED,
    QA_PRODUCT_EDIT,
    QA_PRODUCT_EDIT_SUCCESS,
    QA_PRODUCT_EDIT_FAIL,
  } from "./actionTypes"
  
  export const qaProductsListing = () => {
      return {
        type: QA_GET_PRODUCTS_LISTING,
      }
    }
    export const qaProductsListingSuccess = product => {
      return {
        type: QA_GET_PRODUCTS_LISTING_SUCCESS,
        payload: product,
      }
    }
    export const qaProductsListingFail = error => {
      return {
        type: QA_GET_PRODUCTS_LISTING_FAILED,
        payload: error,
      }
    }

    export const qaEditProduct = productId => {
      return {
        type: QA_PRODUCT_EDIT,
        payload: { productId },
      }
    }
    export const qaEditProductSuccess = product => {
      return {
        type: QA_PRODUCT_EDIT_SUCCESS,
        payload: product,
      }
    }
    export const qaEditProductFail = error => {
      return {
        type:QA_PRODUCT_EDIT_FAIL,
        payload: error,
      }
    }