import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { useHistory } from "react-router-dom"
// Login Redux States
import {
  PRODUCT_EDIT_CO,
  PRODUCT_ADD_CO,
  GET_PRODUCTS_LISTING_CO,
  PRODUCT_UPDATE_CO,
  PRODUCT_IMAGE_DELETE_CO,
  PRODUCT_DELETE_CO,
  CHANGE_PRODUCT_STATUS_CO,
} from "./actionTypes"
import {
  coProductSuccess,
  coProductFail,
  coProductsListingSuccess,
  coProductsListingFail,
  coEditProductSuccess,
  coEditProductFail,
  coUpdateProductSuccess,
  coUpdateProductFail,
  coDeleteProductImageSuccess,
  coDeleteProductImageFail,
  coDeleteProductSuccess,
  coDeleteProductFail,
  coChangeProductStatusFail,
  coChangeProductStatusSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  addProductCO,
  productsListingApiCO,
  editProductApiCO,
  updateProductApiCO,
  deleteProductImageApiCO,
  deleteProductApiCO,
  changeProductStatusApiCO,
} from "../../../helpers/fakebackend_helper"

function* addNewProductCO({ payload: { product, history } }) {
  try {
    const response = yield call(addProductCO, product)
    if (response && response.status) {
      yield put(coProductSuccess(response))
      setTimeout(() => {
        history.push("/products/list")
      }, 5000)
    } else {
      throw response.msg
        ? response.msg
        : "Something went wrong. Please try again later"
    }
  } catch (error) {
    yield put(coProductFail(error))
  }
}

function* onUpdateProductCO({ payload: { product, history } }) {
  try {
    const response = yield call(updateProductApiCO, product)
    if (response && response.status) {
      yield put(coUpdateProductSuccess(response))
      setTimeout(() => {
        history.push("/products/list")
      }, 5000)
    } else {
      throw response.msg ? response.msg : "Failed to update product"
    }
  } catch (error) {
    yield put(coUpdateProductFail(error))
  }
}

function* getProductDetailsByIdCO({ payload: { productId } }) {
  try {
    const response = yield call(editProductApiCO, productId)
    if (response && response.status) {
      yield put(coEditProductSuccess(response.results.prod_dtls))
    } else {
      throw response.msg ? response.msg : "Failed to edit product"
    }
  } catch (error) {
    yield put(coEditProductFail(error))
  }
}

function* getProductListingCO() {
  try {
    const response = yield call(productsListingApiCO)
    if (!response.results.product_dtls) {
      yield put(coProductsListingFail(error))
    }
    yield put(coProductsListingSuccess(response.results.product_dtls))
  } catch (error) {
    yield put(coProductsListingFail(error))
  }
}

function* onDeleteProductImageCO({ payload: { productImgId, history } }) {
  try {
    const response = yield call(deleteProductImageApiCO, productImgId)
    if (response.status) {
      yield put(coDeleteProductImageSuccess(productImgId))
    } else {
      throw response.msg ? response.msg : "Failed to delete product image"
    }
  } catch (error) {
    yield put(coDeleteProductImageFail(error))
  }
}

function* onDeleteproductCO({ payload: { id } }) {
  try {
    const response = yield call(deleteProductApiCO, { prod_id: id })
    if (response.status) {
      response.id = id
      yield put(coDeleteProductSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to delete product"
    }
  } catch (error) {
    yield put(coDeleteProductFail(error))
  }
}

function* onProductStatusChangeCO({ payload: { id } }) {
  try {
    const response = yield call(changeProductStatusApiCO, { prod_id: id })
    if (response.status) {
      response.id = id
      yield put(coChangeProductStatusSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to change product status"
      }
  } catch (error) {
    yield put(coChangeProductStatusFail(error))
  }
}

function* coProductSaga() {
  yield takeEvery(PRODUCT_ADD_CO, addNewProductCO)
  yield takeEvery(GET_PRODUCTS_LISTING_CO, getProductListingCO)
  yield takeEvery(PRODUCT_EDIT_CO, getProductDetailsByIdCO)
  yield takeEvery(PRODUCT_UPDATE_CO, onUpdateProductCO)
  yield takeEvery(PRODUCT_IMAGE_DELETE_CO, onDeleteProductImageCO)
  yield takeEvery(PRODUCT_DELETE_CO, onDeleteproductCO)
  yield takeEvery(CHANGE_PRODUCT_STATUS_CO, onProductStatusChangeCO)
}
export default coProductSaga
