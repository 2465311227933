import {
  BD_GET_HEAVEYCOLLI_LISTING,
  BD_GET_HEAVEYCOLLI_LISTING_SUCCESS,
  BD_GET_HEAVEYCOLLI_LISTING_FAILED,
  } from "./actionTypes"
  
  export const bdHeaveyColliListing = () => {
    return {
      type: BD_GET_HEAVEYCOLLI_LISTING,
    }
  }
  
  export const bdHeaveyColliListingSuccess = bdHeaveyColli => {
    return {
      type: BD_GET_HEAVEYCOLLI_LISTING_SUCCESS,
      payload: bdHeaveyColli,
    }
  }
  
  export const bdHeaveyColliListingFail = error => {
    return {
      type: BD_GET_HEAVEYCOLLI_LISTING_FAILED,
      payload: error,
    }
  }
  