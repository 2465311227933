import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { BD_GET_MATERIALSTANDARD_LISTING } from "./actionTypes"
import {
  bdMaterialStandardListingSuccess,
  bdMaterialStandardListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { bdMaterialStandardListingApi } from "../../../helpers/fakebackend_helper"

function* getBdMaterialStandardListing() {
  try {
    const response = yield call(bdMaterialStandardListingApi)
    if (!response.results.material_standards) {
      yield put(bdMaterialStandardListingFail(error))
    }
    yield put(bdMaterialStandardListingSuccess(response.results.material_standards))
  } catch (error) {
    yield put(bdMaterialStandardListingFail(error))
  }
}

function* bdMaterialStandardSaga() {
  yield takeEvery(BD_GET_MATERIALSTANDARD_LISTING, getBdMaterialStandardListing)
}
export default bdMaterialStandardSaga
