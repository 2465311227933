export const OFFER_ADD = "OFFER_ADD"
export const OFFER_SUCCESS = "OFFER_SUCCESS"
export const OFFER_FAIL = "OFFER_FAIL"

export const OFFER_EDIT = "OFFER_EDIT"
export const OFFER_EDIT_SUCCESS = "OFFER_EDIT_SUCCESS"
export const OFFER_EDIT_FAIL = "OFFER_EDIT_FAIL"

export const GET_OFFERS_LISTING = "GET_OFFERS_LISTING"
export const GET_OFFERS_LISTING_SUCCESS = "GET_OFFERS_LISTING_SUCCESS"
export const GET_OFFERS_LISTING_FAILED = "GET_OFFERS_LISTING_FAILED"

export const OFFER_DELETE = "OFFER_DELETE"
export const OFFER_DELETE_SUCCESS = "OFFER_DELETE_SUCCESS"
export const OFFER_DELETE_FAIL = "OFFER_DELETE_FAIL"

export const OFFER_UPDATE = "OFFER_UPDATE"
export const OFFER_UPDATE_SUCCESS = "OFFER_UPDATE_SUCCESS"
export const OFFER_UPDATE_FAIL = "OFFER_UPDATE_FAIL"

export const CHANGE_OFFER_STATUS = "CHANGE_OFFER_STATUS"
export const CHANGE_OFFER_STATUS_SUCCESS = "CHANGE_OFFER_STATUS_SUCCESS"
export const CHANGE_OFFER_STATUS_FAIL = "CHANGE_OFFER_STATUS_FAIL"

export const SEARCH_ITEM_IN_NEW_OFFER_PAGE = "SEARCH_ITEM_IN_NEW_OFFER_PAGE"
export const SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS =
  "SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS"
export const SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL =
  "SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL"

export const RESET_OFFER = "RESET_OFFER"
export const RESET_OFFER_SUCCESS = "RESET_OFFER_SUCCESS"
export const RESET_OFFER_FAIL = "RESET_OFFER_FAIL"
