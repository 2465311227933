import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = `${process.env.REACT_APP_API_URL}`
const axiosApi = axios.create({
  baseURL: API_URL,
})

// Axios Intercepters
axiosApi.defaults.headers.common["Authorization"] = token
axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function postLogin(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(error => {
      return error.response.data
    })
}

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data)
    .catch(error => {
      if (error.response.status === 401) {
        logoutSession()
      } else {
        let err = error.response.data
          ? error.response.data
          : "Something went wrong. Please try again."
        return err
      }
    })
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(error => {
      if (error.response.status === 401) {
        logoutSession()
      } else {
        let err = error.response.data
          ? error.response.data
          : "Something went wrong. Please try again."
        return err
      }
    })
}

export async function postFiles(url, data) {
  let newurl = API_URL + url
  return axios({
    method: "post",
    url: newurl,
    data: data,
    headers: { "Content-Type": "multipart/form-data", Authorization: token },
  })
    .then(function (response) {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 401) {
        logoutSession()
      } else {
        let err = error.response.data
          ? error.response.data
          : "Something went wrong. Please try again."
        return err
      }
    })
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(error => {
      if (error.response.status === 401) {
        logoutSession()
      } else {
        let err = error.response.data
          ? error.response.data
          : "Something went wrong. Please try again."
        return err
      }
    })
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(error => {
      if (error.response.status === 401) {
        logoutSession()
      } else {
        let err = error.response.data
          ? error.response.data
          : "Something went wrong. Please try again."
        return err
      }
    })
}

function logoutSession() {
  if (token) {
    sessionStorage.removeItem("authUser")
    window.location.href = "/"
  }
}
