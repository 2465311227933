import {
  GET_OFFICE_LISTING,
  GET_OFFICE_LISTING_SUCCESS,
  GET_OFFICE_LISTING_FAILED,
  FETCH_OFFICE_BY_SECTION,
  FETCH_OFFICE_BY_SECTION_SUCCESS,
  FETCH_OFFICE_BY_SECTION_FAIL,
  ADD_OFFICE,
  ADD_OFFICE_SUCCESS,
  ADD_OFFICE_FAIL,
  EDIT_OFFICE,
  EDIT_OFFICE_SUCCESS,
  EDIT_OFFICE_FAIL,
  UPDATE_OFFICE,
  UPDATE_OFFICE_SUCCESS,
  UPDATE_OFFICE_FAIL,
  OFFICE_ACTIVE_INACTIVE,
  OFFICE_ACTIVE_INACTIVE_SUCCESS,
  OFFICE_ACTIVE_INACTIVE_FAIL,
  OFFICE_POSITION,
  OFFICE_POSITION_SUCCESS,
  OFFICE_POSITION_FAIL,
} from "./actionTypes"

const initialState = {
  error: null,
  loading: false,
  office: [],
  positionUpdate: null,
  msg: null,
}

const office = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFICE_LISTING:
      state = {
        ...state,
        error: null,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
        office: action.payload,
      }
      break
    case GET_OFFICE_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        positionUpdate: null,
        office: action.payload,
      }
      break
    case GET_OFFICE_LISTING_FAILED:
      state = { ...state, loading: false }
      break
    case FETCH_OFFICE_BY_SECTION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
        officeList: action.payload,
      }
      break
    case FETCH_OFFICE_BY_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: null,
        positionUpdate: null,
        office: action.payload,
      }
      break
    case FETCH_OFFICE_BY_SECTION_FAIL:
      state = {
        ...state,
        msg: null,
        positionUpdate: null,
        loading: false,
      }
      break
    case ADD_OFFICE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        office: action.payload,
        officeEdit: null,
      }
      break
    case ADD_OFFICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        office: action.payload,
      }
      break
    case ADD_OFFICE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload ? action.payload : null,
      }
      break
    case EDIT_OFFICE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        officeEdit: action.payload,
      }
      break
    case EDIT_OFFICE_SUCCESS:
      if (state === null || state.office.length === 0) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          office: [...state.office, action.payload],
        }
      } else {
        state = {
          ...state,
          loading: false,
          office: state.office.map(i =>
            i.id === action.payload.id ? action.payload : i
          ),
        }
      }
      break
    case EDIT_OFFICE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case UPDATE_OFFICE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        office: action.payload,
      }
      break
    case UPDATE_OFFICE_SUCCESS:
      state = { ...state, error: null, msg: null, loading: false }
      break
    case UPDATE_OFFICE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    case OFFICE_ACTIVE_INACTIVE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break

    case OFFICE_ACTIVE_INACTIVE_SUCCESS:
      const index = state.office.findIndex(
        office => office.office_id === action.payload.id
      )
      let officeStatus = []
      officeStatus = [...state.office] //making a new array
      officeStatus[index].status = officeStatus[index].status == 1 ? 0 : 1

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        office: officeStatus,
      }
      break

    case OFFICE_ACTIVE_INACTIVE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case OFFICE_POSITION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
      }
      break

    case OFFICE_POSITION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: action.payload.msg != "" ? action.payload.msg : "",
        positionUpdate: true,
      }
      break
    case OFFICE_POSITION_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.msg != "" ? action.payload.msg : "",
        msg: null,
        positionUpdate: null,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default office
