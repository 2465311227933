import React from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
  ButtonGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { editRole, updateRole } from "../../../store/actions"

import { useEffect, useState } from "react"

const EditRole = props => {
  const dispatch = useDispatch()
  document.title = "Edit Role"

  const { id } = useParams()
  const [details, setDetails] = useState({})

  useEffect(() => {
    dispatch(editRole(id))
  }, [])

  const { error, loading, msg, roles } = useSelector(state => ({
    msg: state.Role.msg,
    roles: state.Role,
    error: state.Role.error,
    loading: state.Role.loading,
  }))

  useEffect(() => {
    let rolesArr = []
    if (roles && roles.role && roles.role.length > 0) {
      rolesArr = roles.role.filter(role => role.role_id == id)
    }
    if (rolesArr.length > 0) {
      setDetails(rolesArr[0])
    } else {
      props.history.push("/dropdown/user_role/list")
    }
  }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      role_id: details && details.role_id ? details.role_id : "",
      user_role_name:
        details && details.user_role_name ? details.user_role_name : "",

      user_role_short:
        details && details.user_role_short ? details.user_role_short : "",

      is_default: details && details.is_default ? 1 : 0,
    },
    validationSchema: Yup.object({
      user_role_name: Yup.string().required("Please enter user role"),
      user_role_short: Yup.string().required("Please enter user short"),
    }),
    onSubmit: values => {
      values.role_id = id
      console.log("values ", values)
      dispatch(updateRole(values, props.history))
    },
  })

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Edit User Role" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {error ? <ToastContainer /> : ""}
                  <Form
                    id="editRole"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="user_role_name">
                            User Role Name
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            name="user_role_name"
                            autoComplete="off"
                            className={
                              validation.touched.user_role_name &&
                              validation.errors.user_role_name
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            id="user_role_name"
                            // placeholder="Enter Role Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user_role_name || ""}
                          />
                          {validation.touched.user_role_name &&
                          validation.errors.user_role_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.user_role_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="user_role_short">
                            User Role Short
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            name="user_role_short"
                            autoComplete="off"
                            className={
                              validation.touched.user_role_short &&
                              validation.errors.user_role_short
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            id="user_role_short"
                            // placeholder="Enter role short"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user_role_short || ""}
                          />
                          {validation.touched.user_role_short &&
                          validation.errors.user_role_short ? (
                            <FormFeedback type="invalid">
                              {validation.errors.user_role_short}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <div>
                      <h6>Default : </h6>
                      <ButtonGroup>
                        <Button
                          name="is_default"
                          color="success"
                          outline
                          value={1}
                          active={validation.values.is_default == 1}
                          onClick={validation.handleChange}
                        >
                          Yes
                        </Button>

                        <Button
                          name="is_default"
                          color="secondary"
                          outline
                          value={0}
                          active={validation.values.is_default == 0}
                          onClick={validation.handleChange}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </div>
                    <br />
                    <div>
                      {roles && roles.loading ? (
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          disabled
                        >
                          Updating ...
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary w-md">
                          Update
                        </button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default EditRole
