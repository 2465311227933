import {
  BD_GET_PRODUCT_NUMBER_LISTING,
  BD_GET_PRODUCT_NUMBER_LISTING_SUCCESS,
  BD_GET_PRODUCT_NUMBER_LISTING_FAILED,
  } from "./actionTypes"

  export const bdProductNumberListing = () => {
    return {
      type: BD_GET_PRODUCT_NUMBER_LISTING,
    }
  }
  
  export const bdProductNumberListingSuccess = bdProductNumber => {
    return {
      type: BD_GET_PRODUCT_NUMBER_LISTING_SUCCESS,
      payload: bdProductNumber,
    }
  }
  
  export const bdProductNumberListingFail = error => {
    return {
      type: BD_GET_PRODUCT_NUMBER_LISTING_FAILED,
      payload: error,
    }
  }