import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import React, { useState, useRef, useEffect, useMemo, useCallback } from "react"
import "./exports.css"
import { Row, Col, Container } from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { adminExportDownloadProgress } from "../../../store/actions"

const Downloads = () => {
  const dispatch = useDispatch()

  let key = `${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`
  LicenseManager.setLicenseKey(key)

  document.title = "Downloads"

  const gridRef = useRef()

  const [rowData, setRowData] = useState()

  useEffect(() => {
    dispatch(adminExportDownloadProgress())
  }, [])

  let exportDownload = useSelector(
    state => state.collection.adminExportDownloadProgress
  )

  function collectionWithCountGetter(params) {
    if (params.data.collection_name) {
      return params.data.collection_name + " (" + params.data.item_count + ")"
    } else {
      return undefined
    }
  }

  const columnDefination = [
    {
      headerName: "Collection",
      field: "collection_name",
      filter: "agSetColumnFilter",
      valueGetter: collectionWithCountGetter,
    },
    {
      headerName: "Export",
      field: "export_type",
      autoHeight: true,
      filter: "agSetColumnFilter",
      cellStyle: { whiteSpace: "normal" },
      cellRendererSelector: params => {
        if (params.data.export_type) {
          return (
            <p title={params.data.export_type} className="text-center">
              {params.data.export_type}
            </p>
          )
        } else {
          return undefined
        }
      },
    },
    {
      headerName: "Price to export",
      field: "price_to_export",
      filter: "agSetColumnFilter",
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agSetColumnFilter",
      // cellStyle: { textAlign: "center" },
      cellRenderer: params => {
        return (
          <span className="badge bg-primary">
            {params.data.status ? params.data.status : ""}
          </span>
        )
      },
    },
    {
      headerName: "Download",
      field: "downloaded_path",
      filter: "agSetColumnFilter",
      cellStyle: { textAlign: "center" },
      cellRenderer: params => {
        if (params.data.downloaded_path) {
          return (
            <a
              className="text-center cursor-pointer font_size_large"
              target="_blank"
              rel="noreferrer"
              href={params.data.downloaded_path}
            >
              <i className="bx bx-cloud-download cursor-pointer font_size_large"></i>
            </a>
          )
        } else {
          return undefined
        }
      },
    },
  ]
  const [columnDefs, setColumnDefs] = useState(columnDefination)

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      filter: true,
      minWidth: 200,
      resizable: true,
    }),
    []
  )
  const onGridReady = useCallback(params => {
    // remove previous records
    setRowData([])
    // access the Grid API
    gridRef?.api?.deselectAll()

    gridRef?.current?.api?.setFilterModel(null)
    // access the Grid Column API
    gridRef?.columnApi?.resetColumnState()

    // refresh the grid
    gridRef?.api?.redrawRows()
  }, [])

  useEffect(() => {
    if (
      exportDownload &&
      exportDownload.data &&
      exportDownload.data.export_progress.length > 0
    ) {
      setRowData(exportDownload.data.export_progress)
    }
  }, [exportDownload])

  const error = useSelector(state => state.collection.error)
  const loading = useSelector(state => state.collection.loading)
  const msg = useSelector(state => state.collection.msg)

  const showAgGridLoading = useCallback(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.showLoadingOverlay()
    }
  }, [])

  const hideAgGridLoading = useCallback(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.hideOverlay()
    }
  }, [])

  if (loading) {
    showAgGridLoading()
  } else {
    hideAgGridLoading()
  }

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container fluid={true}>
          <Row>
            <Col md={12} lg={12} xl={12}>
              {error ? <ToastContainer /> : ""}
              {msg ? <ToastContainer /> : ""}
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: "80vh" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  animateRows={true}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while we are fetching data</span>'
                  }
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Downloads
