export const CO_OFFER_ADD = "CO_OFFER_ADD"
export const CO_OFFER_SUCCESS = "CO_OFFER_SUCCESS"
export const CO_OFFER_FAIL = "CO_OFFER_FAIL"

export const CO_OFFER_EDIT = "CO_OFFER_EDIT"
export const CO_OFFER_EDIT_SUCCESS = "CO_OFFER_EDIT_SUCCESS"
export const CO_OFFER_EDIT_FAIL = "CO_OFFER_EDIT_FAIL"

export const CO_GET_OFFERS_LISTING = "CO_GET_OFFERS_LISTING"
export const CO_GET_OFFERS_LISTING_SUCCESS = "CO_GET_OFFERS_LISTING_SUCCESS"
export const CO_GET_OFFERS_LISTING_FAILED = "CO_GET_OFFERS_LISTING_FAILED"

export const CO_OFFER_DELETE = "CO_OFFER_DELETE"
export const CO_OFFER_DELETE_SUCCESS = "CO_OFFER_DELETE_SUCCESS"
export const CO_OFFER_DELETE_FAIL = "CO_OFFER_DELETE_FAIL"

export const CO_OFFER_UPDATE = "CO_OFFER_UPDATE"
export const CO_OFFER_UPDATE_SUCCESS = "CO_OFFER_UPDATE_SUCCESS"
export const CO_OFFER_UPDATE_FAIL = "CO_OFFER_UPDATE_FAIL"

export const CO_CHANGE_OFFER_STATUS = "CO_CHANGE_OFFER_STATUS"
export const CO_CHANGE_OFFER_STATUS_SUCCESS = "CO_CHANGE_OFFER_STATUS_SUCCESS"
export const CO_CHANGE_OFFER_STATUS_FAIL = "CO_CHANGE_OFFER_STATUS_FAIL"

export const CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE = "CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE"
export const CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS = "CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS"
export const CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL = "CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL"

export const CO_RESET_OFFER = "CO_RESET_OFFER"
export const CO_RESET_OFFER_SUCCESS = "CO_RESET_OFFER_SUCCESS"
export const CO_RESET_OFFER_FAIL = "CO_RESET_OFFER_FAIL"