import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//admin Dashboard
import Dashboard from "./dashboard/reducer"
//admin User
import User from "./admin/users/reducer"
import Office from "./admin/office/reducer"
import Role from "./admin/roles/reducer"

//admion products
import Product from "./admin/products/reducers"
import ProductCategory from "./admin/productCategory/reducers"
import QualityRating from "./admin/qualityRating/reducers"
import construction from "./admin/constructions/reducers"
import productNumber from "./admin/productNumber/reducers"
import createdBy from "./admin/createdBy/reducers"

// admin log
import Log from "./admin/logs/reducers"

// admin offers
import offer from "./admin/offers/reducers"
import offerStatus from "./admin/offerStatus/reducers"
import supplierName from "./admin/suppliers/reducers"
import incotermCity from "./admin/incotermCity/reducers"
import materialStandard from "./admin/materialStandard/reducers"
import packingStandard from "./admin/packingStandard/reducers"
import environmentalAndHealthCertification from "./admin/environmentalAndHealthCertification/reducers"
import packaging from "./admin/packaging/reducers"
import deliveredOn from "./admin/deliveredOn/reducers"
import heaveyColli from "./admin/heaveyColli/reducers"
import userListAccordingRole from "./admin/userListAccordingRole/reducers"

//admin collection
import collection from "./admin/export/reducers"

//bd product
import bdProduct from "./business-developer/bd-product/reducers"

//bd offer
import bdOffer from "./business-developer/bd-offer/reducers"

import bdDeliveredOn from "./business-developer/bd-deliveredOn/reducers"
import bdEnvironmentalAndHealthCertification from "./business-developer/bd-environmentalAndHealthCertification/reducers"
import bdHeaveyColli from "./business-developer/bd-heaveyColli/reducers"
import bdIncotermCity from "./business-developer/bd-incotermCity/reducers"
import bdMaterialStandard from "./business-developer/bd-materialStandard/reducers"
import bdPackaging from "./business-developer/bd-packaging/reducers"
import bdOfferStatus from "./business-developer/bd-offerStatus/reducers"
import bdPackingStandard from "./business-developer/bd-packingStandard/reducers"
import bdSupplierName from "./business-developer/bd-suppliers/reducers"
import bdUserListAccordingRole from "./business-developer/bd-userListAccordingRole/reducers"
import bdCreatedBy from "./business-developer/bd-createdBy/reducers"
import bdProductCategory from "./business-developer/bd-productCategory/reducers"
import bdProductNumber from "./business-developer/bd-productNumber/reducers"

// profile
import profile from "./auth/profile/reducer"

//bd collection and export
import bdCollection from "./business-developer/bd-export/reducers"

// co product
import coProduct from "./co-ordinator/co-products/reducers"
import coProductCategory from "./co-ordinator/co-productCategory/reducers"
import coQualityRating from "./co-ordinator/co-qualityRating/reducers"
import coConstruction from "./co-ordinator/co-constructions/reducers"

//co offer
import coOffer from "./co-ordinator/co-offer/reducers"
import coDeliveredOn from "./co-ordinator/co-deliveredOn/reducers"
import coEnvironmentalAndHealthCertification from "./co-ordinator/co-environmentalAndHealthCertification/reducers"
import coHeaveyColli from "./co-ordinator/co-heaveyColli/reducers"
import coIncotermCity from "./co-ordinator/co-incotermCity/reducers"
import coMaterialStandard from "./co-ordinator/co-materialStandard/reducers"
import coOfferStatus from "./co-ordinator/co-offerStatus/reducers"
import coPackaging from "./co-ordinator/co-packaging/reducers"
import coPackingStandard from "./co-ordinator/co-packingStandard/reducers"
import coSupplierName from "./co-ordinator/co-suppliers/reducers"
import coUserListAccordingRole from "./co-ordinator/co-userListAccordingRole/reducers"
import coCreatedBy from "./co-ordinator/co-createdBy/reducers"
import coProductNumber from "./co-ordinator/co-productNumber/reducers"

//co export
import coCollection from "./co-ordinator/co-export/reducers"

//sa product
import saProduct from "./sales-manager/sa-product/reducers"

//sa offer
import saOffer from "./sales-manager/sa-offer/reducers"

//sa export collection
import saCollection from "./sales-manager/sa-export/reducers"

//qa product
import qaProduct from "./quality-assurance/qa-product/reducers"

//qa offer
import qaOffer from "./quality-assurance/qa-offer/reducers"
import qaUserListAccordingRole from "./quality-assurance/qa-userListAccordingRole/reducers"

//qa export collection
import qaCollection from "./quality-assurance/qa-export/reducers"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  User,
  Role,
  Office,
  Dashboard,
  ProductCategory,
  productNumber,
  createdBy,
  Product,
  QualityRating,
  Log,
  offer,
  offerStatus,
  supplierName,
  incotermCity,
  materialStandard,
  packingStandard,
  environmentalAndHealthCertification,
  packaging,
  deliveredOn,
  heaveyColli,
  userListAccordingRole,
  collection,
  bdProduct,
  bdOffer,
  profile,
  bdDeliveredOn,
  bdEnvironmentalAndHealthCertification,
  bdHeaveyColli,
  bdIncotermCity,
  bdMaterialStandard,
  bdPackaging,
  bdCreatedBy,
  bdProductNumber,
  bdProductCategory,
  bdOfferStatus,
  bdPackingStandard,
  bdSupplierName,
  bdUserListAccordingRole,
  bdCollection,
  coProduct,
  coOffer,
  coDeliveredOn,
  coCreatedBy,
  coEnvironmentalAndHealthCertification,
  coHeaveyColli,
  coIncotermCity,
  coMaterialStandard,
  coOfferStatus,
  coPackaging,
  coPackingStandard,
  coProductNumber,
  coProductCategory,
  coQualityRating,
  coSupplierName,
  coUserListAccordingRole,
  coCollection,
  qaProduct,
  qaOffer,
  qaUserListAccordingRole,
  qaCollection,
  saProduct,
  saOffer,
  saCollection,
  construction,
  coConstruction,
})

export default rootReducer
