import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./products.css"

function BtnCellRenderer(props) {
  return (
    <>
      <div className="row">
        <div className="col">
          <Link
            to={`edit/` + props.data.id}
            className="text-decoration-none"
          >
            <i
              className="bx bx-edit cursor-pointer font_size_large"
              title="Edit Environment and Health Certification"
            ></i>
          </Link>
        </div>
      </div>
    </>
  )
}

export default BtnCellRenderer
