import {
  GET_LOGS_LISTING,
  GET_LOGS_LISTING_SUCCESS,
  GET_LOGS_LISTING_FAILED,
  GET_ACTIVITY_LOG,
  GET_ACTIVITY_LOG_SUCCESS,
  GET_ACTIVITY_LOG_FAILED
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  log: null,
}

const log = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGS_LISTING:
      state = {
        ...state,
        loading: true,
        payload: action.payload,
      }
      break
    case GET_LOGS_LISTING_SUCCESS:
      state = { ...state, loading: false, log: action.payload }
      break
    case GET_LOGS_LISTING_FAILED:
      state = { ...state, loading: false }
      break
    default:
      state = { ...state }
      break
      case GET_ACTIVITY_LOG:
        state = {
          ...state,
          loading: true,
          payload: action.payload,
        }
        break
      case GET_ACTIVITY_LOG_SUCCESS:
        state = { ...state, loading: false, log: action.payload }
        break
      case GET_ACTIVITY_LOG_FAILED:
        state = { ...state, loading: false }
        break
  }
  return state
}

export default log
