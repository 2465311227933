import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { CO_GET_DELIVEREDON_LISTING } from "./actionTypes"
import {
  coDeliveredOnListingSuccess,
  coDeliveredOnListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { coDeliveredOnListingApi } from "../../../helpers/fakebackend_helper"

function* coGetDeliveredOnListing() {
  try {
    const response = yield call(coDeliveredOnListingApi)
    if (!response.results.delivers) {
      yield put(coDeliveredOnListingFail(error))
    }
    yield put(coDeliveredOnListingSuccess(response.results.delivers))
  } catch (error) {
    yield put(coDeliveredOnListingFail(error))
  }
}

function* coDeliveredOnSaga() {
  yield takeEvery(CO_GET_DELIVEREDON_LISTING, coGetDeliveredOnListing)
}
export default coDeliveredOnSaga
