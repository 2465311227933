import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_PRODUCTCATEGORY_LISTING,
  ADD_PRODUCT_CATEGORY,
  CHANGE_PRODUCT_CATEGORY_STATUS,
  EDIT_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
  FETCH_PRODUCT_CATEGORY_BY_SECTION,
  UPDATE_PRODUCT_CATEGORY_POSITION,
} from "./actionTypes"
import {
  productCategoryListingSuccess,
  productCategoryListingFail,
  addProductCategorySuccess,
  addProductCategoryFail,
  changeProductCategoryStatusSuccess,
  changeProductCategoryStatusFail,
  editProductCategorySuccess,
  editProductCategoryFail,
  updateProductCategorySuccess,
  updateProductCategoryFail,
  fetchProductCategoryListingBySectionSuccess,
  fetchProductCategoryListingBySectionFail,
  updateProductCategoryPositionSuccess,
  updateProductCategoryPositionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  productCategoryListingApi,
  addProductCategoryApi,
  changeProductCategoryStatusApi,
  editProductCategoryApi,
  updateProductCategoryApi,
  fetchDropdownsBySectionApi,
  fetchDropDownPositionApi,
} from "../../../helpers/fakebackend_helper"

function* getproductCategoryListing() {
  try {
    const response = yield call(productCategoryListingApi)
    if (!response.results.prod_cat) {
      yield put(productCategoryListingFail(error))
    }
    yield put(productCategoryListingSuccess(response.results.prod_cat))
  } catch (error) {
    yield put(productCategoryListingFail(error))
  }
}

function* addProductCategory({ payload: { productCategory, history } }) {
  try {
    const response = yield call(addProductCategoryApi, productCategory)
    if (response && response.status) {
      yield put(addProductCategorySuccess(response))
      history.push("/dropdown/product_category/list")
    } else {
      throw response.msg ? response.msg : "Failed to add product category"
    }
  } catch (error) {
    yield put(addProductCategoryFail(error))
  }
}

function* onProductCategoryStatusChange({ payload: { id } }) {
  try {
    const response = yield call(changeProductCategoryStatusApi, {
      category_id: id,
    })
    if (response.status) {
      response.id = id
      yield put(changeProductCategoryStatusSuccess(response))
    } else {
      throw response.msg
        ? response.msg
        : "Failed to change product category status"
    }
  } catch (error) {
    yield put(changeProductCategoryStatusFail(error))
  }
}

function* getProductCategoryDetailsById({ payload: { id } }) {
  try {
    const response = yield call(editProductCategoryApi, id)
    if (response && response.status) {
      yield put(editProductCategorySuccess(response.results.product_category))
    } else {
      throw response.msg ? response.msg : "Failed to edit product category"
    }
  } catch (error) {
    yield put(editProductCategoryFail(error))
  }
}

function* onUpdateProductCategory({ payload: { productCategory, history } }) {
  try {
    const response = yield call(updateProductCategoryApi, productCategory)
    if (response && response.status) {
      yield put(updateProductCategorySuccess(response))
      history.push("/dropdown/product_category/list")
    } else {
      throw response.msg ? response.msg : "Failed to update product category"
    }
  } catch (error) {
    yield put(updateProductCategoryFail(error))
  }
}

function* fetchProductCategoryListingBySection({ payload: { data } }) {
  try {
    const response = yield call(fetchDropdownsBySectionApi, data)
    if (!response?.results?.prod_cat) {
      throw response.msg ? response.msg : "Failed to fetch details"
    }
    yield put(
      fetchProductCategoryListingBySectionSuccess(response.results.prod_cat)
    )
  } catch (error) {
    yield put(fetchProductCategoryListingBySectionFail(error))
  }
}

function* updateProductCategoryPosition({ payload: { data } }) {
  try {
    const response = yield call(fetchDropDownPositionApi, data)
    if (response && response.status) {
      yield put(updateProductCategoryPositionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update position"
    }
  } catch (error) {
    let msg = error ? { msg: error } : { msg: "Failed to update position" }
    yield put(updateProductCategoryPositionFail(msg))
  }
}

function* productCategorySaga() {
  yield takeEvery(GET_PRODUCTCATEGORY_LISTING, getproductCategoryListing)
  yield takeEvery(ADD_PRODUCT_CATEGORY, addProductCategory)
  yield takeEvery(CHANGE_PRODUCT_CATEGORY_STATUS, onProductCategoryStatusChange)
  yield takeEvery(EDIT_PRODUCT_CATEGORY, getProductCategoryDetailsById)
  yield takeEvery(UPDATE_PRODUCT_CATEGORY, onUpdateProductCategory)
  yield takeEvery(
    FETCH_PRODUCT_CATEGORY_BY_SECTION,
    fetchProductCategoryListingBySection
  )
  yield takeEvery(
    UPDATE_PRODUCT_CATEGORY_POSITION,
    updateProductCategoryPosition
  )
}
export default productCategorySaga
