import React, { useState, useRef, useEffect } from "react"
import {
  Container,
  Row,
  Form,
  Input,
  Label,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Card,
  CardBody,
  FormGroup,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
//  import { FontAwesomeIcon } from "@fortawesome/fontawesome-free"

import {
  collectionsListing,
  downloadExportCollectionCatalog,
  getLayOut,
} from "../../../store/actions"

function ExportDownload(props) {
  document.title = "Export Download"
  const dispatch = useDispatch()

  // for admin start here
  const [currentData, setCurrentData] = useState([])
  const [layoutData, setLayoutData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const CollectionList = useSelector(state => state.collection.collection)
  const exportData = useSelector(state => state.collection.exportCollection)
  const layoutListing = useSelector(state => state.collection.export_layout)

  const adminInitialDetails = {
    export_type: null,
    price_to_export: null,
    collection_id: null,
    layout_details: false,
    layout_id: null,
  }
  const [adminDetails, setAdminDetails] = useState(adminInitialDetails)

  function downloadData(e) {
    setModalOpen(true)
    dispatch(downloadExportCollectionCatalog(adminDetails, props.history))
    e.preventDefault()
  }

  function handleExportSelection(e) {
    const { name, value } = e.target
    let newState = {}
    newState = adminDetails
    if (name === "export_selection") {
      let price_to_export = document.getElementsByClassName("price_to_export")
      for (let i = 0; i < price_to_export.length; i++) {
        price_to_export[i].checked = false
      }

      newState.export_type = value
      newState.price_to_export = null
      newState.collection_id = null
      newState.layout_details = false
      newState.layout_id = null
      setLayoutData([])
      if (value == "2") {
        // product_without_prices
        newState.type = "product"
        // newState.layout_details = true
        // setLayoutData([])
        dispatch(collectionsListing())
        dispatch(getLayOut())
      } else if (value == "3") {
        newState.type = "offer"
        newState.price_to_export = "3"
        dispatch(collectionsListing())
      } else {
        // newState.export_type = ''
        newState.type = null
      }
    } else if (name === "price_to_export") {
      newState.type = null
      newState.collection_id = null
      newState.type = "offer"
      newState.price_to_export = value
      const dropdown = document.getElementById("offer_product_select")
      if (dropdown) {
        dropdown.selectedIndex = 0
      } // item is not selected in collection dropdown if a user change their proprity (ex- user change sales price fsc to purchase price non fsc)
      setLayoutData([])
      dispatch(collectionsListing())
      dispatch(getLayOut())
    } else if (name === "collection") {
      newState.collection_id = value
      newState.layout_details = true
      setLayoutData([])
      dispatch(collectionsListing())
      dispatch(getLayOut())
    }

    if (name == "layout_id") {
      newState.layout_id = value
    }

    setAdminDetails(prevState => ({
      ...prevState,
      ...newState,
    }))
  }

  useEffect(() => {
    if (CollectionList && CollectionList.length > 0) {
      setCurrentData(CollectionList)
    }
    if (
      layoutListing &&
      layoutListing.layout &&
      layoutListing.layout.length > 0
    ) {
      setLayoutData(layoutListing.layout)
    }
  }, [adminDetails, CollectionList, layoutListing])

  const { error, loading } = useSelector(state => ({
    error: state.collection.error,
    loading: state.collection.loading,
  }))

  function moveToDownload() {
    setModalOpen(!modalOpen)
    props.history.push("/export/download")
  }

  function updateModalState() {
    let currentStateModal = !modalOpen
    setModalOpen(currentStateModal)
  }

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Collections" breadcrumbItem="Export" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {error ? <ToastContainer /> : ""}

                  <Form id="exportForm" method="post">
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <FormGroup check inline>
                            <Input
                              type="radio"
                              className="form-check-input"
                              name="export_selection"
                              value="1"
                              onChange={e => handleExportSelection(e)}
                              id="export_selection_admin_product_with_prices"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="export_selection_admin_product_with_prices"
                            >
                              Export Product catalogue <b>with prices</b> based
                              on offers ?
                            </Label>
                          </FormGroup>
                          <br />
                          <FormGroup check inline>
                            <Input
                              type="radio"
                              className="form-check-input"
                              name="export_selection"
                              value="2"
                              onChange={e => handleExportSelection(e)}
                              id="export_selection_admin_product_without_prices"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="export_selection_admin_product_without_prices"
                            >
                              Export Product catalogue <b>without prices</b>{" "}
                              based on products ?
                            </Label>
                          </FormGroup>
                          <br />
                          <FormGroup check inline>
                            <Input
                              type="radio"
                              className="form-check-input"
                              name="export_selection"
                              id="export_selection_admin_jysk_format"
                              value="3"
                              // placeholder="jysk"
                              onChange={e => handleExportSelection(e)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="export_selection_admin_jysk_format"
                            >
                              Export product list to JYSK format (Excel)
                            </Label>
                          </FormGroup>
                        </div>
                      </Col>

                      {adminDetails.export_type ? (
                        adminDetails.export_type !== "2" &&
                        adminDetails.export_type !== "3" ? (
                          <Col md={12}>
                            <div className="with_or_without_prices">
                              <hr />
                              <strong>
                                <i>Select price to export</i>
                              </strong>
                              <br />
                              <br />
                              <FormGroup check inline>
                                <Input
                                  type="radio"
                                  value="sales_price_fsc"
                                  className="form-check-input price_to_export-sales"
                                  name="price_to_export"
                                  onChange={handleExportSelection}
                                  id="price_export_admin_sales_price"
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="price_export_admin_sales_price"
                                >
                                  Sales Price (FSC)
                                </Label>
                              </FormGroup>
                              <br />
                              {/* <FormGroup check inline>
                                <Input
                                  type="radio"
                                  value="sales_price_non_fsc"
                                  className="form-check-input price_to_export-sales"
                                  name="price_to_export"
                                  onChange={handleExportSelection}
                                  id="price_export_admin_sales_price_non_fsc"
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="price_export_admin_sales_price_non_fsc"
                                >
                                  Sales Price (Non-FSC)
                                </Label>
                              </FormGroup>
                              <br /> */}
                              <FormGroup check inline>
                                <Input
                                  type="radio"
                                  value="purchase_price_fsc"
                                  className="form-check-input price_to_export-purchase"
                                  name="price_to_export"
                                  onChange={handleExportSelection}
                                  id="price_export_admin_purchase_price"
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="price_export_admin_purchase_price"
                                >
                                  Purchase Price (FSC)
                                </Label>
                              </FormGroup>
                              <br />
                              {/* <FormGroup check inline>
                                <Input
                                  type="radio"
                                  value="purchase_price_non_fsc "
                                  className="form-check-input price_to_export-purchase"
                                  name="price_to_export"
                                  onChange={handleExportSelection}
                                  id="price_export_admin_purchase_price_non_fsc"
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="price_export_admin_purchase_price_non_fsc"
                                >
                                  Purchase Price (Non-FSC)
                                </Label>
                              </FormGroup>
                              <br /> */}
                            </div>
                          </Col>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}

                      {adminDetails.price_to_export ? (
                        adminDetails.price_to_export !== "product" ? (
                          <Col md={12}>
                            <div
                              className="collection"
                              id="offer_product_collection"
                            >
                              <hr />
                              <strong>
                                <i>Select offer collection</i>
                              </strong>
                              <br />
                              <br />
                              <select
                                name="collection"
                                className="form-control"
                                onChange={handleExportSelection}
                                id="offer_product_select"
                              >
                                <option value="">Choose...</option>
                                {currentData &&
                                  currentData.map((cr, pc) =>
                                    cr.collection_type == "offer" ? (
                                      <option key={"op" + pc} value={cr.id}>
                                        {cr.collection_name +
                                          " (" +
                                          cr.item_count +
                                          ")"}
                                      </option>
                                    ) : (
                                      ""
                                    )
                                  )}
                              </select>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}

                      {adminDetails &&
                      adminDetails.price_to_export === null &&
                      adminDetails.type == "product" ? (
                        <Col md={12}>
                          <div
                            className="collection"
                            id="offer_product_collection"
                          >
                            <hr />
                            <strong>
                              <i>Select product collection</i>
                            </strong>
                            <br />
                            <br />
                            <select
                              name="collection"
                              className="form-control"
                              onChange={handleExportSelection}
                            >
                              <option value="">Choose...</option>
                              {currentData &&
                                currentData.map((cr, pc) =>
                                  cr.collection_type == "product" ? (
                                    <option key={"op" + pc} value={cr.id}>
                                      {cr.collection_name +
                                        " (" +
                                        cr.item_count +
                                        ")"}
                                    </option>
                                  ) : (
                                    ""
                                  )
                                )}
                            </select>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {adminDetails.export_type == "1" &&
                      ["sales_price_fsc"].includes(
                        adminDetails.price_to_export
                      ) &&
                      adminDetails.collection_id &&
                      layoutData.length ? (
                        <Col md={12}>
                          <div className="with_or_without_prices">
                            <hr />
                            <strong>
                              <i>Select export format</i>
                            </strong>
                            <br />
                            <br />
                            {layoutData.map(function (ly, li) {
                              return (
                                <>
                                  <FormGroup
                                    check
                                    inline
                                    key={"layout_image_section" + ly.id}
                                  >
                                    <img
                                      src={ly.image}
                                      alt={ly.layout}
                                      width="150"
                                      height="150"
                                      className="img-thumbnail"
                                    ></img>
                                    <Input
                                      type="radio"
                                      name="layout_id"
                                      value={ly.id}
                                      className="form-check-input"
                                      onChange={handleExportSelection}
                                      id={"image_layout" + ly.id}
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor={"image_layout" + ly.id}
                                    >
                                      {ly.layout}
                                    </Label>
                                  </FormGroup>
                                  <br />
                                </>
                              )
                            })}
                            <br />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {((adminDetails.export_type == "1" &&
                        [
                          "purchase_price_fsc",
                        ].includes(adminDetails.price_to_export)) ||
                        adminDetails.export_type == "2") &&
                      adminDetails.collection_id &&
                      layoutData.length ? (
                        <Col md={12}>
                          <div className="with_or_without_prices">
                            <hr />
                            <strong>
                              <i>Select export format</i>
                            </strong>
                            <br />
                            <br />
                            {layoutData.map(function (ly, li) {
                              if (ly.id != 4) {
                                return (
                                  <>
                                    <FormGroup
                                      check
                                      inline
                                      key={"layout_image_section" + ly.id}
                                    >
                                      <img
                                        src={ly.image}
                                        alt={ly.layout}
                                        width="150"
                                        height="150"
                                        className="img-thumbnail"
                                      ></img>
                                      <Input
                                        type="radio"
                                        name="layout_id"
                                        value={ly.id}
                                        className="form-check-input"
                                        onChange={handleExportSelection}
                                        id={"image_layout" + ly.id}
                                      />
                                      <Label
                                        className="form-check-label"
                                        htmlFor={"image_layout" + ly.id}
                                      >
                                        {ly.layout}
                                      </Label>
                                    </FormGroup>
                                    <br />
                                  </>
                                )
                              }
                            })}
                            <br />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {/* {adminDetails.layout_details &&
                      adminDetails.collection_id ? (
                        (adminDetails.export_type == "1" ||
                          adminDetails.export_type == "2") &&
                        layoutData.length ? (
                          <Col md={12}>
                            <div className="layout_template">
                              <hr />
                              <strong>
                                <i>Select export format</i>
                              </strong>
                              <br />
                              <br />
                              <div className="row">
                                {layoutData.map((layoutSec, il) => {
                                  return (
                                    <>
                                      <div className="col-md-12 p-2">
                                        <FormGroup
                                          check
                                          inline
                                          key={`layout_section_` + il}
                                        >
                                          <Label
                                            className="form-check-label"
                                            htmlFor={
                                              `layout_radio_btn_` + layoutSec.id
                                            }
                                          >
                                            <Input
                                              type="radio"
                                              name="layout_id"
                                              value={layoutSec.id}
                                              className="form-check-input"
                                              onChange={handleExportSelection}
                                              id={
                                                `layout_radio_btn_` +
                                                layoutSec.id
                                              }
                                            />
                                            <img
                                              src={layoutSec.image}
                                              width="150"
                                              height="150"
                                              className="img-thumbnail"
                                              alt={layoutSec.layout}
                                            />
                                            <span className="me-2"></span>
                                            {layoutSec.layout}
                                          </Label>
                                        </FormGroup>
                                      </div>
                                      <br />
                                    </>
                                  )
                                })}
                                <br />
                              </div>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )} */}
                    </Row>

                    <Row>
                      <Col md={6} className="mt-2">
                        {adminDetails && adminDetails.collection_id ? (
                          <button
                            className="btn btn-primary text-end mt-2"
                            onClick={e => downloadData(e)}
                          >
                            {loading ? "Loading ..." : "Export now"}
                          </button>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col md={6} className="text-end mt-2"></Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}

        <Modal
          isOpen={modalOpen}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="deleteProductConfirm"
          tabIndex="-1"
          backdrop={false}
          toggle={() => updateModalState()}
        >
          <ModalHeader>Download Export</ModalHeader>
          <ModalBody>
            You export is being processed. Depending on how many products,
            exporting can take several minutes. Please check the status in the
            download section.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={moveToDownload}>
              Ok
            </Button>{" "}
            {/* <Button onClick={() => updateModalState()}>No</Button> */}
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default ExportDownload

{
  /* {adminDetails.export_type ? (
                        adminDetails.export_type == "3" ? (
                          <Col md={12}>
                            <div className="with_or_without_prices">
                              <hr />
                              <strong>
                                <i>Select price to export</i>
                              </strong>
                              <br />
                              <br />
                              <FormGroup check inline>
                                <Input
                                  type="radio"
                                  value="sales_price_fsc"
                                  className="form-check-input price_to_export"
                                  name="price_to_export"
                                  onChange={handleExportSelection}
                                  id="price_export_admin_sales_price"
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="price_export_admin_sales_price"
                                >
                                  Sales Price (FSC)
                                </Label>
                              </FormGroup>
                              <br />
                              <FormGroup check inline>
                                <Input
                                  type="radio"
                                  value="sales_price_non_fsc"
                                  className="form-check-input price_to_export"
                                  name="price_to_export"
                                  onChange={handleExportSelection}
                                  id="price_export_admin_sales_price_non_fsc"
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="price_export_admin_sales_price_non_fsc"
                                >
                                  Sales Price (Non-FSC)
                                </Label>
                              </FormGroup>
                              <br />
                            </div>
                          </Col>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )} */
}
