import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./user.css"

function BtnCellRenderer(props) {
  return (
    <>
      <div className="row">
        <div className="col">
          <Link
            to={`edit/` + props.data.user_id}
            className="text-decoration-none"
          >
            <i
              className="bx bx-edit cursor-pointer font_size_large"
              title="Edit user"
            ></i>
          </Link>
        </div>
        <div className="col">
          <i
            onClick={() => props.updateModalState(props.data.user_id)}
            className="bx bx-lock cursor-pointer font_size_large text-danger"
            title="Reset password"
          ></i>
        </div>
      </div>
    </>
  )
}

export default BtnCellRenderer
