import React, { useState, useEffect, useCallback } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  CardTitle,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import classnames from "classnames"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  qaOfferEdit,
  qaUpdateOffer,
  supplierNameListing,
  offerStatusListing,
  incotermCityListing,
  materialStandardListing,
  packingStandardListing,
  environmentalAndHealthCertificationListing,
  packagingListing,
  deliveredOnListing,
  heaveyColliListing,
  qaUserListAccordingRoleListing,
} from "../../../store/actions"

const QaEditOffer = props => {
  document.title = "Edit Offer"
  const { id } = useParams()
  const dispatch = useDispatch()

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])
  const [productNumberNameCategory, setProductNumberNameCategory] =
    useState(null)

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  const [details, setDetails] = useState({})
  const [supplierName, setSupplierName] = useState([])
  const [offerStatus, setOfferStatus] = useState([])
  const [incotermCity, setIncotermCity] = useState([])
  const [materialStandard, setMaterialStandard] = useState([])
  const [packingStandard, setPackingStandard] = useState([])
  const [
    environmentalAndHealthCertification,
    setEnvironmentalAndHealthCertification,
  ] = useState([])
  const [packaging, setPackaging] = useState([])
  const [deliveredOn, setDeliveredOn] = useState([])
  const [heaveyColli, setHeaveyColli] = useState([])
  const [exportJyskList, setExportJyskList] = useState(0)
  const [userListAccordingRole, setUserListAccordingRole] = useState([])

  useEffect(() => {
    dispatch(supplierNameListing())
    dispatch(offerStatusListing())
    dispatch(incotermCityListing())
    dispatch(materialStandardListing())
    dispatch(packingStandardListing())
    dispatch(environmentalAndHealthCertificationListing())
    dispatch(packagingListing())
    dispatch(deliveredOnListing())
    dispatch(heaveyColliListing())
    dispatch(qaUserListAccordingRoleListing({ user_role: "qa" }, props.history))
    dispatch(qaOfferEdit(id))
  }, [])

  const offersData = useSelector(state => state.qaOffer)
  const supplierNameData = useSelector(state => state.supplierName.supplierName) ///1st supplierName is from reducer
  const offerStatusData = useSelector(state => state.offerStatus.offerStatus)
  const incotermCityData = useSelector(state => state.incotermCity.incotermCity)
  const materialStandardData = useSelector(
    state => state.materialStandard.materialStandard
  )
  const packingStandardData = useSelector(
    state => state.packingStandard.packingStandard
  )
  const environmentalAndHealthCertificationData = useSelector(
    state =>
      state.environmentalAndHealthCertification
        .environmentalAndHealthCertification
  )
  const packagingData = useSelector(state => state.packaging.packaging)
  const deliveredOnData = useSelector(state => state.deliveredOn.deliveredOn)
  const heaveyColliData = useSelector(state => state.heaveyColli.heaveyColli)

  const userListAccordingRoleData = useSelector(
    state => state.qaUserListAccordingRole.userListAccordingRole
  )

  useEffect(() => {
    let offers = []
    if (offersData.offer) {
      offers = offersData.offer.filter(offer => offer.offer_id == id)
    }
    setSupplierName(supplierNameData)
    setOfferStatus(offerStatusData)
    setIncotermCity(incotermCityData)
    setMaterialStandard(materialStandardData)
    setPackingStandard(packingStandardData)
    setEnvironmentalAndHealthCertification(
      environmentalAndHealthCertificationData
    )
    setPackaging(packagingData)
    setDeliveredOn(deliveredOnData)
    setHeaveyColli(heaveyColliData)
    setUserListAccordingRole(userListAccordingRoleData)
    if (offers.length > 0) {
      setDetails(offers[0])
    } else {
      props.history.push("/offers/list")
    }
  }, [
    supplierNameData,
    offerStatusData,
    incotermCityData,
    materialStandardData,
    packingStandardData,
    environmentalAndHealthCertificationData,
    packagingData,
    deliveredOnData,
    heaveyColliData,
    userListAccordingRoleData,
  ])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      offer_id: id,
      offer_status_id:
        details && details.offer_status_id ? details.offer_status_id : "",
      vendor_article_no:
        details && details.vendor_article_no ? details.vendor_article_no : "",
      supplier_id: details && details.supplier_id ? details.supplier_id : "",
      production_time:
        details && details.production_time ? details.production_time : "",
      incoterm_city_id:
        details && details.incoterm_city_id ? details.incoterm_city_id : "",
      material_standard_id:
        details && details.material_standard_id
          ? details.material_standard_id
          : "",
      packing_standard_id:
        details && details.packing_standard_id
          ? details.packing_standard_id
          : "",
      env_health_id:
        details && details.env_health_id ? details.env_health_id : "",
      non_compliance:
        details && details.non_compliance ? details.non_compliance : "",
      sbn_comments: details && details.sbn_comments ? details.sbn_comments : "",
      quality_control:
        details && details.quality_control ? details.quality_control : "",
      color_code_gloss:
        details && details.color_code_gloss ? details.color_code_gloss : "",
      purchase_price_fsc:
        details && details.purchase_price_fsc ? details.purchase_price_fsc : 0,
      yearly_purchase_fsc:
        details && details.yearly_purchase_fsc
          ? details.yearly_purchase_fsc
          : 0,
      purchase_price_non_fsc:
        details && details.purchase_price_non_fsc
          ? details.purchase_price_non_fsc
          : 0,
      yearly_purchase_non_fsc:
        details && details.yearly_purchase_non_fsc
          ? details.yearly_purchase_non_fsc
          : 0,
      purchasing_price_comments:
        details && details.purchasing_price_comments
          ? details.purchasing_price_comments
          : "",
      sales_price_fsc:
        details && details.sales_price_fsc ? details.sales_price_fsc : 0,
      sales_margin_fsc:
        details && details.sales_margin_fsc ? details.sales_margin_fsc : 0,
      yearly_sales_fsc:
        details && details.yearly_sales_fsc ? details.yearly_sales_fsc : 0,
      sales_price_non_fsc:
        details && details.sales_price_non_fsc
          ? details.sales_price_non_fsc
          : 0,
      sales_margin_non_fsc:
        details && details.sales_margin_non_fsc
          ? details.sales_margin_non_fsc
          : 0,
      yearly_sales_non_fsc:
        details && details.yearly_sales_non_fsc
          ? details.yearly_sales_non_fsc
          : 0,
      full_truck: details && details.full_truck ? details.full_truck : "",
      container: details && details.container ? details.container : "",
      moq: details && details.moq ? details.moq : "",
      net_weight: details && details.net_weight ? details.net_weight : "",
      gross_weight: details && details.gross_weight ? details.gross_weight : "",
      packaging_id: details && details.packaging_id ? details.packaging_id : "",
      delivered_on: details && details.delivered_on ? details.delivered_on : "",
      heavy_colli_id:
        details && details.heavy_colli_id ? details.heavy_colli_id : "",
      units_per_box:
        details && details.units_per_box ? details.units_per_box : "",
      bom_per_article:
        details && details.bom_per_article ? details.bom_per_article : "",
      master_cardboard_box:
        details && details.master_cardboard_box
          ? details.master_cardboard_box
          : "",
      packing_comments:
        details && details.packing_comments ? details.packing_comments : "",
      qa_user_id: details && details.user_type_id ? details.user_type_id : "",
      sample_request:
        details && details.sample_request ? details.sample_request : "",
      sample_comments:
        details && details.sample_comments ? details.sample_comments : "",
      sample_status:
        details && details.sample_status ? details.sample_status : "",
      qa_sample_checking_comments:
        details && details.qa_sample_checking_comments
          ? details.qa_sample_checking_comments
          : "",
      sales_sample_checking_comments:
        details && details.sales_sample_checking_comments
          ? details.sales_sample_checking_comments
          : "",
      sample_freight_cost:
        details && details.sample_freight_cost
          ? details.sample_freight_cost
          : "",
      sample_main_material:
        details && details.sample_main_material
          ? details.sample_main_material
          : "",
      sample_hardware:
        details && details.sample_hardware ? details.sample_hardware : "",
      sample_surface_treatment:
        details && details.sample_surface_treatment
          ? details.sample_surface_treatment
          : "",
    },
    validationSchema: Yup.object({
      offer_status_id: Yup.string(),
      supplier_id: Yup.string(),
      incoterm_city_id: Yup.string(),
      production_time: Yup.string(),
      material_standard_id: Yup.string(),
      packing_standard_id: Yup.string(),
      env_health_id: Yup.string(),

      purchase_price_fsc: Yup.number(),
      yearly_purchase_fsc: Yup.number(),
      sales_price_fsc: Yup.number(),
      sales_margin_fsc: Yup.number(),
      yearly_sales_fsc: Yup.number(),

      purchase_price_non_fsc: Yup.number(),
      yearly_purchase_non_fsc: Yup.number(),
      sales_price_non_fsc: Yup.number(),
      sales_margin_non_fsc: Yup.number(),
      yearly_sales_non_fsc: Yup.number(),

      full_truck: Yup.number(),
      container: Yup.number(),
      moq: Yup.number(),
      net_weight: Yup.number(),
      gross_weight: Yup.number(),
      packaging_id: Yup.string(),
      delivered_on: Yup.string(),
      heavy_colli_id: Yup.string(),
      units_per_box: Yup.number(),
      bom_per_article: Yup.number(),
    }),

    onSubmit: values => {
      dispatch(qaUpdateOffer(values, props.history))
    },
  })

  const offer = useSelector(state => state.qaOffer)
  const { error, msg } = useSelector(state => ({
    error: state.qaOffer.error,
    msg: state.qaOffer.msg ? state.qaOffer.msg : "",
  }))

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            breadcrumbItem="Edit Offer"
          // extra={productNumberNameCategory}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    <Row>
                      <Col md={4}>
                        <b className="align-left">
                          Product Number :-{" "}
                          {details && details.product_number
                            ? details.product_number
                            : ""}
                        </b>
                      </Col>
                      <Col md={4}>
                        <b className="align-middle">
                          Product Name :-{" "}
                          {details && details.product_name
                            ? details.product_name
                            : ""}
                        </b>
                      </Col>
                      <Col md={4}>
                        <b className="align-right">
                          Product Category :-{" "}
                          {details && details.product_category_name
                            ? details.product_category_name
                            : ""}
                        </b>
                      </Col>
                    </Row>
                  </CardTitle>
                  {msg || error ? (
                    <ToastContainer className="Toastify__close-button" />
                  ) : (
                    ""
                  )}
                  <Form
                    id="editOfferForm"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === 1,
                              })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">01</span> Offer Details
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 2,
                              })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number">02</span> Offer Packing
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 3,
                              })}
                              onClick={() => {
                                setactiveTab(3)
                              }}
                              disabled={!(passedSteps || []).includes(3)}
                            >
                              <span className="number">03</span> Sample Details
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix mt-4 addformsection">
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId={1}>
                            {/* <Form> */}
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-offer-status">
                                    Offer Status
                                  </Label>
                                  <select
                                    readOnly={true}
                                    name="offer_status_id"
                                    id="add-offer-offer-status"
                                    autoComplete="off"
                                    disabled
                                    className="form-control"
                                    value={
                                      validation.values.offer_status_id || ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {offerStatus &&
                                      offerStatus.map((offerStatus, ofs) => (
                                        <option
                                          key={"ofs" + ofs}
                                          value={offerStatus.id}
                                        >
                                          {offerStatus.offer_status}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-Vendor-article-number">
                                    Vendor Article Number
                                  </Label>
                                  <Input
                                    readOnly={true}
                                    name="vendor_article_no"
                                    type="text"
                                    autoComplete="off"
                                    disabled
                                    className="form-control"
                                    id="add-offer-Vendor-article-number"
                                    value={
                                      validation.values.vendor_article_no || ""
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-supplier-id">
                                    Supplier Name
                                  </Label>
                                  <select
                                    readOnly={true}
                                    name="supplier_id"
                                    id="add-offer-supplier-id"
                                    autoComplete="off"
                                    disabled
                                    className="form-control"
                                    value={validation.values.supplier_id || ""}
                                  >
                                    <option value="">Choose...</option>
                                    {supplierName &&
                                      supplierName.map((supplierName, snk) => (
                                        <option
                                          key={"snk" + snk}
                                          value={supplierName.id}
                                        >
                                          {supplierName.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-production-input1">
                                    Production Time
                                  </Label>
                                  <Input
                                    readOnly={true}
                                    name="production_time"
                                    type="number"
                                    autoComplete="off"
                                    disabled
                                    className="form-control"
                                    id="basicpill-production-input1"
                                    value={
                                      validation.values.production_time || ""
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-incoterm-city">
                                    Incoterm City (port)
                                  </Label>
                                  <select
                                    readOnly={true}
                                    autoComplete="off"
                                    disabled
                                    className="form-control"
                                    name="incoterm_city_id"
                                    id="add-offer-incoterm-city"
                                    value={
                                      validation.values.incoterm_city_id || ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {incotermCity &&
                                      incotermCity.map((incotermCity, icc) => (
                                        <option
                                          key={"icc" + icc}
                                          value={incotermCity.id}
                                        >
                                          {incotermCity.city}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-material-standard">
                                    Material Standard
                                  </Label>
                                  <select
                                    readOnly={true}
                                    autoComplete="off"
                                    disabled
                                    className="form-control"
                                    name="material_standard_id"
                                    id="add-offer-material-standard"
                                    value={
                                      validation.values.material_standard_id ||
                                      ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {materialStandard &&
                                      materialStandard.map(
                                        (materialStandard, ms) => (
                                          <option
                                            key={"ms" + ms}
                                            value={materialStandard.id}
                                          >
                                            {materialStandard.material_standard}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-packing-standard">
                                    Packing Standard
                                  </Label>
                                  <select
                                    readOnly={true}
                                    autoComplete="off"
                                    disabled
                                    className="form-control"
                                    name="packing_standard_id"
                                    id="add-offer-packing-standard"
                                    value={
                                      validation.values.packing_standard_id ||
                                      ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {packingStandard &&
                                      packingStandard.map(
                                        (packingStandard, ps) => (
                                          <option
                                            key={"ps" + ps}
                                            value={packingStandard.id}
                                          >
                                            {packingStandard.packing_standard}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-env&hlth-certification">
                                    Environmental and Health Certification
                                  </Label>
                                  <select
                                    readOnly={true}
                                    autoComplete="off"
                                    disabled
                                    className="form-control"
                                    name="env_health_id"
                                    id="add-offer-env&hlth-certification"
                                    value={
                                      validation.values.env_health_id || ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {environmentalAndHealthCertification &&
                                      environmentalAndHealthCertification.map(
                                        (
                                          environmentalAndHealthCertification,
                                          enhc
                                        ) => (
                                          <option
                                            key={"enhc" + enhc}
                                            value={
                                              environmentalAndHealthCertification.id
                                            }
                                          >
                                            {
                                              environmentalAndHealthCertification.certs
                                            }
                                          </option>
                                        )
                                      )}
                                  </select>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-compliance-input1">
                                    NON-compliance
                                  </Label>
                                  <textarea
                                    name="non_compliance"
                                    id="basicpill-compliance-input1"
                                    className="form-control"
                                    rows="3"
                                    readOnly={true}
                                    disabled
                                    value={
                                      validation.values.non_compliance || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-comments-input1">
                                    SBN comments
                                  </Label>
                                  <textarea
                                    name="sbn_comments"
                                    id="basicpill-comments-input1"
                                    className="form-control"
                                    rows="3"
                                    readOnly={true}
                                      disabled
                                    value={validation.values.sbn_comments || ""}
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-quality-input1">
                                    Quality Control
                                  </Label>
                                  <textarea
                                    name="quality_control"
                                    id="basicpill-quality-input1"
                                    className="form-control"
                                    rows="3"
                                    readOnly={true}
                                    disabled
                                    value={
                                      validation.values.quality_control || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-color-input1">
                                    Color code and Gloss
                                  </Label>
                                  <textarea
                                    name="color_code_gloss"
                                    id="basicpill-color-input1"
                                    className="form-control"
                                    rows="3"
                                    readOnly={true}
                                    disabled
                                    value={
                                      validation.values.color_code_gloss || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {/* </Form> */}
                          </TabPane>
                          <TabPane tabId={2}>
                            <div>
                              <Row>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-truck-input11">
                                      Full Truck/40HC
                                    </Label>
                                    <Input
                                      readOnly={true}
                                      name="full_truck"
                                      type="text"
                                      autoComplete="off"
                                      disabled
                                      className="form-control"
                                      id="basicpill-truck-input11"
                                      value={validation.values.full_truck || ""}
                                    />
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-20st-input11">
                                      20ST Container
                                    </Label>
                                    <Input
                                      readOnly={true}
                                      disabled
                                      name="container"
                                      type="text"
                                      autoComplete="off"
                                      className="form-control"
                                      id="basicpill-20st-input11"
                                      value={validation.values.container || ""}
                                    />
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-moq-input11">
                                      MOQ
                                    </Label>
                                    <Input
                                      readOnly={true}
                                      name="moq"
                                      type="text"
                                      autoComplete="off"
                                      disabled
                                      className="form-control"
                                      id="basicpill-moq-input11"
                                      value={validation.values.moq || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-net-input11">
                                      Net Weight
                                    </Label>
                                    <Input
                                      readOnly={true}
                                      name="net_weight"
                                      type="text"
                                      autoComplete="off"
                                      disabled
                                      className="form-control"
                                      id="basicpill-net-input11"
                                      value={validation.values.net_weight || ""}
                                    />
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-gross-input12">
                                      Gross Weight
                                    </Label>
                                    <Input
                                      readOnly={true}
                                      name="gross_weight"
                                      type="text"
                                      autoComplete="off"
                                      disabled
                                      className="form-control"
                                      id="basicpill-gross-input12"
                                      value={
                                        validation.values.gross_weight || ""
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="add-offer-packaging">
                                      Packaging
                                    </Label>
                                    <select
                                      readOnly={true}
                                      autoComplete="off"
                                      disabled
                                      className="form-control"
                                      name="packaging_id"
                                      id="add-offer-packaging"
                                      value={
                                        validation.values.packaging_id || ""
                                      }
                                    >
                                      <option value="">Choose...</option>
                                      {packaging &&
                                        packaging.map((packaging, pck) => (
                                          <option
                                            key={"pck" + pck}
                                            value={packaging.id}
                                          >
                                            {packaging.packaging}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label htmlFor="add-offer-delivered-on">
                                      Delivered On
                                    </Label>
                                    <select
                                      readOnly={true}
                                      autoComplete="off"
                                      disabled
                                      className="form-control"
                                      name="delivered_on"
                                      id="add-offer-delivered-on"
                                      value={
                                        validation.values.delivered_on || ""
                                      }
                                    >
                                      <option value="">Choose...</option>
                                      {deliveredOn &&
                                        deliveredOn.map((deliveredOn, dn) => (
                                          <option
                                            key={"dn" + dn}
                                            value={deliveredOn.id}
                                          >
                                            {deliveredOn.delivery_name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label htmlFor="add-offer-heavey-colli">
                                      Heavey Colli{" "}
                                    </Label>
                                    <select
                                      readOnly={true}
                                      autoComplete="off"
                                      disabled
                                      className="form-control"
                                      name="heavy_colli_id"
                                      id="add-offer-heavey-colli"
                                      value={
                                        validation.values.heavy_colli_id || ""
                                      }
                                    >
                                      <option value="">Choose...</option>
                                      {heaveyColli &&
                                        heaveyColli.map((heaveyColli, hc) => (
                                          <option
                                            key={"hc" + hc}
                                            value={heaveyColli.id}
                                          >
                                            {heaveyColli.heavy_collis}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-npb-input12">
                                      Number of Units per Box
                                    </Label>
                                    <Input
                                      readOnly={true}
                                      name="units_per_box"
                                      type="text"
                                      autoComplete="off"
                                      disabled
                                      className="form-control"
                                      id="basicpil-npb-input12"
                                      value={
                                        validation.values.units_per_box || ""
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-bom-input12">
                                      BOM per article
                                    </Label>
                                    <Input
                                      readOnly={true}
                                      name="bom_per_article"
                                      type="text"
                                      autoComplete="off"
                                      disabled
                                      className="form-control"
                                      id="basicpill-bom-input12"
                                      value={
                                        validation.values.bom_per_article || ""
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-mcb-input12">
                                      Master Cardboard Box
                                    </Label>
                                    <Input
                                      readOnly={true}
                                      name="master_cardboard_box"
                                      type="text"
                                      autoComplete="off"
                                      disabled
                                      className="form-control"
                                      id="basicpill-mcb-input12"
                                      value={
                                        validation.values
                                          .master_cardboard_box || ""
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-pc-input1">
                                      Packing comments
                                    </Label>
                                    <textarea
                                      name="packing_comments"
                                      id="basicpill-pc-input1"
                                      className="form-control"
                                      rows="3"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.packing_comments || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {/* </Form> */}
                            </div>
                          </TabPane>
                          <TabPane tabId={3}>
                            <div>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label htmlFor="add-offer-user-type">
                                      QA Users
                                    </Label>
                                    <select
                                      className="form-control"
                                      name="qa_user_id"
                                      id="add-offer-user-type"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.qa_user_id || ""}
                                    >
                                      <option value="">Choose...</option>
                                      {userListAccordingRole.length > 0 &&
                                        userListAccordingRole.map((u, ut) => (
                                          <option
                                            key={"ut" + ut}
                                            value={u.user_id}
                                          >
                                            {u.user_name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sr-input1">
                                      Sample Request
                                    </Label>
                                    <textarea
                                      name="sample_request"
                                      id="basicpill-sr-input1"
                                      className="form-control"
                                      rows="3"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_request || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sm-input1">
                                      Sample comments
                                    </Label>
                                    <textarea
                                      name="sample_comments"
                                      id="basicpill-sm-input1"
                                      className="form-control"
                                      rows="3"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_comments || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-ss-input1">
                                      Sample status
                                    </Label>
                                    <textarea
                                      name="sample_status"
                                      id="basicpill-ss-input1"
                                      className="form-control"
                                      rows="3"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_status || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-qa-input1">
                                      QA Sample Checkin comments
                                    </Label>
                                    <textarea
                                      name="qa_sample_checking_comments"
                                      id="basicpill-qa-input1"
                                      className="form-control"
                                      rows="3"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .qa_sample_checking_comments || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sc-input1">
                                      Sales sample checkin comments
                                    </Label>
                                    <textarea
                                      name="sales_sample_checking_comments"
                                      id="basicpill-sc-input1"
                                      className="form-control"
                                      rows="3"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .sales_sample_checking_comments || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sf-input1">
                                      Sample freight cost
                                    </Label>
                                    <textarea
                                      name="sample_freight_cost"
                                      id="basicpill-sf-input1"
                                      className="form-control"
                                      rows="3"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_freight_cost ||
                                        ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-smm-input1">
                                      Sample main material
                                    </Label>
                                    <textarea
                                      name="sample_main_material"
                                      id="basicpill-smm-input1"
                                      className="form-control"
                                      rows="3"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .sample_main_material || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sh-input1">
                                      Sample hardware
                                    </Label>
                                    <textarea
                                      name="sample_hardware"
                                      id="basicpill-sh-input1"
                                      className="form-control"
                                      rows="3"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.sample_hardware || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label htmlFor="basicpill-sst-input1">
                                      Sample surface treatment
                                    </Label>
                                    <textarea
                                      name="sample_surface_treatment"
                                      id="basicpill-sst-input1"
                                      className="form-control"
                                      rows="3"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .sample_surface_treatment || ""
                                      }
                                      onKeyDown={e => {
                                        e.key === "Enter" && e.preventDefault()
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab - 1)
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                          <li className={activeTab !== 3 ? "next" : "next"}>
                            {activeTab !== 3 ? (
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                              >
                                Next
                              </Link>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                                disabled={offer && offer.loading ? true : false}
                              >
                                {offer && offer.loading
                                  ? "Updating Offer"
                                  : "Update Offer"}
                              </button>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QaEditOffer
