import {
  CO_OFFER_ADD,
  CO_OFFER_SUCCESS,
  CO_OFFER_FAIL,
  CO_GET_OFFERS_LISTING,
  CO_GET_OFFERS_LISTING_SUCCESS,
  CO_GET_OFFERS_LISTING_FAILED,
  CO_OFFER_DELETE,
  CO_OFFER_DELETE_SUCCESS,
  CO_OFFER_DELETE_FAIL,
  CO_OFFER_EDIT,
  CO_OFFER_EDIT_SUCCESS,
  CO_OFFER_EDIT_FAIL,
  CO_OFFER_UPDATE,
  CO_OFFER_UPDATE_SUCCESS,
  CO_OFFER_UPDATE_FAIL,
  CO_CHANGE_OFFER_STATUS,
  CO_CHANGE_OFFER_STATUS_SUCCESS,
  CO_CHANGE_OFFER_STATUS_FAIL,
  CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
  CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS,
  CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL,
  CO_RESET_OFFER,
  CO_RESET_OFFER_SUCCESS,
  CO_RESET_OFFER_FAIL
} from "./actionTypes"

const initialState = {
  error: null,
  loading: false,
  msg: null,
  offer: [],
  offerDelete: null,
  offerEdit: null,
  offerUpdate: null,
  search: null,
}

const coOffer = (state = initialState, action) => {
  switch (action.type) {
    case CO_OFFER_ADD:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        search: null,
        offer: action.payload,
      }
      break
    case CO_OFFER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: action?.payload?.msg,
        offer: action.payload,
      }
      break
    case CO_OFFER_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case CO_GET_OFFERS_LISTING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        offer: action.payload,
        offerEdit: [],
        search: null,
      }
      break
    case CO_GET_OFFERS_LISTING_SUCCESS:
      state = {
        ...state,
        error: null,
        msg: null,
        loading: false,
        offer: action.payload,
        offerEdit: [],
        search: null,
      }
      break
    case CO_GET_OFFERS_LISTING_FAILED:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case CO_OFFER_DELETE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        offerDelete: action.payload,
      }
      break
    case CO_OFFER_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        offer: state.offer.filter(offer => {
          return offer.offer_id !== action.payload.id
        }),
      }
      break
    case CO_OFFER_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case CO_OFFER_EDIT:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        offerEdit: action.payload,
      }
      break
    case CO_OFFER_EDIT_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        offer: [action.payload],
      }
      break
    case CO_OFFER_EDIT_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case CO_OFFER_UPDATE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        offerUpdate: action.payload,
      }
      break
    case CO_OFFER_UPDATE_SUCCESS:
      state = {
        ...state,
        error: null,
        msg: action?.payload?.msg,
        loading: false,
      }
      break
    case CO_OFFER_UPDATE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case CO_CHANGE_OFFER_STATUS:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        search: null,
      }
      break
    case CO_CHANGE_OFFER_STATUS_SUCCESS:
      const index = state.offer.findIndex(
        offer => offer.offer_id === action.payload.id
      )
      let newOfferAfterStatusChange = []
      newOfferAfterStatusChange = [...state.offer] //making a new array
      newOfferAfterStatusChange[index].status =
        !newOfferAfterStatusChange[index].status

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        offer: newOfferAfterStatusChange,
      }
      break
    case CO_CHANGE_OFFER_STATUS_FAIL:
    default:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        search: action.payload,
        offerEdit: [],
      }
      break
    case CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        search: action.payload,
      }
      break
    case CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
      case CO_RESET_OFFER:
        state = initialState
        break
  
      case CO_RESET_OFFER_SUCCESS:
        state = initialState
        break
  
      case CO_RESET_OFFER_FAIL:
        state = initialState
        break
  }
  return state
}

export default coOffer
