import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_OFFERSTATUS_LISTING,
  FETCH_OFFER_STATUS_BY_SECTION,
  ADD_OFFER_STATUS,
  EDIT_OFFER_STATUS,
  UPDATE_OFFER_STATUS,
  OFFER_STATUS_ACTIVE_INACTIVE,
  OFFER_STATUS_POSITION,
} from "./actionTypes"
import {
  offerStatusListingSuccess,
  offerStatusListingFail,
  fetchOfferStatusListingBySectionSuccess,
  fetchOfferStatusListingBySectionFail,
  addOfferStatusSuccess,
  addOfferStatusFail,
  editOfferStatusSuccess,
  editOfferStatusFail,
  updateOfferStatusSuccess,
  updateOfferStatusFail,
  offerStatusChangeSuccess,
  offerStatusChangeFail,
  offerStatusPositionSuccess,
  offerStatusPositionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  offerStatusListingApi,
  fetchDropdownsBySectionApi,
  addOfferStatusApi,
  getOfferStatusDetailsById,
  updateOfferStatusApi,
  OfferStatusChangeStatusApi,
  fetchDropDownPositionApi,
} from "../../../helpers/fakebackend_helper"

function* getOfferStatusListing() {
  try {
    const response = yield call(offerStatusListingApi)
    if (!response.results.offer_status) {
      yield put(offerStatusListingFail(error))
    }
    yield put(offerStatusListingSuccess(response.results.offer_status))
  } catch (error) {
    yield put(offerStatusListingFail(error))
  }
}

function* fetchOfferStatusListingBySection({ payload: { data } }) {
  try {
    const response = yield call(fetchDropdownsBySectionApi, data)
    if (!response?.results?.offer_status) {
      throw response.msg ? response.msg : "Failed to fetch details"
    }
    yield put(
      fetchOfferStatusListingBySectionSuccess(response.results.offer_status)
    )
  } catch (error) {
    yield put(fetchOfferStatusListingBySectionFail(error))
  }
}

function* addOfferStatus({ payload: { data, history } }) {
  try {
    const response = yield call(addOfferStatusApi, data)
    if (response && response.status) {
      yield put(addOfferStatusSuccess(response))
      history.push("/dropdown/offer_status/list")
    } else {
      throw response.msg ? response.msg : "Failed to add"
    }
  } catch (error) {
    yield put(addOfferStatusFail(error))
  }
}

function* editOfferStatus({ payload: { id } }) {
  try {
    const response = yield call(getOfferStatusDetailsById, id)
    if (response && response.status) {
      response.id = id
      yield put(editOfferStatusSuccess(response.results.get_offer_status))
    } else {
      throw response.msg ? response.msg : "Failed to edit"
    }
  } catch (error) {
    yield put(editOfferStatusFail(error))
  }
}

function* updateOfferStatus({ payload: { updateOfferStatus, history } }) {
  try {
    const response = yield call(updateOfferStatusApi, updateOfferStatus)
    if (response && response.status) {
      yield put(updateOfferStatusSuccess(response))
      history.push("/dropdown/offer_status/list")
    } else {
      throw response.msg ? response.msg : "Failed to update"
    }
  } catch (error) {
    yield put(updateOfferStatusFail(error))
  }
}

function* offerStatusChange({ payload: { id } }) {
  try {
    const response = yield call(OfferStatusChangeStatusApi, {
      offer_status_id: id,
    })
    if (response && response.status) {
      response.id = id
      yield put(offerStatusChangeSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update status"
    }
  } catch (error) {
    yield put(offerStatusChangeFail(error))
  }
}

function* offerStatusPosition({ payload: { position } }) {
  try {
    const response = yield call(fetchDropDownPositionApi, position)
    if (response && response.status) {
      yield put(offerStatusPositionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update position"
    }
  } catch (error) {
    let msg = error ? { msg: error } : { msg: "Failed to update position" }
    yield put(offerStatusPositionFail(msg))
  }
}

function* offerStatusSaga() {
  yield takeEvery(GET_OFFERSTATUS_LISTING, getOfferStatusListing)
  yield takeEvery(
    FETCH_OFFER_STATUS_BY_SECTION,
    fetchOfferStatusListingBySection
  )
  yield takeEvery(ADD_OFFER_STATUS, addOfferStatus)
  yield takeEvery(EDIT_OFFER_STATUS, editOfferStatus)
  yield takeEvery(UPDATE_OFFER_STATUS, updateOfferStatus)
  yield takeEvery(OFFER_STATUS_ACTIVE_INACTIVE, offerStatusChange)
  yield takeEvery(OFFER_STATUS_POSITION, offerStatusPosition)
}
export default offerStatusSaga
