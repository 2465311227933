import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { GET_USERLISTACCORDINGROLE_LISTING } from "./actionTypes"
import {
  userListAccordingRoleListing,
  userListAccordingRoleSuccess,
  userListAccordingRoleFail,
} from "./actions"

//Include Both Helper File with needed methods
import { userListAccordingRoleApi } from "../../../helpers/fakebackend_helper"
function* getUserListAccordingRoleListing({ payload: { userType, history } }) {
  try {
    const response = yield call(userListAccordingRoleApi, userType)
    if (!response.results.user_lists) {
      yield put(userListAccordingRoleFail(error))
    }
    yield put(userListAccordingRoleSuccess(response.results.user_lists))
  } catch (error) {
    yield put(userListAccordingRoleFail(error))
  }
}

function* userListAccordingRoleSaga() {
  yield takeEvery(
    GET_USERLISTACCORDINGROLE_LISTING,
    getUserListAccordingRoleListing
  )
}
export default userListAccordingRoleSaga
