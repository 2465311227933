import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { useHistory } from "react-router-dom"

import {
  SA_COLLECTION_ADD,
  SA_GET_COLLECTION_LISTING,
  SA_COLLECTION_EDIT,
  SA_COLLECTION_UPDATE,
  SA_COLLECTION_DELETE,
  SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
  SA_COLLECTION_COPY,
  SA_EXPORTDOWNLOAD_PROGRESS,
  SA_ADD_MORE_TO_COLLECTION,
  SA_ADD_MORE_TO_COLLECTION_UPDATE,
  SA_GET_PRODUCTS_FOR_ADD_COLLECTION,
  SA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
  SA_REFRESH_COLLECTION_COUNT,
  SA_GET_LAYOUT,
} from "./actionTypes"

import {
  saCollectionSuccess,
  saCollectionFail,
  saCollectionsListingSuccess,
  saCollectionsListingFail,
  saEditCollectionSuccess,
  saEditCollectionFail,
  saUpdateCollectionSuccess,
  saUpdateCollectionFail,
  saDeleteCollectionSuccess,
  saDeleteCollectionFail,
  saExportCollectionCatalogSuccess,
  saExportCollectionCatalogFail,
  saCopyCollectionSuccess,
  saCopyCollectionFail,
  saExportDownloadProgressSuccess,
  saExportDownloadProgressFail,
  saGetAddMoreToCollectionSuccess,
  saGetAddMoreToCollectionFail,
  saGetAddMoreToCollectionUpdateSuccess,
  saGetAddMoreToCollectionUpdateFail,
  saProductForAddCollectionSuccess,
  saProductForAddCollectionFail,
  saProductOfferForAddCollectionSuccess,
  saProductOfferForAddCollectionFail,
  saRefreshCollectionCountSuccess,
  saRefreshCollectionCountFail,
  saGetLayOutSuccess,
  saGetLayOutFail,
} from "./actions"

import {
  saAddCollectionApi,
  saCollectionsListingApi,
  saEditCollectionApi,
  saUpdateCollectionApi,
  saDeleteCollectionApi,
  saExportCollectionCatalogApi,
  saCopyCollectionApi,
  saExportDownloadProgressApi,
  saGetAddMoreToCollectionApi,
  saAddMoreDataToCollectionUpdateApi,
  saGetProductForAddCollectionApi,
  saGetProductsOfferForAddCollectionApi,
  saRefreshCollectionCountApi,
  saGetLayoutApi,
} from "../../../helpers/fakebackend_helper"

function* saAddNewCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(saAddCollectionApi, collection)

    if (response && response.status) {
      yield put(saCollectionSuccess(response))
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to add collection"
    }
  } catch (error) {
    yield put(saCollectionFail(error))
  }
}

function* saGetCollectionListing() {
  try {
    const response = yield call(saCollectionsListingApi)
    if (
      response.results === undefined ||
      response.results.collections.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(saCollectionsListingSuccess(response.results.collections))
  } catch (error) {
    yield put(saCollectionsListingFail(error))
  }
}

function* saGetCollectionDetailsById({ payload: { id } }) {
  try {
    const response = yield call(saEditCollectionApi, id)
    if (response && response.status) {
      response.id = id
      yield put(saEditCollectionSuccess(response.results.collection))
    } else {
      throw response.msg ? response.msg : "Failed to fetch collection details"
    }
  } catch (error) {
    yield put(saEditCollectionFail(error))
  }
}

function* saOnUpdateCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(saUpdateCollectionApi, collection)
    if (response && response.status) {
      yield put(saUpdateCollectionSuccess(response))
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to update collection"
    }
  } catch (error) {
    yield put(saUpdateCollectionFail(error))
  }
}

function* saOnDeleteCollection({ payload: { id } }) {
  try {
    const response = yield call(saDeleteCollectionApi, { collection_id: id })
    if (response && response.status) {
      response.id = id
      yield put(saDeleteCollectionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to delete collection"
    }
  } catch (error) {
    yield put(saDeleteCollectionFail(error))
  }
}

function* saOnExportCollectionCatalog({ payload: { collection, history } }) {
  try {
    const response = yield call(saExportCollectionCatalogApi, collection)
    if (response && response.status) {
      yield put(saExportCollectionCatalogSuccess(response))
    } else {
      throw response.msg ? response.msg : "Download in progress..."
    }
  } catch (error) {
    yield put(saExportCollectionCatalogFail(error))
  }
}

function* saOnCopyCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(saCopyCollectionApi, collection)

    if (response && response.status) {
      yield put(
        saCopyCollectionSuccess(response.record.original?.results?.collection)
      )
    } else {
      throw response.msg ? response.msg : "Failed to copy collection"
    }
  } catch (error) {
    yield put(saCopyCollectionFail(error))
  }
}

function* onSaExportDownloadProgress({
  payload: { saExportDownloadProgress, history },
}) {
  try {
    const response = yield call(
      saExportDownloadProgressApi,
      saExportDownloadProgress
    )
    if (response && response.status) {
      yield put(saExportDownloadProgressSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to download file"
    }
  } catch (error) {
    yield put(saExportDownloadProgressFail(error))
  }
}

function* saOnFetchDataToAddMoreToCollection({ payload: { collection_id } }) {
  try {
    const response = yield call(saGetAddMoreToCollectionApi, {
      collection_id: collection_id,
    })
    if (response && response.status && response?.results?.product_dtls) {
      yield put(
        saGetAddMoreToCollectionSuccess(response?.results?.product_dtls)
      )
    } else if (response && response.status && response?.results?.prod_offers) {
      yield put(saGetAddMoreToCollectionSuccess(response?.results?.prod_offers))
    } else {
      throw response.msg ? response.msg : "Failed to fetch data"
    }
  } catch (error) {
    yield put(saGetAddMoreToCollectionFail(error))
  }
}

function* saOnUpdateDataToAddMoreToCollection({
  payload: { addMoreUpdateDetails, history },
}) {
  try {
    const response = yield call(
      saAddMoreDataToCollectionUpdateApi,
      addMoreUpdateDetails
    )
    if (response && response.status) {
      yield put(saGetAddMoreToCollectionUpdateSuccess(response))
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to update data"
    }
  } catch (error) {
    yield put(saGetAddMoreToCollectionUpdateFail(error))
  }
}

function* saGetProductsForAddCollection({
  payload: { productsForAddCollection },
}) {
  try {
    const response = yield call(
      saGetProductForAddCollectionApi,
      productsForAddCollection
    )
    if (
      response.results === undefined ||
      response.results.product_dtls.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(saProductForAddCollectionSuccess(response.results.product_dtls))
  } catch (error) {
    yield put(saProductForAddCollectionFail(error))
  }
}

function* saGetProductsOfferForAddCollection({
  payload: { productsOfferForAddCollection },
}) {
  try {
    const response = yield call(
      saGetProductsOfferForAddCollectionApi,
      productsOfferForAddCollection
    )
    if (
      response.results === undefined ||
      response.results.prod_offers.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(
      saProductOfferForAddCollectionSuccess(response.results.prod_offers)
    )
  } catch (error) {
    yield put(saProductOfferForAddCollectionFail(error))
  }
}

function* saRefreshCollectionCount({ payload: { collection_id } }) {
  try {
    const response = yield call(saRefreshCollectionCountApi, {
      collection_id: collection_id,
    })
    if (response.status && response.result) {
      yield put(saRefreshCollectionCountSuccess(response.result))
    } else {
      throw response.msg ? response.msg : "No data found"
    }
  } catch (error) {
    yield put(saRefreshCollectionCountFail(error))
  }
}

function* saGetLayout() {
  try {
    const response = yield call(saGetLayoutApi)
    if (response === undefined || response.results.length == 0) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(saGetLayOutSuccess(response.results))
  } catch (error) {
    yield put(saGetLayOutFail(error))
  }
}

function* saCollectionSaga() {
  yield takeEvery(SA_COLLECTION_ADD, saAddNewCollection)
  yield takeEvery(SA_GET_COLLECTION_LISTING, saGetCollectionListing)
  yield takeEvery(SA_COLLECTION_EDIT, saGetCollectionDetailsById)
  yield takeEvery(SA_COLLECTION_UPDATE, saOnUpdateCollection)
  yield takeEvery(SA_COLLECTION_DELETE, saOnDeleteCollection)
  yield takeEvery(
    SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
    saOnExportCollectionCatalog
  )
  yield takeEvery(SA_COLLECTION_COPY, saOnCopyCollection)
  yield takeEvery(SA_EXPORTDOWNLOAD_PROGRESS, onSaExportDownloadProgress)
  yield takeEvery(SA_ADD_MORE_TO_COLLECTION, saOnFetchDataToAddMoreToCollection)
  yield takeEvery(
    SA_ADD_MORE_TO_COLLECTION_UPDATE,
    saOnUpdateDataToAddMoreToCollection
  )
  yield takeEvery(
    SA_GET_PRODUCTS_FOR_ADD_COLLECTION,
    saGetProductsForAddCollection
  )
  yield takeEvery(
    SA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
    saGetProductsOfferForAddCollection
  )
  yield takeEvery(SA_REFRESH_COLLECTION_COUNT, saRefreshCollectionCount)
  yield takeEvery(SA_GET_LAYOUT, saGetLayout)
}

export default saCollectionSaga
