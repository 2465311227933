import React from "react"
import "./exports.css"
function RefreshCellRenderer(props) {
  return (
    <>
      <div className="d-flex justify-content-center p-1">
        <div>
          <p>{props.data.item_count}&nbsp;&nbsp;</p>
        </div>
        <div className="pl-1">
          <i
            title="Refresh item count"
            className="bx bx-rotate-right ml-2 mt-1 cursor-pointer font_size_large"
            onClick={() => {
              props.refreshCollectionItemCount(props.data.id)
            }}
          ></i>
        </div>
      </div>
    </>
  )
}

export default RefreshCellRenderer
