import {
  GET_CONSTRUCTON_LISTING,
  GET_CONSTRUCTON_LISTING_SUCCESS,
  GET_CONSTRUCTON_LISTING_FAILED,
  ADD_CONSTRUCTON,
  ADD_CONSTRUCTON_SUCCESS,
  ADD_CONSTRUCTON_FAIL,
  CHANGE_CONSTRUCTON_STATUS,
  CHANGE_CONSTRUCTON_STATUS_SUCCESS,
  CHANGE_CONSTRUCTON_STATUS_FAIL,
  EDIT_CONSTRUCTON,
  EDIT_CONSTRUCTON_SUCCESS,
  EDIT_CONSTRUCTON_FAIL,
  UPDATE_CONSTRUCTON,
  UPDATE_CONSTRUCTON_SUCCESS,
  UPDATE_CONSTRUCTON_FAIL,
  FETCH_CONSTRUCTON_BY_SECTION,
  FETCH_CONSTRUCTON_BY_SECTION_SUCCESS,
  FETCH_CONSTRUCTON_BY_SECTION_FAIL,
  UPDATE_CONSTRUCTION_POSITION,
  UPDATE_CONSTRUCTION_POSITION_SUCCESS,
  UPDATE_CONSTRUCTION_POSITION_FAIL,
  } from "./actionTypes"
  
  export const constructionListing = () => {
    return {
      type: GET_CONSTRUCTON_LISTING,
    }
  }
  
  export const constructionListingSuccess = construction => {
    return {
      type: GET_CONSTRUCTON_LISTING_SUCCESS,
      payload: construction,
    }
  }
  
  export const constructionListingFail = error => {
    return {
      type: GET_CONSTRUCTON_LISTING_FAILED,
      payload: error,
    }
  }
  
  export const addConstruction = (construction, history) => {
    return {
      type: ADD_CONSTRUCTON,
      payload: { construction, history },
    }
  }
  
  export const addConstructionSuccess = construction => {
    return {
      type: ADD_CONSTRUCTON_SUCCESS,
      payload: construction,
    }
  }
  
  export const addConstructionFail = error => {
    return {
      type: ADD_CONSTRUCTON_FAIL,
      payload: error,
    }
  }

  export const changeConstructionStatus = id => ({
    type: CHANGE_CONSTRUCTON_STATUS,
    payload: { id: id },
  })
  
  export const changeConstructionStatusSuccess = construction => ({
    type: CHANGE_CONSTRUCTON_STATUS_SUCCESS,
    payload: construction,
  })
  
  export const changeConstructionStatusFail = error => ({
    type: CHANGE_CONSTRUCTON_STATUS_FAIL,
    payload: error,
  })

  export const editConstruction = id => {
    return {
      type: EDIT_CONSTRUCTON,
      payload: { id },
    }
  }
  export const editConstructionSuccess = construction => {
    return {
      type: EDIT_CONSTRUCTON_SUCCESS,
      payload: construction,
    }
  }
  export const editConstructionFail = error => {
    return {
      type: EDIT_CONSTRUCTON_FAIL,
      payload: error,
    }
  }
  
  export const updateConstruction = (constructionUpdate, history) => {
    return {
      type: UPDATE_CONSTRUCTON,
      payload: { constructionUpdate, history },
    }
  }
  
  export const updateConstructionSuccess = construction => {
    return {
      type: UPDATE_CONSTRUCTON_SUCCESS,
      payload: construction,
    }
  }
  
  export const updateConstructionFail = error => {
    return {
      type: UPDATE_CONSTRUCTON_FAIL,
      payload: error,
    }
  }

  export const fetchConstructionListingBySection = (data) => {
    return {
      type: FETCH_CONSTRUCTON_BY_SECTION,
      payload: { data }
    }
  }
  
  export const fetchConstructionListingBySectionSuccess = construction => {
    return {
      type: FETCH_CONSTRUCTON_BY_SECTION_SUCCESS,
      payload: construction,
    }
  }
  
  export const fetchConstructionListingBySectionFail = error => {
    return {
      type: FETCH_CONSTRUCTON_BY_SECTION_FAIL,
      payload: error,
    }
  }

  export const updateConstructionPosition = data => ({
    type: UPDATE_CONSTRUCTION_POSITION,
    payload: { data },
  })
  
  export const updateConstructionPositionSuccess = construction => ({
    type: UPDATE_CONSTRUCTION_POSITION_SUCCESS,
    payload: construction,
  })
  
  export const updateConstructionPositionFail = error => ({
    type: UPDATE_CONSTRUCTION_POSITION_FAIL,
    payload: error,
  })