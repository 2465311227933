import {
  GET_HEAVEYCOLLI_LISTING,
  GET_HEAVEYCOLLI_LISTING_SUCCESS,
  GET_HEAVEYCOLLI_LISTING_FAILED,
  ADD_HEAVEY_COLLI,
  ADD_HEAVEY_COLLI_SUCCESS,
  ADD_HEAVEY_COLLI_FAIL,
  CHANGE_HEAVEY_COLLI_STATUS,
  CHANGE_HEAVEY_COLLI_STATUS_SUCCESS,
  CHANGE_HEAVEY_COLLI_STATUS_FAIL,
  EDIT_HEAVEY_COLLI,
  EDIT_HEAVEY_COLLI_SUCCESS,
  EDIT_HEAVEY_COLLI_FAIL,
  UPDATE_HEAVEY_COLLI,
  UPDATE_HEAVEY_COLLI_SUCCESS,
  UPDATE_HEAVEY_COLLI_FAIL,
  FETCH_HEAVEY_COLLI_BY_SECTION,
  FETCH_HEAVEY_COLLI_BY_SECTION_SUCCESS,
  FETCH_HEAVEY_COLLI_BY_SECTION_FAIL,
  UPDATE_HEAVEY_COLLI_POSITION,
  UPDATE_HEAVEY_COLLI_POSITION_SUCCESS,
  UPDATE_HEAVEY_COLLI_POSITION_FAIL,
} from "./actionTypes"

const initialState = {
  error: null,
  loading: false,
  heaveyColli: null,
  heaveyColliEdit: null,
  heaveyColliUpdate: null,
  positionUpdate: null,
}

const heaveyColli = (state = initialState, action) => {
  switch (action.type) {
    case GET_HEAVEYCOLLI_LISTING:
      state = {
        ...state,
        loading: true,
        positionUpdate: null,
        error: null,
        loading: false,
        heaveyColli: action.payload,
      }
      break
    case GET_HEAVEYCOLLI_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        positionUpdate: null,
        error: null,
        loading: false,
        heaveyColli: action.payload,
      }
      break
    case GET_HEAVEYCOLLI_LISTING_FAILED:
      state = { ...state, loading: false }
      break
    case ADD_HEAVEY_COLLI:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        heaveyColli: action.payload,
      }
      break
    case ADD_HEAVEY_COLLI_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        heaveyColli: action.payload,
      }
      break
    case ADD_HEAVEY_COLLI_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload ? action.payload : null,
      }
      break
    case CHANGE_HEAVEY_COLLI_STATUS:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break
    case CHANGE_HEAVEY_COLLI_STATUS_SUCCESS:
      const index = state.heaveyColli.findIndex(
        heavy_colli => heavy_colli.id === action.payload.id
      )
      let newHeaveyColliAfterStatusChange = []
      newHeaveyColliAfterStatusChange = [...state.heaveyColli] //making a new array
      newHeaveyColliAfterStatusChange[index].status =
        !newHeaveyColliAfterStatusChange[index].status

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        heaveyColli: newHeaveyColliAfterStatusChange,
      }
      break

    case CHANGE_HEAVEY_COLLI_STATUS_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case EDIT_HEAVEY_COLLI:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        heaveyColliEdit: action.payload,
      }
      break
    case EDIT_HEAVEY_COLLI_SUCCESS:
      if (state === null || state.heaveyColli.length === 0) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          heaveyColli: [...state.heaveyColli, action.payload],
        }
      } else {
        state = {
          ...state,
          loading: false,
          heaveyColli: state.heaveyColli.map(i =>
            i.id === action.payload.id ? action.payload : i
          ),
        }
      }
      break
    case EDIT_HEAVEY_COLLI_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case UPDATE_HEAVEY_COLLI:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        heaveyColliUpdate: action.payload,
      }
      break
    case UPDATE_HEAVEY_COLLI_SUCCESS:
      state = { ...state, error: null, msg: null, loading: false }
      break
    case UPDATE_HEAVEY_COLLI_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case FETCH_HEAVEY_COLLI_BY_SECTION:
      state = {
        ...state,
        loading: true,
        positionUpdate: null,
        error: null,
        loading: false,
        heaveyColliList: action.payload,
      }
      break
    case FETCH_HEAVEY_COLLI_BY_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        positionUpdate: null,
        error: null,
        loading: false,
        heaveyColli: action.payload,
      }
      break
    case FETCH_HEAVEY_COLLI_BY_SECTION_FAIL:
      state = { ...state, loading: false }
      break
    case UPDATE_HEAVEY_COLLI_POSITION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
      }
      break

    case UPDATE_HEAVEY_COLLI_POSITION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: action.payload.msg != "" ? action.payload.msg : "",
        positionUpdate: true,
      }
      break
    case UPDATE_HEAVEY_COLLI_POSITION_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.msg != "" ? action.payload.msg : "",
        msg: null,
        positionUpdate: null,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default heaveyColli
