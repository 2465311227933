export const QA_GET_OFFERS_LISTING = "QA_GET_OFFERS_LISTING"
export const QA_GET_OFFERS_LISTING_SUCCESS = "QA_GET_OFFERS_LISTING_SUCCESS"
export const QA_GET_OFFERS_LISTING_FAILED = "QA_GET_OFFERS_LISTING_FAILED"

export const QA_OFFER_EDIT = "QA_OFFER_EDIT"
export const QA_OFFER_EDIT_SUCCESS = "QA_OFFER_EDIT_SUCCESS"
export const QA_OFFER_EDIT_FAIL = "QA_OFFER_EDIT_FAIL"

export const QA_OFFER_UPDATE = "QA_OFFER_UPDATE"
export const QA_OFFER_UPDATE_SUCCESS = "QA_OFFER_UPDATE_SUCCESS"
export const QA_OFFER_UPDATE_FAIL = "QA_OFFER_UPDATE_FAIL"

export const QA_SEARCH_ITEM_IN_NEW_OFFER_PAGE = "QA_SEARCH_ITEM_IN_NEW_OFFER_PAGE"
export const QA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS = "QA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS"
export const QA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL = "QA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL"

export const QA_RESET_OFFER = "QA_RESET_OFFER"
export const QA_RESET_OFFER_SUCCESS = "QA_RESET_OFFER_SUCCESS"
export const QA_RESET_OFFER_FAIL = "QA_RESET_OFFER_FAIL"