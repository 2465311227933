import {
  GET_PACKAGING_LISTING,
  GET_PACKAGING_LISTING_SUCCESS,
  GET_PACKAGING_LISTING_FAILED,
  FETCH_PACKAGING_BY_SECTION,
  FETCH_PACKAGING_BY_SECTION_SUCCESS,
  FETCH_PACKAGING_BY_SECTION_FAIL,
  ADD_PACKAGING,
  ADD_PACKAGING_SUCCESS,
  ADD_PACKAGING_FAIL,
  EDIT_PACKAGING,
  EDIT_PACKAGING_SUCCESS,
  EDIT_PACKAGING_FAIL,
  UPDATE_PACKAGING,
  UPDATE_PACKAGING_SUCCESS,
  UPDATE_PACKAGING_FAIL,
  PACKAGING_ACTIVE_INACTIVE,
  PACKAGING_ACTIVE_INACTIVE_SUCCESS,
  PACKAGING_ACTIVE_INACTIVE_FAIL,
  PACKING_POSITION,
  PACKING_POSITION_SUCCESS,
  PACKING_POSITION_FAIL,
} from "./actionTypes"

export const packagingListing = () => {
  return {
    type: GET_PACKAGING_LISTING,
  }
}

export const packagingListingSuccess = packaging => {
  return {
    type: GET_PACKAGING_LISTING_SUCCESS,
    payload: packaging,
  }
}

export const packagingListingFail = error => {
  return {
    type: GET_PACKAGING_LISTING_FAILED,
    payload: error,
  }
}

export const fetchPackagingListingBySection = data => {
  return {
    type: FETCH_PACKAGING_BY_SECTION,
    payload: { data },
  }
}

export const fetchPackagingListingBySectionSuccess = packaging => {
  return {
    type: FETCH_PACKAGING_BY_SECTION_SUCCESS,
    payload: packaging,
  }
}

export const fetchPackagingListingBySectionFail = error => {
  return {
    type: FETCH_PACKAGING_BY_SECTION_FAIL,
    payload: error,
  }
}

export const addPackaging = (data, history) => {
  return {
    type: ADD_PACKAGING,
    payload: { data, history },
  }
}

export const addPackagingSuccess = packaging => {
  return {
    type: ADD_PACKAGING_SUCCESS,
    payload: packaging,
  }
}

export const addPackagingFail = error => {
  return {
    type: ADD_PACKAGING_FAIL,
    payload: error,
  }
}

export const editPackaging = id => {
  return {
    type: EDIT_PACKAGING,
    payload: { id: id },
  }
}
export const editPackagingSuccess = packaging => {
  return {
    type: EDIT_PACKAGING_SUCCESS,
    payload: packaging,
  }
}
export const editPackagingFail = error => {
  return {
    type: EDIT_PACKAGING_FAIL,
    payload: error,
  }
}

export const updatePackaging = (updatePackaging, history) => {
  return {
    type: UPDATE_PACKAGING,
    payload: { updatePackaging, history },
  }
}

export const updatePackagingSuccess = updatePackaging => {
  return {
    type: UPDATE_PACKAGING_SUCCESS,
    payload: updatePackaging,
  }
}

export const updatePackagingFail = error => {
  return {
    type: UPDATE_PACKAGING_FAIL,
    payload: error,
  }
}

export const packagingChange = id => ({
  type: PACKAGING_ACTIVE_INACTIVE,
  payload: { id: id },
})

export const packagingChangeSuccess = packaging => ({
  type: PACKAGING_ACTIVE_INACTIVE_SUCCESS,
  payload: packaging,
})

export const packagingChangeFail = error => ({
  type: PACKAGING_ACTIVE_INACTIVE_FAIL,
  payload: error,
})

export const updatePackingPosition = position => ({
  type: PACKING_POSITION,
  payload: { position },
})

export const updatePackingPositionSuccess = packing => ({
  type: PACKING_POSITION_SUCCESS,
  payload: packing,
})

export const updatePackingPositionFail = error => ({
  type: PACKING_POSITION_FAIL,
  payload: error,
})
