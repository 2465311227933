import {
  CO_GET_CONSTRUCTON_LISTING,
  CO_GET_CONSTRUCTON_LISTING_SUCCESS,
  CO_GET_CONSTRUCTON_LISTING_FAILED,
  } from "./actionTypes"
  
  export const coConstructionListing = () => {
    return {
      type: CO_GET_CONSTRUCTON_LISTING,
    }
  }
  
  export const coConstructionListingSuccess = construction => {
    return {
      type: CO_GET_CONSTRUCTON_LISTING_SUCCESS,
      payload: construction,
    }
  }
  
  export const coConstructionListingFail = error => {
    return {
      type: CO_GET_CONSTRUCTON_LISTING_FAILED,
      payload: error,
    }
  }