import {
  CO_GET_PACKAGING_LISTING,
  CO_GET_PACKAGING_LISTING_SUCCESS,
  CO_GET_PACKAGING_LISTING_FAILED,
  } from "./actionTypes"
  
  export const coPackagingListing = () => {
    return {
      type: CO_GET_PACKAGING_LISTING,
    }
  }
  
  export const coPackagingListingSuccess = packaging => {
    return {
      type: CO_GET_PACKAGING_LISTING_SUCCESS,
      payload: packaging,
    }
  }
  
  export const coPackagingListingFail = error => {
    return {
      type: CO_GET_PACKAGING_LISTING_FAILED,
      payload: error,
    }
  }
  