import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_INCOTERMCITY_LISTING,
  ADD_INCOTERM,
  CHANGE_INCOTERM_STATUS,
  EDIT_INCOTERM,
  UPDATE_INCOTERM,
  FETCH_INCOTERM_BY_SECTION,
  INCOTERM_CITY_POSITION,
} from "./actionTypes"
import {
  incotermCityListingSuccess,
  incotermCityListingFail,
  addIncotermSuccess,
  addIncotermFail,
  changeIncotermStatusSuccess,
  changeIncotermStatusFail,
  editIncotermSuccess,
  editIncotermFail,
  updateIncotermSuccess,
  updateIncotermFail,
  fetchIncotermListingBySectionSuccess,
  fetchIncotermListingBySectionFail,
  incotermCityPositionSuccess,
  incotermCityPositionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  incotermCityListingApi,
  addIncotermApi,
  changeIncotermStatusApi,
  editIncotermApi,
  updateIncotermApi,
  fetchDropdownsBySectionApi,
  fetchDropDownPositionApi,
} from "../../../helpers/fakebackend_helper"

function* getIncotermCityListing() {
  try {
    const response = yield call(incotermCityListingApi)
    if (!response.results.incoterm_city) {
      yield put(incotermCityListingFail(error))
    }
    yield put(incotermCityListingSuccess(response.results.incoterm_city))
  } catch (error) {
    yield put(incotermCityListingFail(error))
  }
}

function* addIncoterm({ payload: { incotermCity, history } }) {
  try {
    const response = yield call(addIncotermApi, incotermCity)
    if (response && response.status) {
      yield put(addIncotermSuccess(response))
      history.push("/dropdown/incoterm_city/list")
    } else {
      throw response.msg ? response.msg : "Failed to add"
    }
  } catch (error) {
    yield put(addIncotermFail(error))
  }
}

function* onIncotermChange({ payload: { id } }) {
  try {
    const response = yield call(changeIncotermStatusApi, {
      incoterm_city_id: id,
    })
    if (response.status) {
      response.id = id
      yield put(changeIncotermStatusSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to change status"
    }
  } catch (error) {
    yield put(changeIncotermStatusFail(error))
  }
}

function* getIncotermDetailsById({ payload: { id } }) {
  try {
    const response = yield call(editIncotermApi, id)
    if (response && response.status) {
      yield put(editIncotermSuccess(response.results.incoterm_city))
    } else {
      throw response.msg ? response.msg : "Failed to edit"
    }
  } catch (error) {
    yield put(editIncotermFail(error))
  }
}

function* onUpdateIncoterm({ payload: { incotermCityUpdate, history } }) {
  try {
    const response = yield call(updateIncotermApi, incotermCityUpdate)
    if (response && response.status) {
      yield put(updateIncotermSuccess(response))
      history.push("/dropdown/incoterm_city/list")
    } else {
      throw response.msg ? response.msg : "Failed to update"
    }
  } catch (error) {
    yield put(updateIncotermFail(error))
  }
}

function* fetchIncotermListingBySection({ payload: { data } }) {
  try {
    const response = yield call(fetchDropdownsBySectionApi, data)
    if (!response?.results?.incoterm_city) {
      throw response.msg ? response.msg : "Failed to fetch details"
    }
    yield put(
      fetchIncotermListingBySectionSuccess(response.results.incoterm_city)
    )
  } catch (error) {
    yield put(fetchIncotermListingBySectionFail(error))
  }
}

function* incotermCityPosition({ payload: { position } }) {
  try {
    const response = yield call(fetchDropDownPositionApi, position)
    if (response && response.status) {
      yield put(incotermCityPositionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update position"
    }
  } catch (error) {
    let msg = error ? { msg: error } : { msg: "Failed to update position" }
    yield put(incotermCityPositionFail(msg))
  }
}

function* incotermCitySaga() {
  yield takeEvery(GET_INCOTERMCITY_LISTING, getIncotermCityListing)
  yield takeEvery(ADD_INCOTERM, addIncoterm)
  yield takeEvery(CHANGE_INCOTERM_STATUS, onIncotermChange)
  yield takeEvery(EDIT_INCOTERM, getIncotermDetailsById)
  yield takeEvery(UPDATE_INCOTERM, onUpdateIncoterm)
  yield takeEvery(FETCH_INCOTERM_BY_SECTION, fetchIncotermListingBySection)
  yield takeEvery(INCOTERM_CITY_POSITION, incotermCityPosition)
}
export default incotermCitySaga
