export const GET_OFFICE_LISTING = "GET_OFFICE_LISTING"
export const GET_OFFICE_LISTING_SUCCESS = "GET_OFFICE_LISTING_SUCCESS"
export const GET_OFFICE_LISTING_FAILED = "GET_OFFICE_LISTING_FAILED"

export const FETCH_OFFICE_BY_SECTION = "FETCH_OFFICE_BY_SECTION"
export const FETCH_OFFICE_BY_SECTION_SUCCESS = "FETCH_OFFICE_BY_SECTION_SUCCESS"
export const FETCH_OFFICE_BY_SECTION_FAIL = "FETCH_OFFICE_BY_SECTION_FAIL"

export const ADD_OFFICE = "ADD_OFFICE"
export const ADD_OFFICE_SUCCESS = "ADD_OFFICE"
export const ADD_OFFICE_FAIL = "ADD_OFFICE_FAIL"

export const EDIT_OFFICE = "EDIT_OFFICE"
export const EDIT_OFFICE_SUCCESS = "EDIT_OFFICE_SUCCESS"
export const EDIT_OFFICE_FAIL = "EDIT_OFFICE_FAIL"

export const UPDATE_OFFICE = "UPDATE_OFFICE"
export const UPDATE_OFFICE_SUCCESS = "UPDATE_OFFICE_SUCCESS"
export const UPDATE_OFFICE_FAIL = "UPDATE_OFFICE_FAIL"

export const OFFICE_ACTIVE_INACTIVE = "OFFICE_ACTIVE_INACTIVE"
export const OFFICE_ACTIVE_INACTIVE_SUCCESS = "OFFICE_ACTIVE_INACTIVE_SUCCESS"
export const OFFICE_ACTIVE_INACTIVE_FAIL = "OFFICE_ACTIVE_INACTIVE_FAIL"

export const OFFICE_POSITION = "OFFICE_POSITION"
export const OFFICE_POSITION_SUCCESS = "OFFICE_POSITION_SUCCESS"
export const OFFICE_POSITION_FAIL = "OFFICE_POSITION_FAIL"
