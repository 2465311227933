import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { BD_GET_PRODUCTS_LISTING, BD_PRODUCT_EDIT, } from "./actionTypes"

import {
  bdProductsListingSuccess,
  bdProductsListingFail,
  bdEditProductSuccess,
  bdEditProductFail,
} from "./actions"

import { bdProductListingApi, bdEditProductApi, } from "../../../helpers/fakebackend_helper"

function* getBdProductListing() {
  try {
    const response = yield call(bdProductListingApi)
    if (!response.results.product_dtls) {
      yield put(bdProductsListingFail(error))
    }
    yield put(bdProductsListingSuccess(response.results.product_dtls))
  } catch (error) {
    yield put(bdProductsListingFail(error))
  }
}

function* bdGetProductDetailsById({ payload: { productId } }) {
  try {
    const response = yield call(bdEditProductApi, productId)
    if (response && response.status) {
      yield put(bdEditProductSuccess(response.results.prod_dtls))
    } else {
      throw response.msg ? response.msg : "Failed to edit product"
    }
  } catch (error) {
    yield put(bdEditProductFail(error))
  }
}

function* bdProductSaga() {
  yield takeEvery(BD_GET_PRODUCTS_LISTING, getBdProductListing)
  yield takeEvery(BD_PRODUCT_EDIT, bdGetProductDetailsById)
}

export default bdProductSaga
