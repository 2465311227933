export const GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING =
  "GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING"
export const GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_SUCCESS =
  "GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_SUCCESS"
export const GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_FAILED =
  "GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_FAILED"

export const ADD_CERTIFICATION = "ADD_CERTIFICATION"
export const ADD_CERTIFICATION_SUCCESS = "ADD_CERTIFICATION_SUCCESS"
export const ADD_CERTIFICATION_FAIL = "ADD_CERTIFICATION_FAIL"

export const CHANGE_CERTIFICATION_STATUS = "CHANGE_CERTIFICATION_STATUS"
export const CHANGE_CERTIFICATION_STATUS_SUCCESS =
  "CHANGE_CERTIFICATION_STATUS_SUCCESS"
export const CHANGE_CERTIFICATION_STATUS_FAIL =
  "CHANGE_CERTIFICATION_STATUS_FAIL"

export const EDIT_CERTIFICATION = "EDIT_CERTIFICATION"
export const EDIT_CERTIFICATION_SUCCESS = "EDIT_CERTIFICATION_SUCCESS"
export const EDIT_CERTIFICATION_FAIL = "EDIT_CERTIFICATION_FAIL"

export const UPDATE_CERTIFICATION = "UPDATE_CERTIFICATION"
export const UPDATE_CERTIFICATION_SUCCESS = "UPDATE_CERTIFICATION_SUCCESS"
export const UPDATE_CERTIFICATION_FAIL = "UPDATE_CERTIFICATION_FAIL"

export const FETCH_CERTIFICATION_BY_SECTION = "FETCH_CERTIFICATION_BY_SECTION"
export const FETCH_CERTIFICATION_BY_SECTION_SUCCESS =
  "FETCH_CERTIFICATION_BY_SECTION_SUCCESS"
export const FETCH_CERTIFICATION_BY_SECTION_FAIL =
  "FETCH_CERTIFICATION_BY_SECTION_FAIL"

export const UPDATE_CERTIFICATION_POSITION = "UPDATE_CERTIFICATION_POSITION"
export const UPDATE_CERTIFICATION_POSITION_SUCCESS =
  "UPDATE_CERTIFICATION_POSITION_SUCCESS"
export const UPDATE_CERTIFICATION_POSITION_FAIL =
  "UPDATE_CERTIFICATION_POSITION_FAIL"
