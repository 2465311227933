export const GET_DELIVEREDON_LISTING = "GET_DELIVEREDON_LISTING"
export const GET_DELIVEREDON_LISTING_SUCCESS = "GET_DELIVEREDON_LISTING_SUCCESS"
export const GET_DELIVEREDON_LISTING_FAILED = "GET_DELIVEREDON_LISTING_FAILED"

export const ADD_DELIVERED = "ADD_DELIVERED"
export const ADD_DELIVERED_SUCCESS = "ADD_DELIVERED_SUCCESS"
export const ADD_DELIVERED_FAIL = "ADD_DELIVERED_FAIL"

export const CHANGE_DELIVERED_STATUS = "CHANGE_DELIVERED_STATUS"
export const CHANGE_DELIVERED_STATUS_SUCCESS = "CHANGE_DELIVERED_STATUS_SUCCESS"
export const CHANGE_DELIVERED_STATUS_FAIL = "CHANGE_DELIVERED_STATUS_FAIL"

export const EDIT_DELIVERED = "EDIT_DELIVERED"
export const EDIT_DELIVERED_SUCCESS = "EDIT_DELIVERED_SUCCESS"
export const EDIT_DELIVERED_FAIL = "EDIT_DELIVERED_FAIL"

export const UPDATE_DELIVERED = "UPDATE_DELIVERED"
export const UPDATE_DELIVERED_SUCCESS = "UPDATE_DELIVERED_SUCCESS"
export const UPDATE_DELIVERED_FAIL = "UPDATE_DELIVERED_FAIL"

export const FETCH_DELIVERED_BY_SECTION = "FETCH_DELIVERED_BY_SECTION"
export const FETCH_DELIVERED_BY_SECTION_SUCCESS = "FETCH_DELIVERED_BY_SECTION_SUCCESS"
export const FETCH_DELIVERED_BY_SECTION_FAIL = "FETCH_DELIVERED_BY_SECTION_FAIL"

export const UPDATE_DELIVERED_POSITION = "UPDATE_DELIVERED_POSITION"
export const UPDATE_DELIVERED_POSITION_SUCCESS =
  "UPDATE_DELIVERED_POSITION_SUCCESS"
export const UPDATE_DELIVERED_POSITION_FAIL = "UPDATE_DELIVERED_POSITION_FAIL"
