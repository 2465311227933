export const GET_HEAVEYCOLLI_LISTING = "GET_HEAVEYCOLLI_LISTING"
export const GET_HEAVEYCOLLI_LISTING_SUCCESS = "GET_HEAVEYCOLLI_LISTING_SUCCESS"
export const GET_HEAVEYCOLLI_LISTING_FAILED = "GET_HEAVEYCOLLI_LISTING_FAILED"

export const ADD_HEAVEY_COLLI = "ADD_HEAVEY_COLLI"
export const ADD_HEAVEY_COLLI_SUCCESS = "ADD_HEAVEY_COLLI_SUCCESS"
export const ADD_HEAVEY_COLLI_FAIL = "ADD_HEAVEY_COLLI_FAIL"

export const CHANGE_HEAVEY_COLLI_STATUS = "CHANGE_HEAVEY_COLLI_STATUS"
export const CHANGE_HEAVEY_COLLI_STATUS_SUCCESS =
  "CHANGE_HEAVEY_COLLI_STATUS_SUCCESS"
export const CHANGE_HEAVEY_COLLI_STATUS_FAIL = "CHANGE_HEAVEY_COLLI_STATUS_FAIL"

export const EDIT_HEAVEY_COLLI = "EDIT_HEAVEY_COLLI"
export const EDIT_HEAVEY_COLLI_SUCCESS = "EDIT_HEAVEY_COLLI_SUCCESS"
export const EDIT_HEAVEY_COLLI_FAIL = "EDIT_HEAVEY_COLLI_FAIL"

export const UPDATE_HEAVEY_COLLI = "UPDATE_HEAVEY_COLLI"
export const UPDATE_HEAVEY_COLLI_SUCCESS = "UPDATE_HEAVEY_COLLI_SUCCESS"
export const UPDATE_HEAVEY_COLLI_FAIL = "UPDATE_HEAVEY_COLLI_FAIL"

export const FETCH_HEAVEY_COLLI_BY_SECTION = "FETCH_HEAVEY_COLLI_BY_SECTION"
export const FETCH_HEAVEY_COLLI_BY_SECTION_SUCCESS =
  "FETCH_HEAVEY_COLLI_BY_SECTION_SUCCESS"
export const FETCH_HEAVEY_COLLI_BY_SECTION_FAIL =
  "FETCH_HEAVEY_COLLI_BY_SECTION_FAIL"

export const UPDATE_HEAVEY_COLLI_POSITION = "UPDATE_HEAVEY_COLLI_POSITION"
export const UPDATE_HEAVEY_COLLI_POSITION_SUCCESS =
  "UPDATE_HEAVEY_COLLI_POSITION_SUCCESS"
export const UPDATE_HEAVEY_COLLI_POSITION_FAIL =
  "UPDATE_HEAVEY_COLLI_POSITION_FAIL"
