import {
  BD_GET_DELIVEREDON_LISTING,
  BD_GET_DELIVEREDON_LISTING_SUCCESS,
  BD_GET_DELIVEREDON_LISTING_FAILED,
  } from "./actionTypes"
  
  export const bdDeliveredOnListing = () => {
    return {
      type: BD_GET_DELIVEREDON_LISTING,
    }
  }
  
  export const bdDeliveredOnListingSuccess = bdDeliveredOn => {
    return {
      type: BD_GET_DELIVEREDON_LISTING_SUCCESS,
      payload: bdDeliveredOn,
    }
  }
  
  export const bdDeliveredOnListingFail = error => {
    return {
      type: BD_GET_DELIVEREDON_LISTING_FAILED,
      payload: error,
    }
  }
  