import {
  GET_LOGS_LISTING,
  GET_LOGS_LISTING_SUCCESS,
  GET_LOGS_LISTING_FAILED,
  GET_ACTIVITY_LOG,
  GET_ACTIVITY_LOG_SUCCESS,
  GET_ACTIVITY_LOG_FAILED
} from "./actionTypes"

export const logsListing = (paginationData, history) => {
  return {
    type: GET_LOGS_LISTING,
    payload: { paginationData, history },
  }
}

export const logsListingSuccess = log => {
  return {
    type: GET_LOGS_LISTING_SUCCESS,
    payload: log,
  }
}

export const logsListingFail = error => {
  return {
    type: GET_LOGS_LISTING_FAILED,
    payload: error,
  }
}

export const activityLog = (paginationData, history) => {
  return {
    type: GET_ACTIVITY_LOG,
    payload: { paginationData, history },
  }
}

export const activityLogSuccess = log => {
  return {
    type: GET_ACTIVITY_LOG_SUCCESS,
    payload: log,
  }
}

export const activityLogFail = error => {
  return {
    type: GET_ACTIVITY_LOG_FAILED,
    payload: error,
  }
}
