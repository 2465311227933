import {
  BD_OFFER_ADD,
  BD_OFFER_SUCCESS,
  BD_OFFER_FAIL,
  BD_GET_OFFERS_LISTING,
  BD_GET_OFFERS_LISTING_SUCCESS,
  BD_GET_OFFERS_LISTING_FAILED,
  BD_OFFER_EDIT,
  BD_OFFER_EDIT_SUCCESS,
  BD_OFFER_EDIT_FAIL,
  BD_OFFER_UPDATE,
  BD_OFFER_UPDATE_SUCCESS,
  BD_OFFER_UPDATE_FAIL,
  BD_CHANGE_OFFER_STATUS,
  BD_CHANGE_OFFER_STATUS_SUCCESS,
  BD_CHANGE_OFFER_STATUS_FAIL,
  BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
  BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS,
  BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL,
  BD_RESET_OFFER,
  BD_RESET_OFFER_SUCCESS,
  BD_RESET_OFFER_FAIL
} from "./actionTypes"

const initialState = {
  error: null,
  loading: false,
  offer: [],
  msg: null,
  offerEdit: null,
  offerUpdate: null,
  search: null,
}

const bdOffer = (state = initialState, action) => {
  switch (action.type) {
    case BD_OFFER_ADD:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        search: null,
        offer: action.payload,
      }
      break
    case BD_OFFER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: action?.payload?.msg,
        offer: action.payload,
      }
      break
    case BD_OFFER_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case BD_GET_OFFERS_LISTING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        offer: action.payload,
        search: null,
      }
      break
    case BD_GET_OFFERS_LISTING_SUCCESS:
      state = {
        ...state,
        error: null,
        msg: null,
        search: null,
        loading: false,
        offer: action.payload,
      }
      break
    case BD_GET_OFFERS_LISTING_FAILED:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case BD_OFFER_EDIT:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        offerEdit: action.payload,
      }
      break
    case BD_OFFER_EDIT_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        offer: [action.payload],
      }
      break
    case BD_OFFER_EDIT_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case BD_OFFER_UPDATE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        offerUpdate: action.payload,
      }
      break
    case BD_OFFER_UPDATE_SUCCESS:
      state = {
        ...state,
        error: null,
        msg: action?.payload?.msg,
        loading: false,
      }
      break
    case BD_OFFER_UPDATE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case BD_CHANGE_OFFER_STATUS:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        search: null,
      }
      break
    case BD_CHANGE_OFFER_STATUS_SUCCESS:
      const index = state.offer.findIndex(
        offer => offer.offer_id === action.payload.id
      )
      let newOfferAfterStatusChange = []
      newOfferAfterStatusChange = [...state.offer] //making a new array
      newOfferAfterStatusChange[index].status =
        !newOfferAfterStatusChange[index].status

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        offer: newOfferAfterStatusChange,
      }
      break

    case BD_CHANGE_OFFER_STATUS_FAIL:
    default:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        search: action.payload,
        offerEdit: [],
      }
      break
    case BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        search: action.payload,
      }
      break
    case BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
      case BD_RESET_OFFER:
        state = initialState
        break
  
      case BD_RESET_OFFER_SUCCESS:
        state = initialState
        break
  
      case BD_RESET_OFFER_FAIL:
        state = initialState
        break
  }
  return state
}

export default bdOffer
