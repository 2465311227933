import {
  BD_GET_DELIVEREDON_LISTING,
  BD_GET_DELIVEREDON_LISTING_SUCCESS,
  BD_GET_DELIVEREDON_LISTING_FAILED,
  } from "./actionTypes"
  
  const initialState = {
    error: "",
    loading: false,
    bdDeliveredOn: null,
  }
  
  const bdDeliveredOn = (state = initialState, action) => {
    switch (action.type) {
      case BD_GET_DELIVEREDON_LISTING:
        state = {
          ...state,
          loading: true,
          bdDeliveredOn: action.payload,
        }
        break
      case BD_GET_DELIVEREDON_LISTING_SUCCESS:
        state = { ...state, loading: false, bdDeliveredOn: action.payload }
        break
      case BD_GET_DELIVEREDON_LISTING_FAILED:
        state = { ...state, loading: false }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default bdDeliveredOn
  