import {
  CO_GET_INCOTERMCITY_LISTING,
  CO_GET_INCOTERMCITY_LISTING_SUCCESS,
  CO_GET_INCOTERMCITY_LISTING_FAILED,
  } from "./actionTypes"
  
  export const coIncotermCityListing = () => {
    return {
      type: CO_GET_INCOTERMCITY_LISTING,
    }
  }
  
  export const coIncotermCityListingSuccess = incotermCity => {
    return {
      type: CO_GET_INCOTERMCITY_LISTING_SUCCESS,
      payload: incotermCity,
    }
  }
  
  export const coIncotermCityListingFail = error => {
    return {
      type: CO_GET_INCOTERMCITY_LISTING_FAILED,
      payload: error,
    }
  }
  
