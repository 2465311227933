import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Redux States
import {
  GET_ROLES_LISTING,
  FETCH_ROLES_BY_SECTION,
  ADD_ROLE,
  EDIT_ROLE,
  UPDATE_ROLE,
  USER_ROLE_ACTIVE_INACTIVE,
  UPDATE_USER_ROLE_POSITION,
} from "./actionTypes"

import {
  roleListingSuccess,
  roleListingFail,
  fetchRoleListingBySectionSuccess,
  fetchRoleListingBySectionFail,
  addRoleSuccess,
  addRoleFail,
  editRoleSuccess,
  editRoleFail,
  updateRoleSuccess,
  updateRoleFail,
  changeUserRoleStatusSuccess,
  changeUserRoleStatusFail,
  updateUserRolePositionSuccess,
  updateUserRolePositionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  rolesListingApi,
  fetchDropdownsBySectionApi,
  addUserRoleApi,
  editUserRoleApi,
  updateUserRoleApi,
  fetchDropDownPositionApi,
  changeUserRoleApi,
} from "../../../helpers/fakebackend_helper"

function* getRoleListing() { // this is the dropdown for main user section(add and edit)
  try {
    const response = yield call(rolesListingApi)
    if (!response.results.roles_list) {
      yield put(roleListingFail(error))
    }
    yield put(roleListingSuccess(response.results.roles_list))
  } catch (error) {
    yield put(roleListingFail(error))
  }
}

function* fetchRoleListingBySection({ payload: { data } }) { // this is the list for dropdown section(user roles)
  try {
    const response = yield call(fetchDropdownsBySectionApi, data)
    if (!response?.results?.roles_list) {
      throw response.msg ? response.msg : "Failed to fetch details"
    }
    yield put(fetchRoleListingBySectionSuccess(response.results.roles_list))
  } catch (error) {
    yield put(fetchRoleListingBySectionFail(error))
  }
}

function* addRole({ payload: { data, history } }) {
  try {
    const response = yield call(addUserRoleApi, data)
    if (response && response.status) {
      yield put(addRoleSuccess(response))
      history.push("/dropdown/user_role/list")
    } else {
      throw response.msg ? response.msg : "Failed to add"
    }
  } catch (error) {
    yield put(addRoleFail(error))
  }
}

function* editRole({ payload: { id } }) {
  try {
    const response = yield call(editUserRoleApi, id)
    if (response && response.status) {
      response.id = id
      yield put(editRoleSuccess(response.results.user_type))
    } else {
      throw response.msg ? response.msg : "Failed to edit"
    }
  } catch (error) {
    yield put(editRoleFail(error))
  }
}

function* updateRole({ payload: { updateRole, history } }) {
  try {
    const response = yield call(updateUserRoleApi, updateRole)
    if (response && response.status) {
      yield put(updateRoleSuccess(response))
      history.push("/dropdown/user_role/list")
    } else {
      throw response.msg ? response.msg : "Failed to update"
    }
  } catch (error) {
    yield put(updateRoleFail(error))
  }
}

function* changeRoleStatus({ payload: { id } }) {
  try {
    const response = yield call(changeUserRoleApi, { role_id: id })
    if (response && response.status) {
      response.role_id = id
      yield put(changeUserRoleStatusSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update status"
    }
  } catch (error) {
    yield put(changeUserRoleStatusFail(error))
  }
}

function* updateRolePosition({ payload: { position } }) {
  try {
    const response = yield call(fetchDropDownPositionApi, position)
    if (response && response.status) {
      yield put(updateUserRolePositionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update position"
    }
  } catch (error) {
    let msg = error ? { msg: error } : { msg: "Failed to update position" }
    yield put(updateUserRolePositionFail(msg))
  }
}

function* roleSaga() {
  yield takeEvery(GET_ROLES_LISTING, getRoleListing)
  yield takeEvery(FETCH_ROLES_BY_SECTION, fetchRoleListingBySection)
  yield takeEvery(ADD_ROLE, addRole)
  yield takeEvery(EDIT_ROLE, editRole)
  yield takeEvery(UPDATE_ROLE, updateRole)
  yield takeEvery(USER_ROLE_ACTIVE_INACTIVE, changeRoleStatus)
  yield takeEvery(UPDATE_USER_ROLE_POSITION, updateRolePosition)
}
export default roleSaga
