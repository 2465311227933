import {
  USER_SUCCESS,
  USER_FAIL,
  USER_ADD,
  GET_USERS_LISTING,
  GET_USERS_LISTING_SUCCESS,
  GET_USERS_LISTING_FAILED,
  CHANGE_USER_STATUS,
  CHANGE_USER_STATUS_SUCCESS,
  CHANGE_USER_STATUS_FAIL,
  USER_EDIT,
  USER_EDIT_SUCCESS,
  USER_EDIT_FAIL,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_RESET_PASSWORD,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
} from "./actionTypes"

export const userSuccess = user => {
  return {
    type: USER_SUCCESS,
    payload: user,
  }
}

export const userFail = error => {
  return {
    type: USER_FAIL,
    payload: error,
  }
}

export const addNewUser = (user, history) => {
  return {
    type: USER_ADD,
    payload: { user, history },
  }
}

export const usersListing = history => {
  return {
    type: GET_USERS_LISTING,
  }
}

export const usersListingSuccess = user => {
  return {
    type: GET_USERS_LISTING_SUCCESS,
    payload: user,
  }
}

export const usersListingFail = error => {
  return {
    type: GET_USERS_LISTING_FAILED,
    payload: error,
  }
}

export const changeUserStatus = userId => ({
  type: CHANGE_USER_STATUS,
  payload: { id: userId },
})

export const changeUserStatusSuccess = user => ({
  type: CHANGE_USER_STATUS_SUCCESS,
  payload: user,
})

export const changeUserStatusFail = error => ({
  type: CHANGE_USER_STATUS_FAIL,
  payload: error,
})

export const userEdit = userId => {
  return {
    type: USER_EDIT,
    payload: { userId: userId },
  }
}

export const editUserSuccess = user => {
  return {
    type: USER_EDIT_SUCCESS,
    payload: user,
  }
}
export const editUserFail = error => {
  return {
    type: USER_EDIT_FAIL,
    payload: error,
  }
}

export const updateUser = (user, history) => {
  return {
    type: USER_UPDATE,
    payload: { user, history },
  }
}

export const updateUserSuccess = user => {
  return {
    type: USER_UPDATE_SUCCESS,
    payload: user,
  }
}

export const updateUserFail = error => {
  return {
    type: USER_UPDATE_FAIL,
    payload: error,
  }
}

export const resetUserPassword = id => {
  return {
    type: USER_RESET_PASSWORD,
    payload: { id: id },
  }
}

export const resetUserPasswordSuccess = data => {
  return {
    type: USER_RESET_PASSWORD_SUCCESS,
    payload: data,
  }
}

export const resetUserPasswordFail = data => {
  return {
    type: USER_RESET_PASSWORD_FAIL,
    payload: data,
  }
}

// export const addUserRole = (userRole, history) => {
//   return {
//     type: USER_ROLE_ADD,
//     payload: { userRole, history },
//   }
// }

// export const addUserRoleSuccess = userRole => {
//   return {
//     type: USER_ROLE_ADD_SUCCESS,
//     payload: userRole,
//   }
// }

// export const addUserRoleFail = error => {
//   return {
//     type: USER_ROLE_ADD_FAIL,
//     payload: error,
//   }
// }
