import PropTypes, { func } from "prop-types"
import React, { useEffect, useRef } from "react"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
// import {RiDashboardFill} from "react/icons/ri"
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import ChairIcon from "@mui/icons-material/Chair"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import DownloadIcon from "@mui/icons-material/Download"
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown"
import ListAltIcon from "@mui/icons-material/ListAlt"

let sidebarContentHtml = null

let userType = ""
const currentUser = JSON.parse(sessionStorage.getItem("authUser"))

let authProtectedRoutes = []
if (currentUser && currentUser.accessToken && currentUser.user_role_short) {
  userType = currentUser.user_role_short
}

function getAdminSidebar(props) {
  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
        <li className="menu-title">{props.t("Menu")} </li>
        <li>
          <Link to="/dashboard">
            {/* <i className="bx bx-home-circle"></i> */}
            <CircleNotificationsIcon />
            <span>{props.t("Dashboard")}</span>
          </Link>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            {/* <i className="bx bx-male" /> */}
            <ManageAccountsIcon />
            <span>{props.t("Users")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/users/add">{props.t("Add")}</Link>
            </li>
            <li>
              <Link to="/users/list">{props.t("List")}</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            {/* <i className="bx bx-store" /> */}
            <ChairIcon />
            <span>{props.t("Products")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/products/add">{props.t("Add")}</Link>
            </li>
            <li>
              <Link to="/products/list">{props.t("List")}</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            {/* <i className="bx bx-coin-stack" /> */}
            <MonetizationOnIcon />
            <span>{props.t("Offers")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/offers/list">{props.t("List")}</Link>
            </li>
            <li>
              <Link to="/offers/edit">{props.t("Edit Offers")}</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            {/* <i className="bx bx-export" /> */}
            <DownloadIcon />
            <span>{props.t("Exports")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/export/collection/add">
                {props.t("Add Collection")}
              </Link>
            </li>
            <li>
              <Link to="/export/collection">{props.t("Collections")}</Link>
            </li>
            <li>
              <Link to="/export/add">{props.t("Export")}</Link>
            </li>
            <li>
              <Link to="/export/download">{props.t("Download")}</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            {/* <i className="bx bx-home" /> */}
            <KeyboardDoubleArrowDownIcon />
            <span>{props.t("Dropdowns")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/dropdown/product_category/list">
                {props.t("Product Category")}
              </Link>
            </li>
            {/* 
            <li>
              <Link to="/dropdown/quality_rating/list">
                {props.t("Quality Ratings")}
              </Link>
            </li> */}

            <li>
              <Link to="/dropdown/construction/list">
                {props.t("Construction")}
              </Link>
            </li>

            <li>
              <Link to="/dropdown/delivered_on/list">
                {props.t("Delivered On")}
              </Link>
            </li>

            <li>
              <Link to="/dropdown/env_health_certificate/list">
                {props.t("Env & Health Certification")}
              </Link>
            </li>

            <li>
              <Link to="/dropdown/heavey_colli/list">
                {props.t("Heavy Colli")}
              </Link>
            </li>

            <li>
              <Link to="/dropdown/incoterm_city/list">
                {props.t("Incoterm City")}
              </Link>
            </li>

            <li>
              <Link to="/dropdown/material_standard/list">
                {props.t("Material Standard")}
              </Link>
            </li>

            <li>
              <Link to="/dropdown/offer_status/list">
                {props.t("Offer Status")}
              </Link>
            </li>

            <li>
              <Link to="/dropdown/office/list">{props.t("Offices")}</Link>
            </li>

            <li>
              <Link to="/dropdown/packaging/list">{props.t("Packaging")}</Link>
            </li>

            <li>
              <Link to="/dropdown/packing_standard/list">
                {props.t("Packing Standard")}
              </Link>
            </li>

            <li>
              <Link to="/dropdown/user_role/list">{props.t("User Role")}</Link>
            </li>

            <li>
              <Link to="/dropdown/supplier_name/list">
                {props.t("Supplier Name")}
              </Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            {/* <i className="bx bx-circle" /> */}
            <ListAltIcon />
            <span>{props.t("Logs")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/log/list">{props.t("List")}</Link>
            </li>
            <li>
              <Link to="/log/activity">{props.t("Activity")}</Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}

function getBusinessDeveloperSidebar(props) {
  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
        <li className="menu-title">{props.t("Menu")} </li>
        <li>
          <Link to="/dashboard">
            <CircleNotificationsIcon />
            <span>{props.t("Dashboard")}</span>
          </Link>
        </li>
        <li>
          <Link to="/#" className="has-arrow">
            <ChairIcon />
            <span>{props.t("Products")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/products/list">{props.t("List")}</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            <MonetizationOnIcon />
            <span>{props.t("Offers")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/offers/list">{props.t("List")}</Link>
            </li>
            <li>
              <Link to="/offers/edit">{props.t("Edit Offers")}</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            <DownloadIcon />
            <span>{props.t("Exports")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/export/collection/add">
                {props.t("Add Collection")}
              </Link>
            </li>
            <li>
              <Link to="/export/collection">{props.t("Collections")}</Link>
            </li>
            <li>
              <Link to="/export/add">{props.t("Export")}</Link>
            </li>
            <li>
              <Link to="/export/download">{props.t("Download")}</Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}

function getCoordinatorSidebar(props) {
  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
        <li className="menu-title">{props.t("Menu")} </li>
        <li>
          <Link to="/dashboard">
            <CircleNotificationsIcon />
            <span>{props.t("Dashboard")}</span>
          </Link>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            <ChairIcon />
            <span>{props.t("Products")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/products/add">{props.t("Add")}</Link>
            </li>
            <li>
              <Link to="/products/list">{props.t("List")}</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            <MonetizationOnIcon />
            <span>{props.t("Offers")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/offers/list">{props.t("List")}</Link>
            </li>
            <li>
              <Link to="/offers/edit">{props.t("Edit Offers")}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/#" className="has-arrow">
            <DownloadIcon />
            <span>{props.t("Exports")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/export/collection/add">
                {props.t("Add Collection")}
              </Link>
            </li>
            <li>
              <Link to="/export/collection">{props.t("Collections")}</Link>
            </li>
            <li>
              <Link to="/export/add">{props.t("Export")}</Link>
            </li>
            <li>
              <Link to="/export/download">{props.t("Download")}</Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}

function getQualityAnalystSidebar(props) {
  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
        <li className="menu-title">{props.t("Menu")} </li>
        <li>
          <Link to="/dashboard">
            <CircleNotificationsIcon />
            <span>{props.t("Dashboard")}</span>
          </Link>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            <ChairIcon />
            <span>{props.t("Products")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/products/list">{props.t("List")}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/#" className="has-arrow">
            <MonetizationOnIcon />
            <span>{props.t("Offers")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/offers/list">{props.t("List")}</Link>
            </li>
            <li>
              <Link to="/offers/edit">{props.t("Edit Offers")}</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            <DownloadIcon />
            <span>{props.t("Exports")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/export/collection/add">
                {props.t("Add Collection")}
              </Link>
            </li>
            <li>
              <Link to="/export/collection">{props.t("Collections")}</Link>
            </li>
            <li>
              <Link to="/export/add">{props.t("Export")}</Link>
            </li>
            <li>
              <Link to="/export/download">{props.t("Download")}</Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}

function getSalesAgentSidebar(props) {
  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
        <li className="menu-title">{props.t("Menu")} </li>
        <li>
          <Link to="/dashboard">
            <CircleNotificationsIcon />
            <span>{props.t("Dashboard")}</span>
          </Link>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            <ChairIcon />
            <span>{props.t("Products")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/products/list">{props.t("List")}</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            <MonetizationOnIcon />
            <span>{props.t("Offers")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/offers/list">{props.t("List")}</Link>
            </li>
            <li>
              <Link to="/offers/edit">{props.t("Edit Offers")}</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            <DownloadIcon />
            <span>{props.t("Exports")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/export/collection/add">
                {props.t("Add Collection")}
              </Link>
            </li>
            <li>
              <Link to="/export/collection">{props.t("Collections")}</Link>
            </li>
            <li>
              <Link to="/export/add">{props.t("Export")}</Link>
            </li>
            <li>
              <Link to="/export/download">{props.t("Download")}</Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}

const SidebarContent = props => {
  const ref = useRef()
  if (userType !== "" && userType == "AM") {
    sidebarContentHtml = getAdminSidebar(props)
  } else if (userType !== "" && userType == "BD") {
    sidebarContentHtml = getBusinessDeveloperSidebar(props)
  } else if (userType !== "" && userType == "CO") {
    sidebarContentHtml = getCoordinatorSidebar(props)
  } else if (userType !== "" && userType == "QA") {
    sidebarContentHtml = getQualityAnalystSidebar(props)
  } else if (userType !== "" && userType == "SA") {
    sidebarContentHtml = getSalesAgentSidebar(props)
  }
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={ref}>
          {sidebarContentHtml ? sidebarContentHtml : null}
        </SimpleBar>
      </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
