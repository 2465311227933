import {
    GET_PRODUCT_NUMBER_LISTING,
    GET_PRODUCT_NUMBER_LISTING_SUCCESS,
    GET_PRODUCT_NUMBER_LISTING_FAILED,
  } from "./actionTypes"

  export const productNumberListing = () => {
    return {
      type: GET_PRODUCT_NUMBER_LISTING,
    }
  }
  
  export const productNumberListingSuccess = productNumber => {
    return {
      type: GET_PRODUCT_NUMBER_LISTING_SUCCESS,
      payload: productNumber,
    }
  }
  
  export const productNumberListingFail = error => {
    return {
      type: GET_PRODUCT_NUMBER_LISTING_FAILED,
      payload: error,
    }
  }