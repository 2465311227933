import {
  GET_USERLISTACCORDINGROLE_LISTING,
  GET_USERLISTACCORDINGROLE_LISTING_SUCCESS,
  GET_USERLISTACCORDINGROLE_LISTING_FAILED,
} from "./actionTypes"

export const userListAccordingRoleListing = (userType, history) => {
  return {
    type: GET_USERLISTACCORDINGROLE_LISTING,
    payload: { userType, history },
  }
}

export const userListAccordingRoleSuccess = userListAccordingRole => {
  return {
    type: GET_USERLISTACCORDINGROLE_LISTING_SUCCESS,
    payload: userListAccordingRole,
  }
}

export const userListAccordingRoleFail = error => {
  return {
    type: GET_USERLISTACCORDINGROLE_LISTING_FAILED,
    payload: error,
  }
}
