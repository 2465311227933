import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { useHistory } from "react-router-dom"
// offer Redux States
import {
  OFFER_ADD,
  GET_OFFERS_LISTING,
  OFFER_DELETE,
  OFFER_EDIT,
  OFFER_UPDATE,
  CHANGE_OFFER_STATUS,
  SEARCH_ITEM_IN_NEW_OFFER_PAGE,
  RESET_OFFER,
} from "./actionTypes"
import {
  offerSuccess,
  offerFail,
  offersListingSuccess,
  offersListingFail,
  deleteOfferSuccess,
  deleteOfferFail,
  editOfferSuccess,
  editOfferFail,
  updateOfferSuccess,
  updateOfferFail,
  changeOfferStatusFail,
  changeOfferStatusSuccess,
  searchItemInNewOfferPageSuccess,
  searchItemInNewOfferPageFail,
  clearOfferResultError,
  clearOfferResultSuccess,
} from "./actions"

import {
  addOffer,
  offersListingApi,
  deleteOfferApi,
  editOfferApi,
  updateOfferApi,
  changeOfferStatusApi,
  searchItemInNewOfferPageApi,
} from "../../../helpers/fakebackend_helper"

function* addNewOffer({ payload: { offer, history } }) {
  try {
    const response = yield call(addOffer, offer)
    if (response && response.status) {
      yield put(offerSuccess(response))
      setTimeout(() => {
        history.push("/offers/list")
      }, 5000)
    } else {
      throw response.msg ? response.msg : "Failed to add offer"
    }
  } catch (error) {
    yield put(offerFail(error))
  }
}

function* getOfferListing() {
  try {
    const response = yield call(offersListingApi)
    if (
      response.results === undefined ||
      response.results.prod_offers.length == 0
    ) {
      throw response.msg
        ? response.msg
        : "Something went wrong. Please try again later."
    }
    yield put(offersListingSuccess(response.results.prod_offers))
  } catch (error) {
    yield put(offersListingFail(error))
  }
}

function* onDeleteOffer({ payload: { id } }) {
  try {
    const response = yield call(deleteOfferApi, { offer_id: id })
    if (response && response.status) {
      response.id = id
      yield put(deleteOfferSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to delete offer"
    }
  } catch (error) {
    yield put(deleteOfferFail(error))
  }
}

function* getOfferDetailsById({ payload: { id } }) {
  try {
    const response = yield call(editOfferApi, id)
    if (response && response.status) {
      response.id = id
      yield put(editOfferSuccess(response.results.offer_details))
    } else {
      throw response.msg ? response.msg : "Failed to fetch offer details"
    }
  } catch (error) {
    yield put(editOfferFail(error))
  }
}

function* onUpdateOffer({ payload: { offer, history } }) {
  try {
    const response = yield call(updateOfferApi, offer)
    if (response && response.status) {
      yield put(updateOfferSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update offer"
    }
  } catch (error) {
    yield put(updateOfferFail(error))
  }
}

function* onOfferStatusChange({ payload: { id } }) {
  try {
    const response = yield call(changeOfferStatusApi, { offer_id: id })
    if (response.status) {
      response.id = id
      yield put(changeOfferStatusSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to delete offer"
    }
  } catch (error) {
    yield put(changeOfferStatusFail(error))
  }
}

function* searchAtNewOfferPage({ payload: { search } }) {
  try {
    const response = yield call(searchItemInNewOfferPageApi, search)

    if (response && response.status && response?.results?.prod_offers) {
      yield put(searchItemInNewOfferPageSuccess(response.results.prod_offers))
    } else {
      throw response.msg ? response.msg : "No Record found"
    }
  } catch (error) {
    yield put(searchItemInNewOfferPageFail(error))
  }
}

function* clearOfferResult() {
  try {
    yield put(clearOfferResultSuccess())
  } catch (error) {
    yield put(clearOfferResultError(error))
  }
}

function* offerSaga() {
  yield takeEvery(OFFER_ADD, addNewOffer)
  yield takeEvery(GET_OFFERS_LISTING, getOfferListing)
  yield takeEvery(OFFER_DELETE, onDeleteOffer)
  yield takeEvery(OFFER_EDIT, getOfferDetailsById)
  yield takeEvery(OFFER_UPDATE, onUpdateOffer)
  yield takeEvery(CHANGE_OFFER_STATUS, onOfferStatusChange)
  yield takeEvery(SEARCH_ITEM_IN_NEW_OFFER_PAGE, searchAtNewOfferPage)
  yield takeEvery(RESET_OFFER, clearOfferResult)
}
export default offerSaga
