import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  CO_GET_CREATEDBY_LISTING,
} from "./actionTypes"
import {
  coCreatedByListingSuccess,
  coCreatedByListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  coCreatedByListingApi,
} from "../../../helpers/fakebackend_helper"

function* coGetCreatedByListing() {
  try {
    const response = yield call(coCreatedByListingApi)
    if (!response.results) {
      yield put(coCreatedByListingFail(error))
    }
    yield put(coCreatedByListingSuccess(response.results))
  } catch (error) {
    yield put(coCreatedByListingFail(error))
  }
}

function* coCreatedBySaga() {
  yield takeEvery(CO_GET_CREATEDBY_LISTING, coGetCreatedByListing)
}
export default coCreatedBySaga
