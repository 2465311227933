import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_CONSTRUCTON_LISTING,
  ADD_CONSTRUCTON,
  CHANGE_CONSTRUCTON_STATUS,
  EDIT_CONSTRUCTON,
  UPDATE_CONSTRUCTON,
  FETCH_CONSTRUCTON_BY_SECTION,
  UPDATE_CONSTRUCTION_POSITION,
} from "./actionTypes"
import {
  constructionListingSuccess,
  constructionListingFail,
  addConstructionSuccess,
  addConstructionFail,
  changeConstructionStatusSuccess,
  changeConstructionStatusFail,
  editConstructionSuccess,
  editConstructionFail,
  updateConstructionSuccess,
  updateConstructionFail,
  fetchConstructionListingBySectionSuccess,
  fetchConstructionListingBySectionFail,
  updateConstructionPositionSuccess,
  updateConstructionPositionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  constructionListingApi,
  addConstructionApi,
  changeConstructionStatusApi,
  editConstructionApi,
  updateConstructionApi,
  fetchDropdownsBySectionApi,
  fetchDropDownPositionApi,
} from "../../../helpers/fakebackend_helper"

function* getConstructionListing() {
  try {
    const response = yield call(constructionListingApi)
    if (!response.results.construction) {
      yield put(constructionListingFail(error))
    }
    yield put(constructionListingSuccess(response.results.construction))
  } catch (error) {
    yield put(constructionListingFail(error))
  }
}

function* addConstruction({ payload: { construction, history } }) {
  try {
    const response = yield call(addConstructionApi, construction)
    if (response && response.status) {
      yield put(addConstructionSuccess(response))
      history.push("/dropdown/construction/list")
    } else {
      throw response.msg ? response.msg : "Failed to add place"
    }
  } catch (error) {
    yield put(addConstructionFail(error))
  }
}

function* constructionStatusChange({ payload: { id } }) {
  try {
    const response = yield call(changeConstructionStatusApi, {
      construction_id: id,
    })
    if (response.status) {
      response.id = id
      yield put(changeConstructionStatusSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to change status"
    }
  } catch (error) {
    yield put(changeConstructionStatusFail(error))
  }
}

function* getConstructionDetailsById({ payload: { id } }) {
  try {
    const response = yield call(editConstructionApi, id)
    if (response && response.status) {
      yield put(editConstructionSuccess(response.results.construction))
    } else {
      throw response.msg ? response.msg : "Failed to edit"
    }
  } catch (error) {
    yield put(editConstructionFail(error))
  }
}

function* onUpdateConstruction({ payload: { constructionUpdate, history } }) {
  try {
    const response = yield call(updateConstructionApi, constructionUpdate)
    if (response && response.status) {
      yield put(updateConstructionSuccess(response))
      history.push("/dropdown/construction/list")
    } else {
      throw response.msg ? response.msg : "Failed to update"
    }
  } catch (error) {
    yield put(updateConstructionFail(error))
  }
}

function* fetchContructionBySection({ payload: { data } }) {
  try {
    const response = yield call(fetchDropdownsBySectionApi, data)
    if (!response?.results?.construction) {
      throw response.msg ? response.msg : "Failed to fetch details"
    }
    yield put(
      fetchConstructionListingBySectionSuccess(response.results.construction)
    )
  } catch (error) {
    yield put(fetchConstructionListingBySectionFail(error))
  }
}

function* updateConstructionPosition({ payload: { data } }) {
  try {
    const response = yield call(fetchDropDownPositionApi, data)
    if (response && response.status) {
      yield put(updateConstructionPositionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update position"
    }
  } catch (error) {
    let msg = error ? { msg: error } : { msg: "Failed to update position" }
    yield put(updateConstructionPositionFail(msg))
  }
}

function* constructionSaga() {
  yield takeEvery(GET_CONSTRUCTON_LISTING, getConstructionListing)
  yield takeEvery(ADD_CONSTRUCTON, addConstruction)
  yield takeEvery(CHANGE_CONSTRUCTON_STATUS, constructionStatusChange)
  yield takeEvery(EDIT_CONSTRUCTON, getConstructionDetailsById)
  yield takeEvery(UPDATE_CONSTRUCTON, onUpdateConstruction)
  yield takeEvery(FETCH_CONSTRUCTON_BY_SECTION, fetchContructionBySection)
  yield takeEvery(UPDATE_CONSTRUCTION_POSITION, updateConstructionPosition)
}
export default constructionSaga
