import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { BD_GET_USERLISTACCORDINGROLE_LISTING } from "./actionTypes"
import {
  bdUserListAccordingRoleSuccess,
  bdUserListAccordingRoleFail,
} from "./actions"

//Include Both Helper File with needed methods
import { bdUserListAccordingRoleApi } from "../../../helpers/fakebackend_helper"

function* getBdUserListAccordingRoleListing({ payload: { userType, history } }) {
  try {
    const response = yield call(bdUserListAccordingRoleApi, userType)
    if (!response.results.user_lists) {
      yield put(bdUserListAccordingRoleFail(error))
    }
    yield put(bdUserListAccordingRoleSuccess(response.results.user_lists))
  } catch (error) {
    yield put(bdUserListAccordingRoleFail(error))
  }
}

function* bdUserListAccordingRoleSaga() {
  yield takeEvery(
    BD_GET_USERLISTACCORDINGROLE_LISTING,
    getBdUserListAccordingRoleListing
  )
}
export default bdUserListAccordingRoleSaga
