import {
  BD_GET_CREATEDBY_LISTING,
  BD_GET_CREATEDBY_LISTING_SUCCESS,
  BD_GET_CREATEDBY_LISTING_FAILED,
  } from "./actionTypes"

  export const bdCreatedByListing = () => {
    return {
      type: BD_GET_CREATEDBY_LISTING,
    }
  }
  
  export const bdCreatedByListingSuccess = bdCreatedBy => {
    return {
      type: BD_GET_CREATEDBY_LISTING_SUCCESS,
      payload: bdCreatedBy,
    }
  }
  
  export const bdCreatedByListingFail = error => {
    return {
      type: BD_GET_CREATEDBY_LISTING_FAILED,
      payload: error,
    }
  }