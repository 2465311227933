import {
  BD_GET_OFFERSTATUS_LISTING,
  BD_GET_OFFERSTATUS_LISTING_SUCCESS,
  BD_GET_OFFERSTATUS_LISTING_FAILED,
  } from "./actionTypes"
  
  export const bdOfferStatusListing = () => {
    return {
      type: BD_GET_OFFERSTATUS_LISTING,
    }
  }
  
  export const bdOfferStatusListingSuccess = bdOfferStatus => {
    return {
      type: BD_GET_OFFERSTATUS_LISTING_SUCCESS,
      payload: bdOfferStatus,
    }
  }
  
  export const bdOfferStatusListingFail = error => {
    return {
      type: BD_GET_OFFERSTATUS_LISTING_FAILED,
      payload: error,
    }
  }
  
