import {
    GET_CREATEDBY_LISTING,
    GET_CREATEDBY_LISTING_SUCCESS,
    GET_CREATEDBY_LISTING_FAILED,
  } from "./actionTypes"

  const initialState = {
    error: null,
    msg: null,
    loading: false,
    createdBy: null,
  }

  const createdBy = (state = initialState, action) => {
    switch (action.type) {
      case GET_CREATEDBY_LISTING:
        state = {
          ...state,
          loading: true,
          error: null,
          msg: null,
          createdBy: action.payload,
        }
        break
      case GET_CREATEDBY_LISTING_SUCCESS:
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          createdBy: action.payload,
        }
        break
      case GET_CREATEDBY_LISTING_FAILED:
        state = { ...state, loading: false }
    }
    return state
  }
  
  export default createdBy;