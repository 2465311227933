import {
  GET_SUPPLIERNAME_LISTING,
  GET_SUPPLIERNAME_LISTING_SUCCESS,
  GET_SUPPLIERNAME_LISTING_FAILED,
  FETCH_SUPPLIER_BY_SECTION,
  FETCH_SUPPLIER_BY_SECTION_SUCCESS,
  FETCH_SUPPLIER_BY_SECTION_FAIL,
  ADD_SUPPLIER,
  ADD_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_FAIL,
  UPDATE_SUPPLIER,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_FAIL,
  EDIT_SUPPLIER,
  EDIT_SUPPLIER_SUCCESS,
  EDIT_SUPPLIER_FAIL,
  SUPPLIER_ACTIVE_INACTIVE,
  SUPPLIER_ACTIVE_INACTIVE_SUCCESS,
  SUPPLIER_ACTIVE_INACTIVE_FAIL,
  UPDATE_SUPPLIER_POSITION,
  UPDATE_SUPPLIER_POSITION_SUCCESS,
  UPDATE_SUPPLIER_POSITION_FAIL,
  GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
  GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS,
  GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED,
} from "./actionTypes"

export const supplierNameListing = () => {
  return {
    type: GET_SUPPLIERNAME_LISTING,
  }
}

export const supplierNameListingSuccess = supplierName => {
  return {
    type: GET_SUPPLIERNAME_LISTING_SUCCESS,
    payload: supplierName,
  }
}

export const supplierNameListingFail = error => {
  return {
    type: GET_SUPPLIERNAME_LISTING_FAILED,
    payload: error,
  }
}

export const fetchSupllierListingBySection = data => {
  return {
    type: FETCH_SUPPLIER_BY_SECTION,
    payload: { data },
  }
}

export const fetchSupllierListingBySectionSuccess = supplierName => {
  return {
    type: FETCH_SUPPLIER_BY_SECTION_SUCCESS,
    payload: supplierName,
  }
}

export const fetchSupllierListingBySectionFail = error => {
  return {
    type: FETCH_SUPPLIER_BY_SECTION_FAIL,
    payload: error,
  }
}

export const addSupplier = (data, history) => {
  return {
    type: ADD_SUPPLIER,
    payload: { data, history },
  }
}

export const addSupplierSuccess = supplierName => {
  return {
    type: ADD_SUPPLIER_SUCCESS,
    payload: supplierName,
  }
}

export const addSupplierFail = error => {
  return {
    type: ADD_SUPPLIER_FAIL,
    payload: error,
  }
}

export const editSupplier = id => {
  return {
    type: EDIT_SUPPLIER,
    payload: { id: id },
  }
}
export const editSupplierSuccess = supplier => {
  return {
    type: EDIT_SUPPLIER_SUCCESS,
    payload: supplier,
  }
}
export const editSupplierFail = error => {
  return {
    type: EDIT_SUPPLIER_FAIL,
    payload: error,
  }
}

export const updateSupplier = (updateSupplier, history) => {
  return {
    type: UPDATE_SUPPLIER,
    payload: { updateSupplier, history },
  }
}

export const updateSupplierSuccess = updateSupplier => {
  return {
    type: UPDATE_SUPPLIER_SUCCESS,
    payload: updateSupplier,
  }
}

export const updateSupplierFail = error => {
  return {
    type: UPDATE_SUPPLIER_FAIL,
    payload: error,
  }
}

export const changeSupplierStatus = id => ({
  type: SUPPLIER_ACTIVE_INACTIVE,
  payload: { id: id },
})

export const changeSupplierStatusSuccess = supplier => ({
  type: SUPPLIER_ACTIVE_INACTIVE_SUCCESS,
  payload: supplier,
})

export const changeSupplierStatusFail = error => ({
  type: SUPPLIER_ACTIVE_INACTIVE_FAIL,
  payload: error,
})

export const updateSupplierPosition = data => ({
  type: UPDATE_SUPPLIER_POSITION,
  payload: { data },
})

export const updateSupplierPositionSuccess = supplier => ({
  type: UPDATE_SUPPLIER_POSITION_SUCCESS,
  payload: supplier,
})

export const updateSupplierPositionFail = error => ({
  type: UPDATE_SUPPLIER_POSITION_FAIL,
  payload: error,
})

export const supplierNameListingForEditOfferSearch = data => {
  return {
    type: GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
    payload: { data: data },
  }
}

export const supplierNameListingForEditOfferSearchPageSuccess =
  supplierListingForSearch => {
    return {
      type: GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS,
      payload: supplierListingForSearch,
    }
  }

export const supplierNameListingForEditOfferSearchPageFail = error => {
  return {
    type: GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED,
    payload: error,
  }
}
