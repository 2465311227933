//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_JWT_LOGIN = "/login"
export const POST_FORGOT_PASSWORD = "/forget-password"

export const VALIDATE_TOKEN = "/validate-token"

// admin USER
export const ADD_USER = "/admin/add-user"
export const USER_LISTING = "/admin/get-user"
export const USER_STATUS_CHANGE = "/admin/activate-deactivate-user"
export const USER_EDIT = "/admin/edit-user-role/"
export const USER_UPDATE = "/admin/update-user-role"
export const ADMIN_RESET_USER_PASSWORD = "/admin/reset-password"

export const ROLES_LISTING = "/admin/get-user-role"
export const ADD_USER_ROLE = "/admin/add-user-role"
export const EDIT_USER_ROLE = "/admin/edit-role/"
export const UPDATE_USER_ROLE = "/admin/update-role"
export const CHANGE_USER_ROLE_STATUS = "/admin/activate-inactivate-user-role"

export const OFFICE_LISTING = "/admin/get-offices"

//admin PRODUCT
export const PRODUCT_LISTING = "/admin/view-prods"
export const ADD_PRODUCT = "/admin/add-prods"
export const PRODUCT_EDIT = "/admin/edit-product-details/"
export const PRODUCT_UPDATE = "/admin/update-product-details"
export const PRODUCT_IMAGE_DELETE = "/admin/delete-product-image"
export const PRODUCT_DELETE = "/admin/delete-product"
export const CHANGE_PRODUCT_STATUS = "/admin/activate-inactivate-product"
export const PRODUCTCATEGORY_LISTING = "/admin/get-categories"
export const QUALITYRATING_LISTING = "/admin/get-quality-rating"
export const CONSTRUCTION_LISTING = "/admin/get-construction"

// admin LOGS
export const LOGS_LISTING = "/admin/view-logs"

// admin OFFER
export const OFFER_ADD = "/admin/add-prod-offer"
export const OFFERS_LISTING = "/admin/view-prod-offers"
export const OFFER_DELETE = "/admin/delete-offer"
export const OFFER_EDIT = "/admin/edit-product-offer/"
export const OFFER_UPDATE = "/admin/update-product-offer"
export const CHANGE_OFFER_STATUS = "/admin/activate-inactivate-offer"

export const OFFERSTATUS_LISTING = "/admin/get-offer-status"

export const USERLISTACCORDINGROLE_LISTING = "/admin/get-user-list-by-role"

export const SUPPLIERNAME_LISTING = "/admin/get-supplier-names"
export const ADD_SUPPLIER = "/admin/add-supplier-name"
export const UPDATE_SUPPLIER = "/admin/update-supplier-name"
export const SUPPLIER_DETAIL_BY_ID = "/admin/edit-supplier-name/"
export const SUPPLIER_CHANGE_STATUS = "/admin/activate-inactivate-supplier-name"

export const INCOTERMCITY_LISTING = "/admin/get-incoterm-cities"
export const PACKINGSTANDARD_LISTING = "/admin/get-packing-standard"
export const MATERIALSTANDARD_LISTING = "/admin/get-material-standard"
export const ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING = "/admin/get-certs"
export const PACKAGING_LISTING = "/admin/get-packaging"
export const DELIVEREDON_LISTING = "/admin/get-delivers"
export const HEAVEYCOLLI_LISTING = "/admin/get-colli"

export const CREATEDBY_LISTING = "/get-user-listing"
export const PRODUCT_NUMBER_LISTING = "/get-product-number"
export const SUPPLIERNAME_LISTING_FOR_EDIT_OFFER = "/get-supplier-listing"
export const SEARCH_IN_OFFER = "/search-offer"


//admin EXPORT AND COLLECTION
export const COLLECTION_ADD = "/admin/add-collection"
export const COLLECTIONS_LISTING = "/admin/get-collection"
export const COLLECTION_EDIT = "/admin/edit-collection/"
export const COLLECTION_UPDATE = "/admin/update-collection"
export const COLLECTION_DELETE = "/admin/delete-collection"
export const EXPORTCOLLECTIONCATALOG_DOWNLOAD = "/export-product-catalog"
export const COLLECTION_COPY = "/admin/add-collection"
export const ADMINEXPORTDOWNLOAD_PROGRESS = "/view-progress"
export const ADMIN_FETCH_ADD_MORE_DATA_TO_COLLECTION =
  "/get-add-more-collection"
export const ADMIN_ADD_MORE_DATA_TO_COLLECTION_UPDATE =
  "/update-add-more-collection"
export const PRODUCT_FOR_ADD_COLLECTION = "/admin/get-products"
export const PRODUCT_OFFER_FOR_ADD_COLLECTION = "/admin/get-product-offers"
export const REFRESH_COLLECTION_COUNT = "/check-collection"
export const GET_LAYOUT_LISTING = "/admin/get-layout-listing"

// bd product
export const BD_PRODUCT_LISTING = "/admin/view-prods"
export const BD_PRODUCT_EDIT = "/admin/edit-product-details/"

//bd offer
export const BD_OFFER_ADD = "/admin/add-prod-offer"
export const BD_OFFERS_LISTING = "/admin/view-prod-offers"
export const BD_OFFER_EDIT = "/admin/edit-product-offer/"
export const BD_OFFER_UPDATE = "/admin/update-product-offer"
export const BD_CHANGE_OFFER_STATUS = "/admin/activate-inactivate-offer"

export const BD_DELIVEREDON_LISTING = "/admin/get-delivers"
export const BD_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING = "/admin/get-certs"
export const BD_HEAVEYCOLLI_LISTING = "/admin/get-colli"
export const BD_INCOTERMCITY_LISTING = "/admin/get-incoterm-cities"
export const BD_MATERIALSTANDARD_LISTING = "/admin/get-material-standard"
export const BD_PACKAGING_LISTING = "/admin/get-packaging"
export const BD_OFFERSTATUS_LISTING = "/admin/get-offer-status"
export const BD_PACKINGSTANDARD_LISTING = "/admin/get-packing-standard"
export const BD_SUPPLIERNAME_LISTING = "/admin/get-supplier-names"
export const BD_USERLISTACCORDINGROLE_LISTING = "/admin/get-user-list-by-role"

export const BD_CREATEDBY_LISTING = "/get-user-listing"
export const BD_PRODUCTCATEGORY_LISTING = "/admin/get-categories"
export const BD_PRODUCT_NUMBER_LISTING = "/get-product-number"
export const BD_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER = "/get-supplier-listing"
export const BD_SEARCH_IN_OFFER = "/search-offer"

// dashboard counts
export const COUNTS_ON_DASHBOARD = "/get-counts"

// profile
export const PROFILE_EDIT = "/edit-profile"
export const UPDATE_PROFILE = "/update-profile"
export const CHANGE_PASSWORD = "/change-password"

//bd export and collection
export const BD_COLLECTION_ADD = "/admin/add-collection"
export const BD_COLLECTIONS_LISTING = "/admin/get-collection"
export const BD_COLLECTION_EDIT = "/admin/edit-collection/"
export const BD_COLLECTION_UPDATE = "/admin/update-collection"
export const BD_COLLECTION_DELETE = "/admin/delete-collection"
export const BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD = "/export-product-catalog"
export const BD_COLLECTION_COPY = "/admin/add-collection"
export const BD_EXPORTDOWNLOAD_PROGRESS = "/view-progress"
export const BD_FETCH_ADD_MORE_DATA_TO_COLLECTION = "/get-add-more-collection"
export const BD_ADD_MORE_DATA_TO_COLLECTION_UPDATE =
  "/update-add-more-collection"
export const BD_PRODUCT_FOR_ADD_COLLECTION = "/admin/get-products"
export const BD_PRODUCT_OFFER_FOR_ADD_COLLECTION = "/admin/get-product-offers"
export const BD_REFRESH_COLLECTION_COUNT = "/check-collection"
export const BD_GET_LAYOUT_LISTING = "/admin/get-layout-listing"

// co products
export const PRODUCT_LISTING_CO = "/admin/view-prods"
export const ADD_PRODUCT_CO = "/admin/add-prods"
export const PRODUCT_EDIT_CO = "/admin/edit-product-details/"
export const PRODUCT_UPDATE_CO = "/admin/update-product-details"
export const PRODUCT_IMAGE_DELETE_CO = "/admin/delete-product-image"
export const PRODUCT_DELETE_CO = "/admin/delete-product"
export const CHANGE_PRODUCT_STATUS_CO = "/admin/activate-inactivate-product"
export const PRODUCTCATEGORY_LISTING_CO = "/admin/get-categories"
export const QUALITYRATING_LISTING_CO = "/admin/get-quality-rating"
export const CONSTRUCTION_LISTING_CO = "/admin/get-construction"

//co offer
export const CO_OFFER_ADD = "/admin/add-prod-offer"
export const CO_OFFERS_LISTING = "/admin/view-prod-offers"
export const CO_OFFER_DELETE = "/admin/delete-offer"
export const CO_OFFER_EDIT = "/admin/edit-product-offer/"
export const CO_OFFER_UPDATE = "/admin/update-product-offer"
export const CO_CHANGE_OFFER_STATUS = "/admin/activate-inactivate-offer"

export const CO_DELIVEREDON_LISTING = "/admin/get-delivers"
export const CO_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING = "/admin/get-certs"
export const CO_HEAVEYCOLLI_LISTING = "/admin/get-colli"
export const CO_INCOTERMCITY_LISTING = "/admin/get-incoterm-cities"
export const CO_MATERIALSTANDARD_LISTING = "/admin/get-material-standard"
export const CO_OFFERSTATUS_LISTING = "/admin/get-offer-status"
export const CO_PACKAGING_LISTING = "/admin/get-packaging"
export const CO_PACKINGSTANDARD_LISTING = "/admin/get-packing-standard"
export const CO_SUPPLIERNAME_LISTING = "/admin/get-supplier-names"
export const CO_USERLISTACCORDINGROLE_LISTING = "/admin/get-user-list-by-role"


export const CO_CREATEDBY_LISTING = "/get-user-listing"
export const CO_PRODUCT_NUMBER_LISTING = "/get-product-number"
export const CO_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER = "/get-supplier-listing"
export const CO_SEARCH_IN_OFFER = "/search-offer"

//CO EXPORT
export const CO_COLLECTION_ADD = "/admin/add-collection"
export const CO_COLLECTIONS_LISTING = "/admin/get-collection"
export const CO_COLLECTION_EDIT = "/admin/edit-collection/"
export const CO_COLLECTION_UPDATE = "/admin/update-collection"
export const CO_COLLECTION_DELETE = "/admin/delete-collection"
export const CO_EXPORTCOLLECTIONCATALOG_DOWNLOAD = "/export-product-catalog"
export const CO_COLLECTION_COPY = "/admin/add-collection"
export const CO_EXPORTDOWNLOAD_PROGRESS = "/view-progress"
export const CO_FETCH_ADD_MORE_DATA_TO_COLLECTION = "/get-add-more-collection"
export const CO_ADD_MORE_DATA_TO_COLLECTION_UPDATE =
  "/update-add-more-collection"
export const CO_PRODUCT_FOR_ADD_COLLECTION = "/admin/get-products"
export const CO_PRODUCT_OFFER_FOR_ADD_COLLECTION = "/admin/get-product-offers"
export const CO_REFRESH_COLLECTION_COUNT = "/check-collection"
export const CO_GET_LAYOUT_LISTING = "/admin/get-layout-listing"

//qa product
export const QA_PRODUCT_LISTING = "/admin/view-prods"
export const QA_PRODUCT_EDIT = "/admin/edit-product-details/"

//qa offer
export const QA_OFFERS_LISTING = "/admin/view-prod-offers"
export const QA_OFFER_EDIT = "/admin/edit-product-offer/"
export const QA_OFFER_UPDATE = "/admin/update-product-offer"
export const QA_USERLISTACCORDINGROLE_LISTING = "/admin/get-user-list-by-role"
export const QA_SEARCH_IN_OFFER = "/search-offer"

//qa export collection
export const QA_COLLECTION_ADD = "/admin/add-collection"
export const QA_COLLECTIONS_LISTING = "/admin/get-collection"
export const QA_COLLECTION_EDIT = "/admin/edit-collection/"
export const QA_COLLECTION_UPDATE = "/admin/update-collection"
export const QA_COLLECTION_DELETE = "/admin/delete-collection"
export const QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD = "/export-product-catalog"
export const QA_COLLECTION_COPY = "/admin/add-collection"
export const QA_EXPORTDOWNLOAD_PROGRESS = "/view-progress"
export const QA_FETCH_ADD_MORE_DATA_TO_COLLECTION = "/get-add-more-collection"
export const QA_ADD_MORE_DATA_TO_COLLECTION_UPDATE =
  "/update-add-more-collection"
export const QA_PRODUCT_FOR_ADD_COLLECTION = "/admin/get-products"
export const QA_PRODUCT_OFFER_FOR_ADD_COLLECTION = "/admin/get-product-offers"
export const QA_REFRESH_COLLECTION_COUNT = "/check-collection"
export const QA_GET_LAYOUT_LISTING = "/admin/get-layout-listing"

// sa product
export const SA_PRODUCT_LISTING = "/admin/view-prods"
export const SA_PRODUCT_EDIT = "/admin/edit-product-details/"

//sa offer
export const SA_OFFERS_LISTING = "/admin/view-prod-offers"
export const SA_OFFER_EDIT = "/admin/edit-product-offer/"
export const SA_OFFER_UPDATE = "/admin/update-product-offer"
export const SA_SEARCH_IN_OFFER = "/search-offer"

//sa export collection
export const SA_COLLECTION_ADD = "/admin/add-collection"
export const SA_COLLECTIONS_LISTING = "/admin/get-collection"
export const SA_COLLECTION_EDIT = "/admin/edit-collection/"
export const SA_COLLECTION_UPDATE = "/admin/update-collection"
export const SA_COLLECTION_DELETE = "/admin/delete-collection"
export const SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD = "/export-product-catalog"
export const SA_COLLECTION_COPY = "/admin/add-collection"
export const SA_EXPORTDOWNLOAD_PROGRESS = "/view-progress"
export const SA_FETCH_ADD_MORE_DATA_TO_COLLECTION = "/get-add-more-collection"
export const SA_ADD_MORE_DATA_TO_COLLECTION_UPDATE =
  "/update-add-more-collection"
export const SA_PRODUCT_FOR_ADD_COLLECTION = "/admin/get-products"
export const SA_PRODUCT_OFFER_FOR_ADD_COLLECTION = "/admin/get-product-offers"
export const SA_REFRESH_COLLECTION_COUNT = "/check-collection"
export const SA_GET_LAYOUT_LISTING = "/admin/get-layout-listing"

// dropdown listing (all)
export const FETCH_DROPDOWNS_BY_SECTION = "/admin/dropdowns-by-section"
export const FETCH_DROPDOWN_POSITION = "/admin/change-position"

// product category excluded dropdown listing
export const ADD_PRODUCT_CATEGORY = "/admin/add-prod-cat"
export const CHANGE_PRODUCT_CATEGORY_STATUS =
  "/admin/activate-inactivate-product-category"
export const EDIT_PRODUCT_CATEGORY = "/admin/edit-product-category/"
export const UPDATE_PRODUCT_CATEGORY = "/admin/update-product-category"

// quality rating excluded dropdown listing
export const ADD_QUALITY_RATING = "/admin/add-quality-rating"
export const CHANGE_QUALITY_RATING_STATUS =
  "/admin/activate-inactivate-quality-rating"
export const EDIT_QUALITY_RATING = "/admin/edit-quality-rating/"
export const UPDATE_QUALITY_RATING = "/admin/update-quality-rating"

// delivered on excluded dropdown listing
export const ADD_DELIVERED_ON = "/admin/add-delivers"
export const CHANGE_DELIVERED_ON_STATUS = "/admin/activate-inactivate-delivers"
export const EDIT_DELIVERED_ON = "/admin/edit-delivers/"
export const UPDATE_DELIVERED_ON = "/admin/update-delivers"

// env certificates excluded dropdown listing
export const ADD_CERTIFICATES = "/admin/add-certs"
export const CHANGE_CERTIFICATES_STATUS = "/admin/activate-inactivate-certs"
export const EDIT_CERTIFICATES = "/admin/edit-cert/"
export const UPDATE_CERTIFICATES = "/admin/update-certs"

// heavy colli excluded dropdown listing
export const ADD_HEAVEY_COLLI = "/admin/add-colli"
export const CHANGE_HEAVEY_COLLI_STATUS = "/admin/activate-inactivate-colli"
export const EDIT_HEAVEY_COLLI = "/admin/edit-colli/"
export const UPDATE_HEAVEY_COLLI = "/admin/update-colli"

// incoterm city excluded dropdown listing
export const ADD_INCOTERM = "/admin/add-incoterm-city"
export const CHANGE_INCOTERM_STATUS = "/admin/activate-inactivate-incoterm-city"
export const EDIT_INCOTERM = "/admin/edit-incoterm-city/"
export const UPDATE_INCOTERM = "/admin/update-incoterm-city"

// material standard excluded dropdown listing
export const ADD_MATERIAL = "/admin/add-material-standard"
export const CHANGE_MATERIAL = "/admin/activate-inactivate-material-standard"
export const EDIT_MATERIAL = "/admin/edit-material-standard/"
export const UPDATE_MATERIAL = "/admin/update-material-standard"

//activity log
export const ACTIVITY_LOG = "/get-activity"

// offer status excluded dropdown listing
export const ADD_OFFER_STATUS = "/admin/add-offer-status"
export const OFFER_STATUS_CHANGE = "/admin/activate-inactivate-offer-status"
export const EDIT_OFFER_STATUS = "/admin/edit-offer-status/"
export const UPDATE_OFFER_STATUS = "/admin/update-offer-status"

// office excluded dropdown listing
export const ADD_OFFICE = "/admin/add-office-name"
export const OFFICE_STATUS_CHANGE = "/admin/activate-inactivate-office"
export const EDIT_OFFICE = "/admin/edit-office-name/"
export const UPDATE_OFFICE = "/admin/update-office-name"

// packaging excluded dropdown listing
export const ADD_PACKAGING = "/admin/add-packaging"
export const PACKAGING_STATUS_CHANGE = "/admin/activate-deactivate-packaging"
export const EDIT_PACKAGING = "/admin/edit-packaging/"
export const UPDATE_PACKAGING = "/admin/update-packaging"

// packing standard excluded dropdown listing
export const ADD_PACKING_STANDARD = "/admin/add-packing-standard"
export const PACKING_STANDARD_STATUS_CHANGE =
  "/admin/activate-inactivate-packing-standard"
export const EDIT_PACKING_STANDARD = "/admin/edit-packing-standard/"
export const UPDATE_PACKING_STANDARD = "/admin/update-packing-standard"

// CONSTRUCTION excluded dropdown listing
export const ADD_CONSTRUCTION = "/admin/add-construction"
export const CONSTRUCTION_STATUS_CHANGE =
  "/admin/activate-inactivate-construction"
export const EDIT_CONSTRUCTION = "/admin/edit-construction/"
export const UPDATE_CONSTRUCTION = "/admin/update-construction"
