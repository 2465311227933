import {
  BD_GET_PRODUCTCATEGORY_LISTING,
  BD_GET_PRODUCTCATEGORY_LISTING_SUCCESS,
  BD_GET_PRODUCTCATEGORY_LISTING_FAILED,
} from "./actionTypes"

const initialState = {
  error: null,
  msg: null,
  loading: false,
  bdProductCategory: null,
}

const bdProductCategory = (state = initialState, action) => {
  switch (action.type) {
    case BD_GET_PRODUCTCATEGORY_LISTING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        bdProductCategory: action.payload,
      }
      break
    case BD_GET_PRODUCTCATEGORY_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        bdProductCategory: action.payload,
      }
      break
    case BD_GET_PRODUCTCATEGORY_LISTING_FAILED:
      state = { ...state, loading: false }
      break
  }
  return state
}

export default bdProductCategory
