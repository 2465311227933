import {
  GET_QUALITYRATING_LISTING,
  GET_QUALITYRATING_LISTING_SUCCESS,
  GET_QUALITYRATING_LISTING_FAILED,
  ADD_QUALITY_RATING,
  ADD_QUALITY_RATING_SUCCESS,
  ADD_QUALITY_RATING_FAIL,
  CHANGE_QUALITY_RATING_STATUS,
  CHANGE_QUALITY_RATING_STATUS_SUCCESS,
  CHANGE_QUALITY_RATING_STATUS_FAIL,
  EDIT_QUALITY_RATING,
  EDIT_QUALITY_RATING_SUCCESS,
  EDIT_QUALITY_RATING_FAIL,
  UPDATE_QUALITY_RATING,
  UPDATE_QUALITY_RATING_SUCCESS,
  UPDATE_QUALITY_RATING_FAIL,
  FETCH_QUALITY_RATING_BY_SECTION,
  FETCH_QUALITY_RATING_BY_SECTION_SUCCESS,
  FETCH_QUALITY_RATING_BY_SECTION_FAIL,
  UPDATE_QUALITY_RATING_POSITION,
  UPDATE_QUALITY_RATING_POSITION_SUCCESS,
  UPDATE_QUALITY_RATING_POSITION_FAIL,
} from "./actionTypes"

export const qualityRatingListing = () => {
  return {
    type: GET_QUALITYRATING_LISTING,
  }
}

export const qualityRatingListingSuccess = qualityRating => {
  return {
    type: GET_QUALITYRATING_LISTING_SUCCESS,
    payload: qualityRating,
  }
}

export const qualityRatingListingFail = error => {
  return {
    type: GET_QUALITYRATING_LISTING_FAILED,
    payload: error,
  }
}

export const addQualityRating = (qualityRating, history) => {
  return {
    type: ADD_QUALITY_RATING,
    payload: { qualityRating, history },
  }
}

export const addQualityRatingSuccess = qualityRating => {
  return {
    type: ADD_QUALITY_RATING_SUCCESS,
    payload: qualityRating,
  }
}

export const addQualityRatingFail = error => {
  return {
    type: ADD_QUALITY_RATING_FAIL,
    payload: error,
  }
}

export const changeQualityRatingStatus = id => ({
  type: CHANGE_QUALITY_RATING_STATUS,
  payload: { id: id },
})

export const changeQualityRatingStatusSuccess = qualityRating => ({
  type: CHANGE_QUALITY_RATING_STATUS_SUCCESS,
  payload: qualityRating,
})

export const changeQualityRatingStatusFail = error => ({
  type: CHANGE_QUALITY_RATING_STATUS_FAIL,
  payload: error,
})

export const editQualityRating = id => {
  return {
    type: EDIT_QUALITY_RATING,
    payload: { id },
  }
}
export const editQualityRatingSuccess = qualityRating => {
  return {
    type: EDIT_QUALITY_RATING_SUCCESS,
    payload: qualityRating,
  }
}
export const editQualityRatingFail = error => {
  return {
    type: EDIT_QUALITY_RATING_FAIL,
    payload: error,
  }
}

export const updateQualityRating = (qualityRating, history) => {
  return {
    type: UPDATE_QUALITY_RATING,
    payload: { qualityRating, history },
  }
}

export const updateQualityRatingSuccess = qualityRating => {
  return {
    type: UPDATE_QUALITY_RATING_SUCCESS,
    payload: qualityRating,
  }
}

export const updateQualityRatingFail = error => {
  return {
    type: UPDATE_QUALITY_RATING_FAIL,
    payload: error,
  }
}

export const fetchQualityRatingListingBySection = (data) => {
  return {
    type: FETCH_QUALITY_RATING_BY_SECTION,
    payload: { data }
  }
}

export const fetchQualityRatingListingBySectionSuccess = qualityRating => {
  return {
    type: FETCH_QUALITY_RATING_BY_SECTION_SUCCESS,
    payload: qualityRating,
  }
}

export const fetchQualityRatingListingBySectionFail = error => {
  return {
    type: FETCH_QUALITY_RATING_BY_SECTION_FAIL,
    payload: error,
  }
}

export const updateQualityRatingPosition = data => ({
  type: UPDATE_QUALITY_RATING_POSITION,
  payload: { data },
})

export const updateQualityRatingPositionSuccess = qualityRating => ({
  type: UPDATE_QUALITY_RATING_POSITION_SUCCESS,
  payload: qualityRating,
})

export const updateQualityRatingPositionFail = error => ({
  type: UPDATE_QUALITY_RATING_POSITION_FAIL,
  payload: error,
})