import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_PACKINGSTANDARD_LISTING,
  FETCH_PACKING_STANDARD_BY_SECTION,
  ADD_PACKING_STANDARD,
  EDIT_PACKING_STANDARD,
  UPDATE_PACKING_STANDARD,
  PACKING_STANDARD_ACTIVE_INACTIVE,
  PACKING_STANDARD_POSITION,
} from "./actionTypes"
import {
  packingStandardListingSuccess,
  packingStandardListingFail,
  fetchPackingStandardListingBySectionSuccess,
  fetchPackingStandardListingBySectionFail,
  addPackingStandardSuccess,
  addPackingStandardFail,
  editPackingStandardSuccess,
  editPackingStandardFail,
  updatePackingStandardSuccess,
  updatePackingStandardFail,
  packingStandardChangeSuccess,
  packingStandardChangeFail,
  packingStandardPositionSuccess,
  packingStandardPositionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  packingStandardListingApi,
  fetchDropdownsBySectionApi,
  addPackingStandardApi,
  getPackingStandardDetailsById,
  updatePackingStandardApi,
  PackingStandardChangeStatusApi,
  fetchDropDownPositionApi,
} from "../../../helpers/fakebackend_helper"

function* getPackingStandardListing() {
  try {
    const response = yield call(packingStandardListingApi)
    if (!response.results.packing_standards) {
      yield put(packingStandardListingFail(error))
    }
    yield put(packingStandardListingSuccess(response.results.packing_standards))
  } catch (error) {
    yield put(packingStandardListingFail(error))
  }
}

function* fetchPackingStandardListingBySection({ payload: { data } }) {
  try {
    const response = yield call(fetchDropdownsBySectionApi, data)
    if (!response?.results?.packing_standards) {
      throw response.msg ? response.msg : "Failed to fetch details"
    }
    yield put(
      fetchPackingStandardListingBySectionSuccess(
        response.results.packing_standards
      )
    )
  } catch (error) {
    yield put(fetchPackingStandardListingBySectionFail(error))
  }
}

function* addPackingStandard({ payload: { data, history } }) {
  try {
    const response = yield call(addPackingStandardApi, data)
    if (response && response.status) {
      yield put(addPackingStandardSuccess(response))
      history.push("/dropdown/packing_standard/list")
    } else {
      throw response.msg ? response.msg : "Failed to add"
    }
  } catch (error) {
    yield put(addPackingStandardFail(error))
  }
}

function* editPackingStandard({ payload: { id } }) {
  try {
    const response = yield call(getPackingStandardDetailsById, id)
    if (response && response.status) {
      response.id = id
      yield put(editPackingStandardSuccess(response.results.packing_std))
    } else {
      throw response.msg ? response.msg : "Failed to edit"
    }
  } catch (error) {
    yield put(editPackingStandardFail(error))
  }
}

function* updatePackingStandard({
  payload: { updatePackingStandard, history },
}) {
  try {
    const response = yield call(updatePackingStandardApi, updatePackingStandard)
    if (response && response.status) {
      yield put(updatePackingStandardSuccess(response))
      history.push("/dropdown/packing_standard/list")
    } else {
      throw response.msg ? response.msg : "Failed to update"
    }
  } catch (error) {
    yield put(updatePackingStandardFail(error))
  }
}

function* packingStandardChange({ payload: { id } }) {
  try {
    const response = yield call(PackingStandardChangeStatusApi, {
      packing_standard_id: id,
    })
    if (response && response.status) {
      response.id = id
      yield put(packingStandardChangeSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update status"
    }
  } catch (error) {
    yield put(packingStandardChangeFail(error))
  }
}

function* packingStandardPosition({ payload: { position } }) {
  try {
    const response = yield call(fetchDropDownPositionApi, position)
    if (response && response.status) {
      yield put(packingStandardPositionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update position"
    }
  } catch (error) {
    let msg = error ? { msg: error } : { msg: "Failed to update position" }
    yield put(packingStandardPositionFail(msg))
  }
}

function* packingStandardSaga() {
  yield takeEvery(GET_PACKINGSTANDARD_LISTING, getPackingStandardListing)
  yield takeEvery(
    FETCH_PACKING_STANDARD_BY_SECTION,
    fetchPackingStandardListingBySection
  )
  yield takeEvery(ADD_PACKING_STANDARD, addPackingStandard)
  yield takeEvery(EDIT_PACKING_STANDARD, editPackingStandard)
  yield takeEvery(UPDATE_PACKING_STANDARD, updatePackingStandard)
  yield takeEvery(PACKING_STANDARD_ACTIVE_INACTIVE, packingStandardChange)
  yield takeEvery(PACKING_STANDARD_POSITION, packingStandardPosition)
}
export default packingStandardSaga
