import {
  SA_GET_OFFERS_LISTING,
  SA_GET_OFFERS_LISTING_SUCCESS,
  SA_GET_OFFERS_LISTING_FAILED,
  SA_OFFER_EDIT,
  SA_OFFER_EDIT_SUCCESS,
  SA_OFFER_EDIT_FAIL,
  SA_OFFER_UPDATE,
  SA_OFFER_UPDATE_SUCCESS,
  SA_OFFER_UPDATE_FAIL,
  SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
  SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS,
  SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL,
  SA_RESET_OFFER,
  SA_RESET_OFFER_SUCCESS,
  SA_RESET_OFFER_FAIL
  } from "./actionTypes"
  
  export const saOffersListing = history => {
    return {
      type: SA_GET_OFFERS_LISTING,
    }
  }
  
  export const saOffersListingSuccess = offer => {
    return {
      type: SA_GET_OFFERS_LISTING_SUCCESS,
      payload: offer,
    }
  }
  
  export const saOffersListingFail = error => {
    return {
      type: SA_GET_OFFERS_LISTING_FAILED,
      payload: error,
    }
  }
  
  export const saOfferEdit = offer_id => {
    return {
      type: SA_OFFER_EDIT,
      payload: { id: offer_id },
    }
  }

  export const saEditOfferSuccess = offer => {
    return {
      type: SA_OFFER_EDIT_SUCCESS,
      payload: offer,
    }
  }

  export const saEditOfferFail = error => {
    return {
      type: SA_OFFER_EDIT_FAIL,
      payload: error,
    }
  }

  export const saUpdateOffer = (offer, history) => {
    return {
      type: SA_OFFER_UPDATE,
      payload: { offer, history },
    }
  }
 
export const saUpdateOfferSuccess = offer => {
  return {
    type: SA_OFFER_UPDATE_SUCCESS,
    payload: offer,
  }
}

export const saUpdateOfferFail = error => {
  return {
    type: SA_OFFER_UPDATE_FAIL,
    payload: error,
  }
}

export const saSearchItemInNewOfferPage = search => {
  return {
    type: SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
    payload: {search : search},
  }
}

export const saSearchItemInNewOfferPageSuccess = search => {
  return {
    type: SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS,
    payload: search,
  }
}

export const saSearchItemInNewOfferPageFail = error => {
  return {
    type: SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL,
    payload: error,
  }
}

export const saClearOfferResult = () => {
  return {
    type: SA_RESET_OFFER,
  }
}

export const saClearOfferResultSuccess = () => {
  return {
    type: SA_RESET_OFFER_SUCCESS,
  }
}

export const saClearOfferResultError = () => {
  return {
    type: SA_RESET_OFFER_FAIL,
  }
}