import {
    CO_OFFER_ADD,
    CO_OFFER_SUCCESS,
    CO_OFFER_FAIL,
    CO_GET_OFFERS_LISTING,
    CO_GET_OFFERS_LISTING_SUCCESS,
    CO_GET_OFFERS_LISTING_FAILED,
    CO_OFFER_DELETE,
    CO_OFFER_DELETE_SUCCESS,
    CO_OFFER_DELETE_FAIL,
    CO_OFFER_EDIT,
    CO_OFFER_EDIT_SUCCESS,
    CO_OFFER_EDIT_FAIL,
    CO_OFFER_UPDATE,
    CO_OFFER_UPDATE_SUCCESS,
    CO_OFFER_UPDATE_FAIL,
    CO_CHANGE_OFFER_STATUS,
    CO_CHANGE_OFFER_STATUS_SUCCESS,
    CO_CHANGE_OFFER_STATUS_FAIL,
    CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
    CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS,
    CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL,
    CO_RESET_OFFER,
    CO_RESET_OFFER_SUCCESS,
    CO_RESET_OFFER_FAIL
} from "./actionTypes"

export const coAddNewOffer = (offer, history) => {
    return {
        type: CO_OFFER_ADD,
        payload: { offer, history },
    }
}

export const coOfferSuccess = offer => {
    return {
        type: CO_OFFER_SUCCESS,
        payload: offer,
    }
}

export const coOfferFail = error => {
    return {
        type: CO_OFFER_FAIL,
        payload: error,
    }
}

export const coOffersListing = () => {
    return {
        type: CO_GET_OFFERS_LISTING,
    }
}

export const coOffersListingSuccess = offer => {
    return {
        type: CO_GET_OFFERS_LISTING_SUCCESS,
        payload: offer,
    }
}

export const coOffersListingFail = error => {
    return {
        type: CO_GET_OFFERS_LISTING_FAILED,
        payload: error,
    }
}

export const coDeleteOffer = offerId => ({
    type: CO_OFFER_DELETE,
    payload: { id: offerId },
})

export const coDeleteOfferSuccess = offer => ({
    type: CO_OFFER_DELETE_SUCCESS,
    payload: offer,
})

export const coDeleteOfferFail = error => ({
    type: CO_OFFER_DELETE_FAIL,
    payload: error,
})

export const coOfferEdit = offer_id => {
    return {
        type: CO_OFFER_EDIT,
        payload: { id: offer_id },
    }
}

export const coEditOfferSuccess = offer => {
    return {
        type: CO_OFFER_EDIT_SUCCESS,
        payload: offer,
    }
}

export const coEditOfferFail = error => {
    return {
        type: CO_OFFER_EDIT_FAIL,
        payload: error,
    }
}

export const coUpdateOffer = (offer, history) => {
    return {
        type: CO_OFFER_UPDATE,
        payload: { offer, history },
    }
}

export const coUpdateOfferSuccess = offer => {
    return {
        type: CO_OFFER_UPDATE_SUCCESS,
        payload: offer,
    }
}

export const coUpdateOfferFail = error => {
    return {
        type: CO_OFFER_UPDATE_FAIL,
        payload: error,
    }
}

export const coChangeOfferStatus = offerId => ({
    type: CO_CHANGE_OFFER_STATUS,
    payload: { id: offerId },
})

export const coChangeOfferStatusSuccess = offer => ({
    type: CO_CHANGE_OFFER_STATUS_SUCCESS,
    payload: offer,
})

export const coChangeOfferStatusFail = error => ({
    type: CO_CHANGE_OFFER_STATUS_FAIL,
    payload: error,
})

export const coSearchItemInNewOfferPage = search => {
    return {
      type: CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
      payload: {search : search},
    }
  }
  
  export const coSearchItemInNewOfferPageSuccess = search => {
    return {
      type: CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS,
      payload: search,
    }
  }
  
  export const coSearchItemInNewOfferPageFail = error => {
    return {
      type: CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL,
      payload: error,
    }
  }

  export const coClearOfferResult = () => {
    return {
      type: CO_RESET_OFFER,
    }
  }
  
  export const coClearOfferResultSuccess = () => {
    return {
      type: CO_RESET_OFFER_SUCCESS,
    }
  }
  
  export const coClearOfferResultError = () => {
    return {
      type: CO_RESET_OFFER_FAIL,
    }
  }