import {
  BD_GET_SUPPLIERNAME_LISTING,
  BD_GET_SUPPLIERNAME_LISTING_SUCCESS,
  BD_GET_SUPPLIERNAME_LISTING_FAILED,
  BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
  BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS,
  BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  bdSupplierName: [],
  bdSupplierListingForSearch: [],
}

const bdSupplierName = (state = initialState, action) => {
  switch (action.type) {
    case BD_GET_SUPPLIERNAME_LISTING:
      state = {
        ...state,
        loading: true,
        bdSupplierName: action.payload,
      }
      break
    case BD_GET_SUPPLIERNAME_LISTING_SUCCESS:
      state = { ...state, loading: false, bdSupplierName: action.payload }
      break
    case BD_GET_SUPPLIERNAME_LISTING_FAILED:
      state = { ...state, loading: false }
      break
    case BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE:
      state = {
        ...state,
        loading: true,
        msg: null,
        error: null,
        bdSupplierListingForSearch: action.payload,
      }
      break
    case BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: null,
        bdSupplierListingForSearch: action.payload,
      }
      break
    case BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED:
      state = { ...state, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default bdSupplierName
