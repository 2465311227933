import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { useHistory } from "react-router-dom"

import {
  CO_COLLECTION_ADD,
  CO_GET_COLLECTION_LISTING,
  CO_COLLECTION_EDIT,
  CO_COLLECTION_UPDATE,
  CO_COLLECTION_DELETE,
  CO_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
  CO_COLLECTION_COPY,
  CO_EXPORTDOWNLOAD_PROGRESS,
  CO_ADD_MORE_TO_COLLECTION,
  CO_ADD_MORE_TO_COLLECTION_UPDATE,
  CO_GET_PRODUCTS_FOR_ADD_COLLECTION,
  CO_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
  CO_REFRESH_COLLECTION_COUNT,
  CO_GET_LAYOUT,
} from "./actionTypes"

import {
  coCollectionSuccess,
  coCollectionFail,
  coCollectionsListingSuccess,
  coCollectionsListingFail,
  coEditCollectionSuccess,
  coEditCollectionFail,
  coUpdateCollectionSuccess,
  coUpdateCollectionFail,
  coDeleteCollectionSuccess,
  coDeleteCollectionFail,
  coExportCollectionCatalogDownloadSuccess,
  coExportCollectionCatalogDownloadFail,
  coCopyCollectionSuccess,
  coCopyCollectionFail,
  coExportDownloadProgressSuccess,
  coExportDownloadProgressFail,
  coGetAddMoreToCollectionSuccess,
  coGetAddMoreToCollectionFail,
  coGetAddMoreToCollectionUpdateSuccess,
  coGetAddMoreToCollectionUpdateFail,
  coProductForAddCollectionSuccess,
  coProductForAddCollectionFail,
  coProductOfferForAddCollectionSuccess,
  coProductOfferForAddCollectionFail,
  coRefreshCollectionCountSuccess,
  coRefreshCollectionCountFail,
  coGetLayOutSuccess,
  coGetLayOutFail,
} from "./actions"

import {
  coAddCollectionApi,
  coCollectionsListingApi,
  coEditCollectionApi,
  coUpdateCollectionApi,
  coDeleteCollectionApi,
  coExportCollectionCatalogApi,
  coCopyCollectionApi,
  coExportDownloadProgressApi,
  coGetAddMoreToCollectionApi,
  coAddMoreDataToCollectionUpdateApi,
  coGetProductForAddCollectionApi,
  coGetProductsOfferForAddCollectionApi,
  coRefreshCollectionCountApi,
  coGetLayoutApi,
} from "../../../helpers/fakebackend_helper"

function* coAddNewCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(coAddCollectionApi, collection)

    if (response && response.status) {
      yield put(coCollectionSuccess(response))
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to add collection"
    }
  } catch (error) {
    yield put(coCollectionFail(error))
  }
}

function* coGetCollectionListing() {
  try {
    const response = yield call(coCollectionsListingApi)
    if (
      response.results === undefined ||
      response.results.collections.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(coCollectionsListingSuccess(response.results.collections))
  } catch (error) {
    yield put(coCollectionsListingFail(error))
  }
}

function* coGetCollectionDetailsById({ payload: { id } }) {
  try {
    const response = yield call(coEditCollectionApi, id)
    if (response && response.status) {
      response.id = id
      yield put(coEditCollectionSuccess(response.results.collection))
    } else {
      throw response.msg ? response.msg : "Failed to fetch collection details"
    }
  } catch (error) {
    yield put(coEditCollectionFail(error))
  }
}

function* coOnUpdateCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(coUpdateCollectionApi, collection)
    if (response && response.status) {
      yield put(coUpdateCollectionSuccess(response))
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to update collection"
    }
  } catch (error) {
    yield put(coUpdateCollectionFail(error))
  }
}

function* coOnDeleteCollection({ payload: { id } }) {
  try {
    const response = yield call(coDeleteCollectionApi, { collection_id: id })
    if (response && response.status) {
      response.id = id
      yield put(coDeleteCollectionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to delete collection"
    }
  } catch (error) {
    yield put(coDeleteCollectionFail(error))
  }
}

function* coOnExportCollectionCatalog({ payload: { collection, history } }) {
  try {
    const response = yield call(coExportCollectionCatalogApi, collection)
    if (response && response.status) {
      yield put(coExportCollectionCatalogDownloadSuccess(response))
    } else {
      throw response.msg ? response.msg : "Download in progress..."
    }
  } catch (error) {
    yield put(coExportCollectionCatalogDownloadFail(error))
  }
}

function* coOnCopyCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(coCopyCollectionApi, collection)

    if (response && response.status) {
      yield put(
        coCopyCollectionSuccess(response.record.original?.results?.collection)
      )
    } else {
      throw response.msg ? response.msg : "Failed to copy collection"
    }
  } catch (error) {
    yield put(coCopyCollectionFail(error))
  }
}

function* coOnExportDownloadProgress({
  payload: { coExportDownloadProgress, history },
}) {
  try {
    const response = yield call(
      coExportDownloadProgressApi,
      coExportDownloadProgress
    )
    if (response && response.status) {
      yield put(coExportDownloadProgressSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to download file"
    }
  } catch (error) {
    yield put(coExportDownloadProgressFail(error))
  }
}

function* coOnFetchDataToAddMoreToCollection({ payload: { collection_id } }) {
  try {
    const response = yield call(coGetAddMoreToCollectionApi, {
      collection_id: collection_id,
    })
    if (response && response.status && response?.results?.product_dtls) {
      yield put(
        coGetAddMoreToCollectionSuccess(response?.results?.product_dtls)
      )
    } else if (response && response.status && response?.results?.prod_offers) {
      yield put(coGetAddMoreToCollectionSuccess(response?.results?.prod_offers))
    } else {
      throw response.msg ? response.msg : "Failed to fetch data"
    }
  } catch (error) {
    yield put(coGetAddMoreToCollectionFail(error))
  }
}

function* coOnUpdateDataToAddMoreToCollection({
  payload: { addMoreUpdateDetails, history },
}) {
  try {
    const response = yield call(
      coAddMoreDataToCollectionUpdateApi,
      addMoreUpdateDetails
    )
    if (response && response.status) {
      yield put(coGetAddMoreToCollectionUpdateSuccess(response))
      history.push("/export/collection")
    } else {
      throw response.msg ? response.msg : "Failed to update data"
    }
  } catch (error) {
    yield put(coGetAddMoreToCollectionUpdateFail(error))
  }
}

function* coGetProductsForAddCollection({
  payload: { productsForAddCollection },
}) {
  try {
    const response = yield call(
      coGetProductForAddCollectionApi,
      productsForAddCollection
    )
    if (
      response.results === undefined ||
      response.results.product_dtls.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(coProductForAddCollectionSuccess(response.results.product_dtls))
  } catch (error) {
    yield put(coProductForAddCollectionFail(error))
  }
}

function* coGetProductsOfferForAddCollection({
  payload: { productsOfferForAddCollection },
}) {
  try {
    const response = yield call(
      coGetProductsOfferForAddCollectionApi,
      productsOfferForAddCollection
    )
    if (
      response.results === undefined ||
      response.results.prod_offers.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(
      coProductOfferForAddCollectionSuccess(response.results.prod_offers)
    )
  } catch (error) {
    yield put(coProductOfferForAddCollectionFail(error))
  }
}

function* coRefreshCollectionCount({ payload: { collection_id } }) {
  try {
    const response = yield call(coRefreshCollectionCountApi, {
      collection_id: collection_id,
    })
    if (response.status && response.result) {
      yield put(coRefreshCollectionCountSuccess(response.result))
    } else {
      throw response.msg ? response.msg : "No data found"
    }
  } catch (error) {
    yield put(coRefreshCollectionCountFail(error))
  }
}

function* coGetLayout() {
  try {
    const response = yield call(coGetLayoutApi)
    if (response === undefined || response.results.length == 0) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(coGetLayOutSuccess(response.results))
  } catch (error) {
    yield put(coGetLayOutFail(error))
  }
}

function* coCollectionSaga() {
  yield takeEvery(CO_COLLECTION_ADD, coAddNewCollection)
  yield takeEvery(CO_GET_COLLECTION_LISTING, coGetCollectionListing)
  yield takeEvery(CO_COLLECTION_EDIT, coGetCollectionDetailsById)
  yield takeEvery(CO_COLLECTION_UPDATE, coOnUpdateCollection)
  yield takeEvery(CO_COLLECTION_DELETE, coOnDeleteCollection)
  yield takeEvery(
    CO_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
    coOnExportCollectionCatalog
  )
  yield takeEvery(CO_COLLECTION_COPY, coOnCopyCollection)
  yield takeEvery(CO_EXPORTDOWNLOAD_PROGRESS, coOnExportDownloadProgress)
  yield takeEvery(CO_ADD_MORE_TO_COLLECTION, coOnFetchDataToAddMoreToCollection)
  yield takeEvery(
    CO_ADD_MORE_TO_COLLECTION_UPDATE,
    coOnUpdateDataToAddMoreToCollection
  )
  yield takeEvery(
    CO_GET_PRODUCTS_FOR_ADD_COLLECTION,
    coGetProductsForAddCollection
  )
  yield takeEvery(
    CO_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
    coGetProductsOfferForAddCollection
  )
  yield takeEvery(CO_REFRESH_COLLECTION_COUNT, coRefreshCollectionCount)
  yield takeEvery(CO_GET_LAYOUT, coGetLayout)
}

export default coCollectionSaga
