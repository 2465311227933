import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_QUALITYRATING_LISTING,
  ADD_QUALITY_RATING,
  CHANGE_QUALITY_RATING_STATUS,
  EDIT_QUALITY_RATING,
  UPDATE_QUALITY_RATING,
  FETCH_QUALITY_RATING_BY_SECTION,
  UPDATE_QUALITY_RATING_POSITION,
} from "./actionTypes"
import {
  qualityRatingListingSuccess,
  qualityRatingListingFail,
  addQualityRatingSuccess,
  addQualityRatingFail,
  changeQualityRatingStatusSuccess,
  changeQualityRatingStatusFail,
  editQualityRatingSuccess,
  editQualityRatingFail,
  updateQualityRatingSuccess,
  updateQualityRatingFail,
  fetchQualityRatingListingBySectionSuccess,
  fetchQualityRatingListingBySectionFail,
  updateQualityRatingPositionSuccess,
  updateQualityRatingPositionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  qualityRatingListingApi,
  addQualityRatingApi,
  changeQualityRatingStatusApi,
  editQualityRatingApi,
  updateQualityRatingApi,
  fetchDropdownsBySectionApi,
  fetchDropDownPositionApi,
} from "../../../helpers/fakebackend_helper"

function* getQualityRatingListing() {
  try {
    const response = yield call(qualityRatingListingApi)
    if (!response.results.ratings) {
      yield put(qualityRatingListingFail(error))
    }
    yield put(qualityRatingListingSuccess(response.results.ratings))
  } catch (error) {
    yield put(qualityRatingListingFail(error))
  }
}

function* addQualityRating({ payload: { qualityRating, history } }) {
  try {
    const response = yield call(addQualityRatingApi, qualityRating)
    if (response && response.status) {
      yield put(addQualityRatingSuccess(response))
      history.push("/dropdown/quality_rating/list")
    } else {
      throw response.msg ? response.msg : "Failed to add quality rating"
    }
  } catch (error) {
    yield put(addQualityRatingFail(error))
  }
}

function* onQualityRatingStatusChange({ payload: { id } }) {
  try {
    const response = yield call(changeQualityRatingStatusApi, {
      quality_rating_id: id,
    })
    if (response.status) {
      response.id = id
      yield put(changeQualityRatingStatusSuccess(response))
    } else {
      throw response.msg
        ? response.msg
        : "Failed to change quality rating status"
    }
  } catch (error) {
    yield put(changeQualityRatingStatusFail(error))
  }
}

function* getQualityRatingDetailsById({ payload: { id } }) {
  try {
    const response = yield call(editQualityRatingApi, id)
    if (response && response.status) {
      yield put(editQualityRatingSuccess(response.results.quality_rating))
    } else {
      throw response.msg ? response.msg : "Failed to edit quality rating"
    }
  } catch (error) {
    yield put(editQualityRatingFail(error))
  }
}

function* onUpdateQualityRating({ payload: { qualityRating, history } }) {
  try {
    const response = yield call(updateQualityRatingApi, qualityRating)
    if (response && response.status) {
      yield put(updateQualityRatingSuccess(response))
      history.push("/dropdown/quality_rating/list")
    } else {
      throw response.msg ? response.msg : "Failed to update quality rating"
    }
  } catch (error) {
    yield put(updateQualityRatingFail(error))
  }
}

function* fetchQualityRatingListingBySection({ payload: { data } }) {
  try {
    const response = yield call(fetchDropdownsBySectionApi, data)
    if (!response?.results?.ratings) {
      throw response.msg ? response.msg : "Failed to fetch details"
    }
    yield put(
      fetchQualityRatingListingBySectionSuccess(response.results.ratings)
    )
  } catch (error) {
    yield put(fetchQualityRatingListingBySectionFail(error))
  }
}

function* updateQualityRatingPosition({ payload: { data } }) {
  try {
    const response = yield call(fetchDropDownPositionApi, data)
    if (response && response.status) {
      yield put(updateQualityRatingPositionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update position"
    }
  } catch (error) {
    let msg = error ? { msg: error } : { msg: "Failed to update position" }
    yield put(updateQualityRatingPositionFail(msg))
  }
}

function* qualityRatingSaga() {
  yield takeEvery(GET_QUALITYRATING_LISTING, getQualityRatingListing)
  yield takeEvery(ADD_QUALITY_RATING, addQualityRating)
  yield takeEvery(CHANGE_QUALITY_RATING_STATUS, onQualityRatingStatusChange)
  yield takeEvery(EDIT_QUALITY_RATING, getQualityRatingDetailsById)
  yield takeEvery(UPDATE_QUALITY_RATING, onUpdateQualityRating)
  yield takeEvery(
    FETCH_QUALITY_RATING_BY_SECTION,
    fetchQualityRatingListingBySection
  )
  yield takeEvery(UPDATE_QUALITY_RATING_POSITION, updateQualityRatingPosition)
}
export default qualityRatingSaga
