import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./products.css"

function BtnCellRenderer(props) {
  return (
    <>
      <div className="row">
        {/* <div className="col">
          <Link
            to={`/offers/add/${props.data.prod_id}/${props.data.prod_no}`}
            className="text-decoration-none"
          >
            <i
              className="bx bx-add-to-queue cursor-pointer font_size_large"
              title="Add offer"
            ></i>
          </Link>
        </div> */}
        <div className="col">
          <Link
            to={`/offers/create/${props.data.prod_id}/${props.data.prod_no}`}
            className="text-decoration-none"
          >
            <i
              className="bx bx-add-to-queue cursor-pointer font_size_large"
              title="Create offer"
            ></i>
          </Link>
        </div>
        <div className="col">
          <Link
            to={`edit/` + props.data.prod_id}
            className="text-decoration-none"
          >
            <i
              className="bx bx-edit cursor-pointer font_size_large"
              title="Edit product"
            ></i>
          </Link>
        </div>

        <div className="col">
          <i
            className="bx bx-folder-minus cursor-pointer font_size_large text-danger"
            onClick={() => props.extraParams.deleteProductConfirmation(props.data.prod_id)}
            title="Delete product"
          ></i>
        </div>
      </div>
    </>
  )
}

export default BtnCellRenderer
