import {
  CO_GET_SUPPLIERNAME_LISTING,
  CO_GET_SUPPLIERNAME_LISTING_SUCCESS,
  CO_GET_SUPPLIERNAME_LISTING_FAILED,
  CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
  CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS,
  CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED
  } from "./actionTypes"
  
  export const coSupplierNameListing = () => {
    return {
      type: CO_GET_SUPPLIERNAME_LISTING,
    }
  }
  
  export const coSupplierNameListingSuccess = supplierName => {
    return {
      type: CO_GET_SUPPLIERNAME_LISTING_SUCCESS,
      payload: supplierName,
    }
  }
  
  export const coSupplierNameListingFail = error => {
    return {
      type: CO_GET_SUPPLIERNAME_LISTING_FAILED,
      payload: error,
    }
  }

  export const coSupplierNameListingForEditOfferSearch = data => {
    return {
      type: CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
      payload: { data: data },
    }
  }
  
  export const coSupplierNameListingForEditOfferSearchPageSuccess = supplierListingForSearch => {
    return {
      type: CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS,
      payload: supplierListingForSearch,
    }
  }
  
  export const coSupplierNameListingForEditOfferSearchPageFail = error => {
    return {
      type: CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED,
      payload: error,
    }
  }
  