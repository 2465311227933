export const BD_GET_SUPPLIERNAME_LISTING = "BD_GET_SUPPLIERNAME_LISTING"
export const BD_GET_SUPPLIERNAME_LISTING_SUCCESS = "BD_GET_SUPPLIERNAME_LISTING_SUCCESS"
export const BD_GET_SUPPLIERNAME_LISTING_FAILED = "BD_GET_SUPPLIERNAME_LISTING_FAILED"

export const BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE =
  "BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE"
export const BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS =
  "BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS"
export const BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED =
  "BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED"

