import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { BD_GET_PACKINGSTANDARD_LISTING } from "./actionTypes"
import {
  bdPackingStandardListingSuccess,
  bdPackingStandardListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { bdPackingStandardListingApi } from "../../../helpers/fakebackend_helper"

function* getBdPackingStandardListing() {
  try {
    const response = yield call(bdPackingStandardListingApi)
    if (!response.results.packing_standards) {
      yield put(bdPackingStandardListingFail(error))
    }
    yield put(bdPackingStandardListingSuccess(response.results.packing_standards))
  } catch (error) {
    yield put(bdPackingStandardListingFail(error))
  }
}

function* bdPackingStandardSaga() {
  yield takeEvery(BD_GET_PACKINGSTANDARD_LISTING, getBdPackingStandardListing)
}
export default bdPackingStandardSaga
