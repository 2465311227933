export const GET_PRODUCTCATEGORY_LISTING = "GET_PRODUCTCATEGORY_LISTING"
export const GET_PRODUCTCATEGORY_LISTING_SUCCESS =
  "GET_PRODUCTCATEGORY_LISTING_SUCCESS"
export const GET_PRODUCTCATEGORY_LISTING_FAILED =
  "GET_PRODUCTCATEGORY_LISTING_FAILED"

export const ADD_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY"
export const ADD_PRODUCT_CATEGORY_SUCCESS = "ADD_PRODUCT_CATEGORY_SUCCESS"
export const ADD_PRODUCT_CATEGORY_FAIL = "ADD_PRODUCT_CATEGORY_FAIL"

export const CHANGE_PRODUCT_CATEGORY_STATUS = "CHANGE_PRODUCT_CATEGORY_STATUS"
export const CHANGE_PRODUCT_CATEGORY_STATUS_SUCCESS =
  "CHANGE_PRODUCT_CATEGORY_STATUS_SUCCESS"
export const CHANGE_PRODUCT_CATEGORY_STATUS_FAIL =
  "CHANGE_PRODUCT_CATEGORY_STATUS_FAIL"

export const EDIT_PRODUCT_CATEGORY = "EDIT_PRODUCT_CATEGORY"
export const EDIT_PRODUCT_CATEGORY_SUCCESS = "EDIT_PRODUCT_CATEGORY_SUCCESS"
export const EDIT_PRODUCT_CATEGORY_FAIL = "EDIT_PRODUCT_CATEGORY_FAIL"

export const UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY"
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = "UPDATE_PRODUCT_CATEGORY_SUCCESS"
export const UPDATE_PRODUCT_CATEGORY_FAIL = "UPDATE_PRODUCT_CATEGORY_FAIL"

export const FETCH_PRODUCT_CATEGORY_BY_SECTION =
  "FETCH_PRODUCT_CATEGORY_BY_SECTION"
export const FETCH_PRODUCT_CATEGORY_BY_SECTION_SUCCESS =
  "FETCH_PRODUCT_CATEGORY_BY_SECTION_SUCCESS"
export const FETCH_PRODUCT_CATEGORY_BY_SECTION_FAIL =
  "FETCH_PRODUCT_CATEGORY_BY_SECTION_FAIL"

export const UPDATE_PRODUCT_CATEGORY_POSITION =
  "UPDATE_PRODUCT_CATEGORY_POSITION"
export const UPDATE_PRODUCT_CATEGORY_POSITION_SUCCESS =
  "UPDATE_PRODUCT_CATEGORY_POSITION_SUCCESS"
export const UPDATE_PRODUCT_CATEGORY_POSITION_FAIL =
  "UPDATE_PRODUCT_CATEGORY_POSITION_FAIL"
