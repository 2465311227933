import axios from "axios"
import offer from "store/admin/offers/reducers"
import { del, get, post, put, postFiles, postLogin } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = sessionStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Forgot password Method
const postForgotPassword = data => postLogin(url.POST_FORGOT_PASSWORD, data)

// Login Method
const postJwtLogin = data => post(url.POST_JWT_LOGIN, data)
const validateTokenApi = data => get(url.VALIDATE_TOKEN, data)

// admin users
const addUser = data => post(url.ADD_USER, data)

const usersListingApi = data => post(url.USER_LISTING, data)

const rolesListingApi = data => get(url.ROLES_LISTING, data)
const addUserRoleApi = data => post(url.ADD_USER_ROLE, data)
const editUserRoleApi = data => get(url.EDIT_USER_ROLE + data)
const updateUserRoleApi = data => post(url.UPDATE_USER_ROLE, data)
const changeUserRoleApi = data => post(url.CHANGE_USER_ROLE_STATUS, data)

const officeListingApi = data => get(url.OFFICE_LISTING, data)
const changeUserStatusApi = data => post(url.USER_STATUS_CHANGE, data)

const editUserApi = data => get(url.USER_EDIT + data)

const updateUserApi = data => post(url.USER_UPDATE, data)

const adminResetUserPassword = data => post(url.ADMIN_RESET_USER_PASSWORD, data)

// admin PRODUCTS
const addProduct = data => postFiles(url.ADD_PRODUCT, data)
const editProductApi = data => get(url.PRODUCT_EDIT + data)
const updateProductApi = data => postFiles(url.PRODUCT_UPDATE, data)
const productsListingApi = data => post(url.PRODUCT_LISTING, data)
const deleteProductImageApi = data => post(url.PRODUCT_IMAGE_DELETE, data)
const deleteProductApi = data => post(url.PRODUCT_DELETE, data)
const changeProductStatusApi = data => post(url.CHANGE_PRODUCT_STATUS, data)
const qualityRatingListingApi = data => get(url.QUALITYRATING_LISTING, data)
const productCategoryListingApi = data => get(url.PRODUCTCATEGORY_LISTING, data)
const constructionListingApi = data => get(url.CONSTRUCTION_LISTING, data)

// admin logs
const logsListingApi = data => post(url.LOGS_LISTING, data)

//admin offer details
const addOffer = data => post(url.OFFER_ADD, data)
const offersListingApi = data => post(url.OFFERS_LISTING, data)
const deleteOfferApi = data => post(url.OFFER_DELETE, data)
const editOfferApi = data => get(url.OFFER_EDIT + data)
const updateOfferApi = data => post(url.OFFER_UPDATE, data)
const changeOfferStatusApi = data => post(url.CHANGE_OFFER_STATUS, data)

const supplierNameListingApi = data => get(url.SUPPLIERNAME_LISTING, data)
const addSupplierApi = data => post(url.ADD_SUPPLIER, data)
const getSupplierDetailsById = data => get(url.SUPPLIER_DETAIL_BY_ID + data)
const updateSupplierApi = data => post(url.UPDATE_SUPPLIER, data)
const supplierChangeStatusApi = data => post(url.SUPPLIER_CHANGE_STATUS, data)

const offerStatusListingApi = data => get(url.OFFERSTATUS_LISTING, data)
const incotermCityListingApi = data => get(url.INCOTERMCITY_LISTING, data)

const materialStandardListingApi = data =>
  get(url.MATERIALSTANDARD_LISTING, data)
const packingStandardListingApi = data => get(url.PACKINGSTANDARD_LISTING, data)

const environmentalAndHealthCertificationListingApi = data =>
  get(url.ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING, data)
const packagingListingApi = data => get(url.PACKAGING_LISTING, data)
const deliveredOnListingApi = data => get(url.DELIVEREDON_LISTING, data)
const heaveyColliListingApi = data => get(url.HEAVEYCOLLI_LISTING, data)
const userListAccordingRoleApi = data =>
  post(url.USERLISTACCORDINGROLE_LISTING, data)
const createdByListingApi = data => get(url.CREATEDBY_LISTING, data)
const productNumberListingApi = data => get(url.PRODUCT_NUMBER_LISTING, data)
const supplierNameListingForEditOfferSearchPageApi = data =>
  post(url.SUPPLIERNAME_LISTING_FOR_EDIT_OFFER, data)
const searchItemInNewOfferPageApi = data => post(url.SEARCH_IN_OFFER, data)

//admin collection
const addCollection = data => post(url.COLLECTION_ADD, data)
const collectionsListingApi = data => post(url.COLLECTIONS_LISTING, data)
const editCollectionApi = data => get(url.COLLECTION_EDIT + data)
const updateCollectionApi = data => post(url.COLLECTION_UPDATE, data)
const deleteCollectionApi = data => post(url.COLLECTION_DELETE, data)

const exportCollectionCatalogApi = data =>
  post(url.EXPORTCOLLECTIONCATALOG_DOWNLOAD, data)
const copyCollection = data => post(url.COLLECTION_COPY, data)

const getAddMoreToCollection = data =>
  post(url.ADMIN_FETCH_ADD_MORE_DATA_TO_COLLECTION, data)
const addMoreDataToCollectionUpdate = data =>
  post(url.ADMIN_ADD_MORE_DATA_TO_COLLECTION_UPDATE, data)
const adminExportDownloadProgressApi = data =>
  post(url.ADMINEXPORTDOWNLOAD_PROGRESS, data)
const getProductForAddCollectionApi = data =>
  post(url.PRODUCT_FOR_ADD_COLLECTION, data)

const getProductsOfferForAddCollectionApi = data =>
  post(url.PRODUCT_OFFER_FOR_ADD_COLLECTION, data)
const refreshCollectionCountApi = data =>
  post(url.REFRESH_COLLECTION_COUNT, data)
const getLayoutApi = data => get(url.GET_LAYOUT_LISTING, data)

//bd product
const bdProductListingApi = data => post(url.BD_PRODUCT_LISTING, data)
const bdEditProductApi = data => get(url.BD_PRODUCT_EDIT + data)

// bd offer
const bdAddOfferApi = data => post(url.BD_OFFER_ADD, data)
const bdDeliveredOnListingApi = data => get(url.BD_DELIVEREDON_LISTING, data)
const bdEnvironmentalAndHealthCertificationListingApi = data =>
  get(url.BD_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING, data)
const bdHeaveyColliListingApi = data => get(url.BD_HEAVEYCOLLI_LISTING, data)
const bdIncotermCityListingApi = data => get(url.BD_INCOTERMCITY_LISTING, data)
const bdChangeOfferStatusApi = data => post(url.BD_CHANGE_OFFER_STATUS, data)

const bdMaterialStandardListingApi = data =>
  get(url.BD_MATERIALSTANDARD_LISTING, data)
const bdPackagingListingApi = data => get(url.BD_PACKAGING_LISTING, data)
const bdOfferStatusListingApi = data => get(url.BD_OFFERSTATUS_LISTING, data)
const bdPackingStandardListingApi = data =>
  get(url.BD_PACKINGSTANDARD_LISTING, data)
const bdSupplierNameListingApi = data => get(url.BD_SUPPLIERNAME_LISTING, data)
const bdUserListAccordingRoleApi = data =>
  post(url.BD_USERLISTACCORDINGROLE_LISTING, data)
const bdOffersListingApi = data => post(url.BD_OFFERS_LISTING, data)
const bdEditOfferApi = data => get(url.BD_OFFER_EDIT + data)
const bdUpdateOfferApi = data => post(url.BD_OFFER_UPDATE, data)

const bdCreatedByListingApi = data => get(url.BD_CREATEDBY_LISTING, data)
const bdProductCategoryListingApi = data =>
  get(url.BD_PRODUCTCATEGORY_LISTING, data)
const bdProductNumberListingApi = data =>
  get(url.BD_PRODUCT_NUMBER_LISTING, data)
const bdSupplierNameListingForEditOfferSearchPageApi = data =>
  post(url.BD_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER, data)
const bdSearchItemInNewOfferPageApi = data => post(url.BD_SEARCH_IN_OFFER, data)

// dashboard counts
const countsOnDashboardApi = data => get(url.COUNTS_ON_DASHBOARD, data)

// profile
const editProfileApi = data => get(url.PROFILE_EDIT)
const onUpdateProfileApi = data => postFiles(url.UPDATE_PROFILE, data)
const onChangePasswordApi = data => post(url.CHANGE_PASSWORD, data)

//bd collection and export
const addCollectionApi = data => post(url.BD_COLLECTION_ADD, data)
const bdCollectionsListingApi = data => post(url.BD_COLLECTIONS_LISTING, data)
const bdEditCollectionApi = data => get(url.BD_COLLECTION_EDIT + data)
const bdUpdateCollectionApi = data => post(url.BD_COLLECTION_UPDATE, data)
const bdDeleteCollectionApi = data => post(url.BD_COLLECTION_DELETE, data)
const bdExportCollectionCatalogApi = data =>
  post(url.BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD, data)
const bdCopyCollectionApi = data => post(url.BD_COLLECTION_COPY, data)
const getBdAddMoreToCollectionApi = data =>
  post(url.BD_FETCH_ADD_MORE_DATA_TO_COLLECTION, data)
const bdAddMoreDataToCollectionUpdateApi = data =>
  post(url.BD_ADD_MORE_DATA_TO_COLLECTION_UPDATE, data)
const bdExportDownloadProgressApi = data =>
  post(url.BD_EXPORTDOWNLOAD_PROGRESS, data)
const bdGetProductForAddCollectionApi = data =>
  post(url.BD_PRODUCT_FOR_ADD_COLLECTION, data)
const bdGetProductsOfferForAddCollectionApi = data =>
  post(url.BD_PRODUCT_OFFER_FOR_ADD_COLLECTION, data)
const bdRefreshCollectionCountApi = data =>
  post(url.BD_REFRESH_COLLECTION_COUNT, data)
const bdGetLayoutApi = data => get(url.BD_GET_LAYOUT_LISTING, data)

// co PRODUCTS
const addProductCO = data => postFiles(url.ADD_PRODUCT_CO, data)
const editProductApiCO = data => get(url.PRODUCT_EDIT_CO + data)
const updateProductApiCO = data => postFiles(url.PRODUCT_UPDATE_CO, data)
const productsListingApiCO = data => post(url.PRODUCT_LISTING_CO, data)
const deleteProductImageApiCO = data => post(url.PRODUCT_IMAGE_DELETE_CO, data)
const deleteProductApiCO = data => post(url.PRODUCT_DELETE_CO, data)
const changeProductStatusApiCO = data =>
  post(url.CHANGE_PRODUCT_STATUS_CO, data)
const coQualityRatingListingApi = data =>
  get(url.QUALITYRATING_LISTING_CO, data)
const coProductCategoryListingApi = data =>
  get(url.PRODUCTCATEGORY_LISTING_CO, data)
const coConstructionListingApi = data => get(url.CONSTRUCTION_LISTING_CO, data)

//co offer
const coAddOfferApi = data => post(url.CO_OFFER_ADD, data)
const coOffersListingApi = data => post(url.CO_OFFERS_LISTING, data)
const coDeleteOfferApi = data => post(url.CO_OFFER_DELETE, data)
const coEditOfferApi = data => get(url.CO_OFFER_EDIT + data)
const coUpdateOfferApi = data => post(url.CO_OFFER_UPDATE, data)
const coChangeOfferStatusApi = data => post(url.CO_CHANGE_OFFER_STATUS, data)

const coDeliveredOnListingApi = data => get(url.CO_DELIVEREDON_LISTING, data)
const coEnvironmentalAndHealthCertificationListingApi = data =>
  get(url.CO_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING, data)
const coHeaveyColliListingApi = data => get(url.CO_HEAVEYCOLLI_LISTING, data)
const coIncotermCityListingApi = data => get(url.CO_INCOTERMCITY_LISTING, data)
const coMaterialStandardListingApi = data =>
  get(url.CO_MATERIALSTANDARD_LISTING, data)
const coOfferStatusListingApi = data => get(url.CO_OFFERSTATUS_LISTING, data)
const coPackagingListingApi = data => get(url.CO_PACKAGING_LISTING, data)
const coPackingStandardListingApi = data =>
  get(url.CO_PACKINGSTANDARD_LISTING, data)
const coSupplierNameListingApi = data => get(url.CO_SUPPLIERNAME_LISTING, data)
const coUserListAccordingRoleApi = data =>
  post(url.CO_USERLISTACCORDINGROLE_LISTING, data)

const coCreatedByListingApi = data => get(url.CO_CREATEDBY_LISTING, data)
const coProductNumberListingApi = data =>
  get(url.CO_PRODUCT_NUMBER_LISTING, data)
const coSupplierNameListingForEditOfferSearchPageApi = data =>
  post(url.CO_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER, data)
const coSearchItemInNewOfferPageApi = data => post(url.CO_SEARCH_IN_OFFER, data)

//co collection and export
const coAddCollectionApi = data => post(url.CO_COLLECTION_ADD, data)
const coCollectionsListingApi = data => post(url.CO_COLLECTIONS_LISTING, data)
const coEditCollectionApi = data => get(url.CO_COLLECTION_EDIT + data)
const coUpdateCollectionApi = data => post(url.CO_COLLECTION_UPDATE, data)
const coDeleteCollectionApi = data => post(url.CO_COLLECTION_DELETE, data)

const coExportCollectionCatalogApi = data =>
  post(url.CO_EXPORTCOLLECTIONCATALOG_DOWNLOAD, data)
const coCopyCollectionApi = data => post(url.CO_COLLECTION_COPY, data)
const coExportDownloadProgressApi = data =>
  post(url.CO_EXPORTDOWNLOAD_PROGRESS, data)
const coGetAddMoreToCollectionApi = data =>
  post(url.CO_FETCH_ADD_MORE_DATA_TO_COLLECTION, data)
const coAddMoreDataToCollectionUpdateApi = data =>
  post(url.CO_ADD_MORE_DATA_TO_COLLECTION_UPDATE, data)
const coGetProductForAddCollectionApi = data =>
  post(url.CO_PRODUCT_FOR_ADD_COLLECTION, data)
const coGetProductsOfferForAddCollectionApi = data =>
  post(url.CO_PRODUCT_OFFER_FOR_ADD_COLLECTION, data)
const coRefreshCollectionCountApi = data =>
  post(url.CO_REFRESH_COLLECTION_COUNT, data)
const coGetLayoutApi = data => get(url.CO_GET_LAYOUT_LISTING, data)

//sa product
const saProductListingApi = data => post(url.SA_PRODUCT_LISTING, data)
const saEditProductApi = data => get(url.SA_PRODUCT_EDIT + data)

//sa offer
const saOffersListingApi = data => post(url.SA_OFFERS_LISTING, data)
const saEditOfferApi = data => get(url.SA_OFFER_EDIT + data)
const saUpdateOfferApi = data => post(url.SA_OFFER_UPDATE, data)
const saSearchItemInNewOfferPageApi = data => post(url.SA_SEARCH_IN_OFFER, data)

//SA collection and export
const saAddCollectionApi = data => post(url.SA_COLLECTION_ADD, data)
const saCollectionsListingApi = data => post(url.SA_COLLECTIONS_LISTING, data)
const saEditCollectionApi = data => get(url.SA_COLLECTION_EDIT + data)
const saUpdateCollectionApi = data => post(url.SA_COLLECTION_UPDATE, data)
const saDeleteCollectionApi = data => post(url.SA_COLLECTION_DELETE, data)

const saExportCollectionCatalogApi = data =>
  post(url.SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD, data)
const saCopyCollectionApi = data => post(url.SA_COLLECTION_COPY, data)
const saExportDownloadProgressApi = data =>
  post(url.SA_EXPORTDOWNLOAD_PROGRESS, data)
const saGetAddMoreToCollectionApi = data =>
  post(url.SA_FETCH_ADD_MORE_DATA_TO_COLLECTION, data)
const saAddMoreDataToCollectionUpdateApi = data =>
  post(url.SA_ADD_MORE_DATA_TO_COLLECTION_UPDATE, data)
const saGetProductForAddCollectionApi = data =>
  post(url.SA_PRODUCT_FOR_ADD_COLLECTION, data)
const saGetProductsOfferForAddCollectionApi = data =>
  post(url.SA_PRODUCT_OFFER_FOR_ADD_COLLECTION, data)
const saRefreshCollectionCountApi = data =>
  post(url.SA_REFRESH_COLLECTION_COUNT, data)
const saGetLayoutApi = data => get(url.SA_GET_LAYOUT_LISTING, data)

//qa product
const qaProductListingApi = data => post(url.QA_PRODUCT_LISTING, data)
const qaEditProductApi = data => get(url.QA_PRODUCT_EDIT + data)

//QA offer
const qaOffersListingApi = data => post(url.QA_OFFERS_LISTING, data)
const qaEditOfferApi = data => get(url.QA_OFFER_EDIT + data)
const qaUpdateOfferApi = data => post(url.QA_OFFER_UPDATE, data)
const qaUserListAccordingRoleApi = data =>
  post(url.QA_USERLISTACCORDINGROLE_LISTING, data)
const qaSearchItemInNewOfferPageApi = data => post(url.QA_SEARCH_IN_OFFER, data)

//qa collection and export
const qaAddCollectionApi = data => post(url.QA_COLLECTION_ADD, data)
const qaCollectionsListingApi = data => post(url.QA_COLLECTIONS_LISTING, data)
const qaEditCollectionApi = data => get(url.QA_COLLECTION_EDIT + data)
const qaUpdateCollectionApi = data => post(url.QA_COLLECTION_UPDATE, data)
const qaDeleteCollectionApi = data => post(url.QA_COLLECTION_DELETE, data)

const qaExportCollectionCatalogApi = data =>
  post(url.QA_EXPORTCOLLECTIONCATALOG_DOWNLOAD, data)
const qaCopyCollectionApi = data => post(url.QA_COLLECTION_COPY, data)
const qaExportDownloadProgressApi = data =>
  post(url.QA_EXPORTDOWNLOAD_PROGRESS, data)
const qaGetAddMoreToCollectionApi = data =>
  post(url.QA_FETCH_ADD_MORE_DATA_TO_COLLECTION, data)
const qaAddMoreDataToCollectionUpdateApi = data =>
  post(url.QA_ADD_MORE_DATA_TO_COLLECTION_UPDATE, data)
const qaGetProductForAddCollectionApi = data =>
  post(url.QA_PRODUCT_FOR_ADD_COLLECTION, data)
const qaGetProductsOfferForAddCollectionApi = data =>
  post(url.QA_PRODUCT_OFFER_FOR_ADD_COLLECTION, data)
const qaRefreshCollectionCountApi = data =>
  post(url.QA_REFRESH_COLLECTION_COUNT, data)
const qaGetLayoutApi = data => get(url.QA_GET_LAYOUT_LISTING, data)

//dropdown listing for all
const fetchDropdownsBySectionApi = data =>
  post(url.FETCH_DROPDOWNS_BY_SECTION, data) //main api, fetching all the dropdown lsting in dropdown section

const fetchDropDownPositionApi = data => post(url.FETCH_DROPDOWN_POSITION, data)

// product category APIs (excluded listing)
const addProductCategoryApi = data => post(url.ADD_PRODUCT_CATEGORY, data)
const changeProductCategoryStatusApi = data =>
  post(url.CHANGE_PRODUCT_CATEGORY_STATUS, data)
const editProductCategoryApi = data => get(url.EDIT_PRODUCT_CATEGORY + data)
const updateProductCategoryApi = data => post(url.UPDATE_PRODUCT_CATEGORY, data)

// quality ratings APIs (excluded listing)
const addQualityRatingApi = data => post(url.ADD_QUALITY_RATING, data)
const changeQualityRatingStatusApi = data =>
  post(url.CHANGE_QUALITY_RATING_STATUS, data)
const editQualityRatingApi = data => get(url.EDIT_QUALITY_RATING + data)
const updateQualityRatingApi = data => post(url.UPDATE_QUALITY_RATING, data)

// deliveres APIs (excluded listing)
const addDeliveredApi = data => post(url.ADD_DELIVERED_ON, data)
const changeDeliveredStatusApi = data =>
  post(url.CHANGE_DELIVERED_ON_STATUS, data)
const editDeliveredApi = data => get(url.EDIT_DELIVERED_ON + data)
const updateDeliveredApi = data => post(url.UPDATE_DELIVERED_ON, data)

// env certificates APIs (excluded listing)
const addCertificatesApi = data => post(url.ADD_CERTIFICATES, data)
const changeCertificatesStatusApi = data =>
  post(url.CHANGE_CERTIFICATES_STATUS, data)
const editCertificatesApi = data => get(url.EDIT_CERTIFICATES + data)
const updateCertificatesApi = data => post(url.UPDATE_CERTIFICATES, data)

// heavey colli APIs (excluded listing)
const addHeaveyColliApi = data => post(url.ADD_HEAVEY_COLLI, data)
const changeHeaveyColliStatusApi = data =>
  post(url.CHANGE_HEAVEY_COLLI_STATUS, data)
const editHeaveyColliApi = data => get(url.EDIT_HEAVEY_COLLI + data)
const updateHeaveyColliApi = data => post(url.UPDATE_HEAVEY_COLLI, data)

// incoterm city APIs (excluded listing)
const addIncotermApi = data => post(url.ADD_INCOTERM, data)
const changeIncotermStatusApi = data => post(url.CHANGE_INCOTERM_STATUS, data)
const editIncotermApi = data => get(url.EDIT_INCOTERM + data)
const updateIncotermApi = data => post(url.UPDATE_INCOTERM, data)

// material standard APIs (excluded listing)
const addMaterialApi = data => post(url.ADD_MATERIAL, data)
const changeMaterialStatusApi = data => post(url.CHANGE_MATERIAL, data)
const editMaterialApi = data => get(url.EDIT_MATERIAL + data)
const updateMaterialApi = data => post(url.UPDATE_MATERIAL, data)

// activity log
const activityLogApi = data => post(url.ACTIVITY_LOG, data)

// offer status APIs (excluded listing)
const addOfferStatusApi = data => post(url.ADD_OFFER_STATUS, data)
const OfferStatusChangeStatusApi = data => post(url.OFFER_STATUS_CHANGE, data)
const getOfferStatusDetailsById = data => get(url.EDIT_OFFER_STATUS + data)
const updateOfferStatusApi = data => post(url.UPDATE_OFFER_STATUS, data)

// office APIs (excluded listing)
const addOfficeApi = data => post(url.ADD_OFFICE, data)
const OfficeChangeStatusApi = data => post(url.OFFICE_STATUS_CHANGE, data)
const getOfficeDetailsById = data => get(url.EDIT_OFFICE + data)
const updateOfficeApi = data => post(url.UPDATE_OFFICE, data)

// packaging APIs (excluded listing)
const addPackagingApi = data => post(url.ADD_PACKAGING, data)
const PackagingChangeStatusApi = data => post(url.PACKAGING_STATUS_CHANGE, data)
const getPackagingDetailsById = data => get(url.EDIT_PACKAGING + data)
const updatePackagingApi = data => post(url.UPDATE_PACKAGING, data)

// packing standard APIs (excluded listing)
const addPackingStandardApi = data => post(url.ADD_PACKING_STANDARD, data)
const PackingStandardChangeStatusApi = data =>
  post(url.PACKING_STANDARD_STATUS_CHANGE, data)
const getPackingStandardDetailsById = data =>
  get(url.EDIT_PACKING_STANDARD + data)
const updatePackingStandardApi = data => post(url.UPDATE_PACKING_STANDARD, data)

// construction APIs (excluded listing)
const addConstructionApi = data => post(url.ADD_CONSTRUCTION, data)
const changeConstructionStatusApi = data =>
  post(url.CONSTRUCTION_STATUS_CHANGE, data)
const editConstructionApi = data => get(url.EDIT_CONSTRUCTION + data)
const updateConstructionApi = data => post(url.UPDATE_CONSTRUCTION, data)

export {
  coSupplierNameListingForEditOfferSearchPageApi,
  bdSupplierNameListingForEditOfferSearchPageApi,
  supplierNameListingForEditOfferSearchPageApi,
  getLoggedInUser,
  postForgotPassword,
  validateTokenApi,
  isUserAuthenticated,
  postJwtLogin,
  addUser,
  usersListingApi,
  officeListingApi,
  rolesListingApi,
  productCategoryListingApi,
  productsListingApi,
  addProduct,
  qualityRatingListingApi,
  logsListingApi,
  supplierNameListingApi,
  offerStatusListingApi,
  incotermCityListingApi,
  materialStandardListingApi,
  packingStandardListingApi,
  environmentalAndHealthCertificationListingApi,
  packagingListingApi,
  deliveredOnListingApi,
  heaveyColliListingApi,
  addOffer,
  createdByListingApi,
  productNumberListingApi,
  searchItemInNewOfferPageApi,
  offersListingApi,
  userListAccordingRoleApi,
  editProductApi,
  updateProductApi,
  deleteProductImageApi,
  deleteProductApi,
  changeProductStatusApi,
  deleteOfferApi,
  editOfferApi,
  updateOfferApi,
  changeOfferStatusApi,
  addCollection,
  collectionsListingApi,
  editCollectionApi,
  updateCollectionApi,
  deleteCollectionApi,
  exportCollectionCatalogApi,
  copyCollection,
  refreshCollectionCountApi,
  adminExportDownloadProgressApi,
  getLayoutApi,
  bdProductListingApi,
  bdAddOfferApi,
  countsOnDashboardApi,
  changeUserStatusApi,
  editUserApi,
  updateUserApi,
  getAddMoreToCollection,
  addMoreDataToCollectionUpdate,
  editProfileApi,
  onUpdateProfileApi,
  onChangePasswordApi,
  bdDeliveredOnListingApi,
  bdEnvironmentalAndHealthCertificationListingApi,
  bdHeaveyColliListingApi,
  bdIncotermCityListingApi,
  bdMaterialStandardListingApi,
  bdPackagingListingApi,
  bdOfferStatusListingApi,
  bdPackingStandardListingApi,
  bdSupplierNameListingApi,
  bdUserListAccordingRoleApi,
  bdOffersListingApi,
  adminResetUserPassword,
  bdEditOfferApi,
  bdUpdateOfferApi,
  addCollectionApi,
  bdCollectionsListingApi,
  bdEditCollectionApi,
  bdUpdateCollectionApi,
  bdDeleteCollectionApi,
  bdExportCollectionCatalogApi,
  bdCopyCollectionApi,
  getBdAddMoreToCollectionApi,
  bdAddMoreDataToCollectionUpdateApi,
  bdExportDownloadProgressApi,
  bdGetLayoutApi,
  getProductForAddCollectionApi,
  getProductsOfferForAddCollectionApi,
  bdGetProductForAddCollectionApi,
  bdGetProductsOfferForAddCollectionApi,
  productsListingApiCO,
  addProductCO,
  editProductApiCO,
  updateProductApiCO,
  deleteProductImageApiCO,
  deleteProductApiCO,
  changeProductStatusApiCO,
  coQualityRatingListingApi,
  coProductCategoryListingApi,
  coAddOfferApi,
  coDeliveredOnListingApi,
  coOffersListingApi,
  coDeleteOfferApi,
  coEditOfferApi,
  coUpdateOfferApi,
  coChangeOfferStatusApi,
  coEnvironmentalAndHealthCertificationListingApi,
  coHeaveyColliListingApi,
  coIncotermCityListingApi,
  coMaterialStandardListingApi,
  coOfferStatusListingApi,
  coPackagingListingApi,
  coPackingStandardListingApi,
  coSupplierNameListingApi,
  coUserListAccordingRoleApi,
  coAddCollectionApi,
  coCollectionsListingApi,
  coEditCollectionApi,
  coUpdateCollectionApi,
  coDeleteCollectionApi,
  coExportCollectionCatalogApi,
  coCopyCollectionApi,
  coExportDownloadProgressApi,
  coGetAddMoreToCollectionApi,
  coAddMoreDataToCollectionUpdateApi,
  coGetProductForAddCollectionApi,
  coGetProductsOfferForAddCollectionApi,
  bdEditProductApi,
  bdChangeOfferStatusApi,
  qaProductListingApi,
  qaEditProductApi,
  qaOffersListingApi,
  qaEditOfferApi,
  qaUpdateOfferApi,
  qaUserListAccordingRoleApi,
  qaAddCollectionApi,
  qaCollectionsListingApi,
  qaEditCollectionApi,
  qaUpdateCollectionApi,
  qaDeleteCollectionApi,
  qaExportCollectionCatalogApi,
  qaCopyCollectionApi,
  qaExportDownloadProgressApi,
  qaGetAddMoreToCollectionApi,
  qaAddMoreDataToCollectionUpdateApi,
  qaGetProductForAddCollectionApi,
  qaGetProductsOfferForAddCollectionApi,
  saProductListingApi,
  saEditProductApi,
  saOffersListingApi,
  saEditOfferApi,
  saUpdateOfferApi,
  saAddCollectionApi,
  saCollectionsListingApi,
  saEditCollectionApi,
  saUpdateCollectionApi,
  saDeleteCollectionApi,
  saExportCollectionCatalogApi,
  saCopyCollectionApi,
  saExportDownloadProgressApi,
  saGetAddMoreToCollectionApi,
  saAddMoreDataToCollectionUpdateApi,
  saGetProductForAddCollectionApi,
  saGetProductsOfferForAddCollectionApi,
  bdRefreshCollectionCountApi,
  coRefreshCollectionCountApi,
  qaRefreshCollectionCountApi,
  saRefreshCollectionCountApi,
  addProductCategoryApi,
  changeProductCategoryStatusApi,
  editProductCategoryApi,
  updateProductCategoryApi,
  fetchDropdownsBySectionApi,
  addQualityRatingApi,
  changeQualityRatingStatusApi,
  editQualityRatingApi,
  updateQualityRatingApi,
  addDeliveredApi,
  changeDeliveredStatusApi,
  editDeliveredApi,
  updateDeliveredApi,
  addCertificatesApi,
  changeCertificatesStatusApi,
  editCertificatesApi,
  updateCertificatesApi,
  addHeaveyColliApi,
  changeHeaveyColliStatusApi,
  editHeaveyColliApi,
  updateHeaveyColliApi,
  addIncotermApi,
  changeIncotermStatusApi,
  editIncotermApi,
  updateIncotermApi,
  addMaterialApi,
  changeMaterialStatusApi,
  editMaterialApi,
  updateMaterialApi,
  activityLogApi,
  addSupplierApi,
  getSupplierDetailsById,
  updateSupplierApi,
  supplierChangeStatusApi,
  fetchDropDownPositionApi,
  addUserRoleApi,
  editUserRoleApi,
  updateUserRoleApi,
  changeUserRoleApi,
  addOfferStatusApi,
  getOfferStatusDetailsById,
  updateOfferStatusApi,
  OfferStatusChangeStatusApi,
  addOfficeApi,
  getOfficeDetailsById,
  updateOfficeApi,
  OfficeChangeStatusApi,
  addPackagingApi,
  getPackagingDetailsById,
  updatePackagingApi,
  PackagingChangeStatusApi,
  addPackingStandardApi,
  getPackingStandardDetailsById,
  updatePackingStandardApi,
  PackingStandardChangeStatusApi,
  constructionListingApi,
  addConstructionApi,
  changeConstructionStatusApi,
  editConstructionApi,
  updateConstructionApi,
  coConstructionListingApi,
  coGetLayoutApi,
  qaGetLayoutApi,
  saGetLayoutApi,
  bdCreatedByListingApi,
  bdProductCategoryListingApi,
  bdProductNumberListingApi,
  bdSearchItemInNewOfferPageApi,
  coCreatedByListingApi,
  coProductNumberListingApi,
  coSearchItemInNewOfferPageApi,
  saSearchItemInNewOfferPageApi,
  qaSearchItemInNewOfferPageApi,
}
