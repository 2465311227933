import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, VALIDATE_TOKEN } from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  validateTokenSuccess,
  validateTokenError,
} from "./actions"

import {
  postJwtLogin,
  validateTokenApi,
} from "../../../helpers/fakebackend_helper"
function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      email: user.email,
      password: user.password,
    })

    if (response && response.status && response.accessToken) {
      sessionStorage.setItem("authUser", JSON.stringify(response))
      // history.push("/dashboard")
      window.location.href = "/dashboard"
    } else {
      throw "Invalid Credentials"
    }
  } catch (error) {
    let errMsg =
      "Email or password are invalid. Please enter correct email and password"
    yield put(apiError(errMsg))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    sessionStorage.removeItem("authUser")
    yield put(logoutUserSuccess())
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* validateUserToken({ payload: { login, history } }) {
  try {
    const response = yield call(validateTokenApi)
    if (response && !response.status) {
      throw "Token expired"
    }
  } catch (error) {
    yield validateTokenError(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(VALIDATE_TOKEN, validateUserToken)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
