import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { CO_GET_MATERIALSTANDARD_LISTING } from "./actionTypes"
import {
  coMaterialStandardListingSuccess,
  coMaterialStandardListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { coMaterialStandardListingApi } from "../../../helpers/fakebackend_helper"

function* coGetMaterialStandardListing() {
  try {
    const response = yield call(coMaterialStandardListingApi)
    if (!response.results.material_standards) {
      yield put(coMaterialStandardListingFail(error))
    }
    yield put(coMaterialStandardListingSuccess(response.results.material_standards))
  } catch (error) {
    yield put(coMaterialStandardListingFail(error))
  }
}

function* coMaterialStandardSaga() {
  yield takeEvery(CO_GET_MATERIALSTANDARD_LISTING, coGetMaterialStandardListing)
}
export default coMaterialStandardSaga
