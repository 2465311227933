import {
    CO_GET_INCOTERMCITY_LISTING,
    CO_GET_INCOTERMCITY_LISTING_SUCCESS,
    CO_GET_INCOTERMCITY_LISTING_FAILED,
  } from "./actionTypes"
  
  const initialState = {
    error: "",
    loading: false,
    incotermCity: null,
  }
  
  const coIncotermCity = (state = initialState, action) => {
    switch (action.type) {
      case CO_GET_INCOTERMCITY_LISTING:
        state = {
          ...state,
          loading: true,
          incotermCity: action.payload,
        }
        break
      case CO_GET_INCOTERMCITY_LISTING_SUCCESS:
        state = { ...state, loading: false, incotermCity: action.payload }
        break
      case CO_GET_INCOTERMCITY_LISTING_FAILED:
        state = { ...state, loading: false }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default coIncotermCity
  