import {
  COLLECTION_ADD,
  COLLECTION_SUCCESS,
  COLLECTION_FAIL,
  GET_COLLECTION_LISTING,
  GET_COLLECTION_LISTING_SUCCESS,
  GET_COLLECTION_LISTING_FAILED,
  COLLECTION_EDIT,
  COLLECTION_EDIT_SUCCESS,
  COLLECTION_EDIT_FAIL,
  COLLECTION_UPDATE,
  COLLECTION_UPDATE_SUCCESS,
  COLLECTION_UPDATE_FAIL,
  COLLECTION_DELETE,
  COLLECTION_DELETE_SUCCESS,
  COLLECTION_DELETE_FAIL,
  EXPORTCOLLECTIONCATALOG_DOWNLOAD,
  EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS,
  EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL,
  COLLECTION_COPY,
  COLLECTION_COPY_SUCCESS,
  COLLECTION_COPY_FAIL,
  ADMINEXPORTDOWNLOAD_PROGRESS,
  ADMINEXPORTDOWNLOAD_PROGRESS_SUCCESS,
  ADMINEXPORTDOWNLOAD_PROGRESS_FAIL,
  ADMIN_ADD_MORE_TO_COLLECTION,
  ADMIN_ADD_MORE_TO_COLLECTION_SUCCESS,
  ADMIN_ADD_MORE_TO_COLLECTION_FAIL,
  ADMIN_ADD_MORE_TO_COLLECTION_UPDATE,
  ADMIN_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS,
  ADMIN_ADD_MORE_TO_COLLECTION_UPDATE_FAIL,
  GET_PRODUCTS_FOR_ADD_COLLECTION,
  GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS,
  GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL,
  GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
  GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS,
  GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL,
  REFRESH_COLLECTION_COUNT,
  REFRESH_COLLECTION_COUNT_SUCCESS,
  REFRESH_COLLECTION_COUNT_FAIL,
  GET_LAYOUT,
  GET_LAYOUT_SUCCESS,
  GET_LAYOUT_FAIL,
} from "./actionTypes"

export const collectionSuccess = collection => {
  return {
    type: COLLECTION_SUCCESS,
    payload: collection,
  }
}

export const collectionFail = error => {
  return {
    type: COLLECTION_FAIL,
    payload: error,
  }
}

export const addNewCollection = (collection, history) => {
  return {
    type: COLLECTION_ADD,
    payload: { collection, history },
  }
}

export const collectionsListing = history => {
  return {
    type: GET_COLLECTION_LISTING,
  }
}

export const collectionsListingSuccess = collection => {
  return {
    type: GET_COLLECTION_LISTING_SUCCESS,
    payload: collection,
  }
}

export const collectionsListingFail = error => {
  return {
    type: GET_COLLECTION_LISTING_FAILED,
    payload: error,
  }
}

export const collectionEdit = id => {
  return {
    type: COLLECTION_EDIT,
    payload: { id: id },
  }
}

export const editCollectionSuccess = collection => {
  return {
    type: COLLECTION_EDIT_SUCCESS,
    payload: collection,
  }
}

export const editCollectionFail = error => {
  return {
    type: COLLECTION_EDIT_FAIL,
    payload: error,
  }
}

export const updateCollection = (collection, history) => {
  return {
    type: COLLECTION_UPDATE,
    payload: { collection, history },
  }
}

export const updateCollectionSuccess = collection => {
  return {
    type: COLLECTION_UPDATE_SUCCESS,
    payload: collection,
  }
}

export const updateCollectionFail = error => {
  return {
    type: COLLECTION_UPDATE_FAIL,
    payload: error,
  }
}

export const deleteCollection = collectionId => ({
  type: COLLECTION_DELETE,
  payload: { id: collectionId },
})

export const deleteCollectionSuccess = collection => ({
  type: COLLECTION_DELETE_SUCCESS,
  payload: collection,
})

export const deleteCollectionFail = error => ({
  type: COLLECTION_DELETE_FAIL,
  payload: error,
})

export const downloadExportCollectionCatalog = (collection, history) => {
  return {
    type: EXPORTCOLLECTIONCATALOG_DOWNLOAD,
    payload: { collection, history },
  }
}

export const exportCollectionCatalogSuccess = collection => {
  return {
    type: EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS,
    payload: collection,
  }
}

export const exportCollectionCatalogFail = error => {
  return {
    type: EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL,
    payload: error,
  }
}

export const coppyCollection = (collection, history) => {
  return {
    type: COLLECTION_COPY,
    payload: { collection, history },
  }
}

export const coppyCollectionSuccess = collection => {
  return {
    type: COLLECTION_COPY_SUCCESS,
    payload: collection,
  }
}

export const copyCollectionFail = error => {
  return {
    type: COLLECTION_COPY_FAIL,
    payload: error,
  }
}

export const adminExportDownloadProgress = (
  adminExportDownloadProgress,
  history
) => {
  return {
    type: ADMINEXPORTDOWNLOAD_PROGRESS,
    payload: { adminExportDownloadProgress, history },
  }
}

export const adminExportDownloadProgressSuccess =
  adminExportDownloadProgress => {
    return {
      type: ADMINEXPORTDOWNLOAD_PROGRESS_SUCCESS,
      payload: adminExportDownloadProgress,
    }
  }

export const adminExportDownloadProgressFail = error => {
  return {
    type: ADMINEXPORTDOWNLOAD_PROGRESS_FAIL,
    payload: error,
  }
}

export const adminGetAddMoreToCollection = collection_id => {
  return {
    type: ADMIN_ADD_MORE_TO_COLLECTION,
    payload: { collection_id: collection_id },
  }
}
export const adminGetAddMoreToCollectionSuccess = collectionAddMoreDetails => {
  return {
    type: ADMIN_ADD_MORE_TO_COLLECTION_SUCCESS,
    payload: collectionAddMoreDetails,
  }
}
export const adminGetAddMoreToCollectionFail = error => {
  return {
    type: ADMIN_ADD_MORE_TO_COLLECTION_FAIL,
    payload: error,
  }
}

export const adminGetAddMoreToCollectionUpdate = (
  addMoreUpdateDetails,
  history
) => {
  return {
    type: ADMIN_ADD_MORE_TO_COLLECTION_UPDATE,
    payload: { addMoreUpdateDetails, history },
  }
}
export const adminGetAddMoreToCollectionUpdateSuccess =
  collectionAddMoreDetailsUpdate => {
    return {
      type: ADMIN_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS,
      payload: collectionAddMoreDetailsUpdate,
    }
  }
export const adminGetAddMoreToCollectionUpdateFail = error => {
  return {
    type: ADMIN_ADD_MORE_TO_COLLECTION_UPDATE_FAIL,
    payload: error,
  }
}

export const productForAddCollection = productsForAddCollection => {
  return {
    type: GET_PRODUCTS_FOR_ADD_COLLECTION,
    payload: { productsForAddCollection },
  }
}

export const productForAddCollectionSuccess = productsForAddCollection => {
  return {
    type: GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS,
    payload: productsForAddCollection,
  }
}

export const productForAddCollectionFail = error => {
  return {
    type: GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL,
    payload: error,
  }
}

export const productOfferForAddCollection = productsOfferForAddCollection => {
  return {
    type: GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
    payload: { productsOfferForAddCollection },
  }
}

export const productOfferForAddCollectionSuccess =
  productsOfferForAddCollection => {
    return {
      type: GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS,
      payload: productsOfferForAddCollection,
    }
  }

export const productOfferForAddCollectionFail = error => {
  return {
    type: GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL,
    payload: error,
  }
}

export const refreshCollectionCount = id => {
  return {
    type: REFRESH_COLLECTION_COUNT,
    payload: { collection_id: id },
  }
}

export const refreshCollectionCountSuccess = collection => {
  return {
    type: REFRESH_COLLECTION_COUNT_SUCCESS,
    payload: collection,
  }
}

export const refreshCollectionCountFail = error => {
  return {
    type: REFRESH_COLLECTION_COUNT_FAIL,
    payload: error,
  }
}

export const getLayOut = layout => {
  return {
    type: GET_LAYOUT,
    payload: layout,
  }
}

export const getLayOutSuccess = (layout) => {
  return {
    type: GET_LAYOUT_SUCCESS,
    payload: { layout },
  }
}

export const getLayOutFail = error => {
  return {
    type: GET_LAYOUT_FAIL,
    payload: error,
  }
}
