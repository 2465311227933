import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import React, { useState, useRef, useEffect, useMemo, useCallback } from "react"
import BtnCellRenderer from "./sa-BtnCellRenderer.jsx"
import ProductImageRenderer from "./sa-ProductImageRenderer.jsx"
import ActiveInactive from "./sa-ActiveInactive.jsx"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Row, Col, Container } from "reactstrap"

import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { saProductsListing } from "../../../store/actions"

const SaProductList = props => {
  let key = `${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`
  LicenseManager.setLicenseKey(key)

  const gridRef = useRef()

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      filter: true,
      minWidth: 200,
      resizable: true,
    }),
    []
  )
  const rowHeight = 120

  document.title = "Products List"

  const dispatch = useDispatch()

  const [productsData, setProductsData] = useState([])

  const columnDefination = [
    {
      field: "action",
      cellRenderer: "BtnCellRenderer",
      maxWidth: 200,
      cellRendererSelector: params => {
        const buttons = {
          component: BtnCellRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Status",
      field: "product_status",
      cellRenderer: "ActiveInactive",
      maxWidth: 100,
      cellRendererSelector: params => {
        const buttons = {
          component: ActiveInactive,
          // params: {
          //   callChangeProductStatus: callChangeProductStatus,
          // },
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Name",
      field: "prod_name",
    },
    {
      headerName: "Product No",
      field: "prod_no",
    },
    {
      headerName: "Product Category",
      field: "prod_category",
    },
    {
      headerName: "Material Desc",
      field: "material_desc",
      cellStyle: { lineHeight: 1.5, padding: 5 },
      autoHeight: true,
      minWidth: 500,
      wrapText: true,
    },
    { headerName: "Length", field: "length", filter: "agSetColumnFilter" },
    { headerName: "Depth", field: "depth", filter: "agSetColumnFilter" },
    { headerName: "height", field: "height", filter: "agSetColumnFilter" },
    {
      headerName: "Created By",
      field: "created_by",
    },
    {
      headerName: "Created Date",
      field: "created_date",
    },
    {
      headerName: "Product Folder",
      field: "product_folder",
      maxWidth: 150,
      cellRenderer: params => {
        if (params.data.product_folder) {
          return (
            <a
              className="text-center cursor-pointer font_size_medium"
              target="_blank"
              rel="noreferrer"
              href={params.data.product_folder}
            >
              Click here
            </a>
          )
        } else {
          return undefined
        }
      },
    },
    {
      headerName: "Product Image 1",
      field: "img1",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 2",
      field: "img2",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 3",
      field: "img3",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 4",
      field: "img4",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Product Image 5",
      field: "img5",

      cellRenderer: "ProductImageRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: ProductImageRenderer,
        }
        if (params) return buttons
        else return undefined
      },
    },
  ]

  // function callChangeProductStatus(id) {
  //   dispatch(changeProductStatus(id))
  // }

  useEffect(() => {
    dispatch(saProductsListing())
  }, [saProductsListing])

  const [columnDefs, setColumnDefs] = useState(columnDefination)

  const showAgGridLoading = useCallback(() => {
    if (gridRef.current.api) {
      gridRef.current.api.showLoadingOverlay()
    }
  }, [])

  const hideAgGridLoading = useCallback(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.hideOverlay()
    }
  }, [])

  let productList = useSelector(state => state.saProduct.product)
  useEffect(() => {
    if (productList && productList.length > 0) {
      setProductsData(productList)
      if (columnDefination && productsData && productsData.length > 0) {
        gridRef.current.api.setColumnDefs(columnDefination)
      }
    }
  }, [productList])

  const { error, loading } = useSelector(state => ({
    error: state.saProduct.error,
    loading: state.saProduct.loading,
  }))
  const msg = useSelector(state => state.saProduct.msg)

  if (loading) {
    showAgGridLoading()
  } else {
    hideAgGridLoading()
  }
  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container fluid={true}>
          <Row>
            <Col md={12} lg={12} xl={12}>
              {error ? <ToastContainer /> : ""}
              {msg ? <ToastContainer /> : ""}
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: "80vh" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={productsData}
                  rowHeight={rowHeight}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  animateRows={true}
                  pagination={true}
                  paginationPageSize={20}
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while we are fetching data</span>'
                  }
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SaProductList
