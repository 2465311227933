import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
    GET_PRODUCT_NUMBER_LISTING,
} from "./actionTypes"
import {
    productNumberListingSuccess,
    productNumberListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  productNumberListingApi,
} from "../../../helpers/fakebackend_helper"

function* getproductNumberListing() {
  try {
    const response = yield call(productNumberListingApi)
    if (!response.results) {
      yield put(productNumberListingFail(error))
    }
    yield put(productNumberListingSuccess(response.results))
  } catch (error) {
    yield put(productNumberListingFail(error))
  }
}

function* productNumberSaga() {
  yield takeEvery(GET_PRODUCT_NUMBER_LISTING, getproductNumberListing)
}
export default productNumberSaga
