import {
  PRODUCT_SUCCESS_CO,
  PRODUCT_FAIL_CO,
  PRODUCT_EDIT_CO,
  PRODUCT_ADD_CO,
  GET_PRODUCTS_LISTING_CO,
  GET_PRODUCTS_LISTING_SUCCESS_CO,
  GET_PRODUCTS_LISTING_FAILED_CO,
  PRODUCT_EDIT_SUCCESS_CO,
  PRODUCT_EDIT_FAIL_CO,
  PRODUCT_UPDATE_CO,
  PRODUCT_UPDATE_SUCCESS_CO,
  PRODUCT_UPDATE_FAIL_CO,
  PRODUCT_IMAGE_DELETE_CO,
  PRODUCT_IMAGE_DELETE_SUCCESS_CO,
  PRODUCT_IMAGE_DELETE_FAIL_CO,
  PRODUCT_DELETE_CO,
  PRODUCT_DELETE_SUCCESS_CO,
  PRODUCT_DELETE_FAIL_CO,
  CHANGE_PRODUCT_STATUS_CO,
  CHANGE_PRODUCT_STATUS_SUCCESS_CO,
  CHANGE_PRODUCT_STATUS_FAIL_CO,
} from "./actionTypes"

const initialState = {
  error: null,
  loading: false,
  msg: null,
  product: [],
  productEdit: null,
  productUpdate: null,
  productImg: null,
  productDelete: null,
}

const coProduct = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_SUCCESS_CO:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: action?.payload?.msg,
        product: action.payload,
      }
      break
    case PRODUCT_ADD_CO:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        product: action.payload,
        productEdit: null,
        productImg: null,
      }
      break
    case PRODUCT_FAIL_CO:
      state = {
        ...state,
        loading: false,
        error: action.payload ? action.payload : null,
        msg: null,
      }
      break
    case PRODUCT_EDIT_CO:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        productEdit: action.payload,
      }
      break
    case PRODUCT_EDIT_SUCCESS_CO:
      if (state === null || state.product.length === 0) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          product: [...state.product, action.payload],
        }
      } else {
        state = {
          ...state,
          loading: false,
          product: state.product.map(i =>
            i.prod_id === action.payload.prod_id ? action.payload : i
          ),
        }
      }
      break
    case PRODUCT_EDIT_FAIL_CO:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case PRODUCT_UPDATE_CO:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        productUpdate: action.payload,
      }
      break
    case PRODUCT_UPDATE_SUCCESS_CO:
      state = { ...state, error: null, msg: action?.payload?.msg, loading: false }
      break
    case PRODUCT_UPDATE_FAIL_CO:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case GET_PRODUCTS_LISTING_CO:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        product: action.payload,
        productEdit: null,
        productImg: null,
      }
      break
    case GET_PRODUCTS_LISTING_SUCCESS_CO:
      state = { ...state, loading: false, error: null, msg: null, product: action.payload }
      break
    case GET_PRODUCTS_LISTING_FAILED_CO:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    case PRODUCT_IMAGE_DELETE_CO:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        productImg: action.payload,
      }
      break
    case PRODUCT_IMAGE_DELETE_SUCCESS_CO:
      const productindex = state.product.findIndex(
        product =>
          parseInt(product.prod_id) === parseInt(action.payload.prod_id)
      )

      let newImageAfterImage = []
      newImageAfterImage = []
      newImageAfterImage = [...state.product] //making a new array
      newImageAfterImage[productindex][action.payload.img_name] = null
      newImageAfterImage[productindex][action.payload.img_name + "_id"] = null
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        product: newImageAfterImage,
      }
      break

    case PRODUCT_IMAGE_DELETE_FAIL_CO:
      state = {
        ...state,
        loading: false,
        productImg: null,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    case PRODUCT_DELETE_CO:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        productDelete: action.payload,
      }
      break
    case PRODUCT_DELETE_SUCCESS_CO:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        product: state.product.filter(product => {
          return product.prod_id !== action.payload.id
        }),
      }
      break

    case PRODUCT_DELETE_FAIL_CO:
      return {
        ...state,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
        loading: false,
      }

    case CHANGE_PRODUCT_STATUS_CO:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break
    case CHANGE_PRODUCT_STATUS_SUCCESS_CO:
      const index = state.product.findIndex(
        product => product.prod_id === action.payload.id
      )
      let newProductAfterStatusChange = []
      newProductAfterStatusChange = [...state.product] //making a new array
      newProductAfterStatusChange[index].product_status =
        !newProductAfterStatusChange[index].product_status

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        product: newProductAfterStatusChange,
      }

      break

    case CHANGE_PRODUCT_STATUS_FAIL_CO:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    default:
      state = { ...state, loading: false, error: null }
      break
  }
  return state
}

export default coProduct
