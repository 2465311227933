import {
  BD_GET_CREATEDBY_LISTING,
  BD_GET_CREATEDBY_LISTING_SUCCESS,
  BD_GET_CREATEDBY_LISTING_FAILED,
  } from "./actionTypes"

  const initialState = {
    error: null,
    msg: null,
    loading: false,
    bdCreatedBy: null,
  }

  const bdCreatedBy = (state = initialState, action) => {
    switch (action.type) {
      case BD_GET_CREATEDBY_LISTING:
        state = {
          ...state,
          loading: true,
          error: null,
          msg: null,
          bdCreatedBy: action.payload,
        }
        break
      case BD_GET_CREATEDBY_LISTING_SUCCESS:
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          bdCreatedBy: action.payload,
        }
        break
      case BD_GET_CREATEDBY_LISTING_FAILED:
        state = { ...state, loading: false }
    }
    return state
  }
  
  export default bdCreatedBy;