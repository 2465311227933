import {
  CO_GET_MATERIALSTANDARD_LISTING,
  CO_GET_MATERIALSTANDARD_LISTING_SUCCESS,
  CO_GET_MATERIALSTANDARD_LISTING_FAILED,
  } from "./actionTypes"
  
  export const coMaterialStandardListing = () => {
    return {
      type: CO_GET_MATERIALSTANDARD_LISTING,
    }
  }
  
  export const coMaterialStandardListingSuccess = materialStandard => {
    return {
      type: CO_GET_MATERIALSTANDARD_LISTING_SUCCESS,
      payload: materialStandard,
    }
  }
  
  export const coMaterialStandardListingFail = error => {
    return {
      type: CO_GET_MATERIALSTANDARD_LISTING_FAILED,
      payload: error,
    }
  }
  
