import {
  GET_MATERIALSTANDARD_LISTING,
  GET_MATERIALSTANDARD_LISTING_SUCCESS,
  GET_MATERIALSTANDARD_LISTING_FAILED,
  ADD_MATERIAL,
  ADD_MATERIAL_SUCCESS,
  ADD_MATERIAL_FAIL,
  CHANGE_MATERIAL_STATUS,
  CHANGE_MATERIAL_STATUS_SUCCESS,
  CHANGE_MATERIAL_STATUS_FAIL,
  EDIT_MATERIAL,
  EDIT_MATERIAL_SUCCESS,
  EDIT_MATERIAL_FAIL,
  UPDATE_MATERIAL,
  UPDATE_MATERIAL_SUCCESS,
  UPDATE_MATERIAL_FAIL,
  FETCH_MATERIAL_BY_SECTION,
  FETCH_MATERIAL_BY_SECTION_SUCCESS,
  FETCH_MATERIAL_BY_SECTION_FAIL,
  MATERIAL_STANDARD_POSITION,
  MATERIAL_STANDARD_POSITION_SUCCESS,
  MATERIAL_STANDARD_POSITION_FAIL,
} from "./actionTypes"

export const materialStandardListing = () => {
  return {
    type: GET_MATERIALSTANDARD_LISTING,
  }
}

export const materialStandardListingSuccess = materialStandard => {
  return {
    type: GET_MATERIALSTANDARD_LISTING_SUCCESS,
    payload: materialStandard,
  }
}

export const materialStandardListingFail = error => {
  return {
    type: GET_MATERIALSTANDARD_LISTING_FAILED,
    payload: error,
  }
}

export const addMaterial = (materialStandard, history) => {
  return {
    type: ADD_MATERIAL,
    payload: { materialStandard, history },
  }
}

export const addMaterialSuccess = materialStandard => {
  return {
    type: ADD_MATERIAL_SUCCESS,
    payload: materialStandard,
  }
}

export const addMaterialFail = error => {
  return {
    type: ADD_MATERIAL_FAIL,
    payload: error,
  }
}

export const changeMaterialStatus = id => ({
  type: CHANGE_MATERIAL_STATUS,
  payload: { id: id },
})

export const changeMaterialStatusSuccess = materialStandard => ({
  type: CHANGE_MATERIAL_STATUS_SUCCESS,
  payload: materialStandard,
})

export const changeMaterialStatusFail = error => ({
  type: CHANGE_MATERIAL_STATUS_FAIL,
  payload: error,
})

export const editMaterial = id => {
  return {
    type: EDIT_MATERIAL,
    payload: { id },
  }
}
export const editMaterialSuccess = materialStandard => {
  return {
    type: EDIT_MATERIAL_SUCCESS,
    payload: materialStandard,
  }
}
export const editMaterialFail = error => {
  return {
    type: EDIT_MATERIAL_FAIL,
    payload: error,
  }
}

export const updateMaterial = (materialStandardUpdate, history) => {
  return {
    type: UPDATE_MATERIAL,
    payload: { materialStandardUpdate, history },
  }
}

export const updateMaterialSuccess = materialStandardUpdate => {
  return {
    type: UPDATE_MATERIAL_SUCCESS,
    payload: materialStandardUpdate,
  }
}

export const updateMaterialFail = error => {
  return {
    type: UPDATE_MATERIAL_FAIL,
    payload: error,
  }
}

export const fetchMaterialListingBySection = data => {
  return {
    type: FETCH_MATERIAL_BY_SECTION,
    payload: { data },
  }
}

export const fetchMaterialListingBySectionSuccess = materialStandard => {
  return {
    type: FETCH_MATERIAL_BY_SECTION_SUCCESS,
    payload: materialStandard,
  }
}

export const fetchMaterialListingBySectionFail = error => {
  return {
    type: FETCH_MATERIAL_BY_SECTION_FAIL,
    payload: error,
  }
}

export const updateMaterialStandardPosition = position => ({
  type: MATERIAL_STANDARD_POSITION,
  payload: { position },
})

export const updateMaterialStandardPositionSuccess = materialStandard => ({
  type: MATERIAL_STANDARD_POSITION_SUCCESS,
  payload: materialStandard,
})

export const updateMaterialStandardPositionFail = error => ({
  type: MATERIAL_STANDARD_POSITION_FAIL,
  payload: error,
})
