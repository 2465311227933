import {
  CO_GET_PRODUCT_NUMBER_LISTING,
  CO_GET_PRODUCT_NUMBER_LISTING_SUCCESS,
  CO_GET_PRODUCT_NUMBER_LISTING_FAILED,
  } from "./actionTypes"

  export const coProductNumberListing = () => {
    return {
      type: CO_GET_PRODUCT_NUMBER_LISTING,
    }
  }
  
  export const coProductNumberListingSuccess = coProductNumber => {
    return {
      type: CO_GET_PRODUCT_NUMBER_LISTING_SUCCESS,
      payload: coProductNumber,
    }
  }
  
  export const coProductNumberListingFail = error => {
    return {
      type: CO_GET_PRODUCT_NUMBER_LISTING_FAILED,
      payload: error,
    }
  }