import React, { Component, useEffect } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"

//Import Images
import avatar1 from "../../assets/images/default_user.png"

import { getTotalCountOnDashboard } from "../../store/actions"

const currentUser = JSON.parse(sessionStorage.getItem("authUser"))
function CardUser(props) {
  const { dashboardCounts } = props
  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={
                          currentUser && currentUser.avatar
                            ? currentUser.avatar
                            : avatar1
                        }
                        alt="Avatar"
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-2">Welcome</p>
                        <h5 className="mb-1">
                          {currentUser && currentUser.name
                            ? currentUser.name
                            : ""}
                        </h5>
                        <p className="mb-0">
                          {currentUser && currentUser.user_role
                            ? currentUser.user_role
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="6" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            <b>Your offers</b>
                          </p>
                          <h5 className="mb-0">
                            {dashboardCounts?.product_offer_count
                              ? dashboardCounts.product_offer_count
                              : 0}
                          </h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            <b>Your collections</b>
                          </p>
                          <h5 className="mb-0">
                            {dashboardCounts?.collection_count
                              ? dashboardCounts.collection_count
                              : 0}
                          </h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            <b>Your downloads</b>
                          </p>
                          <h5 className="mb-0">
                            {dashboardCounts?.download_count
                              ? dashboardCounts.download_count
                              : 0}
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
