import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  PROFILE_EDIT,
  PROFILE_EDIT_SUCCESS,
  PROFILE_EDIT_FAIL,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from "./actionTypes"

const initialState = {
  msg: null,
  error: null,
  loading: null,
  success: "",
  editProfile: "",
  updateProfile: "",
  changePassword: "",
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state, msg: null, error: null }
      break
    case PROFILE_SUCCESS:
      state = { ...state, msg: null, error: null, success: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, msg: null, error: action.payload }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null }
      break
    case PROFILE_EDIT:
      state = {
        ...state,
        msg: null,
        loading: true,
        error: null,
      }
      break
    case PROFILE_EDIT_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        editProfile: action.payload,
      }
      break
    case PROFILE_EDIT_FAIL:
      state = {
        ...state,
        msg: null,
        loading: false,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case UPDATE_PROFILE:
      state = {
        ...state,
        msg: null,
        error: null,
        loading: true,
        updateProfile: action.payload,
      }
      return state
      break
    case UPDATE_PROFILE_SUCCESS:
      state = {
        ...state,
        error: null,
        loading: false,
        msg: action.payload?.msg,
      }
      return state
      break
    case UPDATE_PROFILE_FAIL:
      state = {
        ...state,
        msg: null,
        loading: false,
        error: action.payload ? action.payload : null,
      }
      return state
      break
    case CHANGE_PASSWORD:
      state = {
        ...state,
        msg: null,
        error: null,
        loading: true,
      }
      return state
      break
    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        msg: null,
        error: null,
        loading: false,
        msg: action.payload?.msg
          ? action.payload?.msg
          : "Something went wrong. Please try again",
      }
      return state
      break
    case CHANGE_PASSWORD_FAIL:
      state = {
        ...state,
        msg: null,
        loading: false,
        error: action.payload ? action.payload : null,
      }
      return state
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
