import {
  BD_OFFER_ADD,
  BD_OFFER_SUCCESS,
  BD_OFFER_FAIL,
  BD_GET_OFFERS_LISTING,
  BD_GET_OFFERS_LISTING_SUCCESS,
  BD_GET_OFFERS_LISTING_FAILED,
  BD_OFFER_EDIT,
  BD_OFFER_EDIT_SUCCESS,
  BD_OFFER_EDIT_FAIL,
  BD_OFFER_UPDATE,
  BD_OFFER_UPDATE_SUCCESS,
  BD_OFFER_UPDATE_FAIL,
  BD_CHANGE_OFFER_STATUS,
  BD_CHANGE_OFFER_STATUS_SUCCESS,
  BD_CHANGE_OFFER_STATUS_FAIL,
  BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
  BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS,
  BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL,
  BD_RESET_OFFER,
  BD_RESET_OFFER_SUCCESS,
  BD_RESET_OFFER_FAIL
} from "./actionTypes"

export const bdAddNewOffer = (offer, history) => {
  return {
    type: BD_OFFER_ADD,
    payload: { offer, history },
  }
}

export const bdOfferSuccess = offer => {
  return {
    type: BD_OFFER_SUCCESS,
    payload: offer,
  }
}

export const bdOfferFail = error => {
  return {
    type: BD_OFFER_FAIL,
    payload: error,
  }
}

export const bdOffersListing = history => {
  return {
    type: BD_GET_OFFERS_LISTING,
  }
}

export const bdOffersListingSuccess = offer => {
  return {
    type: BD_GET_OFFERS_LISTING_SUCCESS,
    payload: offer,
  }
}

export const bdOffersListingFail = error => {
  return {
    type: BD_GET_OFFERS_LISTING_FAILED,
    payload: error,
  }
}

export const bdOfferEdit = offer_id => {
  return {
    type: BD_OFFER_EDIT,
    payload: { id: offer_id },
  }
}

export const bdEditOfferSuccess = offer => {
  return {
    type: BD_OFFER_EDIT_SUCCESS,
    payload: offer,
  }
}

export const bdEditOfferFail = error => {
  return {
    type: BD_OFFER_EDIT_FAIL,
    payload: error,
  }
}

export const bdUpdateOffer = (offer, history) => {
  return {
    type: BD_OFFER_UPDATE,
    payload: { offer, history },
  }
}

export const bdUpdateOfferSuccess = offer => {
  return {
    type: BD_OFFER_UPDATE_SUCCESS,
    payload: offer,
  }
}

export const bdUpdateOfferFail = error => {
  return {
    type: BD_OFFER_UPDATE_FAIL,
    payload: error,
  }
}

export const bdChangeOfferStatus = offerId => ({
  type: BD_CHANGE_OFFER_STATUS,
  payload: { id: offerId },
})

export const bdChangeOfferStatusSuccess = offer => ({
  type: BD_CHANGE_OFFER_STATUS_SUCCESS,
  payload: offer,
})

export const bdChangeOfferStatusFail = error => ({
  type: BD_CHANGE_OFFER_STATUS_FAIL,
  payload: error,
})

export const bdSearchItemInNewOfferPage = search => {
  return {
    type: BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
    payload: {search : search},
  }
}

export const bdSearchItemInNewOfferPageSuccess = search => {
  return {
    type: BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS,
    payload: search,
  }
}

export const bdSearchItemInNewOfferPageFail = error => {
  return {
    type: BD_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL,
    payload: error,
  }
}

export const bdClearOfferResult = () => {
  return {
    type: BD_RESET_OFFER,
  }
}

export const bdClearOfferResultSuccess = () => {
  return {
    type: BD_RESET_OFFER_SUCCESS,
  }
}

export const bdClearOfferResultError = () => {
  return {
    type: BD_RESET_OFFER_FAIL,
  }
}
