import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { useHistory } from "react-router-dom"
// Login Redux States
import {
  PRODUCT_EDIT,
  PRODUCT_ADD,
  GET_PRODUCTS_LISTING,
  PRODUCT_UPDATE,
  PRODUCT_IMAGE_DELETE,
  PRODUCT_DELETE,
  CHANGE_PRODUCT_STATUS,
} from "./actionTypes"
import {
  productSuccess,
  productFail,
  productsListingSuccess,
  productsListingFail,
  editProductSuccess,
  editProductFail,
  updateProductSuccess,
  updateProductFail,
  deleteProductImageSuccess,
  deleteProductImageFail,
  deleteProductSuccess,
  deleteProductFail,
  changeProductStatusFail,
  changeProductStatusSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  addProduct,
  productsListingApi,
  editProductApi,
  updateProductApi,
  deleteProductImageApi,
  deleteProductApi,
  changeProductStatusApi,
} from "../../../helpers/fakebackend_helper"

function* addNewProduct({ payload: { product, history } }) {
  try {
    const response = yield call(addProduct, product)
    if (response && response.status) {
      yield put(productSuccess(response))
      setTimeout(() => {
        history.push("/products/list")
      }, 5000)
    } else {
      throw response.msg ? response.msg : "Failed to add product"
    }
  } catch (error) {
    yield put(productFail(error))
  }
}

function* onUpdateProduct({ payload: { product, history } }) {
  try {
    const response = yield call(updateProductApi, product)
    if (response && response.status) {
      yield put(updateProductSuccess(response))
      setTimeout(() => {
        history.push("/products/list")
      }, 5000)
    } else {
      throw response.msg ? response.msg : "Failed to update product"
    }
  } catch (error) {
    yield put(updateProductFail(error))
  }
}

function* getProductDetailsById({ payload: { productId } }) {
  try {
    const response = yield call(editProductApi, productId)
    if (response && response.status) {
      yield put(editProductSuccess(response.results.prod_dtls))
    } else {
      throw response.msg ? response.msg : "Failed to edit product"
    }
  } catch (error) {
    yield put(editProductFail(error))
  }
}

function* getProductListing() {
  try {
    const response = yield call(productsListingApi)
    if (!response.results.product_dtls) {
      yield put(productsListingFail(error))
    }
    yield put(productsListingSuccess(response.results.product_dtls))
  } catch (error) {
    yield put(productsListingFail(error))
  }
}

function* onDeleteProductImage({ payload: { productImgId, history } }) {
  try {
    const response = yield call(deleteProductImageApi, productImgId)
    if (response.status) {
      yield put(deleteProductImageSuccess(productImgId))
    } else {
      throw response.msg ? response.msg : "Failed to delete product image"
    }
  } catch (error) {
    yield put(deleteProductImageFail(error))
  }
}

function* onDeleteproduct({ payload: { id } }) {
  try {
    const response = yield call(deleteProductApi, { prod_id: id })
    if (response.status) {
      response.id = id
      yield put(deleteProductSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to delete product"
    }
  } catch (error) {
    yield put(deleteProductFail(error))
  }
}

function* onProductStatusChange({ payload: { id } }) {
  try {
    const response = yield call(changeProductStatusApi, { prod_id: id })
    if (response.status) {
      response.id = id
      yield put(changeProductStatusSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to change product status"
    }
  } catch (error) {
    yield put(changeProductStatusFail(error))
  }
}

function* productSaga() {
  yield takeEvery(PRODUCT_ADD, addNewProduct)
  yield takeEvery(GET_PRODUCTS_LISTING, getProductListing)
  yield takeEvery(PRODUCT_EDIT, getProductDetailsById)
  yield takeEvery(PRODUCT_UPDATE, onUpdateProduct)
  yield takeEvery(PRODUCT_IMAGE_DELETE, onDeleteProductImage)
  yield takeEvery(PRODUCT_DELETE, onDeleteproduct)
  yield takeEvery(CHANGE_PRODUCT_STATUS, onProductStatusChange)
}
export default productSaga
