import {
  QA_GET_USERLISTACCORDINGROLE_LISTING,
  QA_GET_USERLISTACCORDINGROLE_LISTING_SUCCESS,
  QA_GET_USERLISTACCORDINGROLE_LISTING_FAILED,
} from "./actionTypes"

export const qaUserListAccordingRoleListing = (userType, history) => {
  return {
    type: QA_GET_USERLISTACCORDINGROLE_LISTING,
    payload: { userType, history },
  }
}

export const qaUserListAccordingRoleSuccess = userListAccordingRole => {
  return {
    type: QA_GET_USERLISTACCORDINGROLE_LISTING_SUCCESS,
    payload: userListAccordingRole,
  }
}

export const qaUserListAccordingRoleFail = error => {
  return {
    type: QA_GET_USERLISTACCORDINGROLE_LISTING_FAILED,
    payload: error,
  }
}
