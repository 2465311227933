import {
  GET_CONSTRUCTON_LISTING,
  GET_CONSTRUCTON_LISTING_SUCCESS,
  GET_CONSTRUCTON_LISTING_FAILED,
  ADD_CONSTRUCTON,
  ADD_CONSTRUCTON_SUCCESS,
  ADD_CONSTRUCTON_FAIL,
  CHANGE_CONSTRUCTON_STATUS,
  CHANGE_CONSTRUCTON_STATUS_SUCCESS,
  CHANGE_CONSTRUCTON_STATUS_FAIL,
  EDIT_CONSTRUCTON,
  EDIT_CONSTRUCTON_SUCCESS,
  EDIT_CONSTRUCTON_FAIL,
  UPDATE_CONSTRUCTON,
  UPDATE_CONSTRUCTON_SUCCESS,
  UPDATE_CONSTRUCTON_FAIL,
  FETCH_CONSTRUCTON_BY_SECTION,
  FETCH_CONSTRUCTON_BY_SECTION_SUCCESS,
  FETCH_CONSTRUCTON_BY_SECTION_FAIL,
  UPDATE_CONSTRUCTION_POSITION,
  UPDATE_CONSTRUCTION_POSITION_SUCCESS,
  UPDATE_CONSTRUCTION_POSITION_FAIL,
} from "./actionTypes"

const initialState = {
  error: null,
  msg: null,
  loading: false,
  construction: null,
  positionUpdate: null,
}

const construction = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONSTRUCTON_LISTING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
        construction: action.payload,
      }
      break
    case GET_CONSTRUCTON_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        positionUpdate: null,
        construction: action.payload,
      }
      break
    case GET_CONSTRUCTON_LISTING_FAILED:
      state = { ...state, positionUpdate: null, loading: false }
      break
    default:
      state = { ...state }
      break
    case ADD_CONSTRUCTON:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        construction: action.payload,
      }
      break
    case ADD_CONSTRUCTON_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        construction: action.payload,
      }
      break
    case ADD_CONSTRUCTON_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload ? action.payload : null,
      }
      break
    case CHANGE_CONSTRUCTON_STATUS:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break
    case CHANGE_CONSTRUCTON_STATUS_SUCCESS:
      const index = state.construction.findIndex(
        cobstructs => cobstructs.construction_id === action.payload.id
      )
      let newConstructionAfterStatusChange = []
      newConstructionAfterStatusChange = [...state.construction] //making a new array
      newConstructionAfterStatusChange[index].status =
        !newConstructionAfterStatusChange[index].status

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        construction: newConstructionAfterStatusChange,
      }
      break

    case CHANGE_CONSTRUCTON_STATUS_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case EDIT_CONSTRUCTON:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        constructionEdit: action.payload,
      }
      break
    case EDIT_CONSTRUCTON_SUCCESS:
      if (state === null || state.construction.length === 0) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          construction: [...state.construction, action.payload],
        }
      } else {
        state = {
          ...state,
          loading: false,
          construction: state.construction.map(i =>
            i.id === action.payload.id ? action.payload : i
          ),
        }
      }
      break
    case EDIT_CONSTRUCTON_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case UPDATE_CONSTRUCTON:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        constructionUpdate: action.payload,
      }
      break
    case UPDATE_CONSTRUCTON_SUCCESS:
      state = { ...state, error: null, msg: null, loading: false }
      break
    case UPDATE_CONSTRUCTON_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case FETCH_CONSTRUCTON_BY_SECTION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
        constructionList: action.payload,
      }
      break
    case FETCH_CONSTRUCTON_BY_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        positionUpdate: null,
        construction: action.payload,
      }
      break
    case FETCH_CONSTRUCTON_BY_SECTION_FAIL:
      state = { ...state, positionUpdate: null, loading: false }
      break

      case UPDATE_CONSTRUCTION_POSITION:
        state = {
          ...state,
          loading: true,
          error: null,
          msg: null,
          positionUpdate: null,
        }
        break
  
      case UPDATE_CONSTRUCTION_POSITION_SUCCESS:
        state = {
          ...state,
          loading: false,
          error: null,
          msg: action.payload.msg != "" ? action.payload.msg : "",
          positionUpdate: true,
        }
        break
      case UPDATE_CONSTRUCTION_POSITION_FAIL:
        state = {
          ...state,
          loading: false,
          error: action.payload.msg != "" ? action.payload.msg : "",
          msg: null,
          positionUpdate: null,
        }
        break
  }
  return state
}

export default construction
