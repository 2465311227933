import {
  BD_GET_PACKINGSTANDARD_LISTING,
  BD_GET_PACKINGSTANDARD_LISTING_SUCCESS,
  BD_GET_PACKINGSTANDARD_LISTING_FAILED,
  } from "./actionTypes"
  
  export const bdPackingStandardListing = () => {
    return {
      type: BD_GET_PACKINGSTANDARD_LISTING,
    }
  }
  
  export const bdPackingStandardListingSuccess = bdPackingStandard => {
    return {
      type: BD_GET_PACKINGSTANDARD_LISTING_SUCCESS,
      payload: bdPackingStandard,
    }
  }
  
  export const bdPackingStandardListingFail = error => {
    return {
      type: BD_GET_PACKINGSTANDARD_LISTING_FAILED,
      payload: error,
    }
  }
  
