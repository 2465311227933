import {
  CO_GET_PRODUCT_NUMBER_LISTING,
  CO_GET_PRODUCT_NUMBER_LISTING_SUCCESS,
  CO_GET_PRODUCT_NUMBER_LISTING_FAILED,
} from "./actionTypes"

const initialState = {
  error: null,
  msg: null,
  loading: false,
  coProductNumber: null,
}

const coProductNumber = (state = initialState, action) => {
  switch (action.type) {
    case CO_GET_PRODUCT_NUMBER_LISTING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        coProductNumber: action.payload,
      }
      break
    case CO_GET_PRODUCT_NUMBER_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        coProductNumber: action.payload,
      }
      break
    case CO_GET_PRODUCT_NUMBER_LISTING_FAILED:
      state = { ...state, loading: false }
  }
  return state
}

export default coProductNumber;