import React, { useState } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { useSelector, useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// actions
import { addHeaveyColli } from "../../../store/actions"
import { useEffect } from "react"

const AddHeavey = props => {
  const dispatch = useDispatch()
  document.title = "Add Heavy"

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      colli_name: "",
    },
    validationSchema: Yup.object({
      colli_name: Yup.string().required("Please Enter Heavey Colli"),
    }),
    onSubmit: values => {
      dispatch(addHeaveyColli(values, props.history))
    },
  })

  const heavey = useSelector(state => state.heaveyColli)
  const { error } = useSelector(state => ({
    error: state.heaveyColli.error,
  }))

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Add Heavey Colli" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {error ? <ToastContainer /> : ""}
                  <Form
                    id="formHeavy"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="heavey">
                            Heavey Colli
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            name="colli_name"
                            className={
                              validation.touched.colli_name &&
                              validation.errors.colli_name
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            id="heavey"
                            placeholder="Enter Heavey Colli"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.colli_name || ""}
                          />
                          {validation.touched.colli_name &&
                          validation.errors.colli_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.colli_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <div>
                      {heavey && heavey.loading ? (
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          disabled
                        >
                          Adding ...
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary w-md">
                          Add
                        </button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default AddHeavey
