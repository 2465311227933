import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { useHistory } from "react-router-dom"
// offer Redux States
import {
  QA_GET_OFFERS_LISTING,
  QA_OFFER_EDIT,
  QA_OFFER_UPDATE,
  QA_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
  QA_RESET_OFFER
} from "./actionTypes"
import {
  qaOffersListingSuccess,
  qaOffersListingFail,
  qaEditOfferSuccess,
  qaEditOfferFail,
  qaUpdateOfferSuccess,
  qaUpdateOfferFail,
  qaSearchItemInNewOfferPageSuccess,
  qaSearchItemInNewOfferPageFail,
  qaClearOfferResultSuccess,
  qaClearOfferResultError
} from "./actions"

import {
  qaOffersListingApi,
  qaEditOfferApi,
  qaUpdateOfferApi,
  qaSearchItemInNewOfferPageApi
} from "../../../helpers/fakebackend_helper"

function* qaGetOfferListing() {
  try {
    const response = yield call(qaOffersListingApi)
    if (
      response.results === undefined ||
      response.results.prod_offers.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(qaOffersListingSuccess(response.results.prod_offers))
  } catch (error) {
    yield put(qaOffersListingFail(error))
  }
}

function* qaGetOfferDetailsById({ payload: { id } }) {
  try {
    const response = yield call(qaEditOfferApi, id)
    if (response && response.status) {
      response.id = id
      yield put(qaEditOfferSuccess(response.results.offer_details))
    } else {
      throw response.msg ? response.msg : "Failed to fetch offer details"
    }
  } catch (error) {
    yield put(qaEditOfferFail(error))
  }
}

function* qaOnUpdateOffer({ payload: { offer, history } }) {
  try {
    const response = yield call(qaUpdateOfferApi, offer)
    if (response && response.status) {
      yield put(qaUpdateOfferSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update offer"
    }
  } catch (error) {
    yield put(qaUpdateOfferFail(error))
  }
}

function* qaSearchAtNewOfferPage({ payload: { search } }) {
  try {
    const response = yield call(qaSearchItemInNewOfferPageApi, search)

    if (response && response.status && response?.results?.prod_offers) {
      yield put(qaSearchItemInNewOfferPageSuccess(response.results.prod_offers))
    } else {
      throw response.msg ? response.msg : "No Record found"
    }
  } catch (error) {
    yield put(qaSearchItemInNewOfferPageFail(error))
  }
}

function* qaClearOfferResults() {
  try {
    yield put(qaClearOfferResultSuccess())
  } catch (error) {
    yield put(qaClearOfferResultError(error))
  }
}

function* qaofferSaga() {
  yield takeEvery(QA_GET_OFFERS_LISTING, qaGetOfferListing)
  yield takeEvery(QA_OFFER_EDIT, qaGetOfferDetailsById)
  yield takeEvery(QA_OFFER_UPDATE, qaOnUpdateOffer)
  yield takeEvery(QA_SEARCH_ITEM_IN_NEW_OFFER_PAGE, qaSearchAtNewOfferPage)
  yield takeEvery(QA_RESET_OFFER, qaClearOfferResults)
}
export default qaofferSaga
