import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

//Include Both Helper File with needed methods
import { postForgotPassword } from "../../../helpers/fakebackend_helper"

function* forgetPasswordUser({ payload: { user, history } }) {
  try {
    const response = yield call(postForgotPassword, user)
    const msg =
      response && response.msg
        ? response.msg
        : "Something went wrong. Please try again later"
    if (response && response.status) {
      yield put(userForgetPasswordSuccess(response.msg))
    } else {
      throw msg
    }
  } catch (error) {
    const msg =
      error && error.msg
        ? error.msg
        : "Something went wrong. Please try again later"
    yield put(userForgetPasswordError(error))
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetPasswordUser)
}

export default forgetPasswordSaga
