export const GET_PACKINGSTANDARD_LISTING = "GET_PACKINGSTANDARD_LISTING"
export const GET_PACKINGSTANDARD_LISTING_SUCCESS =
  "GET_PACKINGSTANDARD_LISTING_SUCCESS"
export const GET_PACKINGSTANDARD_LISTING_FAILED =
  "GET_PACKINGSTANDARD_LISTING_FAILED"

export const FETCH_PACKING_STANDARD_BY_SECTION =
  "FETCH_PACKING_STANDARD_BY_SECTION"
export const FETCH_PACKING_STANDARD_BY_SECTION_SUCCESS =
  "FETCH_PACKING_STANDARD_BY_SECTION_SUCCESS"
export const FETCH_PACKING_STANDARD_BY_SECTION_FAIL =
  "FETCH_PACKING_STANDARD_BY_SECTION_FAIL"

export const ADD_PACKING_STANDARD = "ADD_PACKING_STANDARD"
export const ADD_PACKING_STANDARD_SUCCESS = "ADD_PACKING_STANDARD"
export const ADD_PACKING_STANDARD_FAIL = "ADD_PACKING_STANDARD_FAIL"

export const EDIT_PACKING_STANDARD = "EDIT_PACKING_STANDARD"
export const EDIT_PACKING_STANDARD_SUCCESS = "EDIT_PACKING_STANDARD_SUCCESS"
export const EDIT_PACKING_STANDARD_FAIL = "EDIT_PACKING_STANDARD_FAIL"

export const UPDATE_PACKING_STANDARD = "UPDATE_PACKING_STANDARD"
export const UPDATE_PACKING_STANDARD_SUCCESS = "UPDATE_PACKING_STANDARD_SUCCESS"
export const UPDATE_PACKING_STANDARD_FAIL = "UPDATE_PACKING_STANDARD_FAIL"

export const PACKING_STANDARD_ACTIVE_INACTIVE =
  "PACKING_STANDARD_ACTIVE_INACTIVE"
export const PACKING_STANDARD_ACTIVE_INACTIVE_SUCCESS =
  "PACKING_STANDARD_ACTIVE_INACTIVE_SUCCESS"
export const PACKING_STANDARD_ACTIVE_INACTIVE_FAIL =
  "PACKING_STANDARDA_ACTIVE_INACTIVE_FAIL"

export const PACKING_STANDARD_POSITION = "PACKING_STANDARD_POSITION"
export const PACKING_STANDARD_POSITION_SUCCESS =
  "PACKING_STANDARD_POSITION_SUCCESS"
export const PACKING_STANDARD_POSITION_FAIL = "PACKING_STANDARD_POSITION_FAIL"
