import React from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import classnames from "classnames"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// actions
import {
  coProductCategoryListing,
  // coQualityRatingListing,
  coConstructionListing,
  coAddNewProduct,
} from "../../../store/actions"
import { useEffect, useState } from "react"

const CoAddProduct = props => {
  const dispatch = useDispatch()
  document.title = "Add Product"

  const [coProductCategories, setCoProductCategories] = useState([])
  // const [coQualityRatings, setCoQualityRatings] = useState([])
  const [coProductCategoryDefault, setCoProductCategoryDefault] = useState([])
  // const [coQualityRatingDefault, setCoQualityRatingDefault] = useState([])
  const [coConstructions, setCoConstructions] = useState([])
  const [coConstructionDefault, setcoConstructionDefault] = useState([])

  useEffect(() => {
    dispatch(coProductCategoryListing())
    // dispatch(coQualityRatingListing())
    dispatch(coConstructionListing())
  }, [dispatch])

  const coProductCategoryData = useSelector(
    state => state.coProductCategory.productCategory
  )
  // const coQualityRatingData = useSelector(
  //   state => state.coQualityRating.qualityRating
  // )
  const coConstructionData = useSelector(
    state => state.coConstruction.construction
  )

  useEffect(() => {
    setCoProductCategories(coProductCategoryData)
    // setCoQualityRatings(coQualityRatingData)
    setCoConstructions(coConstructionData)
  }, [coProductCategoryData, coConstructionData])

  useEffect(() => {
    setCoProductCategories(coProductCategoryData)
    // setCoQualityRatings(coQualityRatingData)
    setCoConstructions(coConstructionData)
    if (coProductCategoryData && coProductCategoryData.length > 0) {
      setCoProductCategoryDefault(
        coProductCategoryData.filter((productCategory, pc) => {
          return productCategory.is_default == 1
        })
      )
    }
    // if (coQualityRatingData && coQualityRatingData.length > 0) {
    //   setCoQualityRatingDefault(
    //     coQualityRatingData.filter((quality, qc) => {
    //       return quality.is_default == 1
    //     })
    //   )
    // }
    if (coConstructionData && coConstructionData.length > 0) {
      setcoConstructionDefault(
        coConstructionData.filter((construc, con) => {
          return construc.is_default == 1
        })
      )
      console.log('coConstructionDefault', coConstructionDefault);
    }
  }, [coProductCategoryData, coConstructionData])

  const [files, setFiles] = React.useState({
    img1: null,
    img2: null,
    img3: null,
    img4: null,
    img5: null,
  })
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      prod_name: "",
      prod_cat: coProductCategoryDefault && coProductCategoryDefault.length > 0
        ? coProductCategoryDefault[0]["id"]
        : "",
      construction:
        coConstructionDefault && coConstructionDefault.length > 0
          ? coConstructionDefault[0]["construction_id"]
          : "",
      range: "",
      product: "",
      version: "",
      product_folder: "",
      description: "",
      length: "",
      depth: "",
      height: "",
      img1: "",
      img2: "",
      img3: "",
      img4: "",
      img5: "",
    },
    validationSchema: Yup.object({
      range: Yup.string()
        .required("Please fill up this field. Only numbers are allowed")
        .matches(/^[0-9]{1,6}$/, "Maximum six digits are allowed"),

      product: Yup.string()
        .required("Please fill up this field. Only numbers are allowed")
        .matches(/^[0-9]{1,2}$/, "Maximum two digits are allowed"),

      version: Yup.string()
        .required(
          "Please fill up this field. Only allow this format - V1 upto V99"
        )
        .matches(/^[V]{1}[0-9]{1,2}$/i, "Only allow this format - V1 upto V99"),

      prod_cat: Yup.string().required("Please enter category"),

      construction: Yup.string().required("Please enter construction type"),

      length: Yup.number()
        .required("Please enter length")
        .min(1, "This value should be between 1 and 5000")
        .max(5000, "This value should be between 1 and 5000"),

      depth: Yup.number()
        .required("Please enter depth")
        .min(1, "This value should be between 1 and 5000")
        .max(5000, "This value should be between 1 and 5000"),

      height: Yup.number()
        .required("Please enter height")
        .min(1, "This value should be between 1 and 5000")
        .max(5000, "This value should be between 1 and 5000"),
    }),

    onSubmit: () => {
      let formData = new FormData(document.getElementById("productForm")) // this is for file uploading
      dispatch(coAddNewProduct(formData, props.history))
    },
  })

  const products = useSelector(state => state.coProduct)
  const { error, msg } = useSelector(state => ({
    error: state.coProduct.error,
    msg: state.coProduct.msg ? state.coProduct.msg : "",
  }))

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Add Product" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {msg || error ? (
                    <ToastContainer className="Toastify__close-button" />
                  ) : (
                    ""
                  )}
                  <Form
                    id="productForm"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="product-name">Product Name</Label>
                          <Input
                            name="prod_name"
                            type="text"
                            className="form-control"
                            id="product-name"
                            placeholder="Enter product name"
                            autoComplete="off"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.prod_name || ""}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label htmlFor="fixed">Fixed</Label>
                          <Input
                            name="prod_no"
                            disabled
                            className="form-control"
                            id="fixed"
                            placeholder="SBN"
                            autoComplete="off"
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label htmlFor="range">
                            Range <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="range"
                            type="text"
                            className="form-control text-number"
                            id="range"
                            placeholder="00100"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.range || ""}
                            invalid={
                              validation.touched.range &&
                                validation.errors.range
                                ? true
                                : false
                            }
                          />
                          {validation.touched.range &&
                            validation.errors.range ? (
                            <FormFeedback type="invalid">
                              {validation.errors.range}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label htmlFor="product">
                            product <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="product"
                            type="text"
                            className="form-control"
                            id="product"
                            placeholder="Ex. 01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.product || ""}
                            invalid={
                              validation.touched.product &&
                                validation.errors.product
                                ? true
                                : false
                            }
                          />
                          {validation.touched.product &&
                            validation.errors.product ? (
                            <FormFeedback type="invalid">
                              {validation.errors.product}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label htmlFor="version">
                            Version <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="version"
                            type="text"
                            className="form-control text-uppercase"
                            id="version"
                            placeholder="V1 - V99"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.version || ""}
                            invalid={
                              validation.touched.version &&
                                validation.errors.version
                                ? true
                                : false
                            }
                          />
                          {validation.touched.version &&
                            validation.errors.version ? (
                            <FormFeedback type="invalid">
                              {validation.errors.version}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-product-category">
                            Product Category{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <select
                            name="prod_cat"
                            id="formrow-product-category"
                            className={
                              validation.touched.prod_cat &&
                                validation.errors.prod_cat
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.prod_cat || ""}
                          >
                            <option value="">Choose...</option>
                            {coProductCategories &&
                              coProductCategories.map((productCategory, pc) => (
                                <option
                                  key={"pc" + pc}
                                  value={productCategory.id}
                                >
                                  {productCategory.prod_cat_name}
                                </option>
                              ))}
                          </select>
                          {validation.touched.prod_cat &&
                            validation.errors.prod_cat ? (
                            <FormFeedback type="invalid">
                              {validation.errors.prod_cat}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      {/* <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-quality-rating">
                            Quality Rating{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <select
                            name="quality_rating_id"
                            id="formrow-quality-rating"
                            className={
                              validation.touched.quality_rating_id &&
                                validation.errors.quality_rating_id
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.quality_rating_id || ""}
                          >
                            <option value="">Choose...</option>
                            {coQualityRatings &&
                              coQualityRatings.map((qualityRating, qr) => (
                                <option
                                  key={"qr" + qr}
                                  value={qualityRating.id}
                                >
                                  {qualityRating.quality_rating}
                                </option>
                              ))}
                          </select>
                          {validation.touched.quality_rating_id &&
                            validation.errors.quality_rating_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.quality_rating_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col> */}
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-product-folder">
                            Product Folder
                          </Label>
                          <Input
                            name="product_folder"
                            type="text"
                            className="form-control"
                            id="formrow-product-folder"
                            placeholder="Enter link here"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.product_folder || ""}
                          />
                        </div>
                      </Col>
                      {/* <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-yearlyForecast">
                            Yearly Forecast{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="yearly_forecast"
                            type="text"
                            className="form-control"
                            id="formrow-yearlyForecast"
                            placeholder="Ex. 2014"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.yearly_forecast || ""}
                            invalid={
                              validation.touched.yearly_forecast &&
                                validation.errors.yearly_forecast
                                ? true
                                : false
                            }
                          />
                          {validation.touched.yearly_forecast &&
                            validation.errors.yearly_forecast ? (
                            <FormFeedback type="invalid">
                              {validation.errors.yearly_forecast}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col> */}
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-construction">
                            Construction <span className="text-danger">*</span>
                          </Label>
                          <select
                            name="construction"
                            id="formrow-construction"
                            className={
                              validation.touched.construction &&
                                validation.errors.construction
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.construction || ""}
                          >
                            <option value="">Choose...</option>
                            {coConstructions &&
                              coConstructions.map((construction, cns) => (
                                <option
                                  key={"cns" + cns}
                                  value={construction.construction_id}
                                >
                                  {construction.construction}
                                </option>
                              ))}
                          </select>
                          {validation.touched.construction &&
                            validation.errors.construction ? (
                            <FormFeedback type="invalid">
                              {validation.errors.construction}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-material-description">
                            Material Description
                          </Label>
                          <textarea
                            name="description"
                            type="text"
                            className="form-control"
                            id="formrow-material-description"
                            placeholder="Type Your Text Here"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-length">
                            Length (mm) <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="length"
                            type="text"
                            className="form-control"
                            id="formrow-length"
                            placeholder="Ex. 1800"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.length || ""}
                            invalid={
                              validation.touched.length &&
                                validation.errors.length
                                ? true
                                : false
                            }
                          />
                          {validation.touched.length &&
                            validation.errors.length ? (
                            <FormFeedback type="invalid">
                              {validation.errors.length}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-depth">
                            Depth (mm) <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="depth"
                            type="text"
                            className="form-control"
                            id="formrow-depth"
                            placeholder="Ex. 900"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.depth || ""}
                            invalid={
                              validation.touched.depth &&
                                validation.errors.depth
                                ? true
                                : false
                            }
                          />
                          {validation.touched.depth &&
                            validation.errors.depth ? (
                            <FormFeedback type="invalid">
                              {validation.errors.depth}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-heigth">
                            Heigth (mm) <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="height"
                            type="text"
                            className="form-control"
                            id="formrow-heigth"
                            placeholder="Ex. 750"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.height || ""}
                            invalid={
                              validation.touched.height &&
                                validation.errors.height
                                ? true
                                : false
                            }
                          />
                          {validation.touched.height &&
                            validation.errors.height ? (
                            <FormFeedback type="invalid">
                              {validation.errors.height}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-1">
                            Product Image 1
                          </Label>
                          <Input
                            name="img1"
                            type="file"
                            className="form-control"
                            id="formrow-image-1"
                            placeholder="Upload File"
                            // onChange={handleFileChange}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.img1 || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-2">
                            Product Image 2
                          </Label>
                          <Input
                            name="img2"
                            type="file"
                            className="form-control"
                            id="formrow-image-2"
                            placeholder="Upload File"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.img2 || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-3">
                            Product Image 3
                          </Label>
                          <Input
                            name="img3"
                            type="file"
                            className="form-control"
                            id="formrow-image-3"
                            placeholder="Upload File"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.img3 || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-4">
                            Product Image 4
                          </Label>
                          <Input
                            name="img4"
                            type="file"
                            className="form-control"
                            id="formrow-image-4"
                            placeholder="Upload File"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.img4 || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-5">
                            Product Image 5
                          </Label>
                          <Input
                            name="img5"
                            type="file"
                            className="form-control"
                            id="formrow-image-5"
                            placeholder="Upload File"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.img5 || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div>
                      {products && products.loading ? (
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          disabled
                        >
                          Adding Product ...
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary w-md">
                          Add Product
                        </button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default CoAddProduct
