import {
  CO_GET_DELIVEREDON_LISTING,
    CO_GET_DELIVEREDON_LISTING_SUCCESS,
    CO_GET_DELIVEREDON_LISTING_FAILED,
  } from "./actionTypes"
  
  export const coDeliveredOnListing = () => {
    return {
      type: CO_GET_DELIVEREDON_LISTING,
    }
  }
  
  export const coDeliveredOnListingSuccess = deliveredOn => {
    return {
      type: CO_GET_DELIVEREDON_LISTING_SUCCESS,
      payload: deliveredOn,
    }
  }
  
  export const coDeliveredOnListingFail = error => {
    return {
      type: CO_GET_DELIVEREDON_LISTING_FAILED,
      payload: error,
    }
  }
  