import {
  OFFER_SUCCESS,
  OFFER_FAIL,
  OFFER_ADD,
  GET_OFFERS_LISTING,
  GET_OFFERS_LISTING_SUCCESS,
  GET_OFFERS_LISTING_FAILED,
  OFFER_DELETE,
  OFFER_DELETE_SUCCESS,
  OFFER_DELETE_FAIL,
  OFFER_EDIT,
  OFFER_EDIT_SUCCESS,
  OFFER_EDIT_FAIL,
  OFFER_UPDATE,
  OFFER_UPDATE_SUCCESS,
  OFFER_UPDATE_FAIL,
  CHANGE_OFFER_STATUS,
  CHANGE_OFFER_STATUS_SUCCESS,
  CHANGE_OFFER_STATUS_FAIL,
  SEARCH_ITEM_IN_NEW_OFFER_PAGE,
  SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS,
  SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL,
  RESET_OFFER_SUCCESS,
  RESET_OFFER,
  RESET_OFFER_FAIL,
} from "./actionTypes"

const initialState = {
  error: null,
  msg: null,
  loading: false,
  offer: [],
  offerDelete: null,
  offerEdit: null,
  offerUpdate: null,
  search: null,
}

const offer = (state = initialState, action) => {
  switch (action.type) {
    case OFFER_ADD:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        search: null,
        offer: action.payload,
      }
      break
    case OFFER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: action?.payload?.msg,
        offer: [], // whenever clear the previous data this blanl array will call and return the new redux state which is ready for new data rendering (its mainly for add collection data ag grid )
      }
      break
    case OFFER_FAIL:
      state = {
        ...state,
        loading: false,
        msg: action?.payload?.msg,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case GET_OFFERS_LISTING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        offer: action.payload,
        offerEdit: [],
        search: null,
      }
      break
    case GET_OFFERS_LISTING_SUCCESS:
      state = {
        ...state,
        error: null,
        msg: null,
        loading: false,
        offer: action.payload,
        offerEdit: [],
        search: null,
      }
      break
    case GET_OFFERS_LISTING_FAILED:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case OFFER_DELETE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        offerDelete: action.payload,
      }
      break
    case OFFER_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        offer: state.offer.filter(offer => {
          return offer.offer_id !== action.payload.id
        }),
      }
    case OFFER_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case OFFER_EDIT:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        offerEdit: action.payload,
      }
      break
    case OFFER_EDIT_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        offer: [action.payload],
      }
      break
    case OFFER_EDIT_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case OFFER_UPDATE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        offerUpdate: action.payload,
      }
      break
    case OFFER_UPDATE_SUCCESS:
      state = {
        ...state,
        error: null,
        msg: action?.payload?.msg,
        loading: false,
      }
      break
    case OFFER_UPDATE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case CHANGE_OFFER_STATUS:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        offerEdit: [],
        search: null,
      }
      break
    case CHANGE_OFFER_STATUS_SUCCESS:
      const index = state.offer.findIndex(
        offer => offer.offer_id === action.payload.id
      )
      let newOfferAfterStatusChange = []
      newOfferAfterStatusChange = [...state.offer] //making a new array
      newOfferAfterStatusChange[index].status =
        !newOfferAfterStatusChange[index].status

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        offer: newOfferAfterStatusChange,
      }
      break

    case CHANGE_OFFER_STATUS_FAIL:
    default:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case SEARCH_ITEM_IN_NEW_OFFER_PAGE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        search: action.payload,
        offerEdit: [],
      }
      break
    case SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        search: action.payload,
      }
      break
    case SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break

    case RESET_OFFER:
      state = initialState
      break

    case RESET_OFFER_SUCCESS:
      state = initialState
      break

    case RESET_OFFER_FAIL:
      state = initialState
      break
  }
  return state
}

export default offer
