import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  CO_GET_PRODUCT_NUMBER_LISTING,
} from "./actionTypes"
import {
  coProductNumberListingSuccess,
  coProductNumberListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  coProductNumberListingApi,
} from "../../../helpers/fakebackend_helper"

function* coGetproductNumberListing() {
  try {
    const response = yield call(coProductNumberListingApi)
    if (!response.results) {
      yield put(coProductNumberListingFail(error))
    }
    yield put(coProductNumberListingSuccess(response.results))
  } catch (error) {
    yield put(coProductNumberListingFail(error))
  }
}

function* coProductNumberSaga() {
  yield takeEvery(CO_GET_PRODUCT_NUMBER_LISTING, coGetproductNumberListing)
}
export default coProductNumberSaga
