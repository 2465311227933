import {
  BD_GET_USERLISTACCORDINGROLE_LISTING,
  BD_GET_USERLISTACCORDINGROLE_LISTING_SUCCESS,
  BD_GET_USERLISTACCORDINGROLE_LISTING_FAILED,
} from "./actionTypes"

export const bdUserListAccordingRoleListing = (userType, history) => {
  return {
    type: BD_GET_USERLISTACCORDINGROLE_LISTING,
    payload: { userType, history },
  }
}

export const bdUserListAccordingRoleSuccess = bdUserListAccordingRole => {
  return {
    type: BD_GET_USERLISTACCORDINGROLE_LISTING_SUCCESS,
    payload: bdUserListAccordingRole,
  }
}

export const bdUserListAccordingRoleFail = error => {
  return {
    type: BD_GET_USERLISTACCORDINGROLE_LISTING_FAILED,
    payload: error,
  }
}
