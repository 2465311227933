import {
  BD_COLLECTION_ADD,
  BD_COLLECTION_SUCCESS,
  BD_COLLECTION_FAIL,
  BD_GET_COLLECTION_LISTING,
  BD_GET_COLLECTION_LISTING_SUCCESS,
  BD_GET_COLLECTION_LISTING_FAILED,
  BD_COLLECTION_EDIT,
  BD_COLLECTION_EDIT_SUCCESS,
  BD_COLLECTION_EDIT_FAIL,
  BD_COLLECTION_UPDATE,
  BD_COLLECTION_UPDATE_SUCCESS,
  BD_COLLECTION_UPDATE_FAIL,
  BD_COLLECTION_DELETE,
  BD_COLLECTION_DELETE_SUCCESS,
  BD_COLLECTION_DELETE_FAIL,
  BD_COLLECTION_COPY,
  BD_COLLECTION_COPY_SUCCESS,
  BD_COLLECTION_COPY_FAIL,
  BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD,
  BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS,
  BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL,
  BD_EXPORTDOWNLOAD_PROGRESS,
  BD_EXPORTDOWNLOAD_PROGRESS_SUCCESS,
  BD_EXPORTDOWNLOAD_PROGRESS_FAIL,
  BD_ADD_MORE_TO_COLLECTION,
  BD_ADD_MORE_TO_COLLECTION_SUCCESS,
  BD_ADD_MORE_TO_COLLECTION_FAIL,
  BD_ADD_MORE_TO_COLLECTION_UPDATE,
  BD_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS,
  BD_ADD_MORE_TO_COLLECTION_UPDATE_FAIL,
  BD_GET_PRODUCTS_FOR_ADD_COLLECTION,
  BD_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS,
  BD_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL,
  BD_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION,
  BD_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS,
  BD_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL,
  BD_REFRESH_COLLECTION_COUNT,
  BD_REFRESH_COLLECTION_COUNT_SUCCESS,
  BD_REFRESH_COLLECTION_COUNT_FAIL,
  BD_GET_LAYOUT,
  BD_GET_LAYOUT_SUCCESS,
  BD_GET_LAYOUT_FAIL,
} from "./actionTypes"

const initialState = {
  error: null,
  loading: false,
  msg: null,
  collection: null,
  collectionEdit: null,
  collectionUpdate: null,
  collectionDelete: null,
  collectionCopy: null,
  exportCollection: null,
  exportDownloadProgress: null,
  collectionAddMore: null,
  addMoreUpdateDetails: null,
  productsForAddCollection: null,
  productsOfferForAddCollection: null,
  export_layout: null,
}

export const bdCollection = (state = initialState, action) => {
  switch (action.type) {
    case BD_COLLECTION_ADD:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        collection: action.payload,
      }
      break
    case BD_COLLECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        collectionEdit: null,
        collectionUpdate: null,
        collectionDelete: null,
        exportCollection: null,
        collectionCopy: null,
        collectionAddMore: null,
        collection: action.payload,
      }
      break
    case BD_COLLECTION_FAIL:
        state = {
          ...state,
          loading: false,
          msg: null,
          collectionEdit: null,
          collectionUpdate: null,
          collectionDelete: null,
          exportCollection: null,
          collectionCopy: null,
          collectionAddMore: null,
          error: action.payload !== "light" ? action.payload : "",
        }
        break

    case BD_GET_COLLECTION_LISTING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        collectionEdit: null,
        collectionUpdate: null,
        collectionDelete: null,
        exportCollection: null,
        collectionCopy: null,
        collection: action.payload,
      }
      break
    case BD_GET_COLLECTION_LISTING_SUCCESS:
      state = {
        ...state,
        error: null,
        msg: null,
        loading: false,
        collection: action.payload,
      }
      break
    case BD_GET_COLLECTION_LISTING_FAILED:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break

    case BD_COLLECTION_EDIT:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        collectionEdit: action.payload,
      }
      break
    case BD_COLLECTION_EDIT_SUCCESS:
      if (state === null || state.collection.length === 0) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          collection: [...state.collection, action.payload],
        }
      } else {
        state = {
          ...state,
          loading: false,
          collection: state.collection.map(i =>
            i.id === action.payload.id ? action.payload : i
          ),
        }
      }
      break
    case BD_COLLECTION_EDIT_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break

    case BD_COLLECTION_UPDATE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        collectionUpdate: action.payload,
      }
      break
    case BD_COLLECTION_UPDATE_SUCCESS:
      state = { ...state, error: null,  msg: null, loading: false }
      break
    case BD_COLLECTION_UPDATE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break

    case BD_COLLECTION_DELETE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        collectionDelete: action.payload,
      }
      break
    case BD_COLLECTION_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        collection: state.collection.filter(collection => {
          return collection.id !== action.payload.id
        }),
      }
      break
    case BD_COLLECTION_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break

    case BD_COLLECTION_COPY:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        collectionCopy: action.payload,
      }
      break
    case BD_COLLECTION_COPY_SUCCESS:
      let newcol = [...state.collectionCopy, action.payload]
      newcol = newcol.sort(function (a, b) {
        return b.id - a.id || b.id.localeCompare(a.id)
      })
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        collection: newcol,
        collectionCopy: null,
      }
      break
    case BD_COLLECTION_COPY_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break

    case BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        exportCollection: action.payload,
      }
      break
    case BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS:
      state = {
        ...state,
        error: null,
        loading: false,
        msg: null,
        exportCollection: action.payload,
      }
      break
    case BD_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break

    case BD_EXPORTDOWNLOAD_PROGRESS:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        exportDownloadProgress: action.payload,
      }
      break
    case BD_EXPORTDOWNLOAD_PROGRESS_SUCCESS:
      state = {
        ...state,
        error: null,
        loading: false,
        msg: null,
        exportDownloadProgress: action.payload,
      }
      break
    case BD_EXPORTDOWNLOAD_PROGRESS_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break

    case BD_ADD_MORE_TO_COLLECTION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        collectionAddMore: action.payload,
      }
      break
    case BD_ADD_MORE_TO_COLLECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        collectionAddMore: action.payload,
      }
      break
    case BD_ADD_MORE_TO_COLLECTION_FAIL:
      return {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break

    case BD_ADD_MORE_TO_COLLECTION_UPDATE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        addMoreUpdateDetails: action.payload,
      }
      break
    case BD_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        addMoreUpdateDetails: null,
      }
      break
    case BD_ADD_MORE_TO_COLLECTION_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
      case BD_GET_PRODUCTS_FOR_ADD_COLLECTION:
        state = {
          ...state,
          loading: true,
          error: null,
          msg: null,
          productsForAddCollection: action.payload,
        }
        break
      case BD_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS:
        
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          productsForAddCollection: action.payload,
        }
        break
      case BD_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL:
        state = {
          ...state,
          loading: false,
          msg: null,
          error: action.payload !== "light" ? action.payload : "",
        }
        break
      case BD_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION:
        state = {
          ...state,
          loading: true,
          error: null,
          msg: null,
          productsOfferForAddCollection: action.payload,
        }
        break
      case BD_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS:
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          productsOfferForAddCollection: action.payload,
        }
        break
      case BD_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL:
        state = {
          ...state,
          loading: false,
          msg: null,
          error: action.payload !== "light" ? action.payload : "",
        }
        break
        case BD_REFRESH_COLLECTION_COUNT:
          state = {
            ...state,
            loading: true,
            error: null,
            msg: null,
            productsForAddCollection: null,
            productsOfferForAddCollection: action.payload,
          }
          break
        case BD_REFRESH_COLLECTION_COUNT_SUCCESS:
          state = {
            ...state,
            loading: false,
            error: null,
            msg: null,
            collection: state.collection.map(i =>
              i.id === action.payload.id ? action.payload : i
            ),
          }
          break
        case BD_REFRESH_COLLECTION_COUNT_FAIL:
          state = {
            ...state,
            loading: false,
            msg: null,
            error: action.payload !== "light" ? action.payload : "",
          }
          break      
          case BD_GET_LAYOUT:
          state = {
            ...state,
            loading: true,
            error: null,
            msg: null,
            export_layout: action.payload,
          }
          break
        case BD_GET_LAYOUT_SUCCESS:
          state = {
            ...state,
            error: null,
            msg: null,
            loading: false,
            export_layout: action.payload,
          }
          break
        case BD_GET_LAYOUT_FAIL:
          state = {
            ...state,
            loading: false,
            msg: null,
            error: action.payload !== "light" ? action.payload : "",
          }
          break
  }
  return state
}

export default bdCollection
