import React, { useState } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { useSelector, useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// actions
import { roleListing, officeListing, addNewUser } from "../../../store/actions"
import { useEffect } from "react"

const AddUser = props => {
  const dispatch = useDispatch()
  document.title = "Add User"

  useEffect(() => {
    dispatch(roleListing())
    dispatch(officeListing())
  }, [dispatch])

  const [roles, setRoles] = useState([])
  const [rolesDefault, setrolesdefault] = useState([])

  const [offices, setOffices] = useState([])
  const [officesDefault, setofficesDefault] = useState([])

  const rolesData = useSelector(state => state.Role.role)
  const officeData = useSelector(state => state.Office.office)

  useEffect(() => {
    setRoles(rolesData)
    if (rolesData && rolesData.length > 0) {
      setrolesdefault(
        rolesData.filter((ul, pc) => {
          return ul.is_default == 1
        })
      )
    }
    setOffices(officeData)
    if (officeData && officeData.length > 0) {
      setofficesDefault(
        officeData.filter((of, pc) => {
          return of.is_default == 1
        })
      )
    }
  }, [rolesData, officeData])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      short_name: "",
      user_office:
        officesDefault && officesDefault.length > 0
          ? officesDefault[0]["id"]
          : "",
      email: "",
      user_role:
        rolesDefault && rolesDefault.length > 0 ? rolesDefault[0]["id"] : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      short_name: Yup.string().required("Please Enter Your Short Name"),
      user_office: Yup.string().required("Please Enter Your Office"),
      email: Yup.string().required("Please Enter Your Email"),
      user_role: Yup.string().required("Please Enter Your Role"),
    }),
    onSubmit: values => {
      dispatch(addNewUser(values, props.history))
    },
  })

  const users = useSelector(state => state.User)
  const { error, msg } = useSelector(state => ({
    error: state.User.error,
    msg: state.User.msg ? state.User.msg : "",
  }))

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Add User" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {error || msg ? <ToastContainer className="Toastify__close-button" /> : ""}
                  <Form
                    id="userForm"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="username">
                            User Name
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            name="name"
                            className={
                              validation.touched.name && validation.errors.name
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            id="username"
                            placeholder="Enter User Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="shortname">
                            User name short
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="short_name"
                            type="text"
                            className={
                              validation.touched.short_name &&
                              validation.errors.short_name
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            id="shortname"
                            placeholder="Enter user name (short)"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.short_name || ""}
                            invalid={
                              validation.touched.short_name &&
                              validation.errors.short_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.short_name &&
                          validation.errors.short_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.short_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="User_Office">
                            User Office <span className="text-danger">*</span>
                          </Label>
                          <select
                            name="user_office"
                            id="User_Office"
                            className={
                              validation.touched.user_office &&
                              validation.errors.user_office
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user_office || ""}
                          >
                            <option value="">Choose...</option>
                            {offices &&
                              offices.map((office, ofk) => (
                                <option key={"ofk" + ofk} value={office.id}>
                                  {office.office_name}
                                </option>
                              ))}
                          </select>
                          {validation.touched.user_office &&
                          validation.errors.user_office ? (
                            <FormFeedback type="invalid">
                              {validation.errors.user_office}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="emailID">
                            User Email ID
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="email"
                            type="email"
                            className={
                              validation.touched.email &&
                              validation.errors.email
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            id="emailID"
                            placeholder="Enter user email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="role">
                            User role <span className="text-danger">*</span>
                          </Label>
                          <select
                            id="role"
                            className={
                              validation.touched.user_role &&
                              validation.errors.user_role
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            name="user_role"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user_role || ""}
                          >
                            <option value="">Choose...</option>
                            {roles &&
                              roles.map((role, rk) => (
                                <option
                                  selected={
                                    role.is_default == 1 ? "selected" : ""
                                  }
                                  key={"rk" + rk}
                                  value={role.id}
                                >
                                  {role.user_role_short}
                                </option>
                              ))}
                          </select>
                          {validation.touched.user_role &&
                          validation.errors.user_role ? (
                            <FormFeedback type="invalid">
                              {validation.errors.user_role}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <div>
                      {users && users.loading ? (
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          disabled
                        >
                          Adding User ...
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary w-md">
                          Add User
                        </button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default AddUser
