import {
  GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING,
  GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_SUCCESS,
  GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_FAILED,
  ADD_CERTIFICATION,
  ADD_CERTIFICATION_SUCCESS,
  ADD_CERTIFICATION_FAIL,
  CHANGE_CERTIFICATION_STATUS,
  CHANGE_CERTIFICATION_STATUS_SUCCESS,
  CHANGE_CERTIFICATION_STATUS_FAIL,
  EDIT_CERTIFICATION,
  EDIT_CERTIFICATION_SUCCESS,
  EDIT_CERTIFICATION_FAIL,
  UPDATE_CERTIFICATION,
  UPDATE_CERTIFICATION_SUCCESS,
  UPDATE_CERTIFICATION_FAIL,
  FETCH_CERTIFICATION_BY_SECTION,
  FETCH_CERTIFICATION_BY_SECTION_SUCCESS,
  FETCH_CERTIFICATION_BY_SECTION_FAIL,
  UPDATE_CERTIFICATION_POSITION,
  UPDATE_CERTIFICATION_POSITION_SUCCESS,
  UPDATE_CERTIFICATION_POSITION_FAIL,
} from "./actionTypes"

const initialState = {
  error: null,
  msg: null,
  loading: false,
  environmentalAndHealthCertification: null,
  certificateEdit: null,
  certificateUpdate: null,
  positionUpdate: null,
}

const environmentalAndHealthCertification = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
        environmentalAndHealthCertification: action.payload,
      }
      break
    case GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        positionUpdate: null,
        environmentalAndHealthCertification: action.payload,
      }
      break
    case GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING_FAILED:
      state = { ...state, loading: false }
      break
    case ADD_CERTIFICATION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        environmentalAndHealthCertification: action.payload,
      }
      break
    case ADD_CERTIFICATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        environmentalAndHealthCertification: action.payload,
      }
      break
    case ADD_CERTIFICATION_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload ? action.payload : null,
      }
      break
    case CHANGE_CERTIFICATION_STATUS:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break
    case CHANGE_CERTIFICATION_STATUS_SUCCESS:
      const index = state.environmentalAndHealthCertification.findIndex(
        certificates => certificates.id === action.payload.id
      )
      let newCertificateAfterStatusChange = []
      newCertificateAfterStatusChange = [
        ...state.environmentalAndHealthCertification,
      ] //making a new array
      newCertificateAfterStatusChange[index].status =
        newCertificateAfterStatusChange[index].status == 1 ? 0 : 1

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        environmentalAndHealthCertification: newCertificateAfterStatusChange,
      }
      break

    case CHANGE_CERTIFICATION_STATUS_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case EDIT_CERTIFICATION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        certificateEdit: action.payload,
      }
      break
    case EDIT_CERTIFICATION_SUCCESS:
      if (
        state === null ||
        state.environmentalAndHealthCertification.length === 0
      ) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          certificateEdit: action.payload,
        }
      } else {
        state = {
          ...state,
          loading: false,
          environmentalAndHealthCertification:
            state.environmentalAndHealthCertification.map(i =>
              i.id === action.payload.id ? action.payload : i
            ),
        }
      }
      break
    case EDIT_CERTIFICATION_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case UPDATE_CERTIFICATION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        certificateUpdate: action.payload,
      }
      break
    case UPDATE_CERTIFICATION_SUCCESS:
      state = { ...state, error: null, msg: null, loading: false }
      break
    case UPDATE_CERTIFICATION_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case FETCH_CERTIFICATION_BY_SECTION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
        certificateList: action.payload,
      }
      break
    case FETCH_CERTIFICATION_BY_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        positionUpdate: null,
        environmentalAndHealthCertification: action.payload,
      }
      break
    case FETCH_CERTIFICATION_BY_SECTION_FAIL:
      state = { ...state, loading: false, positionUpdate: null }
      break
      case UPDATE_CERTIFICATION_POSITION:
        state = {
          ...state,
          loading: true,
          error: null,
          msg: null,
          positionUpdate: null,
        }
        break
  
      case UPDATE_CERTIFICATION_POSITION_SUCCESS:
        state = {
          ...state,
          loading: false,
          error: null,
          msg: action.payload.msg != "" ? action.payload.msg : "",
          positionUpdate: true,
        }
        break
      case UPDATE_CERTIFICATION_POSITION_FAIL:
        state = {
          ...state,
          loading: false,
          error: action.payload.msg != "" ? action.payload.msg : "",
          msg: null,
          positionUpdate: null,
        }
        break
    default:
      state = { ...state }
      break
  }
  return state
}

export default environmentalAndHealthCertification
