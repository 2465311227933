import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { BD_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING } from "./actionTypes"
import {
  bdEnvironmentalAndHealthCertificationListingSuccess,
  bdEnvironmentalAndHealthCertificationListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { bdEnvironmentalAndHealthCertificationListingApi } from "../../../helpers/fakebackend_helper"

function* getBdEnvironmentalAndHealthCertificationListing() {
  try {
    const response = yield call(bdEnvironmentalAndHealthCertificationListingApi)
    if (!response.results.certificates) {
      yield put(bdEnvironmentalAndHealthCertificationListingFail(error))
    }
    yield put(
      bdEnvironmentalAndHealthCertificationListingSuccess(
        response.results.certificates
      )
    )
  } catch (error) {
    yield put(bdEnvironmentalAndHealthCertificationListingFail(error))
  }
}

function* bdEnvironmentalAndHealthCertificationSaga() {
  yield takeEvery(
    BD_GET_ENVIRONMENTALANDHEALTHCERTIFICATION_LISTING,
    getBdEnvironmentalAndHealthCertificationListing
  )
}
export default bdEnvironmentalAndHealthCertificationSaga
