import {
  CO_GET_QUALITYRATING_LISTING,
  CO_GET_QUALITYRATING_LISTING_SUCCESS,
  CO_GET_QUALITYRATING_LISTING_FAILED,
  } from "./actionTypes"
  
  export const coQualityRatingListing = () => {
    return {
      type: CO_GET_QUALITYRATING_LISTING,
    }
  }
  
  export const coQualityRatingListingSuccess = qualityRating => {
    return {
      type: CO_GET_QUALITYRATING_LISTING_SUCCESS,
      payload: qualityRating,
    }
  }
  
  export const coQualityRatingListingFail = error => {
    return {
      type: CO_GET_QUALITYRATING_LISTING_FAILED,
      payload: error,
    }
  }
  
