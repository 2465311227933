import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { useHistory } from "react-router-dom"
// offer Redux States
import {
  SA_GET_OFFERS_LISTING,
  SA_OFFER_EDIT,
  SA_OFFER_UPDATE,
  SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
  SA_RESET_OFFER
} from "./actionTypes"
import {
  saOffersListingSuccess,
  saOffersListingFail,
  saEditOfferSuccess,
  saEditOfferFail,
  saUpdateOfferSuccess,
  saUpdateOfferFail,
  saSearchItemInNewOfferPageSuccess,
  saSearchItemInNewOfferPageFail,
  saClearOfferResultSuccess,
  saClearOfferResultError
} from "./actions"

import {
  saOffersListingApi,
  saEditOfferApi,
  saUpdateOfferApi,
  saSearchItemInNewOfferPageApi
} from "../../../helpers/fakebackend_helper"

function* saGetOfferListing() {
  try {
    const response = yield call(saOffersListingApi)
    if (
      response.results === undefined ||
      response.results.prod_offers.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(saOffersListingSuccess(response.results.prod_offers))
  } catch (error) {
    yield put(saOffersListingFail(error))
  }
}

function* saGetOfferDetailsById({ payload: { id } }) {
  try {
    const response = yield call(saEditOfferApi, id)
    if (response && response.status) {
      response.id = id
      yield put(saEditOfferSuccess(response.results.offer_details))
    } else {
      throw response.msg ? response.msg : "Failed to fetch offer details"
    }
  } catch (error) {
    yield put(saEditOfferFail(error))
  }
}

function* saOnUpdateOffer({ payload: { offer, history } }) {
  try {
    const response = yield call(saUpdateOfferApi, offer)
    if (response && response.status) {
      yield put(saUpdateOfferSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update offer"
    }
  } catch (error) {
    yield put(saUpdateOfferFail(error))
  }
}

function* saSearchAtNewOfferPage({ payload: { search } }) {
  try {
    const response = yield call(saSearchItemInNewOfferPageApi, search)

    if (response && response.status && response?.results?.prod_offers) {
      yield put(saSearchItemInNewOfferPageSuccess(response.results.prod_offers))
    } else {
      throw response.msg ? response.msg : "No Record found"
    }
  } catch (error) {
    yield put(saSearchItemInNewOfferPageFail(error))
  }
}

function* saClearOfferResults() {
  try {
    yield put(saClearOfferResultSuccess())
  } catch (error) {
    yield put(saClearOfferResultError(error))
  }
}

function* saOfferSaga() {
  yield takeEvery(SA_GET_OFFERS_LISTING, saGetOfferListing)
  yield takeEvery(SA_OFFER_EDIT, saGetOfferDetailsById)
  yield takeEvery(SA_OFFER_UPDATE, saOnUpdateOffer)
  yield takeEvery(SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE, saSearchAtNewOfferPage)
  yield takeEvery(SA_RESET_OFFER, saClearOfferResults)
}
export default saOfferSaga
