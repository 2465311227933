import {
    CO_GET_SUPPLIERNAME_LISTING,
    CO_GET_SUPPLIERNAME_LISTING_SUCCESS,
    CO_GET_SUPPLIERNAME_LISTING_FAILED,
    CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
    CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS,
    CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED
  } from "./actionTypes"
  
  const initialState = {
    loading: false,
    msg: null,
    error: null,
    supplierName: [],
    supplierListingForSearch: [],
  }
  
  const coSupplierName = (state = initialState, action) => {
    switch (action.type) {
      case CO_GET_SUPPLIERNAME_LISTING:
        state = {
          ...state,
          loading: true,
          supplierName: action.payload,
        }
        break
      case CO_GET_SUPPLIERNAME_LISTING_SUCCESS:
        state = { ...state, loading: false, supplierName: action.payload }
        break
      case CO_GET_SUPPLIERNAME_LISTING_FAILED:
        state = { ...state, loading: false }
        break
        case CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE:
          state = {
            ...state,
            loading: true,
            msg: null,
            error: null,
            supplierListingForSearch: action.payload,
          }
          break
        case CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_SUCCESS:
          state = {
            ...state,
            loading: false,
            msg: null,
            error: null,
            supplierListingForSearch: action.payload,
          }
          break
        case CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE_FAILED:
          state = { ...state, loading: false }
          break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default coSupplierName
  