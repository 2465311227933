import {
  GET_QUALITYRATING_LISTING,
  GET_QUALITYRATING_LISTING_SUCCESS,
  GET_QUALITYRATING_LISTING_FAILED,
  ADD_QUALITY_RATING,
  ADD_QUALITY_RATING_SUCCESS,
  ADD_QUALITY_RATING_FAIL,
  CHANGE_QUALITY_RATING_STATUS,
  CHANGE_QUALITY_RATING_STATUS_SUCCESS,
  CHANGE_QUALITY_RATING_STATUS_FAIL,
  EDIT_QUALITY_RATING,
  EDIT_QUALITY_RATING_SUCCESS,
  EDIT_QUALITY_RATING_FAIL,
  UPDATE_QUALITY_RATING,
  UPDATE_QUALITY_RATING_SUCCESS,
  UPDATE_QUALITY_RATING_FAIL,
  FETCH_QUALITY_RATING_BY_SECTION,
  FETCH_QUALITY_RATING_BY_SECTION_SUCCESS,
  FETCH_QUALITY_RATING_BY_SECTION_FAIL,
  UPDATE_QUALITY_RATING_POSITION,
  UPDATE_QUALITY_RATING_POSITION_SUCCESS,
  UPDATE_QUALITY_RATING_POSITION_FAIL,
} from "./actionTypes"

const initialState = {
  error: null,
  loading: false,
  msg: null,
  qualityRating: null,
  qualityRatingEdit: null,
  qualityRatingUpdate: null,
  positionUpdate: null,
}

const qualityRating = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUALITYRATING_LISTING:
      state = {
        ...state,
        loading: true,
        positionUpdate: null,
        msg: null,
        error: null,
        qualityRating: action.payload,
      }
      break
    case GET_QUALITYRATING_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        positionUpdate: null,
        msg: null,
        error: null,
        qualityRating: action.payload,
      }
      break
    case GET_QUALITYRATING_LISTING_FAILED:
      state = { ...state, loading: false }
      break
    case ADD_QUALITY_RATING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        qualityRating: action.payload,
      }
      break
    case ADD_QUALITY_RATING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        qualityRating: action.payload,
      }
      break
    case ADD_QUALITY_RATING_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload ? action.payload : null,
      }
      break
    case CHANGE_QUALITY_RATING_STATUS:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break
    case CHANGE_QUALITY_RATING_STATUS_SUCCESS:
      const index = state.qualityRating.findIndex(
        ratings => ratings.id === action.payload.id
      )
      let newQualityRatingAfterStatusChange = []
      newQualityRatingAfterStatusChange = [...state.qualityRating] //making a new array
      newQualityRatingAfterStatusChange[index].status =
        newQualityRatingAfterStatusChange[index].status == 1 ? 0 : 1

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        qualityRating: newQualityRatingAfterStatusChange,
      }
      break

    case CHANGE_QUALITY_RATING_STATUS_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case EDIT_QUALITY_RATING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        qualityRatingEdit: action.payload,
      }
      break
    case EDIT_QUALITY_RATING_SUCCESS:
      if (state === null || state.qualityRating.length === 0) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          qualityRating: [...state.qualityRating, action.payload],
        }
      } else {
        state = {
          ...state,
          loading: false,
          qualityRating: state.qualityRating.map(i =>
            i.id === action.payload.id ? action.payload : i
          ),
        }
      }
      break
    case EDIT_QUALITY_RATING_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case UPDATE_QUALITY_RATING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        qualityRatingUpdate: action.payload,
      }
      break
    case UPDATE_QUALITY_RATING_SUCCESS:
      state = { ...state, error: null, msg: null, loading: false }
      break
    case UPDATE_QUALITY_RATING_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case FETCH_QUALITY_RATING_BY_SECTION:
      state = {
        ...state,
        loading: true,
        positionUpdate: null,
        msg: null,
        error: null,
        qualityRatingList: action.payload,
      }
      break
    case FETCH_QUALITY_RATING_BY_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        positionUpdate: null,
        msg: null,
        error: null,
        qualityRating: action.payload,
      }
      break
    case FETCH_QUALITY_RATING_BY_SECTION_FAIL:
      state = { ...state, loading: false }
      break
    case UPDATE_QUALITY_RATING_POSITION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
      }
      break

    case UPDATE_QUALITY_RATING_POSITION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: action.payload.msg != "" ? action.payload.msg : "",
        positionUpdate: true,
      }
      break
    case UPDATE_QUALITY_RATING_POSITION_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.msg != "" ? action.payload.msg : "",
        msg: null,
        positionUpdate: null,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default qualityRating
