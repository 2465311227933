import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { QA_GET_USERLISTACCORDINGROLE_LISTING } from "./actionTypes"
import {
  qaUserListAccordingRoleSuccess,
  qaUserListAccordingRoleFail,
} from "./actions"

//Include Both Helper File with needed methods
import { qaUserListAccordingRoleApi } from "../../../helpers/fakebackend_helper"

function* qaGetUserListAccordingRoleListing({ payload: { userType, history } }) {
  try {
    const response = yield call(qaUserListAccordingRoleApi, userType)
    if (!response.results.user_lists) {
      yield put(qaUserListAccordingRoleFail(error))
    }
    yield put(qaUserListAccordingRoleSuccess(response.results.user_lists))
  } catch (error) {
    yield put(qaUserListAccordingRoleFail(error))
  }
}

function* qaUserListAccordingRoleSaga() {
  yield takeEvery(
    QA_GET_USERLISTACCORDINGROLE_LISTING,
    qaGetUserListAccordingRoleListing
  )
}
export default qaUserListAccordingRoleSaga
