export const SA_OFFER_EDIT = "SA_OFFER_EDIT"
export const SA_OFFER_EDIT_SUCCESS = "SA_OFFER_EDIT_SUCCESS"
export const SA_OFFER_EDIT_FAIL = "SA_OFFER_EDIT_FAIL"

export const SA_GET_OFFERS_LISTING = "SA_GET_OFFERS_LISTING"
export const SA_GET_OFFERS_LISTING_SUCCESS = "SA_GET_OFFERS_LISTING_SUCCESS"
export const SA_GET_OFFERS_LISTING_FAILED = "SA_GET_OFFERS_LISTING_FAILED"

export const SA_OFFER_UPDATE = "SA_OFFER_UPDATE"
export const SA_OFFER_UPDATE_SUCCESS = "SA_OFFER_UPDATE_SUCCESS"
export const SA_OFFER_UPDATE_FAIL = "SA_OFFER_UPDATE_FAIL"

export const SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE = "SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE"
export const SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS = "SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS"
export const SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL = "SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL"

export const SA_RESET_OFFER = "SA_RESET_OFFER"
export const SA_RESET_OFFER_SUCCESS = "SA_RESET_OFFER_SUCCESS"
export const SA_RESET_OFFER_FAIL = "SA_RESET_OFFER_FAIL"