import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { CO_GET_PACKINGSTANDARD_LISTING } from "./actionTypes"
import {
  coPackingStandardListingSuccess,
  coPackingStandardListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { coPackingStandardListingApi } from "../../../helpers/fakebackend_helper"

function* coGetPackingStandardListing() {
  try {
    const response = yield call(coPackingStandardListingApi)
    if (!response.results.packing_standards) {
      yield put(coPackingStandardListingFail(error))
    }
    yield put(coPackingStandardListingSuccess(response.results.packing_standards))
  } catch (error) {
    yield put(coPackingStandardListingFail(error))
  }
}

function* coPackingStandardSaga() {
  yield takeEvery(CO_GET_PACKINGSTANDARD_LISTING, coGetPackingStandardListing)
}
export default coPackingStandardSaga
