import React, { useState, useEffect } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import * as Yup from "yup"
import { useFormik } from "formik"
import classnames from "classnames"
import { Link, history, useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  bdAddNewOffer,
  bdDeliveredOnListing,
  bdEnvironmentalAndHealthCertificationListing,
  bdHeaveyColliListing,
  bdIncotermCityListing,
  bdMaterialStandardListing,
  bdPackagingListing,
  bdOfferStatusListing,
  bdPackingStandardListing,
  bdSupplierNameListing,
  bdUserListAccordingRoleListing,
} from "../../../store/actions"

const BusinessDeveloperAddOffer = props => {
  document.title = "Add Offer"

  const { id, prod_no } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const defaultMargin = 0.8 // 20% margin =>  (100-20)/100

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  useEffect(() => {
    dispatch(bdDeliveredOnListing())
    dispatch(bdEnvironmentalAndHealthCertificationListing())
    dispatch(bdHeaveyColliListing())
    dispatch(bdIncotermCityListing())
    dispatch(bdMaterialStandardListing())
    dispatch(bdPackagingListing())
    dispatch(bdOfferStatusListing())
    dispatch(bdPackingStandardListing())
    dispatch(bdSupplierNameListing())
    dispatch(bdUserListAccordingRoleListing({ user_role: "qa" }, props.history))
  }, [dispatch])

  const [details, setDetails] = useState({})
  const [errorsArr, SetErrorsArr] = useState([]) // validation section

  const [bdSupplierName, setBdSupplierName] = useState([])
  const [bdOfferStatus, setBdOfferStatus] = useState([])
  const [bdIncotermCity, setBdIncotermCity] = useState([])
  const [bdMaterialStandard, setBdMaterialStandard] = useState([])
  const [bdPackingStandard, setBdPackingStandard] = useState([])
  const [
    bdEnvironmentalAndHealthCertification,
    setBdEnvironmentalAndHealthCertification,
  ] = useState([])
  const [bdPackaging, setBdPackaging] = useState([])
  const [bdDeliveredOn, setBdDeliveredOn] = useState([])
  const [bdHeaveyColli, setBdHeaveyColli] = useState([])
  const [bdUserListAccordingRole, setBdUserListAccordingRole] = useState([])

  const products = useSelector(state => state.bdProduct)
  const bdSupplierNameData = useSelector(
    state => state.bdSupplierName.bdSupplierName
  ) ///1st supplierName is from reducer
  const bdOfferStatusData = useSelector(
    state => state.bdOfferStatus.bdOfferStatus
  )
  const bdIncotermCityData = useSelector(
    state => state.bdIncotermCity.bdIncotermCity
  )
  const bdMaterialStandardData = useSelector(
    state => state.bdMaterialStandard.bdMaterialStandard
  )
  const bdPackingStandardData = useSelector(
    state => state.bdPackingStandard.bdPackingStandard
  )
  const bdEnvironmentalAndHealthCertificationData = useSelector(
    state =>
      state.bdEnvironmentalAndHealthCertification
        .bdEnvironmentalAndHealthCertification
  )

  const bdPackagingData = useSelector(state => state.bdPackaging.bdPackaging)

  const bdDeliveredOnData = useSelector(
    state => state.bdDeliveredOn.bdDeliveredOn
  )
  const bdHeaveyColliData = useSelector(
    state => state.bdHeaveyColli.bdHeaveyColli
  )

  const bdUserListAccordingRoleData = useSelector(
    state => state.bdUserListAccordingRole.bdUserListAccordingRole
  )

  useEffect(() => {
    let prods = []
    if (products) {
      prods = products.product.filter(product => product.prod_id == id)
    }
    if (prods.length > 0) {
      setDetails(prods[0])
    } else {
      props.history.push("/products/list")
    }
    setBdSupplierName(bdSupplierNameData)
    setBdOfferStatus(bdOfferStatusData)
    setBdIncotermCity(bdIncotermCityData)
    setBdMaterialStandard(bdMaterialStandardData)
    setBdPackingStandard(bdPackingStandardData)
    setBdEnvironmentalAndHealthCertification(
      bdEnvironmentalAndHealthCertificationData
    )
    setBdPackaging(bdPackagingData)
    setBdDeliveredOn(bdDeliveredOnData)
    setBdHeaveyColli(bdHeaveyColliData)
    setBdUserListAccordingRole(bdUserListAccordingRoleData)
  }, [
    bdSupplierNameData,
    bdOfferStatusData,
    bdIncotermCityData,
    bdMaterialStandardData,
    bdPackingStandardData,
    bdEnvironmentalAndHealthCertificationData,
    bdPackagingData,
    bdDeliveredOnData,
    bdHeaveyColliData,
    bdUserListAccordingRoleData,
  ])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      prod_id: id,
      offer_status_id: "1",
      vendor_article_no: "",
      supplier_id: "",
      production_time: "",
      incoterm_city_id: "",
      material_standard_id: "",
      packing_standard_id: "",
      env_health_id: "",
      non_compliance: "",
      sbn_comments: "",
      quality_control: "",
      color_code_gloss: "",
      purchase_price_fsc: "",
      yearly_purchase_fsc: "",
      sales_price_fsc: "",
      sales_margin_fsc: "",
      purchase_price_non_fsc: "",
      yearly_purchase_non_fsc: "",
      sales_price_non_fsc: "",
      sales_margin_non_fsc: "",
      purchasing_price_comments: "",
      full_truck: "",
      container: "",
      moq: "",
      net_weight: "",
      gross_weight: "",
      packaging_id: "",
      delivered_on: "",
      heavy_colli_id: "",
      units_per_box: "",
      bom_per_article: "",
      master_cardboard_box: "",
      packing_comments: "",
      qa_user_id: "",
      sample_request: "",
      sample_comments: "",
      sample_status: "",
      qa_sample_checking_comments: "",
      sales_sample_checking_comments: "",
      sample_freight_cost: "",
      sample_main_material: "",
      sample_hardware: "",
      sample_surface_treatment: "",
    },
    validationSchema: Yup.object({
      offer_status_id: Yup.string().required("Offer status is required"),
      supplier_id: Yup.string().required("Supplier is required"),
      incoterm_city_id: Yup.string().required("Incoterm is required"),
      production_time: Yup.string().required("Production time is required"),
      material_standard_id: Yup.string().required(
        "Material standard is required"
      ),
      packing_standard_id: Yup.string().required(
        "Packing standard is required"
      ),
      env_health_id: Yup.string().required("Env health is required"),
      purchase_price_fsc: Yup.number().required("Purchase FSC is required"),
      yearly_purchase_fsc: Yup.number(),
      sales_price_fsc: Yup.number(),
      sales_margin_fsc: Yup.number(),
      yearly_sales_fsc: Yup.number(),

      purchase_price_non_fsc: Yup.number(),
      yearly_purchase_non_fsc: Yup.number(),
      sales_price_non_fsc: Yup.number(),
      sales_margin_non_fsc: Yup.number(),
      yearly_sales_non_fsc: Yup.number(),
      full_truck: Yup.number().required("Full truck is required"),
      container: Yup.number().required("20ST Container is required"),
      moq: Yup.number().required("Moq is required"),
      net_weight: Yup.number().required("Net weight is required"),
      gross_weight: Yup.number().required("Gross weight is required"),
      packaging_id: Yup.string().required("Packaging is required"),
      delivered_on: Yup.string().required("Delivered on is required"),
      heavy_colli_id: Yup.string().required("Heavy colli is required"),
      units_per_box: Yup.number().required("Units per box is required"),
      bom_per_article: Yup.number().required("BOM per article is required"),
    }),
    onSubmit: values => {
      dispatch(bdAddNewOffer(values, props.history))
    },
  })

  let validationSections = [
    {
      section: "Offer Details",
      errorPresent: false,
      children: [
        { name: "offer_status_id", errorMessage: null },
        { name: "supplier_id", errorMessage: null },
        { name: "incoterm_city_id", errorMessage: null },
        { name: "production_time", errorMessage: null },
        { name: "material_standard_id", errorMessage: null },
        { name: "packing_standard_id", errorMessage: null },
        { name: "env_health_id", errorMessage: null },
      ],
    },
    {
      section: "Offer Price",
      errorPresent: false,
      children: [
        { name: "purchase_price_fsc", errorMessage: null },
        { name: "sales_price_fsc", errorMessage: null },
      ],
    },
    {
      section: "Offer Packing",
      errorPresent: false,
      children: [
        { name: "full_truck", errorMessage: null },
        { name: "container", errorMessage: null },
        { name: "moq", errorMessage: null },
        { name: "net_weight", errorMessage: null },
        { name: "gross_weight", errorMessage: null },
        { name: "packaging_id", errorMessage: null },
        { name: "delivered_on", errorMessage: null },
        { name: "heavy_colli_id", errorMessage: null },
        { name: "units_per_box", errorMessage: null },
        { name: "bom_per_article", errorMessage: null },
      ],
    },
  ]

  useEffect(() => {
    if (
      Object.keys(validation.touched).length &&
      Object.keys(validation.errors).length
    ) {
      let currentValidation = [...validationSections]
      for (const error in validation.errors) {
        currentValidation.forEach(section => {
          // Find the matching child by name
          const child = section.children.find(c => c.name === error)
          // If a matching child is found, update its errorMessage
          if (child && validation?.errors[error] !== "") {
            child.errorMessage = validation.errors[error]
            section.errorPresent = true
          } else if (child && validation?.errors[error] === "") {
            child.errorMessage = null
          }
        })
        SetErrorsArr(currentValidation)
      }
    } else {
      SetErrorsArr([])
    }
  }, [validation.errors, validation.touched])

  function updateDecimalPortion(data = 0.0) {
    data = parseFloat(data)
    data = data.toFixed(2)

    let updated_number = 0.0
    let left_portion = parseFloat((data + "").split(".")[0])
    let decimal_portion = parseFloat((data + "").split(".")[1])

    if (left_portion >= 0 && left_portion <= 30) {
      if (decimal_portion > 0 && decimal_portion < 25) {
        updated_number = left_portion.toString() + ".25"
      } else if (decimal_portion > 25 && decimal_portion <= 49) {
        updated_number = left_portion.toString() + ".50"
      } else if (decimal_portion > 50 && decimal_portion <= 74) {
        updated_number = left_portion.toString() + ".75"
      } else if (decimal_portion > 75 && decimal_portion <= 99) {
        updated_number = (left_portion + 1).toFixed(2)
      } else {
        updated_number = left_portion.toFixed(2)
      }
    } else if (left_portion >= 30 && left_portion <= 50) {
      if (decimal_portion > 0 && decimal_portion < 50) {
        updated_number = left_portion.toString() + ".50"
      } else {
        updated_number = (left_portion + 1).toFixed(2)
      }
    } else {
      if (decimal_portion > 0) {
        updated_number = (left_portion + 1).toFixed(2)
      } else {
        updated_number = left_portion.toFixed(2)
      }
    }
    updated_number = parseFloat(updated_number).toFixed(2)
    return updated_number
  }

  function yearly_purchase_fsc(e) {
    let purchase_price_fsc = isNaN(e.target.value) ? 0 : e.target.value
    let optionValue = purchase_price_fsc * details.yearly_forecast
    let sales_price_fsc = purchase_price_fsc / defaultMargin
    let yearly_sales_fsc = sales_price_fsc * details.yearly_forecast

    yearly_sales_fsc = yearly_sales_fsc.toFixed(2)
    sales_price_fsc = sales_price_fsc.toFixed(2)

    sales_price_fsc = updateDecimalPortion(sales_price_fsc)
    let sales_margin_fsc =
      ((sales_price_fsc - purchase_price_fsc) / purchase_price_fsc) * 100

    validation.setFieldValue(
      "yearly_purchase_fsc",
      parseFloat(optionValue).toFixed(2)
    )
    validation.setFieldValue(
      "sales_price_fsc",
      parseFloat(sales_price_fsc).toFixed(2)
    )
    validation.setFieldValue(
      "sales_margin_fsc",
      parseFloat(sales_margin_fsc).toFixed(1)
    )
    validation.setFieldValue(
      "yearly_sales_fsc",
      parseFloat(yearly_sales_fsc).toFixed(2)
    )
  }

  function yearly_sales_fsc(e) {
    let number = isNaN(e.target.value) ? 0 : e.target.value
    let optionValue = number * details.yearly_forecast
    validation.setFieldValue("yearly_sales_fsc", optionValue)
    sales_margin_fsc()
  }

  function sales_margin_fsc() {
    let purchase_price_fsc = document.getElementsByClassName(
      "purchase_price_fsc"
    )[0].value
      ? parseFloat(
        document.getElementsByClassName("purchase_price_fsc")[0].value
      )
      : 0
    let sales_price_fsc = document.getElementsByClassName("sales_price_fsc")[0]
      .value
      ? parseFloat(document.getElementsByClassName("sales_price_fsc")[0].value)
      : 0
    if (sales_price_fsc > 0) {
      let sales_margin_fsc =
        ((sales_price_fsc - purchase_price_fsc) / sales_price_fsc) * 100
      validation.setFieldValue("sales_margin_fsc", sales_margin_fsc.toFixed(1))
    }
  }

  function yearly_purchase_non_fsc(e) {
    let purchase_price_non_fsc = isNaN(e.target.value) ? 0 : e.target.value
    let optionValue = purchase_price_non_fsc * details.yearly_forecast
    validation.setFieldValue("yearly_purchase_non_fsc", optionValue)
    let sales_price_non_fsc = purchase_price_non_fsc / defaultMargin

    sales_price_non_fsc = updateDecimalPortion(sales_price_non_fsc)
    let sales_margin_non_fsc =
      ((sales_price_non_fsc - purchase_price_non_fsc) /
        purchase_price_non_fsc) *
      100
    let yearly_sales_non_fsc = sales_margin_non_fsc * details.yearly_forecast
    yearly_sales_non_fsc = yearly_sales_non_fsc.toFixed(2)

    validation.setFieldValue(
      "yearly_purchase_non_fsc",
      parseFloat(optionValue).toFixed(2)
    )
    validation.setFieldValue(
      "sales_price_non_fsc",
      parseFloat(sales_price_non_fsc).toFixed(2)
    )
    validation.setFieldValue(
      "sales_margin_non_fsc",
      parseFloat(sales_margin_non_fsc).toFixed(1)
    )
    validation.setFieldValue(
      "yearly_sales_non_fsc",
      parseFloat(yearly_sales_non_fsc).toFixed(2)
    )
  }

  function yearly_sales_non_fsc(e) {
    let number = isNaN(e.target.value) ? 0 : e.target.value
    let optionValue = number * details.yearly_forecast
    validation.setFieldValue("yearly_sales_non_fsc", optionValue)
    sales_margin_non_fsc()
  }

  function sales_margin_non_fsc() {
    let purchase_price_fsc = document.getElementsByClassName(
      "purchase_price_non_fsc"
    )[0].value
      ? parseFloat(
        document.getElementsByClassName("purchase_price_non_fsc")[0].value
      )
      : 0
    let sales_price_fsc = document.getElementsByClassName(
      "sales_price_non_fsc"
    )[0].value
      ? parseFloat(
        document.getElementsByClassName("sales_price_non_fsc")[0].value
      )
      : 0
    if (sales_price_fsc > 0) {
      let sales_margin_non_fsc =
        ((sales_price_fsc - purchase_price_fsc) / sales_price_fsc) * 100
      validation.setFieldValue(
        "sales_margin_non_fsc",
        sales_margin_non_fsc.toFixed(1)
      )
    }
  }

  const bdOffer = useSelector(state => state.bdOffer) // from reducer.
  const { error, msg } = useSelector(state => ({
    error: state.bdOffer.error,
    msg: state.bdOffer.msg ? state.bdOffer.msg : "",
  }))

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Offers" breadcrumbItem="Add Offer" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {msg || error ? (
                    <ToastContainer className="Toastify__close-button" />
                  ) : (
                    ""
                  )}
                  {prod_no ? (
                    <h4 className="card-title mb-4">
                      Product Number :- {prod_no}
                    </h4>
                  ) : (
                    ""
                  )}
                  <Form
                    id="addOfferForm"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === 1,
                              })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">01</span> Offer Details
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 2,
                              })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number ms-2">02</span> Offer
                              Price
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 3,
                              })}
                              onClick={() => {
                                setactiveTab(3)
                              }}
                              disabled={!(passedSteps || []).includes(3)}
                            >
                              <span className="number">03</span> Offer Packing
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 4 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 4,
                              })}
                              onClick={() => {
                                setactiveTab(4)
                              }}
                              disabled={!(passedSteps || []).includes(4)}
                            >
                              <span className="number">04</span> Sample Details
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix mt-4 addformsection">
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId={1}>
                            {/* <Form> */}
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-offer-status">
                                    Offer Status{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.offer_status_id &&
                                        validation.errors.offer_status_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="offer_status_id"
                                    id="add-offer-offer-status"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.offer_status_id || ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {bdOfferStatus &&
                                      bdOfferStatus.map(
                                        (bdOfferStatus, ofs) => (
                                          <option
                                            key={"ofs" + ofs}
                                            value={bdOfferStatus.id}
                                          >
                                            {bdOfferStatus.offer_status}
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {validation.touched.offer_status_id &&
                                    validation.errors.offer_status_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.offer_status_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-Vendor-article-number">
                                    Vendor Article Number
                                  </Label>
                                  <Input
                                    name="vendor_article_no"
                                    type="text"
                                    className={
                                      validation.touched.vendor_article_no &&
                                        validation.errors.vendor_article_no
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="add-offer-Vendor-article-number"
                                    placeholder="Enter vender article number"
                                    onChange={e => {
                                      validation.handleChange(e)
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.vendor_article_no || ""
                                    }
                                  />
                                  {validation.touched.vendor_article_no &&
                                    validation.errors.vendor_article_no ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.vendor_article_no}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-supplier-id">
                                    Supplier Name{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.supplier_id &&
                                        validation.errors.supplier_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="supplier_id"
                                    id="add-offer-supplier-id"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.supplier_id || ""}
                                  >
                                    <option value="">Choose...</option>
                                    {bdSupplierName &&
                                      bdSupplierName.map(
                                        (bdSupplierName, snk) => (
                                          <option
                                            key={"snk" + snk}
                                            value={bdSupplierName.id}
                                          >
                                            {bdSupplierName.name}
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {validation.touched.supplier_id &&
                                    validation.errors.supplier_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.supplier_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-production-input1">
                                    Production Time{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="production_time"
                                    type="number"
                                    className={
                                      validation.touched.production_time &&
                                        validation.errors.production_time
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="basicpill-production-input1"
                                    placeholder="Enter production time in number of days"
                                    onChange={e => {
                                      validation.handleChange(e)
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.production_time || ""
                                    }
                                  />
                                  {validation.touched.production_time &&
                                    validation.errors.production_time ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.production_time}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-incoterm-city">
                                    Incoterm City (port){" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.incoterm_city_id &&
                                        validation.errors.incoterm_city_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="incoterm_city_id"
                                    id="add-offer-incoterm-city"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.incoterm_city_id || ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {bdIncotermCity &&
                                      bdIncotermCity.map(
                                        (bdIncotermCity, icc) => (
                                          <option
                                            key={"icc" + icc}
                                            value={bdIncotermCity.id}
                                          >
                                            {bdIncotermCity.city}
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {validation.touched.incoterm_city_id &&
                                    validation.errors.incoterm_city_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.incoterm_city_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-material-standard">
                                    Material Standard{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.material_standard_id &&
                                        validation.errors.material_standard_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="material_standard_id"
                                    id="add-offer-material-standard"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.material_standard_id ||
                                      ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {bdMaterialStandard &&
                                      bdMaterialStandard.map(
                                        (bdMaterialStandard, ms) => (
                                          <option
                                            key={"ms" + ms}
                                            value={bdMaterialStandard.id}
                                          >
                                            {
                                              bdMaterialStandard.material_standard
                                            }
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {validation.touched.material_standard_id &&
                                    validation.errors.material_standard_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.material_standard_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-packing-standard">
                                    Packing Standard{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.packing_standard_id &&
                                        validation.errors.packing_standard_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="packing_standard_id"
                                    id="add-offer-packing-standard"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.packing_standard_id ||
                                      ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {bdPackingStandard &&
                                      bdPackingStandard.map(
                                        (bdPackingStandard, ps) => (
                                          <option
                                            key={"ps" + ps}
                                            value={bdPackingStandard.id}
                                          >
                                            {bdPackingStandard.packing_standard}
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {validation.touched.packing_standard_id &&
                                    validation.errors.packing_standard_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.packing_standard_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-env&hlth-certification">
                                    Environmental and Health Certification{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.env_health_id &&
                                        validation.errors.env_health_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="env_health_id"
                                    id="add-offer-env&hlth-certification"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.env_health_id || ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {bdEnvironmentalAndHealthCertification &&
                                      bdEnvironmentalAndHealthCertification.map(
                                        (
                                          bdEnvironmentalAndHealthCertification,
                                          enhc
                                        ) => (
                                          <option
                                            key={"enhc" + enhc}
                                            value={
                                              bdEnvironmentalAndHealthCertification.id
                                            }
                                          >
                                            {
                                              bdEnvironmentalAndHealthCertification.certs
                                            }
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {validation.touched.env_health_id &&
                                    validation.errors.env_health_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.env_health_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-compliance-input1">
                                    NON-compliance
                                  </Label>
                                  <textarea
                                    name="non_compliance"
                                    id="basicpill-compliance-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.non_compliance || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-comments-input1">
                                    SBN comments
                                  </Label>
                                  <textarea
                                    name="sbn_comments"
                                    id="basicpill-comments-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.sbn_comments || ""}
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-quality-input1">
                                    Quality Control
                                  </Label>
                                  <textarea
                                    name="quality_control"
                                    id="basicpill-quality-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.quality_control || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-color-input1">
                                    Color code and Gloss
                                  </Label>
                                  <textarea
                                    name="color_code_gloss"
                                    id="basicpill-color-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.color_code_gloss || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {/* </Form> */}
                          </TabPane>
                          <TabPane tabId={2}>
                            <Row>
                              {/* <Form> */}
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-price-input5">
                                    Purchase Price (FSC)
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="purchase_price_fsc"
                                    type="text"
                                    autoComplete="off"
                                    className={
                                      validation.touched.purchase_price_fsc &&
                                        validation.errors.purchase_price_fsc
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="basicpill-price-input5"
                                    placeholder="Enter purchase price (FSC)."
                                    onChange={e => (
                                      validation.handleChange(e),
                                      yearly_purchase_fsc(e)
                                    )}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.purchase_price_fsc || ""
                                    }
                                  />
                                  {validation.touched.purchase_price_fsc &&
                                    validation.errors.purchase_price_fsc ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.purchase_price_fsc}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-Yearly-input6">
                                    Yearly Purchase (FSC)
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="yearly_purchase_fsc"
                                    type="text"
                                    autoComplete="off"
                                    readOnly={true}
                                    disabled
                                    className={
                                      validation.touched.yearly_purchase_fsc &&
                                        validation.errors.yearly_purchase_fsc
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="basicpill-year-input6"
                                    placeholder="Enter yearly price (FSC)"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.yearly_purchase_fsc ||
                                      ""
                                    }
                                  />
                                  {validation.touched.yearly_purchase_fsc &&
                                    validation.errors.yearly_purchase_fsc ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.yearly_purchase_fsc}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-purchase-price-input10">
                                    Purchase Price (NON FSC)
                                  </Label>
                                  <Input
                                    name="purchase_price_non_fsc"
                                    type="text"
                                    autoComplete="off"
                                    className="form-control"
                                    id="basicpill-purchase-price-input10"
                                    placeholder="Enter purchase price (NON FSC)"
                                    onChange={e => {
                                      validation.handleChange(e)
                                      yearly_purchase_non_fsc(e)
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .purchase_price_non_fsc || ""
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-yearly-price-non-input10">
                                    Yearly Purchase (NON FSC)
                                  </Label>
                                  <Input
                                    name="yearly_purchase_non_fsc"
                                    type="text"
                                    autoComplete="off"
                                    readOnly={true}
                                    disabled
                                    className="form-control"
                                    id="basicpill-yearly-price-non-input10"
                                    placeholder="Enter yearly price (NON FSC)"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .yearly_purchase_non_fsc || ""
                                    }
                                  />
                                </div>
                              </Col>
                              <Col className="mt-2" lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-com-input1">
                                    Purchasing Price Comments
                                  </Label>
                                  <textarea
                                    name="purchasing_price_comments"
                                    id="basicpill-com-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .purchasing_price_comments || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId={3}>
                            <Row>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-truck-input11">
                                    Full Truck/40HC
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="full_truck"
                                    type="text"
                                    className={
                                      validation.touched.full_truck &&
                                        validation.errors.full_truck
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="basicpill-truck-input11"
                                    placeholder="Enter Full Truck/40HC"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.full_truck || ""}
                                  />
                                  {validation.touched.full_truck &&
                                    validation.errors.full_truck ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.full_truck}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-20st-input11">
                                    20ST Container
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="container"
                                    type="text"
                                    className={
                                      validation.touched.container &&
                                        validation.errors.container
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="basicpill-20st-input11"
                                    placeholder="Enter 20ST Container"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.container || ""}
                                  />
                                  {validation.touched.container &&
                                    validation.errors.container ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.container}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-moq-input11">
                                    MOQ
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="moq"
                                    type="text"
                                    className={
                                      validation.touched.moq &&
                                        validation.errors.moq
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="basicpill-moq-input11"
                                    placeholder="Enter MOQ"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.moq || ""}
                                  />
                                  {validation.touched.moq &&
                                    validation.errors.moq ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.moq}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-net-input11">
                                    Net Weight
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="net_weight"
                                    type="text"
                                    className={
                                      validation.touched.net_weight &&
                                        validation.errors.net_weight
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="basicpill-net-input11"
                                    placeholder="Enter net weight"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.net_weight || ""}
                                  />
                                  {validation.touched.net_weight &&
                                    validation.errors.net_weight ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.net_weight}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-gross-input12">
                                    Gross Weight
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="gross_weight"
                                    type="text"
                                    className={
                                      validation.touched.gross_weight &&
                                        validation.errors.gross_weight
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="basicpill-gross-input12"
                                    placeholder="Enter gross weight"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.gross_weight || ""}
                                  />
                                  {validation.touched.gross_weight &&
                                    validation.errors.gross_weight ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.gross_weight}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-packaging">
                                    Packaging{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.packaging_id &&
                                        validation.errors.packaging_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="packaging_id"
                                    id="add-offer-packaging"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.packaging_id || ""}
                                  >
                                    <option value="">Choose...</option>
                                    {bdPackaging &&
                                      bdPackaging.map((bdPackaging, pck) => (
                                        <option
                                          key={"pck" + pck}
                                          value={bdPackaging.id}
                                        >
                                          {bdPackaging.packaging}
                                        </option>
                                      ))}
                                  </select>
                                  {validation.touched.packaging_id &&
                                    validation.errors.packaging_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.packaging_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-delivered-on">
                                    Delivered On{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.delivered_on &&
                                        validation.errors.delivered_on
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="delivered_on"
                                    id="add-offer-delivered-on"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.delivered_on || ""}
                                  >
                                    <option value="">Choose...</option>
                                    {bdDeliveredOn &&
                                      bdDeliveredOn.map((bdDeliveredOn, dn) => (
                                        <option
                                          key={"dn" + dn}
                                          value={bdDeliveredOn.id}
                                        >
                                          {bdDeliveredOn.delivery_name}
                                        </option>
                                      ))}
                                  </select>
                                  {validation.touched.delivered_on &&
                                    validation.errors.delivered_on ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.delivered_on}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-heavey-colli">
                                    Heavey Colli{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    className={
                                      validation.touched.heavy_colli_id &&
                                        validation.errors.heavy_colli_id
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="heavy_colli_id"
                                    id="add-offer-heavey-colli"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.heavy_colli_id || ""
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {bdHeaveyColli &&
                                      bdHeaveyColli.map((bdHeaveyColli, hc) => (
                                        <option
                                          key={"hc" + hc}
                                          value={bdHeaveyColli.id}
                                        >
                                          {bdHeaveyColli.heavy_collis}
                                        </option>
                                      ))}
                                  </select>
                                  {validation.touched.heavy_colli_id &&
                                    validation.errors.heavy_colli_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.heavy_colli_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-npb-input12">
                                    Number of Units per Box
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="units_per_box"
                                    type="text"
                                    className={
                                      validation.touched.units_per_box &&
                                        validation.errors.units_per_box
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="basicpil-npb-input12"
                                    placeholder="Enter number of unit per box"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.units_per_box || ""
                                    }
                                  />
                                  {validation.touched.units_per_box &&
                                    validation.errors.units_per_box ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.units_per_box}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-bom-input12">
                                    BOM per article
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="bom_per_article"
                                    type="text"
                                    className={
                                      validation.touched.bom_per_article &&
                                        validation.errors.bom_per_article
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="basicpill-bom-input12"
                                    placeholder="Enter BOM per article"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.bom_per_article || ""
                                    }
                                  />
                                  {validation.touched.bom_per_article &&
                                    validation.errors.bom_per_article ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.bom_per_article}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-mcb-input12">
                                    Master Cardboard Box
                                  </Label>
                                  <Input
                                    name="master_cardboard_box"
                                    type="text"
                                    className="form-control"
                                    id="basicpill-mcb-input12"
                                    placeholder="Enter master cardboard box"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.master_cardboard_box ||
                                      ""
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-pc-input1">
                                    Packing comments
                                  </Label>
                                  <textarea
                                    name="packing_comments"
                                    id="basicpill-pc-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.packing_comments || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId={4}>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="add-offer-qa_user_id">
                                    QA Users
                                  </Label>
                                  <select
                                    className="form-control"
                                    name="qa_user_id"
                                    id="add-offer-qa_user_id"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.qa_user_id || ""}
                                  >
                                    <option value="">Choose...</option>
                                    {bdUserListAccordingRole.length > 0 &&
                                      bdUserListAccordingRole.map(
                                        (bdUserListAccordingRole, ut) => (
                                          <option
                                            key={"ut" + ut}
                                            value={
                                              bdUserListAccordingRole.user_id
                                            }
                                          >
                                            {bdUserListAccordingRole.user_name}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-sr-input1">
                                    Sample Request
                                  </Label>
                                  <textarea
                                    name="sample_request"
                                    id="basicpill-sr-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.sample_request || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-sm-input1">
                                    Sample comments
                                  </Label>
                                  <textarea
                                    name="sample_comments"
                                    id="basicpill-sm-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter sample comments"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.sample_comments || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-ss-input1">
                                    Sample status
                                  </Label>
                                  <textarea
                                    name="sample_status"
                                    id="basicpill-ss-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.sample_status || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-qa-input1">
                                    QA Sample Checking comments
                                  </Label>
                                  <textarea
                                    name="qa_sample_checking_comments"
                                    id="basicpill-qa-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .qa_sample_checking_comments || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-sc-input1">
                                    Sales sample checking comments
                                  </Label>
                                  <textarea
                                    name="sales_sample_checking_comments"
                                    id="basicpill-sc-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .sales_sample_checking_comments || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-sf-input1">
                                    Sample freight cost
                                  </Label>
                                  <textarea
                                    name="sample_freight_cost"
                                    id="basicpill-sf-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.sample_freight_cost ||
                                      ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-smm-input1">
                                    Sample main material
                                  </Label>
                                  <textarea
                                    name="sample_main_material"
                                    id="basicpill-smm-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.sample_main_material ||
                                      ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-sh-input1">
                                    Sample hardware
                                  </Label>
                                  <textarea
                                    name="sample_hardware"
                                    id="basicpill-sh-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.sample_hardware || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label htmlFor="basicpill-sst-input1">
                                    Sample surface treatment
                                  </Label>
                                  <textarea
                                    name="sample_surface_treatment"
                                    id="basicpill-sst-input1"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .sample_surface_treatment || ""
                                    }
                                    onKeyDown={e => {
                                      e.key === "Enter" && e.preventDefault()
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab - 1)
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                          <li className={activeTab !== 4 ? "next" : "next"}>
                            {activeTab !== 4 ? (
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                              >
                                Next
                              </Link>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                                disabled={
                                  bdOffer && bdOffer.loading ? true : false
                                }
                              >
                                {bdOffer && bdOffer.loading
                                  ? "Adding Offer"
                                  : "Add Offer"}
                              </button>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Form>
                  <section className="error-message">
                    {errorsArr && errorsArr.length
                      ? errorsArr.map((er, ei) => {
                        if (er.errorPresent) {
                          return (
                            <ul key={`ei_` + ei}>
                              <li className="text-danger">
                                <b>{er.section}</b>
                              </li>
                              <ul>
                                {er.children.map((ce, ci) => {
                                  if (ce.errorMessage) {
                                    return (
                                      <li
                                        key={`ce_` + ci}
                                        className="text-danger"
                                      >
                                        {ce.errorMessage}
                                      </li>
                                    )
                                  }
                                })}
                              </ul>
                            </ul>
                          )
                        }
                      })
                      : null}
                  </section>

                  {/* below code will be useful when we do not need the validation section.*/}
                  {/* {Object.keys(validation.errors).some(
                    key => validation.touched[key] && !validation.values[key]
                  ) && (
                    <div className="text-danger">
                      <ul>
                        {Object.keys(validation.errors).map((key, index) => {
                          if (
                            validation.touched[key] &&
                            !validation.values[key]
                          ) {
                            return (
                              <li key={index}>{validation.errors[key]}.</li>
                            )
                          }
                          return null
                        })}
                      </ul>
                    </div>
                  )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BusinessDeveloperAddOffer
