import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import React, { useState, useRef, useEffect, useMemo, useCallback } from "react"
import BtnCellRenderer from "./BtnCellRenderer.jsx"
import ActiveInactive from "./ActiveInactive.jsx"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  Row,
  Col,
  Container,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import {
  usersListing,
  changeUserStatus,
  resetUserPassword,
} from "../../../store/actions"

const UserList = props => {
  const dispatch = useDispatch()
  document.title = "Users List"

  let key = `${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`
  LicenseManager.setLicenseKey(key)

  const gridRef = useRef()
  const [rowData, setRowData] = useState("")
  const [modalOpen, setModalOpen] = useState(false)
  const [currentUser, setCurrentUser] = useState(undefined)
  const [usersData, setUsersData] = useState([])

  useEffect(() => {
    dispatch(usersListing())
  }, [])

  const columnDefination = [
    {
      field: "action",
      cellRenderer: "BtnCellRenderer",
      cellRendererSelector: params => {
        const buttons = {
          component: BtnCellRenderer,
          params: {
            updateModalState: updateModalState,
          },
        }
        if (params) return buttons
        else return undefined
      },
    },
    {
      headerName: "Status",
      field: "user_status",
      cellRenderer: "ActiveInactive",
      cellRendererSelector: params => {
        const buttons = {
          component: ActiveInactive,
          params: {
            callChangeUserStatus: callChangeUserStatus,
          },
        }
        if (params) return buttons
        else return undefined
      },
    },
    { headerName: "Name", field: "name" },
    {
      headerName: "Short Name",
      field: "short_name",
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "Role",
      field: "role",
    },
    {
      headerName: "Short Role",
      field: "role_short",
    },
    {
      headerName: "Office",
      field: "office",
    },
  ]
  const [columnDefs, setColumnDefs] = useState(columnDefination)

  function callChangeUserStatus(id) {
    dispatch(changeUserStatus(id))
  }

  function resetPasswordConfirmation(id) {
    dispatch(resetUserPassword(id))
    updateModalState()
  }

  function updateModalState(id = null) {
    setModalOpen(!modalOpen)
    if (id) {
      setCurrentUser(id)
    }
  }

  const showAgGridLoading = useCallback(() => {
    if (gridRef.current.api) {
      gridRef.current.api.showLoadingOverlay()
    }
  }, [])

  const hideAgGridLoading = useCallback(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.hideOverlay()
    }
  }, [])

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      filter: true,
      minWidth: 180,
      resizable: true,
    }),
    []
  )

  let userList = useSelector(state => state.User.user)
  useEffect(() => {
    if (userList && userList.length > 0) {
      setUsersData(userList)
      if (columnDefination && usersData && usersData.length > 0) {
        gridRef.current.api.setColumnDefs(columnDefination)
      }
    }
  }, [userList])

  const error = useSelector(state => state.User.error)
  const loading = useSelector(state => state.User.loading)
  const msg = useSelector(state => state.User.msg)

  useEffect(() => {
    setRowData(userList)
  }, [userList])

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    } else if (msg) {
      toast.dismiss()
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error, msg])

  if (loading) {
    showAgGridLoading()
  } else {
    hideAgGridLoading()
  }

  //pagination
  //  const [gridApi, setGridApi] = useState(null);

  //  const datasource = {
  //   getRows(params) {
  //     console.log(JSON.stringify(params.request, null, 1));
  //     const { startRow, endRow } = params.request
  //     let url = `https://iksen.com/claus_product_management/public/api/admin/get-user`

  //     //Pagination
  //     url = `_start=${startRow}&_end=${endRow}`
  //     dispatch(url)
  //       .then(httpResponse => httpResponse.json())
  //       .then(response => {
  //         params.successCallback(response, 1000);
  //       })
  //       .catch(error => {
  //         console.error(error);
  //         params.failCallback();
  //       })
  //   }
  // };

  // const onGridReady = (params) => {
  //   setGridApi(params);
  //   // register datasource with the grid
  //   params.api.setServerSideDatasource(datasource);
  // }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container fluid={true}>
          <Row>
            <Col md={12} lg={12} xl={12}>
              {error ? <ToastContainer /> : ""}
              {msg ? <ToastContainer /> : ""}
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: "80vh" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={usersData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  animateRows={true}
                  pagination={true}
                  paginationPageSize={20}
                  // rowModelType={'infinite'}
                  // domLayout="autoHeight"
                  // rowModelType="serverSide"
                  // onGridReady={onGridReady}
                  // rowHeight={60}
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center">Please wait while we are fetching data</span>'
                  }
                />
              </div>
            </Col>
          </Row>
          <Modal
            isOpen={modalOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="deleteUserConfirm"
            tabIndex="-1"
            backdrop={false}
            toggle={() => updateModalState()}
          >
            <ModalHeader>Reset Password</ModalHeader>
            <ModalBody>Do you really want to reset user password?</ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => resetPasswordConfirmation(currentUser)}
              >
                Yes
              </Button>{" "}
              <Button onClick={() => updateModalState()}>No</Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserList
