import React from "react"
import { Badge } from "reactstrap"
import "./products.css"

function ActiveInactive(props) {
  let status = props.data.status ? 1 : 0
  return (
    <>
      {status ? (
        <Badge
          color="success"
          className="cursor-pointer"
          pill
          onClick={() => {
            props.callChangeSupplierStatus(props.data.supplier_id)
          }}
        >
          Active
        </Badge>
      ) : (
        <Badge
          className="cursor-pointer"
          color="danger"
          pill
          onClick={() => {
            props.callChangeSupplierStatus(props.data.supplier_id)
          }}
        >
          Inactive
        </Badge>
      )}
    </>
  )
}

export default ActiveInactive
