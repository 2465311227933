import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { useHistory } from "react-router-dom"
// offer Redux States
import {
  CO_OFFER_ADD,
  CO_GET_OFFERS_LISTING,
  CO_OFFER_DELETE,
  CO_OFFER_EDIT,
  CO_OFFER_UPDATE,
  CO_CHANGE_OFFER_STATUS,
  CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
  CO_RESET_OFFER
} from "./actionTypes"
import {
  coOfferSuccess,
  coOfferFail,
  coOffersListingSuccess,
  coOffersListingFail,
  coDeleteOfferSuccess,
  coDeleteOfferFail,
  coEditOfferSuccess,
  coEditOfferFail,
  coUpdateOfferSuccess,
  coUpdateOfferFail,
  coChangeOfferStatusSuccess,
  coChangeOfferStatusFail,
  coSearchItemInNewOfferPageSuccess,
  coSearchItemInNewOfferPageFail,
  coClearOfferResultSuccess,
  coClearOfferResultError
} from "./actions"

import {
  coAddOfferApi,
  coOffersListingApi,
  coDeleteOfferApi,
  coEditOfferApi,
  coUpdateOfferApi,
  coChangeOfferStatusApi,
  coSearchItemInNewOfferPageApi,
} from "../../../helpers/fakebackend_helper"

function* coAddOffer({ payload: { offer, history } }) {
  try {
    const response = yield call(coAddOfferApi, offer)

    if (response && response.status) {
      yield put(coOfferSuccess(response))
      setTimeout(() => {
        history.push("/offers/list")
      }, 5000)
    } else {
      throw response.msg ? response.msg : "Failed to add offer"
    }
  } catch (error) {
    yield put(coOfferFail(error))
  }
}

function* coGetOfferListing() {
  try {
    const response = yield call(coOffersListingApi)
    if (
      response.results === undefined ||
      response.results.prod_offers.length == 0
    ) {
      throw response.msg ? response.msg : "No data found"
    }
    yield put(coOffersListingSuccess(response.results.prod_offers))
  } catch (error) {
    yield put(coOffersListingFail(error))
  }
}

function* coOnDeleteOffer({ payload: { id } }) {
  try {
    const response = yield call(coDeleteOfferApi, { offer_id: id })
    if (response && response.status) {
      response.id = id
      yield put(coDeleteOfferSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to delete offer"
    }
  } catch (error) {
    yield put(coDeleteOfferFail(error))
  }
}

function* coGetOfferDetailsById({ payload: { id } }) {
  try {
    const response = yield call(coEditOfferApi, id)
    if (response && response.status) {
      response.id = id
      yield put(coEditOfferSuccess(response.results.offer_details))
    } else {
      throw response.msg ? response.msg : "Failed to fetch offer details"
    }
  } catch (error) {
    yield put(coEditOfferFail(error))
  }
}

function* coOnUpdateOffer({ payload: { offer, history } }) {
  try {
    const response = yield call(coUpdateOfferApi, offer)
    if (response && response.status) {
      yield put(coUpdateOfferSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update offer"
    }
  } catch (error) {
    yield put(coUpdateOfferFail(error))
  }
}

function* coOnOfferStatusChange({ payload: { id } }) {
  try {
    const response = yield call(coChangeOfferStatusApi, { offer_id: id })
    if (response.status) {
      response.id = id
      yield put(coChangeOfferStatusSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to delete offer"
    }
  } catch (error) {
    yield put(coChangeOfferStatusFail(error))
  }
}

function* coSearchAtNewOfferPage({ payload: { search } }) {
  try {
    const response = yield call(coSearchItemInNewOfferPageApi, search)

    if (response && response.status && response?.results?.prod_offers) {
      yield put(coSearchItemInNewOfferPageSuccess(response.results.prod_offers))
    } else {
      throw response.msg ? response.msg : "No Record found"
    }
  } catch (error) {
    yield put(coSearchItemInNewOfferPageFail(error))
  }
}

function* coClearOfferResults() {
  try {
    yield put(coClearOfferResultSuccess())
  } catch (error) {
    yield put(coClearOfferResultError(error))
  }
}

function* coOfferSaga() {
  yield takeEvery(CO_OFFER_ADD, coAddOffer)
  yield takeEvery(CO_GET_OFFERS_LISTING, coGetOfferListing)
  yield takeEvery(CO_OFFER_DELETE, coOnDeleteOffer)
  yield takeEvery(CO_OFFER_EDIT, coGetOfferDetailsById)
  yield takeEvery(CO_OFFER_UPDATE, coOnUpdateOffer)
  yield takeEvery(CO_CHANGE_OFFER_STATUS, coOnOfferStatusChange)
  yield takeEvery(CO_SEARCH_ITEM_IN_NEW_OFFER_PAGE, coSearchAtNewOfferPage)
  yield takeEvery(CO_RESET_OFFER, coClearOfferResults)
}
export default coOfferSaga
