import React from "react"
import { Redirect } from "react-router-dom"

let userType = ""
const currentUser = JSON.parse(sessionStorage.getItem("authUser"))

let authProtectedRoutes = [{ path: "/dashboard", component: Dashboard }]
if (currentUser && currentUser.accessToken && currentUser.user_role_short) {
  userType = currentUser.user_role_short
}
// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// dashboard
import Dashboard from "../pages/Dashboard/index"

//admin
import ProductList from "pages/Products/admin/ProductList"
import AddProduct from "pages/Products/admin/AddProduct"
import EditProduct from "pages/Products/admin/EditProduct"
import AddUser from "../pages/User/admin/AddUser"
import UserList from "pages/User/admin/UserList"
import LogList from "pages/User/admin/Logs/LogList"
import OfferList from "pages/OfferDetails/admin/OfferList"
import AddOffer from "pages/OfferDetails/admin/AddOffer"
import EditOffer from "pages/OfferDetails/admin/EditOffer"
import AddOfferNew from "pages/OfferDetails/admin/AddOfferNew"
import GetOfferDetailForUpdate from "pages/OfferDetails/admin/GetOfferDetailForUpdate"
import Collections from "pages/Exports/admin/collections"
import AddCollection from "pages/Exports/admin/add_collection"
import EditCollection from "pages/Exports/admin/edit_collection"
import AddMoreDataToCollection from "pages/Exports/admin/add_more_data_to_collection"
import ExportDownload from "pages/Exports/admin/ExportDownload"
import Downloads from "pages/Exports/admin/download"
import EditUserRole from "pages/User/admin/editUserRole"

//bd
import BusinessDeveloperProductList from "pages/Products/business-developer/bd-ProductList"
import BusinessDeveloperAddOffer from "pages/OfferDetails/business-developer/bd-AddOffer"
import BusinessDeveloperOfferList from "pages/OfferDetails/business-developer/bd-OfferList"
import BusinessDeveloperEditOffer from "pages/OfferDetails/business-developer/bd-EditOffer"
import BusinessDeveloperAddOfferNew from "pages/OfferDetails/business-developer/bd-AddOfferNew"
import BusinessDeveloperGetOfferDetailForUpdate from "pages/OfferDetails/business-developer/bd-GetOfferDetailForUpdate"
import BdAddCollection from "pages/Exports/business-developer/bd-add_collections"
import BdCollections from "pages/Exports/business-developer/bd-collections"
import BdEditCollection from "pages/Exports/business-developer/bd-edit_collection"
import BdAddMoreDataToCollection from "pages/Exports/business-developer/bd-add_more_data_to_collection"
import BdDownloads from "pages/Exports/business-developer/bd-download"
import BdExportDownload from "pages/Exports/business-developer/bd-ExportDownload"
import BdEditProduct from "pages/Products/business-developer/bd-EditProduct"

// CO
import CoAddProduct from "pages/Products/Co-ordinator/co-AddProduct"
import CoProductList from "pages/Products/Co-ordinator/co-ProductList"
import CoEditProduct from "pages/Products/Co-ordinator/co-EditProduct"
import CoAddOffer from "pages/OfferDetails/co-ordinator/co-AddOffer"
import CoOfferList from "pages/OfferDetails/co-ordinator/co-OfferList"
import CoEditOffer from "pages/OfferDetails/co-ordinator/co-EditOffer"
import COAddOfferNew from "../pages/OfferDetails/co-ordinator/co-AddOfferNew"
import COGetOfferDetailForUpdate from "pages/OfferDetails/co-ordinator/co-GetOfferDetailForUpdate"
import CoCollections from "pages/Exports/co-ordinator/co-collections"
import CoAddCollection from "pages/Exports/co-ordinator/co-add_collection"
import CoAddMoreDataToCollection from "pages/Exports/co-ordinator/co-add_more_data_to_collection"
import CoDownloads from "pages/Exports/co-ordinator/co-download"
import CoEditCollection from "pages/Exports/co-ordinator/co-edit_collection"
import CoExportDownload from "pages/Exports/co-ordinator/co-ExportDownload"

//sa
import SaProductList from "pages/Products/sales-manager/sa-ProductList"
import SaEditProduct from "pages/Products/sales-manager/sa-EditProduct"
import SaEditOffer from "pages/OfferDetails/sales-manager/sa-EditOffer"
import SaOfferList from "pages/OfferDetails/sales-manager/sa-OfferList"
import SaGetOfferDetailForUpdate from "pages/OfferDetails/sales-manager/sa-GetOfferDetailForUpdate"
import SaCollections from "pages/Exports/sales-manager/sa-collections"
import SaAddCollection from "pages/Exports/sales-manager/sa-add_collection"
import SaEditCollection from "pages/Exports/sales-manager/sa-edit_collection"
import SaAddMoreDataToCollection from "pages/Exports/sales-manager/sa-add_more_data_to_collection"
import SaExportDownload from "pages/Exports/sales-manager/sa-ExportDownload"
import SaDownloads from "pages/Exports/sales-manager/sa-download"

//qa
import QaProductList from "pages/Products/quality-assurance/qa-ProductList"
import QaEditProduct from "pages/Products/quality-assurance/qa-EditProduct"
import QaOfferList from "pages/OfferDetails/quality-assurance/qa-OfferList"
import QaEditOffer from "pages/OfferDetails/quality-assurance/qa-EditOffer"
import QaGetOfferDetailForUpdate from "pages/OfferDetails/quality-assurance/qa-GetOfferDetailForUpdate"
import QaCollections from "pages/Exports/quality-assurance/qa-collections"
import QaAddCollection from "pages/Exports/quality-assurance/qa-add_collection"
import QaEditCollection from "pages/Exports/quality-assurance/qa-edit_collection"
import QaAddMoreDataToCollection from "pages/Exports/quality-assurance/qa-add_more_data_to_collection"
import QaExportDownload from "pages/Exports/quality-assurance/qa-ExportDownload"
import QaDownloads from "pages/Exports/quality-assurance/qa-download"

// admin dropdown
import ListProductCategory from "pages/Dropdowns/Product-Category/ListProductCategory"
import AddProductCategory from "pages/Dropdowns/Product-Category/AddProductCategory"
import EditProductCategory from "pages/Dropdowns/Product-Category/EditProductCategory"
import AddQualityRating from "pages/Dropdowns/Quality-Rating/AddQualityRating"
import ListQualityRating from "pages/Dropdowns/Quality-Rating/ListQualityRating"
import EditQualityRating from "pages/Dropdowns/Quality-Rating/EditQualityRating"
import ListDeliveredOn from "pages/Dropdowns/Delivered-On/ListDeliveredOn"
import AddDeliveredOn from "pages/Dropdowns/Delivered-On/AddDeliveredOn"
import EditDeliveredOn from "pages/Dropdowns/Delivered-On/EditDeliveredOn"
import AddEnvCerts from "pages/Dropdowns/Env-Certificates/AddEnvCerts"
import ListEnvCerts from "pages/Dropdowns/Env-Certificates/ListEnvCerts"
import EditEnvCerts from "pages/Dropdowns/Env-Certificates/EditEnvCerts"
import AddHeavey from "pages/Dropdowns/Heavey-Colli/AddHeavey"
import ListHeavey from "pages/Dropdowns/Heavey-Colli/ListHeavey"
import EditHeavey from "pages/Dropdowns/Heavey-Colli/EditHeavey"
import ListIncoterm from "pages/Dropdowns/Incoterm-City/ListIncoterm"
import AddIncoterm from "pages/Dropdowns/Incoterm-City/AddIncoterm"
import EditIncoterm from "pages/Dropdowns/Incoterm-City/EditIncoterm"
import ListMaterial from "pages/Dropdowns/Material-Standard/ListMaterial"
import AddMaterialStandard from "pages/Dropdowns/Material-Standard/AddMaterialStandard"
import ListOfferStatus from "pages/Dropdowns/Offer-Status/ListOfferStatus"
import ListOffice from "pages/Dropdowns/Office/ListOffice"
import ListPackaging from "pages/Dropdowns/Packaging/ListPackaging"
import ListPackingStandard from "pages/Dropdowns/Packing-Standard/ListPackingStandard"
import ListRoles from "pages/Dropdowns/Roles/ListRoles"
import ListSupplier from "pages/Dropdowns/Supplier-Name/ListSupplier"
import AddSupplier from "pages/Dropdowns/Supplier-Name/AddSupplier"
import EditSupplier from "pages/Dropdowns/Supplier-Name/EditSupplier"
import ActivityLogList from "pages/User/admin/ActivityLogs/ActivityLogList"
import EditMaterial from "pages/Dropdowns/Material-Standard/EditMaterial"
import AddRole from "pages/Dropdowns/Roles/AddRole"
import EditRole from "pages/Dropdowns/Roles/EditRole"
import AddOfferStatus from "pages/Dropdowns/Offer-Status/AddOfferStatus"
import EditOfferStatus from "pages/Dropdowns/Offer-Status/EditOfferStatus"
import AddOffice from "pages/Dropdowns/Office/AddOffice"
import EditOffice from "pages/Dropdowns/Office/EditOffice"
import AddPackaging from "pages/Dropdowns/Packaging/AddPackaging"
import EditPackaging from "pages/Dropdowns/Packaging/EditPackaging"
import AddPackingStandard from "pages/Dropdowns/Packing-Standard/AddPackingStandard"
import EditPackingStandard from "pages/Dropdowns/Packing-Standard/EditPackingStandard"
import AddConstruction from "pages/Dropdowns/Construction/AddConstruction"
import EditConstruction from "pages/Dropdowns/Construction/EditConstruction"
import ListConstruction from "pages/Dropdowns/Construction/ListConstruction"

if (userType !== "" && userType == "AM") {
  authProtectedRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/products/list", component: ProductList },
    { path: "/products/edit/:id", component: EditProduct },
    { path: "/products/add", component: AddProduct },
    { path: "/users/add", component: AddUser },
    { path: "/users/list", component: UserList },
    { path: "/offers/add/:id/:prod_no", component: AddOffer },
    { path: "/offers/edit/:id/", component: EditOffer },
    { path: "/offers/list", component: OfferList },
    { path: "/offers/create/:id/:prod_no", component: AddOfferNew },
    { path: "/offers/edit", component: GetOfferDetailForUpdate },
    { path: "/log/list", component: LogList },
    { path: "/export/collection", component: Collections },
    { path: "/export/collection/add", component: AddCollection },
    { path: "/export/collection/edit/:id", component: EditCollection },
    {
      path: "/export/collection/add-more-to-collection/:id",
      component: AddMoreDataToCollection,
    },
    { path: "/export/add", component: ExportDownload },
    { path: "/export/download", component: Downloads },
    { path: "/profile", component: UserProfile },
    { path: "/users/edit/:id", component: EditUserRole },
    { path: "/dropdown/product_category/list", component: ListProductCategory },
    { path: "/dropdown/product_category/add", component: AddProductCategory },
    {
      path: "/dropdown/product_category/edit/:id",
      component: EditProductCategory,
    },
    { path: "/dropdown/quality_rating/add", component: AddQualityRating },
    { path: "/dropdown/quality_rating/list", component: ListQualityRating },
    { path: "/dropdown/quality_rating/edit/:id", component: EditQualityRating },
    { path: "/dropdown/delivered_on/list", component: ListDeliveredOn },
    { path: "/dropdown/delivered_on/add", component: AddDeliveredOn },
    { path: "/dropdown/delivered_on/edit/:id", component: EditDeliveredOn },
    { path: "/dropdown/env_health_certificate/add", component: AddEnvCerts },
    { path: "/dropdown/env_health_certificate/list", component: ListEnvCerts },
    {
      path: "/dropdown/env_health_certificate/edit/:id",
      component: EditEnvCerts,
    },
    { path: "/dropdown/heavey_colli/add", component: AddHeavey },
    { path: "/dropdown/heavey_colli/list", component: ListHeavey },
    { path: "/dropdown/heavey_colli/edit/:id", component: EditHeavey },
    { path: "/dropdown/incoterm_city/list", component: ListIncoterm },
    { path: "/dropdown/incoterm_city/add", component: AddIncoterm },
    { path: "/dropdown/incoterm_city/edit/:id", component: EditIncoterm },
    { path: "/dropdown/material_standard/list", component: ListMaterial },
    { path: "/dropdown/material_standard/add", component: AddMaterialStandard },
    { path: "/dropdown/offer_status/list", component: ListOfferStatus },
    { path: "/dropdown/office/list", component: ListOffice },
    { path: "/dropdown/packaging/list", component: ListPackaging },
    { path: "/dropdown/packing_standard/list", component: ListPackingStandard },
    { path: "/dropdown/user_role/list", component: ListRoles },
    { path: "/dropdown/user_role/add", component: AddRole },
    { path: "/dropdown/user_role/edit/:id", component: EditRole },
    { path: "/dropdown/supplier_name/list", component: ListSupplier },
    { path: "/dropdown/supplier_name/add", component: AddSupplier },
    { path: "/dropdown/supplier_name/edit/:id", component: EditSupplier },
    { path: "/log/activity", component: ActivityLogList },
    { path: "/dropdown/material_standard/edit/:id", component: EditMaterial },
    { path: "/dropdown/offer_status/add", component: AddOfferStatus },
    { path: "/dropdown/offer_status/edit/:id", component: EditOfferStatus },
    { path: "/dropdown/office/add", component: AddOffice },
    { path: "/dropdown/office/edit/:id", component: EditOffice },
    { path: "/dropdown/packaging/add", component: AddPackaging },
    { path: "/dropdown/packaging/edit/:id", component: EditPackaging },
    { path: "/dropdown/packing_standard/add", component: AddPackingStandard },
    {
      path: "/dropdown/packing_standard/edit/:id",
      component: EditPackingStandard,
    },
    { path: "/dropdown/construction/add", component: AddConstruction },
    { path: "/dropdown/construction/edit/:id", component: EditConstruction },
    { path: "/dropdown/construction/list", component: ListConstruction },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {
      path: "/",
      exact: true,
      component: () => <Redirect to="/dashboard" />,
    },
  ]
} else if (userType !== "" && userType == "BD") {
  authProtectedRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/products/list", component: BusinessDeveloperProductList },
    { path: "/products/edit/:id", component: BdEditProduct },
    { path: "/offers/add/:id/:prod_no", component: BusinessDeveloperAddOffer },
    { path: "/profile", component: UserProfile },
    { path: "/offers/list", component: BusinessDeveloperOfferList },
    { path: "/offers/edit/:id/", component: BusinessDeveloperEditOffer },
    {
      path: "/offers/create/:id/:prod_no",
      component: BusinessDeveloperAddOfferNew,
    },
    {
      path: "/offers/edit",
      component: BusinessDeveloperGetOfferDetailForUpdate,
    },
    { path: "/export/collection/add", component: BdAddCollection },
    { path: "/export/collection", component: BdCollections },
    { path: "/export/collection/edit/:id", component: BdEditCollection },
    { path: "/export/add", component: BdExportDownload },
    {
      path: "/export/collection/add-more-to-collection/:id",
      component: BdAddMoreDataToCollection,
    },
    { path: "/export/download", component: BdDownloads },
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  ]
} else if (userType !== "" && userType == "CO") {
  authProtectedRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/profile", component: UserProfile },
    { path: "/products/add", component: CoAddProduct },
    { path: "/products/list", component: CoProductList },
    { path: "/products/edit/:id", component: CoEditProduct },
    { path: "/offers/add/:id/:prod_no", component: CoAddOffer },
    { path: "/offers/list", component: CoOfferList },
    { path: "/offers/edit/:id/", component: CoEditOffer },
    { path: "/offers/create/:id/:prod_no", component: COAddOfferNew },
    { path: "/offers/edit", component: COGetOfferDetailForUpdate },
    { path: "/export/collection", component: CoCollections },
    { path: "/export/collection/add", component: CoAddCollection },
    {
      path: "/export/collection/add-more-to-collection/:id",
      component: CoAddMoreDataToCollection,
    },
    { path: "/export/download", component: CoDownloads },
    { path: "/export/collection/edit/:id", component: CoEditCollection },
    { path: "/export/add", component: CoExportDownload },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  ]
} else if (userType !== "" && userType == "QA") {
  authProtectedRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/profile", component: UserProfile },
    { path: "/products/list", component: QaProductList },
    { path: "/products/edit/:id", component: QaEditProduct },
    { path: "/offers/edit/:id/", component: QaEditOffer },
    { path: "/offers/list", component: QaOfferList },
    { path: "/offers/edit", component: QaGetOfferDetailForUpdate },
    { path: "/export/collection", component: QaCollections },
    { path: "/export/collection/add", component: QaAddCollection },
    { path: "/export/collection/edit/:id", component: QaEditCollection },
    {
      path: "/export/collection/add-more-to-collection/:id",
      component: QaAddMoreDataToCollection,
    },
    { path: "/export/add", component: QaExportDownload },
    { path: "/export/download", component: QaDownloads },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  ]
} else if (userType !== "" && userType == "SA") {
  authProtectedRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/profile", component: UserProfile },
    { path: "/products/list", component: SaProductList },
    { path: "/products/edit/:id", component: SaEditProduct },
    { path: "/offers/edit/:id/", component: SaEditOffer },
    { path: "/offers/list", component: SaOfferList },
    { path: "/offers/edit", component: SaGetOfferDetailForUpdate },
    { path: "/export/collection", component: SaCollections },
    { path: "/export/collection/add", component: SaAddCollection },
    { path: "/export/collection/edit/:id", component: SaEditCollection },
    {
      path: "/export/collection/add-more-to-collection/:id",
      component: SaAddMoreDataToCollection,
    },
    { path: "/export/add", component: SaExportDownload },
    { path: "/export/download", component: SaDownloads },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  ]
}

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  { path: "/forgot-password", component: ForgetPwd },
]

export { publicRoutes, authProtectedRoutes }
