import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { BD_GET_HEAVEYCOLLI_LISTING } from "./actionTypes"
import{
  bdHeaveyColliListingSuccess,
  bdHeaveyColliListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { bdHeaveyColliListingApi } from "../../../helpers/fakebackend_helper"

function* getBdHeaveyColliListing() {
  try {
    const response = yield call(bdHeaveyColliListingApi)
    if (!response.results.heavy_colli) {
      yield put(bdHeaveyColliListingFail(error))
    }
    yield put(bdHeaveyColliListingSuccess(response.results.heavy_colli))
  } catch (error) {
    yield put(bdHeaveyColliListingFail(error))
  }
}

function* bdHeaveyColliSaga() {
  yield takeEvery(BD_GET_HEAVEYCOLLI_LISTING, getBdHeaveyColliListing)
}
export default bdHeaveyColliSaga
