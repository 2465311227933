import {
  USER_SUCCESS,
  USER_FAIL,
  USER_ADD,
  GET_USERS_LISTING,
  GET_USERS_LISTING_SUCCESS,
  GET_USERS_LISTING_FAILED,
  CHANGE_USER_STATUS,
  CHANGE_USER_STATUS_SUCCESS,
  CHANGE_USER_STATUS_FAIL,
  USER_EDIT,
  USER_EDIT_SUCCESS,
  USER_EDIT_FAIL,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_RESET_PASSWORD,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: null,
  msg: null,
  loading: false,
  user: [],
  userEdit: null,
  userUpdate: null,
  userResetPassword: null,
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_SUCCESS:
      state = {
        ...state,
        error: null,
        loading: false,
        msg: action?.payload?.msg,
        user: action.payload,
      }
      break
    case USER_ADD:
      state = {
        ...state,
        msg: null,
        error: null,
        loading: true,
        user: action.payload,
      }
      break
    case USER_FAIL:
      state = {
        ...state,
        msg: null,
        loading: false,
        error: action.payload !== "light" ? action.payload : "",
      }
      break

    case GET_USERS_LISTING:
      state = {
        ...state,
        msg: null,
        error: null,
        loading: true,
        user: action.payload,
      }
      break
    case GET_USERS_LISTING_SUCCESS:
      state = {
        ...state,
        error: null,
        msg: null,
        loading: false,
        user: action.payload,
      }
      break
    case GET_USERS_LISTING_FAILED:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break

    case CHANGE_USER_STATUS:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break
    case CHANGE_USER_STATUS_SUCCESS:
      const index = state.user.findIndex(
        user => user.user_id === action.payload.id
      )
      let newUserAfterStatusChange = []
      newUserAfterStatusChange = [...state.user] //making a new array
      newUserAfterStatusChange[index].status =
        !newUserAfterStatusChange[index].status

      state = {
        ...state,
        loading: false,
        msg: null,
        error: null,
        user: newUserAfterStatusChange,
      }
      break
    case CHANGE_USER_STATUS_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    case USER_EDIT:
      state = {
        ...state,
        loading: true,
        msg: null,
        error: null,
        userEdit: action.payload,
      }
      break
    case USER_EDIT_SUCCESS:
      if (state === null || state.user.length === 0) {
        state = {
          ...state,
          msg: null,
          loading: false,
          error: null,
          user: [...state.user, action.payload],
        }
      } else {
        state = {
          ...state,
          msg: null,
          loading: false,
          user: state.user.map(i =>
            i.user_id === action.payload.user_id ? action.payload : i
          ),
        }
      }
      break
    case USER_EDIT_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    case USER_UPDATE:
      state = {
        ...state,
        loading: true,
        msg: null,
        error: null,
        userUpdate: action.payload,
      }
      break
    case USER_UPDATE_SUCCESS:
      state = { ...state, error: null, loading: false, msg: action?.payload?.msg, }
      break
    case USER_UPDATE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    case USER_RESET_PASSWORD:
      state = {
        ...state,
        loading: true,
        msg: null,
        error: null,
        userResetPassword: action.payload,
      }
      break
    case USER_RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        error: null,
        loading: false,
        msg: action.payload.msg
          ? action.payload.msg
          : "Something went wrong. please try again",
        userResetPassword: null,
      }
      break
    case USER_RESET_PASSWORD_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    default:
      state = { ...state, loading: false, error: null }
      break
  }
  return state
}

export default user
