import {
    CO_GET_PACKINGSTANDARD_LISTING,
    CO_GET_PACKINGSTANDARD_LISTING_SUCCESS,
    CO_GET_PACKINGSTANDARD_LISTING_FAILED,
  } from "./actionTypes"
  
  const initialState = {
    error: "",
    loading: false,
    packingStandard: null,
  }
  
  const coPackingStandard = (state = initialState, action) => {
    switch (action.type) {
      case CO_GET_PACKINGSTANDARD_LISTING:
        state = {
          ...state,
          loading: true,
          packingStandard: action.payload,
        }
        break
      case CO_GET_PACKINGSTANDARD_LISTING_SUCCESS:
        state = { ...state, loading: false, packingStandard: action.payload }
        break
      case CO_GET_PACKINGSTANDARD_LISTING_FAILED:
        state = { ...state, loading: false }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default coPackingStandard
  