import {
  GET_PACKINGSTANDARD_LISTING,
  GET_PACKINGSTANDARD_LISTING_SUCCESS,
  GET_PACKINGSTANDARD_LISTING_FAILED,
  FETCH_PACKING_STANDARD_BY_SECTION,
  FETCH_PACKING_STANDARD_BY_SECTION_SUCCESS,
  FETCH_PACKING_STANDARD_BY_SECTION_FAIL,
  ADD_PACKING_STANDARD,
  ADD_PACKING_STANDARD_SUCCESS,
  ADD_PACKING_STANDARD_FAIL,
  EDIT_PACKING_STANDARD,
  EDIT_PACKING_STANDARD_SUCCESS,
  EDIT_PACKING_STANDARD_FAIL,
  UPDATE_PACKING_STANDARD,
  UPDATE_PACKING_STANDARD_SUCCESS,
  UPDATE_PACKING_STANDARD_FAIL,
  PACKING_STANDARD_ACTIVE_INACTIVE,
  PACKING_STANDARD_ACTIVE_INACTIVE_SUCCESS,
  PACKING_STANDARD_ACTIVE_INACTIVE_FAIL,
  PACKING_STANDARD_POSITION,
  PACKING_STANDARD_POSITION_SUCCESS,
  PACKING_STANDARD_POSITION_FAIL,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  packingStandard: null,
  positionUpdate: null,
}

const packingStandard = (state = initialState, action) => {
  switch (action.type) {
    case GET_PACKINGSTANDARD_LISTING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
        packingStandard: action.payload,
      }
      break
    case GET_PACKINGSTANDARD_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        positionUpdate: null,
        packingStandard: action.payload,
      }
      break
    case GET_PACKINGSTANDARD_LISTING_FAILED:
      state = { ...state, loading: false }
      break
    case FETCH_PACKING_STANDARD_BY_SECTION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
        packingStandardList: action.payload,
      }
      break
    case FETCH_PACKING_STANDARD_BY_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        positionUpdate: null,
        packingStandard: action.payload,
      }
      break
    case FETCH_PACKING_STANDARD_BY_SECTION_FAIL:
      state = { ...state, loading: false }
      break
    case ADD_PACKING_STANDARD:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        packingStandard: action.payload,
        packingStandardEdit: null,
      }
      break
    case ADD_PACKING_STANDARD_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        packingStandard: action.payload,
      }
      break
    case ADD_PACKING_STANDARD_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload ? action.payload : null,
      }
      break
    case EDIT_PACKING_STANDARD:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        packingStandardEdit: action.payload,
      }
      break
    case EDIT_PACKING_STANDARD_SUCCESS:
      if (state === null || state.packingStandard.length === 0) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          packingStandard: [...state.packingStandard, action.payload],
        }
      } else {
        state = {
          ...state,
          loading: false,
          packingStandard: state.packingStandard.map(i =>
            i.id === action.payload.id ? action.payload : i
          ),
        }
      }
      break
    case EDIT_PACKING_STANDARD_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case UPDATE_PACKING_STANDARD:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        packingStandard: action.payload,
      }
      break
    case UPDATE_PACKING_STANDARD_SUCCESS:
      state = { ...state, error: null, msg: null, loading: false }
      break
    case UPDATE_PACKING_STANDARD_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case PACKING_STANDARD_ACTIVE_INACTIVE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break
    case PACKING_STANDARD_ACTIVE_INACTIVE_SUCCESS:
      const index = state.packingStandard.findIndex(
        packingStandard =>
          packingStandard.packing_standard_id === action.payload.id
      )
      let packingStandardStatus = []
      packingStandardStatus = [...state.packingStandard] //making a new array
      packingStandardStatus[index].status =
        packingStandardStatus[index].status == 1 ? 0 : 1

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        packingStandard: packingStandardStatus,
      }
      break
    case PACKING_STANDARD_ACTIVE_INACTIVE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    case PACKING_STANDARD_POSITION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
      }
      break

    case PACKING_STANDARD_POSITION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: action.payload.msg != "" ? action.payload.msg : "",
        positionUpdate: true,
      }
      break
    case PACKING_STANDARD_POSITION_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.msg != "" ? action.payload.msg : "",
        msg: null,
        positionUpdate: null,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default packingStandard
