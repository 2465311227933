import React from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// actions
import {
  productCategoryListing,
  // qualityRatingListing,
  constructionListing,
  qaEditProduct,
} from "../../../store/actions"
import { useEffect, useState } from "react"

const QaEditProduct = props => {
  const dispatch = useDispatch()
  document.title = "Edit Product"
  const { id } = useParams()

  const [details, setDetails] = useState({})

  const [productCategories, setProductCategories] = useState([])
  // const [qualityRatings, setQualityRatings] = useState([])
  const [constructions, setConstructions] = useState([])

  // const [modalOpen, setModalOpen] = useState(false)
  // const [currentProductImage, setCurrentProductImage] = useState(undefined)

  // function updateModalState(productImgParams = undefined) {
  //   setModalOpen(!modalOpen)
  //   if (productImgParams) {
  //     setCurrentProductImage(productImgParams)
  //   }
  // }

  // function deleteProductImageHandler(productImgParams) {
  //   if (id) {
  //     dispatch(deleteProductImage(productImgParams, props.history))
  //     updateModalState(undefined)
  //   }
  // }

  useEffect(() => {
    dispatch(productCategoryListing())
    // dispatch(qualityRatingListing())
    dispatch(constructionListing())
    dispatch(qaEditProduct(id))
  }, [dispatch])

  const products = useSelector(state => state.qaProduct)

  const productCategoryData = useSelector(
    state => state.ProductCategory.productCategory
  )
  // const qualityRatingData = useSelector(
  //   state => state.QualityRating.qualityRating
  // )
  const constructionData = useSelector(state => state.construction.construction)

  // function onDeleteImg(img_name, img_id, id, props) {
  //   let productImgParams = {
  //     img_name: img_name,
  //     prod_img_id: img_id,
  //     prod_id: id,
  //   }
  //   updateModalState(productImgParams)
  //   // dispatch(deleteProductImage(productImgParams, props.history))
  // }

  useEffect(() => {
    let prods = []
    if (products && products.product && products.product.length > 0) {
      prods = products.product.filter(product => product.prod_id == id)
    }
    setProductCategories(productCategoryData)
    // setQualityRatings(qualityRatingData)
    setConstructions(constructionData)
    if (prods.length > 0) {
      setDetails(prods[0])
    } else {
      props.history.push("/products/list")
    }
  }, [productCategoryData, constructionData, products])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      prod_id: details && details.prod_id ? details.prod_id : "",

      prod_name: details && details.prod_name ? details.prod_name : "",

      prod_cat:
        details && details.product_category_id
          ? details.product_category_id
          : "",

      construction:
        details && details.construction_id ? details.construction_id : "",

      product_folder:
        details && details.product_folder ? details.product_folder : "",

      description: details && details.material_desc ? details.material_desc : "",
      length: details && details.length ? details.length : "",
      depth: details && details.depth ? details.depth : "",
      height: details && details.height ? details.height : "",
      img1: "",
      img2: "",
      img3: "",
      img4: "",
      img5: "",
    },
    validationSchema: Yup.object({
      prod_cat: Yup.string().required("Please enter category"),
      construction: Yup.string().required("Please enter construction type"),
      length: Yup.number()
        .required("Please enter length")
        .min(1, "This value should be between 1 and 5000")
        .max(5000, "This value should be between 1 and 5000"),

      depth: Yup.number()
        .required("Please enter depth")
        .min(1, "This value should be between 1 and 5000")
        .max(5000, "This value should be between 1 and 5000"),

      height: Yup.number()
        .required("Please enter height")
        .min(1, "This value should be between 1 and 5000")
        .max(5000, "This value should be between 1 and 5000"),
    }),
    onSubmit: () => { },
  })

  const { error } = useSelector(state => ({
    error: state.qaProduct.error,
  }))

  useEffect(() => {
    if (error) {
      toast.dismiss()
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
  }, [error])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Edit Product" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    <Row>
                      <Col md={6}>
                        <b className="align-right">
                          Product Number :-{" "}
                          {details && details.prod_no ? details.prod_no : ""}
                        </b>
                      </Col>
                    </Row>
                  </CardTitle>
                  {error ? <ToastContainer /> : ""}
                  <Form
                    id="editproductForm"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <div className="mb-3 mt-1">
                          <Label htmlFor="product-name">Product Name</Label>
                          <Input
                            name="prod_id"
                            type="hidden"
                            value={validation.values.prod_id || ""}
                          />
                          <Input
                            readOnly={true}
                            name="prod_name"
                            type="text"
                            id="product-name"
                            // placeholder="Enter product name"
                            autoComplete="off"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.prod_name || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="product-category">
                            Product Category{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <select
                            readOnly={true}
                            name="prod_cat"
                            id="formrow-product-category"
                            className={
                              validation.touched.prod_cat &&
                                validation.errors.prod_cat
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.prod_cat || ""}
                          >
                            <option value="">Choose...</option>
                            {productCategories &&
                              productCategories.map((productCategory, pc) => (
                                <option
                                  key={"pc" + pc}
                                  value={productCategory.id}
                                >
                                  {productCategory.prod_cat_name}
                                </option>
                              ))}
                          </select>
                          {validation.touched.prod_cat &&
                            validation.errors.prod_cat ? (
                            <FormFeedback type="invalid">
                              {validation.errors.prod_cat}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      {/* <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-quality-rating">
                            Quality Rating{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <select
                            readOnly={true}
                            name="quality_rating_id"
                            id="formrow-quality-rating"
                            className={
                              validation.touched.quality_rating_id &&
                                validation.errors.quality_rating_id
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.quality_rating_id || ""}
                          >
                            <option value="">Choose...</option>
                            {qualityRatings &&
                              qualityRatings.map((qualityRating, qr) => (
                                <option
                                  key={"qr" + qr}
                                  value={qualityRating.id}
                                >
                                  {qualityRating.quality_rating}
                                </option>
                              ))}
                          </select>
                          {validation.touched.quality_rating_id &&
                            validation.errors.quality_rating_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.quality_rating_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col> */}
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-productFolder">
                            Product Folder
                          </Label>
                          <Input
                            readOnly={true}
                            name="product_folder"
                            type="text"
                            className="form-control"
                            id="formrow-product-folder"
                            // placeholder="Enter link here"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.product_folder || ""}
                          />
                        </div>
                      </Col>
                {/*       <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-yearlyForecast">
                            Yearly Forecast{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            readOnly={true}
                            name="yearly_forecast"
                            type="text"
                            className="form-control"
                            id="formrow-yearly-forecast"
                            // placeholder="Ex. 2014"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.yearly_forecast || ""}
                            invalid={
                              validation.touched.yearly_forecast &&
                                validation.errors.yearly_forecast
                                ? true
                                : false
                            }
                          />
                          {validation.touched.yearly_forecast &&
                            validation.errors.yearly_forecast ? (
                            <FormFeedback type="invalid">
                              {validation.errors.yearly_forecast}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col> */}
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-construction">
                            Construction <span className="text-danger">*</span>
                          </Label>
                          <select
                            readOnly={true}
                            name="construction"
                            id="formrow-construction"
                            className={
                              validation.touched.construction &&
                                validation.errors.construction
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.construction || ""}
                          >
                            <option value="">Choose...</option>
                            {constructions &&
                              constructions.map((construction, cns) => (
                                <option
                                  key={"cns" + cns}
                                  value={construction.construction_id}
                                >
                                  {construction.construction}
                                </option>
                              ))}
                          </select>
                          {validation.touched.construction &&
                            validation.errors.construction ? (
                            <FormFeedback type="invalid">
                              {validation.errors.construction}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-material-description">
                            Material Description
                          </Label>
                          <textarea
                            readOnly={true}
                            name="description"
                            type="text"
                            className="form-control"
                            id="formrow-material-description"
                            // placeholder="Type Your Text Here"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="length">
                            Length (mm) <span className="text-danger">*</span>
                          </Label>
                          <Input
                            readOnly={true}
                            name="length"
                            type="text"
                            className="form-control"
                            id="formrow-length"
                            // placeholder="Ex. 1800"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.length || ""}
                            invalid={
                              validation.touched.length &&
                                validation.errors.length
                                ? true
                                : false
                            }
                          />
                          {validation.touched.length &&
                            validation.errors.length ? (
                            <FormFeedback type="invalid">
                              {validation.errors.length}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-depth">
                            Depth (mm) <span className="text-danger">*</span>
                          </Label>
                          <Input
                            readOnly={true}
                            name="depth"
                            type="text"
                            className="form-control"
                            id="formrow-deoth"
                            // placeholder="Ex. 900"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.depth || ""}
                            invalid={
                              validation.touched.depth &&
                                validation.errors.depth
                                ? true
                                : false
                            }
                          />
                          {validation.touched.depth &&
                            validation.errors.depth ? (
                            <FormFeedback type="invalid">
                              {validation.errors.depth}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-heigth">
                            Heigth (mm) <span className="text-danger">*</span>
                          </Label>
                          <Input
                            readOnly={true}
                            name="height"
                            type="text"
                            className="form-control"
                            id="formrow-heigth"
                            // placeholder="Ex. 750"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.height || ""}
                            invalid={
                              validation.touched.height &&
                                validation.errors.height
                                ? true
                                : false
                            }
                          />
                          {validation.touched.height &&
                            validation.errors.height ? (
                            <FormFeedback type="invalid">
                              {validation.errors.height}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    {/* Images */}
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-1">
                            Product Image 1
                          </Label>
                          <Input
                            readOnly={true}
                            name="img1"
                            type="file"
                            className="form-control"
                            id="formrow-image-1"
                            // placeholder="Upload File"
                            // onChange={handleFileChange}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.img1 || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3 text-center">
                          {details && details.img1 ? (
                            <a
                              href={details.img1}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={details.img1}
                                className="img-fluid img-thumbnail rounded"
                                width="80"
                                height="100"
                                alt="img1"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                      {/* <Col lg={4}>
                        {details && details.img1_id ? (
                          <div className="mb-3 text-left mt-3">
                            <Button
                              color="danger"
                              outline
                              onClick={() =>
                                onDeleteImg("img1", details.img1_id, id, props)
                              }
                            >
                              delete
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                      </Col> */}
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-2">
                            Product Image 2
                          </Label>
                          <Input
                            readOnly={true}
                            name="img2"
                            type="file"
                            className="form-control"
                            id="formrow-image-2"
                            // placeholder="Upload File"
                            // onChange={handleFileChange}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.img2 || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3 text-center">
                          {details && details.img2 ? (
                            <a
                              href={details.img2}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={details.img2}
                                className="img-fluid img-thumbnail rounded"
                                width="80"
                                height="100"
                                alt="img2"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                      {/* <Col lg={4}>
                        {details && details.img2_id ? (
                          <div className="mb-3 text-left mt-3">
                            <Button
                              color="danger"
                              outline
                              onClick={() =>
                                onDeleteImg("img2", details.img2_id, id, props)
                              }
                            >
                              delete
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                      </Col> */}
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-3">
                            Product Image 3
                          </Label>
                          <Input
                            readOnly={true}
                            name="img3"
                            type="file"
                            className="form-control"
                            id="formrow-image-3"
                            // placeholder="Upload File"
                            // onChange={handleFileChange}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.img3 || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3 text-center">
                          {details && details.img3 ? (
                            <a
                              href={details.img3}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={details.img3}
                                className="img-fluid img-thumbnail rounded"
                                width="80"
                                height="100"
                                alt="img3"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                      {/* <Col lg={4}>
                        {details && details.img3_id ? (
                          <div className="mb-3 text-left mt-3">
                            <Button
                              color="danger"
                              outline
                              onClick={() =>
                                onDeleteImg("img3", details.img3_id, id, props)
                              }
                            >
                              delete
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                      </Col> */}
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-4">
                            Product Image 4
                          </Label>
                          <Input
                            readOnly={true}
                            name="img4"
                            type="file"
                            className="form-control"
                            id="formrow-image-4"
                            // placeholder="Upload File"
                            // onChange={handleFileChange}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.img4 || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3 text-center">
                          {details && details.img4 ? (
                            <a
                              href={details.img4}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={details.img4}
                                className="img-fluid img-thumbnail rounded"
                                width="80"
                                height="100"
                                alt="img4"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                      {/* <Col lg={4}>
                        {details && details.img4_id ? (
                          <div className="mb-3 text-left mt-3">
                            <Button
                              color="danger"
                              outline
                              onClick={() =>
                                onDeleteImg("img4", details.img4_id, id, props)
                              }
                            >
                              delete
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                      </Col> */}
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-image-5">
                            Product Image 5
                          </Label>
                          <Input
                            readOnly={true}
                            name="img5"
                            type="file"
                            className="form-control"
                            id="formrow-image-5"
                            // placeholder="Upload File"
                            // onChange={handleFileChange}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.img5 || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3 text-center">
                          {details && details.img5 ? (
                            <a
                              href={details.img5}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={details.img5}
                                className="img-fluid img-thumbnail rounded"
                                width="80"
                                height="100"
                                alt="img5"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                      {/* <Col lg={4}>
                        {details && details.img5_id ? (
                          <div className="mb-3 text-left mt-3">
                            <Button
                              color="danger"
                              outline
                              onClick={() =>
                                onDeleteImg("img5", details.img5_id, id, props)
                              }
                            >
                              delete
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                      </Col> */}
                    </Row>

                    <div>
                      {products && products.loading ? (
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          disabled
                        >
                          Updating ...
                        </button>
                      ) : (
                        <button type="submit" disabled className="btn btn-primary w-md">
                          Update Product
                        </button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Modal
            isOpen={modalOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="deleteProductImageConfirm"
            tabIndex="-1"
            backdrop={false}
            toggle={() => updateModalState()}
          >
            <ModalHeader>Remove Product Image</ModalHeader>
            <ModalBody>Do you really want to delete product image?</ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => deleteProductImageHandler(currentProductImage)}
              >
                Yes
              </Button>{" "}
              <Button onClick={() => updateModalState()}>No</Button>
            </ModalFooter>
          </Modal> */}
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default QaEditProduct
