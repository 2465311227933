import {
  GET_HEAVEYCOLLI_LISTING,
  GET_HEAVEYCOLLI_LISTING_SUCCESS,
  GET_HEAVEYCOLLI_LISTING_FAILED,
  ADD_HEAVEY_COLLI,
  ADD_HEAVEY_COLLI_SUCCESS,
  ADD_HEAVEY_COLLI_FAIL,
  CHANGE_HEAVEY_COLLI_STATUS,
  CHANGE_HEAVEY_COLLI_STATUS_SUCCESS,
  CHANGE_HEAVEY_COLLI_STATUS_FAIL,
  EDIT_HEAVEY_COLLI,
  EDIT_HEAVEY_COLLI_SUCCESS,
  EDIT_HEAVEY_COLLI_FAIL,
  UPDATE_HEAVEY_COLLI,
  UPDATE_HEAVEY_COLLI_SUCCESS,
  UPDATE_HEAVEY_COLLI_FAIL,
  FETCH_HEAVEY_COLLI_BY_SECTION,
  FETCH_HEAVEY_COLLI_BY_SECTION_SUCCESS,
  FETCH_HEAVEY_COLLI_BY_SECTION_FAIL,
  UPDATE_HEAVEY_COLLI_POSITION,
  UPDATE_HEAVEY_COLLI_POSITION_SUCCESS,
  UPDATE_HEAVEY_COLLI_POSITION_FAIL,
} from "./actionTypes"

export const heaveyColliListing = () => {
  return {
    type: GET_HEAVEYCOLLI_LISTING,
  }
}

export const heaveyColliListingSuccess = heaveyColli => {
  return {
    type: GET_HEAVEYCOLLI_LISTING_SUCCESS,
    payload: heaveyColli,
  }
}

export const heaveyColliListingFail = error => {
  return {
    type: GET_HEAVEYCOLLI_LISTING_FAILED,
    payload: error,
  }
}

export const addHeaveyColli = (heaveyColli, history) => {
  return {
    type: ADD_HEAVEY_COLLI,
    payload: { heaveyColli, history },
  }
}

export const addHeaveyColliSuccess = heaveyColli => {
  return {
    type: ADD_HEAVEY_COLLI_SUCCESS,
    payload: heaveyColli,
  }
}

export const addHeaveyColliFail = error => {
  return {
    type: ADD_HEAVEY_COLLI_FAIL,
    payload: error,
  }
}

export const changeHeaveyColliStatus = id => ({
  type: CHANGE_HEAVEY_COLLI_STATUS,
  payload: { id: id },
})

export const changeHeaveyColliStatusSuccess = heaveyColli => ({
  type: CHANGE_HEAVEY_COLLI_STATUS_SUCCESS,
  payload: heaveyColli,
})

export const changeHeaveyColliStatusFail = error => ({
  type: CHANGE_HEAVEY_COLLI_STATUS_FAIL,
  payload: error,
})

export const editHeaveyColli = id => {
  return {
    type: EDIT_HEAVEY_COLLI,
    payload: { id },
  }
}
export const editHeaveyColliSuccess = heaveyColli => {
  return {
    type: EDIT_HEAVEY_COLLI_SUCCESS,
    payload: heaveyColli,
  }
}
export const editHeaveyColliFail = error => {
  return {
    type: EDIT_HEAVEY_COLLI_FAIL,
    payload: error,
  }
}

export const updateHeaveyColli = (heaveyColliUpdate, history) => {
  return {
    type: UPDATE_HEAVEY_COLLI,
    payload: { heaveyColliUpdate, history },
  }
}

export const updateHeaveyColliSuccess = heaveyColli => {
  return {
    type: UPDATE_HEAVEY_COLLI_SUCCESS,
    payload: heaveyColli,
  }
}

export const updateHeaveyColliFail = error => {
  return {
    type: UPDATE_HEAVEY_COLLI_FAIL,
    payload: error,
  }
}

export const fetchHeaveyColliListingBySection = data => {
  return {
    type: FETCH_HEAVEY_COLLI_BY_SECTION,
    payload: { data },
  }
}

export const fetchHeaveyColliListingBySectionSuccess = heaveyColli => {
  return {
    type: FETCH_HEAVEY_COLLI_BY_SECTION_SUCCESS,
    payload: heaveyColli,
  }
}

export const fetchHeaveyListingBySectionFail = error => {
  return {
    type: FETCH_HEAVEY_COLLI_BY_SECTION_FAIL,
    payload: error,
  }
}

export const updateHeaveyPosition = data => ({
  type: UPDATE_HEAVEY_COLLI_POSITION,
  payload: { data },
})

export const updateHeaveyPositionSuccess = heaveyColli => ({
  type: UPDATE_HEAVEY_COLLI_POSITION_SUCCESS,
  payload: heaveyColli,
})

export const updateHeaveyPositionFail = error => ({
  type: UPDATE_HEAVEY_COLLI_POSITION_FAIL,
  payload: error,
})
