import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./exports.css"

function BtnCellRenderer(props) {
  return (
    <>
      <div className="row">
      <div className="col">
          <i
            className="bx bx-edit cursor-pointer font_size_large"
            title="Edit Collection name"
            onClick={() =>
              props.updateCollectionNameModalState(
                props.data.id,
                props.data.collection_type,
                props.data.collection_name,
                props.data.meta_data
              )
            }
          ></i>
        </div>
        <div className="col">
          <Link
            to={`/export/collection/edit/` + props.data.id}
            className="text-decoration-none"
          >
            <i
              className="bx bx-message-alt-x cursor-pointer font_size_large"
              title="Remove items from collection"
            ></i>
          </Link>
        </div>

        <div className="col">
          {/* <Link to="#" className="text-decoration-none"> */}
          <i
            className="bx bx-copy-alt cursor-pointer font_size_large"
            title="Copy Collection"
            onClick={() =>
              props.updateCopyCollectionModalState(
                props.data.collection_type,
                props.data.collection_name,
                props.data.meta_data
              )
            }
          ></i>
          {/* </Link> */}
        </div>

        <div className="col">
          <i
            className="bx bx-folder-minus cursor-pointer font_size_large text-danger"
            title="Delete Collection"
            onClick={() => props.deleteConfirmation(props.data.id)}
          ></i>
        </div>

        <div className="col">
          <Link
            to={`/export/collection/add-more-to-collection/` + props.data.id}
            className="text-decoration-none"
          >
            <i
              className="bx bx-add-to-queue cursor-pointer font_size_large"
              title="Add more data to collection"
            ></i>
          </Link>
        </div>
      </div>
    </>
  )
}

export default BtnCellRenderer
