import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_MATERIALSTANDARD_LISTING,
  ADD_MATERIAL,
  CHANGE_MATERIAL_STATUS,
  EDIT_MATERIAL,
  UPDATE_MATERIAL,
  FETCH_MATERIAL_BY_SECTION,
  MATERIAL_STANDARD_POSITION,
} from "./actionTypes"
import {
  materialStandardListingSuccess,
  materialStandardListingFail,
  addMaterialSuccess,
  addMaterialFail,
  changeMaterialStatusSuccess,
  changeMaterialStatusFail,
  editMaterialSuccess,
  editMaterialFail,
  updateMaterialSuccess,
  updateMaterialFail,
  fetchMaterialListingBySectionSuccess,
  fetchMaterialListingBySectionFail,
  updateMaterialStandardPositionSuccess,
  updateMaterialStandardPositionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  materialStandardListingApi,
  addMaterialApi,
  changeMaterialStatusApi,
  editMaterialApi,
  updateMaterialApi,
  fetchDropdownsBySectionApi,
  fetchDropDownPositionApi,
} from "../../../helpers/fakebackend_helper"

function* getMaterialStandardListing() {
  try {
    const response = yield call(materialStandardListingApi)
    if (!response.results.material_standards) {
      yield put(materialStandardListingFail(error))
    }
    yield put(
      materialStandardListingSuccess(response.results.material_standards)
    )
  } catch (error) {
    yield put(materialStandardListingFail(error))
  }
}

function* addMaterial({ payload: { materialStandard, history } }) {
  try {
    const response = yield call(addMaterialApi, materialStandard)
    if (response && response.status) {
      yield put(addMaterialSuccess(response))
      history.push("/dropdown/material_standard/list")
    } else {
      throw response.msg ? response.msg : "Failed to add"
    }
  } catch (error) {
    yield put(addMaterialFail(error))
  }
}

function* onMaterialStatusChange({ payload: { id } }) {
  try {
    const response = yield call(changeMaterialStatusApi, {
      material_standard_id: id,
    })
    if (response.status) {
      response.id = id
      yield put(changeMaterialStatusSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to change status"
    }
  } catch (error) {
    yield put(changeMaterialStatusFail(error))
  }
}

function* getMaterialDetailsById({ payload: { id } }) {
  try {
    const response = yield call(editMaterialApi, id)
    if (response && response.status) {
      yield put(editMaterialSuccess(response.results.material_standard))
    } else {
      throw response.msg ? response.msg : "Failed to edit"
    }
  } catch (error) {
    yield put(editMaterialFail(error))
  }
}

function* onMaterialUpdate({ payload: { materialStandardUpdate, history } }) {
  try {
    const response = yield call(updateMaterialApi, materialStandardUpdate)
    if (response && response.status) {
      yield put(updateMaterialSuccess(response))
      history.push("/dropdown/material_standard/list")
    } else {
      throw response.msg ? response.msg : "Failed to update"
    }
  } catch (error) {
    yield put(updateMaterialFail(error))
  }
}

function* fetchMaterialListingBySection({ payload: { data } }) {
  try {
    const response = yield call(fetchDropdownsBySectionApi, data)
    if (!response?.results?.material_standards) {
      throw response.msg ? response.msg : "Failed to fetch details"
    }
    yield put(
      fetchMaterialListingBySectionSuccess(response.results.material_standards)
    )
  } catch (error) {
    yield put(fetchMaterialListingBySectionFail(error))
  }
}

function* updateMaterialStandardPosition({ payload: { position } }) {
  try {
    const response = yield call(fetchDropDownPositionApi, position)
    if (response && response.status) {
      yield put(updateMaterialStandardPositionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update position"
    }
  } catch (error) {
    let msg = error ? { msg: error } : { msg: "Failed to update position" }
    yield put(updateMaterialStandardPositionFail(msg))
  }
}

function* materialStandardSaga() {
  yield takeEvery(GET_MATERIALSTANDARD_LISTING, getMaterialStandardListing)
  yield takeEvery(ADD_MATERIAL, addMaterial)
  yield takeEvery(CHANGE_MATERIAL_STATUS, onMaterialStatusChange)
  yield takeEvery(EDIT_MATERIAL, getMaterialDetailsById)
  yield takeEvery(UPDATE_MATERIAL, onMaterialUpdate)
  yield takeEvery(FETCH_MATERIAL_BY_SECTION, fetchMaterialListingBySection)
  yield takeEvery(MATERIAL_STANDARD_POSITION, updateMaterialStandardPosition)
}
export default materialStandardSaga
