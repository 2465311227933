import {
  BD_GET_USERLISTACCORDINGROLE_LISTING,
  BD_GET_USERLISTACCORDINGROLE_LISTING_SUCCESS,
  BD_GET_USERLISTACCORDINGROLE_LISTING_FAILED,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  bdUserListAccordingRole: null,
}

const bdUserListAccordingRole = (state = initialState, action) => {
  switch (action.type) {
    case BD_GET_USERLISTACCORDINGROLE_LISTING:
      state = {
        ...state,
        loading: true,
        bdUserListAccordingRole: action.payload,
      }
      break
    case BD_GET_USERLISTACCORDINGROLE_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        bdUserListAccordingRole: action.payload,
      }
      break
    case BD_GET_USERLISTACCORDINGROLE_LISTING_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default bdUserListAccordingRole
