import {
  SA_GET_OFFERS_LISTING,
  SA_GET_OFFERS_LISTING_SUCCESS,
  SA_GET_OFFERS_LISTING_FAILED,
  SA_OFFER_EDIT,
  SA_OFFER_EDIT_SUCCESS,
  SA_OFFER_EDIT_FAIL,
  SA_OFFER_UPDATE,
  SA_OFFER_UPDATE_SUCCESS,
  SA_OFFER_UPDATE_FAIL,
  SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE,
  SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS,
  SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL,
  SA_RESET_OFFER,
  SA_RESET_OFFER_SUCCESS,
  SA_RESET_OFFER_FAIL
} from "./actionTypes"

const initialState = {
  error: null,
  loading: false,
  msg: null,
  offer: [],
  offerEdit: null,
  offerUpdate: null,
  search: null,
}

const saOffer = (state = initialState, action) => {
  switch (action.type) {
    case SA_GET_OFFERS_LISTING:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        search: null,
        offer: action.payload,
      }
      break
    case SA_GET_OFFERS_LISTING_SUCCESS:
      state = {
        ...state,
        error: null,
        loading: false,
        offer: action.payload,
        offerEdit: [],
        search: null,
      }
      break
    case SA_GET_OFFERS_LISTING_FAILED:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case SA_OFFER_EDIT:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        offerEdit: action.payload,
      }
      break
    case SA_OFFER_EDIT_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        offer: [action.payload],
      }

      break
    case SA_OFFER_EDIT_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case SA_OFFER_UPDATE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        offerUpdate: action.payload,
      }
      break
    case SA_OFFER_UPDATE_SUCCESS:
      state = {
        ...state,
        error: null,
        msg: action?.payload?.msg,
        loading: false,
      }
      break
    case SA_OFFER_UPDATE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
    case SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        search: action.payload,
        offerEdit: [],
      }
      break
    case SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        search: action.payload,
      }
      break
    case SA_SEARCH_ITEM_IN_NEW_OFFER_PAGE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload !== "light" ? action.payload : "",
      }
      break
      case SA_RESET_OFFER:
        state = initialState
        break
  
      case SA_RESET_OFFER_SUCCESS:
        state = initialState
        break
  
      case SA_RESET_OFFER_FAIL:
        state = initialState
        break
  }
  return state
}

export default saOffer
