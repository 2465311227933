export const SA_COLLECTION_ADD = "SA_COLLECTION_ADD"
export const SA_COLLECTION_SUCCESS = "SA_COLLECTION_SUCCESS"
export const SA_COLLECTION_FAIL = "SA_COLLECTION_FAIL"

export const SA_GET_COLLECTION_LISTING = "SA_GET_COLLECTION_LISTING"
export const SA_GET_COLLECTION_LISTING_SUCCESS =
  "SA_GET_COLLECTION_LISTING_SUCCESS"
export const SA_GET_COLLECTION_LISTING_FAILED =
  "SA_GET_COLLECTION_LISTING_FAILED"

export const SA_COLLECTION_EDIT = "SA_COLLECTION_EDIT"
export const SA_COLLECTION_EDIT_SUCCESS = "SA_COLLECTION_EDIT_SUCCESS"
export const SA_COLLECTION_EDIT_FAIL = "SA_COLLECTION_EDIT_FAIL"

export const SA_COLLECTION_UPDATE = "SA_COLLECTION_UPDATE"
export const SA_COLLECTION_UPDATE_SUCCESS = "SA_COLLECTION_UPDATE_SUCCESS"
export const SA_COLLECTION_UPDATE_FAIL = "SA_COLLECTION_UPDATE_FAIL"

export const SA_COLLECTION_DELETE = "SA_COLLECTION_DELETE"
export const SA_COLLECTION_DELETE_SUCCESS = "SA_COLLECTION_DELETE_SUCCESS"
export const SA_COLLECTION_DELETE_FAIL = "SA_COLLECTION_DELETE_FAIL"

export const SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD =
  "SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD"
export const SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS =
  "SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_SUCCESS"
export const SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL =
  "SA_EXPORTCOLLECTIONCATALOG_DOWNLOAD_FAIL"

export const SA_COLLECTION_COPY = "SA_COLLECTION_COPY"
export const SA_COLLECTION_COPY_SUCCESS = "SA_COLLECTION_COPY_SUCCESS"
export const SA_COLLECTION_COPY_FAIL = "SA_COLLECTION_COPY_FAIL"

export const SA_EXPORTDOWNLOAD_PROGRESS = "SA_EXPORTDOWNLOAD_PROGRESS"
export const SA_EXPORTDOWNLOAD_PROGRESS_SUCCESS =
  "SA_EXPORTDOWNLOAD_PROGRESS_SUCCESS"
export const SA_EXPORTDOWNLOAD_PROGRESS_FAIL = "SA_EXPORTDOWNLOAD_PROGRESS_FAIL"

export const SA_ADD_MORE_TO_COLLECTION = "SA_ADD_MORE_TO_COLLECTION"
export const SA_ADD_MORE_TO_COLLECTION_SUCCESS =
  "SA_ADD_MORE_TO_COLLECTION_SUCCESS"
export const SA_ADD_MORE_TO_COLLECTION_FAIL = "SA_ADD_MORE_TO_COLLECTION_FAIL"

export const SA_ADD_MORE_TO_COLLECTION_UPDATE =
  "SA_ADD_MORE_TO_COLLECTION_UPDATE"
export const SA_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS =
  "SA_ADD_MORE_TO_COLLECTION_UPDATE_SUCCESS"
export const SA_ADD_MORE_TO_COLLECTION_UPDATE_FAIL =
  "SA_ADD_MORE_TO_COLLECTION_UPDATE_FAIL"

export const SA_GET_PRODUCTS_FOR_ADD_COLLECTION =
  "SA_GET_PRODUCTS_FOR_ADD_COLLECTION"
export const SA_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS =
  "SA_GET_PRODUCTS_FOR_ADD_COLLECTION_SUCCESS"
export const SA_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL =
  "SA_GET_PRODUCTS_FOR_ADD_COLLECTION_FAIL"

export const SA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION =
  "SA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION"
export const SA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS =
  "SA_GET_PRODUCT_OFFER_FOR_ADD_COLLECTION_SUCCESS"
export const SA_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL =
  "SA_GET_PRODUCTS_OFFER_FOR_ADD_COLLECTION_FAIL"

export const SA_REFRESH_COLLECTION_COUNT = "SA_REFRESH_COLLECTION_COUNT"
export const SA_REFRESH_COLLECTION_COUNT_SUCCESS =
  "SA_REFRESH_COLLECTION_COUNT_SUCCESS"
export const SA_REFRESH_COLLECTION_COUNT_FAIL =
  "SA_REFRESH_COLLECTION_COUNT_FAIL"

export const SA_GET_LAYOUT = "SA_GET_LAYOUT"
export const SA_GET_LAYOUT_SUCCESS = "SA_GET_LAYOUT_SUCCESS"
export const SA_GET_LAYOUT_FAIL = "SA_GET_LAYOUT_FAIL"
