import {
    GET_DELIVEREDON_LISTING,
    GET_DELIVEREDON_LISTING_SUCCESS,
    GET_DELIVEREDON_LISTING_FAILED,
    ADD_DELIVERED,
    ADD_DELIVERED_SUCCESS,
    ADD_DELIVERED_FAIL,
    CHANGE_DELIVERED_STATUS,
    CHANGE_DELIVERED_STATUS_SUCCESS,
    CHANGE_DELIVERED_STATUS_FAIL,
    EDIT_DELIVERED,
    EDIT_DELIVERED_SUCCESS,
    EDIT_DELIVERED_FAIL,
    UPDATE_DELIVERED,
    UPDATE_DELIVERED_SUCCESS,
    UPDATE_DELIVERED_FAIL,
    FETCH_DELIVERED_BY_SECTION,
    FETCH_DELIVERED_BY_SECTION_SUCCESS,
    FETCH_DELIVERED_BY_SECTION_FAIL,
    UPDATE_DELIVERED_POSITION,
    UPDATE_DELIVERED_POSITION_SUCCESS,
    UPDATE_DELIVERED_POSITION_FAIL,
  } from "./actionTypes"
  
  export const deliveredOnListing = () => {
    return {
      type: GET_DELIVEREDON_LISTING,
    }
  }
  
  export const deliveredOnListingSuccess = deliveredOn => {
    return {
      type: GET_DELIVEREDON_LISTING_SUCCESS,
      payload: deliveredOn,
    }
  }
  
  export const deliveredOnListingFail = error => {
    return {
      type: GET_DELIVEREDON_LISTING_FAILED,
      payload: error,
    }
  }
  
  export const addDelivered = (deliveredOn, history) => {
    return {
      type: ADD_DELIVERED,
      payload: { deliveredOn, history },
    }
  }
  
  export const addDeliveredSuccess = deliveredOn => {
    return {
      type: ADD_DELIVERED_SUCCESS,
      payload: deliveredOn,
    }
  }
  
  export const addDeliveredFail = error => {
    return {
      type: ADD_DELIVERED_FAIL,
      payload: error,
    }
  }

  export const changeDeliveredStatus = id => ({
    type: CHANGE_DELIVERED_STATUS,
    payload: { id: id },
  })
  
  export const changeDeliveredStatusSuccess = deliveredOn => ({
    type: CHANGE_DELIVERED_STATUS_SUCCESS,
    payload: deliveredOn,
  })
  
  export const changeDeliverdStatusFail = error => ({
    type: CHANGE_DELIVERED_STATUS_FAIL,
    payload: error,
  })

  export const editDelivered = id => {
    return {
      type: EDIT_DELIVERED,
      payload: { id },
    }
  }
  export const editDeliveredSuccess = deliveredOn => {
    return {
      type: EDIT_DELIVERED_SUCCESS,
      payload: deliveredOn,
    }
  }
  export const editDeliveredFail = error => {
    return {
      type: EDIT_DELIVERED_FAIL,
      payload: error,
    }
  }
  
  export const updateDelivered = (deliveredOnUpdate, history) => {
    return {
      type: UPDATE_DELIVERED,
      payload: { deliveredOnUpdate, history },
    }
  }
  
  export const updateDeliveredSuccess = deliveredOn => {
    return {
      type: UPDATE_DELIVERED_SUCCESS,
      payload: deliveredOn,
    }
  }
  
  export const updateDeliveredFail = error => {
    return {
      type: UPDATE_DELIVERED_FAIL,
      payload: error,
    }
  }

  export const fetchDeliveredListingBySection = (data) => {
    return {
      type: FETCH_DELIVERED_BY_SECTION,
      payload: { data }
    }
  }
  
  export const fetchDeliveredListingBySectionSuccess = deliveredOn => {
    return {
      type: FETCH_DELIVERED_BY_SECTION_SUCCESS,
      payload: deliveredOn,
    }
  }
  
  export const fetchDeliveredListingBySectionFail = error => {
    return {
      type: FETCH_DELIVERED_BY_SECTION_FAIL,
      payload: error,
    }
  }

  export const updateDeliversPosition = data => ({
    type: UPDATE_DELIVERED_POSITION,
    payload: { data },
  })
  
  export const updateDeliversPositionSuccess = deliveredOn => ({
    type: UPDATE_DELIVERED_POSITION_SUCCESS,
    payload: deliveredOn,
  })
  
  export const updateDeliversPositionFail = error => ({
    type: UPDATE_DELIVERED_POSITION_FAIL,
    payload: error,
  })