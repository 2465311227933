import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { CO_GET_PRODUCTCATEGORY_LISTING } from "./actionTypes"
import {
  coProductCategoryListingSuccess,
  coProductCategoryListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { coProductCategoryListingApi } from "../../../helpers/fakebackend_helper"

function* coGetproductCategoryListing() {
  try {
    const response = yield call(coProductCategoryListingApi)
    if (!response.results.prod_cat) {
      yield put(coProductCategoryListingFail(error))
    }
    yield put(coProductCategoryListingSuccess(response.results.prod_cat))
  } catch (error) {
    yield put(coProductCategoryListingFail(error))
  }
}

function* coProductCategorySaga() {
  yield takeEvery(CO_GET_PRODUCTCATEGORY_LISTING, coGetproductCategoryListing)
}
export default coProductCategorySaga
