import {
  SA_GET_PRODUCTS_LISTING,
  SA_GET_PRODUCTS_LISTING_SUCCESS,
  SA_GET_PRODUCTS_LISTING_FAILED,
  SA_PRODUCT_EDIT,
  SA_PRODUCT_EDIT_SUCCESS,
  SA_PRODUCT_EDIT_FAIL,
  } from "./actionTypes"
  
  export const saProductsListing = () => {
      return {
        type: SA_GET_PRODUCTS_LISTING,
      }
    }
    export const saProductsListingSuccess = product => {
      return {
        type: SA_GET_PRODUCTS_LISTING_SUCCESS,
        payload: product,
      }
    }
    export const saProductsListingFail = error => {
      return {
        type: SA_GET_PRODUCTS_LISTING_FAILED,
        payload: error,
      }
    }

    export const saEditProduct = productId => {
      return {
        type: SA_PRODUCT_EDIT,
        payload: { productId },
      }
    }
    export const saEditProductSuccess = product => {
      return {
        type: SA_PRODUCT_EDIT_SUCCESS,
        payload: product,
      }
    }
    export const saEditProductFail = error => {
      return {
        type: SA_PRODUCT_EDIT_FAIL,
        payload: error,
      }
    }