import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Redux States
import {
  BD_GET_SUPPLIERNAME_LISTING,
  BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
} from "./actionTypes"
import {
  bdSupplierNameListingSuccess,
  bdSupplierNameListingFail,
  bdSupplierNameListingForEditOfferSearchPageSuccess,
  bdSupplierNameListingForEditOfferSearchPageFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  bdSupplierNameListingApi,
  bdSupplierNameListingForEditOfferSearchPageApi,
} from "../../../helpers/fakebackend_helper"

function* getBdSupplierNameListing() {
  try {
    const response = yield call(bdSupplierNameListingApi)
    if (!response.results.supplier_names) {
      yield put(bdSupplierNameListingFail(error))
    }
    yield put(bdSupplierNameListingSuccess(response.results.supplier_names))
  } catch (error) {
    yield put(bdSupplierNameListingFail(error))
  }
}

function* getBdSupplierNameListingForEditOfferSearchPage({ payload: { data } }) {
  try {
    const response = yield call(
      bdSupplierNameListingForEditOfferSearchPageApi,
      data
    )
    if (
      response &&
      response.status &&
      response?.results?.supplier_names &&
      response?.results?.supplier_names.length
    ) {
      yield put(
        bdSupplierNameListingForEditOfferSearchPageSuccess(
          response.results.supplier_names
        )
      )
    } else {
      throw response.msg ? response.msg : "Failed to fetch supplier names"
    }
  } catch (error) {
    yield put(bdSupplierNameListingForEditOfferSearchPageFail(error))
  }
}

function* bdSupplierNameSaga() {
  yield takeEvery(BD_GET_SUPPLIERNAME_LISTING, getBdSupplierNameListing)
  yield takeEvery(
    BD_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
    getBdSupplierNameListingForEditOfferSearchPage
  )
}
export default bdSupplierNameSaga
