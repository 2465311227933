export const GET_CONSTRUCTON_LISTING = "GET_CONSTRUCTON_LISTING"
export const GET_CONSTRUCTON_LISTING_SUCCESS = "GET_CONSTRUCTON_LISTING_SUCCESS"
export const GET_CONSTRUCTON_LISTING_FAILED = "GET_CONSTRUCTON_LISTING_FAILED"

export const ADD_CONSTRUCTON = "ADD_CONSTRUCTON"
export const ADD_CONSTRUCTON_SUCCESS = "ADD_CONSTRUCTON_SUCCESS"
export const ADD_CONSTRUCTON_FAIL = "ADD_CONSTRUCTON_FAIL"

export const CHANGE_CONSTRUCTON_STATUS = "CHANGE_CONSTRUCTON_STATUS"
export const CHANGE_CONSTRUCTON_STATUS_SUCCESS =
  "CHANGE_CONSTRUCTON_STATUS_SUCCESS"
export const CHANGE_CONSTRUCTON_STATUS_FAIL = "CHANGE_CONSTRUCTON_STATUS_FAIL"

export const EDIT_CONSTRUCTON = "EDIT_CONSTRUCTON"
export const EDIT_CONSTRUCTON_SUCCESS = "EDIT_CONSTRUCTON_SUCCESS"
export const EDIT_CONSTRUCTON_FAIL = "EDIT_CONSTRUCTON_FAIL"

export const UPDATE_CONSTRUCTON = "UPDATE_CONSTRUCTON"
export const UPDATE_CONSTRUCTON_SUCCESS = "UPDATE_CONSTRUCTON_SUCCESS"
export const UPDATE_CONSTRUCTON_FAIL = "UPDATE_CONSTRUCTON_FAIL"

export const FETCH_CONSTRUCTON_BY_SECTION = "FETCH_CONSTRUCTON_BY_SECTION"
export const FETCH_CONSTRUCTON_BY_SECTION_SUCCESS =
  "FETCH_CONSTRUCTON_BY_SECTION_SUCCESS"
export const FETCH_CONSTRUCTON_BY_SECTION_FAIL =
  "FETCH_CONSTRUCTON_BY_SECTION_FAIL"

  export const UPDATE_CONSTRUCTION_POSITION = "UPDATE_CONSTRUCTION_POSITION"
export const UPDATE_CONSTRUCTION_POSITION_SUCCESS =
  "UPDATE_CONSTRUCTION_POSITION_SUCCESS"
export const UPDATE_CONSTRUCTION_POSITION_FAIL = "UPDATE_CONSTRUCTION_POSITION_FAIL"