import {
  CO_GET_OFFERSTATUS_LISTING,
  CO_GET_OFFERSTATUS_LISTING_SUCCESS,
  CO_GET_OFFERSTATUS_LISTING_FAILED,
  } from "./actionTypes"
  
  export const coOfferStatusListing = () => {
    return {
      type: CO_GET_OFFERSTATUS_LISTING,
    }
  }
  
  export const coOfferStatusListingSuccess = offerStatus => {
    return {
      type: CO_GET_OFFERSTATUS_LISTING_SUCCESS,
      payload: offerStatus,
    }
  }
  
  export const coOfferStatusListingFail = error => {
    return {
      type: CO_GET_OFFERSTATUS_LISTING_FAILED,
      payload: error,
    }
  }
  
