import {
  CO_GET_PACKINGSTANDARD_LISTING,
  CO_GET_PACKINGSTANDARD_LISTING_SUCCESS,
  CO_GET_PACKINGSTANDARD_LISTING_FAILED,
  } from "./actionTypes"
  
  export const coPackingStandardListing = () => {
    return {
      type: CO_GET_PACKINGSTANDARD_LISTING,
    }
  }
  
  export const coPackingStandardListingSuccess = packingStandard => {
    return {
      type: CO_GET_PACKINGSTANDARD_LISTING_SUCCESS,
      payload: packingStandard,
    }
  }
  
  export const coPackingStandardListingFail = error => {
    return {
      type: CO_GET_PACKINGSTANDARD_LISTING_FAILED,
      payload: error,
    }
  }
  
