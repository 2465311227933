import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Redux States
import {
  CO_GET_SUPPLIERNAME_LISTING,
  CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
} from "./actionTypes"

import {
  coSupplierNameListingSuccess,
  coSupplierNameListingFail,
  coSupplierNameListingForEditOfferSearchPageSuccess,
  coSupplierNameListingForEditOfferSearchPageFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  coSupplierNameListingApi,
  coSupplierNameListingForEditOfferSearchPageApi,
} from "../../../helpers/fakebackend_helper"

function* coGetSupplierNameListing() {
  try {
    const response = yield call(coSupplierNameListingApi)
    if (!response.results.supplier_names) {
      yield put(coSupplierNameListingFail(error))
    }
    yield put(coSupplierNameListingSuccess(response.results.supplier_names))
  } catch (error) {
    yield put(coSupplierNameListingFail(error))
  }
}

function* coGetSupplierNameListingForEditOfferSearchPage({ payload: { data } }) {
  try {
    const response = yield call(
      coSupplierNameListingForEditOfferSearchPageApi,
      data
    )
    if (
      response &&
      response.status &&
      response?.results?.supplier_names &&
      response?.results?.supplier_names.length
    ) {
      yield put(
        coSupplierNameListingForEditOfferSearchPageSuccess(
          response.results.supplier_names
        )
      )
    } else {
      throw response.msg ? response.msg : "Failed to fetch supplier names"
    }
  } catch (error) {
    yield put(coSupplierNameListingForEditOfferSearchPageFail(error))
  }
}

function* coSupplierNameSaga() {
  yield takeEvery(CO_GET_SUPPLIERNAME_LISTING, coGetSupplierNameListing)
  yield takeEvery(
    CO_GET_SUPPLIERNAME_LISTING_FOR_EDIT_OFFER_SEARCH_PAGE,
    coGetSupplierNameListingForEditOfferSearchPage
  )
}
export default coSupplierNameSaga
