import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_PACKAGING_LISTING,
  FETCH_PACKAGING_BY_SECTION,
  ADD_PACKAGING,
  EDIT_PACKAGING,
  UPDATE_PACKAGING,
  PACKAGING_ACTIVE_INACTIVE,
  PACKING_POSITION,
} from "./actionTypes"
import {
  packagingListingSuccess,
  packagingListingFail,
  fetchPackagingListingBySectionSuccess,
  fetchPackagingListingBySectionFail,
  addPackagingSuccess,
  addPackagingFail,
  editPackagingSuccess,
  editPackagingFail,
  updatePackagingSuccess,
  updatePackagingFail,
  packagingChangeSuccess,
  packagingChangeFail,
  updatePackingPositionSuccess,
  updatePackingPositionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  packagingListingApi,
  fetchDropdownsBySectionApi,
  addPackagingApi,
  getPackagingDetailsById,
  updatePackagingApi,
  PackagingChangeStatusApi,
  fetchDropDownPositionApi,
} from "../../../helpers/fakebackend_helper"

function* getPackagingListing() {
  try {
    const response = yield call(packagingListingApi)
    if (!response.results.packaging) {
      yield put(packagingListingFail(error))
    }
    yield put(packagingListingSuccess(response.results.packaging))
  } catch (error) {
    yield put(packagingListingFail(error))
  }
}

function* fetchPackagingListingBySection({ payload: { data } }) {
  try {
    const response = yield call(fetchDropdownsBySectionApi, data)
    if (!response?.results?.packaging) {
      throw response.msg ? response.msg : "Failed to fetch details"
    }
    yield put(fetchPackagingListingBySectionSuccess(response.results.packaging))
  } catch (error) {
    yield put(fetchPackagingListingBySectionFail(error))
  }
}

function* addPackaging({ payload: { data, history } }) {
  try {
    const response = yield call(addPackagingApi, data)
    if (response && response.status) {
      yield put(addPackagingSuccess(response))
      history.push("/dropdown/packaging/list")
    } else {
      throw response.msg ? response.msg : "Failed to add"
    }
  } catch (error) {
    yield put(addPackagingFail(error))
  }
}

function* editPackaging({ payload: { id } }) {
  try {
    const response = yield call(getPackagingDetailsById, id)
    if (response && response.status) {
      response.id = id
      yield put(editPackagingSuccess(response.results.packaging))
    } else {
      throw response.msg ? response.msg : "Failed to edit"
    }
  } catch (error) {
    yield put(editPackagingFail(error))
  }
}

function* updatePackaging({ payload: { updatePackaging, history } }) {
  try {
    const response = yield call(updatePackagingApi, updatePackaging)
    if (response && response.status) {
      yield put(updatePackagingSuccess(response))
      history.push("/dropdown/packaging/list")
    } else {
      throw response.msg ? response.msg : "Failed to update"
    }
  } catch (error) {
    yield put(updatePackagingFail(error))
  }
}

function* PackagingChange({ payload: { id } }) {
  try {
    const response = yield call(PackagingChangeStatusApi, {
      packaging_id: id,
    })
    if (response && response.status) {
      response.id = id
      yield put(packagingChangeSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update status"
    }
  } catch (error) {
    yield put(packagingChangeFail(error))
  }
}

function* updatePackingPosition({ payload: { position } }) {
  try {
    const response = yield call(fetchDropDownPositionApi, position)
    if (response && response.status) {
      yield put(updatePackingPositionSuccess(response))
    } else {
      throw response.msg ? response.msg : "Failed to update position"
    }
  } catch (error) {
    let msg = error ? { msg: error } : { msg: "Failed to update position" }
    yield put(updatePackingPositionFail(msg))
  }
}

function* packagingSaga() {
  yield takeEvery(GET_PACKAGING_LISTING, getPackagingListing)
  yield takeEvery(FETCH_PACKAGING_BY_SECTION, fetchPackagingListingBySection)
  yield takeEvery(ADD_PACKAGING, addPackaging)
  yield takeEvery(EDIT_PACKAGING, editPackaging)
  yield takeEvery(UPDATE_PACKAGING, updatePackaging)
  yield takeEvery(PACKAGING_ACTIVE_INACTIVE, PackagingChange)
  yield takeEvery(PACKING_POSITION, updatePackingPosition)
}
export default packagingSaga
