import {
  GET_INCOTERMCITY_LISTING,
  GET_INCOTERMCITY_LISTING_SUCCESS,
  GET_INCOTERMCITY_LISTING_FAILED,
  ADD_INCOTERM,
  ADD_INCOTERM_SUCCESS,
  ADD_INCOTERM_FAIL,
  CHANGE_INCOTERM_STATUS,
  CHANGE_INCOTERM_STATUS_SUCCESS,
  CHANGE_INCOTERM_STATUS_FAIL,
  EDIT_INCOTERM,
  EDIT_INCOTERM_SUCCESS,
  EDIT_INCOTERM_FAIL,
  UPDATE_INCOTERM,
  UPDATE_INCOTERM_SUCCESS,
  UPDATE_INCOTERM_FAIL,
  FETCH_INCOTERM_BY_SECTION,
  FETCH_INCOTERM_BY_SECTION_SUCCESS,
  FETCH_INCOTERM_BY_SECTION_FAIL,
  INCOTERM_CITY_POSITION,
  INCOTERM_CITY_POSITION_SUCCESS,
  INCOTERM_CITY_POSITION_FAIL,
} from "./actionTypes"

const initialState = {
  loading: false,
  msg: null,
  error: null,
  positionUpdate: null,
  incotermCity: [],
  incotermCityEdit: null,
  incotermCityUpdate: null,
}

const incotermCity = (state = initialState, action) => {
  switch (action.type) {
    case GET_INCOTERMCITY_LISTING:
      state = {
        ...state,
        loading: true,
        msg: null,
        error: null,
        positionUpdate: null,
        incotermCity: action.payload,
      }
      break
    case GET_INCOTERMCITY_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: null,
        positionUpdate: null,
        incotermCity: action.payload,
      }
      break
    case GET_INCOTERMCITY_LISTING_FAILED:
      state = {
        ...state,
        msg: null,
        positionUpdate: null,
        loading: false,
      }
      break

    case ADD_INCOTERM:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        incotermCity: action.payload,
      }
      break
    case ADD_INCOTERM_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        incotermCity: action.payload,
      }
      break
    case ADD_INCOTERM_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: action.payload ? action.payload : null,
      }
      break
    case CHANGE_INCOTERM_STATUS:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break
    case CHANGE_INCOTERM_STATUS_SUCCESS:
      const index = state.incotermCity.findIndex(
        incoterm_city => incoterm_city.incoterm_city_id === action.payload.id
      )
      let newIncotermAfterStatusChange = []
      newIncotermAfterStatusChange = [...state.incotermCity] //making a new array
      newIncotermAfterStatusChange[index].status =
        !newIncotermAfterStatusChange[index].status

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        incotermCity: newIncotermAfterStatusChange,
      }
      break

    case CHANGE_INCOTERM_STATUS_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case EDIT_INCOTERM:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        incotermCityEdit: action.payload,
      }
      break
    case EDIT_INCOTERM_SUCCESS:
      if (state === null || state.incotermCity.length === 0) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          incotermCity: [...state.incotermCity, action.payload],
        }
      } else {
        state = {
          ...state,
          loading: false,
          incotermCity: state.incotermCity.map(i =>
            i.id === action.payload.id ? action.payload : i
          ),
        }
      }
      break
    case EDIT_INCOTERM_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case UPDATE_INCOTERM:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        incotermCityUpdate: action.payload,
      }
      break
    case UPDATE_INCOTERM_SUCCESS:
      state = { ...state, error: null, msg: null, loading: false }
      break
    case UPDATE_INCOTERM_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case FETCH_INCOTERM_BY_SECTION:
      state = {
        ...state,
        loading: true,
        msg: null,
        error: null,
        positionUpdate: null,
        incotermCityList: action.payload,
      }
      break
    case FETCH_INCOTERM_BY_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: null,
        positionUpdate: null,
        incotermCity: action.payload,
      }
      break
    case FETCH_INCOTERM_BY_SECTION_FAIL:
      state = {
        ...state,
        msg: null,
        positionUpdate: null,
        loading: false,
      }
      break
    case INCOTERM_CITY_POSITION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
      }
      break

    case INCOTERM_CITY_POSITION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: action.payload.msg != "" ? action.payload.msg : "",
        positionUpdate: true,
      }
      break
    case INCOTERM_CITY_POSITION_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.msg != "" ? action.payload.msg : "",
        msg: null,
        positionUpdate: null,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default incotermCity
