import {
    BD_GET_PRODUCTS_LISTING,
    BD_GET_PRODUCTS_LISTING_SUCCESS,
    BD_GET_PRODUCTS_LISTING_FAILED,
    BD_PRODUCT_EDIT,
    BD_PRODUCT_EDIT_SUCCESS,
    BD_PRODUCT_EDIT_FAIL,
  } from "./actionTypes"
  
  export const bdProductsListing = () => {
      return {
        type: BD_GET_PRODUCTS_LISTING,
      }
    }
    export const bdProductsListingSuccess = product => {
      return {
        type: BD_GET_PRODUCTS_LISTING_SUCCESS,
        payload: product,
      }
    }
    export const bdProductsListingFail = error => {
      return {
        type: BD_GET_PRODUCTS_LISTING_FAILED,
        payload: error,
      }
    }

    export const bdEditProduct = productId => {
      return {
        type: BD_PRODUCT_EDIT,
        payload: { productId },
      }
    }
    export const bdEditProductSuccess = product => {
      return {
        type: BD_PRODUCT_EDIT_SUCCESS,
        payload: product,
      }
    }
    export const bdEditProductFail = error => {
      return {
        type: BD_PRODUCT_EDIT_FAIL,
        payload: error,
      }
    }