import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { useHistory } from "react-router-dom"
// Logs Redux States
import { GET_LOGS_LISTING, GET_ACTIVITY_LOG } from "./actionTypes"
import {
  logsListingSuccess,
  logsListingFail,
  activityLogSuccess,
  activityLogFail,
} from "./actions"

//Include Both Helper File with needed methods
import { logsListingApi, activityLogApi } from "../../../helpers/fakebackend_helper"

function* getLogsListing({ payload: { logPagination, history } }) {
  try {
    const response = yield call(logsListingApi, logPagination)
    if (
      response.results === undefined ||
      response.results.log_details.length == 0
    ) {
      throw "No data found"
    }
    yield put(logsListingSuccess(response.results.log_details))
  } catch (error) {
    yield put(logsListingFail(error))
  }
}

function* getActivityLog({ payload: { logPagination, history } }) {
  try {
    const response = yield call(activityLogApi, logPagination)
    if (
      response.results === undefined ||
      response.results.length == 0
    ) {
      throw "No data found"
    }
    yield put(activityLogSuccess(response.results))
  } catch (error) {
    yield put(activityLogFail(error))
  }
}

function* logSaga() {
  yield takeEvery(GET_LOGS_LISTING, getLogsListing)
  yield takeEvery(GET_ACTIVITY_LOG, getActivityLog)
}
export default logSaga
