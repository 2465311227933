import {
    GET_CREATEDBY_LISTING,
    GET_CREATEDBY_LISTING_SUCCESS,
    GET_CREATEDBY_LISTING_FAILED,
  } from "./actionTypes"

  export const createdByListing = () => {
    return {
      type: GET_CREATEDBY_LISTING,
    }
  }
  
  export const createdByListingSuccess = createdBy => {
    return {
      type: GET_CREATEDBY_LISTING_SUCCESS,
      payload: createdBy,
    }
  }
  
  export const createdByListingFail = error => {
    return {
      type: GET_CREATEDBY_LISTING_FAILED,
      payload: error,
    }
  }