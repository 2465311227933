import {
  BD_GET_INCOTERMCITY_LISTING,
  BD_GET_INCOTERMCITY_LISTING_SUCCESS,
  BD_GET_INCOTERMCITY_LISTING_FAILED,
  } from "./actionTypes"
  
  export const bdIncotermCityListing = () => {
    return {
      type: BD_GET_INCOTERMCITY_LISTING,
    }
  }
  
  export const bdIncotermCityListingSuccess = bdIncotermCity => {
    return {
      type: BD_GET_INCOTERMCITY_LISTING_SUCCESS,
      payload: bdIncotermCity,
    }
  }
  
  export const bdIncotermCityListingFail = error => {
    return {
      type: BD_GET_INCOTERMCITY_LISTING_FAILED,
      payload: error,
    }
  }
  
