import {
  BD_GET_HEAVEYCOLLI_LISTING,
  BD_GET_HEAVEYCOLLI_LISTING_SUCCESS,
  BD_GET_HEAVEYCOLLI_LISTING_FAILED,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  bdHeaveyColli: null,
}

const bdHeaveyColli = (state = initialState, action) => {
  switch (action.type) {
    case BD_GET_HEAVEYCOLLI_LISTING:
      state = {
        ...state,
        loading: true,
        bdHeaveyColli: action.payload,
      }
      break
    case BD_GET_HEAVEYCOLLI_LISTING_SUCCESS:
      state = { ...state, loading: false, bdHeaveyColli: action.payload }
      break
    case BD_GET_HEAVEYCOLLI_LISTING_FAILED:
      state = { ...state, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default bdHeaveyColli
