import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import React, { useState, useRef, useEffect, useMemo } from "react"
import { logsListing } from "../../../../store/admin/logs/actions"
import { Row, Col, Container } from "reactstrap"

import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

const LogList = props => {
  const dispatch = useDispatch()

  let key = `${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`
  LicenseManager.setLicenseKey(key)
  document.title = "Logs List"

  useEffect(() => {
    let page = 1
    let limit = 10
    let logPaginationData = { page: page, limit: limit }
    dispatch(logsListing(logPaginationData, props.history))
  }, [])

  let logsList = useSelector(state => state.Log.log)
  const error = useSelector(state => state.Log.error)
  const loading = useSelector(state => state.Log.loading)

  const gridRef = useRef()

  const [rowData, setRowData] = useState()

  const [columnDefs, setColumnDefs] = useState([
    { headerName: "Name", field: "name" },
    {
      headerName: "Entry Date",
      field: "entry_date",
    },
    {
      headerName: "Login Time",
      field: "login_time",
    },
    { headerName: "Browser", field: "browser" },
    { headerName: "Device", field: "device" },
    {
      headerName: "IP Address",
      field: "ip_addr",
    },
    {
      headerName: "User Type",
      field: "user_type",
    },
  ])

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      filter: true,
      minWidth: 200,
      resizable: true,
    }),
    []
  )

  useEffect(() => {
    setRowData(logsList)
  }, [logsList])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container fluid={true}>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: "80vh" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  animateRows={true}
                  enableRangeSelection={true}
                  enableCharts={true}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  pagination={true}
                  paginationPageSize={20}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LogList
