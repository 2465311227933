import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { BD_GET_DELIVEREDON_LISTING } from "./actionTypes"
import {
  bdDeliveredOnListingSuccess,
  bdDeliveredOnListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import { bdDeliveredOnListingApi } from "../../../helpers/fakebackend_helper"

function* getBdDeliveredOnListing() {
  try {
    const response = yield call(bdDeliveredOnListingApi)
    if (!response.results.delivers) {
      yield put(bdDeliveredOnListingFail(error))
    }
    yield put(bdDeliveredOnListingSuccess(response.results.delivers))
  } catch (error) {
    yield put(bdDeliveredOnListingFail(error))
  }
}

function* bdDeliveredOnSaga() {
  yield takeEvery(BD_GET_DELIVEREDON_LISTING, getBdDeliveredOnListing)
}
export default bdDeliveredOnSaga
