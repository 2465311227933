import {
  GET_ROLES_LISTING,
  GET_ROLES_LISTING_SUCCESS,
  GET_ROLES_LISTING_FAILED,
  FETCH_ROLES_BY_SECTION,
  FETCH_ROLES_BY_SECTION_SUCCESS,
  FETCH_ROLES_BY_SECTION_FAIL,
  EDIT_ROLE,
  EDIT_ROLE_FAIL,
  EDIT_ROLE_SUCCESS,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  USER_ROLE_ACTIVE_INACTIVE,
  USER_ROLE_ACTIVE_INACTIVE_FAIL,
  USER_ROLE_ACTIVE_INACTIVE_SUCCESS,
  UPDATE_USER_ROLE_POSITION_SUCCESS,
  UPDATE_USER_ROLE_POSITION_FAIL,
  UPDATE_USER_ROLE_POSITION,
} from "./actionTypes"

const initialState = {
  error: null,
  msg:null,
  loading: false,
  role: null,
  roleEdit: null,
  positionUpdate: null,
}

const role = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES_LISTING:
      state = {
        ...state,
        error: null,
        loading: true,
        msg:null,
        roleEdit: null,
        role: action.payload,
        positionUpdate: null,
      }
      break
    case GET_ROLES_LISTING_SUCCESS:
      state = {
        ...state,
        error: null,
        loading: false,
        msg:null,
        positionUpdate: null,
        role: action.payload,
      }
      break
    case GET_ROLES_LISTING_FAILED:
      state = { ...state, loading: false }
      break

    case FETCH_ROLES_BY_SECTION:
      state = {
        ...state,
        loading: true,
        msg:null,
        roleList: action.payload,
        roleEdit: null,
        positionUpdate: null,
      }
      break
    case FETCH_ROLES_BY_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        msg: null,
        error: null,
        positionUpdate: null,
        role: action.payload,
      }
      break
    case FETCH_ROLES_BY_SECTION_FAIL:
      state = {
        ...state,
        msg: null,
        error: null,
        positionUpdate: null,
        loading: false,
      }
      break

    case EDIT_ROLE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        roleEdit: action.payload,
      }
      break
    case EDIT_ROLE_SUCCESS:
      if (state === null || state.role.length === 0) {
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          role: [...state.role, action.payload],
        }
      } else {
        state = {
          ...state,
          loading: false,
          role: state.role.map(i =>
            i.id === action.payload.id ? action.payload : i
          ),
        }
      }
      break
    case EDIT_ROLE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break
    case UPDATE_ROLE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        role: action.payload,
      }
      break
    case UPDATE_ROLE_SUCCESS:
      state = { ...state, error: null, msg: null, loading: false }
      break
    case UPDATE_ROLE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    case USER_ROLE_ACTIVE_INACTIVE:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
      }
      break

    case USER_ROLE_ACTIVE_INACTIVE_SUCCESS:
      const index = state.role.findIndex(
        role => role.role_id === action.payload.role_id
      )
      let roles = []
      roles = [...state.role] //making a new array
      roles[index].status = roles[index].status == 1 ? 0 : 1

      state = {
        ...state,
        loading: false,
        error: null,
        msg: null,
        role: roles,
      }
      break

    case USER_ROLE_ACTIVE_INACTIVE_FAIL:
      state = {
        ...state,
        loading: false,
        msg: null,
        error:
          action.payload !== "light"
            ? action.payload
            : "" !== "light"
            ? action.payload
            : "",
      }
      break

    case UPDATE_USER_ROLE_POSITION:
      state = {
        ...state,
        loading: true,
        error: null,
        msg: null,
        positionUpdate: null,
      }
      break

    case UPDATE_USER_ROLE_POSITION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        msg: action.payload.msg != "" ? action.payload.msg : "",
        positionUpdate: true,
      }
      break
    case UPDATE_USER_ROLE_POSITION_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.msg != "" ? action.payload.msg : "",
        msg: null,
        positionUpdate: null,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default role
