import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  BD_GET_PRODUCT_NUMBER_LISTING,
} from "./actionTypes"
import {
  bdProductNumberListingSuccess,
  bdProductNumberListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  bdProductNumberListingApi,
} from "../../../helpers/fakebackend_helper"

function* bdGetproductNumberListing() {
  try {
    const response = yield call(bdProductNumberListingApi)
    if (!response.results) {
      yield put(bdProductNumberListingFail(error))
    }
    yield put(bdProductNumberListingSuccess(response.results))
  } catch (error) {
    yield put(bdProductNumberListingFail(error))
  }
}

function* bdProductNumberSaga() {
  yield takeEvery(BD_GET_PRODUCT_NUMBER_LISTING, bdGetproductNumberListing)
}
export default bdProductNumberSaga
