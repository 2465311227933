import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  BD_GET_PRODUCTCATEGORY_LISTING,
} from "./actionTypes"
import {
  bdProductCategoryListingSuccess,
  bdProductCategoryListingFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  bdProductCategoryListingApi,
} from "../../../helpers/fakebackend_helper"

function* bdGetproductCategoryListing() {
  try {
    const response = yield call(bdProductCategoryListingApi)
    if (!response.results.prod_cat) {
      yield put(bdProductCategoryListingFail(error))
    }
    yield put(bdProductCategoryListingSuccess(response.results.prod_cat))
  } catch (error) {
    yield put(bdProductCategoryListingFail(error))
  }
}

function* bdProductCategorySaga() {
  yield takeEvery(BD_GET_PRODUCTCATEGORY_LISTING, bdGetproductCategoryListing)
}
export default bdProductCategorySaga
