import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  GET_COUNTS_ON_DASHBOARD,
  GET_COUNTS_ON_DASHBOARD_SUCCESS,
  GET_COUNTS_ON_DASHBOARD_FAILED,
} from "./actionTypes"

const INIT_STATE = {
  chartsData: [],
  error: "",
  loading: false,
  dashboardCounts: null,
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsData: action.payload.data,
          }
        default:
          return state
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsDataError: action.payload.error,
          }

        default:
          return state
      }

    case GET_COUNTS_ON_DASHBOARD:
      state = {
        ...state,
        error: null,
        loading: true,
      }
      return state
      break
    case GET_COUNTS_ON_DASHBOARD_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        dashboardCounts: action.payload,
      }
      return state
      break
    case GET_COUNTS_ON_DASHBOARD_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload ? action.payload : null,
      }
      return state
      break
    default:
      return state
  }
}

export default Dashboard
