import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import React, { useState, useRef, useEffect, useMemo } from "react"
import { Row, Col, Container } from "reactstrap"

import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import { activityLog } from "../../../../store/actions"

const ActivityLogList = props => {
  const dispatch = useDispatch()

  let key = `${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`
  LicenseManager.setLicenseKey(key)
  document.title = "Activity Log"

  useEffect(() => {
    let page = 1
    let limit = 10
    let logPaginationData = { page: page, limit: limit }
    dispatch(activityLog(logPaginationData, props.history))
  }, [])

  let activity = useSelector(state => state.Log.log)

  const gridRef = useRef()

  const [rowData, setRowData] = useState()

  const [columnDefs, setColumnDefs] = useState([
    { headerName: "Name", field: "name", maxWidth: 300, },
    {
      headerName: "User Role",
      field: "user_role",
      maxWidth: 200,
    },
    {
      headerName: "Action",
      field: "ref_name",
      maxWidth: 500,
      cellRenderer: params => {
        if (params?.data?.ref_type && (params?.data?.ref_type == 'edit_product')) {
          return (
            <Link
              className="text-center cursor-pointer font_size_medium"
              to={`/products/edit/` + params.data.prod_id}
            >
              {params?.data?.ref_name}
            </Link>
          )
        } else if (params?.data?.ref_type && (params?.data?.ref_type == 'edit_offer')) {
          return (
            <Link
              className="text-center cursor-pointer font_size_medium"
              to={`/offers/edit/` + params.data.offer_id}
            >
              {params?.data?.ref_name}
            </Link>
          )
        } else if (params?.data?.ref_type && (params?.data?.ref_type == 'create_product')) {
          return (
            <Link
              className="text-center cursor-pointer font_size_medium"
              to={`/products/edit/` + params.data.prod_id}
            >
              {params?.data?.ref_name}
            </Link>
          )
        } else if (params?.data?.ref_type && (params?.data?.ref_type == 'create_offer')) {
          return (
            <Link
              className="text-center cursor-pointer font_size_medium"
              to={`/offers/edit/` + params.data.offer_id}
            >
              {params?.data?.ref_name}
            </Link>
          )
        } else if (params?.data?.ref_type && (['delete_offer', 'product_deletion', 'export'].includes(params?.data?.ref_type))) {
          return (<p>{params?.data?.ref_name}</p>)
        }
      },
    },
    { headerName: "Created At", field: "created_at", maxWidth: 300, },
  ])

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      filter: true,
      minWidth: 200,
      resizable: true,
    }),
    []
  )

  useEffect(() => {
    setRowData(activity)
  }, [activity])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container fluid={true}>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: "80vh" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  animateRows={true}
                  enableRangeSelection={true}
                  enableCharts={true}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  pagination={true}
                  paginationPageSize={20}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ActivityLogList
