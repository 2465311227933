import {
  BD_GET_PRODUCT_NUMBER_LISTING,
  BD_GET_PRODUCT_NUMBER_LISTING_SUCCESS,
  BD_GET_PRODUCT_NUMBER_LISTING_FAILED,
  } from "./actionTypes"

  const initialState = {
    error: null,
    msg: null,
    loading: false,
    bdProductNumber: null,
  }

  const bdProductNumber = (state = initialState, action) => {
    switch (action.type) {
      case BD_GET_PRODUCT_NUMBER_LISTING:
        state = {
          ...state,
          loading: true,
          error: null,
          msg: null,
          bdProductNumber: action.payload,
        }
        break
      case BD_GET_PRODUCT_NUMBER_LISTING_SUCCESS:
        state = {
          ...state,
          loading: false,
          error: null,
          msg: null,
          bdProductNumber: action.payload,
        }
        break
      case BD_GET_PRODUCT_NUMBER_LISTING_FAILED:
        state = { ...state, loading: false }
    }
    return state
  }
  
  export default bdProductNumber;